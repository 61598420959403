<app-crosschecks-chart-wrapper
  [context]="crosschecksChartContext"
  [crosscheckIndicationVisible]="true"
  [filters]="filters"
  [skeletonTheme]="'StockMix'"
>
  <div *ngIf="false" class="header-container">
    <app-chart-filters
      [dropdownList]="dropdownList"
      [dropdownOptions]="dropdownOptions"
      [isShowValuesVisible]="false"
    ></app-chart-filters>
  </div>

  <div class="subheader-container">
    <span class="subheader-text"> Stock units </span>
  </div>

  <div class="container">
    <div class="chart-container">
      <div class="horizontal-chart-wrapper">
        <div class="pagination-header-container">
          <span class="chart-header-text">Average stock coverage</span>
          <span class="pagination-button-text"
            >next <mat-icon class="pagination-button-arrow">chevron_right</mat-icon></span
          >
        </div>
        <app-ftd-bar-chart
          *ngIf="chartData"
          [data]="chartData"
          [categoryField]="'model'"
          [rootId]="'ftd-bar-chart-ref-stock-mix'"
          [chartCustomTheme]="'StockMixCustomTheme'"
          [isLabelValuesVisible]="isChartValuesVisible"
          [isTooltipVisible]="false"
          [horizontalLayout]="true"
          [axisType]="chartAxisType"
          [showPercentage]="false"
          [verticalRangeValue]="idealStockParameter"
        ></app-ftd-bar-chart>
      </div>
      <app-stock-mix-side-table [dataSource]="sideTableDataSource"></app-stock-mix-side-table>
    </div>
    <div class="tile-container">
      <app-ftd-chart-info-tile
        *ngIf="averageStockAge"
        [displayName]="averageStockAge.display_name"
        [selectedSeries]="averageStockAge.selected_series"
        [displayValue]="averageStockAge.value"
        [displayNameDetails]="averageStockAge.display_name_details"
      ></app-ftd-chart-info-tile>
      <app-ftd-chart-info-tile
        *ngIf="ispStockCoverage"
        [displayName]="ispStockCoverage.display_name"
        [selectedSeries]="ispStockCoverage.selected_series"
        [displayValue]="ispStockCoverage.value"
        [displayNameDetails]="ispStockCoverage.display_name_details"
      ></app-ftd-chart-info-tile>
    </div>
  </div>

  <div class="chart-filter-wrapper">
    <img ngSrc="./assets/images/charts/legends/rectangle-blue.svg" alt="" height="18" width="18" />
    <span class="chart-filter-item">Stock Coverage</span>

    <img src="./assets/images/charts/legends/delta_next_month.svg" alt="" height="18" width="60" />
    <span class="chart-filter-item">Expected stock coverage delta next month</span>
  </div>
</app-crosschecks-chart-wrapper>
