import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MatDateFormats } from '@angular/material/core';
import { FormGroup } from '@angular/forms';
import { InputStatus } from '../../enums/table-input-status.enum';
import { MatCalendar, MatDateRangeInput } from '@angular/material/datepicker';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
let _id: number = 0;

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatDateRangeInput,
    },
  ],
  selector: 'app-ftd-date-picker',
  styleUrls: ['./ftd-date-picker.component.scss'],
  templateUrl: './ftd-date-picker.component.html',
})
export class FtdDatepickerComponent implements AfterViewInit {
  @Output() datepickerToggleClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @ViewChild('textField') textField!: ElementRef<HTMLInputElement>;
  ftdHeader = FtdDatepickerHeader;

  // Ftd-date-picker-${id}
  @Input() pickRanges: boolean = false;
  @Input() id!: string;
  @Input() name!: string;
  @Input() form!: FormGroup;
  @Input() controlName: string = 'effectiveDate';
  @Input() customMessage: string = '';
  @Input() label: string = '';
  @Input() placeholder: string = 'DD/MM/YYYY';
  @Input() daysAhead: number = 1;
  @Input() inputStatus?: InputStatus = 'default';
  @Input() minDate?: Date | null = new Date();

  _enableMinDate: boolean = false;
  get enableMinDate(): boolean {
    return this._enableMinDate;
  }

  @Input() set enableMinDate(value: boolean) {
    this._enableMinDate = value;
    if (value) {
      this.minDate = new Date();
      this.minDate?.setDate(this.minDate.getDate() + this.daysAhead);
    } else {
      this.minDate = null;
    }
  }

  constructor() {
    _id += 1;
    this.id = `ftd-date-picker-${_id}`;
  }

  ngAfterViewInit() {
    if (this.inputStatus === 'disabled') {
      this.textField.nativeElement.disabled = true;
    }
  }

  emitDatepickerToggleClicked(): void {
    this.datepickerToggleClicked.emit(true);
  }
}

/** Custom header component for datepicker. */
@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-ftd-date-picker-header',
  styleUrls: ['./ftd-date-picker-header.scss'],
  template: `
    <div class="ftd-date-picker-header">
      <button mat-icon-button class="ftd-date-picker-double-arrow" (click)="previousClicked('year')">
        <mat-icon>keyboard_double_arrow_left</mat-icon>
      </button>
      <button mat-icon-button (click)="previousClicked('month')">
        <mat-icon>chevron_left</mat-icon>
      </button>
      <span class="ftd-date-picker-header-label">{{ periodLabel }}</span>
      <button mat-icon-button (click)="nextClicked('month')">
        <mat-icon>chevron_right</mat-icon>
      </button>
      <button mat-icon-button class="ftd-date-picker-double-arrow" (click)="nextClicked('year')">
        <mat-icon>keyboard_double_arrow_right</mat-icon>
      </button>
    </div>
  `,
})

// eslint-disable-next-line @angular-eslint/component-class-suffix
export class FtdDatepickerHeader<D> implements OnDestroy {
  private _destroyed: Subject<void> = new Subject<void>();
  private subscriptions: Subscription[] = [];

  constructor(
    private _calendar: MatCalendar<D>,
    private _dateAdapter: DateAdapter<D>,
    @Inject(MAT_DATE_FORMATS) private _dateFormats: MatDateFormats,
    cdr: ChangeDetectorRef
  ) {
    const subscription: Subscription = _calendar.stateChanges
      .pipe(takeUntil(this._destroyed))
      .subscribe(() => cdr.markForCheck());
    this.subscriptions.push(subscription);
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
    this.subscriptions.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }

  get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, this._dateFormats.display.monthYearLabel)
      .toLocaleUpperCase();
  }

  previousClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, -1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, -1);
  }

  nextClicked(mode: 'month' | 'year') {
    this._calendar.activeDate =
      mode === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, 1)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, 1);
  }
}
