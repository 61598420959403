import { AxisRenderer, CategoryAxis } from '@amcharts/amcharts5/xy';
import { FtdAm5ChartsThemeHelper } from '../../ftd-am5-charts.theme';
import { Theme } from '@amcharts/amcharts5';
import { am5 } from '../../../ftd-base-chart.abstract';
import { stockCoverageSeriesName } from '../../../enums/crosschecks/stock/ftd-stock-coverage.enum';

export class FtdStockCoverageBarCustomTheme extends Theme {
  setupDefaultRules() {
    super.setupDefaultRules();
    this.setupGridRules();
    this.setupColumnRules();
    this.setupZoom();
  }

  protected setupGridRules() {
    this.rule('ValueAxis').setAll({
      extraMax: 0.2,
    });

    this.rule('Button').setAll({
      forceHidden: true,
    });
  }

  protected setupColumnRules() {
    this.rule('ColumnSeries').adapters.add('fill', (fill, target) => {
      switch (target.get('name')) {
        case stockCoverageSeriesName.accessible:
        case stockCoverageSeriesName.totalStock:
          return am5.color(0x52a2eb);
        case stockCoverageSeriesName.stockEstimateCurrentPrice:
          target.columns.template.setAll({
            fill: undefined,
            fillOpacity: 0,
            stroke: am5.color(0x52a2eb),
            strokeDasharray: [10, 5],
            strokeOpacity: 1,
            strokeWidth: 2,
          });
          return am5.color(0x52a2eb);
        case stockCoverageSeriesName.stockEstimateScenarioPrice:
          target.columns.template.setAll({
            fill: undefined,
            fillOpacity: 0,
            stroke: am5.color(0xf28f33),
            strokeDasharray: [10, 5],
            strokeOpacity: 1,
            strokeWidth: 2,
          });
          return am5.color(0xf28f33);
      }
      return fill;
    });
  }

  protected setupZoom() {
    this.rule('CategoryAxis').events.once(
      'datavalidated',
      (event: { type: string; target: CategoryAxis<AxisRenderer> }) => {
        event.target.zoomToIndexes(0, 12);
      }
    );
  }
}

export class FtdStockCoverageLineCustomTheme extends Theme {
  setupDefaultRules() {
    super.setupDefaultRules();
    this.setupLabelRules();
    this.setupGridRules();
    this.setupLineRules();
    this.setupZoom();
  }

  protected setupGridRules() {
    this.rule('AxisRendererY').setAll({
      minGridDistance: 35,
    });

    this.rule('ValueAxis').setAll({
      extraMax: 0.2,
    });

    this.rule('Button').setAll({
      forceHidden: true,
    });
  }

  protected setupLabelRules() {
    this.rule('Label').setup = (target) => {
      if (target.dataItem?.bullets) {
        target.setAll({
          centerX: am5.p50,
          centerY: am5.p50,
          layer: 1,
        });
      }
    };
  }

  protected setupLineRules() {
    this.rule('LineSeries').adapters.add('stroke', (stroke, target) => {
      switch (target.get('name')) {
        case stockCoverageSeriesName.totalISPStockCoverage:
          target.strokes.template.setAll({ strokeWidth: 2 });
          target.root.numberFormatter.setAll({
            numberFormat: '#,###.0',
            numericFields: ['valueY'],
          });
          return am5.color(0xf8c237);
        case stockCoverageSeriesName.totalISPStockCoverageForecast:
          target.strokes.template.setAll({
            strokeDasharray: [10, 5],
            strokeOpacity: 1,
            strokeWidth: 2,
          });
          return am5.color(0xf8c237);
        case stockCoverageSeriesName.totalStockCoverage:
          target.strokes.template.setAll({ strokeWidth: 2 });
          return am5.color(0x79ddc0);
        case stockCoverageSeriesName.stockCoverageEstimateCurrentPrice:
          target.strokes.template.setAll({
            strokeDasharray: [10, 5],
            strokeOpacity: 1,
            strokeWidth: 2,
          });
          return am5.color(0x79ddc0);
        case stockCoverageSeriesName.stockCoverageEstimateScenarioPrice:
          target.strokes.template.setAll({
            strokeDasharray: [10, 5],
            strokeOpacity: 1,
            strokeWidth: 2,
          });
          return am5.color(0xf28f33);
      }
      return stroke;
    });
    this.rule('LineSeries').adapters.add('fill', (fill, target) => {
      switch (target.get('name')) {
        case stockCoverageSeriesName.totalISPStockCoverage:
          return am5.color(0xf8c237);
        case stockCoverageSeriesName.totalISPStockCoverageForecast:
          return am5.color(0xf8c237);
        case stockCoverageSeriesName.totalStockCoverage:
          return am5.color(0x79ddc0);
        case stockCoverageSeriesName.totalStockCoverageForecast:
          return am5.color(0x79ddc0);
        case stockCoverageSeriesName.stockCoverageEnginePredicted:
          return am5.color(0xf28f33);
        case stockCoverageSeriesName.stockCoverageEstimateCurrentPrice:
          return am5.color(0x79ddc0);
        case stockCoverageSeriesName.stockCoverageEstimateScenarioPrice:
          return am5.color(0xf28f33);
      }
      return fill;
    });
    this.rule('LineSeries').setup = (target) => {
      if (target.get('name') === 'Engine Predicted Stock Coverage') {
        target.bullets.push((root, series, dataItem) => {
          return am5.Bullet.new(root, {
            sprite: am5.Rectangle.new(root, {
              centerX: 10,
              centerY: 10,
              fill: am5.color(0xf28f33),
              height: 20,
              rotation: 45,
              tooltip: FtdAm5ChartsThemeHelper.getTooltip(root, 'horizontal', true),
              tooltipHTML: FtdAm5ChartsThemeHelper.buildChartTooltipHTML([{ key: '{name}', value: '{valueY}' }]),
              width: 20,
            }),
          });
        });
      }
    };
  }

  protected setupZoom() {
    this.rule('CategoryAxis').events.once(
      'datavalidated',
      (event: { type: string; target: CategoryAxis<AxisRenderer> }) => {
        event.target.zoomToIndexes(0, 12);
        event.target.get('renderer').grid.template.set('visible', false);
      }
    );
  }
}
