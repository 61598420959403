<div class="side-table-wrapper">
  <div class="header-wrapper">
    <span class="header-text">Next month expected</span>
    <span class="header-text">Total stock</span>
  </div>
  <mat-divider></mat-divider>
  <div *ngFor="let row of dataSource" class="row-wrapper">
    <div class="next-month-expected-wrapper">
      <mat-icon class="next-month-delta-icon" ngClass="{{ row.deltaStockCoverage }}">straight</mat-icon>
      <span>Δ {{ row.nextMonthExpected }} months</span>
    </div>
    <span>{{ row.totalStock }} units</span>
  </div>
</div>
