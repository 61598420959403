export enum DiscountApprovalStatus {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  LVL1_APPROVED = 'LVL1_APPROVED',
  NSC_APPROVED = 'NSC_APPROVED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EXPORTED = 'EXPORTED',
  RETIRED = 'RETIRED',
}
