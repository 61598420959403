import { Color, Theme } from '@amcharts/amcharts5';
import { am5 } from '../../../ftd-base-chart.abstract';

export class FtdStockMixCustomTheme extends Theme {
  setupDefaultRules() {
    super.setupDefaultRules();
    this.setupLabelRules();
    this.setupGridRules();
    this.setupColumnRules();
  }

  protected setupGridRules() {
    this.rule('XYChart').setAll({
      paddingRight: 0,
      paddingTop: 0,
      wheelX: 'panX',
    });

    this.rule('ValueAxis').setAll({
      maxDeviation: 0,
    });

    this.rule('AxisRendererY').setAll({
      layer: 2,
      minGridDistance: 35,
      stroke: Color.fromHex(0xffffff),
    });

    this.rule('ValueAxis').setAll({
      extraMax: 0.1,
    });

    this.rule('ValueAxis').events.once('datavalidated', (ev) => {
      ev.target.get('renderer').grid.template.set('visible', false);
    });

    this.rule('Button').setAll({
      forceHidden: true,
    });

    this.rule('CategoryAxis').setAll({
      maxDeviation: 0,
    });
  }

  protected setupLabelRules() {
    this.rule('Label').setup = (target: am5.Label): void => {
      if (target.dataItem?.bullets) {
        target.setAll({
          centerX: am5.p50,
          centerY: am5.p50,
        });
      }
    };

    this.rule('AxisLabel', ['x']).setAll({
      maxPosition: 0.9,
    });

    this.rule('AxisLabel', ['y']).setAll({
      paddingLeft: 20,
    });
  }

  protected setupColumnRules() {
    this.rule('ColumnSeries').adapters.add('fill', (): Color | undefined => am5.color(0x52a2eb));
  }
}
