import * as Apollo from 'apollo-angular';
import { Injectable } from '@angular/core';
import { gql } from 'apollo-angular';
export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDate: string;
  AWSDateTime: string;
  AWSEmail: string;
  AWSIPAddress: string;
  AWSJSON: string;
  AWSPhone: string;
  AWSTime: string;
  AWSTimestamp: number;
  AWSURL: string;
}

export enum Accessible {
  Accessible = 'ACCESSIBLE',
  TotalStock = 'Total_Stock',
}

export interface IAddDiscountCommentInput {
  createdAt: Scalars['AWSDateTime'];
  discountPointId: Scalars['ID'];
  filterId: Scalars['ID'];
  text: Scalars['String'];
  userId?: InputMaybe<Scalars['String']>;
  userName: Scalars['String'];
}

export interface IAddDiscountInput {
  absoluteDiscountInclTaxRange?: InputMaybe<Scalars['String']>;
  absoluteDiscountNetRange?: InputMaybe<Scalars['String']>;
  applicationLevel: ApplicationLevel;
  approvalStatus?: InputMaybe<DiscountApprovalStatus>;
  benefitType: BenefitType;
  brand: Scalars['String'];
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  discountCategory: DiscountCategory;
  discountType: DiscountType;
  endDate?: InputMaybe<Scalars['AWSDateTime']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  market: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  relativeDiscountInclTaxRange?: InputMaybe<Scalars['String']>;
  relativeDiscountNetRange?: InputMaybe<Scalars['String']>;
  salesChannels: Array<SalesChannels>;
  startDate?: InputMaybe<Scalars['AWSDateTime']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface IAddDiscountPointInput {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  deletedAt?: InputMaybe<Scalars['AWSDateTime']>;
  discountId: Scalars['ID'];
  filterId: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  market?: InputMaybe<Scalars['String']>;
  modelCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  value?: InputMaybe<Scalars['Float']>;
}

export interface IAddScenarioResponse {
  response: Scalars['String'];
}

export interface IAddScenariosInput {
  kpiCalculatedDate?: InputMaybe<Scalars['AWSDateTime']>;
  predictionComponentURI?: InputMaybe<Scalars['String']>;
  scenarioName: Scalars['String'];
  scenarioType: ScenarioType;
  status?: InputMaybe<Scalars['String']>;
  userScenarioId?: InputMaybe<Scalars['String']>;
}

export enum ApplicationLevel {
  GrossListPrice = 'GROSS_LIST_PRICE',
  NetListPrice = 'NET_LIST_PRICE',
}

export enum ApprovalStatus {
  Approved = 'APPROVED',
  Exported = 'EXPORTED',
  Lvl1Approved = 'LVL1_APPROVED',
  Lvl1Rejected = 'LVL1_REJECTED',
  New = 'NEW',
  NscApproved = 'NSC_APPROVED',
  NscRejected = 'NSC_REJECTED',
  ProposalWithdrawn = 'PROPOSAL_WITHDRAWN',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
}

export interface IApprovalStatusBySeriesInput {
  market?: InputMaybe<Scalars['String']>;
  modelCodePathIds: Array<Scalars['String']>;
  pathId: Scalars['String'];
  series?: InputMaybe<Scalars['String']>;
}

export interface IApprovalStatusBySeriesResponse {
  canApprove: Scalars['Boolean'];
  lvl1Approved?: Maybe<Array<Maybe<IPricePoint>>>;
  market?: Maybe<Scalars['String']>;
  nscApproved?: Maybe<Array<Maybe<IPricePoint>>>;
  pathId?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
}

export interface IApproveReleaseSeriesPricePointsResponse {
  market?: Maybe<Scalars['String']>;
  pathId?: Maybe<Scalars['String']>;
  pricePoints?: Maybe<Array<Maybe<IPricePoint>>>;
  series?: Maybe<Scalars['String']>;
}

export interface IApproveSeriesPricePointsInput {
  canApprove: Scalars['Boolean'];
  lvl1Approved?: InputMaybe<Array<InputMaybe<IPricePointInput>>>;
  market?: InputMaybe<Scalars['String']>;
  nscApproved?: InputMaybe<Array<InputMaybe<IPricePointInput>>>;
  pathId?: InputMaybe<Scalars['String']>;
  series?: InputMaybe<Scalars['String']>;
}

export enum BenefitType {
  Absolute = 'ABSOLUTE',
  Relative = 'RELATIVE',
}

export interface IBrandApprovalStatus {
  brand: Scalars['String'];
  canApprove: Scalars['Boolean'];
  message?: Maybe<Scalars['String']>;
}

export interface IColumnMetadata {
  config: Scalars['String'];
  dataIngestionDate?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  lastSourceCall?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
}

export enum ColumnType {
  Actions = 'ACTIONS',
  AllEffectiveDateInputEditor = 'ALL_EFFECTIVE_DATE_INPUT_EDITOR',
  ApprovalStatus = 'APPROVAL_STATUS',
  Crosscheck = 'CROSSCHECK',
  CrosscheckSummary = 'CROSSCHECK_SUMMARY',
  DateDisplay = 'DATE_DISPLAY',
  Disabled = 'DISABLED',
  EffectiveDateInput = 'EFFECTIVE_DATE_INPUT',
  Empty = 'EMPTY',
  GoToLeaseLoanSimulatorButton = 'GO_TO_LEASE_LOAN_SIMULATOR_BUTTON',
  IntegerDisplay = 'INTEGER_DISPLAY',
  IntegerPriceDisplay = 'INTEGER_PRICE_DISPLAY',
  Name = 'NAME',
  NumberDisplay = 'NUMBER_DISPLAY',
  PredictionEvaluationIndicator = 'PREDICTION_EVALUATION_INDICATOR',
  PriceDisplay = 'PRICE_DISPLAY',
  PriceInputEditor = 'PRICE_INPUT_EDITOR',
  PriceInputPercentageEditor = 'PRICE_INPUT_PERCENTAGE_EDITOR',
  TextDisplay = 'TEXT_DISPLAY',
  UnitMap = 'UNIT_MAP',
}

export interface IComment {
  createdAt: Scalars['AWSDateTime'];
  filterId: Scalars['ID'];
  id: Scalars['ID'];
  pricePointId: Scalars['ID'];
  text: Scalars['String'];
  userId: Scalars['String'];
  userName: Scalars['String'];
}

export enum CrossCheckContextType {
  Current = 'CURRENT',
  Forecasted = 'FORECASTED',
}

export interface IDeleteScenarioResponse {
  response: Scalars['String'];
}

export interface IDiscount {
  absoluteDiscountInclTaxRange?: Maybe<Scalars['String']>;
  absoluteDiscountNetRange?: Maybe<Scalars['String']>;
  applicationLevel: ApplicationLevel;
  approvalStatus?: Maybe<DiscountApprovalStatus>;
  benefitType: BenefitType;
  brand: Scalars['String'];
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  discountCategory: DiscountCategory;
  discountType: DiscountType;
  endDate?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['String'];
  isActive?: Maybe<Scalars['Boolean']>;
  market: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  relativeDiscountInclTaxRange?: Maybe<Scalars['String']>;
  relativeDiscountNetRange?: Maybe<Scalars['String']>;
  salesChannels: Array<SalesChannels>;
  startDate?: Maybe<Scalars['AWSDateTime']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  userId?: Maybe<Scalars['String']>;
}

export enum DiscountApprovalStatus {
  Approved = 'APPROVED',
  Exported = 'EXPORTED',
  Lvl1Approved = 'LVL1_APPROVED',
  Lvl1Rejected = 'LVL1_REJECTED',
  New = 'NEW',
  NscApproved = 'NSC_APPROVED',
  NscRejected = 'NSC_REJECTED',
  ProposalWithdrawn = 'PROPOSAL_WITHDRAWN',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED',
}

export enum DiscountCategory {
  Campaign = 'CAMPAIGN',
  SpecialB2CDiscount = 'SPECIAL_B2C_DISCOUNT',
}

export interface IDiscountComment {
  createdAt: Scalars['AWSDateTime'];
  discountPointId: Scalars['ID'];
  filterId: Scalars['ID'];
  id: Scalars['ID'];
  text: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  userName: Scalars['String'];
}

export interface IDiscountData {
  discountId: Scalars['ID'];
  filterId: Scalars['ID'];
  indicativeLeaseRate?: Maybe<Scalars['Float']>;
  listPriceExclTax?: Maybe<Scalars['Float']>;
  listPriceInclTax?: Maybe<Scalars['Float']>;
  typicallyEquippedPrice?: Maybe<Scalars['Float']>;
}

export interface IDiscountIterator {
  items: Array<IDiscount>;
  nextToken?: Maybe<Scalars['String']>;
}

export interface IDiscountPoint {
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  deletedAt?: Maybe<Scalars['AWSDateTime']>;
  discountId: Scalars['ID'];
  filterId: Scalars['ID'];
  id: Scalars['ID'];
  isActive?: Maybe<Scalars['Boolean']>;
  market?: Maybe<Scalars['String']>;
  modelCode?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['AWSDateTime']>;
  value?: Maybe<Scalars['Float']>;
}

export interface IDiscountPointsByDiscountIdTotalResponse {
  total: Scalars['Int'];
}

export enum DiscountType {
  BevCampaign = 'BEV_CAMPAIGN',
  ConquestCampaign = 'CONQUEST_CAMPAIGN',
  DisabledPerson = 'DISABLED_PERSON',
  EventBasedCampaign = 'EVENT_BASED_CAMPAIGN',
  Journalist = 'JOURNALIST',
  LoyaltyCampaign = 'LOYALTY_CAMPAIGN',
  RunRateSupport = 'RUN_RATE_SUPPORT',
  VipA = 'VIP_A',
  VipB = 'VIP_B',
  VipC = 'VIP_C',
}

export interface IDiscountsColumnMetadata {
  config: Scalars['String'];
  dataIngestionDate?: Maybe<Scalars['AWSDateTime']>;
  description?: Maybe<Scalars['String']>;
  lastSourceCall?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
}

export enum DiscountsColumnType {
  Actions = 'ACTIONS',
  AllEffectiveDateInputEditor = 'ALL_EFFECTIVE_DATE_INPUT_EDITOR',
  ApprovalStatus = 'APPROVAL_STATUS',
  Crosscheck = 'CROSSCHECK',
  CrosscheckSummary = 'CROSSCHECK_SUMMARY',
  DateDisplay = 'DATE_DISPLAY',
  Disabled = 'DISABLED',
  EffectiveDateInput = 'EFFECTIVE_DATE_INPUT',
  Empty = 'EMPTY',
  GoToLeaseLoanSimulatorButton = 'GO_TO_LEASE_LOAN_SIMULATOR_BUTTON',
  IntegerDisplay = 'INTEGER_DISPLAY',
  IntegerPriceDisplay = 'INTEGER_PRICE_DISPLAY',
  Name = 'NAME',
  NumberDisplay = 'NUMBER_DISPLAY',
  PredictionEvaluationIndicator = 'PREDICTION_EVALUATION_INDICATOR',
  PriceDisplay = 'PRICE_DISPLAY',
  PriceInputEditor = 'PRICE_INPUT_EDITOR',
  PriceInputPercentageEditor = 'PRICE_INPUT_PERCENTAGE_EDITOR',
  TextDisplay = 'TEXT_DISPLAY',
  UnitMap = 'UNIT_MAP',
}

export interface IDiscountsMatrixColumn {
  columnType: DiscountsColumnType;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isNotCollapsible?: Maybe<Scalars['Boolean']>;
  isSticky?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<IDiscountsColumnMetadata>;
  parentId?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  secondaryDisplayName?: Maybe<Scalars['String']>;
  sectionKey?: Maybe<Scalars['String']>;
  technicalName?: Maybe<Scalars['String']>;
}

export interface IDynamic1ScenarioDataInput {
  budgetCmPerUnit?: InputMaybe<Scalars['Int']>;
  budgetNextYearCmPerUnit?: InputMaybe<Scalars['Int']>;
  budgetVolumeRetail?: InputMaybe<Scalars['Int']>;
  budgetVolumeRetailNextYear?: InputMaybe<Scalars['Int']>;
  channelsTrafficLight?: InputMaybe<Scalars['AWSJSON']>;
  co2BaseCar?: InputMaybe<Scalars['String']>;
  competitionTrafficLight?: InputMaybe<Scalars['AWSJSON']>;
  currentCmPerUnit?: InputMaybe<Scalars['Int']>;
  deltaCmOrdersAbsCurrency?: InputMaybe<Scalars['String']>;
  effectiveSince?: InputMaybe<Scalars['AWSDateTime']>;
  expectedVolumeRetail?: InputMaybe<Scalars['Int']>;
  filterId: Scalars['ID'];
  forecast1CmPerUnit?: InputMaybe<Scalars['Int']>;
  forecast2CmPerUnit?: InputMaybe<Scalars['Int']>;
  forecastLatestCmPerUnit?: InputMaybe<Scalars['Int']>;
  forecastVolumeRetail?: InputMaybe<Scalars['Int']>;
  governancePricePointStatus?: InputMaybe<Scalars['String']>;
  indicativeLeaseRate?: InputMaybe<Scalars['Float']>;
  indicativeLeaseRateCurrency?: InputMaybe<Scalars['String']>;
  indicativeLeaseRateMetaData?: InputMaybe<Scalars['AWSJSON']>;
  listPriceExclTax?: InputMaybe<Scalars['Float']>;
  listPriceExclTaxCurrency?: InputMaybe<Scalars['String']>;
  listPriceInclTax?: InputMaybe<Scalars['Float']>;
  listPriceInclTaxCurrency?: InputMaybe<Scalars['String']>;
  listPriceInclTaxFuture?: InputMaybe<Scalars['Float']>;
  listPriceInclTaxMetaData?: InputMaybe<Scalars['AWSJSON']>;
  marketsTrafficLight?: InputMaybe<Scalars['AWSJSON']>;
  microMacroTrafficLight?: InputMaybe<Scalars['AWSJSON']>;
  netPriceBaseCar?: InputMaybe<Scalars['Float']>;
  netPriceBaseCarCurrency?: InputMaybe<Scalars['String']>;
  orderForecastMonth?: InputMaybe<Scalars['Float']>;
  portfolioTrafficLight?: InputMaybe<Scalars['AWSJSON']>;
  priceChangeCount?: InputMaybe<Scalars['Int']>;
  pricePointEffectiveDate?: InputMaybe<Scalars['AWSDateTime']>;
  pricePointPriceCurrency?: InputMaybe<Scalars['String']>;
  productionPipelineTrafficLight?: InputMaybe<Scalars['AWSJSON']>;
  recommendedPriceEngine?: InputMaybe<Scalars['Float']>;
  recommendedPriceMetaData?: InputMaybe<Scalars['AWSJSON']>;
  recommendedRetailPrice?: InputMaybe<Scalars['Float']>;
  recommendedRetailPriceReasonCode?: InputMaybe<Scalars['String']>;
  recommendedRetailPriceReasonCodeMetaData?: InputMaybe<Scalars['AWSJSON']>;
  recommendedRetailPriceValidFromDate?: InputMaybe<Scalars['AWSDateTime']>;
  retailVolumeAchievementPct?: InputMaybe<Scalars['Float']>;
  retailVolumeActualAbs?: InputMaybe<Scalars['Int']>;
  salesPipelineTrafficLight?: InputMaybe<Scalars['AWSJSON']>;
  stockTrafficLight?: InputMaybe<Scalars['AWSJSON']>;
  targetCmPerUnitOrdersCurrency?: InputMaybe<Scalars['String']>;
  taxChangeValidFromDate?: InputMaybe<Scalars['AWSDateTime']>;
  typicallyEquippedCurrentCmPerUnit?: InputMaybe<Scalars['Int']>;
  typicallyEquippedLeaseRate?: InputMaybe<Scalars['Float']>;
  typicallyEquippedPrice?: InputMaybe<Scalars['Float']>;
  typicallyEquippedPriceCurrency?: InputMaybe<Scalars['String']>;
}

export interface IDynamic2ScenarioDataInput {
  adjustedCmPerUnit?: InputMaybe<Scalars['Int']>;
  adjustedListPriceReasonCode?: InputMaybe<Scalars['String']>;
  channelsTrafficLightAdj?: InputMaybe<Scalars['AWSJSON']>;
  competitionTrafficLightAdj?: InputMaybe<Scalars['AWSJSON']>;
  deltaCmPerUnit?: InputMaybe<Scalars['String']>;
  deltaCurrentIndicativeLeaseRate?: InputMaybe<Scalars['String']>;
  deltaCurrentPriceEditor?: InputMaybe<Scalars['String']>;
  deltaExpectedVolumeRetailCurrentAdj?: InputMaybe<Scalars['String']>;
  deltaTotalCmAbs?: InputMaybe<Scalars['Int']>;
  expectedVolumeRetailAdj?: InputMaybe<Scalars['Int']>;
  filterId: Scalars['ID'];
  indicativeLeaseRateAdj?: InputMaybe<Scalars['Float']>;
  listPriceExclTaxAdj?: InputMaybe<Scalars['Float']>;
  marketsTrafficLightAdj?: InputMaybe<Scalars['AWSJSON']>;
  microMacroTrafficLightAdj?: InputMaybe<Scalars['AWSJSON']>;
  portfolioTrafficLightAdj?: InputMaybe<Scalars['AWSJSON']>;
  productionPipelineTrafficLightAdj?: InputMaybe<Scalars['AWSJSON']>;
  salesPipelineTrafficLightAdj?: InputMaybe<Scalars['AWSJSON']>;
  status?: InputMaybe<Scalars['String']>;
  stockTrafficLightAdj?: InputMaybe<Scalars['AWSJSON']>;
  summaryTrafficLightAdj?: InputMaybe<Scalars['AWSJSON']>;
  typicallyEquippedLeaseRateAdj?: InputMaybe<Scalars['Float']>;
  typicallyEquippedPriceAdj?: InputMaybe<Scalars['Float']>;
}

export enum EditPricePointTask {
  Promote = 'PROMOTE',
  Reject = 'REJECT',
  Withdraw = 'WITHDRAW',
}

export interface IFilter {
  brand: Scalars['String'];
  eSeries: Scalars['String'];
  id: Scalars['ID'];
  isVisible?: Maybe<Scalars['Boolean']>;
  level?: Maybe<Scalars['String']>;
  market: Scalars['String'];
  model: Scalars['String'];
  modelCode: Scalars['String'];
  posInPath?: Maybe<Scalars['Int']>;
  powertrain: Scalars['String'];
  segment: Scalars['String'];
  series: Scalars['String'];
}

export interface IHistoricData {
  countPerBucket0_10Days?: Maybe<Scalars['Int']>;
  countPerBucket11_30Days?: Maybe<Scalars['Int']>;
  countPerBucket31_90Days?: Maybe<Scalars['Int']>;
  countPerBucket91_180Days?: Maybe<Scalars['Int']>;
  countPerBucket181_270Days?: Maybe<Scalars['Int']>;
  countPerBucket271_365Days?: Maybe<Scalars['Int']>;
  countPerBucket365Days?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['AWSDate']>;
  historicSumGranularBucket0_10Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket11_20Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket21_30Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket31_40Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket41_50Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket51_60Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket61_70Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket71_80Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket81_90Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket91_100Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket101_110Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket111_120Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket121_130Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket131_140Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket141_150Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket151_160Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket161_170Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket171_180Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket181_195Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket196_210Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket211_225Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket226_240Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket241_255Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket256_270Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket271_285Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket286_300Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket301_315Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket316_330Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket331_345Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket346_365Days?: Maybe<Scalars['Int']>;
  historicSumGranularBucket365Days?: Maybe<Scalars['Int']>;
  monthlyCount?: Maybe<Scalars['Int']>;
  monthlyMedianAge?: Maybe<Scalars['Int']>;
}

export interface IInternalPriceLadder {
  eRange?: Maybe<Scalars['Float']>;
  enginePower?: Maybe<Scalars['Float']>;
  hasAdjustedPrice?: Maybe<Scalars['Boolean']>;
  indicativeLeaseRate?: Maybe<Scalars['Float']>;
  indicativeLeaseRateAdj?: Maybe<Scalars['Float']>;
  indicativeLeaseRateNet?: Maybe<Scalars['Float']>;
  indicativeLeaseRateNetAdj?: Maybe<Scalars['Float']>;
  listPriceExclTax?: Maybe<Scalars['Float']>;
  listPriceExclTaxAdj?: Maybe<Scalars['Float']>;
  listPriceInclTax?: Maybe<Scalars['Float']>;
  listPriceInclTaxAdj?: Maybe<Scalars['Float']>;
  model: Scalars['String'];
  ph_id: Scalars['Float'];
  priceLadderFulfilled?: Maybe<Scalars['String']>;
  priceLadderFulfilledAdj?: Maybe<Scalars['String']>;
  typicallyEquippedLeaseRate?: Maybe<Scalars['Float']>;
  typicallyEquippedLeaseRateAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedLeaseRateNet?: Maybe<Scalars['Float']>;
  typicallyEquippedLeaseRateNetAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedNetPrice?: Maybe<Scalars['Float']>;
  typicallyEquippedNetPriceAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedPrice?: Maybe<Scalars['Float']>;
  typicallyEquippedPriceAdj?: Maybe<Scalars['Float']>;
  volumeMix?: Maybe<Scalars['Float']>;
}

export interface IInternalPriceLadderData {
  data: Array<IInternalPriceLadder>;
  eSeries: Scalars['String'];
  segment: Scalars['String'];
  series: Scalars['String'];
}

export interface IMarketsCcTrafficLightsOverview {
  data?: Maybe<IMarketsCcTrafficLightsOverviewData>;
}

export interface IMarketsCcTrafficLightsOverviewData {
  trafficLight?: Maybe<ITrafficLight>;
}

export interface IMarketsCrosscheckData {
  leaseRate: IMarketsLeaseRate;
  market: Scalars['String'];
  modelCode?: Maybe<Scalars['String']>;
  modelCodeAdj?: Maybe<Scalars['String']>;
  price: IMarketsPrice;
}

export interface IMarketsLeaseRate extends IMarketsListPrice {
  baseGrossPrice?: Maybe<Scalars['Float']>;
  baseGrossPriceAdj?: Maybe<Scalars['Float']>;
  baseNetPrice?: Maybe<Scalars['Float']>;
  baseNetPriceAdj?: Maybe<Scalars['Float']>;
  hasAdjustedPrice?: Maybe<Scalars['Boolean']>;
  marketGrossPriceRatio?: Maybe<Scalars['Float']>;
  marketGrossPriceRatioAdj?: Maybe<Scalars['Float']>;
  marketGrossPriceTypicallyEquippedRatio?: Maybe<Scalars['Float']>;
  marketGrossPriceTypicallyEquippedRatioAdj?: Maybe<Scalars['Float']>;
  marketNetPriceRatio?: Maybe<Scalars['Float']>;
  marketNetPriceRatioAdj?: Maybe<Scalars['Float']>;
  marketNetPriceTypicallyEquippedRatio?: Maybe<Scalars['Float']>;
  marketNetPriceTypicallyEquippedRatioAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedGrossPrice?: Maybe<Scalars['Float']>;
  typicallyEquippedGrossPriceAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedNetPrice?: Maybe<Scalars['Float']>;
  typicallyEquippedNetPriceAdj?: Maybe<Scalars['Float']>;
}

export interface IMarketsListPrice {
  baseGrossPrice?: Maybe<Scalars['Float']>;
  baseGrossPriceAdj?: Maybe<Scalars['Float']>;
  baseNetPrice?: Maybe<Scalars['Float']>;
  baseNetPriceAdj?: Maybe<Scalars['Float']>;
  hasAdjustedPrice?: Maybe<Scalars['Boolean']>;
  marketGrossPriceRatio?: Maybe<Scalars['Float']>;
  marketGrossPriceRatioAdj?: Maybe<Scalars['Float']>;
  marketGrossPriceTypicallyEquippedRatio?: Maybe<Scalars['Float']>;
  marketGrossPriceTypicallyEquippedRatioAdj?: Maybe<Scalars['Float']>;
  marketNetPriceRatio?: Maybe<Scalars['Float']>;
  marketNetPriceRatioAdj?: Maybe<Scalars['Float']>;
  marketNetPriceTypicallyEquippedRatio?: Maybe<Scalars['Float']>;
  marketNetPriceTypicallyEquippedRatioAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedGrossPrice?: Maybe<Scalars['Float']>;
  typicallyEquippedGrossPriceAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedNetPrice?: Maybe<Scalars['Float']>;
  typicallyEquippedNetPriceAdj?: Maybe<Scalars['Float']>;
}

export interface IMarketsPrice extends IMarketsListPrice {
  baseGrossPrice?: Maybe<Scalars['Float']>;
  baseGrossPriceAdj?: Maybe<Scalars['Float']>;
  baseNetPrice?: Maybe<Scalars['Float']>;
  baseNetPriceAdj?: Maybe<Scalars['Float']>;
  hasAdjustedPrice?: Maybe<Scalars['Boolean']>;
  marketGrossPriceRatio?: Maybe<Scalars['Float']>;
  marketGrossPriceRatioAdj?: Maybe<Scalars['Float']>;
  marketGrossPriceTypicallyEquippedRatio?: Maybe<Scalars['Float']>;
  marketGrossPriceTypicallyEquippedRatioAdj?: Maybe<Scalars['Float']>;
  marketNetPriceRatio?: Maybe<Scalars['Float']>;
  marketNetPriceRatioAdj?: Maybe<Scalars['Float']>;
  marketNetPriceTypicallyEquippedRatio?: Maybe<Scalars['Float']>;
  marketNetPriceTypicallyEquippedRatioAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedGrossPrice?: Maybe<Scalars['Float']>;
  typicallyEquippedGrossPriceAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedNetPrice?: Maybe<Scalars['Float']>;
  typicallyEquippedNetPriceAdj?: Maybe<Scalars['Float']>;
}

export interface IMatrixColumn {
  columnType: ColumnType;
  displayName: Scalars['String'];
  id: Scalars['ID'];
  isNotCollapsible?: Maybe<Scalars['Boolean']>;
  isSticky?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<IColumnMetadata>;
  parentId?: Maybe<Scalars['String']>;
  position: Scalars['Int'];
  secondaryDisplayName?: Maybe<Scalars['String']>;
  sectionKey?: Maybe<Scalars['String']>;
  technicalName?: Maybe<Scalars['String']>;
}

export interface IMatrixColumnDynamicNameConfig {
  dataIngestionDate?: InputMaybe<Scalars['String']>;
  dynamicSubString?: InputMaybe<Scalars['String']>;
  lastSourceCall?: InputMaybe<Scalars['String']>;
  source?: InputMaybe<Scalars['String']>;
  technicalName: Scalars['String'];
}

export interface IMatrixColumnDynamicNameData {
  dataIngestionDate?: Maybe<Scalars['String']>;
  dynamicSubString?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  lastSourceCall?: Maybe<Scalars['String']>;
  market?: Maybe<Scalars['String']>;
  scenarioId?: Maybe<Scalars['String']>;
  source?: Maybe<Scalars['String']>;
  technicalName?: Maybe<Scalars['String']>;
}

export interface IMatrixColumnFormat {
  columnName: Scalars['String'];
  config?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  market: Scalars['String'];
}

export interface IMatrixColumnOrder {
  columnId: Scalars['ID'];
  isNotCollapsible?: Maybe<Scalars['Boolean']>;
  isSticky?: Maybe<Scalars['Boolean']>;
  isVisible?: Maybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  userId: Scalars['String'];
}

export interface IMatrixColumnOrderInput {
  columnId: Scalars['ID'];
  isNotCollapsible?: InputMaybe<Scalars['Boolean']>;
  isSticky?: InputMaybe<Scalars['Boolean']>;
  isVisible?: InputMaybe<Scalars['Boolean']>;
  position: Scalars['Int'];
  sectionKey?: InputMaybe<Scalars['String']>;
}

export interface IMutation {
  addComment: IComment;
  addDiscount: IDiscount;
  addDiscountComment: IDiscountComment;
  addDiscountPoint: Array<IDiscountPoint>;
  addDynamic1ScenarioData: IAddScenarioResponse;
  addDynamic2ScenarioData: IAddScenarioResponse;
  addNote: INote;
  addOptimisationScenarioData: IAddScenarioResponse;
  addScenario: IScenario;
  approveBrandPricePoints: Array<IPricePoint>;
  approveSeriesPricePoints: Array<Maybe<IApproveReleaseSeriesPricePointsResponse>>;
  bulkEditPricePoints: Array<IPricePoint>;
  createUpdatePricePoint: Array<Maybe<IPricePoint>>;
  deactivatePricePoints?: Maybe<IPricePointDeactivationResponse>;
  deleteScenario: IDeleteScenarioResponse;
  exportPricePoint: IPricePoint;
  promotePricePoint: IPricePoint;
  rejectPricePoint: IPricePoint;
  releaseSeriesPricePoints: Array<Maybe<IApproveReleaseSeriesPricePointsResponse>>;
  submitDiscount: IDiscount;
  submitPricePoints: ISubmitPricePointsResponse;
  updateDiscount: IDiscount;
  updateDiscountPoint: Array<IDiscountPoint>;
  updateMatrixColumnsDynamicName: Array<IMatrixColumnDynamicNameData>;
  updateMatrixColumnsOrder: Array<IMatrixColumn>;
  updateScenario: IScenario;
  updateScenarioLastUpdatedDate: IScenario;
  webVitals: IWebVitalsResponse;
  withdrawPricePoint: IPricePoint;
}

export interface IMutationAddCommentArgs {
  filterId: Scalars['ID'];
  pricePointId: Scalars['ID'];
  text: Scalars['String'];
}

export interface IMutationAddDiscountArgs {
  input: IAddDiscountInput;
}

export interface IMutationAddDiscountCommentArgs {
  input: IAddDiscountCommentInput;
}

export interface IMutationAddDiscountPointArgs {
  input?: InputMaybe<IAddDiscountPointInput>;
}

export interface IMutationAddDynamic1ScenarioDataArgs {
  input: Array<IDynamic1ScenarioDataInput>;
  market?: InputMaybe<Scalars['String']>;
}

export interface IMutationAddDynamic2ScenarioDataArgs {
  input: Array<IDynamic2ScenarioDataInput>;
  lastUpdated: Scalars['AWSDateTime'];
  market?: InputMaybe<Scalars['String']>;
  scenarioId: Scalars['String'];
}

export interface IMutationAddNoteArgs {
  filterId: Scalars['ID'];
  pricePointId: Scalars['ID'];
  scenarioId: Scalars['ID'];
  text: Scalars['String'];
}

export interface IMutationAddOptimisationScenarioDataArgs {
  input: Array<IOptimisationScenarioDataInput>;
  lastUpdated: Scalars['AWSDateTime'];
  market?: InputMaybe<Scalars['String']>;
  scenarioId: Scalars['String'];
}

export interface IMutationAddScenarioArgs {
  input: IAddScenariosInput;
  market: Scalars['String'];
}

export interface IMutationApproveBrandPricePointsArgs {
  brand: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['ID'];
}

export interface IMutationApproveSeriesPricePointsArgs {
  pathId: Array<Scalars['ID']>;
  scenarioId: Scalars['ID'];
}

export interface IMutationBulkEditPricePointsArgs {
  pricePoints: Array<IPricePointEditInput>;
  reason?: InputMaybe<Scalars['String']>;
  scenarioId: Scalars['ID'];
  task: EditPricePointTask;
}

export interface IMutationCreateUpdatePricePointArgs {
  pricePoints: Array<IPricePointInput>;
  recalculateScenario?: InputMaybe<Scalars['Boolean']>;
  scenarioId: Scalars['String'];
}

export interface IMutationDeactivatePricePointsArgs {
  pricePointIds: Array<Scalars['ID']>;
}

export interface IMutationDeleteScenarioArgs {
  scenarioId: Scalars['String'];
}

export interface IMutationExportPricePointArgs {
  pricePointId: Scalars['ID'];
}

export interface IMutationPromotePricePointArgs {
  expectedCurrentStatus: PromotePricePointStatus;
  pricePointId: Scalars['ID'];
  scenarioId: Scalars['ID'];
}

export interface IMutationRejectPricePointArgs {
  expectedCurrentStatus: ApprovalStatus;
  pricePointId: Scalars['ID'];
  reason?: InputMaybe<Scalars['String']>;
}

export interface IMutationReleaseSeriesPricePointsArgs {
  pathId: Array<Scalars['ID']>;
  scenarioId: Scalars['ID'];
}

export interface IMutationSubmitDiscountArgs {
  input: ISubmitDiscountInput;
}

export interface IMutationSubmitPricePointsArgs {
  isOverride: Scalars['Boolean'];
  pricePoints: Array<IPricePointInputForSubmit>;
  scenarioId: Scalars['String'];
}

export interface IMutationUpdateDiscountArgs {
  input: IUpdateDiscountInput;
}

export interface IMutationUpdateDiscountPointArgs {
  input?: InputMaybe<IUpdateDiscountPointInput>;
}

export interface IMutationUpdateMatrixColumnsDynamicNameArgs {
  config: Array<IMatrixColumnDynamicNameConfig>;
  market: Scalars['String'];
  scenarioId?: InputMaybe<Scalars['ID']>;
}

export interface IMutationUpdateMatrixColumnsOrderArgs {
  matrixColumnsOrder: Array<IMatrixColumnOrderInput>;
}

export interface IMutationUpdateScenarioArgs {
  input: IUpdateScenariosInput;
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IMutationUpdateScenarioLastUpdatedDateArgs {
  id: Scalars['String'];
}

export interface IMutationWebVitalsArgs {
  webVitals: IWebVitalsInput;
}

export interface IMutationWithdrawPricePointArgs {
  pricePointId: Scalars['ID'];
}

export interface INote {
  createdAt: Scalars['AWSDateTime'];
  filterId: Scalars['ID'];
  id: Scalars['ID'];
  scenarioId: Scalars['ID'];
  text: Scalars['String'];
  userId: Scalars['String'];
  userName: Scalars['String'];
}

export interface IOptimisationScenarioDataInput {
  deltaCurrentPriceEngineAbs?: InputMaybe<Scalars['Float']>;
  filterId: Scalars['ID'];
  indicativeLeaseRateEngine?: InputMaybe<Scalars['Float']>;
  predictionEvaluationIndicator?: InputMaybe<Scalars['Int']>;
  recommendedPriceEngine?: InputMaybe<Scalars['Float']>;
  recommendedPriceMetaData?: InputMaybe<Scalars['AWSJSON']>;
  summaryTrafficLight?: InputMaybe<Scalars['AWSJSON']>;
  typicallyEquippedLeaseRateEngine?: InputMaybe<Scalars['Float']>;
  typicallyEquippedPriceEngine?: InputMaybe<Scalars['Float']>;
}

export interface IPortfolioInternalPriceCcTrafficLightsOverview {
  data?: Maybe<IPortfolioInternalPriceCcTrafficLightsOverviewData>;
}

export interface IPortfolioInternalPriceCcTrafficLightsOverviewData {
  trafficLight?: Maybe<ITrafficLight>;
}

export interface IPortfolioInternalPriceCrosscheckData {
  internalPriceLadderData?: Maybe<Array<Maybe<IInternalPriceLadderData>>>;
  priceLadderOverallTrafficLight?: Maybe<ITrafficLight>;
  priceLadderOverallTrafficLightAdj?: Maybe<ITrafficLight>;
}

export interface IPricePoint {
  active?: Maybe<Scalars['Boolean']>;
  approvalStatus?: Maybe<ApprovalStatus>;
  author: Scalars['String'];
  creationDate?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['AWSDateTime']>;
  endDate?: Maybe<Scalars['AWSDateTime']>;
  filterId: Scalars['String'];
  id: Scalars['ID'];
  lastUpdateDate?: Maybe<Scalars['AWSDateTime']>;
  netPrice?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  scenarioId: Scalars['String'];
}

export interface IPricePointDeactivationResponse {
  errors?: Maybe<Array<Scalars['String']>>;
}

export interface IPricePointEditInput {
  expectedCurrentStatus: PromotePricePointStatus;
  pricePointId: Scalars['ID'];
}

export interface IPricePointInput {
  active?: InputMaybe<Scalars['Boolean']>;
  approvalStatus?: InputMaybe<ApprovalStatus>;
  comment?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  effectiveDate: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  filterId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  netPrice?: InputMaybe<Scalars['Float']>;
  price: Scalars['Float'];
}

export interface IPricePointInputForSubmit {
  active?: InputMaybe<Scalars['Boolean']>;
  comment?: InputMaybe<Scalars['String']>;
  currency: Scalars['String'];
  effectiveDate: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  filterId: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  netPrice?: InputMaybe<Scalars['Float']>;
  price: Scalars['Float'];
}

export interface IPricePointOperationError {
  errorType?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  pricePoint: IPricePointWithNullableFields;
}

export interface IPricePointWithNullableFields {
  active?: Maybe<Scalars['Boolean']>;
  approvalStatus?: Maybe<Scalars['String']>;
  author?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['AWSDateTime']>;
  currency?: Maybe<Scalars['String']>;
  effectiveDate?: Maybe<Scalars['AWSDateTime']>;
  endDate?: Maybe<Scalars['AWSDateTime']>;
  filterId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lastUpdateDate?: Maybe<Scalars['AWSDateTime']>;
  price?: Maybe<Scalars['Float']>;
  scenarioId?: Maybe<Scalars['String']>;
}

export interface IProductHierarchy {
  brand?: Maybe<Scalars['String']>;
  children?: Maybe<Array<IProductHierarchy>>;
  eSeries?: Maybe<Scalars['String']>;
  market: Scalars['String'];
  model?: Maybe<Scalars['String']>;
  modelCode?: Maybe<Scalars['String']>;
  parentPathId?: Maybe<Scalars['Int']>;
  pathElement?: Maybe<Scalars['String']>;
  pathId: Scalars['Int'];
  pathLevel?: Maybe<Scalars['String']>;
  powertrain?: Maybe<Scalars['String']>;
  segment?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
}

export enum PromotePricePointStatus {
  Approved = 'APPROVED',
  Lvl1Approved = 'LVL1_APPROVED',
  NscApproved = 'NSC_APPROVED',
  Submitted = 'SUBMITTED',
}

export interface IQuery {
  approvalStatusBySeries: Array<Maybe<IApprovalStatusBySeriesResponse>>;
  approvedPricePoints: Array<IPricePoint>;
  brandApprovalStatus: IBrandApprovalStatus;
  comments: Array<IComment>;
  discount: IDiscount;
  discountDataByFilterIds: Array<IDiscountData>;
  discountDrafts: IDiscountIterator;
  discountPointsByDiscountIdTotal: IDiscountPointsByDiscountIdTotalResponse;
  discounts: IDiscountIterator;
  discountsMatrixColumns: Array<IDiscountsMatrixColumn>;
  downloadScenarioDataByFilterIds: Array<IScenarioDataWithPricePoint>;
  filters: Array<IFilter>;
  marketsCcTrafficLightsOverview?: Maybe<IMarketsCcTrafficLightsOverview>;
  marketsCrossChecks: Array<IMarketsCrosscheckData>;
  matrixColumnFormats: Array<IMatrixColumnFormat>;
  matrixColumns: Array<IMatrixColumn>;
  matrixColumnsCustomOrder: Array<IMatrixColumn>;
  notes: Array<INote>;
  phModelCodesBySeries?: Maybe<Array<ISeriesWithModelCodes>>;
  portfolioInternalPrice: IPortfolioInternalPriceCrosscheckData;
  portfolioInternalPriceCcTrafficLightsOverview: IPortfolioInternalPriceCcTrafficLightsOverview;
  priceFilledFilterIds: Array<Maybe<IPricePoint>>;
  pricePoints: Array<IPricePoint>;
  productData?: Maybe<Array<IProductHierarchy>>;
  salesPipelineCcTrafficLightsOverview: ISalesPipelineCcTrafficLightsOverview;
  salesPipelineCrossChecks: ISalesPipelineCrossCheckData;
  scenarioDataByFilterIds: Array<IScenarioDataWithPricePoint>;
  scenarios: Array<IScenario>;
  stockAge: IStockAgeCrosscheckData;
  stockAgeCcTrafficLightsOverview?: Maybe<IStockAgeCcTrafficLightsOverview>;
  stockCoverage: IStockCoverageCrosscheckData;
  stockCoverageCcTrafficLightsOverview?: Maybe<IStockCoverageCcTrafficLightsOverview>;
}

export interface IQueryApprovalStatusBySeriesArgs {
  pathId: Array<Scalars['ID']>;
  scenarioId: Scalars['ID'];
}

export interface IQueryBrandApprovalStatusArgs {
  brand: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['ID'];
}

export interface IQueryCommentsArgs {
  filterId: Scalars['ID'];
}

export interface IQueryDiscountArgs {
  id: Scalars['ID'];
}

export interface IQueryDiscountDataByFilterIdsArgs {
  discountId: Scalars['String'];
  filterIds: Array<Scalars['String']>;
  market: Scalars['String'];
}

export interface IQueryDiscountDraftsArgs {
  nextToken?: InputMaybe<Scalars['String']>;
  userId: Scalars['String'];
}

export interface IQueryDiscountPointsByDiscountIdTotalArgs {
  discountId: Scalars['ID'];
}

export interface IQueryDiscountsArgs {
  brands: Array<Scalars['String']>;
  market: Scalars['String'];
  nextToken?: InputMaybe<Scalars['String']>;
}

export interface IQueryDiscountsMatrixColumnsArgs {
  market: Scalars['String'];
}

export interface IQueryDownloadScenarioDataByFilterIdsArgs {
  filterIds: Array<Scalars['String']>;
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQueryFiltersArgs {
  brands: Array<Scalars['String']>;
  market: Scalars['String'];
}

export interface IQueryMarketsCcTrafficLightsOverviewArgs {
  context: CrossCheckContextType;
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQueryMarketsCrossChecksArgs {
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQueryMatrixColumnFormatsArgs {
  market: Scalars['String'];
}

export interface IQueryMatrixColumnsCustomOrderArgs {
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQueryNotesArgs {
  filterId: Scalars['ID'];
  scenarioId: Scalars['ID'];
}

export interface IQueryPhModelCodesBySeriesArgs {
  pathId: Array<Scalars['String']>;
}

export interface IQueryPortfolioInternalPriceArgs {
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQueryPortfolioInternalPriceCcTrafficLightsOverviewArgs {
  context: CrossCheckContextType;
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQueryPriceFilledFilterIdsArgs {
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQueryPricePointsArgs {
  filterId?: InputMaybe<Scalars['String']>;
  scenarioId: Scalars['String'];
}

export interface IQueryProductDataArgs {
  market: Scalars['String'];
}

export interface IQuerySalesPipelineCcTrafficLightsOverviewArgs {
  context: CrossCheckContextType;
  crossCheckType: SalesPipelineCrossCheckType;
  dateRange?: InputMaybe<ISalesPipelineDateRange>;
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQuerySalesPipelineCrossChecksArgs {
  crossCheckType: SalesPipelineCrossCheckType;
  dateRange?: InputMaybe<ISalesPipelineDateRange>;
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQueryScenarioDataByFilterIdsArgs {
  filterIds: Array<Scalars['String']>;
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}

export interface IQueryScenariosArgs {
  scenarioId?: InputMaybe<Scalars['String']>;
  scenarioName?: InputMaybe<Scalars['String']>;
}

export interface IQueryStockAgeArgs {
  accessible?: InputMaybe<Accessible>;
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  stockPriceLevel?: InputMaybe<StockPriceLevel>;
}

export interface IQueryStockAgeCcTrafficLightsOverviewArgs {
  accessible?: InputMaybe<Accessible>;
  context: CrossCheckContextType;
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  stockPriceLevel?: InputMaybe<StockPriceLevel>;
}

export interface IQueryStockCoverageArgs {
  accessible?: InputMaybe<Accessible>;
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  stockPriceLevel?: InputMaybe<StockPriceLevel>;
}

export interface IQueryStockCoverageCcTrafficLightsOverviewArgs {
  accessible?: InputMaybe<Accessible>;
  context: CrossCheckContextType;
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  stockPriceLevel?: InputMaybe<StockPriceLevel>;
}

export enum SalesChannels {
  Nc = 'NC',
  Yuc = 'YUC',
}

export interface ISalesPipelineCcTrafficLightsOverview {
  data?: Maybe<ISalesPipelineCcTrafficLightsOverviewData>;
}

export interface ISalesPipelineCcTrafficLightsOverviewData {
  trafficLight?: Maybe<ITrafficLight>;
}

export interface ISalesPipelineCrossCheckData {
  data?: Maybe<ISalesPipelineCrossCheckResult>;
}

export type ISalesPipelineCrossCheckResult = ISalesPipelineOrderIntakeData | ISalesPipelineRetailData;

export enum SalesPipelineCrossCheckType {
  OrderIntake = 'ORDER_INTAKE',
  Retail = 'RETAIL',
}

export interface ISalesPipelineDateRange {
  endDate: Scalars['String'];
  frequency: SalesPipelineFrequency;
  startDate: Scalars['String'];
}

export enum SalesPipelineFrequency {
  D = 'D',
  M = 'M',
  ThreeMonth = 'THREE_MONTH',
  TwoMonth = 'TWO_MONTH',
  Y = 'Y',
}

export interface ISalesPipelineOpenVolumeYe {
  displayName: Scalars['String'];
  displayNameDetails: Scalars['String'];
  ratioRetailOpenVolumeBudget?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
}

export interface ISalesPipelineOrderIntakeData {
  frequency?: Maybe<Array<Scalars['String']>>;
  scalar?: Maybe<ISalesPipelineOrderIntakeScalar>;
  timeSeries?: Maybe<Array<Maybe<ISalesPipelineOrderIntakeTimeSeries>>>;
  timeSeriesProperties: Array<Scalars['String']>;
  trafficLight?: Maybe<ITrafficLight>;
  trafficLightAdj?: Maybe<ITrafficLight>;
}

export interface ISalesPipelineOrderIntakeScalar {
  openVolumeYe?: Maybe<ISalesPipelineOpenVolumeYe>;
  openVolumeYeRatio?: Maybe<Scalars['Int']>;
}

export interface ISalesPipelineOrderIntakeTimeSeries extends ISalesPipelineTimeSeries {
  actualsPreviousYear?: Maybe<Scalars['Int']>;
  actualsThisYear?: Maybe<Scalars['Int']>;
  date: Scalars['AWSDate'];
  enginePredictedOrderIntakeVolume?: Maybe<Scalars['Int']>;
  enginePredictedOrderIntakeVolumeAdj?: Maybe<Scalars['Int']>;
  orderIntakeBudget?: Maybe<Scalars['Int']>;
  orderIntakeLatestForecast?: Maybe<Scalars['Int']>;
  orderIntakeSecondLatestForecast?: Maybe<Scalars['Int']>;
}

export interface ISalesPipelineRetailData {
  frequency?: Maybe<Array<Scalars['String']>>;
  scalar?: Maybe<ISalesPipelineRetailScalar>;
  timeSeries?: Maybe<Array<Maybe<ISalesPipelineRetailTimeSeries>>>;
  timeSeriesProperties: Array<Scalars['String']>;
  trafficLight?: Maybe<ITrafficLight>;
  trafficLightAdj?: Maybe<ITrafficLight>;
}

export interface ISalesPipelineRetailScalar {
  absDiffCumulatedBudget?: Maybe<Scalars['Int']>;
  absDiffExpectedVolumeBudget?: Maybe<Scalars['Int']>;
  budgetVolumeRetail?: Maybe<Scalars['Int']>;
  expectedVolumeAchievement?: Maybe<Scalars['Int']>;
  expectedVolumeRetail?: Maybe<Scalars['Int']>;
  openVolumeYe?: Maybe<ISalesPipelineOpenVolumeYe>;
  ratioCumulatedBudget?: Maybe<Scalars['Int']>;
  ratioExpectedRetailBudget?: Maybe<Scalars['Int']>;
  ratioExpectedVolumeBudget?: Maybe<Scalars['Int']>;
  retailBudgetScalar?: Maybe<Scalars['Int']>;
  retailCount?: Maybe<Scalars['Int']>;
}

export interface ISalesPipelineRetailTimeSeries extends ISalesPipelineTimeSeries {
  actualsPreviousYear?: Maybe<Scalars['Int']>;
  actualsThisYear?: Maybe<Scalars['Int']>;
  date: Scalars['AWSDate'];
  enginePredictedRetailVolume?: Maybe<Scalars['Int']>;
  expectedVolumeRetailAdjTS?: Maybe<Scalars['Int']>;
  retailBudget?: Maybe<Scalars['Int']>;
  retailLatestForecast?: Maybe<Scalars['Int']>;
  retailSecondLatestForecast?: Maybe<Scalars['Int']>;
}

export interface ISalesPipelineTimeSeries {
  actualsPreviousYear?: Maybe<Scalars['Int']>;
  actualsThisYear?: Maybe<Scalars['Int']>;
  date: Scalars['AWSDate'];
}

export interface ISalesPipelineTimeSeriesValue {
  date: Scalars['String'];
  value: Scalars['Int'];
}

export interface IScalarData {
  medianAgeCurrentMonth?: Maybe<Scalars['Int']>;
  medianAgeDiff?: Maybe<Scalars['Int']>;
  over180PercentageDiff?: Maybe<Scalars['Int']>;
  percentageCurrentMonth?: Maybe<Scalars['Int']>;
}

export interface IScenario {
  creationDate: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  kpiCalculatedDate?: Maybe<Scalars['AWSDateTime']>;
  lastUpdatedDate?: Maybe<Scalars['AWSDateTime']>;
  predictionComponentURI?: Maybe<Scalars['String']>;
  scenarioName: Scalars['String'];
  scenarioType: ScenarioType;
  status?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
}

export interface IScenarioDataWithPricePoint {
  adjustedCmPerUnit?: Maybe<Scalars['Int']>;
  adjustedListPriceReasonCode?: Maybe<Scalars['String']>;
  budgetCmPerUnit?: Maybe<Scalars['Int']>;
  budgetNextYearCmPerUnit?: Maybe<Scalars['Int']>;
  budgetVolumeRetail?: Maybe<Scalars['Int']>;
  budgetVolumeRetailNextYear?: Maybe<Scalars['Int']>;
  channelsTrafficLight?: Maybe<Scalars['AWSJSON']>;
  channelsTrafficLightAdj?: Maybe<Scalars['AWSJSON']>;
  co2BaseCar?: Maybe<Scalars['String']>;
  comments: Array<IComment>;
  competitionTrafficLight?: Maybe<Scalars['AWSJSON']>;
  competitionTrafficLightAdj?: Maybe<Scalars['AWSJSON']>;
  currentCmPerUnit?: Maybe<Scalars['Int']>;
  currentCmPerUnitOrdersCurrency?: Maybe<Scalars['String']>;
  deltaCmOrdersAbsCurrency?: Maybe<Scalars['String']>;
  deltaCmPerUnit?: Maybe<Scalars['String']>;
  deltaCurrentIndicativeLeaseRate?: Maybe<Scalars['String']>;
  deltaCurrentPriceEditor?: Maybe<Scalars['String']>;
  deltaCurrentPriceEngineAbs?: Maybe<Scalars['Float']>;
  deltaExpectedVolumeRetailCurrentAdj?: Maybe<Scalars['String']>;
  deltaTotalCmAbs?: Maybe<Scalars['Int']>;
  effectiveSince?: Maybe<Scalars['AWSDateTime']>;
  expectedVolumeRetail?: Maybe<Scalars['Int']>;
  expectedVolumeRetailAdj?: Maybe<Scalars['Int']>;
  filterId: Scalars['ID'];
  forecast1CmPerUnit?: Maybe<Scalars['Int']>;
  forecast2CmPerUnit?: Maybe<Scalars['Int']>;
  forecastLatestCmPerUnit?: Maybe<Scalars['Int']>;
  forecastVolumeRetail?: Maybe<Scalars['Int']>;
  governancePricePointId?: Maybe<Scalars['String']>;
  governancePricePointStatus?: Maybe<Scalars['String']>;
  indicativeLeaseRate?: Maybe<Scalars['Float']>;
  indicativeLeaseRateAdj?: Maybe<Scalars['Float']>;
  indicativeLeaseRateCurrency?: Maybe<Scalars['String']>;
  indicativeLeaseRateEngine?: Maybe<Scalars['Float']>;
  indicativeLeaseRateMetaData?: Maybe<Scalars['AWSJSON']>;
  listPriceExclTax?: Maybe<Scalars['Float']>;
  listPriceExclTaxAdj?: Maybe<Scalars['Float']>;
  listPriceExclTaxCurrency?: Maybe<Scalars['String']>;
  listPriceInclTax?: Maybe<Scalars['Float']>;
  listPriceInclTaxCurrency?: Maybe<Scalars['String']>;
  listPriceInclTaxFuture?: Maybe<Scalars['Float']>;
  listPriceInclTaxMetaData?: Maybe<Scalars['AWSJSON']>;
  marketsTrafficLight?: Maybe<Scalars['AWSJSON']>;
  marketsTrafficLightAdj?: Maybe<Scalars['AWSJSON']>;
  microMacroTrafficLight?: Maybe<Scalars['AWSJSON']>;
  microMacroTrafficLightAdj?: Maybe<Scalars['AWSJSON']>;
  netPriceBaseCar?: Maybe<Scalars['Float']>;
  netPriceBaseCarCurrency?: Maybe<Scalars['String']>;
  notes: Array<INote>;
  orderForecastMonth?: Maybe<Scalars['Float']>;
  portfolioTrafficLight?: Maybe<Scalars['AWSJSON']>;
  portfolioTrafficLightAdj?: Maybe<Scalars['AWSJSON']>;
  predictionEvaluationIndicator?: Maybe<Scalars['Int']>;
  priceChangeCount?: Maybe<Scalars['Int']>;
  pricePoint?: Maybe<IPricePoint>;
  pricePointEffectiveDate?: Maybe<Scalars['AWSDateTime']>;
  pricePointPrice?: Maybe<Scalars['Float']>;
  pricePointPriceCurrency?: Maybe<Scalars['String']>;
  productionPipelineTrafficLight?: Maybe<Scalars['AWSJSON']>;
  productionPipelineTrafficLightAdj?: Maybe<Scalars['AWSJSON']>;
  recommendedPriceEngine?: Maybe<Scalars['Float']>;
  recommendedPriceMetaData?: Maybe<Scalars['AWSJSON']>;
  recommendedRetailPrice?: Maybe<Scalars['Float']>;
  recommendedRetailPriceReasonCode?: Maybe<Scalars['String']>;
  recommendedRetailPriceReasonCodeMetaData?: Maybe<Scalars['AWSJSON']>;
  recommendedRetailPriceValidFromDate?: Maybe<Scalars['AWSDateTime']>;
  retailVolumeAchievementPct?: Maybe<Scalars['Float']>;
  retailVolumeActualAbs?: Maybe<Scalars['Int']>;
  salesPipelineTrafficLight?: Maybe<Scalars['AWSJSON']>;
  salesPipelineTrafficLightAdj?: Maybe<Scalars['AWSJSON']>;
  scenarioId: Scalars['ID'];
  stockTrafficLight?: Maybe<Scalars['AWSJSON']>;
  stockTrafficLightAdj?: Maybe<Scalars['AWSJSON']>;
  summaryTrafficLight?: Maybe<Scalars['AWSJSON']>;
  summaryTrafficLightAdj?: Maybe<Scalars['AWSJSON']>;
  targetCmPerUnitOrdersCurrency?: Maybe<Scalars['String']>;
  taxChangeValidFromDate?: Maybe<Scalars['AWSDateTime']>;
  typicallyEquippedCurrentCmPerUnit?: Maybe<Scalars['Int']>;
  typicallyEquippedLeaseRate?: Maybe<Scalars['Float']>;
  typicallyEquippedLeaseRateAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedLeaseRateEngine?: Maybe<Scalars['Float']>;
  typicallyEquippedPrice?: Maybe<Scalars['Float']>;
  typicallyEquippedPriceAdj?: Maybe<Scalars['Float']>;
  typicallyEquippedPriceCurrency?: Maybe<Scalars['String']>;
  typicallyEquippedPriceEngine?: Maybe<Scalars['Float']>;
}

export enum ScenarioType {
  System = 'SYSTEM',
  User = 'USER',
}

export interface ISeriesWithModelCodes {
  market: Scalars['String'];
  modelCodePathIds: Array<Scalars['String']>;
  pathId: Scalars['String'];
  series: Scalars['String'];
}

export interface IStockAgeCcTrafficLightsOverview {
  data?: Maybe<IStockAgeCcTrafficLightsOverviewData>;
}

export interface IStockAgeCcTrafficLightsOverviewData {
  trafficLight?: Maybe<ITrafficLight>;
}

export interface IStockAgeCrosscheckData {
  calculationDate?: Maybe<Scalars['AWSDate']>;
  historic?: Maybe<Array<Maybe<IHistoricData>>>;
  scalar?: Maybe<Array<Maybe<IScalarData>>>;
  stockAge?: Maybe<Array<Maybe<IStockAgeData>>>;
}

export interface IStockAgeData {
  countPerBucket0_10Days?: Maybe<Scalars['Int']>;
  countPerBucket11_30Days?: Maybe<Scalars['Int']>;
  countPerBucket31_90Days?: Maybe<Scalars['Int']>;
  countPerBucket91_180Days?: Maybe<Scalars['Int']>;
  countPerBucket181_270Days?: Maybe<Scalars['Int']>;
  countPerBucket271_365Days?: Maybe<Scalars['Int']>;
  countPerBucket365Days?: Maybe<Scalars['Int']>;
  eSeries?: Maybe<Scalars['String']>;
  eSeriesCount?: Maybe<Scalars['Int']>;
  eSeriesPercentage0_10Days?: Maybe<Scalars['Int']>;
  eSeriesPercentage11_30Days?: Maybe<Scalars['Int']>;
  eSeriesPercentage31_90Days?: Maybe<Scalars['Int']>;
  eSeriesPercentage91_180Days?: Maybe<Scalars['Int']>;
  eSeriesPercentage181_270Days?: Maybe<Scalars['Int']>;
  eSeriesPercentage271_365Days?: Maybe<Scalars['Int']>;
  eSeriesPercentage365Days?: Maybe<Scalars['Int']>;
}

export interface IStockCoverage {
  date: Scalars['AWSDateTime'];
  idealStockCoverageTS?: Maybe<Scalars['Float']>;
  stockCoverageForecastTS?: Maybe<Scalars['Float']>;
  stockCoverageForecastTSAdj?: Maybe<Scalars['Float']>;
  stockCoverageTS?: Maybe<Scalars['Float']>;
  stockCoverageTSAdj?: Maybe<Scalars['Float']>;
}

export interface IStockCoverageCcTrafficLightsOverview {
  data?: Maybe<IStockCoverageCcTrafficLightsOverviewData>;
}

export interface IStockCoverageCcTrafficLightsOverviewData {
  trafficLight?: Maybe<ITrafficLight>;
}

export interface IStockCoverageCrosscheckData {
  scalar?: Maybe<IStockCoverageScalar>;
  stockCoverage?: Maybe<Array<Maybe<IStockCoverage>>>;
  stockUnits?: Maybe<Array<Maybe<IStockUnits>>>;
  tooltipText?: Maybe<IStockCoverageTooltipText>;
}

export interface IStockCoverageScalar {
  currentActualStockUnits?: Maybe<Scalars['Float']>;
  currentStockCoverage?: Maybe<Scalars['Float']>;
  deltaCurrentStockCoverageToISP?: Maybe<Scalars['Float']>;
  deltaCurrentToSoYActualStockUnits?: Maybe<Scalars['Float']>;
  listPriceInclTax?: Maybe<Scalars['Float']>;
  listPriceInclTaxAdjFilled?: Maybe<Scalars['Int']>;
}

export interface IStockCoverageTooltipText {
  stockCoverage?: Maybe<Scalars['String']>;
  stockUnits?: Maybe<Scalars['String']>;
}

export enum StockPriceLevel {
  FirstPriceLevel = 'FIRST_PRICE_LEVEL',
  SecondPriceLevel = 'SECOND_PRICE_LEVEL',
}

export interface IStockUnits {
  date: Scalars['AWSDateTime'];
  stockUnitsActualsTS?: Maybe<Scalars['Int']>;
  stockUnitsForecastTS?: Maybe<Scalars['Int']>;
  stockUnitsForecastTSAdj?: Maybe<Scalars['Int']>;
}

export interface ISubmitDiscountInput {
  id: Scalars['ID'];
}

export interface ISubmitPricePointsResponse {
  errors: Array<IPricePointOperationError>;
  results: Array<IPricePoint>;
}

export interface ITrafficLight {
  arrow?: Maybe<Scalars['Int']>;
  color?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
}

export interface IUpdateDiscountInput {
  absoluteDiscountInclTaxRange?: InputMaybe<Scalars['String']>;
  absoluteDiscountNetRange?: InputMaybe<Scalars['String']>;
  applicationLevel: ApplicationLevel;
  approvalStatus?: InputMaybe<DiscountApprovalStatus>;
  benefitType: BenefitType;
  brand: Scalars['String'];
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  discountCategory: DiscountCategory;
  discountType: DiscountType;
  endDate?: InputMaybe<Scalars['AWSDateTime']>;
  id: Scalars['String'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  market: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  relativeDiscountInclTaxRange?: InputMaybe<Scalars['String']>;
  relativeDiscountNetRange?: InputMaybe<Scalars['String']>;
  salesChannels: Array<SalesChannels>;
  startDate?: InputMaybe<Scalars['AWSDateTime']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  userId?: InputMaybe<Scalars['String']>;
}

export interface IUpdateDiscountPointInput {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  deletedAt?: InputMaybe<Scalars['AWSDateTime']>;
  discountId: Scalars['ID'];
  filterId: Scalars['ID'];
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  market?: InputMaybe<Scalars['String']>;
  modelCode?: InputMaybe<Scalars['String']>;
  updatedAt?: InputMaybe<Scalars['AWSDateTime']>;
  value?: InputMaybe<Scalars['Float']>;
}

export interface IUpdateScenariosInput {
  kpiCalculatedDate?: InputMaybe<Scalars['AWSDateTime']>;
  predictionComponentURI?: InputMaybe<Scalars['String']>;
  scenarioName?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
}

export interface IWebVitalsInput {
  type: WebVitalsType;
  userId?: InputMaybe<Scalars['String']>;
  value: Scalars['Float'];
}

export interface IWebVitalsResponse {
  response: Scalars['String'];
}

export enum WebVitalsType {
  Cls = 'CLS',
  Fid = 'FID',
  Lcp = 'LCP',
}

export type ISubmitPricePointsMutationVariables = Exact<{
  scenarioId: Scalars['String'];
  pricePoints: Array<IPricePointInputForSubmit> | IPricePointInputForSubmit;
  isOverride: Scalars['Boolean'];
}>;

export type ISubmitPricePointsMutation = {
  submitPricePoints: {
    errors: Array<{
      errorType?: string | null | undefined;
      message?: string | null | undefined;
      pricePoint: {
        active?: boolean | null | undefined;
        approvalStatus?: string | null | undefined;
        author?: string | null | undefined;
        creationDate?: string | null | undefined;
        currency?: string | null | undefined;
        effectiveDate?: string | null | undefined;
        endDate?: string | null | undefined;
        filterId?: string | null | undefined;
        lastUpdateDate?: string | null | undefined;
        id?: string | null | undefined;
        price?: number | null | undefined;
        scenarioId?: string | null | undefined;
      };
    }>;
    results: Array<{
      active?: boolean | null | undefined;
      approvalStatus?: ApprovalStatus | null | undefined;
      author: string;
      creationDate?: string | null | undefined;
      scenarioId: string;
      price?: number | null | undefined;
      lastUpdateDate?: string | null | undefined;
      id: string;
      filterId: string;
      endDate?: string | null | undefined;
      effectiveDate?: string | null | undefined;
      currency?: string | null | undefined;
    }>;
  };
};

export type ICreateUpdatePricePointMutationVariables = Exact<{
  scenarioId: Scalars['String'];
  pricePoints: Array<IPricePointInput> | IPricePointInput;
}>;

export type ICreateUpdatePricePointMutation = {
  createUpdatePricePoint: Array<
    | {
        active?: boolean | null | undefined;
        approvalStatus?: ApprovalStatus | null | undefined;
        author: string;
        creationDate?: string | null | undefined;
        currency?: string | null | undefined;
        effectiveDate?: string | null | undefined;
        endDate?: string | null | undefined;
        filterId: string;
        id: string;
        lastUpdateDate?: string | null | undefined;
        price?: number | null | undefined;
        scenarioId: string;
      }
    | null
    | undefined
  >;
};

export type IPromotePricePointMutationVariables = Exact<{
  pricePointId: Scalars['ID'];
  scenarioId: Scalars['ID'];
  expectedCurrentStatus: PromotePricePointStatus;
}>;

export type IPromotePricePointMutation = {
  promotePricePoint: {
    active?: boolean | null | undefined;
    approvalStatus?: ApprovalStatus | null | undefined;
    author: string;
    creationDate?: string | null | undefined;
    currency?: string | null | undefined;
    effectiveDate?: string | null | undefined;
    endDate?: string | null | undefined;
    filterId: string;
    id: string;
    lastUpdateDate?: string | null | undefined;
    price?: number | null | undefined;
    scenarioId: string;
  };
};

export type IRejectPricePointMutationVariables = Exact<{
  pricePointId: Scalars['ID'];
  expectedCurrentStatus: ApprovalStatus;
  reason?: InputMaybe<Scalars['String']>;
}>;

export type IRejectPricePointMutation = {
  rejectPricePoint: {
    active?: boolean | null | undefined;
    approvalStatus?: ApprovalStatus | null | undefined;
    author: string;
    creationDate?: string | null | undefined;
    currency?: string | null | undefined;
    effectiveDate?: string | null | undefined;
    endDate?: string | null | undefined;
    filterId: string;
    id: string;
    lastUpdateDate?: string | null | undefined;
    price?: number | null | undefined;
    scenarioId: string;
  };
};

export type IWithdrawPricePointMutationVariables = Exact<{
  pricePointId: Scalars['ID'];
}>;

export type IWithdrawPricePointMutation = {
  withdrawPricePoint: {
    active?: boolean | null | undefined;
    approvalStatus?: ApprovalStatus | null | undefined;
    author: string;
    creationDate?: string | null | undefined;
    currency?: string | null | undefined;
    effectiveDate?: string | null | undefined;
    endDate?: string | null | undefined;
    filterId: string;
    id: string;
    lastUpdateDate?: string | null | undefined;
    price?: number | null | undefined;
    scenarioId: string;
  };
};

export type IApproveBrandPricePointsMutationVariables = Exact<{
  scenarioId: Scalars['ID'];
  brand: Scalars['String'];
  market: Scalars['String'];
}>;

export type IApproveBrandPricePointsMutation = {
  approveBrandPricePoints: Array<{
    active?: boolean | null | undefined;
    approvalStatus?: ApprovalStatus | null | undefined;
    author: string;
    creationDate?: string | null | undefined;
    currency?: string | null | undefined;
    effectiveDate?: string | null | undefined;
    endDate?: string | null | undefined;
    filterId: string;
    id: string;
    lastUpdateDate?: string | null | undefined;
    price?: number | null | undefined;
    scenarioId: string;
  }>;
};

export type IAddCommentMutationVariables = Exact<{
  filterId: Scalars['ID'];
  text: Scalars['String'];
  pricePointId: Scalars['ID'];
}>;

export type IAddCommentMutation = { addComment: { id: string; text: string; userId: string; createdAt: string } };

export type IAddNoteMutationVariables = Exact<{
  filterId: Scalars['ID'];
  scenarioId: Scalars['ID'];
  text: Scalars['String'];
  pricePointId: Scalars['ID'];
}>;

export type IAddNoteMutation = {
  addNote: {
    filterId: string;
    id: string;
    scenarioId: string;
    text: string;
    userId: string;
    userName: string;
    createdAt: string;
  };
};

export type IBulkEditPricePointsMutationVariables = Exact<{
  pricePoints: Array<IPricePointEditInput> | IPricePointEditInput;
  scenarioId: Scalars['ID'];
  task: EditPricePointTask;
}>;

export type IBulkEditPricePointsMutation = {
  bulkEditPricePoints: Array<{
    active?: boolean | null | undefined;
    approvalStatus?: ApprovalStatus | null | undefined;
    author: string;
    creationDate?: string | null | undefined;
    currency?: string | null | undefined;
    effectiveDate?: string | null | undefined;
    endDate?: string | null | undefined;
    filterId: string;
    id: string;
    lastUpdateDate?: string | null | undefined;
    price?: number | null | undefined;
    scenarioId: string;
  }>;
};

export type IUpdateMatrixColumnsOrderMutationVariables = Exact<{
  matrixColumnsOrder: Array<IMatrixColumnOrderInput> | IMatrixColumnOrderInput;
}>;

export type IUpdateMatrixColumnsOrderMutation = {
  updateMatrixColumnsOrder: Array<{
    id: string;
    columnType: ColumnType;
    displayName: string;
    isNotCollapsible?: boolean | null | undefined;
    isVisible?: boolean | null | undefined;
    isSticky?: boolean | null | undefined;
    sectionKey?: string | null | undefined;
    parentId?: string | null | undefined;
    position: number;
    technicalName?: string | null | undefined;
  }>;
};

export type IApproveSeriesPricePointsMutationVariables = Exact<{
  scenarioId: Scalars['ID'];
  pathId: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type IApproveSeriesPricePointsMutation = {
  approveSeriesPricePoints: Array<
    | {
        pathId?: string | null | undefined;
        market?: string | null | undefined;
        series?: string | null | undefined;
        pricePoints?:
          | Array<
              | {
                  id: string;
                  scenarioId: string;
                  filterId: string;
                  price?: number | null | undefined;
                  netPrice?: number | null | undefined;
                  currency?: string | null | undefined;
                  effectiveDate?: string | null | undefined;
                  endDate?: string | null | undefined;
                  approvalStatus?: ApprovalStatus | null | undefined;
                  active?: boolean | null | undefined;
                  creationDate?: string | null | undefined;
                  lastUpdateDate?: string | null | undefined;
                  author: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type IReleaseSeriesPricePointsMutationVariables = Exact<{
  scenarioId: Scalars['ID'];
  pathId: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type IReleaseSeriesPricePointsMutation = {
  releaseSeriesPricePoints: Array<
    | {
        pathId?: string | null | undefined;
        market?: string | null | undefined;
        series?: string | null | undefined;
        pricePoints?:
          | Array<
              | {
                  id: string;
                  scenarioId: string;
                  filterId: string;
                  price?: number | null | undefined;
                  netPrice?: number | null | undefined;
                  currency?: string | null | undefined;
                  effectiveDate?: string | null | undefined;
                  endDate?: string | null | undefined;
                  approvalStatus?: ApprovalStatus | null | undefined;
                  active?: boolean | null | undefined;
                  creationDate?: string | null | undefined;
                  lastUpdateDate?: string | null | undefined;
                  author: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type IWebVitalsMutationVariables = Exact<{
  webVitals: IWebVitalsInput;
}>;

export type IWebVitalsMutation = { webVitals: { response: string } };

export type IAddDiscountsMutationVariables = Exact<{
  market: Scalars['String'];
  brand: Scalars['String'];
  name?: InputMaybe<Scalars['String']>;
  benefitType: BenefitType;
  applicationLevel: ApplicationLevel;
  discountCategory: DiscountCategory;
  discountType: DiscountType;
  salesChannels: Array<SalesChannels> | SalesChannels;
  startDate?: InputMaybe<Scalars['AWSDateTime']>;
  endDate?: InputMaybe<Scalars['AWSDateTime']>;
  userId: Scalars['String'];
}>;

export type IAddDiscountsMutation = {
  addDiscount: { id: string; userId?: string | null | undefined; market: string; brand: string };
};

export type ISubmitDiscountMutationVariables = Exact<{
  input: ISubmitDiscountInput;
}>;

export type ISubmitDiscountMutation = {
  submitDiscount: {
    absoluteDiscountInclTaxRange?: string | null | undefined;
    absoluteDiscountNetRange?: string | null | undefined;
    benefitType: BenefitType;
    applicationLevel: ApplicationLevel;
    createdAt?: string | null | undefined;
    discountType: DiscountType;
    discountCategory: DiscountCategory;
    brand: string;
    endDate?: string | null | undefined;
    isActive?: boolean | null | undefined;
    id: string;
    market: string;
    name?: string | null | undefined;
    relativeDiscountInclTaxRange?: string | null | undefined;
    relativeDiscountNetRange?: string | null | undefined;
    startDate?: string | null | undefined;
    salesChannels: Array<SalesChannels>;
    updatedAt?: string | null | undefined;
    approvalStatus?: DiscountApprovalStatus | null | undefined;
    userId?: string | null | undefined;
  };
};

export type IFiltersQueryVariables = Exact<{
  market: Scalars['String'];
  brands: Array<Scalars['String']> | Scalars['String'];
}>;

export type IFiltersQuery = {
  filters: Array<{
    id: string;
    market: string;
    brand: string;
    eSeries: string;
    model: string;
    modelCode: string;
    powertrain: string;
    segment: string;
    series: string;
    level?: string | null | undefined;
    posInPath?: number | null | undefined;
  }>;
};

export type ISalesPipelineTrafficLightsQueryVariables = Exact<{
  scenarioId: Scalars['String'];
  filterIds: Array<Scalars['String']> | Scalars['String'];
  market: Scalars['String'];
}>;

export type ISalesPipelineTrafficLightsQuery = {
  scenarioDataByFilterIds: Array<{
    salesPipelineTrafficLight?: string | null | undefined;
    salesPipelineTrafficLightAdj?: string | null | undefined;
  }>;
};

export type IStockTrafficLightsQueryVariables = Exact<{
  scenarioId: Scalars['String'];
  filterIds: Array<Scalars['String']> | Scalars['String'];
  market: Scalars['String'];
}>;

export type IStockTrafficLightsQuery = {
  scenarioDataByFilterIds: Array<{
    stockTrafficLight?: string | null | undefined;
    stockTrafficLightAdj?: string | null | undefined;
  }>;
};

export type IMarketsTrafficLightsQueryVariables = Exact<{
  scenarioId: Scalars['String'];
  filterIds: Array<Scalars['String']> | Scalars['String'];
  market: Scalars['String'];
}>;

export type IMarketsTrafficLightsQuery = {
  scenarioDataByFilterIds: Array<{
    marketsTrafficLight?: string | null | undefined;
    marketsTrafficLightAdj?: string | null | undefined;
  }>;
};

export type IPortfolioTrafficLightsQueryVariables = Exact<{
  scenarioId: Scalars['String'];
  filterIds: Array<Scalars['String']> | Scalars['String'];
  market: Scalars['String'];
}>;

export type IPortfolioTrafficLightsQuery = {
  scenarioDataByFilterIds: Array<{
    portfolioTrafficLight?: string | null | undefined;
    portfolioTrafficLightAdj?: string | null | undefined;
  }>;
};

export type ISalesPipelineCcTrafficLightsOverviewQueryVariables = Exact<{
  market: Scalars['String'];
  filterId: Scalars['String'];
  scenarioId: Scalars['String'];
  dateRange?: InputMaybe<ISalesPipelineDateRange>;
  crossCheckType: SalesPipelineCrossCheckType;
  context: CrossCheckContextType;
}>;

export type ISalesPipelineCcTrafficLightsOverviewQuery = {
  salesPipelineCcTrafficLightsOverview: {
    data?:
      | {
          trafficLight?:
            | {
                arrow?: number | null | undefined;
                color?: string | null | undefined;
                weight?: number | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type IPortfolioInternalPriceCcTrafficLightsOverviewQueryVariables = Exact<{
  market: Scalars['String'];
  filterId: Scalars['String'];
  scenarioId: Scalars['String'];
  context: CrossCheckContextType;
}>;

export type IPortfolioInternalPriceCcTrafficLightsOverviewQuery = {
  portfolioInternalPriceCcTrafficLightsOverview: {
    data?:
      | {
          trafficLight?:
            | {
                arrow?: number | null | undefined;
                color?: string | null | undefined;
                weight?: number | null | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type IMarketsCcTrafficLightsOverviewQueryVariables = Exact<{
  market: Scalars['String'];
  filterId: Scalars['String'];
  scenarioId: Scalars['String'];
  context: CrossCheckContextType;
}>;

export type IMarketsCcTrafficLightsOverviewQuery = {
  marketsCcTrafficLightsOverview?:
    | {
        data?:
          | {
              trafficLight?:
                | {
                    arrow?: number | null | undefined;
                    color?: string | null | undefined;
                    weight?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IStockCoverageCcTrafficLightsOverviewQueryVariables = Exact<{
  market: Scalars['String'];
  filterId: Scalars['String'];
  scenarioId: Scalars['String'];
  context: CrossCheckContextType;
  accessible: Accessible;
  stockPriceLevel: StockPriceLevel;
}>;

export type IStockCoverageCcTrafficLightsOverviewQuery = {
  stockCoverageCcTrafficLightsOverview?:
    | {
        data?:
          | {
              trafficLight?:
                | {
                    arrow?: number | null | undefined;
                    color?: string | null | undefined;
                    weight?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IStockAgeCcTrafficLightsOverviewQueryVariables = Exact<{
  market: Scalars['String'];
  filterId: Scalars['String'];
  scenarioId: Scalars['String'];
  context: CrossCheckContextType;
  accessible: Accessible;
  stockPriceLevel: StockPriceLevel;
}>;

export type IStockAgeCcTrafficLightsOverviewQuery = {
  stockAgeCcTrafficLightsOverview?:
    | {
        data?:
          | {
              trafficLight?:
                | {
                    arrow?: number | null | undefined;
                    color?: string | null | undefined;
                    weight?: number | null | undefined;
                  }
                | null
                | undefined;
            }
          | null
          | undefined;
      }
    | null
    | undefined;
};

export type IScenarioDataByFilterIdsQueryVariables = Exact<{
  scenarioId: Scalars['String'];
  filterIds: Array<Scalars['String']> | Scalars['String'];
  market: Scalars['String'];
}>;

export type IScenarioDataByFilterIdsQuery = {
  scenarioDataByFilterIds: Array<{
    adjustedListPriceReasonCode?: string | null | undefined;
    adjustedCmPerUnit?: number | null | undefined;
    budgetVolumeRetail?: number | null | undefined;
    budgetNextYearCmPerUnit?: number | null | undefined;
    budgetCmPerUnit?: number | null | undefined;
    budgetVolumeRetailNextYear?: number | null | undefined;
    co2BaseCar?: string | null | undefined;
    currentCmPerUnit?: number | null | undefined;
    deltaCmPerUnit?: string | null | undefined;
    deltaTotalCmAbs?: number | null | undefined;
    deltaCurrentPriceEngineAbs?: number | null | undefined;
    deltaCurrentPriceEditor?: string | null | undefined;
    deltaCurrentIndicativeLeaseRate?: string | null | undefined;
    deltaExpectedVolumeRetailCurrentAdj?: string | null | undefined;
    expectedVolumeRetailAdj?: number | null | undefined;
    expectedVolumeRetail?: number | null | undefined;
    effectiveSince?: string | null | undefined;
    filterId: string;
    predictionEvaluationIndicator?: number | null | undefined;
    forecastVolumeRetail?: number | null | undefined;
    forecastLatestCmPerUnit?: number | null | undefined;
    forecast1CmPerUnit?: number | null | undefined;
    forecast2CmPerUnit?: number | null | undefined;
    listPriceExclTaxAdj?: number | null | undefined;
    governancePricePointStatus?: string | null | undefined;
    governancePricePointId?: string | null | undefined;
    indicativeLeaseRate?: number | null | undefined;
    indicativeLeaseRateEngine?: number | null | undefined;
    indicativeLeaseRateAdj?: number | null | undefined;
    indicativeLeaseRateMetaData?: string | null | undefined;
    listPriceExclTax?: number | null | undefined;
    listPriceInclTax?: number | null | undefined;
    listPriceInclTaxMetaData?: string | null | undefined;
    recommendedPriceEngine?: number | null | undefined;
    recommendedRetailPrice?: number | null | undefined;
    recommendedPriceMetaData?: string | null | undefined;
    recommendedRetailPriceValidFromDate?: string | null | undefined;
    recommendedRetailPriceReasonCode?: string | null | undefined;
    retailVolumeActualAbs?: number | null | undefined;
    priceChangeCount?: number | null | undefined;
    pricePointEffectiveDate?: string | null | undefined;
    pricePointPrice?: number | null | undefined;
    retailVolumeAchievementPct?: number | null | undefined;
    scenarioId: string;
    typicallyEquippedLeaseRate?: number | null | undefined;
    typicallyEquippedPriceEngine?: number | null | undefined;
    typicallyEquippedLeaseRateEngine?: number | null | undefined;
    typicallyEquippedPriceAdj?: number | null | undefined;
    typicallyEquippedLeaseRateAdj?: number | null | undefined;
    typicallyEquippedPrice?: number | null | undefined;
    typicallyEquippedCurrentCmPerUnit?: number | null | undefined;
    channelsTrafficLight?: string | null | undefined;
    channelsTrafficLightAdj?: string | null | undefined;
    competitionTrafficLight?: string | null | undefined;
    competitionTrafficLightAdj?: string | null | undefined;
    marketsTrafficLight?: string | null | undefined;
    marketsTrafficLightAdj?: string | null | undefined;
    microMacroTrafficLight?: string | null | undefined;
    microMacroTrafficLightAdj?: string | null | undefined;
    portfolioTrafficLight?: string | null | undefined;
    portfolioTrafficLightAdj?: string | null | undefined;
    productionPipelineTrafficLight?: string | null | undefined;
    productionPipelineTrafficLightAdj?: string | null | undefined;
    salesPipelineTrafficLight?: string | null | undefined;
    salesPipelineTrafficLightAdj?: string | null | undefined;
    stockTrafficLight?: string | null | undefined;
    stockTrafficLightAdj?: string | null | undefined;
    summaryTrafficLight?: string | null | undefined;
    summaryTrafficLightAdj?: string | null | undefined;
    taxChangeValidFromDate?: string | null | undefined;
    listPriceInclTaxFuture?: number | null | undefined;
    pricePoint?:
      | {
          active?: boolean | null | undefined;
          approvalStatus?: ApprovalStatus | null | undefined;
          creationDate?: string | null | undefined;
          currency?: string | null | undefined;
          effectiveDate?: string | null | undefined;
          endDate?: string | null | undefined;
          author: string;
          filterId: string;
          id: string;
          scenarioId: string;
          price?: number | null | undefined;
          lastUpdateDate?: string | null | undefined;
          netPrice?: number | null | undefined;
        }
      | null
      | undefined;
    comments: Array<{
      createdAt: string;
      filterId: string;
      id: string;
      text: string;
      userId: string;
      userName: string;
      pricePointId: string;
    }>;
    notes: Array<{
      createdAt: string;
      filterId: string;
      id: string;
      scenarioId: string;
      text: string;
      userId: string;
      userName: string;
    }>;
  }>;
};

export type IDownloadScenarioDataByFilterIdsQueryVariables = Exact<{
  scenarioId: Scalars['String'];
  filterIds: Array<Scalars['String']> | Scalars['String'];
  market: Scalars['String'];
}>;

export type IDownloadScenarioDataByFilterIdsQuery = {
  downloadScenarioDataByFilterIds: Array<{
    adjustedListPriceReasonCode?: string | null | undefined;
    adjustedCmPerUnit?: number | null | undefined;
    budgetVolumeRetail?: number | null | undefined;
    budgetNextYearCmPerUnit?: number | null | undefined;
    budgetCmPerUnit?: number | null | undefined;
    budgetVolumeRetailNextYear?: number | null | undefined;
    co2BaseCar?: string | null | undefined;
    currentCmPerUnit?: number | null | undefined;
    deltaCmPerUnit?: string | null | undefined;
    deltaTotalCmAbs?: number | null | undefined;
    deltaCurrentPriceEngineAbs?: number | null | undefined;
    deltaCurrentPriceEditor?: string | null | undefined;
    deltaCurrentIndicativeLeaseRate?: string | null | undefined;
    deltaExpectedVolumeRetailCurrentAdj?: string | null | undefined;
    expectedVolumeRetailAdj?: number | null | undefined;
    expectedVolumeRetail?: number | null | undefined;
    effectiveSince?: string | null | undefined;
    filterId: string;
    forecastVolumeRetail?: number | null | undefined;
    forecastLatestCmPerUnit?: number | null | undefined;
    forecast1CmPerUnit?: number | null | undefined;
    forecast2CmPerUnit?: number | null | undefined;
    listPriceExclTaxAdj?: number | null | undefined;
    governancePricePointStatus?: string | null | undefined;
    indicativeLeaseRate?: number | null | undefined;
    indicativeLeaseRateEngine?: number | null | undefined;
    indicativeLeaseRateAdj?: number | null | undefined;
    indicativeLeaseRateMetaData?: string | null | undefined;
    listPriceExclTax?: number | null | undefined;
    listPriceInclTax?: number | null | undefined;
    listPriceInclTaxMetaData?: string | null | undefined;
    recommendedPriceEngine?: number | null | undefined;
    recommendedRetailPrice?: number | null | undefined;
    recommendedRetailPriceValidFromDate?: string | null | undefined;
    recommendedRetailPriceReasonCode?: string | null | undefined;
    retailVolumeActualAbs?: number | null | undefined;
    priceChangeCount?: number | null | undefined;
    pricePointEffectiveDate?: string | null | undefined;
    pricePointPrice?: number | null | undefined;
    retailVolumeAchievementPct?: number | null | undefined;
    scenarioId: string;
    typicallyEquippedLeaseRate?: number | null | undefined;
    typicallyEquippedPriceEngine?: number | null | undefined;
    typicallyEquippedLeaseRateEngine?: number | null | undefined;
    typicallyEquippedPriceAdj?: number | null | undefined;
    typicallyEquippedLeaseRateAdj?: number | null | undefined;
    typicallyEquippedPrice?: number | null | undefined;
    typicallyEquippedCurrentCmPerUnit?: number | null | undefined;
    channelsTrafficLight?: string | null | undefined;
    channelsTrafficLightAdj?: string | null | undefined;
    competitionTrafficLight?: string | null | undefined;
    competitionTrafficLightAdj?: string | null | undefined;
    marketsTrafficLight?: string | null | undefined;
    marketsTrafficLightAdj?: string | null | undefined;
    microMacroTrafficLight?: string | null | undefined;
    microMacroTrafficLightAdj?: string | null | undefined;
    portfolioTrafficLight?: string | null | undefined;
    portfolioTrafficLightAdj?: string | null | undefined;
    productionPipelineTrafficLight?: string | null | undefined;
    productionPipelineTrafficLightAdj?: string | null | undefined;
    salesPipelineTrafficLight?: string | null | undefined;
    salesPipelineTrafficLightAdj?: string | null | undefined;
    stockTrafficLight?: string | null | undefined;
    stockTrafficLightAdj?: string | null | undefined;
    summaryTrafficLight?: string | null | undefined;
    summaryTrafficLightAdj?: string | null | undefined;
    taxChangeValidFromDate?: string | null | undefined;
    listPriceInclTaxFuture?: number | null | undefined;
    pricePoint?:
      | {
          active?: boolean | null | undefined;
          approvalStatus?: ApprovalStatus | null | undefined;
          creationDate?: string | null | undefined;
          currency?: string | null | undefined;
          effectiveDate?: string | null | undefined;
          endDate?: string | null | undefined;
          author: string;
          filterId: string;
          id: string;
          scenarioId: string;
          price?: number | null | undefined;
          lastUpdateDate?: string | null | undefined;
          netPrice?: number | null | undefined;
        }
      | null
      | undefined;
    comments: Array<{
      createdAt: string;
      filterId: string;
      id: string;
      text: string;
      userId: string;
      userName: string;
    }>;
    notes: Array<{
      createdAt: string;
      filterId: string;
      id: string;
      scenarioId: string;
      text: string;
      userId: string;
      userName: string;
    }>;
  }>;
};

export type IMatrixViewColumnsQueryVariables = Exact<{ [key: string]: never }>;

export type IMatrixViewColumnsQuery = {
  matrixColumns: Array<{
    id: string;
    columnType: ColumnType;
    displayName: string;
    secondaryDisplayName?: string | null | undefined;
    isNotCollapsible?: boolean | null | undefined;
    isSticky?: boolean | null | undefined;
    isVisible?: boolean | null | undefined;
    sectionKey?: string | null | undefined;
    parentId?: string | null | undefined;
    position: number;
    technicalName?: string | null | undefined;
  }>;
};

export type IScenariosQueryVariables = Exact<{
  scenarioId: Scalars['String'];
}>;

export type IScenariosQuery = {
  scenarios: Array<{
    userId: string;
    status?: string | null | undefined;
    scenarioType: ScenarioType;
    scenarioName: string;
    predictionComponentURI?: string | null | undefined;
    lastUpdatedDate?: string | null | undefined;
    kpiCalculatedDate?: string | null | undefined;
    id: string;
    creationDate: string;
  }>;
};

export type IFilterScenarioDataByPriceEditorQueryVariables = Exact<{
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  brands?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;

export type IFilterScenarioDataByPriceEditorQuery = {
  priceFilledFilterIds: Array<
    | {
        id: string;
        active?: boolean | null | undefined;
        approvalStatus?: ApprovalStatus | null | undefined;
        creationDate?: string | null | undefined;
        currency?: string | null | undefined;
        effectiveDate?: string | null | undefined;
        endDate?: string | null | undefined;
        filterId: string;
        lastUpdateDate?: string | null | undefined;
        price?: number | null | undefined;
        scenarioId: string;
      }
    | null
    | undefined
  >;
};

export type IBrandApprovalStatusQueryVariables = Exact<{
  scenarioId: Scalars['ID'];
  market: Scalars['String'];
  brand: Scalars['String'];
}>;

export type IBrandApprovalStatusQuery = {
  brandApprovalStatus: { brand: string; canApprove: boolean; message?: string | null | undefined };
};

export type IMatrixColumnFormatsQueryVariables = Exact<{
  market: Scalars['String'];
}>;

export type IMatrixColumnFormatsQuery = {
  matrixColumnFormats: Array<{
    description: string;
    columnName: string;
    config?: string | null | undefined;
    market: string;
  }>;
};

export type IStockCoverageQueryVariables = Exact<{
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  filterId: Scalars['String'];
  accessible: Accessible;
  stockPriceLevel: StockPriceLevel;
}>;

export type IStockCoverageQuery = {
  stockCoverage: {
    scalar?:
      | {
          currentActualStockUnits?: number | null | undefined;
          currentStockCoverage?: number | null | undefined;
          deltaCurrentStockCoverageToISP?: number | null | undefined;
          deltaCurrentToSoYActualStockUnits?: number | null | undefined;
          listPriceInclTax?: number | null | undefined;
          listPriceInclTaxAdjFilled?: number | null | undefined;
        }
      | null
      | undefined;
    stockCoverage?:
      | Array<
          | {
              date: string;
              idealStockCoverageTS?: number | null | undefined;
              stockCoverageTS?: number | null | undefined;
              stockCoverageTSAdj?: number | null | undefined;
              stockCoverageForecastTSAdj?: number | null | undefined;
              stockCoverageForecastTS?: number | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    stockUnits?:
      | Array<
          | {
              date: string;
              stockUnitsActualsTS?: number | null | undefined;
              stockUnitsForecastTSAdj?: number | null | undefined;
              stockUnitsForecastTS?: number | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    tooltipText?:
      | { stockUnits?: string | null | undefined; stockCoverage?: string | null | undefined }
      | null
      | undefined;
  };
};

export type IMarketsCrossChecksQueryVariables = Exact<{
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  filterId: Scalars['String'];
}>;

export type IMarketsCrossChecksQuery = {
  marketsCrossChecks: Array<{
    modelCode?: string | null | undefined;
    modelCodeAdj?: string | null | undefined;
    market: string;
    price: {
      baseNetPrice?: number | null | undefined;
      baseGrossPrice?: number | null | undefined;
      baseNetPriceAdj?: number | null | undefined;
      baseGrossPriceAdj?: number | null | undefined;
      typicallyEquippedNetPrice?: number | null | undefined;
      typicallyEquippedGrossPrice?: number | null | undefined;
      typicallyEquippedNetPriceAdj?: number | null | undefined;
      typicallyEquippedGrossPriceAdj?: number | null | undefined;
      marketNetPriceRatio?: number | null | undefined;
      marketGrossPriceRatio?: number | null | undefined;
      marketNetPriceRatioAdj?: number | null | undefined;
      marketGrossPriceRatioAdj?: number | null | undefined;
      marketNetPriceTypicallyEquippedRatio?: number | null | undefined;
      marketGrossPriceTypicallyEquippedRatio?: number | null | undefined;
      marketNetPriceTypicallyEquippedRatioAdj?: number | null | undefined;
      marketGrossPriceTypicallyEquippedRatioAdj?: number | null | undefined;
      hasAdjustedPrice?: boolean | null | undefined;
    };
    leaseRate: {
      baseNetPrice?: number | null | undefined;
      baseGrossPrice?: number | null | undefined;
      baseNetPriceAdj?: number | null | undefined;
      baseGrossPriceAdj?: number | null | undefined;
      typicallyEquippedNetPrice?: number | null | undefined;
      typicallyEquippedGrossPrice?: number | null | undefined;
      typicallyEquippedNetPriceAdj?: number | null | undefined;
      typicallyEquippedGrossPriceAdj?: number | null | undefined;
      marketNetPriceRatio?: number | null | undefined;
      marketGrossPriceRatio?: number | null | undefined;
      marketNetPriceRatioAdj?: number | null | undefined;
      marketGrossPriceRatioAdj?: number | null | undefined;
      marketNetPriceTypicallyEquippedRatio?: number | null | undefined;
      marketGrossPriceTypicallyEquippedRatio?: number | null | undefined;
      marketNetPriceTypicallyEquippedRatioAdj?: number | null | undefined;
      marketGrossPriceTypicallyEquippedRatioAdj?: number | null | undefined;
      hasAdjustedPrice?: boolean | null | undefined;
    };
  }>;
};

export type IPortfolioInternalPriceQueryVariables = Exact<{
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  filterId: Scalars['String'];
}>;

export type IPortfolioInternalPriceQuery = {
  portfolioInternalPrice: {
    internalPriceLadderData?:
      | Array<
          | {
              eSeries: string;
              series: string;
              segment: string;
              data: Array<{
                model: string;
                ph_id: number;
                listPriceInclTax?: number | null | undefined;
                listPriceInclTaxAdj?: number | null | undefined;
                listPriceExclTax?: number | null | undefined;
                listPriceExclTaxAdj?: number | null | undefined;
                hasAdjustedPrice?: boolean | null | undefined;
                eRange?: number | null | undefined;
                volumeMix?: number | null | undefined;
                enginePower?: number | null | undefined;
                typicallyEquippedPrice?: number | null | undefined;
                typicallyEquippedPriceAdj?: number | null | undefined;
                priceLadderFulfilled?: string | null | undefined;
                priceLadderFulfilledAdj?: string | null | undefined;
                indicativeLeaseRate?: number | null | undefined;
                indicativeLeaseRateAdj?: number | null | undefined;
                typicallyEquippedNetPrice?: number | null | undefined;
                typicallyEquippedNetPriceAdj?: number | null | undefined;
                typicallyEquippedLeaseRate?: number | null | undefined;
                typicallyEquippedLeaseRateAdj?: number | null | undefined;
              }>;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type ISalesPipelineCrossChecksQueryVariables = Exact<{
  crossCheckType: SalesPipelineCrossCheckType;
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  dateRange?: InputMaybe<ISalesPipelineDateRange>;
}>;

export type ISalesPipelineCrossChecksQuery = {
  salesPipelineCrossChecks: {
    __typename: 'SalesPipelineCrossCheckData';
    data?:
      | {
          frequency?: Array<string> | null | undefined;
          timeSeriesProperties: Array<string>;
          timeSeries?:
            | Array<
                | {
                    date: string;
                    actualsThisYear?: number | null | undefined;
                    actualsPreviousYear?: number | null | undefined;
                    orderIntakeBudget?: number | null | undefined;
                    enginePredictedOrderIntakeVolume?: number | null | undefined;
                    enginePredictedOrderIntakeVolumeAdj?: number | null | undefined;
                    orderIntakeLatestForecast?: number | null | undefined;
                    orderIntakeSecondLatestForecast?: number | null | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          scalar?:
            | {
                openVolumeYeRatio?: number | null | undefined;
                openVolumeYe?:
                  | { value?: number | null | undefined; displayName: string; displayNameDetails: string }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | {
          frequency?: Array<string> | null | undefined;
          timeSeriesProperties: Array<string>;
          timeSeries?:
            | Array<
                | {
                    date: string;
                    actualsThisYear?: number | null | undefined;
                    actualsPreviousYear?: number | null | undefined;
                    retailBudget?: number | null | undefined;
                    enginePredictedRetailVolume?: number | null | undefined;
                    expectedVolumeRetailAdjTS?: number | null | undefined;
                    retailLatestForecast?: number | null | undefined;
                    retailSecondLatestForecast?: number | null | undefined;
                  }
                | null
                | undefined
              >
            | null
            | undefined;
          scalar?:
            | {
                expectedVolumeRetail?: number | null | undefined;
                budgetVolumeRetail?: number | null | undefined;
                expectedVolumeAchievement?: number | null | undefined;
                ratioExpectedVolumeBudget?: number | null | undefined;
                absDiffExpectedVolumeBudget?: number | null | undefined;
                ratioExpectedRetailBudget?: number | null | undefined;
                ratioCumulatedBudget?: number | null | undefined;
                absDiffCumulatedBudget?: number | null | undefined;
                retailBudgetScalar?: number | null | undefined;
                retailCount?: number | null | undefined;
                openVolumeYe?:
                  | {
                      value?: number | null | undefined;
                      displayName: string;
                      displayNameDetails: string;
                      ratioRetailOpenVolumeBudget?: number | null | undefined;
                    }
                  | null
                  | undefined;
              }
            | null
            | undefined;
        }
      | null
      | undefined;
  };
};

export type IStockAgeQueryVariables = Exact<{
  filterId: Scalars['String'];
  market: Scalars['String'];
  scenarioId: Scalars['String'];
  accessible: Accessible;
  stockPriceLevel: StockPriceLevel;
}>;

export type IStockAgeQuery = {
  stockAge: {
    calculationDate?: string | null | undefined;
    stockAge?:
      | Array<
          | {
              eSeries?: string | null | undefined;
              eSeriesCount?: number | null | undefined;
              countPerBucket0_10Days?: number | null | undefined;
              countPerBucket11_30Days?: number | null | undefined;
              countPerBucket31_90Days?: number | null | undefined;
              countPerBucket91_180Days?: number | null | undefined;
              countPerBucket181_270Days?: number | null | undefined;
              countPerBucket271_365Days?: number | null | undefined;
              countPerBucket365Days?: number | null | undefined;
              eSeriesPercentage0_10Days?: number | null | undefined;
              eSeriesPercentage11_30Days?: number | null | undefined;
              eSeriesPercentage31_90Days?: number | null | undefined;
              eSeriesPercentage91_180Days?: number | null | undefined;
              eSeriesPercentage181_270Days?: number | null | undefined;
              eSeriesPercentage271_365Days?: number | null | undefined;
              eSeriesPercentage365Days?: number | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    historic?:
      | Array<
          | {
              date?: string | null | undefined;
              monthlyCount?: number | null | undefined;
              monthlyMedianAge?: number | null | undefined;
              countPerBucket0_10Days?: number | null | undefined;
              countPerBucket11_30Days?: number | null | undefined;
              countPerBucket31_90Days?: number | null | undefined;
              countPerBucket91_180Days?: number | null | undefined;
              countPerBucket181_270Days?: number | null | undefined;
              countPerBucket271_365Days?: number | null | undefined;
              countPerBucket365Days?: number | null | undefined;
              historicSumGranularBucket11_20Days?: number | null | undefined;
              historicSumGranularBucket21_30Days?: number | null | undefined;
              historicSumGranularBucket31_40Days?: number | null | undefined;
              historicSumGranularBucket41_50Days?: number | null | undefined;
              historicSumGranularBucket51_60Days?: number | null | undefined;
              historicSumGranularBucket61_70Days?: number | null | undefined;
              historicSumGranularBucket71_80Days?: number | null | undefined;
              historicSumGranularBucket81_90Days?: number | null | undefined;
              historicSumGranularBucket91_100Days?: number | null | undefined;
              historicSumGranularBucket101_110Days?: number | null | undefined;
              historicSumGranularBucket111_120Days?: number | null | undefined;
              historicSumGranularBucket121_130Days?: number | null | undefined;
              historicSumGranularBucket131_140Days?: number | null | undefined;
              historicSumGranularBucket141_150Days?: number | null | undefined;
              historicSumGranularBucket151_160Days?: number | null | undefined;
              historicSumGranularBucket161_170Days?: number | null | undefined;
              historicSumGranularBucket171_180Days?: number | null | undefined;
              historicSumGranularBucket181_195Days?: number | null | undefined;
              historicSumGranularBucket196_210Days?: number | null | undefined;
              historicSumGranularBucket211_225Days?: number | null | undefined;
              historicSumGranularBucket226_240Days?: number | null | undefined;
              historicSumGranularBucket241_255Days?: number | null | undefined;
              historicSumGranularBucket256_270Days?: number | null | undefined;
              historicSumGranularBucket271_285Days?: number | null | undefined;
              historicSumGranularBucket286_300Days?: number | null | undefined;
              historicSumGranularBucket301_315Days?: number | null | undefined;
              historicSumGranularBucket316_330Days?: number | null | undefined;
              historicSumGranularBucket331_345Days?: number | null | undefined;
              historicSumGranularBucket346_365Days?: number | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
    scalar?:
      | Array<
          | {
              medianAgeCurrentMonth?: number | null | undefined;
              medianAgeDiff?: number | null | undefined;
              percentageCurrentMonth?: number | null | undefined;
              over180PercentageDiff?: number | null | undefined;
            }
          | null
          | undefined
        >
      | null
      | undefined;
  };
};

export type IMatrixColumnsCustomOrderQueryVariables = Exact<{
  market: Scalars['String'];
  scenarioId: Scalars['String'];
}>;

export type IMatrixColumnsCustomOrderQuery = {
  matrixColumnsCustomOrder: Array<{
    columnType: ColumnType;
    displayName: string;
    secondaryDisplayName?: string | null | undefined;
    id: string;
    isNotCollapsible?: boolean | null | undefined;
    isSticky?: boolean | null | undefined;
    isVisible?: boolean | null | undefined;
    parentId?: string | null | undefined;
    position: number;
    sectionKey?: string | null | undefined;
    technicalName?: string | null | undefined;
    metadata?:
      | {
          config: string;
          dataIngestionDate?: string | null | undefined;
          description?: string | null | undefined;
          source?: string | null | undefined;
          lastSourceCall?: string | null | undefined;
        }
      | null
      | undefined;
  }>;
};

export type IProductDataQueryVariables = Exact<{
  market: Scalars['String'];
}>;

export type IProductDataQuery = {
  productData?:
    | Array<{
        pathLevel?: string | null | undefined;
        pathElement?: string | null | undefined;
        parentPathId?: number | null | undefined;
        brand?: string | null | undefined;
        series?: string | null | undefined;
        segment?: string | null | undefined;
        powertrain?: string | null | undefined;
        eSeries?: string | null | undefined;
        model?: string | null | undefined;
        modelCode?: string | null | undefined;
        pathId: number;
        market: string;
        children?:
          | Array<{
              pathLevel?: string | null | undefined;
              pathElement?: string | null | undefined;
              parentPathId?: number | null | undefined;
              brand?: string | null | undefined;
              series?: string | null | undefined;
              segment?: string | null | undefined;
              powertrain?: string | null | undefined;
              eSeries?: string | null | undefined;
              model?: string | null | undefined;
              modelCode?: string | null | undefined;
              pathId: number;
              market: string;
              children?:
                | Array<{
                    pathLevel?: string | null | undefined;
                    pathElement?: string | null | undefined;
                    parentPathId?: number | null | undefined;
                    brand?: string | null | undefined;
                    series?: string | null | undefined;
                    segment?: string | null | undefined;
                    powertrain?: string | null | undefined;
                    eSeries?: string | null | undefined;
                    model?: string | null | undefined;
                    modelCode?: string | null | undefined;
                    pathId: number;
                    market: string;
                    children?:
                      | Array<{
                          pathLevel?: string | null | undefined;
                          pathElement?: string | null | undefined;
                          parentPathId?: number | null | undefined;
                          brand?: string | null | undefined;
                          series?: string | null | undefined;
                          segment?: string | null | undefined;
                          powertrain?: string | null | undefined;
                          eSeries?: string | null | undefined;
                          model?: string | null | undefined;
                          modelCode?: string | null | undefined;
                          pathId: number;
                          market: string;
                          children?:
                            | Array<{
                                pathLevel?: string | null | undefined;
                                pathElement?: string | null | undefined;
                                parentPathId?: number | null | undefined;
                                brand?: string | null | undefined;
                                series?: string | null | undefined;
                                segment?: string | null | undefined;
                                powertrain?: string | null | undefined;
                                eSeries?: string | null | undefined;
                                model?: string | null | undefined;
                                modelCode?: string | null | undefined;
                                pathId: number;
                                market: string;
                                children?:
                                  | Array<{
                                      pathLevel?: string | null | undefined;
                                      pathElement?: string | null | undefined;
                                      parentPathId?: number | null | undefined;
                                      brand?: string | null | undefined;
                                      series?: string | null | undefined;
                                      segment?: string | null | undefined;
                                      powertrain?: string | null | undefined;
                                      eSeries?: string | null | undefined;
                                      model?: string | null | undefined;
                                      modelCode?: string | null | undefined;
                                      pathId: number;
                                      market: string;
                                      children?:
                                        | Array<{
                                            pathLevel?: string | null | undefined;
                                            pathElement?: string | null | undefined;
                                            parentPathId?: number | null | undefined;
                                            brand?: string | null | undefined;
                                            series?: string | null | undefined;
                                            segment?: string | null | undefined;
                                            powertrain?: string | null | undefined;
                                            eSeries?: string | null | undefined;
                                            model?: string | null | undefined;
                                            modelCode?: string | null | undefined;
                                            pathId: number;
                                            market: string;
                                          }>
                                        | null
                                        | undefined;
                                    }>
                                  | null
                                  | undefined;
                              }>
                            | null
                            | undefined;
                        }>
                      | null
                      | undefined;
                  }>
                | null
                | undefined;
            }>
          | null
          | undefined;
      }>
    | null
    | undefined;
};

export type IApprovalStatusBySeriesQueryVariables = Exact<{
  scenarioId: Scalars['ID'];
  pathId: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type IApprovalStatusBySeriesQuery = {
  approvalStatusBySeries: Array<
    | {
        pathId?: string | null | undefined;
        market?: string | null | undefined;
        series?: string | null | undefined;
        canApprove: boolean;
        nscApproved?:
          | Array<
              | {
                  id: string;
                  scenarioId: string;
                  filterId: string;
                  price?: number | null | undefined;
                  netPrice?: number | null | undefined;
                  currency?: string | null | undefined;
                  effectiveDate?: string | null | undefined;
                  endDate?: string | null | undefined;
                  approvalStatus?: ApprovalStatus | null | undefined;
                  active?: boolean | null | undefined;
                  creationDate?: string | null | undefined;
                  lastUpdateDate?: string | null | undefined;
                  author: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
        lvl1Approved?:
          | Array<
              | {
                  id: string;
                  scenarioId: string;
                  filterId: string;
                  price?: number | null | undefined;
                  netPrice?: number | null | undefined;
                  currency?: string | null | undefined;
                  effectiveDate?: string | null | undefined;
                  endDate?: string | null | undefined;
                  approvalStatus?: ApprovalStatus | null | undefined;
                  active?: boolean | null | undefined;
                  creationDate?: string | null | undefined;
                  lastUpdateDate?: string | null | undefined;
                  author: string;
                }
              | null
              | undefined
            >
          | null
          | undefined;
      }
    | null
    | undefined
  >;
};

export type IDiscountsQueryVariables = Exact<{
  market: Scalars['String'];
  brands: Array<Scalars['String']> | Scalars['String'];
}>;

export type IDiscountsQuery = {
  discounts: {
    nextToken?: string | null | undefined;
    items: Array<{
      absoluteDiscountInclTaxRange?: string | null | undefined;
      absoluteDiscountNetRange?: string | null | undefined;
      benefitType: BenefitType;
      applicationLevel: ApplicationLevel;
      createdAt?: string | null | undefined;
      discountType: DiscountType;
      discountCategory: DiscountCategory;
      brand: string;
      endDate?: string | null | undefined;
      isActive?: boolean | null | undefined;
      id: string;
      market: string;
      name?: string | null | undefined;
      relativeDiscountInclTaxRange?: string | null | undefined;
      relativeDiscountNetRange?: string | null | undefined;
      startDate?: string | null | undefined;
      salesChannels: Array<SalesChannels>;
      updatedAt?: string | null | undefined;
      approvalStatus?: DiscountApprovalStatus | null | undefined;
      userId?: string | null | undefined;
    }>;
  };
};

export type IDiscountQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type IDiscountQuery = {
  discount: {
    absoluteDiscountInclTaxRange?: string | null | undefined;
    absoluteDiscountNetRange?: string | null | undefined;
    benefitType: BenefitType;
    applicationLevel: ApplicationLevel;
    createdAt?: string | null | undefined;
    discountType: DiscountType;
    discountCategory: DiscountCategory;
    brand: string;
    endDate?: string | null | undefined;
    isActive?: boolean | null | undefined;
    id: string;
    market: string;
    name?: string | null | undefined;
    relativeDiscountInclTaxRange?: string | null | undefined;
    relativeDiscountNetRange?: string | null | undefined;
    startDate?: string | null | undefined;
    salesChannels: Array<SalesChannels>;
    updatedAt?: string | null | undefined;
    approvalStatus?: DiscountApprovalStatus | null | undefined;
    userId?: string | null | undefined;
  };
};

export type IDiscountDraftsQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type IDiscountDraftsQuery = {
  discountDrafts: {
    nextToken?: string | null | undefined;
    items: Array<{
      absoluteDiscountInclTaxRange?: string | null | undefined;
      absoluteDiscountNetRange?: string | null | undefined;
      benefitType: BenefitType;
      applicationLevel: ApplicationLevel;
      createdAt?: string | null | undefined;
      discountType: DiscountType;
      discountCategory: DiscountCategory;
      brand: string;
      endDate?: string | null | undefined;
      isActive?: boolean | null | undefined;
      id: string;
      market: string;
      name?: string | null | undefined;
      relativeDiscountInclTaxRange?: string | null | undefined;
      relativeDiscountNetRange?: string | null | undefined;
      startDate?: string | null | undefined;
      salesChannels: Array<SalesChannels>;
      updatedAt?: string | null | undefined;
      approvalStatus?: DiscountApprovalStatus | null | undefined;
      userId?: string | null | undefined;
    }>;
  };
};

export type IDiscountPointsByDiscountIdTotalQueryVariables = Exact<{
  discountId: Scalars['ID'];
}>;

export type IDiscountPointsByDiscountIdTotalQuery = { discountPointsByDiscountIdTotal: { total: number } };

export const SubmitPricePointsDocument = gql`
  mutation submitPricePoints($scenarioId: String!, $pricePoints: [PricePointInputForSubmit!]!, $isOverride: Boolean!) {
    submitPricePoints(pricePoints: $pricePoints, scenarioId: $scenarioId, isOverride: $isOverride) {
      errors {
        errorType
        pricePoint {
          active
          approvalStatus
          author
          creationDate
          currency
          effectiveDate
          endDate
          filterId
          lastUpdateDate
          id
          price
          scenarioId
        }
        message
      }
      results {
        active
        approvalStatus
        author
        creationDate
        scenarioId
        price
        lastUpdateDate
        id
        filterId
        endDate
        effectiveDate
        currency
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ISubmitPricePointsGQL extends Apollo.Mutation<
  ISubmitPricePointsMutation,
  ISubmitPricePointsMutationVariables
> {
  document = SubmitPricePointsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const CreateUpdatePricePointDocument = gql`
  mutation createUpdatePricePoint($scenarioId: String!, $pricePoints: [PricePointInput!]!) {
    createUpdatePricePoint(scenarioId: $scenarioId, pricePoints: $pricePoints) {
      active
      approvalStatus
      author
      creationDate
      currency
      effectiveDate
      endDate
      filterId
      id
      lastUpdateDate
      price
      scenarioId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ICreateUpdatePricePointGQL extends Apollo.Mutation<
  ICreateUpdatePricePointMutation,
  ICreateUpdatePricePointMutationVariables
> {
  document = CreateUpdatePricePointDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PromotePricePointDocument = gql`
  mutation promotePricePoint($pricePointId: ID!, $scenarioId: ID!, $expectedCurrentStatus: PromotePricePointStatus!) {
    promotePricePoint(
      pricePointId: $pricePointId
      scenarioId: $scenarioId
      expectedCurrentStatus: $expectedCurrentStatus
    ) {
      active
      approvalStatus
      author
      creationDate
      currency
      effectiveDate
      endDate
      filterId
      id
      lastUpdateDate
      price
      scenarioId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IPromotePricePointGQL extends Apollo.Mutation<
  IPromotePricePointMutation,
  IPromotePricePointMutationVariables
> {
  document = PromotePricePointDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const RejectPricePointDocument = gql`
  mutation rejectPricePoint($pricePointId: ID!, $expectedCurrentStatus: ApprovalStatus!, $reason: String) {
    rejectPricePoint(pricePointId: $pricePointId, expectedCurrentStatus: $expectedCurrentStatus, reason: $reason) {
      active
      approvalStatus
      author
      creationDate
      currency
      effectiveDate
      endDate
      filterId
      id
      lastUpdateDate
      price
      scenarioId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IRejectPricePointGQL extends Apollo.Mutation<
  IRejectPricePointMutation,
  IRejectPricePointMutationVariables
> {
  document = RejectPricePointDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const WithdrawPricePointDocument = gql`
  mutation withdrawPricePoint($pricePointId: ID!) {
    withdrawPricePoint(pricePointId: $pricePointId) {
      active
      approvalStatus
      author
      creationDate
      currency
      effectiveDate
      endDate
      filterId
      id
      lastUpdateDate
      price
      scenarioId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IWithdrawPricePointGQL extends Apollo.Mutation<
  IWithdrawPricePointMutation,
  IWithdrawPricePointMutationVariables
> {
  document = WithdrawPricePointDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ApproveBrandPricePointsDocument = gql`
  mutation approveBrandPricePoints($scenarioId: ID!, $brand: String!, $market: String!) {
    approveBrandPricePoints(scenarioId: $scenarioId, brand: $brand, market: $market) {
      active
      approvalStatus
      author
      creationDate
      currency
      effectiveDate
      endDate
      filterId
      id
      lastUpdateDate
      price
      scenarioId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IApproveBrandPricePointsGQL extends Apollo.Mutation<
  IApproveBrandPricePointsMutation,
  IApproveBrandPricePointsMutationVariables
> {
  document = ApproveBrandPricePointsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddCommentDocument = gql`
  mutation addComment($filterId: ID!, $text: String!, $pricePointId: ID!) {
    addComment(filterId: $filterId, text: $text, pricePointId: $pricePointId) {
      id
      text
      userId
      createdAt
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IAddCommentGQL extends Apollo.Mutation<IAddCommentMutation, IAddCommentMutationVariables> {
  document = AddCommentDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddNoteDocument = gql`
  mutation addNote($filterId: ID!, $scenarioId: ID!, $text: String!, $pricePointId: ID!) {
    addNote(filterId: $filterId, scenarioId: $scenarioId, text: $text, pricePointId: $pricePointId) {
      filterId
      id
      scenarioId
      text
      userId
      userName
      createdAt
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IAddNoteGQL extends Apollo.Mutation<IAddNoteMutation, IAddNoteMutationVariables> {
  document = AddNoteDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BulkEditPricePointsDocument = gql`
  mutation bulkEditPricePoints($pricePoints: [PricePointEditInput!]!, $scenarioId: ID!, $task: EditPricePointTask!) {
    bulkEditPricePoints(pricePoints: $pricePoints, scenarioId: $scenarioId, task: $task) {
      active
      approvalStatus
      author
      creationDate
      currency
      effectiveDate
      endDate
      filterId
      id
      lastUpdateDate
      price
      scenarioId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IBulkEditPricePointsGQL extends Apollo.Mutation<
  IBulkEditPricePointsMutation,
  IBulkEditPricePointsMutationVariables
> {
  document = BulkEditPricePointsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const UpdateMatrixColumnsOrderDocument = gql`
  mutation updateMatrixColumnsOrder($matrixColumnsOrder: [MatrixColumnOrderInput!]!) {
    updateMatrixColumnsOrder(matrixColumnsOrder: $matrixColumnsOrder) {
      id
      columnType
      displayName
      isNotCollapsible
      isVisible
      isSticky
      sectionKey
      parentId
      position
      technicalName
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IUpdateMatrixColumnsOrderGQL extends Apollo.Mutation<
  IUpdateMatrixColumnsOrderMutation,
  IUpdateMatrixColumnsOrderMutationVariables
> {
  document = UpdateMatrixColumnsOrderDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ApproveSeriesPricePointsDocument = gql`
  mutation approveSeriesPricePoints($scenarioId: ID!, $pathId: [ID!]!) {
    approveSeriesPricePoints(scenarioId: $scenarioId, pathId: $pathId) {
      pathId
      market
      series
      pricePoints {
        id
        scenarioId
        filterId
        price
        netPrice
        currency
        effectiveDate
        endDate
        approvalStatus
        active
        creationDate
        lastUpdateDate
        author
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IApproveSeriesPricePointsGQL extends Apollo.Mutation<
  IApproveSeriesPricePointsMutation,
  IApproveSeriesPricePointsMutationVariables
> {
  document = ApproveSeriesPricePointsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ReleaseSeriesPricePointsDocument = gql`
  mutation releaseSeriesPricePoints($scenarioId: ID!, $pathId: [ID!]!) {
    releaseSeriesPricePoints(scenarioId: $scenarioId, pathId: $pathId) {
      pathId
      market
      series
      pricePoints {
        id
        scenarioId
        filterId
        price
        netPrice
        currency
        effectiveDate
        endDate
        approvalStatus
        active
        creationDate
        lastUpdateDate
        author
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IReleaseSeriesPricePointsGQL extends Apollo.Mutation<
  IReleaseSeriesPricePointsMutation,
  IReleaseSeriesPricePointsMutationVariables
> {
  document = ReleaseSeriesPricePointsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const WebVitalsDocument = gql`
  mutation webVitals($webVitals: WebVitalsInput!) {
    webVitals(webVitals: $webVitals) {
      response
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IWebVitalsGQL extends Apollo.Mutation<IWebVitalsMutation, IWebVitalsMutationVariables> {
  document = WebVitalsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const AddDiscountsDocument = gql`
  mutation AddDiscounts(
    $market: String!
    $brand: String!
    $name: String
    $benefitType: BenefitType!
    $applicationLevel: ApplicationLevel!
    $discountCategory: DiscountCategory!
    $discountType: DiscountType!
    $salesChannels: [SalesChannels!]!
    $startDate: AWSDateTime
    $endDate: AWSDateTime
    $userId: String!
  ) {
    addDiscount(
      input: {
        market: $market
        brand: $brand
        name: $name
        benefitType: $benefitType
        applicationLevel: $applicationLevel
        discountCategory: $discountCategory
        discountType: $discountType
        salesChannels: $salesChannels
        startDate: $startDate
        endDate: $endDate
        userId: $userId
      }
    ) {
      id
      userId
      market
      brand
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IAddDiscountsGQL extends Apollo.Mutation<IAddDiscountsMutation, IAddDiscountsMutationVariables> {
  document = AddDiscountsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SubmitDiscountDocument = gql`
  mutation submitDiscount($input: SubmitDiscountInput!) {
    submitDiscount(input: $input) {
      absoluteDiscountInclTaxRange
      absoluteDiscountNetRange
      benefitType
      applicationLevel
      createdAt
      discountType
      discountCategory
      brand
      endDate
      isActive
      id
      market
      name
      relativeDiscountInclTaxRange
      relativeDiscountNetRange
      startDate
      salesChannels
      updatedAt
      approvalStatus
      userId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ISubmitDiscountGQL extends Apollo.Mutation<ISubmitDiscountMutation, ISubmitDiscountMutationVariables> {
  document = SubmitDiscountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FiltersDocument = gql`
  query filters($market: String!, $brands: [String!]!) {
    filters(market: $market, brands: $brands) {
      id
      market
      brand
      eSeries
      market
      model
      modelCode
      powertrain
      segment
      series
      level
      posInPath
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IFiltersGQL extends Apollo.Query<IFiltersQuery, IFiltersQueryVariables> {
  document = FiltersDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SalesPipelineTrafficLightsDocument = gql`
  query salesPipelineTrafficLights($scenarioId: String!, $filterIds: [String!]!, $market: String!) {
    scenarioDataByFilterIds(filterIds: $filterIds, scenarioId: $scenarioId, market: $market) {
      salesPipelineTrafficLight
      salesPipelineTrafficLightAdj
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ISalesPipelineTrafficLightsGQL extends Apollo.Query<
  ISalesPipelineTrafficLightsQuery,
  ISalesPipelineTrafficLightsQueryVariables
> {
  document = SalesPipelineTrafficLightsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const StockTrafficLightsDocument = gql`
  query stockTrafficLights($scenarioId: String!, $filterIds: [String!]!, $market: String!) {
    scenarioDataByFilterIds(filterIds: $filterIds, scenarioId: $scenarioId, market: $market) {
      stockTrafficLight
      stockTrafficLightAdj
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IStockTrafficLightsGQL extends Apollo.Query<IStockTrafficLightsQuery, IStockTrafficLightsQueryVariables> {
  document = StockTrafficLightsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MarketsTrafficLightsDocument = gql`
  query marketsTrafficLights($scenarioId: String!, $filterIds: [String!]!, $market: String!) {
    scenarioDataByFilterIds(filterIds: $filterIds, scenarioId: $scenarioId, market: $market) {
      marketsTrafficLight
      marketsTrafficLightAdj
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IMarketsTrafficLightsGQL extends Apollo.Query<
  IMarketsTrafficLightsQuery,
  IMarketsTrafficLightsQueryVariables
> {
  document = MarketsTrafficLightsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PortfolioTrafficLightsDocument = gql`
  query portfolioTrafficLights($scenarioId: String!, $filterIds: [String!]!, $market: String!) {
    scenarioDataByFilterIds(filterIds: $filterIds, scenarioId: $scenarioId, market: $market) {
      portfolioTrafficLight
      portfolioTrafficLightAdj
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IPortfolioTrafficLightsGQL extends Apollo.Query<
  IPortfolioTrafficLightsQuery,
  IPortfolioTrafficLightsQueryVariables
> {
  document = PortfolioTrafficLightsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SalesPipelineCcTrafficLightsOverviewDocument = gql`
  query salesPipelineCcTrafficLightsOverview(
    $market: String!
    $filterId: String!
    $scenarioId: String!
    $dateRange: SalesPipelineDateRange
    $crossCheckType: SalesPipelineCrossCheckType!
    $context: CrossCheckContextType!
  ) {
    salesPipelineCcTrafficLightsOverview(
      context: $context
      crossCheckType: $crossCheckType
      filterId: $filterId
      market: $market
      scenarioId: $scenarioId
      dateRange: $dateRange
    ) {
      data {
        trafficLight {
          arrow
          color
          weight
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ISalesPipelineCcTrafficLightsOverviewGQL extends Apollo.Query<
  ISalesPipelineCcTrafficLightsOverviewQuery,
  ISalesPipelineCcTrafficLightsOverviewQueryVariables
> {
  document = SalesPipelineCcTrafficLightsOverviewDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PortfolioInternalPriceCcTrafficLightsOverviewDocument = gql`
  query portfolioInternalPriceCcTrafficLightsOverview(
    $market: String!
    $filterId: String!
    $scenarioId: String!
    $context: CrossCheckContextType!
  ) {
    portfolioInternalPriceCcTrafficLightsOverview(
      context: $context
      filterId: $filterId
      market: $market
      scenarioId: $scenarioId
    ) {
      data {
        trafficLight {
          arrow
          color
          weight
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IPortfolioInternalPriceCcTrafficLightsOverviewGQL extends Apollo.Query<
  IPortfolioInternalPriceCcTrafficLightsOverviewQuery,
  IPortfolioInternalPriceCcTrafficLightsOverviewQueryVariables
> {
  document = PortfolioInternalPriceCcTrafficLightsOverviewDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MarketsCcTrafficLightsOverviewDocument = gql`
  query marketsCcTrafficLightsOverview(
    $market: String!
    $filterId: String!
    $scenarioId: String!
    $context: CrossCheckContextType!
  ) {
    marketsCcTrafficLightsOverview(context: $context, filterId: $filterId, market: $market, scenarioId: $scenarioId) {
      data {
        trafficLight {
          arrow
          color
          weight
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IMarketsCcTrafficLightsOverviewGQL extends Apollo.Query<
  IMarketsCcTrafficLightsOverviewQuery,
  IMarketsCcTrafficLightsOverviewQueryVariables
> {
  document = MarketsCcTrafficLightsOverviewDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const StockCoverageCcTrafficLightsOverviewDocument = gql`
  query stockCoverageCcTrafficLightsOverview(
    $market: String!
    $filterId: String!
    $scenarioId: String!
    $context: CrossCheckContextType!
    $accessible: Accessible!
    $stockPriceLevel: StockPriceLevel!
  ) {
    stockCoverageCcTrafficLightsOverview(
      context: $context
      filterId: $filterId
      market: $market
      scenarioId: $scenarioId
      accessible: $accessible
      stockPriceLevel: $stockPriceLevel
    ) {
      data {
        trafficLight {
          arrow
          color
          weight
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IStockCoverageCcTrafficLightsOverviewGQL extends Apollo.Query<
  IStockCoverageCcTrafficLightsOverviewQuery,
  IStockCoverageCcTrafficLightsOverviewQueryVariables
> {
  document = StockCoverageCcTrafficLightsOverviewDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const StockAgeCcTrafficLightsOverviewDocument = gql`
  query stockAgeCcTrafficLightsOverview(
    $market: String!
    $filterId: String!
    $scenarioId: String!
    $context: CrossCheckContextType!
    $accessible: Accessible!
    $stockPriceLevel: StockPriceLevel!
  ) {
    stockAgeCcTrafficLightsOverview(
      context: $context
      filterId: $filterId
      market: $market
      scenarioId: $scenarioId
      accessible: $accessible
      stockPriceLevel: $stockPriceLevel
    ) {
      data {
        trafficLight {
          arrow
          color
          weight
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IStockAgeCcTrafficLightsOverviewGQL extends Apollo.Query<
  IStockAgeCcTrafficLightsOverviewQuery,
  IStockAgeCcTrafficLightsOverviewQueryVariables
> {
  document = StockAgeCcTrafficLightsOverviewDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ScenarioDataByFilterIdsDocument = gql`
  query scenarioDataByFilterIds($scenarioId: String!, $filterIds: [String!]!, $market: String!) {
    scenarioDataByFilterIds(filterIds: $filterIds, scenarioId: $scenarioId, market: $market) {
      adjustedListPriceReasonCode
      adjustedCmPerUnit
      budgetVolumeRetail
      budgetNextYearCmPerUnit
      budgetCmPerUnit
      budgetVolumeRetailNextYear
      co2BaseCar
      currentCmPerUnit
      deltaCmPerUnit
      deltaTotalCmAbs
      deltaCurrentPriceEngineAbs
      deltaCurrentPriceEditor
      deltaCurrentIndicativeLeaseRate
      deltaExpectedVolumeRetailCurrentAdj
      expectedVolumeRetailAdj
      expectedVolumeRetail
      effectiveSince
      filterId
      predictionEvaluationIndicator
      forecastVolumeRetail
      forecastLatestCmPerUnit
      forecast1CmPerUnit
      forecast2CmPerUnit
      listPriceExclTaxAdj
      governancePricePointStatus
      governancePricePointId
      indicativeLeaseRate
      indicativeLeaseRateEngine
      indicativeLeaseRateAdj
      indicativeLeaseRateMetaData
      listPriceExclTax
      listPriceInclTax
      listPriceInclTaxMetaData
      recommendedPriceEngine
      recommendedRetailPrice
      recommendedPriceMetaData
      recommendedRetailPriceValidFromDate
      recommendedRetailPriceReasonCode
      retailVolumeActualAbs
      priceChangeCount
      pricePointEffectiveDate
      pricePointPrice
      retailVolumeAchievementPct
      scenarioId
      typicallyEquippedLeaseRate
      typicallyEquippedPriceEngine
      typicallyEquippedLeaseRateEngine
      typicallyEquippedPriceAdj
      typicallyEquippedLeaseRateAdj
      typicallyEquippedPrice
      typicallyEquippedCurrentCmPerUnit
      channelsTrafficLight
      channelsTrafficLightAdj
      competitionTrafficLight
      competitionTrafficLightAdj
      marketsTrafficLight
      marketsTrafficLightAdj
      microMacroTrafficLight
      microMacroTrafficLightAdj
      portfolioTrafficLight
      portfolioTrafficLightAdj
      productionPipelineTrafficLight
      productionPipelineTrafficLightAdj
      salesPipelineTrafficLight
      salesPipelineTrafficLightAdj
      stockTrafficLight
      stockTrafficLightAdj
      summaryTrafficLight
      summaryTrafficLightAdj
      taxChangeValidFromDate
      listPriceInclTaxFuture
      pricePoint {
        active
        approvalStatus
        creationDate
        currency
        effectiveDate
        endDate
        author
        filterId
        id
        scenarioId
        price
        lastUpdateDate
        netPrice
      }
      comments {
        createdAt
        filterId
        id
        text
        userId
        userName
        pricePointId
      }
      notes {
        createdAt
        filterId
        id
        scenarioId
        text
        userId
        userName
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IScenarioDataByFilterIdsGQL extends Apollo.Query<
  IScenarioDataByFilterIdsQuery,
  IScenarioDataByFilterIdsQueryVariables
> {
  document = ScenarioDataByFilterIdsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DownloadScenarioDataByFilterIdsDocument = gql`
  query downloadScenarioDataByFilterIds($scenarioId: String!, $filterIds: [String!]!, $market: String!) {
    downloadScenarioDataByFilterIds(filterIds: $filterIds, scenarioId: $scenarioId, market: $market) {
      adjustedListPriceReasonCode
      adjustedCmPerUnit
      budgetVolumeRetail
      budgetNextYearCmPerUnit
      budgetCmPerUnit
      budgetVolumeRetailNextYear
      co2BaseCar
      currentCmPerUnit
      deltaCmPerUnit
      deltaTotalCmAbs
      deltaCurrentPriceEngineAbs
      deltaCurrentPriceEditor
      deltaCurrentIndicativeLeaseRate
      deltaExpectedVolumeRetailCurrentAdj
      expectedVolumeRetailAdj
      expectedVolumeRetail
      effectiveSince
      filterId
      forecastVolumeRetail
      forecastLatestCmPerUnit
      forecast1CmPerUnit
      forecast2CmPerUnit
      listPriceExclTaxAdj
      governancePricePointStatus
      indicativeLeaseRate
      indicativeLeaseRateEngine
      indicativeLeaseRateAdj
      indicativeLeaseRateMetaData
      listPriceExclTax
      listPriceInclTax
      listPriceInclTaxMetaData
      recommendedPriceEngine
      recommendedRetailPrice
      recommendedRetailPriceValidFromDate
      recommendedRetailPriceReasonCode
      retailVolumeActualAbs
      priceChangeCount
      pricePointEffectiveDate
      pricePointPrice
      retailVolumeAchievementPct
      scenarioId
      typicallyEquippedLeaseRate
      typicallyEquippedPriceEngine
      typicallyEquippedLeaseRateEngine
      typicallyEquippedPriceAdj
      typicallyEquippedLeaseRateAdj
      typicallyEquippedPrice
      typicallyEquippedCurrentCmPerUnit
      channelsTrafficLight
      channelsTrafficLightAdj
      competitionTrafficLight
      competitionTrafficLightAdj
      marketsTrafficLight
      marketsTrafficLightAdj
      microMacroTrafficLight
      microMacroTrafficLightAdj
      portfolioTrafficLight
      portfolioTrafficLightAdj
      productionPipelineTrafficLight
      productionPipelineTrafficLightAdj
      salesPipelineTrafficLight
      salesPipelineTrafficLightAdj
      stockTrafficLight
      stockTrafficLightAdj
      summaryTrafficLight
      summaryTrafficLightAdj
      taxChangeValidFromDate
      listPriceInclTaxFuture
      pricePoint {
        active
        approvalStatus
        creationDate
        currency
        effectiveDate
        endDate
        author
        filterId
        id
        scenarioId
        price
        lastUpdateDate
        netPrice
      }
      comments {
        createdAt
        filterId
        id
        text
        userId
        userName
      }
      notes {
        createdAt
        filterId
        id
        scenarioId
        text
        userId
        userName
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IDownloadScenarioDataByFilterIdsGQL extends Apollo.Query<
  IDownloadScenarioDataByFilterIdsQuery,
  IDownloadScenarioDataByFilterIdsQueryVariables
> {
  document = DownloadScenarioDataByFilterIdsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MatrixViewColumnsDocument = gql`
  query matrixViewColumns {
    matrixColumns {
      id
      columnType
      displayName
      secondaryDisplayName
      isNotCollapsible
      isSticky
      isVisible
      sectionKey
      parentId
      position
      technicalName
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IMatrixViewColumnsGQL extends Apollo.Query<IMatrixViewColumnsQuery, IMatrixViewColumnsQueryVariables> {
  document = MatrixViewColumnsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ScenariosDocument = gql`
  query scenarios($scenarioId: String!) {
    scenarios(scenarioId: $scenarioId) {
      userId
      status
      scenarioType
      scenarioName
      predictionComponentURI
      lastUpdatedDate
      kpiCalculatedDate
      id
      creationDate
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IScenariosGQL extends Apollo.Query<IScenariosQuery, IScenariosQueryVariables> {
  document = ScenariosDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const FilterScenarioDataByPriceEditorDocument = gql`
  query filterScenarioDataByPriceEditor($market: String!, $scenarioId: String!, $brands: [String]) {
    priceFilledFilterIds(market: $market, scenarioId: $scenarioId, brands: $brands) {
      id
      active
      approvalStatus
      creationDate
      currency
      effectiveDate
      endDate
      filterId
      lastUpdateDate
      price
      scenarioId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IFilterScenarioDataByPriceEditorGQL extends Apollo.Query<
  IFilterScenarioDataByPriceEditorQuery,
  IFilterScenarioDataByPriceEditorQueryVariables
> {
  document = FilterScenarioDataByPriceEditorDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BrandApprovalStatusDocument = gql`
  query brandApprovalStatus($scenarioId: ID!, $market: String!, $brand: String!) {
    brandApprovalStatus(scenarioId: $scenarioId, market: $market, brand: $brand) {
      brand
      canApprove
      message
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IBrandApprovalStatusGQL extends Apollo.Query<
  IBrandApprovalStatusQuery,
  IBrandApprovalStatusQueryVariables
> {
  document = BrandApprovalStatusDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MatrixColumnFormatsDocument = gql`
  query matrixColumnFormats($market: String!) {
    matrixColumnFormats(market: $market) {
      description
      columnName
      config
      market
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IMatrixColumnFormatsGQL extends Apollo.Query<
  IMatrixColumnFormatsQuery,
  IMatrixColumnFormatsQueryVariables
> {
  document = MatrixColumnFormatsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const StockCoverageDocument = gql`
  query stockCoverage(
    $market: String!
    $scenarioId: String!
    $filterId: String!
    $accessible: Accessible!
    $stockPriceLevel: StockPriceLevel!
  ) {
    stockCoverage(
      market: $market
      filterId: $filterId
      scenarioId: $scenarioId
      accessible: $accessible
      stockPriceLevel: $stockPriceLevel
    ) {
      scalar {
        currentActualStockUnits
        currentStockCoverage
        deltaCurrentStockCoverageToISP
        deltaCurrentToSoYActualStockUnits
        listPriceInclTax
        listPriceInclTaxAdjFilled
      }
      stockCoverage {
        date
        idealStockCoverageTS
        stockCoverageTS
        stockCoverageTSAdj
        stockCoverageForecastTSAdj
        stockCoverageForecastTS
      }
      stockUnits {
        date
        stockUnitsActualsTS
        stockUnitsForecastTSAdj
        stockUnitsForecastTS
      }
      tooltipText {
        stockUnits
        stockCoverage
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IStockCoverageGQL extends Apollo.Query<IStockCoverageQuery, IStockCoverageQueryVariables> {
  document = StockCoverageDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MarketsCrossChecksDocument = gql`
  query marketsCrossChecks($market: String!, $scenarioId: String!, $filterId: String!) {
    marketsCrossChecks(market: $market, filterId: $filterId, scenarioId: $scenarioId) {
      modelCode
      modelCodeAdj
      market
      price {
        baseNetPrice
        baseGrossPrice
        baseNetPriceAdj
        baseGrossPriceAdj
        typicallyEquippedNetPrice
        typicallyEquippedGrossPrice
        typicallyEquippedNetPriceAdj
        typicallyEquippedGrossPriceAdj
        marketNetPriceRatio
        marketGrossPriceRatio
        marketNetPriceRatioAdj
        marketGrossPriceRatioAdj
        marketNetPriceTypicallyEquippedRatio
        marketGrossPriceTypicallyEquippedRatio
        marketNetPriceTypicallyEquippedRatioAdj
        marketGrossPriceTypicallyEquippedRatioAdj
        hasAdjustedPrice
      }
      leaseRate {
        baseNetPrice
        baseGrossPrice
        baseNetPriceAdj
        baseGrossPriceAdj
        typicallyEquippedNetPrice
        typicallyEquippedGrossPrice
        typicallyEquippedNetPriceAdj
        typicallyEquippedGrossPriceAdj
        marketNetPriceRatio
        marketGrossPriceRatio
        marketNetPriceRatioAdj
        marketGrossPriceRatioAdj
        marketNetPriceTypicallyEquippedRatio
        marketGrossPriceTypicallyEquippedRatio
        marketNetPriceTypicallyEquippedRatioAdj
        marketGrossPriceTypicallyEquippedRatioAdj
        hasAdjustedPrice
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IMarketsCrossChecksGQL extends Apollo.Query<IMarketsCrossChecksQuery, IMarketsCrossChecksQueryVariables> {
  document = MarketsCrossChecksDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const PortfolioInternalPriceDocument = gql`
  query portfolioInternalPrice($market: String!, $scenarioId: String!, $filterId: String!) {
    portfolioInternalPrice(market: $market, filterId: $filterId, scenarioId: $scenarioId) {
      internalPriceLadderData {
        eSeries
        series
        segment
        data {
          model
          ph_id
          listPriceInclTax
          listPriceInclTaxAdj
          listPriceExclTax
          listPriceExclTaxAdj
          hasAdjustedPrice
          eRange
          volumeMix
          enginePower
          typicallyEquippedPrice
          typicallyEquippedPriceAdj
          priceLadderFulfilled
          priceLadderFulfilledAdj
          indicativeLeaseRate
          indicativeLeaseRateAdj
          typicallyEquippedNetPrice
          typicallyEquippedNetPriceAdj
          typicallyEquippedLeaseRate
          typicallyEquippedLeaseRateAdj
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IPortfolioInternalPriceGQL extends Apollo.Query<
  IPortfolioInternalPriceQuery,
  IPortfolioInternalPriceQueryVariables
> {
  document = PortfolioInternalPriceDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const SalesPipelineCrossChecksDocument = gql`
  query salesPipelineCrossChecks(
    $crossCheckType: SalesPipelineCrossCheckType!
    $filterId: String!
    $market: String!
    $scenarioId: String!
    $dateRange: SalesPipelineDateRange
  ) {
    salesPipelineCrossChecks(
      crossCheckType: $crossCheckType
      filterId: $filterId
      market: $market
      scenarioId: $scenarioId
      dateRange: $dateRange
    ) {
      __typename
      data {
        ... on SalesPipelineRetailData {
          frequency
          timeSeries {
            date
            actualsThisYear
            actualsPreviousYear
            retailBudget
            enginePredictedRetailVolume
            expectedVolumeRetailAdjTS
            retailLatestForecast
            retailSecondLatestForecast
          }
          scalar {
            expectedVolumeRetail
            openVolumeYe {
              value
              displayName
              displayNameDetails
              ratioRetailOpenVolumeBudget
            }
            budgetVolumeRetail
            expectedVolumeAchievement
            ratioExpectedVolumeBudget
            absDiffExpectedVolumeBudget
            ratioExpectedRetailBudget
            ratioCumulatedBudget
            absDiffCumulatedBudget
            retailBudgetScalar
            retailCount
          }
          timeSeriesProperties
        }
        ... on SalesPipelineOrderIntakeData {
          frequency
          timeSeries {
            date
            actualsThisYear
            actualsPreviousYear
            orderIntakeBudget
            enginePredictedOrderIntakeVolume
            enginePredictedOrderIntakeVolumeAdj
            orderIntakeLatestForecast
            orderIntakeSecondLatestForecast
          }
          scalar {
            openVolumeYe {
              value
              displayName
              displayNameDetails
            }
            openVolumeYeRatio
          }
          timeSeriesProperties
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class ISalesPipelineCrossChecksGQL extends Apollo.Query<
  ISalesPipelineCrossChecksQuery,
  ISalesPipelineCrossChecksQueryVariables
> {
  document = SalesPipelineCrossChecksDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const StockAgeDocument = gql`
  query stockAge(
    $filterId: String!
    $market: String!
    $scenarioId: String!
    $accessible: Accessible!
    $stockPriceLevel: StockPriceLevel!
  ) {
    stockAge(
      filterId: $filterId
      market: $market
      scenarioId: $scenarioId
      accessible: $accessible
      stockPriceLevel: $stockPriceLevel
    ) {
      calculationDate
      stockAge {
        eSeries
        eSeriesCount
        countPerBucket0_10Days
        countPerBucket11_30Days
        countPerBucket31_90Days
        countPerBucket91_180Days
        countPerBucket181_270Days
        countPerBucket271_365Days
        countPerBucket365Days
        eSeriesPercentage0_10Days
        eSeriesPercentage11_30Days
        eSeriesPercentage31_90Days
        eSeriesPercentage91_180Days
        eSeriesPercentage181_270Days
        eSeriesPercentage271_365Days
        eSeriesPercentage365Days
      }
      historic {
        date
        monthlyCount
        monthlyMedianAge
        countPerBucket0_10Days
        countPerBucket11_30Days
        countPerBucket31_90Days
        countPerBucket91_180Days
        countPerBucket181_270Days
        countPerBucket271_365Days
        countPerBucket365Days
        historicSumGranularBucket11_20Days
        historicSumGranularBucket21_30Days
        historicSumGranularBucket31_40Days
        historicSumGranularBucket41_50Days
        historicSumGranularBucket51_60Days
        historicSumGranularBucket61_70Days
        historicSumGranularBucket71_80Days
        historicSumGranularBucket81_90Days
        historicSumGranularBucket91_100Days
        historicSumGranularBucket101_110Days
        historicSumGranularBucket111_120Days
        historicSumGranularBucket121_130Days
        historicSumGranularBucket131_140Days
        historicSumGranularBucket141_150Days
        historicSumGranularBucket151_160Days
        historicSumGranularBucket161_170Days
        historicSumGranularBucket171_180Days
        historicSumGranularBucket181_195Days
        historicSumGranularBucket196_210Days
        historicSumGranularBucket211_225Days
        historicSumGranularBucket226_240Days
        historicSumGranularBucket241_255Days
        historicSumGranularBucket256_270Days
        historicSumGranularBucket271_285Days
        historicSumGranularBucket286_300Days
        historicSumGranularBucket301_315Days
        historicSumGranularBucket316_330Days
        historicSumGranularBucket331_345Days
        historicSumGranularBucket346_365Days
      }
      scalar {
        medianAgeCurrentMonth
        medianAgeDiff
        percentageCurrentMonth
        over180PercentageDiff
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IStockAgeGQL extends Apollo.Query<IStockAgeQuery, IStockAgeQueryVariables> {
  document = StockAgeDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const MatrixColumnsCustomOrderDocument = gql`
  query matrixColumnsCustomOrder($market: String!, $scenarioId: String!) {
    matrixColumnsCustomOrder(market: $market, scenarioId: $scenarioId) {
      columnType
      displayName
      secondaryDisplayName
      id
      isNotCollapsible
      isSticky
      isVisible
      parentId
      position
      sectionKey
      technicalName
      metadata {
        config
        dataIngestionDate
        description
        source
        lastSourceCall
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IMatrixColumnsCustomOrderGQL extends Apollo.Query<
  IMatrixColumnsCustomOrderQuery,
  IMatrixColumnsCustomOrderQueryVariables
> {
  document = MatrixColumnsCustomOrderDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ProductDataDocument = gql`
  query productData($market: String!) {
    productData(market: $market) {
      pathLevel
      pathElement
      parentPathId
      brand
      series
      segment
      powertrain
      eSeries
      model
      modelCode
      pathId
      children {
        pathLevel
        pathElement
        parentPathId
        brand
        series
        segment
        powertrain
        eSeries
        model
        modelCode
        pathId
        children {
          pathLevel
          pathElement
          parentPathId
          brand
          series
          segment
          powertrain
          eSeries
          model
          modelCode
          children {
            pathLevel
            pathElement
            parentPathId
            brand
            series
            segment
            powertrain
            eSeries
            model
            modelCode
            pathId
            children {
              pathLevel
              pathElement
              parentPathId
              brand
              series
              segment
              powertrain
              eSeries
              model
              modelCode
              pathId
              children {
                pathLevel
                pathElement
                parentPathId
                brand
                series
                segment
                powertrain
                eSeries
                model
                modelCode
                children {
                  pathLevel
                  pathElement
                  parentPathId
                  brand
                  series
                  segment
                  powertrain
                  eSeries
                  model
                  modelCode
                  pathId
                  market
                }
                pathId
                market
              }
              market
            }
            market
          }
          pathId
          market
        }
        market
      }
      market
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IProductDataGQL extends Apollo.Query<IProductDataQuery, IProductDataQueryVariables> {
  document = ProductDataDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const ApprovalStatusBySeriesDocument = gql`
  query approvalStatusBySeries($scenarioId: ID!, $pathId: [ID!]!) {
    approvalStatusBySeries(scenarioId: $scenarioId, pathId: $pathId) {
      pathId
      market
      series
      canApprove
      nscApproved {
        id
        scenarioId
        filterId
        price
        netPrice
        currency
        effectiveDate
        endDate
        approvalStatus
        active
        creationDate
        lastUpdateDate
        author
      }
      lvl1Approved {
        id
        scenarioId
        filterId
        price
        netPrice
        currency
        effectiveDate
        endDate
        approvalStatus
        active
        creationDate
        lastUpdateDate
        author
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IApprovalStatusBySeriesGQL extends Apollo.Query<
  IApprovalStatusBySeriesQuery,
  IApprovalStatusBySeriesQueryVariables
> {
  document = ApprovalStatusBySeriesDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DiscountsDocument = gql`
  query discounts($market: String!, $brands: [String!]!) {
    discounts(market: $market, brands: $brands) {
      items {
        absoluteDiscountInclTaxRange
        absoluteDiscountNetRange
        benefitType
        applicationLevel
        createdAt
        discountType
        discountCategory
        brand
        endDate
        isActive
        id
        market
        name
        relativeDiscountInclTaxRange
        relativeDiscountNetRange
        startDate
        salesChannels
        updatedAt
        approvalStatus
        userId
      }
      nextToken
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IDiscountsGQL extends Apollo.Query<IDiscountsQuery, IDiscountsQueryVariables> {
  document = DiscountsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DiscountDocument = gql`
  query discount($id: ID!) {
    discount(id: $id) {
      absoluteDiscountInclTaxRange
      absoluteDiscountNetRange
      benefitType
      applicationLevel
      createdAt
      discountType
      discountCategory
      brand
      endDate
      isActive
      id
      market
      name
      relativeDiscountInclTaxRange
      relativeDiscountNetRange
      startDate
      salesChannels
      updatedAt
      approvalStatus
      userId
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IDiscountGQL extends Apollo.Query<IDiscountQuery, IDiscountQueryVariables> {
  document = DiscountDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DiscountDraftsDocument = gql`
  query discountDrafts($userId: String!) {
    discountDrafts(userId: $userId) {
      items {
        absoluteDiscountInclTaxRange
        absoluteDiscountNetRange
        benefitType
        applicationLevel
        createdAt
        discountType
        discountCategory
        brand
        endDate
        isActive
        id
        market
        name
        relativeDiscountInclTaxRange
        relativeDiscountNetRange
        startDate
        salesChannels
        updatedAt
        approvalStatus
        userId
      }
      nextToken
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IDiscountDraftsGQL extends Apollo.Query<IDiscountDraftsQuery, IDiscountDraftsQueryVariables> {
  document = DiscountDraftsDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const DiscountPointsByDiscountIdTotalDocument = gql`
  query discountPointsByDiscountIdTotal($discountId: ID!) {
    discountPointsByDiscountIdTotal(discountId: $discountId) {
      total
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class IDiscountPointsByDiscountIdTotalGQL extends Apollo.Query<
  IDiscountPointsByDiscountIdTotalQuery,
  IDiscountPointsByDiscountIdTotalQueryVariables
> {
  document = DiscountPointsByDiscountIdTotalDocument;

  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
