<div class="skeleton">
  <div class="skeleton-overlay {{ theme }}"></div>
  <div class="skeleton-images-wrapper">
    <div
      *ngIf="themeTemplate.hasTitle || themeTemplate.hasFiltersToggle || themeTemplate.filtersNumber"
      class="skeleton-header-wrapper"
    >
      <div class="skeleton-header-left">
        <img
          *ngIf="themeTemplate.hasTitle"
          src="../../../../assets/images/skeleton-loaders/general_skeleton_title.svg"
          alt="Loading title"
        />
        <div class="skeleton-header-left-filters">
          <img
            *ngFor="let _ of [].constructor(themeTemplate.filtersNumber)"
            src="../../../../assets/images/skeleton-loaders/general_skeleton_filter.svg"
            alt="Loading filter"
          />
          <img
            *ngIf="themeTemplate.hasFiltersToggle"
            src="../../../../assets/images/skeleton-loaders/general_skeleton_filters_toggle.svg"
            alt="Loading filters toggle"
          />
        </div>
      </div>
      <img
        *ngIf="themeTemplate.hasHeaderRight"
        src="../../../../assets/images/skeleton-loaders/general_skeleton_header_right.svg"
        alt="Loading header right"
      />
    </div>
    <div *ngFor="let chart of themeTemplate.chartNames">
      <div class="title-wrapper" *ngIf="themeTemplate.hasChartTitle">
        <img
          src="../../../../assets/images/skeleton-loaders/general_skeleton_chart_title.svg"
          alt="Loading chart title"
        />
      </div>
      <div class="skeleton-chart-and-tile-wrapper">
        <img
          src="../../../../assets/images/skeleton-loaders/{{ chart }}_skeleton_chart.svg"
          class="skeleton-chart {{ chart }}_chart"
          alt="Loading {{ chart }}"
        />
        <div class="skeleton-tile-wrapper">
          <img
            *ngFor="let _ of [].constructor(themeTemplate.tilesNumber)"
            src="../../../../assets/images/skeleton-loaders/general_skeleton_tile.svg"
            class="skeleton-chart-tile"
            alt="Loading tile"
          />
        </div>
      </div>
      <div class="skeleton-legend-wrapper">
        <img
          *ngFor="let _ of [].constructor(themeTemplate.legendNumber)"
          src="../../../../assets/images/skeleton-loaders/general_skeleton_legend.svg"
          alt="Loading chart legend"
        />
      </div>
    </div>
  </div>
</div>
