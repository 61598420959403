import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { ISnackbarData } from '../../models/ftd-notifier.model';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-ftd-snackbar',
  styleUrls: ['./ftd-snackbar.component.scss'],
  templateUrl: './ftd-snackbar.component.html',
})
export class FtdSnackbarComponent {
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarData,
    private snackBarRef: MatSnackBarRef<FtdSnackbarComponent>
  ) {}

  onClose(): void {
    this.snackBarRef.dismiss();
  }
}
