import { ChipSize } from './ftd-chip-size.enum';
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ftd-chip',
  styleUrls: ['./ftd-chip.component.scss'],
  templateUrl: './ftd-chip.component.html',
})
export class FtdChipComponent {
  @Input() chipText = '';
  @Input() chipColor = '#3f7bfe';
  @Input() chipTextColor = '#ffffff';
  @Input() enableRemoveButton = true;
  @Input() chipSize: ChipSize = ChipSize.DEFAULT;
  @Input() chipIcon?: string;
  @Input() showCursorPointer: boolean = false;
  @Input() textFontWeight?: string | number;
  @Output() removeEvent = new EventEmitter<string>();

  remove(item: string): void {
    this.removeEvent.emit(item);
  }
}
