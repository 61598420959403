<app-matrix-view-wrapper
  [title]="title"
  [isReturnButtonHidden]="isReturnButtonHidden"
  [isMatrixViewDataHidden]="isMatrixViewDataHidden"
  [isMatrixViewSideFiltersHidden]="isMatrixViewSideFiltersHidden"
  [isDescriptionHidden]="isDescriptionHidden"
  [isTitleHidden]="isTitleHidden"
  [description]="description"
  [isSubmitScenarioButtonHidden]="isSubmitScenarioButtonHidden"
  [isSaveButtonHidden]="isSaveButtonHidden"
  [isUnlockScenarioButtonHidden]="isUnlockScenarioButtonHidden"
  [isExportButtonHidden]="isExportButtonHidden"
>
</app-matrix-view-wrapper>
