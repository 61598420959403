import { Component } from '@angular/core';

/**
 *
 *  Dialog component's box, that (should) wraps the header, body and footer content.
 *
 *  1) Eg. of simplest way to use them together:
 *
 *  <app-ftd-custom-modal>
 *    <app-ftd-custom-modal-header
 *      [title]="'My Title'"
 *      (closeButtonClicked)="implementMe()"
 *    ></app-ftd-custom-modal-header>
 *
 *    <app-ftd-custom-modal-body>
 *      <div>
 *        ... some content
 *      </div>
 *    </app-ftd-custom-modal-body>
 *
 *    <app-ftd-custom-modal-footer
 *      [hiddeCancelButton]="false"
 *      (cancelButtonClicked)="implementMe()"
 *    ></app-ftd-custom-modal-footer>
 *  </app-ftd-custom-modal>
 *
 *
 *  2) Eg. of custom content for header & more footer actions when using them together:
 *
 *  <app-ftd-custom-modal>
 *    <app-ftd-custom-modal-header>
 *      <h2>My custom title</h2>
 *      <button>X</button>
 *    </app-ftd-custom-modal-header>
 *
 *    <app-ftd-custom-modal-body>
 *      <div>
 *        ... some content
 *      </div>
 *    </app-ftd-custom-modal-body>
 *
 *    <app-ftd-custom-modal-footer
 *      [hiddeCancelButton]="false"
 *      (cancelButtonClicked)="implementMe()"
 *    >
 *      <button>Save</button>
 *      <button>Submit</button>
 *    </app-ftd-custom-modal-footer>
 *  </app-ftd-custom-modal>
 *
 */
@Component({
  selector: 'app-ftd-custom-modal',
  styleUrls: ['./ftd-custom-modal.component.scss'],
  templateUrl: './ftd-custom-modal.component.html',
})
export class FtdCustomModalComponent {}
