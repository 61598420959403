import { AuthService } from 'src/app/auth/services/auth.service';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MatrixColumnTechnicalName } from 'src/app/matrix-view/enums/matrix-column-technical-name.enum';
import { PricePointApprovalStatus } from '../../../enums/price-point-approval-status.enum';
import { UserGroups } from 'src/app/common/enums/user-groups.enum';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-mv-column-price-input-editor',
  templateUrl: './mv-column-price-input-editor.component.html',
})
export class MvColumnPriceInputEditorComponent implements OnChanges {
  APPROVED_GROUPS: UserGroups[] = [UserGroups.admin];

  @Input() priceHolder!: any;
  @Input() technicalName?: MatrixColumnTechnicalName;
  @Input() isUserScenario!: boolean;
  @Input() approvalStatus?: PricePointApprovalStatus = PricePointApprovalStatus.no_status;

  _currency: string = 'EUR';
  get currency(): string {
    return this._currency;
  }

  @Input() set currency(currency: string) {
    this._currency = currency;
    this.currencySymbol = getCurrencySymbol(this.currency, 'narrow');
  }

  currencySymbol: string = 'EUR';
  showApprovalStatusChip: boolean = false;

  constructor(private authService: AuthService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.approvalStatus?.currentValue !== changes.approvalStatus?.previousValue) {
      this.approvalStatus = changes.approvalStatus?.currentValue;
      this.showApprovalStatusChip = this.getCheckIfApprovalStatusChipVisible();
    }
  }

  userHasAccess(): boolean {
    let userHasAccess: boolean = false;
    this.authService.getLoggedInUser()?.groups.forEach((group: string): void => {
      this.APPROVED_GROUPS.forEach((approvedGroup: UserGroups): void => {
        if (group === approvedGroup) {
          userHasAccess = true;
        }
      });
    });
    return userHasAccess;
  }

  private getCheckIfApprovalStatusChipVisible(): boolean {
    return [
      PricePointApprovalStatus.proposed_to_pricing_lead,
      PricePointApprovalStatus.proposed_to_governance_board,
      PricePointApprovalStatus.approved_by_1,
      PricePointApprovalStatus.approved_by_2,
    ].includes(this.approvalStatus as PricePointApprovalStatus);
  }
}
