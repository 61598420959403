<app-ftd-custom-modal>
  <app-ftd-custom-modal-header [title]="data.title"></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body [isRequestProcessing]="isRequestProcessing">
    <div class="mb-3">
      <span>
        {{ data.description }}<br />

        {{ data.title }} for:<br />
      </span>
    </div>

    <div id="approve-scenario-modal" *ngIf="!isRequestProcessing">
      <div class="approve-scenario-list">
        <ng-container *ngFor="let brand of getBrands()">
          <div class="approve-scenario-item">
            <span>
              <button
                mat-icon-button
                class="expand-collapse-button"
                (click)="getBrandByKey(brand).isExpanded = !getBrandByKey(brand).isExpanded"
              >
                <mat-icon *ngIf="!getBrandByKey(brand).isExpanded">chevron_right</mat-icon>
                <mat-icon *ngIf="getBrandByKey(brand).isExpanded">expand_more</mat-icon>
              </button>

              <mat-checkbox
                [indeterminate]="isAllSeriesSelected(brand)"
                (click)="selectAllSeries(brand)"
                [checked]="getBrandByKey(brand).isChecked"
                [disabled]="isBrandDisabled(brand)"
              ></mat-checkbox>

              <img class="brand-logo" src="./assets/images/brands/{{ brand }}.svg" alt="brand" />

              <b>{{ brand }}</b>
            </span>
          </div>
          <div
            class="children-list approve-scenario-list inner-column"
            [class.expanded]="getBrandByKey(brand).isExpanded"
            [class.collapsed]="!getBrandByKey(brand).isExpanded"
          >
            <ng-container *ngFor="let series of getBrandByKey(brand).data">
              <div class="approve-scenario-item" [attr.data-test]="'approve-scenario-series-' + series.pathElement">
                <span>
                  <mat-checkbox
                    (click)="selectSeries(series)"
                    [disabled]="series.isDisabled"
                    [checked]="series.isChecked"
                  ></mat-checkbox>
                  <span [ngClass]="series.isDisabled ? 'series-text-disabled' : ''">{{ series.pathElement }}</span>
                  <mat-icon class="warning-icon" *ngIf="series.isDisabled">warning_amber</mat-icon>
                </span>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </div>
    </div>
    <div class="warning-message" *ngIf="errorMessage && !isRequestProcessing">
      <mat-icon>warning_amber</mat-icon><span>{{ errorMessage }}</span>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer>
    <app-ftd-button
      (btnClick)="approveScenario()"
      [id]="'submit-scenario-btn'"
      [disabled]="isReleaseButtonDisabled"
      [label]="data.label"
      ariaText="Approve price point"
      [matTooltip]="'You must first select a series before you can approve'"
      matTooltipPosition="above"
      [matTooltipDisabled]="false"
      [attr.data-test]="'approve-series-modal-btn'"
      hierarchy="green"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
