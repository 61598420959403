import { ChannelsPageComponent } from './pages/channels/channels-page/channels-page.component';
import { CompetitionPageComponent } from './pages/competition/competition-page/competition-page.component';
import { CrosschecksComponent } from './crosschecks.component';
import { CrosschecksContext } from './enums/crosschecks-context.enum';
import { CrosschecksSettingsComponent } from './pages/crosschecks-settings/crosschecks-settings.component';
import { MacroeconomicsPageComponent } from './pages/macroeconomics/macroeconomics-page/macroeconomics-page.component';
import { MarketsPageComponent } from './pages/markets/markets-page/markets-page.component';
import { NgModule } from '@angular/core';
import { PortfolioPageComponent } from './pages/portfolio/portfolio-page/portfolio-page.component';
import { ProductionPipelinePageComponent } from './pages/production-pipeline/production-pipeline-page/production-pipeline-page.component';
import { RouterModule, Routes } from '@angular/router';
import { SalesPipelinePageComponent } from './pages/sales-pipeline/sales-pipeline-page/sales-pipeline-page.component';
import { StockPageComponent } from './pages/stock/stock-page/stock-page.component';

const routes: Routes = [
  {
    children: [
      {
        children: [
          {
            component: MarketsPageComponent,
            data: { breadcrumb: 'Markets', context: CrosschecksContext.FORECASTED, index: 0 },
            path: ':id',
          },
        ],
        path: 'current/markets',
      },
      {
        children: [
          {
            component: MarketsPageComponent,
            data: { breadcrumb: 'Markets', context: CrosschecksContext.CURRENT, index: 0 },
            path: ':id',
          },
        ],
        path: 'forecasted/markets',
      },

      {
        children: [
          {
            component: PortfolioPageComponent,
            data: { breadcrumb: 'Portfolio', context: CrosschecksContext.CURRENT, index: 0 },
            path: 'internal-price-ladder/:id',
          },
          {
            component: PortfolioPageComponent,
            data: { breadcrumb: 'Portfolio', context: CrosschecksContext.CURRENT, index: 1 },
            path: 'price-distribution/:id',
          },
          {
            component: PortfolioPageComponent,
            data: { breadcrumb: 'Portfolio', context: CrosschecksContext.CURRENT, index: 2 },
            path: 'internal-lease-rate-ladder/:id',
          },
          {
            component: PortfolioPageComponent,
            data: { breadcrumb: 'Portfolio', context: CrosschecksContext.CURRENT, index: 3 },
            path: 'lease-rate-distribution/:id',
          },
          {
            path: ':id',
            redirectTo: '/crosschecks/current/portfolio/internal-price-ladder/:id',
          },
        ],
        path: 'current/portfolio',
      },
      {
        children: [
          {
            component: PortfolioPageComponent,
            data: { breadcrumb: 'Portfolio', context: CrosschecksContext.FORECASTED, index: 0 },
            path: 'internal-price-ladder/:id',
          },
          {
            component: PortfolioPageComponent,
            data: { breadcrumb: 'Portfolio', context: CrosschecksContext.FORECASTED, index: 1 },
            path: 'price-distribution/:id',
          },
          {
            component: PortfolioPageComponent,
            data: { breadcrumb: 'Portfolio', context: CrosschecksContext.FORECASTED, index: 2 },
            path: 'internal-lease-rate-ladder/:id',
          },
          {
            component: PortfolioPageComponent,
            data: { breadcrumb: 'Portfolio', context: CrosschecksContext.FORECASTED, index: 3 },
            path: 'lease-rate-distribution/:id',
          },
          {
            path: ':id',
            redirectTo: '/crosschecks/forecasted/portfolio/internal-price-ladder/:id',
          },
        ],
        path: 'forecasted/portfolio',
      },

      { path: '', pathMatch: 'full', redirectTo: '/crosschecks/current/sales-pipeline/order-intake/:id' },
      {
        children: [
          {
            component: SalesPipelinePageComponent,
            data: { breadcrumb: 'Sales Pipeline', context: CrosschecksContext.CURRENT, index: 0 },
            path: 'lease-returns/:id',
          },
          {
            component: SalesPipelinePageComponent,
            data: { breadcrumb: 'Sales Pipeline', context: CrosschecksContext.CURRENT, index: 1 },
            path: 'order-intake/:id',
          },
          {
            component: SalesPipelinePageComponent,
            data: { breadcrumb: 'Sales Pipeline', context: CrosschecksContext.CURRENT, index: 2 },
            path: 'calendarized-order-bank/:id',
          },
          {
            component: SalesPipelinePageComponent,
            data: { breadcrumb: 'Sales Pipeline', context: CrosschecksContext.CURRENT, index: 3 },
            path: 'retail-sales/:id',
          },
          {
            path: ':id',
            redirectTo: 'order-intake/:id',
          },
        ],
        path: 'current/sales-pipeline',
      },
      {
        children: [
          {
            component: SalesPipelinePageComponent,
            data: { breadcrumb: 'Sales Pipeline', context: CrosschecksContext.FORECASTED, index: 0 },
            path: 'lease-returns/:id',
          },
          {
            component: SalesPipelinePageComponent,
            data: { breadcrumb: 'Sales Pipeline', context: CrosschecksContext.FORECASTED, index: 1 },
            path: 'order-intake/:id',
          },
          {
            component: SalesPipelinePageComponent,
            data: { breadcrumb: 'Sales Pipeline', context: CrosschecksContext.FORECASTED, index: 2 },
            path: 'calendarized-order-bank/:id',
          },
          {
            component: SalesPipelinePageComponent,
            data: { breadcrumb: 'Sales Pipeline', context: CrosschecksContext.FORECASTED, index: 3 },
            path: 'retail-sales/:id',
          },
          {
            path: ':id',
            redirectTo: 'order-intake/:id',
          },
        ],
        path: 'forecasted/sales-pipeline',
      },

      {
        component: ProductionPipelinePageComponent,
        data: { context: CrosschecksContext.CURRENT },
        path: 'current/production',
      },
      {
        component: ProductionPipelinePageComponent,
        data: { context: CrosschecksContext.FORECASTED },
        path: 'forecasted/production',
      },

      {
        component: MacroeconomicsPageComponent,
        data: { breadcrumb: 'Macroeconomics', context: CrosschecksContext.CURRENT },
        path: 'current/macroeconomics/:id',
      },
      {
        component: MacroeconomicsPageComponent,
        data: { breadcrumb: 'Macroeconomics', context: CrosschecksContext.FORECASTED },
        path: 'forecasted/macroeconomics/:id',
      },

      {
        component: ChannelsPageComponent,
        data: { breadcrumb: 'Stock', context: CrosschecksContext.CURRENT },
        path: 'current/channels',
      },
      {
        component: ChannelsPageComponent,
        data: { breadcrumb: 'Stock', context: CrosschecksContext.FORECASTED },
        path: 'forecasted/channels',
      },

      {
        component: CompetitionPageComponent,
        data: { breadcrumb: 'Stock', context: CrosschecksContext.CURRENT },
        path: 'current/competition',
      },
      {
        component: CompetitionPageComponent,
        data: { breadcrumb: 'Stock', context: CrosschecksContext.FORECASTED },
        path: 'forecasted/competition',
      },

      {
        children: [
          {
            component: StockPageComponent,
            data: { breadcrumb: 'Stock', context: CrosschecksContext.CURRENT, index: 0 },
            path: 'stock-coverage/:id',
          },
          {
            component: StockPageComponent,
            data: { breadcrumb: 'Stock', context: CrosschecksContext.CURRENT, index: 2 },
            path: 'stock-age/:id',
          },
          {
            path: ':id',
            redirectTo: 'stock-coverage/:id',
          },
        ],
        path: 'current/stock',
      },

      {
        children: [
          {
            component: StockPageComponent,
            data: { breadcrumb: 'Stock', context: CrosschecksContext.FORECASTED, index: 0 },
            path: 'stock-coverage/:id',
          },
          {
            component: StockPageComponent,
            data: { breadcrumb: 'Stock', context: CrosschecksContext.FORECASTED, index: 2 },
            path: 'stock-age/:id',
          },
          {
            path: ':id',
            redirectTo: 'stock-coverage/:id',
          },
        ],
        path: 'forecasted/stock',
      },
    ],
    component: CrosschecksComponent,
    path: 'crosschecks',
  },
  {
    component: CrosschecksSettingsComponent,
    data: { breadcrumb: 'Crosschecks Settings' },
    path: 'crosschecks/settings',
  },
];
@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class CrosschecksRoutingModule {}
