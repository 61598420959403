import { AppUnitType } from '../../common/enums/app-unit-type.enum';
import {
  ConsideredPriceType,
  DisplayedValuesType,
} from 'src/app/crosschecks/enums/crosschecks-chart-dropdowns-type.enum';
import {
  CurrentCrossCheckTechnicalName,
  CurrentFinancialTechnicalName,
  CurrentPricingTechnicalName,
} from '../enums/matrix-column-technical-name.enum';
import { GenericChartFields } from 'src/app/common/components/ftd-charts/models/ftd-generic-chart.model';
import { GranularityType } from '../enums/granularity-type.enum';
import { ICrosschecksFilters } from '../../crosschecks/crosschecks.component';
import { LineChartPricePointColor } from 'src/app/common/components/ftd-charts/enums/line-chart-price-point-color.enum';
import {
  OrderIntakeForecastMonthsEnum,
  keyofOrderIntakeForecastMonthsEnum,
} from '../../crosschecks/enums/order-intake-forecast-months.enum';
import { PlanningHorizonValue } from '../enums/planning-horizon-value.enum';
import { ROLE } from './roles.model';
import { RedCrosscheckValue } from '../enums/red-crosscheck-value.enum';
import {
  RetailForecastMonthsEnum,
  keyofRetailForecastMonthsEnum,
} from '../../crosschecks/enums/retail-forecast-months.enum';
import { ScenarioDataKpiRequestType } from '../enums/scenario-data-kpi-request-type.enum';
import { ScenarioMarketParameter } from '../enums/scenario-market-parameter.enum';
import {
  StockCoverageMonthsEnum,
  keyofStockCoverageMonthsEnum,
} from '../../crosschecks/enums/stock-coverage-months.enum';
import { TrafficLightsColor } from '../../crosschecks/types/traffic-lights.type';
/**
 * Representation for granularity-filters
 */
export interface IGranularityFilters {
  market: string[];
  brand?: string[];
  series?: string[];
  e_series?: string[];
  model?: string[];
  segment?: string[];
  powertrain?: string[];
}

/**
 * Request payload to define which granularities to fetch
 */
export interface IGranularityDataRequest {
  scenario: string;
  test: boolean;
  granularity: {
    test: boolean;
  } & IGranularityFilters;
  red_cross_checks?: RedCrosscheckValue;
  price_editor_filled?: boolean;
  goverance_status?: any;
}

/**
 * Raw data response used to generate all {@link IGranularityDto} objects
 */
export interface IGranularityDataResponse {
  header: string[];
  data: string[][];
}

/**
 * Scenario data request
 */
export interface IScenarioDataRequest {
  id?: string;
  granularity_filter?: IGranularityFilters;
  granularities?: IGranularityDto[];
  planning_horizon?: PlanningHorizonValue | null;
  kpi_requests: IScenarioDataKpiRequest[];
  test: boolean;
  market?: ScenarioMarketParameter | null;
}

/**
 * @deprecated
 * New scenario data request
 */
export interface INewScenarioDataRequest {
  user_id?: string;
  id?: string;
  new_name?: string;
}

/**
 * Delete scenario data request
 */
export interface IDeleteScenarioDataRequest {
  user_id: string;
  id: string;
  role: ROLE;
}

/**
 * Scenario list data request
 */
export interface IScenarioListDataRequest {
  user_id?: string;
}

export interface IScenarioDataKpiRequest {
  /**
   * Name of the requested KPI
   */
  name?: string | null;
  /**
   * Type of the requested KPI
   */
  type: ScenarioDataKpiRequestType;
  /**
   * Planning horizon
   */
  planning_horizon?: PlanningHorizonValue | string | null;
  /**
   * Sections to fetch
   */
  sections?: string[] | null;
  /**
   * Fields to fetch in the given scenario-types
   */
  fields?: string[] | null;
}

/**
 * Scenario responses
 */
export interface IScenarioMetadataResponse {
  test: boolean;
  id: string;
  kpi_requests: IScenarioDataKpiRequest[];
  kpis: IScenarioKpiMetadata[][];
}

export interface IScenarioMatrixValueResponse {
  test: boolean;
  id: string;
  scenario_name: string;
  kpi_requests: IScenarioDataKpiRequest[];
  kpis: (string | number)[][];
  granularities: Omit<IGranularityDto, 'id'>[];
}

export interface IScenarioListMetadataResponse {
  id?: string;
  modification_time?: string;
  creation_time: string;
  user_id?: string;
  hash?: string;
  data_update_date?: string;
  name: string;
  type: string;
  rule_id_under_which_optimal?: string;
}

export interface IScenarioKpiMetadata {
  section: string;
  section_display_name: string;
  technicalName?: string;
  position_in_section: number;
  display_name: string;
  description: string;
  unit?: AppUnitType | string;
  parents?: string[];

  /**
   * KPI Name for futher mapping purposes.
   * This property is being set after initial mapping.
   */
  kpi_name?: string;
}

export type ScenarioKpiMetadataByMarketKey = {
  [key: string]: ScenarioKpiMetadataByMarket;
};

export type ScenarioKpiMetadataByMarket = {
  formatting: IScenarioKpiMetadata[];
  market: ScenarioMarketParameter;
};

export interface IMetadata {
  id: string;
  user_id: string;
  market: string;
  planning_horizon: string;
  creation_date: string;
  modification_time: string;
}

export interface ICurrentPrices {
  [CurrentPricingTechnicalName.list_price_incl_tax]: number[];
  [CurrentPricingTechnicalName.indicative_lease_rate]: number[];
  [CurrentPricingTechnicalName.effective_since]: string[];
  [CurrentPricingTechnicalName.price_change_count]: number[];
  [CurrentPricingTechnicalName.typically_equipped_price]: number[];
  [CurrentPricingTechnicalName.list_price_excl_tax]: number[];
  [CurrentPricingTechnicalName.typically_equipped_lease_rate]: number[];
}

export interface ICurrentFinancials {
  [CurrentFinancialTechnicalName.budget_volume_retail]: number[];
  [CurrentFinancialTechnicalName.retail_volume_achievement_pct]: number[];
  [CurrentFinancialTechnicalName.retail_volume_actual_abs]: number[];
  [CurrentFinancialTechnicalName.forecast_volume_retail]: number[];
  [CurrentFinancialTechnicalName.expected_volume_retail]: number[];
  [CurrentFinancialTechnicalName.current_cm_per_unit]: number[];
  [CurrentFinancialTechnicalName.typically_equipped_current_cm_per_unit]: number[];
  [CurrentFinancialTechnicalName.forecast_1_cm_per_unit]: number[];
  [CurrentFinancialTechnicalName.forecast_2_cm_per_unit]: number[];
  [CurrentFinancialTechnicalName.budget_cm_per_unit]: number[];
  [CurrentFinancialTechnicalName.co2_basecar]: number[];
}

export interface IScenarioOutputsDto {
  current_prices: ICurrentPrices;
  current_financials: ICurrentFinancials;
}

export interface IGovernance {
  status: string[];
  price: number[];
  effective_date: number[];
  id: string[];
}

export interface IArrow {
  value: string;
}

export interface ISettings {}

export interface ICrossCheckResultsDto {
  [CurrentCrossCheckTechnicalName.sales_pipeline]: number[];
  [CurrentCrossCheckTechnicalName.production_pipeline]: number[];
  [CurrentCrossCheckTechnicalName.stock]: number[];
  [CurrentCrossCheckTechnicalName.competition]: number[];
  [CurrentCrossCheckTechnicalName.markets]: number[];
  [CurrentCrossCheckTechnicalName.portfolio]: number[];
  [CurrentCrossCheckTechnicalName.channels]: number[];
  [CurrentCrossCheckTechnicalName.micro_macro]: number[];
}

export interface IScenarioDataResponse {
  metadata: IMetadata;
  granularities: IGranularityDto[];
  outputs: IScenarioOutputsDto;
  governance: IGovernance;
}

export interface ICrosscheckDataResponse {
  metadata: IMetadata;
  granularities: IGranularityDto[];
  settings: ISettings;
  cross_check_results: ICrossCheckResultsDto;
}

/**
 * Cross-checks | Sales-Pipelines - order-intake
 */

export interface ICrossChecksFilters {
  sales_channel: any[];
  comparison_vs: any[];
}

export interface IHeading {
  description: string;
  key: string;
}

export interface IOpenVolumeYe {
  displayName: string;
  displayNameDetails: string;
  value: string;
  ratioRetailOpenVolumeBudget?: string;
  displaySubValue?: string;
}

/**
 * Crosschecks | Sales-Pipelines - Retail Sales
 */
export interface IRetailSalesApiResponse {
  timeSeriesProperties: string[];
  frequency: string[];
  timeSeries: IYaxisRetailSales[];
  scalar: IRetailSalesScalarProps;
}

export interface IRetailSalesScalarProps {
  openVolumeYe: IOpenVolumeYe;
  budgetVolumeRetail: number;
  retailCount: number;
  expectedVolumeAchievement: number;
  ratioCumulatedBudget: number;
  ratioExpectedVolumeBudget: number;
  absDiffExpectedVolumeBudget: number;
  ratioExpectedRetailBudget: number;
  absDiffCumulatedBudget: number;
  expectedVolumeRetail: number;
}

export interface IRetailSalesIOpenVolumeYe {
  displayName: string;
  displayNameDetails: string;
  value: number;
}

export interface ISalesPipelineChartData {
  xaxis: string[];
  headings: IHeading[];
}

export interface IRetailSalesData extends ISalesPipelineChartData {
  timeSeriesProperties: string[];
  yaxis: IYaxisRetailSales[];
  scalar: IRetailSalesScalarProps;
  filters: ICrossChecksFilters;
}

export interface IYaxisRetailSales {
  date: Date | string;
  actualsThisYear: number;
  actualsPreviousYear: number;
  retailBudget: number;
  enginePredictedRetailVolume: number;
}

export interface IOrderIntakeData extends ISalesPipelineChartData {
  yaxis: IYaxisOrderIntake[];
  openVolumeYe: IOpenVolumeYe;
}

export interface IYaxisOrderIntake extends IOrderIntakeForecast {
  date: Date | string;
  actualsThisYear: number | null;
  actualsPreviousYear: number | null;
  orderIntakeBudget: number | null;
  enginePredictedOrderIntakeVolume: number | null;
  enginePredictedOrderIntakeVolumeAdj: number | null;
  orderIntakeLatestForecast: number | null;
  orderIntakeSecondLatestForecast: number | null;
}

export type KeyofRetailForecast = keyof IRetailForecast;

export interface IRetailForecast {
  retailForecastF01?: number;
  retailForecastF02?: number;
  retailForecastF03?: number;
  retailForecastF04?: number;
  retailForecastF05?: number;
  retailForecastF06?: number;
  retailForecastF07?: number;
  retailForecastF08?: number;
  retailForecastF09?: number;
  retailForecastF10?: number;
  retailForecastF11?: number;
  retailForecastF12?: number;
}

export interface IRetailForecastPrevNextMonthKeysAndDescriptions {
  nextMonthKey: { description: RetailForecastMonthsEnum; key: keyofRetailForecastMonthsEnum };
  prevMonthKey: { description: RetailForecastMonthsEnum; key: keyofRetailForecastMonthsEnum };
}

export interface TrafficLightsResponse {
  trafficLight: CrosschecksTrafficLightProps;
  trafficLightAdj: CrosschecksTrafficLightProps;
}

/**
 * Crosschecks | Sales-Pipelines - Order Intake
 */

export interface IOrderIntakeApiResponse {
  timeSeriesProperties: string[];
  frequency: string[];
  timeSeries: IOrderIntakeTimeSeries[];
  scalar: IOrderIntakeScalarProps;
  trafficLight: CrosschecksTrafficLightProps;
  trafficLightAdj: CrosschecksTrafficLightProps;
}

export interface IOrderIntakeTimeSeries {
  date: Date | string;
  actualsThisYear: number | null;
  actualsPreviousYear: number | null;
  orderIntakeBudget: number | null;
  enginePredictedOrderIntakeVolume: number | null;
  enginePredictedOrderIntakeVolumeAdj: number | null;
  orderIntakeLatestForecast: number | null;
  orderIntakeSecondLatestForecast: number | null;
}

export interface CrosschecksTrafficLightProps {
  color: TrafficLightsColor;
  arrow: number;
  weight?: number;
}

export interface CrosschecksTrafficLightUIProps extends Pick<CrosschecksTrafficLightProps, 'arrow' | 'color'> {
  weight: string;
}

export interface IOrderIntakeScalarProps {
  openVolumeYe: IOrderIntakeOpenVolumeYe;
  openVolumeYeRatio: number;
}

export interface IOrderIntakeOpenVolumeYe {
  displayName: string;
  displayNameDetails: string;
  value: number;
}

export type keyofOrderIntakeForecast = keyof IOrderIntakeForecast;

export interface IOrderIntakeForecast {
  orderIntakeForecastF01?: number;
  orderIntakeForecastF02?: number;
  orderIntakeForecastF03?: number;
  orderIntakeForecastF04?: number;
  orderIntakeForecastF05?: number;
  orderIntakeForecastF06?: number;
  orderIntakeForecastF07?: number;
  orderIntakeForecastF08?: number;
  orderIntakeForecastF09?: number;
  orderIntakeForecastF10?: number;
  orderIntakeForecastF11?: number;
  orderIntakeForecastF12?: number;
}

export interface IOrderIntakeForecastPrevNextMonthKeysAndDescriptions {
  nextMonthKey: { description: OrderIntakeForecastMonthsEnum; key: keyofOrderIntakeForecastMonthsEnum };
  prevMonthKey: { description: OrderIntakeForecastMonthsEnum; key: keyofOrderIntakeForecastMonthsEnum };
}

export interface ITooltipDataResponse {
  [key: string]: string;
}

export interface ISalesPipelineTooltips extends ITooltipDataResponse {
  leaseReturns: string;
  orderIntake: string;
  calendarizedOrderBank: string;
  retailSales: string;
  salesOpportunities: string;
}

export interface IStockTooltips extends ITooltipDataResponse {
  stockCoverage: string;
  stockMix: string;
  stockAge: string;
}

export interface IMarketsTooltips extends ITooltipDataResponse {
  markets: string;
}

export interface IPortfolioTooltips extends ITooltipDataResponse {
  internalPriceLadder: string;
  priceDistribution: string;
  internalLeaseRateLadder: string;
  leaseRateDistribution: string;
}

export interface IMacroeconomicsTooltips extends ITooltipDataResponse {
  subsidiesTaxation: string;
}

/**
 * Cross-checks | Sales-Pipelines - Overview
 */
export interface ISalesPipelineOverviewResponse {
  sales_pipeline_overview: ISalesPipelineOverview;
}

export interface ISalesPipelineOverview {
  lease_returns: ICalendarizedOrderBank;
  incoming_orders: IOrderIntake;
  calendarized_order_bank: ICalendarizedOrderBank;
  retail: IOrderIntake;
  crosscheck_recommendation: ICalendarizedOrderBank;
}

export interface ICalendarizedOrderBank {
  arrow: IArrow;
}

export interface IOrderIntake {
  weight: number;
  arrow: IArrow;
}

/**
 * Crosschecks | Sales-Pipeline - Calenderized Order Bank
 */

export interface ICalendarizedOrderBankDataResponse {
  calendarized_order_bank: ICalendarizedOrderBankDataObject;
}

export interface ICalendarizedOrderBankDataObject {
  data: ICalendarizedOrderBankData[];
  order_bank_reach: IOrderBankReach;
  total_order_bank_cy: ITotalOderBankCY;
}

export interface ITotalOderBankCY {
  current: number;
  forecasted: number;
}

export interface IOrderBankReach {
  months: number;
  delta: number;
}

export interface ICalendarizedOrderBankOrderBank {
  value: number;
  percentage?: number;
}

export interface ICalendarizedOrderBankData {
  period: string | number;
  budget?: number;
  retail?: number;
  order_bank?: ICalendarizedOrderBankOrderBank;
  forecast_not_covered_by_ob_f01?: number;
  forecast_not_covered_by_ob_f02?: number;
  forecast_not_covered_by_ob_f03?: number;
  forecast_not_covered_by_ob_f04?: number;
  forecast_not_covered_by_ob_f05?: number;
  forecast_not_covered_by_ob_f06?: number;
  forecast_not_covered_by_ob_f07?: number;
  forecast_not_covered_by_ob_f08?: number;
  forecast_not_covered_by_ob_f09?: number;
  forecast_not_covered_by_ob_f10?: number;
  forecast_not_covered_by_ob_f11?: number;
  forecast_not_covered_by_ob_f12?: number;
}

/**
 * Crosschecks | Markets
 */

export interface IMarketsCrosschecksChartData {}

export interface IMarketsCrosscheckDataResponse {
  priceData: IMarketsCrosscheckData[];
  leaseRateData: IMarketsCrosscheckData[];
}

export interface IMarketsCrosscheckData {
  modelCode: string;
  modelCodeAdj?: string;
  market: string;
  baseNetPrice?: number;
  baseGrossPrice?: number;
  baseNetPriceAdj?: number;
  baseGrossPriceAdj?: number;
  typicallyEquippedNetPrice?: number;
  typicallyEquippedGrossPrice?: number;
  typicallyEquippedNetPriceAdj?: number;
  typicallyEquippedGrossPriceAdj?: number;
  marketNetPriceRatio?: number;
  marketGrossPriceRatio?: number;
  marketNetPriceRatioAdj?: number;
  marketGrossPriceRatioAdj?: number;
  marketNetPriceTypicallyEquippedRatio?: number;
  marketGrossPriceTypicallyEquippedRatio?: number;
  marketNetPriceTypicallyEquippedRatioAdj?: number;
  marketGrossPriceTypicallyEquippedRatioAdj?: number;
  hasAdjustedPrice?: boolean;
}

export type MarketsCrosscheckDataDictionary = {
  [consideredPriceType in ConsideredPriceType]?: {
    [priceValueType in DisplayedValuesType]: keyof IMarketsCrosscheckData;
  };
};

export interface IMarketsCrosscheckChartData extends GenericChartFields {
  basePrice?: number;
  basePriceVariation?: number;
  typicallyEquippedPrice?: number;
  modelCode?: string;
  section: string;
  selected: boolean;
  xAxisImgSrc: string;
  xAxisValue: string;
}

/**
 * Crosschecks | Stock Age
 */

export interface IStockAgeResponseChartData {
  calculationDate: string;
  stockAge: IStockAgeChartData[];
  historic?: IHistoricChartData[];
  scalar: IStockAgeTileData[];
}

export interface IStockAgeChartData {
  eSeries: string;
  eSeriesCount: number;
  countPerBucket0_10Days: number;
  countPerBucket11_30Days: number;
  countPerBucket31_90Days: number;
  countPerBucket91_180Days: number;
  countPerBucket181_270Days: number;
  countPerBucket271_365Days: number;
  countPerBucket365Days: number;
  eSeriesPercentage0_10Days: number;
  eSeriesPercentage11_30Days: number;
  eSeriesPercentage31_90Days: number;
  eSeriesPercentage91_180Days: number;
  eSeriesPercentage181_270Days: number;
  eSeriesPercentage271_365Days: number;
  eSeriesPercentage365Days: number;
}

export interface IHistoricChartData {
  date: Date;
  monthlyMedianAge: number;
  monthlyCount: number;
  countPerBucket0_10Days: number;
  countPerBucket11_30Days: number;
  countPerBucket31_90Days: number;
  countPerBucket91_180Days: number;
  countPerBucket181_270Days: number;
  countPerBucket271_365Days: number;
  countPerBucket365Days: number;
  historicSumGranularBucket0_10Days: number;
  historicSumGranularBucket11_20Days: number;
  historicSumGranularBucket21_30Days: number;
  historicSumGranularBucket31_40Days: number;
  historicSumGranularBucket41_50Days: number;
  historicSumGranularBucket51_60Days: number;
  historicSumGranularBucket61_70Days: number;
  historicSumGranularBucket71_80Days: number;
  historicSumGranularBucket81_90Days: number;
  historicSumGranularBucket91_100Days: number;
  historicSumGranularBucket101_110Days: number;
  historicSumGranularBucket111_120Days: number;
  historicSumGranularBucket121_130Days: number;
  historicSumGranularBucket131_140Days: number;
  historicSumGranularBucket141_150Days: number;
  historicSumGranularBucket151_160Days: number;
  historicSumGranularBucket161_170Days: number;
  historicSumGranularBucket171_180Days: number;
  historicSumGranularBucket181_195Days: number;
  historicSumGranularBucket196_210Days: number;
  historicSumGranularBucket211_225Days: number;
  historicSumGranularBucket226_240Days: number;
  historicSumGranularBucket241_255Days: number;
  historicSumGranularBucket256_270Days: number;
  historicSumGranularBucket271_285Days: number;
  historicSumGranularBucket286_300Days: number;
  historicSumGranularBucket301_315Days: number;
  historicSumGranularBucket316_330Days: number;
  historicSumGranularBucket331_345Days: number;
  historicSumGranularBucket346_365Days: number;
  historicSumGranularBucket365Days: number;
}

export interface IStockAgeTileData {
  medianAgeCurrentMonth: number;
  medianAgeDiff: number;
  percentageCurrentMonth: number;
  over180PercentageDiff: number;
}

/**
 * Crosschecks | Stock Mix
 */

export interface IStockMixCrosschecksChartDataResponse {
  stock_mix: IStockMixDataResponse;
}

export interface IStockMixDataResponse {
  avg_stock_coverage?: IStockMixTileChartResponse;
  ideal_stock_coverage?: number;
  yAxis: IYaxisStockMixResponse[];
}

export interface IStockMixTileChartResponse {
  display_description: string;
  display_description_value: number;
  display_name: string;
  display_value: number;
}

export interface IStockMixDataValueResponse {
  key: string;
  value: number;
}

export interface IYaxisStockMixResponse {
  data: IStockMixDataValueResponse[];
  value: string;
}

export interface IStockMixTileChart {
  display_name_details: string;
  display_name: string;
  value: number;
  selected_series: string;
}

/**
 * Crosschecks | Stock - Overview
 */
export interface IStockOverviewResponse {
  stock_overview: IStockOverview;
}

export interface IStockCoverageDataResponse {
  stockUnits: IStockCoverageBarChartData[];
  stockCoverage: IStockCoverageLineChartData[];
  scalar: IStockCoverageScalar;
  tooltipText: string;
}

export interface IStockCoverageScalar {
  deltaCurrentToSoYActualStockUnits: number | null;
  deltaCurrentStockCoverageToISP: number | null;
  currentActualStockUnits: number | null;
  currentStockCoverage: number | null;
  listPriceInclTax: number;
  listPriceInclTaxAdjFilled: number;
}

export interface IStockOverviewPrevNextMonthKeysAndDescriptions {
  nextMonthKey: { description: StockCoverageMonthsEnum; key: keyofStockCoverageMonthsEnum };
  prevMonthKey: { description: StockCoverageMonthsEnum; key: keyofStockCoverageMonthsEnum };
}

export interface IStockCoverageLineChartData {
  date: Date;
  idealStockCoverageTS: number;
  stockCoverageTS: number;
  stockCoverageTSAdj: number;
  stockCoverageForecastTSAdj: number;
  stockCoverageForecastTS: number;
}

export interface IStockCoverageBarChartData {
  date: Date;
  stockUnitsActualsTS: number;
  stockUnitsForecastTSAdj: number;
  stockUnitsForecastTS: number;
}

export interface ITotalAccessibleStockCoverage {
  stockUnitsDisplayName: string;
  stockCoverageDisplayName: string;
  currentActualStockUnits: number;
  deltaCurrentToSoY: string;
  currentStockCoverage: string;
  deltaCurrentStockCoverage: string;
}

export interface IYaxisStockCoverageLineChart {
  value: Date | string;
  engine_predicted_stock_coverage: number;
  forecast_total_isp_stock_coverage: number;
  forecast_total_stock_coverage: number;
  total_isp_stock_coverage: number;
  total_stock_coverage: number;
  type: string;
}

export interface IStockOverview {
  stock_coverage: IStockCoverage;
  stock_mix: IStockMix;
  stock_age: IStockAge;
}

export interface IStockCoverage {
  weight: number;
  arrow: IArrow;
}

export interface IStockMix {
  arrow: IArrow;
}

export interface IStockAge {
  arrow: IArrow;
}

/**
 * Cross-checks | Portfolio - Internal Price Ladder
 */

export interface IInternalPriceLadderResponse {
  data: IInternalPriceLadderResponseData[];
  segment: string;
  eSeries: string;
  series: string;
}

export interface IInternalPriceLadderResponseData {
  model: string;
  eSeries?: string;
  series?: string;
  ph_id: number;
  listPriceInclTax?: number;
  listPriceInclTaxAdj?: number;
  listPriceExclTax?: number;
  listPriceExclTaxAdj?: number;
  hasAdjustedPrice?: boolean;
  eRange?: number;
  volumeMix?: number;
  enginePower?: number;
  typicallyEquippedPrice?: number;
  typicallyEquippedPriceAdj?: number;
  priceLadderFulfilled?: string;
  priceLadderFulfilledAdj?: string;
  indicativeLeaseRate?: number;
  indicativeLeaseRateAdj?: number;
  typicallyEquippedNetPrice?: number;
  typicallyEquippedNetPriceAdj?: number;
  typicallyEquippedLeaseRate?: number;
  typicallyEquippedLeaseRateAdj?: number;
}

export interface IInternalPriceLadderChartData extends GenericChartFields {
  model: string;
  series: string;
  eRange?: number;
  volMix?: number;
  kw?: number;
  isAdjustedPrice: boolean;
  value?: number;
  currency: string;
  pricePointColor: LineChartPricePointColor;
  pricingGuideline: string;
  xAxisValue: string;
}

export interface IFilterValue {
  id: string;
  displayName: string;
  isGrouped: boolean;
}

export interface IFilterValueGroupedOptions extends IFilterValue {
  groupedBy: string;
  groupedOptions: _.Dictionary<ICrosschecksFilters[]>;
}

export interface IFilterValueOptions extends IFilterValue {
  options: ICrosschecksFilters[];
}

export interface IFilters {
  brand: IFilterValueOptions;
  segment: IFilterValueGroupedOptions;
  series: IFilterValueGroupedOptions;
  eSeries: IFilterValueGroupedOptions;
  powertrain: IFilterValueGroupedOptions;
  model: IFilterValueGroupedOptions;
  modelCode: IFilterValueGroupedOptions;
}

export interface IFilterIdsWithPriceFilled {
  filterId: string;
}

export interface IGranularityDto {
  id?: string;
  market: string;
  type?: string;
  childrenType?: GranularityType;
  children?: IGranularityDto[];
  displayName?: string;
  group?: string;
  brand: string;
  segment: string;
  series: string;
  eSeries: string;
  model: string;
  modelCode?: string;
  powertrain: string;
  level?: string | null;
  posInPath?: number | null;
}
export type FilterKey = keyof IFilters;
