import { ScenarioContext } from '../../common/enums/scenario-context.enum';
import { User } from '../../auth/models/user.model';

export function showElementByPermission(context: ScenarioContext, currentUser?: User | null): boolean {
  return !!(
    (context === ScenarioContext.mainScenario && currentUser?.permissions.getHasPermissionToApproveMainScenario()) ||
    currentUser?.permissions.getHasPermissionToDownloadScenario() ||
    (context === ScenarioContext.userScenario &&
      (currentUser?.permissions.getHasPermissionToSubmitToMainScenario() ||
        currentUser?.permissions.getHasPermissionToSaveUserScenario()))
  );
}
