import { CrossCheckContextType } from '../../graphql/services/gql-api.service';

export enum CrosschecksContext {
  CURRENT = 'current',
  FORECASTED = 'forecasted',
}

export const CrossCheckContextTypeMap = new Map<CrosschecksContext, CrossCheckContextType>([
  [CrosschecksContext.CURRENT, CrossCheckContextType.Current],
  [CrosschecksContext.FORECASTED, CrossCheckContextType.Forecasted],
]);
