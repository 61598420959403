import { Component, Input } from '@angular/core';
import { MatrixColumnTechnicalName } from '../../../enums/matrix-column-technical-name.enum';

@Component({
  selector: 'app-discount-mv-pp-display',
  styleUrls: ['./mv-pp-display.component.scss'],
  templateUrl: './mv-pp-display.component.html',
})
export class MvPpDisplayComponent {
  @Input() textHolder!: any;

  @Input() technicalName?: MatrixColumnTechnicalName;
}
