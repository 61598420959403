<div class="chart-info-tile-container" [ngClass]="{ extended: isExtended }">
  <div class="display-name">
    {{ displayName }}
  </div>
  <div class="selected-series" *ngIf="selectedSeries">
    {{ selectedSeries }}
  </div>
  <div class="value-display-area">
    <div class="display-value">
      {{ displayValue | textAndNumberDisplay: '1.3-3' }}
    </div>
    <div *ngIf="displaySubValue" class="display-sub-value"><span class="divider"></span> {{ displaySubValue }}</div>
  </div>
  <div>
    <span
      *ngIf="!displayAbsValue"
      class="display-sub-value {{ displayNameDetailsColor }}"
      [innerHtml]="displayNameDetailsValue"
    >
    </span>
    {{ displayNameDetails }}
  </div>
  <div *ngIf="displayAbsValue">
    <span class="display-name-details {{ displayNameDetailsColor }}">{{ addPlusSign(displayAbsValue) }}</span> |
    <span class="display-name-details">{{ displayRatioValue }}%</span>
  </div>
</div>
