import {
  CurrentFinancialTechnicalName,
  CurrentPricingTechnicalName,
  ForecastedFinancialTechnicalName,
} from '../../enums/matrix-column-technical-name.enum';
import {
  FetchMatrixViewColumnsDataApiError,
  MapMatrixViewColumnsDataError,
} from 'src/app/core/error-handling/errors/error-functions';
import { FetchResult } from '@apollo/client/core';
import { GovernanceService } from '../governance/governance.service';
import { GranularityType } from '../../enums/granularity-type.enum';
import {
  ICreateUpdatePricePointMutationVariables,
  IMatrixColumnsCustomOrderGQL,
  IMatrixColumnsCustomOrderQuery,
  IMatrixViewColumnsGQL,
  IMatrixViewColumnsQuery,
  IPricePointInput,
  IScenarioDataWithPricePoint,
  IUpdateMatrixColumnsOrderGQL,
  IUpdateMatrixColumnsOrderMutationVariables,
} from 'src/app/graphql/services/gql-api.service';
import {
  ICrossCheckResults,
  IGranularity,
  IPriceEditorData,
  IScenarioMetaDataBuilder,
  ISelectedFiltersFormConfirmed,
  MatrixViewDataToRefresh,
} from '../../models/app.model';
import {
  IMatrixColumnConfig,
  IMatrixViewConfig,
  IMatrixViewCustomConfig,
  IMatrixViewDataSourceItem,
  MatrixViewDataSource,
} from '../../models/matrix-view.model';
import { Injectable } from '@angular/core';
import { MATRIX_VIEW_COLUMNS_TREE_TRANSFORMATION_CONFIG } from '../../constants/matrix-view-columns.config';
import { MatrixColumnType } from '../../enums/matrix-column-type.enum';
import { MatrixViewDataFormattingService } from '../matrix-view-data-formatting/matrix-view-data-formatting.service';
import { Observable, Subject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { error } from 'src/app/core/error-handling/functional-error-handling/error';
import sortby from 'lodash.sortby';

type ReType<T, K extends string> = T & { [P in K]?: ReType<T, K>[] };

interface ITreeOption<T extends object> {
  id: keyof T;
  children: keyof T;
}
@Injectable({
  providedIn: 'root',
})
/**
 * @class MatrixViewDataService
 */
export class MatrixViewDataService {
  private granularityToExpandOrCollapseSubject: Subject<IGranularity> = new Subject<IGranularity>();
  private currentSelectedFilters!: ISelectedFiltersFormConfirmed;
  private dataSource!: MatrixViewDataSource;
  matrixViewColumnsConfig: Subject<IMatrixColumnConfig[]> = new Subject<IMatrixColumnConfig[]>();

  /**
   * Observable Subject that notify matrix-view to update the specified granularities & KPI's values.
   *  - By specifying an undefined value for field {@link MatrixViewDataToRefresh.kpiTechnicalsNamesToRefreshData}, all available KPI will be requested on the server.
   *  - By specifying a null / undefined value for field {@link MatrixViewDataToRefresh.granularitiesToRefreshData} all displayed granularities will be considered.
   */
  public readonly refreshMatrixViewDataSubject$: Subject<MatrixViewDataToRefresh> = new Subject();

  /**
   * @constructor
   * @param matrixViewColumnsOrder
   * @param matrixViewColumns
   * @param updateMatrixColumnsOrderGQL
   * @param governanceService
   * @param matrixViewDataFormattingService
   */
  constructor(
    private matrixViewColumnsOrder: IMatrixColumnsCustomOrderGQL,
    private matrixViewColumns: IMatrixViewColumnsGQL,
    private updateMatrixColumnsOrderGQL: IUpdateMatrixColumnsOrderGQL,
    private governanceService: GovernanceService,
    private matrixViewDataFormattingService: MatrixViewDataFormattingService
  ) {}

  /**
   * UnderscoreToCamelCase
   * @param str
   */
  underscoreToCamelCase(str: string): string {
    return str.replace(/_([a-z])/g, (match, letter) => {
      return letter.toUpperCase();
    });
  }

  /**
   * Get filter values from filter graphql api.
   * @returns Observable<MatrixViewConfig> List of filter values.
   */
  getMatrixViewColumns(): Observable<IMatrixViewConfig> {
    return this.matrixViewColumns.fetch().pipe(
      map((res) => {
        const columns = res.data.matrixColumns;
        return columns.map((column) => {
          return { ...column, technicalName: this.underscoreToCamelCase(column.technicalName!) };
        });
      }),
      map((res: IMatrixColumnConfig[]) => {
        return {
          columns: this.transformDataToTreeView(res, MATRIX_VIEW_COLUMNS_TREE_TRANSFORMATION_CONFIG),
        };
      }),
      catchError((error) => {
        if (error.graphqlErrors || error.networkError) {
          return throwError(FetchMatrixViewColumnsDataApiError);
        } else {
          return throwError(MapMatrixViewColumnsDataError);
        }
      })
    );
  }

  /**
   * BuildMatrixViewColumnsTree
   * @param columns
   * @returns IMatrixColumnConfig[]
   */
  buildMatrixViewColumnsTree(columns: IMatrixColumnConfig[]): IMatrixColumnConfig[] {
    const filteredColumns = columns
      .map((column: IMatrixColumnConfig) => {
        const col = { ...column };
        if (col.metadata) {
          col.metadata.config = JSON.parse(col.metadata.config as unknown as string);
          col.unit = col.metadata.config.unit || 'None';
        }
        col.technicalName = this.underscoreToCamelCase(column.technicalName);

        if (col.technicalName === CurrentPricingTechnicalName.list_price_incl_tax) {
          this.matrixViewDataFormattingService.setMatrixViewCurrency(col.unit || 'EUR');
        }

        return col;
      })
      .filter((column: IMatrixColumnConfig) => column.isVisible === true);
    return this.transformDataToTreeView(filteredColumns, MATRIX_VIEW_COLUMNS_TREE_TRANSFORMATION_CONFIG);
  }

  /**
   * GetMatrixViewColumnsOrdered
   */
  getMatrixViewColumnsOrdered(market: string, scenarioId: string): Observable<IMatrixViewCustomConfig> {
    return this.matrixViewColumnsOrder.fetch({ market, scenarioId }).pipe(
      map((response: FetchResult<IMatrixColumnsCustomOrderQuery> | any) => {
        return response.data;
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * SetMatrixViewColumns
   * @param updateMatrixColumnsOrderMutationVariables
   */
  setMatrixViewColumns(
    updateMatrixColumnsOrderMutationVariables: IUpdateMatrixColumnsOrderMutationVariables
  ): Observable<IMatrixViewColumnsQuery> {
    return this.updateMatrixColumnsOrderGQL.mutate(updateMatrixColumnsOrderMutationVariables).pipe(
      map((response: FetchResult<IMatrixViewColumnsQuery> | any) => {
        return response.data;
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * UpdateMatrixViewColumns
   * @param matrixColumnConfig
   */
  updateMatrixViewColumns(matrixColumnConfig: IMatrixColumnConfig[]): void {
    const tree = this.buildMatrixViewColumnsTree(matrixColumnConfig);
    this.matrixViewColumnsConfig.next(tree);
  }

  /**
   * TransformMatrixViewColumnsLevel2Flat
   * @param matrixColumnConfig
   */
  transformMatrixViewColumnsLevel2Flat(matrixColumnConfig: IMatrixColumnConfig[]): IMatrixColumnConfig[] {
    return matrixColumnConfig
      .filter((column: IMatrixColumnConfig): boolean => column.columnType === MatrixColumnType.NAME)
      .flatMap((column: IMatrixColumnConfig) => column.children as IMatrixColumnConfig[]);
  }

  /**
   * TransformDataToTreeView
   * @param list
   * @param options
   * @return R[]
   */
  transformDataToTreeView<
    T extends object,
    C extends Pick<ITreeOption<T>, 'id'> & {
      parentId: keyof T;
      sortBy: keyof T;
      children: string & keyof R;
    },
    R extends ReType<T, C['children']>
  >(list: T[], options: C): R[] {
    let res: R[] = [];

    if (!list) {
      return res;
    }

    try {
      const itemsList: T[] = structuredClone(list);
      const fullMap = new Map<T[C['id']], T>(itemsList.map((v) => [v[options.id], v]));

      for (const node of itemsList) {
        const parent: R = fullMap.get(node[options.parentId]) as R;
        if (parent) {
          if (!parent[options.children]) {
            parent[options.children] = [] as any;
          }
          parent[options.children]!.push(node as any);
          if (options.sortBy) {
            parent[options.children] = sortby(parent[options.children], options.sortBy) as any;
          }
        } else {
          res.push(node as any);
          if (options.sortBy) {
            res = sortby(res, options.sortBy) as any;
          }
        }
      }
    } catch {
      throw error(MapMatrixViewColumnsDataError);
    }
    return res;
  }

  /**
   * SetCurrentSelectedFilters
   * @param currentSelectedFilters
   */
  setCurrentSelectedFilters(currentSelectedFilters: ISelectedFiltersFormConfirmed): void {
    this.currentSelectedFilters = currentSelectedFilters;
  }

  /**
   * GetCurrentSelectedFilters
   * @return SelectedFiltersFormConfirmed
   */
  public getCurrentSelectedFilters(): ISelectedFiltersFormConfirmed {
    return this.currentSelectedFilters;
  }

  /**
   * SetCurrentDataSource
   * @param currentDataSource
   */
  setCurrentDataSource(currentDataSource: MatrixViewDataSource): void {
    this.dataSource = currentDataSource;
  }

  /**
   * GetCurrentDataSource
   * @return MatrixViewDataSource
   */
  public getCurrentDataSource(): MatrixViewDataSource {
    return this.dataSource;
  }

  /**
   * BuildSingleDataSourceItem
   * @param granularity
   * @param scenarioOutput
   * @param priceEditorData
   * @param currentCrossCheckResults
   * @return CrossCheckResults
   */
  buildSingleDataSourceItem(
    granularity: IGranularity,
    scenarioOutput?: IScenarioDataWithPricePoint,
    priceEditorData?: IPriceEditorData,
    currentCrossCheckResults?: ICrossCheckResults
  ): IMatrixViewDataSourceItem {
    return {
      currentCrossCheckResults,
      granularity,
      id: granularity?.id,
      priceEditorData,
      scenarioOutput,
    };
  }

  /**
   * BuildSingleDataSourceItemWithLoaderIndicator
   * @param granularity
   * @param isLoading
   * @param existingDataSourceItem
   * @return MatrixViewDataSourceItem
   */
  buildSingleDataSourceItemWithLoaderIndicator(
    granularity: IGranularity,
    isLoading: boolean,
    existingDataSourceItem?: IMatrixViewDataSourceItem
  ): IMatrixViewDataSourceItem {
    return existingDataSourceItem?.id
      ? {
          ...existingDataSourceItem,
          isLoading,
        }
      : {
          granularity,
          id: granularity.id,
          isLoading,
        };
  }

  /**
   * UpdateSingleDataSourceItemWithDataAndStopLoaderIndicator
   * @param existingDataSourceItem
   * @param scenarioData
   * @param priceEditorData
   * @param scenarioMetaData
   * @param currentCrossCheckResults
   * @return MatrixViewDataSourceItem
   */
  updateSingleDataSourceItemWithDataAndStopLoaderIndicator(
    existingDataSourceItem: IMatrixViewDataSourceItem,
    scenarioData?: IScenarioDataWithPricePoint,
    priceEditorData?: IPriceEditorData,
    scenarioMetaData?: IScenarioMetaDataBuilder,
    currentCrossCheckResults?: ICrossCheckResults
  ): IMatrixViewDataSourceItem {
    existingDataSourceItem.isLoading = false;

    if (scenarioData) {
      existingDataSourceItem.scenarioOutput = scenarioData;
    }

    if (priceEditorData) {
      existingDataSourceItem.priceEditorData = priceEditorData;
    }

    if (scenarioMetaData) {
      existingDataSourceItem.scenarioMetaData = scenarioMetaData;
    }

    if (currentCrossCheckResults) {
      existingDataSourceItem.currentCrossCheckResults = currentCrossCheckResults;
    }

    return existingDataSourceItem;
  }

  /**
   * SetDataSourceItemsLoaderIndicator
   * @param currentPage
   * @param isLoading
   */
  setDataSourceItemsLoaderIndicator(currentPage: number, isLoading: boolean): void {
    const updatedDataSourceItemsWithLoaderIndicator = this.dataSource.getData().map((dataItem) => ({
      ...dataItem,
      isLoading,
    }));
    this.dataSource.setData(currentPage, [...updatedDataSourceItemsWithLoaderIndicator]);
  }

  /**
   * SetSingleDataSourceItemLoaderIndicator
   * @param existingDataSourceItem
   * @param isLoading
   * @return MatrixViewDataSourceItem
   */
  setSingleDataSourceItemLoaderIndicator(
    existingDataSourceItem: IMatrixViewDataSourceItem,
    isLoading: boolean
  ): IMatrixViewDataSourceItem {
    return {
      ...existingDataSourceItem,
      isLoading,
    };
  }

  /**
   * SetSingleDataSourceItemAsExpandedOrCollapsed
   * @param existingDataSourceItem
   * @param shouldExpandRow
   * @return MatrixViewDataSourceItem
   */
  setSingleDataSourceItemAsExpandedOrCollapsed(
    existingDataSourceItem: IMatrixViewDataSourceItem,
    shouldExpandRow: boolean
  ): IMatrixViewDataSourceItem {
    return {
      ...existingDataSourceItem,
      isExpanded: shouldExpandRow,
    };
  }

  /**
   * SendGranularityToExpandOrCollapse
   * @param granularity
   */
  sendGranularityToExpandOrCollapse(granularity: IGranularity): void {
    if (granularity.type === GranularityType.ESERIES) {
      granularity.type = GranularityType.E_SERIES;
    }
    if (granularity.childrenType === GranularityType.ESERIES) {
      granularity.childrenType = GranularityType.E_SERIES;
    }
    this.granularityToExpandOrCollapseSubject.next(granularity);
  }

  /**
   * ListenForGranularityToExpandOrCollapse
   * @return Observable<Granularity>
   */
  listenForGranularityToExpandOrCollapse(): Observable<IGranularity> {
    return this.granularityToExpandOrCollapseSubject.asObservable();
  }

  /**
   * Map All Scenario Sources and return a datasource joined item
   * mapAllScenarioSources
   *
   * @private
   * @param dataSourceItem
   * @param scenarioMatrixValueDataList
   * @return MatrixViewDataSourceItem
   */
  mapAllScenarioSources(
    dataSourceItem: IMatrixViewDataSourceItem,
    scenarioMatrixValueDataList: IScenarioDataWithPricePoint[]
  ): IMatrixViewDataSourceItem {
    const scenarioData: IScenarioDataWithPricePoint = scenarioMatrixValueDataList.find(
      (item: IScenarioDataWithPricePoint): boolean => item.filterId === dataSourceItem.id
    ) as IScenarioDataWithPricePoint;
    // Set PriceEditor Data
    const priceData: IPriceEditorData = this.governanceService.priceEditorDataBuilder(scenarioData);

    // Set ScenarioMetaData
    const scenarioMetaData: IScenarioMetaDataBuilder = this.governanceService.scenarioMetaDataBuilder(scenarioData);

    // NOTE: This mock is not being used
    return scenarioData && priceData && scenarioMetaData
      ? this.updateSingleDataSourceItemWithDataAndStopLoaderIndicator(
          dataSourceItem,
          scenarioData,
          priceData,
          scenarioMetaData
        )
      : dataSourceItem;
  }

  /**
   * NotifyMatrixViewToReflectPriceChanges
   * @param priceSubmissionList
   */
  notifyMatrixViewToReflectPriceChanges(priceSubmissionList?: ICreateUpdatePricePointMutationVariables): void {
    const pricePointsArray: IPricePointInput[] = priceSubmissionList?.pricePoints as IPricePointInput[];
    const dataToRefresh: IGranularity[] = [];

    pricePointsArray?.forEach((pricePoint: IPricePointInput): void => {
      this.dataSource
        .getData()
        .filter((item: IMatrixViewDataSourceItem): boolean => item.granularity.id === pricePoint.filterId)
        .forEach((item: IMatrixViewDataSourceItem) => dataToRefresh.push(item.granularity));
    });

    this.refreshMatrixViewDataSubject$.next({
      granularitiesToRefreshData: dataToRefresh,
      kpiTechnicalsNamesToRefreshData: [
        CurrentFinancialTechnicalName.expected_volume_retail,
        ForecastedFinancialTechnicalName.delta_expected_volume_retail_current_adj,
        ForecastedFinancialTechnicalName.delta_total_cm_abs,
      ],
    });
  }
}
