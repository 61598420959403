import { Accessible, CrossCheckContextType, StockPriceLevel } from '../../../../graphql/services/gql-api.service';
import { ActivatedRoute, Data } from '@angular/router';
import { Base64Utils } from '../../../../common/utils/base64.utils';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../../../../matrix-view/services/context/context.service';
import { CrossCheckContextTypeMap, CrosschecksContext } from '../../../enums/crosschecks-context.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import {
  CrosschecksTrafficLightUIProps,
  IGranularityDto,
  IStockTooltips,
  ITooltipDataResponse,
} from '../../../../matrix-view/models/api.model';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { TrafficLightsArrow, TrafficLightsColor } from '../../../types/traffic-lights.type';

@Component({
  selector: 'app-stock-page',
  templateUrl: './stock-page.component.html',
})
export class StockPageComponent implements OnInit, OnDestroy {
  selectedTab: number = 0;
  context: CrosschecksContext = CrosschecksContext.CURRENT;

  tooltips?: IStockTooltips;
  private subscriptions: Subscription[] = [];
  private stockTabs: string[] = ['stock-coverage', 'stock-mix', 'stock-age'];
  stockCoverageStatus: TrafficLightsArrow = 'keep';
  crosscheckIndication?: TrafficLightsColor;
  crosscheckIndicationForecasted?: TrafficLightsColor;

  trafficLightStockCoverage?: CrosschecksTrafficLightUIProps;
  trafficLightStockAge?: CrosschecksTrafficLightUIProps;

  _filters: string = '';
  @Input() set filters(value: string) {
    this._filters = this.getParamMapId;
    this.loadTrafficLights();
    this.loadTooltips();
    this.loadTrafficLightsOverview();
  }

  get filters(): string {
    return this._filters;
  }

  /**
   * Constructor
   * @param route
   * @param location
   * @param crosscheckService
   * @param contextService
   */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private crosscheckService: CrosschecksService,
    private contextService: ContextService
  ) {
    const routeSubscription: Subscription = this.route.data.subscribe((data: Data): void => {
      this.selectedTab = data.index;
      this.context = data.context;
    });

    this.subscriptions.push(routeSubscription);
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.filters = this.getParamMapId;
      this.loadTrafficLights();
    });
    this.loadTooltips();
    this.loadTrafficLightsOverview();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * ChangeTab
   * @param changeEvent
   */
  changeTab(changeEvent: number): void {
    const changedTabURL: string = `/crosschecks/${this.context}/stock/${this.stockTabs[changeEvent]}/${this.filters}`;
    this.selectedTab = changeEvent;
    this.location.go(changedTabURL);
  }

  /**
   * IsSelectedTabStockCoverage
   * @return boolean
   */
  get isSelectedTabStockCoverage(): boolean {
    return this.selectedTab === 0;
  }

  /**
   * IsSelectedTabStockMix
   * @return boolean
   */
  get isSelectedTabStockMix(): boolean {
    return this.selectedTab === 1;
  }

  /**
   * IsSelectedTabStockAge
   * @return boolean
   */
  get isSelectedTabStockAge(): boolean {
    return this.selectedTab === 2;
  }

  /**
   * GetParamMapId
   * @private
   * @return string
   */
  private get getParamMapId(): string {
    return this.route.snapshot.paramMap.get('id') ?? '';
  }

  /**
   * LoadTooltips
   * @private
   */
  private loadTooltips(): void {
    const subscription: Subscription = this.crosscheckService.getTooltipData('DE', 'stock').subscribe({
      next: (response: ITooltipDataResponse): void => {
        this.tooltips = response as IStockTooltips;
      },
    });
    this.subscriptions.push(subscription);
  }

  /**
   * LoadTrafficLights
   * @private
   */
  private loadTrafficLights(): void {
    const granularity: IGranularityDto = Base64Utils.decodeAtobToJson(this.filters);

    if (granularity && granularity.id) {
      const trafficLightsSubscription: Subscription = this.crosscheckService
        .getStockTrafficLights(this.contextService.scenarioId, granularity.id, granularity.market)
        .subscribe((trafficLights) => {
          this.crosscheckIndication = trafficLights.trafficLight.color;
          this.crosscheckIndicationForecasted = trafficLights.trafficLightAdj.color;
        });
      this.subscriptions.push(trafficLightsSubscription);
    }
  }

  private loadTrafficLightsOverview(): void {
    const granularity: IGranularityDto = Base64Utils.decodeAtobToJson(this.filters);
    if (granularity.id) {
      const stockCoverageTrafficLightsSubscription: Subscription = this.crosscheckService
        .getStockCoverageTrafficLightsOverview(
          granularity.id,
          granularity.market,
          this.contextService.scenarioId,
          Accessible.TotalStock,
          StockPriceLevel.FirstPriceLevel,
          CrossCheckContextTypeMap.get(this.context) as CrossCheckContextType
        )
        .subscribe((response) => {
          this.trafficLightStockCoverage = response;
        });
      this.subscriptions.push(stockCoverageTrafficLightsSubscription);

      const stockAgeTrafficLightsSubscription: Subscription = this.crosscheckService
        .getStockAgeTrafficLightsOverview(
          granularity.id,
          granularity.market,
          this.contextService.scenarioId,
          Accessible.TotalStock,
          StockPriceLevel.FirstPriceLevel,
          CrossCheckContextTypeMap.get(this.context) as CrossCheckContextType
        )
        .subscribe((response) => {
          this.trafficLightStockAge = response;
        });
      this.subscriptions.push(stockAgeTrafficLightsSubscription);
    }
  }
}
