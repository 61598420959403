export type keyofRetailSalesSeriesName = keyof RetailTimeSeriesProps;

export enum RetailTimeSeriesProps {
  actualsThisYear = 'actualsThisYear',
  actualsPreviousYear = 'actualsPreviousYear',
  retailBudget = 'retailBudget',
  runRateExtrapolYtd = 'runRateExtrapolYtd',
  enginePredictedRetailVolume = 'enginePredictedRetailVolume',
  expectedVolumeRetailAdjTS = 'expectedVolumeRetailAdjTS',
  retailLatestForecast = 'retailLatestForecast',
  retailSecondLatestForecast = 'retailSecondLatestForecast',
}

export enum RetailSeriesDisplayName {
  actualsThisYear = 'Actuals this year',
  actualsPreviousYear = 'Actuals previous year',
  retailBudget = 'Retail budget',
  enginePredictedRetailVolume = 'Retail NCPE-Estimation (current price)',
  expectedVolumeRetailAdjTS = 'Retail NCPE-Estimation (scenario price)',
  retailLatestForecast = 'Retail forecast',
  retailSecondLatestForecast = 'Retail forecast',
}
