import { APP_SYMBOLS } from '../../../../../constants/app-symbols';
import { AppThemeColors } from 'src/app/common/constants/app-theme-colors';
import {
  AxisRenderer,
  AxisRendererX,
  AxisRendererY,
  CategoryAxis,
  LineSeries,
  ValueAxis,
} from '@amcharts/amcharts5/xy';
import { DataItem, Theme } from '@amcharts/amcharts5';
import { FtdAm5ChartsThemeHelper } from '../../ftd-am5-charts.theme';
import { FtdLineChartComponent } from '../../../charts/ftd-line-chart/ftd-line-chart.component';
import { GenericChartFields } from '../../../models/ftd-generic-chart.model';
import { ISeriesDataItem } from '@amcharts/amcharts5/.internal/core/render/Series';
import { am5 } from '../../../ftd-base-chart.abstract';

export class FtdInternalPriceLadderCustomTheme extends Theme {
  setupDefaultRules() {
    super.setupDefaultRules();
    this.setupValueAxisRules();
    this.setupLineRules();
    this.setupCategoryAxisRules();
    this.setLabelRules();
    this.setXYChartRules();
  }

  private setXYChartRules(): void {
    this.rule('XYChart').setAll({
      paddingTop: 0,
      panX: true,
      panY: true,
      wheelX: 'panX',
      wheelY: 'zoomY',
    });

    // Fix zoom out button feature to match current selection
    this.rule('XYChart').events.once('boundschanged', (event) => {
      event.target.zoomOutButton.events.removeType('click');
      event.target.zoomOutButton.events.on('click', (click) => {
        const categoryAxis = event.target.xAxes.getIndex(0) as CategoryAxis<AxisRendererX>;
        const index = categoryAxis.dataItems.findIndex((dataItem: DataItem<ISeriesDataItem>) => {
          return dataItem.get('visible');
        });
        const valueAxis = event.target.yAxes.getIndex(0) as ValueAxis<AxisRendererY>;
        const series = event.target.series.getIndex(0) as LineSeries;

        categoryAxis.zoomToIndexes(index, index + 6);
        valueAxis.setAll({
          max: series.getPrivate('selectionMaxY')!,
          min: series.getPrivate('selectionMinY')!,
        });
        valueAxis.zoom(0, 1);
      });
    });
  }

  private setLabelRules() {
    this.rule('Label').setAll({
      fontFamily: 'BMWGroupTNCondensedPro-Regular',
      fontSize: '16px',
      fontWeight: 'normal',
    });

    this.rule('AxisLabel', ['x']).adapters.add(
      'x',
      (x: number | am5.Percent | null | undefined, label: am5.Label): number | am5.Percent | null | undefined => {
        if (Number(x) < 0) {
          label.dataItem?.set('visible', false);
        }
        return x;
      }
    );
  }

  protected setupValueAxisRules() {
    this.rule('ValueAxis').setAll({
      autoZoom: false,
      extraMax: 0.2,
      logarithmic: true,
      maskContent: true,
      zoomX: false,
      zoomY: true,
    });

    // Update scale when zooming out
    this.rule('ValueAxis').on('end', (value: number | undefined, axis: ValueAxis<AxisRenderer> | undefined): void => {
      if (Number(value) > 1.1) {
        axis?.setAll({
          max: undefined,
          min: undefined,
        });
      }
    });

    this.rule('CategoryAxis').events.once('datavalidated', (event): void => {
      const currencySymbol: string = event.target.get('userData')[0]?.currency;

      event.target.chart?.yAxes
        .getIndex(0)
        ?.set(
          'numberFormatter',
          am5.NumberFormatter._new(event.target.root, { numberFormat: `#,###${currencySymbol}` })
        );
    });
  }

  /**
   * Draw vertical lines on each category
   * @private
   */
  private setupCategoryAxisRules() {
    this.rule('CategoryAxis').setAll({
      maxZoomCount: 6,
      zoomX: false,
      zoomY: false,
    });

    this.rule('CategoryAxis').events.once('datavalidated', (event) => {
      event.target.zoomToIndexes(0, 6);
    });

    this.rule('AxisRendererX').setup = (renderer) => {
      renderer.grid.template.setAll({
        location: 0.5,
        stroke: am5.color('#fff'),
        strokeOpacity: 1,
        strokeWidth: 1,
        visible: true,
      });
    };
  }

  private setupLineRules() {
    this.rule('LineSeries').setAll({
      maskBullets: true,
      maskContent: true,
    });

    this.rule('LineSeries').events.once('datavalidated', (event) => {
      if (event.target.get('valueYField')) {
        event.target.setAll({
          fill: am5.color('#fff'),
          opacity: 0,
          stroke: am5.color('#fff'),
          width: 1,
        });

        /**
         * Extract {@link FtdLineChartComponent} component from attached click callback
         */
        const context: FtdLineChartComponent = (
          event.target.events as unknown as { _listeners: any[] }
        )._listeners.find((eventItem) => eventItem.context !== undefined).context;

        // First clear all previous default-chart's Bullets
        event.target.bullets.clear();

        // Now, draw custom price-point's Bullets
        event.target.bullets.push((root: am5.Root, dataSeries: am5.Series, dataItem: DataItem<ISeriesDataItem>) =>
          this.drawCustomPricePointBullet(root, dataSeries, dataItem, context)
        );

        // Draw labels alternating side of price-point's Bullets
        event.target.bullets.push((root: am5.Root, dataSeries, dataItem): am5.Bullet | undefined =>
          this.drawModelNameLabelBullet(root, dataSeries, dataItem)
        );

        // Draw labels alternating side of price-point's Bullets
        event.target.bullets.push((root: am5.Root, dataSeries, dataItem): am5.Bullet | undefined =>
          this.drawPricePointLabelBullet(root, dataSeries, dataItem)
        );
      }
    });
  }

  private drawCustomPricePointBullet(
    root: am5.Root,
    dataSeries: am5.Series,
    dataItem: DataItem<ISeriesDataItem>,
    context: FtdLineChartComponent
  ): am5.Bullet {
    const dataField: GenericChartFields = dataItem.dataContext as GenericChartFields;
    const pricePointColor = dataField?.pricePointColor;
    const pricePointBullet = am5.Picture.new(root, {
      centerX: 10,
      centerY: 18,
      height: 20,
      src: `/assets/images/charts/bullets/price-point-${(pricePointColor as string)?.toLowerCase()}.svg`,
      tooltip: FtdAm5ChartsThemeHelper.getTooltip(root, 'horizontal', true),
      tooltipHTML: this.buildPricePointTooltip(dataField),
      width: 20,
    });

    /**
     * Use {@link FtdLineChartComponent} to send data through emitter
     */
    pricePointBullet.events.on('click', (clickEvent) => {
      context.seriesOnClick.emit(clickEvent.target.dataItem?.dataContext);
    });
    return am5.Bullet.new(root, {
      sprite: pricePointBullet,
    });
  }

  private drawPricePointLabelBullet(
    root: am5.Root,
    dataSeries: am5.Series,
    dataItem: DataItem<ISeriesDataItem>
  ): am5.Bullet | undefined {
    const dataField: GenericChartFields = dataItem.dataContext as GenericChartFields;
    const list = dataSeries.dataItems.filter((item: am5.DataItem<ISeriesDataItem>) => {
      return (item.dataContext as GenericChartFields).xAxisValue === dataField.xAxisValue;
    });

    const isLeftSide: boolean = list.indexOf(dataItem) % 2 !== 0;

    const sprite = am5.Label.new(root, {
      background: dataField.isAdjustedPrice
        ? am5.RoundedRectangle.new(root, {
            cornerRadiusBL: 2,
            cornerRadiusBR: 2,
            cornerRadiusTL: 2,
            cornerRadiusTR: 2,
            dx: 10,
            dy: 8,
            fill: am5.color(0xffe38e),
            fillOpacity: 1,
            maxHeight: 20,
            scale: 0.73,
            stroke: am5.color(0xffe38e),
            strokeWidth: 3,
          })
        : undefined,
      centerY: 35,
      dx: isLeftSide ? -2 : 2,
      dy: 20,
      fill: dataField.isAdjustedPrice ? am5.color(0x393021) : am5.color(0xffffff),
      populateText: true,
      text: '[fontSize: 12px fontWeight: 400 verticalAlign: super]{value}{currency}[/]',
      textAlign: isLeftSide ? 'right' : 'left',
    });

    return am5.Bullet.new(root, {
      sprite: sprite,
    });
  }

  private drawModelNameLabelBullet(
    root: am5.Root,
    dataSeries: am5.Series,
    dataItem: DataItem<ISeriesDataItem>
  ): am5.Bullet | undefined {
    const dataField: GenericChartFields = dataItem.dataContext as GenericChartFields;
    const list = dataSeries.dataItems.filter((item: am5.DataItem<ISeriesDataItem>) => {
      return (item.dataContext as GenericChartFields).xAxisValue === dataField.xAxisValue;
    });

    const isLeftSide: boolean = list.indexOf(dataItem) % 2 !== 0;

    const sprite = am5.Label.new(root, {
      centerY: 35,
      dx: isLeftSide ? -2 : 2,
      populateText: true,
      text: '[bold fontSize: 14px fontFamily: BMWGroupTNCondensedPro-Bold]{model}[/]',
      textAlign: isLeftSide ? 'right' : 'left',
    });

    return am5.Bullet.new(root, {
      sprite: sprite,
    });
  }

  private buildPricePointTooltip(dataField: GenericChartFields): string {
    const { model, volMix, kw, eRange } = dataField;
    const modelColor = AppThemeColors['ftd-grey']['300'];

    return `
          <div style="background: #282F47;padding: 8px;border-radius: 4px;display: flex;flex-direction: column;row-gap: 14px;">
              <div style="color: ${modelColor};font-family: BMWGroupTNCondensedPro-Regular; font-size: 14px;">
                  ${model}
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-between;gap:47px; font-size:16px;">
                  <span style="font-family: BMWGroupTNCondensedPro-Bold">Volume Mix</span>
                  <span style="font-family: BMWGroupTNCondensedPro-Regular">${
                    volMix ? `${((volMix as number) * 100).toFixed(0)}%` : APP_SYMBOLS.NA
                  }</span>
              </div>
              <div style="display: flex;flex-direction: row;justify-content: space-between;gap:47px; font-size:16px;">
                  <span style="font-family: BMWGroupTNCondensedPro-Bold">kW</span>
                  <span style="font-family: BMWGroupTNCondensedPro-Regular">${
                    kw ? kw.toString() : APP_SYMBOLS.NA
                  }</span>
              </div>
              ${
                eRange
                  ? `<div style="display: flex;flex-direction: row;justify-content: space-between;gap:47px; font-size:16px;">
                  <span style="font-family: BMWGroupTNCondensedPro-Bold">E-range</span>
                  <span style="font-family: BMWGroupTNCondensedPro-Regular">${eRange} km</span>
              </div>`
                  : ''
              }
          </div>`;
  }
}
