import { IMatrixColumnConfig } from '../models/matrix-view.model';

export const MATRIX_VIEW_MAX_PINNED_COLUMNS: number = 3;

export const MATRIX_VIEW_MIN_PINNED_COLUMNS: number = 1;

export const MATRIX_VIEW_COLUMNS_TREE_TRANSFORMATION_CONFIG: {
  id: keyof IMatrixColumnConfig;
  parentId: keyof IMatrixColumnConfig;
  sortBy: keyof IMatrixColumnConfig;
  children: string & keyof IMatrixColumnConfig;
} = {
  children: 'children',
  id: 'id',
  parentId: 'parentId',
  sortBy: 'position',
};
