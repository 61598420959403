<div class="ftd-tab-label">
  <div class="ftd-tab-title-container">
    <ul class="ftd-tab-label-title">
      <li class="title" attr.data-test="{{ 'ftd-tab-label-' + title.toLowerCase().replace(' ', '-') }}">
        {{ title }}
      </li>
      <li *ngIf="info" class="info">
        <mat-icon alt="Information" [matTooltip]="infoText!">info</mat-icon>
      </li>
    </ul>
  </div>
  <div class="ftd-tab-label-info">
    <div *ngIf="_percentage" class="percentage">{{ _percentage }}%</div>
    <div *ngIf="showDivider" id="tab-actions-divider"></div>
    <app-crosschecks-traffic-lights
      *ngIf="_trafficLights"
      [status]="{ color: _trafficLightColor, arrow: null }"
      [className]="'ftd-tab-label-traffic-light overview'"
    ></app-crosschecks-traffic-lights>
  </div>
</div>
