/* eslint-disable sort-keys-fix/sort-keys-fix */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { AuthService } from 'src/app/auth/services/auth.service';
import { FtdModalSize } from '../../../common/components/ftd-modal/ftd-modal-size.enum';
import { FtdNotifierService } from 'src/app/common/services/ftd-notifier/ftd-notifier.service';
import { IScenario } from 'src/app/graphql/services/gql-api.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog as Mat_dialog, MatDialogRef as Mat_dialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ScenarioDeleteModalComponent } from '../scenario-delete-modal/scenario-delete-modal.component';
import { ScenariosGQLAPIService } from 'src/app/graphql/services/scenarios-gql-api.service';
import { takeUntil, tap } from 'rxjs/operators';

/**
 * Attributes for Scenario List table
 */
interface IScenarioListHeader {
  creationDate: string;
  delete: string;
  scenarioName: string;
}

@Component({
  selector: 'app-scenario-list-modal',
  styleUrls: ['./scenario-list-modal.component.scss'],
  templateUrl: './scenario-list-modal.component.html',
})
export class ScenarioListModalComponent implements OnInit, AfterViewInit, OnDestroy {
  private _router = inject(Router);
  private _dialog = inject(Mat_dialog);
  private _authService = inject(AuthService);
  private _dialogRef = inject(Mat_dialogRef);
  private _notifierService = inject(FtdNotifierService);
  private _scenarioGQLAPIService = inject(ScenariosGQLAPIService);

  /**
   * Mapping for Column header names
   */
  readonly COLUMNS: IScenarioListHeader = {
    scenarioName: 'scenarioName',
    creationDate: 'creationDate',
    delete: 'delete',
  };

  /**
   * List of columns to be displayed
   */
  readonly displayedColumns: IScenarioListHeader[keyof IScenarioListHeader][] = Object.keys(this.COLUMNS);

  /**
   * List of User scenarios
   */
  userScenarios$!: Observable<IScenario[]>;

  /**
   * Currently selected scenario
   */
  selectedScenario!: IScenario;

  /**
   * Data source for Mat table for List scenarios
   */
  scenarioListDataSource = new MatTableDataSource<IScenario>([]);

  /**
   * Subject to unsubscribe subscriptions as soon as component is destroyed
   */
  private _destroy$ = new Subject<unknown>();

  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.scenarioListDataSource.sort = sort;
  }

  ngOnInit(): void {
    // Fetch list of user scenarios for logged in scenario from GraphQL scenarios query
    this.userScenarios$ = this.getUserScenariosByUserId$();
  }

  ngAfterViewInit(): void {
    this.scenarioListDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  ngOnDestroy(): void {
    /*
     *Pass null to insert a value to _destroy$ will complete
     *the subscription as we have used takeUntil
     */
    this._destroy$.next(null);
  }

  /**
   * Fetch list of user scenarios for logged in scenario from GraphQL scenarios query
   * @returns Observable<Scenario[]>
   */
  getUserScenariosByUserId$ = (): Observable<IScenario[]> => {
    return this._scenarioGQLAPIService.scenarioByUserId$().pipe(
      takeUntil(this._destroy$),
      tap((scenarios: IScenario[]) => {
        this.scenarioListDataSource.data = scenarios;
      })
    );
  };

  setSelectedScenario(selectedScenario: IScenario): void {
    this.selectedScenario = selectedScenario;
  }

  deleteScenario(selectedScenario: IScenario): void {
    this._dialogRef.close();
    const _dialogRef = this._dialog.open(ScenarioDeleteModalComponent, {
      data: selectedScenario,
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    _dialogRef
      .afterClosed()
      .pipe(
        takeUntil(this._destroy$),
        tap((_) =>
          this._dialog.open(ScenarioListModalComponent, {
            maxWidth: FtdModalSize.S,
            width: '90%',
          })
        )
      )
      .subscribe();
  }

  openScenario(): void {
    if (this.selectedScenario?.id) {
      this._dialogRef.close(this.selectedScenario);
      this._notifierService.showInfo('Opening selected scenario ...');
      this.redirectToUserScenarioById(this.selectedScenario?.id);
    }
  }

  redirectToUserScenarioById(id: string): void {
    this._router.navigate(['user-scenario', id]);
  }

  private sortingDataAccessor = (scenario: IScenario, sortHeader: string) => {
    if (sortHeader === this.COLUMNS.scenarioName) {
      return scenario.scenarioName.toLowerCase();
    } else if (sortHeader === this.COLUMNS.creationDate) {
      return scenario.creationDate;
    }
    return '';
  };
}
