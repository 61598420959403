import { AfterViewChecked, ChangeDetectorRef, Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[appFtdScrollToBottom]',
})
export class FtdScrollToBottomDirective implements AfterViewChecked {
  constructor(private changeDetector: ChangeDetectorRef, private _el: ElementRef) {}

  ngAfterViewChecked() {
    this.scrollToBottom();
  }

  public scrollToBottom() {
    const el: HTMLDivElement = this._el.nativeElement;
    el.scrollTop = Math.max(0, el.scrollHeight - el.offsetHeight);
  }
}
