<app-ftd-custom-modal>
  <app-ftd-custom-modal-header>
    <h3 class="modal-title">Where we are...</h3>
  </app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body>
    <app-price-approval-status-chip-modal-item
      *ngFor="let item of stepList"
      [item]="item"
    ></app-price-approval-status-chip-modal-item>
  </app-ftd-custom-modal-body>
</app-ftd-custom-modal>
