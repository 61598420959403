<mat-dialog-actions>
  <div class="mat-dialog-action">
    <ng-content select="[start]"></ng-content>
  </div>
  <div class="mat-dialog-action">
    <button
      id="ftd-custom-modal-cancel-btn"
      [attr.data-test]="'custom-modal-cancel-btn'"
      *ngIf="!hideCancelButton"
      mat-button
      color="primary"
      (click)="handleCancelClick($event)"
      mat-dialog-close
    >
      {{ cancelButtonText }}
    </button>
    <ng-content></ng-content>
  </div>
</mat-dialog-actions>
