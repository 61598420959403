import { AuthService } from '../../../../../auth/services/auth.service';
import { Component, Inject, OnDestroy } from '@angular/core';
import { EditDiscountsModalComponent } from '../../modals/edit-discounts-modal/edit-discounts-modal.component';
import { FtdModalSize } from '../../../../../common/components/ftd-modal/ftd-modal-size.enum';
import { GovernanceService } from '../../services/governance/governance.service';
import { ISnackbarDiscountActionsData } from '../../models/discount-actions-toolbar.model';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../../../../auth/models/user.model';

@Component({
  selector: 'app-discount-matrix-view-actions-toolbar',
  styleUrls: ['./matrix-view-actions-toolbar.component.scss'],
  templateUrl: './matrix-view-actions-toolbar.component.html',
})
/**
 * @class DiscountMatrixViewActionsToolbarComponent
 */
export class DiscountMatrixViewActionsToolbarComponent implements OnDestroy {
  public currentUser!: User | null;
  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param data
   * @param snackBarRef
   * @param dialog
   * @param authService
   * @param governanceService
   */
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarDiscountActionsData,
    private snackBarRef: MatSnackBarRef<DiscountMatrixViewActionsToolbarComponent>,
    public dialog: MatDialog,
    private authService: AuthService,
    public governanceService: GovernanceService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * OnClose
   */
  onClose(): void {
    this.snackBarRef.dismiss();
  }

  /**
   * OnEditDiscountsClicked
   */
  onEditDiscountsClicked(): void {
    this.openEditDiscountsModal();
  }

  /**
   * OpenEditDiscountsModal
   */
  openEditDiscountsModal(): void {
    const dialogRef: MatDialogRef<EditDiscountsModalComponent> = this.dialog.open(EditDiscountsModalComponent, {
      data: {
        discount: this.data.discount,
      },
      width: FtdModalSize.SM,
    });

    const subscription: Subscription = dialogRef.afterClosed().subscribe((): void => {});
    this.subscriptions.push(subscription);
  }
}
