export enum ChipSize {
  EXTRA_SMALL = 'extra-small',
  SMALL = 'small',
  DEFAULT = 'default',
  LARGE = 'large',
  EXTRA_LARGE = 'extra-large',
}

export enum ChipColor {
  ftdInfo200 = '#0071C5',
}
