import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { FormValidation } from 'src/app/core/validators';
import { IFtdDropdownOption } from 'src/app/common/models/ftd-dropdown-option.model';
import { Observable } from 'rxjs';
import { User } from 'src/app/auth/models/user.model';
import { filter, map } from 'rxjs/operators';

/**
 * Request format required to create new scenario
 */
export interface INewScenarioRequest {
  /**
   * Name of the new scenario to be created
   */
  scenarioName: string;
  /**
   * User ID of the user who is creating new scenario
   */
  userId: string;
  /**
   * Scenario ID of the existing User scenario
   */
  userScenarioId: string;
  /**
   * Whether user wan't to copy scenario from existing user scenario
   */
  isUseExistingUserScenarioChecked: boolean;
}

/**
 * Form Type for new scenario creation
 */
export type INewScenarioForm = {
  [key in keyof INewScenarioRequest]: FormControl<INewScenarioRequest[key]>;
};

/**
 * New Scenario form class which extends from a fom validation (contains utility and typings)
 * @param _formBuilder FormBuilder instance to create form
 * @param _userId User ID who is creating new scenario
 * @param _scenarios$ Stream of List of scenarios
 */
export class NewScenarioForm extends FormValidation<INewScenarioForm, INewScenarioRequest> {
  constructor(
    private _formBuilder: FormBuilder,
    private _userId: User['sub'],
    private _scenarios$: Observable<IFtdDropdownOption<string>[]>
  ) {
    super();

    /**
     * Create form instance which can be accesses as new NewScenarioForm(formBuilder, userId, scenarios).form
     */
    this.form = this._formBuilder.nonNullable.group(
      {
        /**
         * Checks if the checkbox for using existing scenario is checked
         * isUseExistingUserScenarioChecked - boolean - default (false)
         */
        isUseExistingUserScenarioChecked: [false],
        /**
         * Adds validators as required, max length of 100 chars and should not be already taken
         * scenarioName - string - default ('')
         */
        scenarioName: [
          '',
          [Validators.required, Validators.maxLength(100)],
          [alreadyTakenValidator.bind(this)(this._scenarios$)],
        ],
        /**
         * Adds validation to be required field
         * userId - string - default (current logged in user)
         */
        userId: [this._userId, [Validators.required]],
        /**
         * Adds validation to be required field
         * userScenarioId - string - Adds validation to be required field
         */
        userScenarioId: [''],
      },
      {
        validators: [noUserScenarioSelectedWhenCheckboxTicked.bind(this)()],
      }
    );
  }

  /**
   * Validation error to be displayed while hovering on submit button
   */
  get formInvalidMessage(): string {
    if (!this.control('scenarioName').value) {
      return 'New scenario name is required';
    } else {
      if (this.control('isUseExistingUserScenarioChecked').value) {
        return 'Please select existing user scenario to copy from';
      } else {
        return '';
      }
    }
  }

  /**
   * Validation error to be displayed on scenarioName input box
   */
  get scenarioNameErrorMessage(): string {
    if (this.control('scenarioName').errors?.isAlreadyTaken) {
      return 'This scenario name is already taken';
    }

    if (this.hasRequiredError('scenarioName')) {
      return 'The name for the New Scenario is required';
    }

    return '';
  }

  /**
   * Validation error to be displayed on userScenario dropdown
   */
  get userScenarioIdErrorMessage(): string {
    return this.control('userScenarioId').errors?.noScenarioSelected ? 'Please select an existing user scenario.' : '';
  }
}

/**
 * Validates if a scenario with the same name already exists
 */
export const alreadyTakenValidator = (scenarios$: Observable<IFtdDropdownOption<string>[]>): ValidatorFn => {
  return (control: AbstractControl): Observable<{ [key: string]: boolean } | null> => {
    return scenarios$.pipe(
      filter((scenarios) => !!scenarios),
      map((scenarios: IFtdDropdownOption<string>[]) =>
        scenarios.find((scenario: IFtdDropdownOption<string>) => scenario.label === control.value)
          ? { isAlreadyTaken: true }
          : null
      )
    );
  };
};

/**
 * Validates if checkbox is ticked but no scenario is selected from list
 */
export const noUserScenarioSelectedWhenCheckboxTicked = (): ValidatorFn => {
  return ((formGroup: FormGroup<INewScenarioForm>): ValidationErrors | null => {
    let validationErrors: ValidationErrors | null = null;

    const userScenarioIdControl = formGroup.controls['userScenarioId'];
    const isUseExistingUserScenarioChecked = formGroup.controls['isUseExistingUserScenarioChecked'];

    if (isUseExistingUserScenarioChecked.value === true && !userScenarioIdControl.value) {
      validationErrors = { noScenarioSelected: true };
    }
    userScenarioIdControl.setErrors(validationErrors);
    return validationErrors;
  }) as ValidatorFn;
};
