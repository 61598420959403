import { AuthService } from 'src/app/auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { User } from 'src/app/auth/models/user.model';

@Component({
  selector: 'app-subsidies-and-taxation',
  styleUrls: ['./subsidies-and-taxation.component.scss'],
  templateUrl: './subsidies-and-taxation.component.html',
})
export class SubsidiesAndTaxationComponent implements OnInit {
  rows: FormArray = this.formBuilder.array([]);
  macroeconomicsFormGroup: FormGroup = this.formBuilder.group({
    items: this.rows,
  });

  macroeconomicsData!: any;

  displayedColumns: string[] = [
    'category',
    'description',
    'kpi_influenced',
    'relevance_for',
    'actions_needed',
    'published_on',
    'effect_period',
    'editor',
    'actions',
  ];

  isEditableRow: boolean = false;
  user!: User | null;
  constructor(private formBuilder: FormBuilder, private authService: AuthService) {
    this.user = this.authService.getLoggedInUser();
  }

  ngOnInit() {
    this.macroeconomicsData = {
      data: [
        {
          actions_needed: 'List price car should not exceed 60,000€',
          category: 'EV Subsidies',
          description: '>= 60,000€ Net list price leads to no PHEV or BEV & FCEV subsidies',
          editor: 'QXZ2XJE',
          effect_period: {
            endDate: new Date('12/31/2024'),
            startDate: new Date('01/31/2024'),
          },
          kpi_influenced: 'Gross price',
          published_on: new Date('10/10/2023'),
          relevance_for: 'PHEV, BEV & FCEV',
        },
        {
          actions_needed: 'List price car should not exceed 60,000€',
          category: 'EV Subsidies',
          description: '>= 40,000 and <60,000€',
          editor: 'QXZ2XJE',
          effect_period: {
            endDate: new Date('12/31/2024'),
            startDate: new Date('01/31/2024'),
          },
          kpi_influenced: 'Gross price',
          published_on: new Date('10/10/2023'),
          relevance_for: 'PHEV & BEV',
        },
      ],
      market: 'Germany',
    };
    this.setItemsForm();
  }

  deleteRow(rowIndex: number) {
    this.macroeconomicsData.data.splice(rowIndex, 1);
    this.macroeconomicsData.data = [...this.macroeconomicsData.data];
    const itemsArray = this.macroeconomicsFormGroup.get('items') as FormArray;
    itemsArray.at(rowIndex).reset();
  }

  addEntry() {
    const macroeconomicsEntry = {
      actions_needed: null,
      category: null,
      description: null,
      editor: this.user!.sub,
      effect_period: '',
      kpi_influenced: null,
      published_on: null,
      relevance_for: null,
    };
    this.macroeconomicsData.data = [...this.macroeconomicsData.data, macroeconomicsEntry];
    const itemsArray = this.macroeconomicsFormGroup.get('items') as FormArray;
    const macroeconomicsDataLastIndex = this.macroeconomicsData.data.length - 1;
    itemsArray.push(this.setItemsFormArray(this.macroeconomicsData.data[macroeconomicsDataLastIndex]));
    const rowFormGroup = itemsArray.at(macroeconomicsDataLastIndex);
    rowFormGroup.get('canEdit')?.patchValue(true);
  }

  saveEditedEntry(rowIndex: number) {
    const itemsArray = this.macroeconomicsFormGroup.get('items') as FormArray;
    const rowFormGroup = itemsArray.at(rowIndex) as FormGroup;
    const effectPeriodForm = rowFormGroup.get('effect_period');

    const macroeconomicsEntry = {
      actions_needed: rowFormGroup.get('actions_needed')?.value,
      category: rowFormGroup.get('category')?.value,
      description: rowFormGroup.get('description')?.value,
      editor: this.user!.sub.toUpperCase(),
      effect_period: {
        endDate: effectPeriodForm!.get('endDate')?.value,
        startDate: effectPeriodForm!.get('startDate')?.value,
      },
      kpi_influenced: rowFormGroup.get('kpi_influenced')?.value,
      published_on: rowFormGroup.get('published_on')?.value,
      relevance_for: rowFormGroup.get('relevance_for')?.value,
    };
    this.macroeconomicsData.data[rowIndex] = { ...macroeconomicsEntry };
    this.macroeconomicsData.data = [...this.macroeconomicsData.data];

    itemsArray.at(rowIndex).get('canEdit')?.patchValue(false);
  }

  editRow(rowIndex: number) {
    const itemsFormArray = this.macroeconomicsFormGroup.get('items') as FormArray;
    itemsFormArray.at(rowIndex).get('canEdit')?.patchValue(true);
  }

  getFormGroup(rowIndex: number) {
    const formArray = this.macroeconomicsFormGroup.get('items') as FormArray;
    return formArray.at(rowIndex) as FormGroup;
  }

  canEdit(rowIndex: number) {
    return this.macroeconomicsFormGroup.get('items')?.value[rowIndex].canEdit;
  }

  getEffectPeriodFormGroup(rowIndex: number) {
    const form = this.getFormGroup(rowIndex);
    return form.get('effect_period') as FormGroup;
  }

  closeEdit(rowIndex: number) {
    const itemsArray = this.macroeconomicsFormGroup.get('items') as FormArray;
    const rowFormGroup: FormGroup = itemsArray.at(rowIndex) as FormGroup;
    rowFormGroup.get('canEdit')?.patchValue(false);
    if (rowFormGroup.invalid && this.formHasNullValues(rowIndex)) {
      this.deleteRow(rowIndex);
    }
  }

  formHasNullValues(rowIndex: number) {
    const itemsArray = this.macroeconomicsFormGroup.get('items') as FormArray;
    const rowFormGroup = itemsArray.at(rowIndex) as FormGroup;
    const isFormGroupNull = Object.keys(rowFormGroup.value).find((prop) => rowFormGroup.value[prop] === null);
    const isEffectPeriodNull = Object.keys(rowFormGroup.value['effect_period']).find(
      (prop) => rowFormGroup.value['effect_period'][prop] === null
    );
    return isFormGroupNull || isEffectPeriodNull;
  }

  private setItemsForm() {
    const itemsCtrl = this.macroeconomicsFormGroup.get('items') as FormArray;
    this.macroeconomicsData.data.forEach((item: any) => {
      itemsCtrl.push(this.setItemsFormArray(item));
    });
  }

  private setItemsFormArray(item: any) {
    return this.formBuilder.group({
      actions_needed: new FormControl(item.actions_needed, Validators.required),
      canEdit: new FormControl(false),
      category: new FormControl(item.category, Validators.required),
      description: item.description,
      editor: new FormControl(item.editor, Validators.required),
      effect_period: this.formBuilder.group({
        endDate: new FormControl(item.effect_period.endDate, Validators.required),
        startDate: new FormControl(item.effect_period.startDate, Validators.required),
      }),
      kpi_influenced: new FormControl(item.kpi_influenced, Validators.required),
      published_on: new FormControl(item.published_on, Validators.required),
      relevance_for: new FormControl(item.relevance_for, Validators.required),
    });
  }
}
