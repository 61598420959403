<div class="container">
  <div class="header-container">
    <div class="title-container">
      <span class="market-text">{{ macroeconomicsData.market }}</span>
      <div class="divider"></div>
      <div>Country</div>
    </div>
    <app-ftd-button
      (btnClick)="addEntry()"
      [size]="'S'"
      [icon]="'add'"
      [label]="'ADD ENTRY'"
      hierarchy="primary"
    ></app-ftd-button>
  </div>
  <div class="table-container">
    <form [formGroup]="macroeconomicsFormGroup">
      <table
        aria-describedby="Macroeconomics Subsidies And Taxation Table"
        mat-table
        mat-table
        [dataSource]="macroeconomicsData.data"
        formArrayName="items"
      >
        <ng-container matColumnDef="category">
          <th mat-header-cell *matHeaderCellDef>Category</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="!canEdit(rowIndex)">{{ element.category }} </span>
            <div *ngIf="canEdit(rowIndex)">
              <app-ftd-table-input
                [isMatrixView]="false"
                [inputType]="'text'"
                [form]="getFormGroup(rowIndex)"
                [placeholder]="'Category'"
                [id]="'macroeconomics-category'"
                [controlName]="'category'"
              ></app-ftd-table-input>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="!canEdit(rowIndex)">{{ element.description }}</span>
            <div *ngIf="canEdit(rowIndex)">
              <app-ftd-table-input
                [isMatrixView]="false"
                [inputType]="'text'"
                [form]="getFormGroup(rowIndex)"
                [placeholder]="'Description'"
                [id]="'macroeconomics-description'"
                [controlName]="'description'"
              ></app-ftd-table-input>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="kpi_influenced">
          <th mat-header-cell *matHeaderCellDef>KPI influenced</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="!canEdit(rowIndex)">{{ element.kpi_influenced }}</span>
            <div *ngIf="canEdit(rowIndex)">
              <app-ftd-table-input
                [isMatrixView]="false"
                [inputType]="'text'"
                [form]="getFormGroup(rowIndex)"
                [placeholder]="'KPI influenced'"
                [id]="'macroeconomics-kpi-influenced'"
                [controlName]="'kpi_influenced'"
              ></app-ftd-table-input>
            </div>
          </td>
        </ng-container>
        <ng-container matColumnDef="relevance_for">
          <th mat-header-cell *matHeaderCellDef>Relevance for</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="!canEdit(rowIndex)">{{ element.relevance_for }}</span>
            <div *ngIf="canEdit(rowIndex)">
              <app-ftd-table-input
                [isMatrixView]="false"
                [inputType]="'text'"
                [form]="getFormGroup(rowIndex)"
                [placeholder]="'Relevance for'"
                [id]="'macroeconomics-relevance_for'"
                [controlName]="'relevance_for'"
              ></app-ftd-table-input>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions_needed">
          <th mat-header-cell *matHeaderCellDef>Actions needed</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="!canEdit(rowIndex)">{{ element.actions_needed }}</span>
            <div *ngIf="canEdit(rowIndex)">
              <app-ftd-table-input
                [isMatrixView]="false"
                [form]="getFormGroup(rowIndex)"
                [inputType]="'text'"
                [placeholder]="'Actions needed'"
                [id]="'macroeconomics-actions_needed'"
                [controlName]="'actions_needed'"
              ></app-ftd-table-input>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="published_on">
          <th mat-header-cell *matHeaderCellDef>Published on</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="!canEdit(rowIndex)">{{ element.published_on | date: 'dd/MM/yyyy' }}</span>
            <div *ngIf="canEdit(rowIndex)">
              <app-ftd-table-date-picker
                [isMatrixView]="false"
                [form]="getFormGroup(rowIndex)"
                [controlName]="'published_on'"
              ></app-ftd-table-date-picker>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="effect_period">
          <th mat-header-cell *matHeaderCellDef>Effect period</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="!canEdit(rowIndex)"
              >{{ element.effect_period.startDate | date: 'dd/MM/yyyy' }} -
              {{ element.effect_period.endDate | date: 'dd/MM/yyyy' }}</span
            >
            <div *ngIf="canEdit(rowIndex)">
              <app-ftd-date-picker
                [form]="getEffectPeriodFormGroup(rowIndex)"
                [pickRanges]="true"
              ></app-ftd-date-picker>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="editor">
          <th mat-header-cell *matHeaderCellDef>Editor</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index" [formGroupName]="rowIndex">
            <span *ngIf="!canEdit(rowIndex)">{{ element.editor }}</span>
          </td>
        </ng-container>

        <ng-container matColumnDef="actions" stickyEnd>
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element; let rowIndex = index">
            <div class="cell-container">
              <ng-container *ngIf="!canEdit(rowIndex)">
                <mat-icon (click)="editRow(rowIndex)" class="edit">edit</mat-icon>
                <mat-icon class="delete" (click)="deleteRow(rowIndex)">delete</mat-icon>
              </ng-container>
              <ng-container *ngIf="canEdit(rowIndex)">
                <mat-icon
                  [matTooltip]="formHasNullValues(rowIndex) ? 'Please fill all the fields' : ''"
                  (click)="!formHasNullValues(rowIndex) ? saveEditedEntry(rowIndex) : ''"
                  [ngClass]="!formHasNullValues(rowIndex) ? 'done' : 'disabled'"
                  >done</mat-icon
                >
                <mat-icon (click)="closeEdit(rowIndex)" class="close">close</mat-icon>
              </ng-container>
            </div>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
    </form>
  </div>
</div>
