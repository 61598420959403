import { AxisRenderer, CategoryAxis } from '@amcharts/amcharts5/xy';
import { Color, Theme } from '@amcharts/amcharts5';
import { IGenericChartHeadings } from '../../../models/ftd-generic-chart.model';
import { ILineChartLineTheme } from '../../../models/ftd-line-chart.model';
import { RetailTimeSeriesProps } from 'src/app/crosschecks/enums/retail-time-series-props.enum';

export class FtdRetailSalesCustomTheme extends Theme {
  setupDefaultRules() {
    this.setupLineRules();
    this.setupZoom();
  }

  protected setupLineRules() {
    this.rule('LineSeries').events.once('datavalidated', (event) => {
      if (event.target.get('valueYField') && event.target.dataItems.length) {
        const headings = Object.keys(event.target.dataItems[0].dataContext as string[]).map((value: string) => ({
          description: '',
          key: value,
        }));
        const lineTheme = this.getRetailSalesSeriesTheme(event.target.get('valueYField')!, headings);
        event.target.set('fill', lineTheme.fill);
        event.target.strokes.template.setAll({
          fillOpacity: lineTheme.fillOpacity,
          stroke: lineTheme.fill,
          strokeDasharray: lineTheme.strokeDasharray,
          strokeWidth: lineTheme.strokeWidth,
        });
      }
    });
  }

  protected setupZoom() {
    this.rule('CategoryAxis').events.once(
      'datavalidated',
      (event: { type: string; target: CategoryAxis<AxisRenderer> }) => {
        event.target.zoomToIndexes(0, 12);
      }
    );
  }

  private getRetailSalesSeriesTheme(seriesName: string, headings: IGenericChartHeadings[]): ILineChartLineTheme {
    switch (seriesName) {
      case RetailTimeSeriesProps.actualsThisYear:
        return {
          fill: Color.fromHex(0xf8c237),
          fillOpacity: 1,
          strokeWidth: 2,
        };
      case RetailTimeSeriesProps.retailBudget:
        return {
          fill: Color.fromHex(0x58ad6d),
          fillOpacity: 1,
          strokeWidth: 2,
        };
      case RetailTimeSeriesProps.runRateExtrapolYtd:
        return {
          fill: Color.fromHex(0xb867ea),
          fillOpacity: 1,
          strokeWidth: 2,
        };
      case RetailTimeSeriesProps.actualsPreviousYear:
        return {
          fill: Color.fromHex(0xf28f33),
          fillOpacity: 1,
          strokeWidth: 2,
        };
      case RetailTimeSeriesProps.enginePredictedRetailVolume:
        return {
          fill: Color.fromHex(0xb867ea),
          fillOpacity: 1,
          strokeDasharray: [10, 5],
          strokeWidth: 2,
        };
      case RetailTimeSeriesProps.expectedVolumeRetailAdjTS:
        return {
          fill: Color.fromHex(0x79ddc0),
          fillOpacity: 1,
          strokeDasharray: [10, 5],
          strokeWidth: 2,
        };
      case RetailTimeSeriesProps.retailLatestForecast:
        return {
          fill: Color.fromHex(0xea68a6),
          fillOpacity: 1,
          strokeDasharray: [10, 5],
          strokeWidth: 2,
        };
      case RetailTimeSeriesProps.retailSecondLatestForecast:
        return {
          fill: Color.fromHex(0x52a2eb),
          fillOpacity: 1,
          strokeDasharray: [10, 5],
          strokeWidth: 2,
        };
      default:
        return {
          fill: Color.fromHex(0xfffff),
          fillOpacity: 1,
          strokeWidth: 2,
        };
    }
  }
}
