import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})

/**
 * @class DiscountMatrixViewDataFormattingService
 */
export class DiscountMatrixViewDataFormattingService {
  // Currency
  private matrixViewCurrency: string = 'EUR';

  /**
   * GetMatrixViewCurrency
   * @return string
   */
  public getMatrixViewCurrency(): string {
    return this.matrixViewCurrency;
  }

  /**
   * SetMatrixViewCurrency
   */
  public setMatrixViewCurrency(currency: string): void {
    this.matrixViewCurrency = currency;
  }
}
