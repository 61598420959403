import { Base64Utils } from '../../../../common/utils/base64.utils';
import { ChartAxisType } from '../../../../common/components/ftd-charts/enums/ftd-generic-chart.enum';
import { ChartLegendType } from 'src/app/common/components/ftd-charts/enums/chart-legend-type.enum';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from 'src/app/matrix-view/services/context/context.service';
import { CrosschecksChartContext } from '../../../enums/crosschecks-chart-context-messages.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IChartLegendConfig } from 'src/app/common/components/ftd-charts/models/ftd-chart-legend-config.model';
import { IFtdDropdownOption } from '../../../../common/models/ftd-dropdown-option.model';
import { IGenericChartHeadings } from '../../../../common/components/ftd-charts/models/ftd-generic-chart.model';
import {
  IGranularityDto,
  IOpenVolumeYe,
  IOrderIntakeApiResponse,
  IOrderIntakeTimeSeries,
} from '../../../../matrix-view/models/api.model';
import { ILineChartData } from '../../../../common/components/ftd-charts/models/ftd-line-chart.model';
import {
  OrderIntakeSeriesDisplayName,
  OrderIntakeSeriesName,
} from 'src/app/crosschecks/enums/order-intake-time-series.enum';
import { SalesPipelineCrossCheckType } from 'src/app/graphql/services/gql-api.service';
import { Subscription } from 'rxjs';
import { TrafficLightsColor } from 'src/app/crosschecks/types/traffic-lights.type';
import dayjs from 'dayjs';

@Component({
  selector: 'app-order-intake',
  styleUrls: ['./order-intake.component.scss'],
  templateUrl: './order-intake.component.html',
})
export class OrderIntakeComponent implements OnInit, OnDestroy {
  /**
   * Granularity for which chart data need to be fetched
   */
  protected readonly ChartAxisType = ChartAxisType;
  filters!: IGranularityDto;
  private _granularity: IGranularityDto = <IGranularityDto>{};
  @Input() set granularity(granularityData: string) {
    this._granularity = Base64Utils.decodeAtobToJson(granularityData);
    this.filters = this._granularity;
    this.loadOrderIntakeData();
  }

  /**
   * Traffic light value coming from overview request on Sales Pipeline
   */
  @Input() crosscheckIndication?: TrafficLightsColor;
  @Input() crosscheckIndicationForecasted?: TrafficLightsColor;

  crosschecksChartContext: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  chartData!: ILineChartData;
  openVolumeYe!: IOpenVolumeYe;
  chartLegend: string[] = [];
  chartLegendConfigs: IChartLegendConfig[] = [];
  chartLegendForm!: FormGroup;
  isChartValuesVisible: boolean = false;
  dropdownDisabled: boolean[] = [true];
  dropdownList: string[] = ['Sales Channel'];
  dropdownOptions: IFtdDropdownOption<string>[][] = [
    [
      {
        id: 1,
        label: 'Private',
        value: 'private',
      },
      {
        id: 2,
        label: 'Employee sales',
        value: 'employee',
      },
      {
        id: 3,
        label: 'Corporate / Professional',
        value: 'professional',
      },
      {
        id: 2,
        label: 'Corporate / Business',
        value: 'business',
      },
      {
        id: 3,
        label: 'Corporate / Fleet',
        value: 'fleet',
      },
    ],
  ];

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param formBuilder
   * @param crosscheckService
   * @param contextService
   */
  constructor(
    private formBuilder: FormBuilder,
    private crosscheckService: CrosschecksService,
    private contextService: ContextService
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe();
  }

  /**
   * NgUnsubscribe
   */
  ngUnsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * InitForm
   * @private
   */
  private initForm(): void {
    this.chartLegendForm = this.formBuilder.group({
      orderIntakeFields: new FormControl<string[]>([]),
    });

    const chartLegendFormSubscription: Subscription =
      this.chartLegendForm.controls.orderIntakeFields.valueChanges.subscribe((selectedLegend: string[]): void => {
        this.chartLegend = selectedLegend;
      });
    this.subscriptions.push(chartLegendFormSubscription);

    this.chartLegend = this.chartLegendForm.controls.orderIntakeFields.value;
  }

  /**
   * LoadOrderIntakeData
   * @private
   */
  private loadOrderIntakeData(): void {
    this.crosschecksChartContext = CrosschecksChartContext.LOADING;
    if (this._granularity.market) {
      const subscription: Subscription = this.crosscheckService
        .getSalesPipelineOrderIntakeData(
          this._granularity,
          this.contextService.scenarioId,
          SalesPipelineCrossCheckType.OrderIntake
        )
        .subscribe({
          error: (): void => {
            this.crosschecksChartContext = CrosschecksChartContext.ERROR;
          },
          next: (response: IOrderIntakeApiResponse): void => {
            this.setOrderIntakeDataResponseOnNext(response);
          },
        });
      this.subscriptions.push(subscription);
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
    }
  }

  /**
   * ShowValuesToggle
   * @param value
   */
  showValuesToggle(value: boolean): void {
    this.isChartValuesVisible = value;
  }

  /**
   * SetOrderIntakeDataResponseOnNext
   * @private
   * @param response
   */
  private setOrderIntakeDataResponseOnNext(response: IOrderIntakeApiResponse): void {
    // Remove Order intake NCPE-Estimation (proposed price) if no values found in enginePredictedOrderIntakeVolumeAdj
    if (response.timeSeries.length > 0) {
      if (
        response.timeSeries.every(
          (timeSeries: IOrderIntakeTimeSeries) => !timeSeries.enginePredictedOrderIntakeVolumeAdj
        )
      ) {
        response.timeSeriesProperties = response.timeSeriesProperties.filter(
          (timeSeries) => timeSeries !== OrderIntakeSeriesName.enginePredictedOrderIntakeVolumeAdj
        );
      }

      this.chartData = {
        fields: response.timeSeries.map((item: IOrderIntakeTimeSeries) => {
          return {
            ...item,
            xAxisValue: dayjs(item.date).format("MMM 'YY"),
          };
        }),
        headings: response.timeSeriesProperties.map((timeSeries: string) => {
          return {
            description: this._granularity.series,
            key: timeSeries.split('FC')[0],
          };
        }),
      };
      this.openVolumeYe = {
        ...response.scalar.openVolumeYe,
        displaySubValue: `${response.scalar.openVolumeYeRatio}%`,
        value: response.scalar.openVolumeYe.value.toLocaleString(),
      };
      this.crosschecksChartContext = CrosschecksChartContext.CHART;

      // Set up chart legend
      this.setOrderIntakeChartLegendConfig(response.timeSeriesProperties);
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_RESULTS_FOUND;
    }
  }

  /**
   * SetOrderIntakeChartLegendConfig
   * @private
   * @param keys
   */
  private setOrderIntakeChartLegendConfig(keys: string[]): void {
    this.chartLegend = [];
    this.chartData.headings.forEach((heading: IGenericChartHeadings): void => {
      this.chartLegend.push(heading.key);
    });
    this.chartLegendForm.get('orderIntakeFields')?.setValue(this.chartLegend);

    this.chartLegendConfigs = [];
    keys.forEach((key: string) => {
      switch (key.split('FC')[0]) {
        case OrderIntakeSeriesName.actualsThisYear:
          this.chartLegendConfigs.push({
            fieldName: OrderIntakeSeriesName.actualsThisYear,
            imageAlt: 'Yellow line from chart legend',
            imagePath: './assets/images/charts/legends/line-yellow.svg',
            label: OrderIntakeSeriesDisplayName.actualsThisYear,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 0,
            type: ChartLegendType.IMAGE,
          });
          break;
        case OrderIntakeSeriesName.actualsPreviousYear:
          this.chartLegendConfigs.push({
            fieldName: OrderIntakeSeriesName.actualsPreviousYear,
            imageAlt: 'Orange line from chart legend',
            imagePath: './assets/images/charts/legends/line-orange.svg',
            label: OrderIntakeSeriesDisplayName.actualsPreviousYear,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 1,
            type: ChartLegendType.IMAGE,
          });
          break;
        case OrderIntakeSeriesName.orderIntakeBudget:
          this.chartLegendConfigs.push({
            fieldName: OrderIntakeSeriesName.orderIntakeBudget,
            imageAlt: 'Green line from chart legend',
            imagePath: './assets/images/charts/legends/line-green.svg',
            label: OrderIntakeSeriesDisplayName.orderIntakeBudget,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 2,
            type: ChartLegendType.IMAGE,
          });
          break;
        case OrderIntakeSeriesName.orderIntakeSecondLatestForecast:
          this.chartLegendConfigs.push({
            fieldName: OrderIntakeSeriesName.orderIntakeSecondLatestForecast,
            imageAlt: 'Blue dotted line from chart legend',
            imagePath: './assets/images/charts/legends/line-dotted-blue.svg',
            label: `${OrderIntakeSeriesDisplayName.orderIntakeSecondLatestForecast} (FC${key.split('FC')[1]})`,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 3,
            type: ChartLegendType.IMAGE,
          });
          break;
        case OrderIntakeSeriesName.orderIntakeLatestForecast:
          this.chartLegendConfigs.push({
            fieldName: OrderIntakeSeriesName.orderIntakeLatestForecast,
            imageAlt: 'Pink dotted line from chart legend',
            imagePath: './assets/images/charts/legends/line-dotted-pink.svg',
            label: `${OrderIntakeSeriesDisplayName.orderIntakeLatestForecast} (FC${key.split('FC')[1]})`,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 4,
            type: ChartLegendType.IMAGE,
          });
          break;
        case OrderIntakeSeriesName.enginePredictedOrderIntakeVolume:
          this.chartLegendConfigs.push({
            fieldName: OrderIntakeSeriesName.enginePredictedOrderIntakeVolume,
            imageAlt: 'Purple dotted line from chart legend',
            imagePath: './assets/images/charts/legends/line-dotted-purple.svg',
            label: OrderIntakeSeriesDisplayName.enginePredictedOrderIntakeVolume,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 5,
            type: ChartLegendType.IMAGE,
          });
          break;
        case OrderIntakeSeriesName.enginePredictedOrderIntakeVolumeAdj:
          this.chartLegendConfigs.push({
            fieldName: OrderIntakeSeriesName.enginePredictedOrderIntakeVolumeAdj,
            imageAlt: 'Green dotted line from chart legend',
            imagePath: './assets/images/charts/legends/line-dotted-green.svg',
            label: OrderIntakeSeriesDisplayName.enginePredictedOrderIntakeVolumeAdj,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 6,
            type: ChartLegendType.IMAGE,
          });
          break;
      }
    });
    this.chartLegendConfigs.sort(
      (configA: IChartLegendConfig, configB: IChartLegendConfig) => configA.order! - configB.order!
    );
  }
}
