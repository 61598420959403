import { Color, PointedRectangle, Root, RoundedRectangle, Theme, Tooltip } from '@amcharts/amcharts5';
import { FtdCalendarizedOrderBankCustomTheme } from './crosschecks/sales-pipeline/calendarized-order-banks-chart.theme';
import { FtdInternalPriceLadderCustomTheme } from './crosschecks/portfolio/internal-price-ladder-chart.theme';
import { FtdLeaseReturnsCustomTheme } from './crosschecks/sales-pipeline/lease-returns-chart.theme';
import { FtdMarketsCustomTheme } from './crosschecks/markets/markets-chart.theme';
import { FtdOrderIntakeCustomTheme } from './crosschecks/sales-pipeline/order-intake-chart.theme';
import { FtdRetailSalesCustomTheme } from './crosschecks/sales-pipeline/retail-sales-chart.theme';
import {
  FtdStockAgeHorizontalCustomTheme,
  FtdStockAgePopupCustomTheme,
} from './crosschecks/stock/stock-age-chart.theme';
import {
  FtdStockCoverageBarCustomTheme,
  FtdStockCoverageLineCustomTheme,
} from './crosschecks/stock/stock-coverage-chart.theme';
import { FtdStockMixCustomTheme } from './crosschecks/stock/stock-mix-chart.theme';
import { GenericChartFields, GenericChartVerticalSpacer, IGenericChartData } from '../models/ftd-generic-chart.model';
import { IChartTooltipData } from '../models/ftd-chart-tooltip-data.model';
import { am5 } from '../ftd-base-chart.abstract';

export type ChartDimension = number | am5.Percent | null | undefined;

/**
 * @class FtdAm5ChartsTheme
 */
export class FtdAm5ChartsTheme extends Theme {
  /**
   * SetupDefaultRules
   */
  setupDefaultRules() {
    super.setupDefaultRules();

    this.setInterfaceColorsRules();
    this.setGridRules();
    this.setXYChartRules();
    this.setCursorRules();
    this.setZoomButtonRules();
    this.setTooltipRules();
    this.setLabelRules();
    this.setAxisLabelRules();
    this.setCategoryAxisRules();
    this.setAxisRendererXRules();
    this.setAxisRendererYRules();
    this.setScrollBarXRules();
  }

  /**
   * SetInterfaceColorsRules
   * @private
   */
  private setInterfaceColorsRules(): void {
    this.rule('InterfaceColors').setAll({
      alternativeBackground: Color.fromHex(0xffffff),
      alternativeText: Color.fromHex(0xffffff),
      background: Color.fromHex(0x000000),
      disabled: Color.fromHex(0xadadad),
      fill: Color.fromHex(0x2b2b2b),
      grid: Color.fromHex(0xffffff),
      negative: Color.fromHex(0xb30000),
      positive: Color.fromHex(0x50b300),
      primaryButton: Color.lighten(Color.fromHex(0x6794dc), -0.2),
      primaryButtonActive: Color.lighten(Color.fromHex(0x68dc76), -0.2),
      primaryButtonDown: Color.lighten(Color.fromHex(0x68dc75), -0.2),
      primaryButtonHover: Color.lighten(Color.fromHex(0x6771dc), -0.2),
      primaryButtonStroke: Color.lighten(Color.fromHex(0x6794dc), -0.2),
      primaryButtonText: Color.fromHex(0xffffff),
      secondaryButton: Color.fromHex(0x3b3b3b),
      secondaryButtonActive: Color.lighten(Color.fromHex(0x3b3b3b), 0.2),
      secondaryButtonDown: Color.lighten(Color.fromHex(0x3b3b3b), 0.15),
      secondaryButtonHover: Color.lighten(Color.fromHex(0x3b3b3b), 0.1),
      secondaryButtonStroke: Color.lighten(Color.fromHex(0x3b3b3b), -0.2),
      secondaryButtonText: Color.fromHex(0xbbbbbb),
      stroke: Color.fromHex(0x000000),
      text: Color.fromHex(0xffffff),
    });
  }

  /**
   * SetGridRules
   * @private
   */
  private setGridRules(): void {
    this.rule('Grid').setAll({
      stroke: Color.fromHex(0xffffff),
      strokeWidth: 1,
    });
  }

  /**
   * SetXYChartRules
   * @private
   */
  private setXYChartRules(): void {
    this.rule('XYChart').setAll({
      paddingLeft: -5,
      paddingRight: -15,
      paddingTop: 42,
      panX: true,
      panY: true,
      wheelY: 'zoomX',
    });
  }

  /**
   * SetZoomButtonRules
   * @private
   */
  private setZoomButtonRules(): void {
    // Added -10 horizontal offset to avoid getting clipped by negative padding on chart
    this.rule('Button').setAll({ dx: -10 });
  }

  /**
   * SetTooltipRules
   * @private
   */
  private setTooltipRules(): void {
    this.rule('Tooltip').setAll({
      autoTextColor: false,
      getFillFromSprite: false,
      getLabelFillFromSprite: false,
      getStrokeFromSprite: false,
      marginBottom: 6,
      marginLeft: 6,
      marginRight: 6,
      marginTop: 6,
      opacity: 1,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
    });
  }

  /**
   * SetCursorRules
   * @private
   */
  private setCursorRules(): void {
    this.rule('XYCursor').setAll({
      behavior: 'none',
    });
  }

  /**
   * SetLabelRules
   * @private
   */
  private setLabelRules() {
    this.rule('Label').setAll({
      fontFamily: 'BMWGroupTNCondensedPro-Regular',
      fontSize: '16px',
      fontWeight: '400',
    });
  }

  /**
   * SetAxisLabelRules
   * @private
   */
  private setAxisLabelRules() {
    this.rule('AxisLabel', ['axis', 'x']).setAll({
      location: 0.5,
      paddingTop: 14,
    });

    this.rule('AxisLabel', ['axis', 'y']).setAll({
      location: 0.5,
      paddingRight: 14,
    });

    this.rule('ValueAxis').setAll({
      extraMax: 0.1,
    });
  }

  /**
   * SetCategoryAxisRules
   * @private
   */
  private setCategoryAxisRules() {
    this.rule('CategoryAxis').setAll({
      panX: true,
      panY: true,
    });
    this.rule('DateAxis').setAll({
      extraMax: 0,
    });
  }

  /**
   * SetAxisRendererXRules
   * @private
   */
  private setAxisRendererXRules() {
    this.rule('AxisRendererX').setAll({
      cellEndLocation: 0.8,
      cellStartLocation: 0.2,
      strokeOpacity: 1,
    });
  }

  /**
   * SetAxisRendererYRules
   * @private
   */
  private setAxisRendererYRules() {
    this.rule('AxisRendererY').setAll({
      strokeOpacity: 1,
    });
  }

  /**
   * SetScrollBarXRules
   * @private
   */
  private setScrollBarXRules() {
    this.rule('Scrollbar').set('dx', -15);
    this.rule('RoundedRectangle', ['scrollbar']).states.create('hover', { height: 16 });

    this.rule('RoundedRectangle', ['scrollbar']).events.on(
      'dragged',
      (event: { type: string; target: RoundedRectangle }): void => {
        event.target.states.apply('hover');
      }
    );
  }
}

/**
 * @class FtdAm5ChartsThemeHelper
 */
export class FtdAm5ChartsThemeHelper {
  /**
   * GetTooltip
   * @static
   *
   * @param root
   * @param position
   * @param visible
   * @param data
   */
  static getTooltip(
    root: Root,
    position: 'horizontal' | 'vertical',
    visible: boolean = true,
    data?: IChartTooltipData[]
  ): Tooltip {
    const tooltip: Tooltip = Tooltip.new(root, {
      autoTextColor: false,
      forceHidden: !visible,
      getFillFromSprite: false,
      getLabelFillFromSprite: false,
      getStrokeFromSprite: false,
      marginBottom: 6,
      marginLeft: 6,
      marginRight: 6,
      marginTop: 6,
      opacity: 1,
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 0,
      pointerOrientation: position,
    });
    tooltip.set('background', PointedRectangle.new(root, { fill: Color.fromHex(0x282f47), opacity: 1 }));

    if (data) {
      const tooltipHtml: string = this.buildChartTooltipHTML(data);
      tooltip.set('labelHTML', tooltipHtml);
    }

    return tooltip;
  }

  /**
   * BuildChartTooltipHTML
   * @static
   * @param data
   */
  static buildChartTooltipHTML(data: IChartTooltipData[]): string {
    let tootltipHTML = `<div style='font-family:BMWGroupTNCondensedPro-Regular; font-size: 16px; font-weight: 400;padding: 8px;background-color:#282f47; border-radius: 5px;'>`;

    data.forEach((attribute, index) => {
      if (index === 0) {
        tootltipHTML += `<div style='display:flex;justify-content:space-between;'>`;
      } else {
        tootltipHTML += `<div style='display:flex;justify-content:space-between;margin-top:14px'>`;
      }
      tootltipHTML += `<span style='font-family:BMWGroupTNCondensedPro-Bold;margin-right:8px'>${attribute.key}</span>
                        <span >${attribute.value}</span>
                    </div>`;
    });

    tootltipHTML += '</div>';

    return tootltipHTML;
  }

  /**
   * SetDataVerticalSpacersIds
   * @static
   * @param data
   */
  static setDataVerticalSpacersIds(data: IGenericChartData) {
    let counter = 1;
    data.fields.forEach((field: GenericChartFields) => {
      if (field['verticalSpacer']) {
        (field as GenericChartVerticalSpacer).xAxisValue = `${
          (field as GenericChartVerticalSpacer).verticalSpacer
        }_${counter}`;
        counter += 1;
      }
    });
  }
}

export type FtdChartCustomThemeKeys =
  | 'MarketsCustomTheme'
  | 'OrderIntakeCustomTheme'
  | 'RetailSalesCustomTheme'
  | 'StockAgeHorizontalCustomTheme'
  | 'StockAgePopupCustomTheme'
  | 'LeaseReturnsCustomTheme'
  | 'InternalPriceLadderCustomTheme'
  | 'StockMixCustomTheme'
  | 'StockCoverageBarCustomTheme'
  | 'StockCoverageLineCustomTheme'
  | 'CalendarizedOrderBankCustomTheme';

export class FtdChartCustomThemes {
  static readonly DefaultCustomTheme = FtdAm5ChartsTheme;
  static readonly MarketsCustomTheme = FtdMarketsCustomTheme;
  static readonly OrderIntakeCustomTheme = FtdOrderIntakeCustomTheme;
  static readonly RetailSalesCustomTheme = FtdRetailSalesCustomTheme;
  static readonly StockAgeHorizontalCustomTheme = FtdStockAgeHorizontalCustomTheme;
  static readonly StockAgePopupCustomTheme = FtdStockAgePopupCustomTheme;
  static readonly InternalPriceLadderCustomTheme = FtdInternalPriceLadderCustomTheme;
  static readonly LeaseReturnsCustomTheme = FtdLeaseReturnsCustomTheme;
  static readonly StockCoverageBarCustomTheme = FtdStockCoverageBarCustomTheme;
  static readonly StockCoverageLineCustomTheme = FtdStockCoverageLineCustomTheme;
  static readonly StockMixCustomTheme = FtdStockMixCustomTheme;
  static readonly CalendarizedOrderBankCustomTheme = FtdCalendarizedOrderBankCustomTheme;
}
