import { Component } from '@angular/core';

@Component({
  selector: 'app-crosschecks-settings',
  styleUrls: ['./crosschecks-settings.component.scss'],
  templateUrl: './crosschecks-settings.component.html',
})
export class CrosschecksSettingsComponent {
  isReturnButtonHidden: boolean = false;
  title: string = 'Crosschecks Settings';
  description: string = '';

  crosschecksNames = [
    'Sales Pipeline',
    'Production',
    'Stock',
    'Competition',
    'Markets',
    'Portfolio',
    'Channels',
    'Macroeconomics',
  ];

  crosschecksSettingsData: ICrosschecksSettings[][] = [
    [
      { name: 'Lease Returns', threshold: 'N/A', weighting: '0%' },
      { name: 'Order Intake', threshold: '90 - 110%', weighting: '80%' },
      { name: 'Calendarized Order Bank', threshold: 'N/A', weighting: '0%' },
      { name: 'Retail Sales', threshold: '90 - 110%', weighting: '20%' },
      { name: 'Sales Opportunities', threshold: 'N/A', weighting: '0%' },
    ],
    [{ name: 'Production', threshold: 'N/A', weighting: '0%' }],
    [
      { name: 'Stock Coverage', threshold: '90 - 110%', weighting: '100%' },
      { name: 'Stock Mix', threshold: 'N/A', weighting: '0%' },
      { name: 'Stock Age', threshold: 'N/A', weighting: '0%' },
    ],
    [
      { name: 'Ext. Price & Lease Rate Dev', threshold: 'N/A', weighting: 'N/A' },
      { name: 'Price & Lease Rate Plot', threshold: 'N/A', weighting: 'N/A' },
      { name: 'Ext. Price & Lease Rate Ladder', threshold: 'N/A', weighting: 'N/A' },
      { name: 'Portal Comparison', threshold: 'N/A', weighting: 'N/A' },
    ],
    [{ name: 'Markets', threshold: '70 - 130%', weighting: '100%' }],
    [
      { name: 'Int. price & Lease Rate Ladder', threshold: 'Within target range', weighting: '100%' },
      { name: 'Price & Lease Rate Distribution', threshold: 'N/A', weighting: '0%' },
      { name: 'Int. Price & Lease Rate Dev.', threshold: 'N/A', weighting: '0%' },
    ],
    [
      { name: 'Channels Overview', threshold: 'N/A', weighting: '0%' },
      { name: 'Young Used Cars (YUC)', threshold: 'N/A', weighting: '0%' },
    ],
    [{ name: 'Subsidies & Taxation', threshold: 'N/A', weighting: '0%' }],
  ];
}

export interface ICrosschecksSettings {
  name: string;
  weighting: string;
  threshold: string;
}
