import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '../common/common.module';
import { CreateDiscountModalComponent } from './components/create-discount-modal/create-discount-modal.component';
import { DiscountDraftsModalComponent } from './components/discount-drafts-modal/discount-drafts-modal.component';
import { DiscountManagementComponent } from './discount-management.component';
import { DiscountManagementRoutingModule } from './discount-management-routing.module';
import { DiscountMatrixComponent } from './pages/discount-matrix/discount-matrix.component';
import { DiscountMatrixLandingViewComponent } from './components/matrix-view/components/matrix-landing-view/matrix-landing-view.component';
import { DiscountMatrixViewActionsToolbarComponent } from './components/matrix-view/components/matrix-view-actions-toolbar/matrix-view-actions-toolbar.component';
import { DiscountMatrixViewColumnsOrderingModalComponent } from './components/matrix-view/components/modals/matrix-view-columns-ordering-modal/matrix-view-columns-ordering-modal.component';
import { DiscountMatrixViewComponent } from './components/matrix-view/components/matrix-view/matrix-view.component';
import { DiscountMatrixViewDataComponent } from './components/matrix-view/components/matrix-view-data/matrix-view-data.component';
import { DiscountMatrixViewFooterComponent } from './components/matrix-view/components/matrix-view-footer/matrix-view-footer.component';
import { DiscountMatrixViewSideFiltersComponent } from './components/matrix-view/components/matrix-view-side-filters/matrix-view-side-filters.component';
import { DiscountMatrixViewSubheaderComponent } from './components/matrix-view/components/matrix-view-subheader/matrix-view-subheader.component';
import { DiscountMatrixViewTableHeaderComponent } from './components/matrix-view/components/matrix-view-table-header/matrix-view-table-header.component';
import { DiscountMatrixViewWrapperComponent } from './components/matrix-view/components/matrix-view-wrapper/matrix-view-wrapper.component';
import { DiscountSubmitModalComponent } from './components/discount-submit-modal/discount-submit-modal.component';
import { DiscountsHeaderComponent } from './components/discounts-header/discounts-header.component';
import { DiscountsSummaryComponent } from './pages/discounts-summary/discounts-summary.component';
import { DiscountsSummaryEmptyStateComponent } from './components/discounts-summary-empty-state/discounts-summary-empty-state.component';
import { DiscountsSummaryFiltersComponent } from './components/discounts-summary-filters/discounts-summary-filters.component';
import { DiscountsSummaryTableColumnMapComponent } from './components/discounts-summary-table/discounts-summary-table-column-map/discounts-summary-table-column-map.component';
import { DiscountsSummaryTableComponent } from './components/discounts-summary-table/discounts-summary-table.component';
import { EditDiscountsModalComponent } from './components/matrix-view/modals/edit-discounts-modal/edit-discounts-modal.component';
import { FtdStepperComponent } from '../common/components/ftd-stepper/ftd-stepper.component';
import { HeaderModule } from '../header/header.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MaterialModule } from '../common/material.module';
import { MvApprovalStatusDisplayComponent } from './components/matrix-view/components/matrix-view-columns/mv-approval-status-display/mv-approval-status-display.component';
import { MvChangedValueComponent } from './components/matrix-view/components/matrix-view-columns/mv-changed-value/mv-changed-value.component';
import { MvColumnActionComponent } from './components/matrix-view/components/matrix-view-columns/mv-column-action/mv-column-action.component';
import { MvColumnMetadataComponent } from './components/matrix-view/components/matrix-view-columns/mv-column-metadata/mv-column-metadata.component';
import { MvColumnPriceInputEditorComponent } from './components/matrix-view/components/matrix-view-columns/mv-column-price-input-editor/mv-column-price-input-editor.component';
import { MvColumnWrapperComponent } from './components/matrix-view/components/matrix-view-columns/mv-column-wrapper/mv-column-wrapper.component';
import { MvDateDisplayComponent } from './components/matrix-view/components/matrix-view-columns/mv-date-display/mv-date-display.component';
import { MvDisabledDisplayComponent } from './components/matrix-view/components/matrix-view-columns/mv-disabled-display/mv-disabled-display.component';
import { MvExpandedTooltipColumnHeaderComponent } from './components/matrix-view/components/matrix-view-columns/mv-expanded-tooltip-column-header/mv-expanded-tooltip-column-header.component';
import { MvNumberDisplayComponent } from './components/matrix-view/components/matrix-view-columns/mv-number-display/mv-number-display.component';
import { MvNumberPercentComponent } from './components/matrix-view/components/matrix-view-columns/mv-number-percent/mv-number-percent.component';
import { MvPpDisplayComponent } from './components/matrix-view/components/matrix-view-columns/mv-pp-display/mv-pp-display.component';
import { MvPredictionEvaluationIndicatorComponent } from './components/matrix-view/components/matrix-view-columns/mv-prediction-evaluation-indicator/mv-prediction-evaluation-indicator.component';
import { MvPriceDisplayComponent } from './components/matrix-view/components/matrix-view-columns/mv-price-display/mv-price-display.component';
import { MvRowNameComponent } from './components/matrix-view/components/matrix-view-columns/mv-row-name/mv-row-name.component';
import { MvTextDisplayComponent } from './components/matrix-view/components/matrix-view-columns/mv-text-display/mv-text-display.component';
import { MvUnitMapComponent } from './components/matrix-view/components/matrix-view-columns/mv-unit-map/mv-unit-map.component';
import { CommonModule as NgCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PriceApprovalStatusChipComponent } from './components/matrix-view/components/matrix-view-columns/mv-column-price-input-editor/price-approval-status-chip/price-approval-status-chip.component';
import { PriceApprovalStatusChipModalComponent } from './components/matrix-view/components/modals/price-approval-status-chip-modal/price-approval-status-chip-modal.component';
import { PriceApprovalStatusChipModalItemComponent } from './components/matrix-view/components/modals/price-approval-status-chip-modal/price-approval-status-chip-modal-item/price-approval-status-chip-modal-item.component';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    DiscountMatrixViewComponent,
    DiscountMatrixViewDataComponent,
    MvColumnWrapperComponent,
    DiscountMatrixViewFooterComponent,
    MvRowNameComponent,
    MvPriceDisplayComponent,
    MvNumberDisplayComponent,
    MvNumberPercentComponent,
    MvColumnPriceInputEditorComponent,
    DiscountMatrixViewWrapperComponent,
    DiscountMatrixViewSideFiltersComponent,
    DiscountMatrixViewActionsToolbarComponent,
    DiscountMatrixViewSubheaderComponent,
    DiscountMatrixLandingViewComponent,
    MvTextDisplayComponent,
    MvDateDisplayComponent,
    MvDisabledDisplayComponent,
    MvColumnActionComponent,
    DiscountMatrixViewTableHeaderComponent,
    DiscountMatrixViewColumnsOrderingModalComponent,
    MvChangedValueComponent,
    MvPpDisplayComponent,
    MvPredictionEvaluationIndicatorComponent,
    MvUnitMapComponent,
    MvApprovalStatusDisplayComponent,
    PriceApprovalStatusChipComponent,
    PriceApprovalStatusChipModalComponent,
    PriceApprovalStatusChipModalItemComponent,
    MvColumnMetadataComponent,
    MvExpandedTooltipColumnHeaderComponent,
    DiscountManagementComponent,
    DiscountsHeaderComponent,
    DiscountMatrixComponent,
    CreateDiscountModalComponent,
    EditDiscountsModalComponent,
    DiscountDraftsModalComponent,
    DiscountSubmitModalComponent,
    DiscountsSummaryComponent,
    DiscountManagementComponent,
    DiscountsHeaderComponent,
    DiscountsSummaryTableComponent,
    DiscountsSummaryTableColumnMapComponent,
    FtdStepperComponent,
    DiscountsHeaderComponent,
    DiscountsSummaryEmptyStateComponent,
    DiscountsSummaryFiltersComponent,
  ],
  imports: [
    DiscountManagementRoutingModule,
    NgCommonModule,
    CommonModule,
    HeaderModule,
    MaterialModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    MatExpansionModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatMenuModule,
    CdkTableModule,
  ],
})
export class DiscountManagementModule {}
