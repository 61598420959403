import { DiscountManagementComponent } from './discount-management.component';
import { DiscountMatrixComponent } from './pages/discount-matrix/discount-matrix.component';
import { DiscountsSummaryComponent } from './pages/discounts-summary/discounts-summary.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/discounts/summary',
  },
  {
    canActivate: [],
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: '/discounts/summary',
      },
      {
        component: DiscountsSummaryComponent,
        data: { breadcrumb: 'Discount Summary' },
        path: 'summary',
      },
      {
        component: DiscountMatrixComponent,
        data: { breadcrumb: 'Discount Matrix' },
        path: 'discount/:id',
      },
    ],
    component: DiscountManagementComponent,
    path: 'discounts',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forChild(routes)],
})
export class DiscountManagementRoutingModule {}
