/**
 * Used for data-mapping between frontend Technical names & metadata template
 * Every ENUM-type is corresponding to a template.
 * MetaData Templates
 */
export enum MetaDataTemplates {
  DEFAULT = 'default',
  INDICATIVE_LEASE_RATE = 'indicativeLeaseRate',
  LIST_PRICE_INCL_TAX = 'listPriceInclTax',
  RECOMMENDED_RETAIL_PRICE_REASON_CODE = 'recommendedRetailPriceReasonCode',
  SUMMARY_TRAFFIC_LIGHT = 'summaryTrafficLight',
}
