<div class="mv-column-wrapper">
  <ng-container [ngSwitch]="columnConfig.columnType">
    <app-ftd-expanded-tooltip
      [closeMenuOnMouseLeave]="true"
      [hasBackdrop]="false"
      [hasTooltip]="
        dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL ||
        dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE
      "
    >
      <div class="mv-column" tooltipTrigger>
        <ng-container *ngSwitchCase="COLUMN_TYPE.DISABLED">
          <app-mv-disabled-display></app-mv-disabled-display>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.NAME">
          {{ columnConfig.unit }}
          <app-mv-row-name
            [form]="matrixViewFormGroup"
            [controlName]="'checkboxStatus'"
            [dataSourceItem]="dataSourceItem"
            [rowIndex]="index"
            [isBulkSelection]="isBulkSelection"
          >
          </app-mv-row-name>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.UNIT_MAP">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS ||
                columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_FINANCIALS ||
                columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR ||
                columnConfig?.sectionKey === COLUMN_SECTION.PRICE_ENGINE_RECOMMENDATION) &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="
              dataSourceItem.scenarioOutput &&
              dataSourceItem.granularity.type !== GRANULARITY_TYPE.BRAND &&
              (columnConfig.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS)
            "
          >
            <app-mv-unit-map
              *ngIf="columnConfig.unit"
              [COLUMN_UNIT]="columnConfig.unit"
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            >
            </app-mv-unit-map>

            <app-mv-text-display
              *ngIf="!columnConfig.unit"
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-mv-text-display>
          </ng-container>

          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR ||
                columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_FINANCIALS ||
                columnConfig?.sectionKey === COLUMN_SECTION.PRICE_ENGINE_RECOMMENDATION) &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-mv-unit-map
              *ngIf="columnConfig.unit"
              [COLUMN_UNIT]="columnConfig.unit"
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            >
            </app-mv-unit-map>

            <app-mv-text-display
              *ngIf="!columnConfig.unit"
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-mv-text-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.PRICE_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type !== GRANULARITY_TYPE.BRAND &&
              columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type !== GRANULARITY_TYPE.BRAND &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_FINANCIALS) &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-mv-unit-map
              [COLUMN_UNIT]="columnConfig.unit"
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            >
            </app-mv-unit-map>
          </ng-container>

          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-mv-price-display
              [currency]="columnConfig.unit || 'EUR'"
              [priceHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-mv-price-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.NUMBER_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS) &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS) &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-mv-text-display
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-mv-text-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.INTEGER_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS ||
                columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_FINANCIALS) &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="
              ((dataSourceItem.granularity.type !== GRANULARITY_TYPE.BRAND && columnConfig.technicalName !== CO2_CAR) ||
                (dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                  columnConfig.technicalName === CO2_CAR)) &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS ||
                columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_FINANCIALS) &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
            ></app-mv-number-percent>

            <ng-template #regular>
              <app-mv-number-display
                [textHolder]="dataSourceItem.scenarioOutput"
                [technicalName]="columnConfig.technicalName"
              ></app-mv-number-display>
            </ng-template>
          </ng-container>

          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
            ></app-mv-number-percent>

            <ng-template #regular>
              <app-mv-number-display
                [textHolder]="dataSourceItem.scenarioOutput"
                [technicalName]="columnConfig.technicalName"
              ></app-mv-number-display>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.INTEGER_PRICE_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS ||
                columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_FINANCIALS) &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="
              ((dataSourceItem.granularity.type !== GRANULARITY_TYPE.BRAND &&
                columnConfig.technicalName !== DELTA_CM) ||
                (dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                  columnConfig.technicalName === DELTA_CM)) &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS ||
                columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_FINANCIALS) &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
            ></app-mv-number-percent>

            <ng-template #regular>
              <app-mv-number-display
                [textHolder]="dataSourceItem.scenarioOutput"
                [technicalName]="columnConfig.technicalName"
                [price]="true"
              ></app-mv-number-display>
            </ng-template>
          </ng-container>

          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
            ></app-mv-number-percent>

            <ng-template #regular>
              <app-mv-number-display
                [textHolder]="dataSourceItem.scenarioOutput"
                [technicalName]="columnConfig.technicalName"
                [price]="true"
              ></app-mv-number-display>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.DATE_DISPLAY">
          <div
            *ngIf="dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE && !dataSourceItem?.scenarioOutput"
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE && dataSourceItem?.scenarioOutput"
          >
            <app-mv-date-display
              [dateHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-mv-date-display>
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="
            columnConfig?.columnType === COLUMN_TYPE.NUMBER_DISPLAY ||
            columnConfig?.columnType === COLUMN_TYPE.PRICE_DISPLAY
          "
        >
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.PRICE_ENGINE_RECOMMENDATION ||
                columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_FINANCIALS) &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.PRICE_ENGINE_RECOMMENDATION ||
                columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_FINANCIALS) &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-mv-text-display
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-mv-text-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.PRICE_INPUT_EDITOR">
          <div
            *ngIf="columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR && !dataSourceItem?.priceEditorData"
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR && dataSourceItem?.priceEditorData"
          >
            <app-ftd-table-input
              [id]="dataSourceItem.id + '-price-input-editor'"
              *ngIf="
                dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                scenarioContext === SCENARIO_CONTEXT.userScenario
              "
              [rowIndex]="index"
              [currencyMaskOption]="{ allowNegative: false, decimal: '.', precision: 4 }"
              [form]="matrixViewFormGroup"
              [inputStatus]="getPriceEditorInputStatus(index)"
              [controlName]="'price'"
              [inputType]="'currency'"
              [currency]="columnConfig.unit || 'EUR'"
              [textAlign]="'right'"
              (click)="priceInputHandler(index)"
              (KeyUp)="onPriceInputChange($event, index)"
              (Paste)="onPriceInputChange($event, index)"
            ></app-ftd-table-input>

            <app-mv-column-price-input-editor
              *ngIf="
                dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                scenarioContext === SCENARIO_CONTEXT.mainScenario
              "
              [currency]="columnConfig.unit || 'EUR'"
              [class]="dataSourceItem.priceEditorData?.status"
              [priceHolder]="dataSourceItem.priceEditorData"
              [technicalName]="columnConfig.technicalName"
              [approvalStatus]="
                dataSourceItem.priceEditorData
                  ? dataSourceItem.priceEditorData.status
                  : PRICE_POINT_APPROVAL_STATUS.no_status
              "
              [isUserScenario]="false"
            ></app-mv-column-price-input-editor>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.PRICE_INPUT_PERCENTAGE_EDITOR">
          <div
            *ngIf="columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR && !dataSourceItem?.priceEditorData"
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR && dataSourceItem?.priceEditorData"
          >
            <app-ftd-table-input
              [id]="dataSourceItem.id + '-price-input-percentage-editor'"
              *ngIf="
                dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                scenarioContext === SCENARIO_CONTEXT.userScenario
              "
              [placeholder]="'Set a percentage'"
              [currencyMaskOption]="{ allowNegative: true, decimal: '.', precision: 1 }"
              [inputType]="'percentage'"
              [rowIndex]="index"
              [form]="matrixViewFormGroup"
              [controlName]="'pricePointPercentage'"
              [textAlign]="'right'"
              (KeyUp)="onPricePercentageInputChange($event, index)"
              (Paste)="onPricePercentageInputChange($event, index)"
            ></app-ftd-table-input>
          </ng-container>
          <app-mv-unit-map
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              scenarioContext === SCENARIO_CONTEXT.mainScenario
            "
            [COLUMN_UNIT]="'%'"
            [textHolder]="dataSourceItem.priceEditorData"
            [technicalName]="columnConfig.technicalName"
          >
          </app-mv-unit-map>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.EFFECTIVE_DATE_INPUT">
          <div
            *ngIf="columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR && !dataSourceItem?.priceEditorData"
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR && dataSourceItem?.priceEditorData"
          >
            <app-ftd-table-date-picker
              *ngIf="
                dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                scenarioContext === SCENARIO_CONTEXT.userScenario
              "
              [rowIndex]="index"
              [enableMinDate]="isEffectiveDateDirtyOrTouched(index)"
              (datepickerToggleClicked)="setEffectiveDateDirtyAndTouched(index)"
              [daysAhead]="0"
              [inputStatus]="getEffectiveDateInputStatus(index)"
              [form]="matrixViewFormGroup"
              [controlName]="'effectiveDate'"
            ></app-ftd-table-date-picker>
            <app-mv-date-display
              *ngIf="
                dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                scenarioContext === SCENARIO_CONTEXT.mainScenario
              "
              [nullableType]="APP_SYMBOLS.NOT_SUBMITTED"
              [dateHolder]="dataSourceItem.priceEditorData"
              [technicalName]="columnConfig.technicalName"
            ></app-mv-date-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.PRICE_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR &&
              !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR &&
              dataSourceItem?.priceEditorData
            "
          >
            <app-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
              [currency]="columnConfig.unit || 'EUR'"
            ></app-mv-number-percent>

            <ng-template #regular>
              <app-mv-changed-value
                *ngIf="
                  scenarioContext === SCENARIO_CONTEXT.userScenario &&
                    !(
                      columnConfig.technicalName === TE_LEASE_RATE_ADJ ||
                      columnConfig.technicalName === DELTA_LEASE_RATE_ADJ
                    );
                  else mainScenarioPrice
                "
                [currency]="columnConfig.unit || 'EUR'"
                [technicalName]="columnConfig.technicalName!"
                [newValue]="matrixViewFormArray.at(index).get('price')?.value"
                [oldValue]="+dataSourceItem.priceEditorData?.listPriceInclTax!"
              ></app-mv-changed-value>
              <ng-template #mainScenarioPrice>
                <app-mv-price-display
                  [currency]="columnConfig.unit || 'EUR'"
                  [priceHolder]="
                    columnConfig.technicalName === TE_LEASE_RATE_ADJ ||
                    columnConfig.technicalName === DELTA_LEASE_RATE_ADJ
                      ? dataSourceItem.scenarioOutput
                      : dataSourceItem.priceEditorData
                  "
                  [technicalName]="columnConfig.technicalName"
                ></app-mv-price-display>
              </ng-template>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.NUMBER_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR &&
              !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR &&
              dataSourceItem?.priceEditorData
            "
          >
            <app-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
            ></app-mv-number-percent>

            <ng-template #regular>
              <app-mv-text-display
                [textHolder]="dataSourceItem.priceEditorData"
                [technicalName]="columnConfig.technicalName"
              ></app-mv-text-display>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.TEXT_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR &&
              !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR ||
                columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_FINANCIALS ||
                columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING) &&
              dataSourceItem?.priceEditorData
            "
          >
            <app-mv-text-display
              [textHolder]="dataSourceItem.priceEditorData"
              [technicalName]="columnConfig.technicalName"
            ></app-mv-text-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.APPROVAL_STATUS">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR &&
              !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICE_EDITOR &&
              dataSourceItem?.priceEditorData
            "
          >
            <app-mv-approval-status-display
              [textHolder]="dataSourceItem.priceEditorData"
              [technicalName]="columnConfig.technicalName"
            ></app-mv-approval-status-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.CROSSCHECK">
          <div
            class="skeleton-matrix-view-values-overlay"
            *ngIf="
              ((dataSourceItem.granularity.type !== GRANULARITY_TYPE.BRAND &&
                (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_CROSSCHECKS ||
                  columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_CROSSCHECKS)) ||
                (dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                  columnConfig?.sectionKey === COLUMN_SECTION.PRICE_ENGINE_RECOMMENDATION)) &&
              !dataSourceItem.scenarioOutput
            "
          ></div>
          <ng-container
            *ngIf="
              ((dataSourceItem.granularity.type !== GRANULARITY_TYPE.BRAND &&
                (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_CROSSCHECKS ||
                  columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_CROSSCHECKS)) ||
                (dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                  columnConfig?.sectionKey === COLUMN_SECTION.PRICE_ENGINE_RECOMMENDATION)) &&
              dataSourceItem.scenarioOutput
            "
          >
            <app-mv-crosscheck
              [granularity]="dataSourceItem.granularity"
              [crossCheckResultsItem]="buildCrosscheckResultItem(columnConfig.technicalName)"
              [technicalName]="columnConfig.technicalName"
              [scenario]="scenarioContext"
              [scenarioId]="dataSourceItem.scenarioOutput.scenarioId"
            ></app-mv-crosscheck>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.PREDICTION_EVALUATION_INDICATOR">
          <div
            class="skeleton-matrix-view-values-overlay"
            *ngIf="
              (((dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL ||
                dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE ||
                dataSourceItem.granularity.type === GRANULARITY_TYPE.E_SERIES) &&
                (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_CROSSCHECKS ||
                  columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_CROSSCHECKS)) ||
                (dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                  columnConfig?.sectionKey === COLUMN_SECTION.PRICE_ENGINE_RECOMMENDATION)) &&
              !dataSourceItem.scenarioOutput
            "
          ></div>
          <ng-container
            *ngIf="
              (((dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL ||
                dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE ||
                dataSourceItem.granularity.type === GRANULARITY_TYPE.E_SERIES) &&
                (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_CROSSCHECKS ||
                  columnConfig?.sectionKey === COLUMN_SECTION.FORECASTED_CROSSCHECKS)) ||
                (dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                  columnConfig?.sectionKey === COLUMN_SECTION.PRICE_ENGINE_RECOMMENDATION)) &&
              dataSourceItem.scenarioOutput
            "
          >
            <app-mv-prediction-evaluation-indicator
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            >
            </app-mv-prediction-evaluation-indicator>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.ACTIONS">
          <div
            class="skeleton-matrix-view-values-overlay"
            *ngIf="
              columnConfig?.sectionKey === COLUMN_SECTION.ACTIONS &&
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              !dataSourceItem?.priceEditorData
            "
          ></div>

          <ng-container
            *ngIf="
              columnConfig?.sectionKey === COLUMN_SECTION.ACTIONS &&
              dataSourceItem?.priceEditorData &&
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE
            "
          >
            <app-mv-column-action
              [dataSourceItem]="dataSourceItem"
              [isUserScenario]="scenarioContext === SCENARIO_CONTEXT.userScenario"
            ></app-mv-column-action>
          </ng-container>
        </ng-container>
      </div>

      <div class="mv-column-metadata" tooltipContent>
        <app-mv-column-metadata
          [currency]="columnConfig.unit || 'EUR'"
          [scenarioMetaData]="dataSourceItem.scenarioMetaData"
          [technicalName]="columnConfig.technicalName"
        >
        </app-mv-column-metadata>
      </div>
    </app-ftd-expanded-tooltip>
  </ng-container>
</div>
