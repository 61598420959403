export enum GranularityType {
  BRAND = 'BRAND',
  SEGMENT = 'SEGMENT',
  SERIES = 'SERIES',
  E_SERIES = 'E_SERIES',
  ESERIES = 'ESERIES',
  MODEL = 'MODEL',
  MODEL_CODE = 'MODEL_CODE',
  UNKNOWN = 'UNKNOWN',
  POWERTRAIN = 'POWERTRAIN',
}
