<app-crosschecks-chart-wrapper
  [context]="crosschecksChartContext"
  [skeletonTheme]="'OrderIntake'"
  [filters]="filters"
  [crosscheckIndicationVisible]="true"
  [trafficLightStatus]="crosscheckIndication!"
  [trafficLightAdjStatus]="crosscheckIndicationForecasted!"
>
  <app-chart-filters
    (showValuesToggleEvent)="showValuesToggle($event)"
    [dropdownDisabled]="dropdownDisabled"
    [dropdownList]="dropdownList"
    [dropdownOptions]="dropdownOptions"
  ></app-chart-filters>
  <div class="container">
    <div class="chart-container">
      <app-ftd-line-chart
        *ngIf="chartData"
        [data]="chartData"
        [rootId]="'ftd-line-chart-ref-order-intake'"
        [chartLegend]="chartLegend"
        [isLabelValuesVisible]="isChartValuesVisible"
        [chartCustomTheme]="'OrderIntakeCustomTheme'"
        [primaryUnitLabel]="'Units'"
        [isSysDateIndicatorVisible]="true"
        [axisType]="ChartAxisType.CATEGORY"
        [isScrollBarVisible]="true"
      ></app-ftd-line-chart>
    </div>

    <app-ftd-chart-info-tile
      class="chart-tiles"
      *ngIf="openVolumeYe"
      [displayName]="openVolumeYe.displayName"
      [displayValue]="openVolumeYe.value"
      [displayNameDetails]="openVolumeYe.displayNameDetails"
      [displaySubValue]="openVolumeYe.displaySubValue"
    ></app-ftd-chart-info-tile>
  </div>

  <form [formGroup]="chartLegendForm">
    <app-crosschecks-chart-filter-group formControlName="orderIntakeFields">
      <app-chart-filter-item
        *ngFor="let legendConfig of chartLegendConfigs"
        [value]="legendConfig.fieldName"
        [label]="legendConfig.label"
        [legendType]="legendConfig.type"
        [legendImagePath]="legendConfig.imagePath"
        [legendImageAlt]="legendConfig.imageAlt"
        [legendImageHeight]="legendConfig.legendImageHeight"
        [legendImageWidth]="legendConfig.legendImageWidth"
      >
      </app-chart-filter-item>
    </app-crosschecks-chart-filter-group>
  </form>
</app-crosschecks-chart-wrapper>
