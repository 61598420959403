import { ActivatedRoute, Params, Router } from '@angular/router';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DiscountApprovalStatus } from '../../enums/discount-approval-status.enum';
import { DiscountContext } from '../../components/matrix-view/services/context/context.service';
import { DiscountManagementService } from '../../services/discount-management.service';
import { DiscountMatrixViewDataService } from '../../components/matrix-view/services/matrix-view-data/matrix-view-data.service';
import { DiscountMatrixViewFormService } from '../../components/matrix-view/services/matrix-view-form/matrix-view-form.service';
import { FormGroup } from '@angular/forms';
import { IDiscount, IDiscountQueryVariables } from '../../../graphql/services/gql-api.service';
import { IDiscountsConfig, getDiscountsConfig } from '../../configs/discounts.config';
import { MessageService } from '../../../common/services/message/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-discount-discount-matrix',
  styleUrls: ['./discount-matrix.component.scss'],
  templateUrl: './discount-matrix.component.html',
})
export class DiscountMatrixComponent implements OnInit, OnDestroy {
  @Input({ required: true }) discount!: IDiscount;
  title: string = 'Discount Review';
  mode: DiscountContext = DiscountContext.read;
  DISCOUNTS_CONFIG!: IDiscountsConfig;

  isRequestProcessing: boolean = true;
  private subscriptions: Subscription[] = [];
  private discountId!: string;

  private formValidRowsChecked: FormGroup[] = [];
  formValidRows: FormGroup[] = [];
  private formRowsChecked: FormGroup[] = [];

  isSaveDisabled: boolean = true;
  isSaveLoading: boolean = false;

  /**
   * @constructor
   * @param discountManagementService
   * @param route
   * @param messageService
   * @param matrixViewFormService
   * @param router
   * @param matrixViewDataService
   */
  constructor(
    private readonly discountManagementService: DiscountManagementService,
    private route: ActivatedRoute,
    private messageService: MessageService,
    private matrixViewFormService: DiscountMatrixViewFormService,
    private router: Router,
    private matrixViewDataService: DiscountMatrixViewDataService
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.listenForDiscountIdChanges();
    this.initReloadGetDiscountListener();
    this.initMatrixViewFormValueChanged();
  }

  private initMatrixViewFormValueChanged(): void {
    const subscription: Subscription = this.matrixViewFormService.formValueChangedSubject$.subscribe((): void => {
      this.formValidRowsChecked = this.matrixViewFormService.getFormValidRowsChecked();
      this.formValidRows = this.matrixViewFormService.getFormValidRows();
      this.isSaveDisabled = !this.formValidRows.length;
    });
    this.subscriptions.push(subscription);
  }

  saveDiscount(): void {
    this.isSaveLoading = true;
    setTimeout((): void => {
      this.isSaveLoading = false;
      this.onDiscountSaveSuccess();
      this.matrixViewFormService.resetForm();
      this.matrixViewDataService.notifyMatrixViewToReflectPriceChanges();
    }, 3000);
  }

  /**
   * OnDiscountSaveSuccess
   */
  private onDiscountSaveSuccess(): void {
    this.messageService.showSuccess(`Discount Saved`);
  }

  /**
   * OnDiscountSaveError
   */
  private onDiscountSaveError(): void {
    this.messageService.showError(`Error in Discount Save`);
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * GetPayload
   * @private
   * @return IDiscountQueryVariables
   */
  private getPayload(): IDiscountQueryVariables {
    return {
      id: this.discountId,
    };
  }

  /**
   * GetDiscounts
   */

  getDiscount(): void {
    this.isRequestProcessing = true;

    const payload: IDiscountQueryVariables = this.getPayload();

    this.discountManagementService.getDiscount(payload).subscribe((data: IDiscount): void => {
      this.discount = data;
      this.setDiscountConfig(this.discount);
      this.isRequestProcessing = false;
    });
  }

  /**
   * SetDiscountConfig
   * @param discount
   * @private
   */
  private setDiscountConfig(discount: IDiscount = this.discount): void {
    const status: DiscountApprovalStatus = discount.approvalStatus as unknown as DiscountApprovalStatus;
    this.DISCOUNTS_CONFIG = getDiscountsConfig(status);

    if (status === DiscountApprovalStatus.NEW) {
      this.mode = DiscountContext.create;
    }
  }

  /**
   * ListenForDiscountIdChanges
   * @private
   */
  private listenForDiscountIdChanges(): void {
    const discountIdSubscription: Subscription = this.route.params.subscribe(
      (params: Params | { id: string }): void => {
        this.discountId = params.id;
        if (this.discountId.length) {
          this.getDiscount();
        }
      }
    );
    this.subscriptions.push(discountIdSubscription);
  }

  /**
   * InitReloadGetDiscountListener
   */
  initReloadGetDiscountListener(): void {
    const subscription: Subscription = this.discountManagementService.reloadGetDiscount().subscribe({
      next: (reloadGetDiscount: boolean): void => {
        if (reloadGetDiscount) {
          this.getDiscount();
        }
      },
    });

    this.subscriptions.push(subscription);
  }

  /**
   * SubmitDiscount
   */
  submitDiscount(): void {
    this.goToDiscountSummaryPage();
  }

  /**
   * GoToDiscountSummaryPage
   */
  goToDiscountSummaryPage(): void {
    this.router.navigate(['/discounts/summary']);
  }
}
