export class Actions {
  APPROVE_MAIN_SCENARIO: boolean = false;
  RELEASE_MAIN_SCENARIO: boolean = false;
  SUBMIT_TO_MAIN_SCENARIO: boolean = false;
  DOWNLOAD_SCENARIO: boolean = false;
  SAVE_USER_SCENARIO: boolean = false;
  DISCOUNT_MODULE: boolean = false;
  CREATE_DISCOUNT: boolean = false;
  READ_DISCOUNT: boolean = false;
  WITHDRAW_DISCOUNT: boolean = false;
  REJECT_DISCOUNT: boolean = false;
  APPROVE_BY_1_DISCOUNT: boolean = false;
  APPROVE_BY_2_DISCOUNT: boolean = false;
  RELEASE_DISCOUNT: boolean = false;
  CREATE_USER_SCENARIO: boolean = false;
  REJECT_PRICE: boolean = false;
  ACCEPT_PRICE: boolean = false;
  WITHDRAW_ON_MAIN_SCENARIO: boolean = false;
  ADJUST_ROLES: boolean = false;
  READ_MAIN_SCENARIO: boolean = false;
}

export type KeyofActions = keyof Actions;

export enum ActionsDescription {
  READ_MAIN_SCENARIO = 'Read',
  CREATE_USER_SCENARIO = 'Create',
  SAVE_USER_SCENARIO = 'Save',
  DOWNLOAD_SCENARIO = 'Download Excel',
  SUBMIT_TO_MAIN_SCENARIO = 'Submit',
  WITHDRAW_ON_MAIN_SCENARIO = 'Withdraw',
  REJECT_PRICE = 'Reject prices',
  ACCEPT_PRICE = 'Accept prices',
  APPROVE_MAIN_SCENARIO = 'Approve Scenario',
  RELEASE_MAIN_SCENARIO = 'Release Scenario',
  CREATE_DISCOUNT = 'Create Discount',
}

export type KeyofActionsDescription = keyof ActionsDescription;
