export enum PricePointApprovalStatus {
  comments_only = 'contains_comments_only',
  proposed_to_pricing_lead = 'proposed_to_pricing_lead',
  proposed_to_governance_board = 'proposed_to_governance_board',
  approved_by_1 = 'approved_by_1',
  approved_by_2 = 'approved_by_2',
  released = 'released',
  rejected = 'rejected',
  saved = 'saved',
  no_status = 'no_status',
  demote_status = 'demote_status',
  promote_status = 'promote_status',
  exported = 'exported',
  withdraw = 'withdraw',
  done = 'done',
}

export enum ApprovalStatus {
  NEW = 'NEW',
  SUBMITTED = 'SUBMITTED',
  PROPOSAL_WITHDRAWN = 'PROPOSAL_WITHDRAWN',
  LVL1_APPROVED = 'LVL1_APPROVED',
  LVL1_REJECTED = 'LVL1_REJECTED',
  NSC_APPROVED = 'NSC_APPROVED',
  NSC_REJECTED = 'NSC_REJECTED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  EXPORTED = 'EXPORTED',
}

export enum ApprovalStatusRejection {
  LVL1_REJECTED = 'LVL1_REJECTED',
  NSC_REJECTED = 'NSC_REJECTED',
  REJECTED = 'REJECTED',
}

export type KeyofPricePointApprovalStatusRequest = keyof typeof PricePointApprovalStatusRequest;
export const PricePointApprovalStatusRequest = {
  // STEP 4
  APPROVED: 'approved_by_2',
  // STEP 0
  DEFAULT: 'NEW',
  EXPORTED: 'exported',
  // STEP 2
  LVL1_APPROVED: 'proposed_to_governance_board',
  LVL1_REJECTED: 'rejected',
  NEW: 'saved',
  // STEP 3
  NSC_APPROVED: 'approved_by_1',
  NSC_REJECTED: 'rejected',
  PROPOSAL_WITHDRAWN: 'withdraw',
  REJECTED: 'rejected',
  // STEP 1
  SUBMITTED: 'proposed_to_pricing_lead',
  comments_only: 'contains_comments_only',
  demote_status: 'demote_status',
  done: 'done',
  no_status: 'no_status',
  promote_status: 'promote_status',
  released: 'released',
  saved: 'saved',
};
