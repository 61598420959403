<div class="container">
  <app-crosschecks-header
    (showFiltersButtonVisible)="toggleShowFiltersButtonVisible()"
    [isToggleFiltersVisible]="isCrosschecksFiltersAllowed"
    title="Crosschecks"
  ></app-crosschecks-header>
  <ng-container *ngIf="isCrosschecksFiltersAllowed">
    <app-crosschecks-main-filter
      [ngClass]="hideAndShowFiltersClass"
      [crosschecksFilters]="crosschecksFilters"
      (applyEvent)="onClickApply()"
    ></app-crosschecks-main-filter>
  </ng-container>
  <mat-card id="crosschecks-mat-card" [ngClass]="hideAndShowMatCardSpacingClass">
    <mat-card-header>
      <nav class="crosschecks-main-tab-nav" id="crosschecks-nav">
        <a
          (click)="setSelectedTab('sales-pipeline')"
          [ngClass]="selectedTab === 'sales-pipeline' ? 'crosschecks-main-tab-active' : ''"
          routerLink="/crosschecks/{{ getSelectedContextFromUrl }}/sales-pipeline/order-intake/{{ hashedUrl }}"
          [queryParams]="queryParams"
          >Sales Pipeline</a
        >
        <a
          class="crosschecks-main-tab-disabled"
          [ngClass]="selectedTab === 'production' ? 'crosschecks-main-tab-active' : ''"
          >Production</a
        >
        <a
          routerLink="/crosschecks/{{ getSelectedContextFromUrl }}/stock/stock-coverage/{{ hashedUrl }}"
          [queryParams]="queryParams"
          (click)="setSelectedTab('stock')"
          [ngClass]="selectedTab === 'stock' ? 'crosschecks-main-tab-active' : ''"
          >Stock</a
        >
        <a
          class="crosschecks-main-tab-disabled"
          [ngClass]="selectedTab === 'competition' ? 'crosschecks-main-tab-active' : ''"
          >Competition</a
        >
        <a
          routerLink="/crosschecks/{{ getSelectedContextFromUrl }}/markets/{{ hashedUrl }}"
          [queryParams]="queryParams"
          (click)="setSelectedTab('markets')"
          [ngClass]="selectedTab === 'markets' ? 'crosschecks-main-tab-active' : ''"
          >Markets</a
        >
        <a
          routerLink="/crosschecks/{{ getSelectedContextFromUrl }}/portfolio/internal-price-ladder/{{ hashedUrl }}"
          [queryParams]="queryParams"
          (click)="setSelectedTab('portfolio')"
          [ngClass]="selectedTab === 'portfolio' ? 'crosschecks-main-tab-active' : ''"
          >Portfolio</a
        >
        <a
          class="crosschecks-main-tab-disabled"
          [ngClass]="selectedTab === 'channels' ? 'crosschecks-main-tab-active' : ''"
          >Channels</a
        >
        <a
          class="crosschecks-main-tab-disabled"
          [ngClass]="selectedTab === 'macroeconomics' ? 'crosschecks-main-tab-active' : ''"
          >Macroeconomics</a
        >
      </nav>
    </mat-card-header>
    <mat-card-content>
      <router-outlet></router-outlet>
    </mat-card-content>
  </mat-card>
</div>
