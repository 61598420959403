import { ChipSize } from '../../../../../../../common/components/ftd-chip/ftd-chip-size.enum';
import { Component, Input } from '@angular/core';
import { FtdModalSize } from '../../../../../../../common/components/ftd-modal/ftd-modal-size.enum';
import {
  IPriceApprovalStatusChipModalData,
  PriceApprovalStatusChipModalComponent,
} from '../../../modals/price-approval-status-chip-modal/price-approval-status-chip-modal.component';
import {
  IPriceApprovalStatusToChipData,
  PriceApprovalStatusToChipTextMap,
} from '../../../../constants/price-approval-status-to-chip-text-map';
import { MatDialog } from '@angular/material/dialog';
import { PricePointApprovalStatus } from '../../../../enums/price-point-approval-status.enum';

@Component({
  selector: 'app-discount-price-approval-status-chip',
  styleUrls: ['./price-approval-status-chip.component.scss'],
  templateUrl: './price-approval-status-chip.component.html',
})
export class PriceApprovalStatusChipComponent {
  @Input() approvalStatus!: PricePointApprovalStatus;

  CHIP_SIZE = ChipSize;

  constructor(private dialog: MatDialog) {}

  openPriceApprovalStatusChipModal(): void {
    this.dialog.open(PriceApprovalStatusChipModalComponent, {
      data: {
        approvalStatus: this.approvalStatus,
      } as IPriceApprovalStatusChipModalData,
      width: FtdModalSize.S,
    });
  }

  get chipData(): IPriceApprovalStatusToChipData | undefined {
    return this.approvalStatus
      ? <IPriceApprovalStatusToChipData>PriceApprovalStatusToChipTextMap.get(this.approvalStatus)
      : undefined;
  }
}
