export interface ILogoData {
  name: string;
  file: string;
  width: number;
  height: number;
}
export abstract class HeaderLogos {
  public logos: ILogoData[] = [
    { file: 'logoBmwGroupMonoWhite.svg', height: 32, name: 'BMW Group', width: 67 },
    { file: 'logoBmwMonoWhite.svg', height: 32, name: 'BMW', width: 32 },
    { file: 'logoMiniMonoWhite.svg', height: 24, name: 'MINI', width: 54 },
  ];

  public appLogos: ILogoData[] = [{ file: 'logoBmwNcpeMonoWhite.svg', height: 41, name: 'NCPE', width: 120 }];
}
