import { AuthService } from '../../../auth/services/auth.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';

@Component({
  selector: 'app-discounts-summary-empty-state',
  styleUrls: ['./discounts-summary-empty-state.component.scss'],
  templateUrl: './discounts-summary-empty-state.component.html',
})

/**
 * @class DiscountsSummaryEmptyStateComponent
 */
export class DiscountsSummaryEmptyStateComponent implements OnInit, OnDestroy {
  currentUser!: User | null;
  private subscriptions: Subscription[] = [];
  @Input() isFilterApplied: Boolean = true;
  @Input() hasError: Boolean = false;
  @Input() isLoading = true;

  /**
   * @constructor
   * @param authService
   * @param dialog
   */
  constructor(private authService: AuthService, public dialog: MatDialog) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.listenToCurrentUserChanges();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * ListenToCurrentUserChanges
   * @private
   */
  private listenToCurrentUserChanges(): void {
    const subscription: Subscription = this.authService.getLoggedInUserAsObservable().subscribe({
      next: (user: User | null) => (this.currentUser = user),
    });
    this.subscriptions.push(subscription);
  }
}
