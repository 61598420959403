import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IFtdDropdownOption } from '../../models/ftd-dropdown-option.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ftd-multi-dropdown',
  styleUrls: ['./ftd-multi-dropdown.component.scss'],
  templateUrl: './ftd-multi-dropdown.component.html',
})
export class FtdMultiDropdownComponent implements AfterViewInit, OnDestroy, OnInit {
  @Input() id!: string;
  @Input() isSearchVisible: boolean = false;
  @Input() isLabelVisible: boolean = true;
  @Input() isFormArray: boolean = false;
  private _defaultOptions: IFtdDropdownOption[] = [];
  private _options: IFtdDropdownOption[] = [];
  get options(): IFtdDropdownOption[] {
    return this._options;
  }

  @Input() set options(options: IFtdDropdownOption[]) {
    this._options = options;
    this._defaultOptions = options;
  }

  @Input() label: string = 'Select';
  @Input() form!: FormGroup;
  @Input() controlName: string = '';
  @Input() defaultSelected?: IFtdDropdownOption[];
  @Input() showBrandIcon?: boolean = false;
  @Output() selectedOptions: EventEmitter<any> = new EventEmitter();
  @Output() optionsClosed: EventEmitter<any> = new EventEmitter();

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param changeDetector
   */
  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.isSearchVisible) {
      this.setSearchForm();
    }
  }

  ngAfterViewInit(): void {
    const subscription = this.form?.get(this.controlName)?.valueChanges.subscribe((option) => {
      this.emitSelectedOptions(option);
    });

    if (this.defaultSelected) {
      const defaultValues = this.options.filter((option1) =>
        this.defaultSelected?.find((option2) => option2.id === option1.id)
      );
      this.form?.patchValue({
        [this.controlName]: defaultValues,
      });
      if (subscription) {
        this.subscriptions.push(subscription);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * Distinct Value Model By Id
   * @param ftdDropdownOptionA
   * @param ftdDropdownOptionB
   */
  distinctById(ftdDropdownOptionA: IFtdDropdownOption, ftdDropdownOptionB: IFtdDropdownOption): boolean {
    if (ftdDropdownOptionA && ftdDropdownOptionB) {
      return ftdDropdownOptionA.id === ftdDropdownOptionB.id;
    }
    return false;
  }

  emitSelectedOptions(options: IFtdDropdownOption[] | undefined): void {
    this.selectedOptions.emit(options);
  }

  emitOptionsClosed() {
    this.optionsClosed.emit();
  }

  optionTrackBy(index: number, option: IFtdDropdownOption): number | string {
    return option.id;
  }

  /**
   * FilterSearch
   * @private
   * @param search
   */
  private filterSearch(search: string): void {
    if (search?.length) {
      this._options = this._defaultOptions.filter(
        (option: IFtdDropdownOption<{}>): boolean =>
          String(option.label).toLowerCase().indexOf(String(search).toLowerCase()) > -1
      );

      this.changeDetector.detectChanges();
    } else if (search?.length === 0 || this._options.length === 0) {
      this._options = this._defaultOptions;
    }
  }

  /**
   * SetSearchForm
   * @private
   */
  private setSearchForm(): void {
    this.form?.addControl(`${this.controlName}Search`, new FormControl(''));

    const subscriptionSearch: Subscription | undefined = this.form
      ?.get(`${this.controlName}Search`)
      ?.valueChanges.subscribe((search): void => {
        this.filterSearch(search);
      });

    if (subscriptionSearch) {
      this.subscriptions.push(subscriptionSearch);
    }
  }
}
