<mat-form-field
  class="ftd-textarea"
  appearance="outline"
  [class.error-input]="hasInputErrors"
  [formGroup]="form"
  [ngClass]="{ 'full-width': fillWidth }"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <textarea
    #textarea
    matInput
    [id]="id"
    [name]="name"
    [formControlName]="controlName"
    [placeholder]="placeholder"
    [attr.maxlength]="maxlength"
    [attr.rows]="rows"
    [attr.columns]="columns"
    [attr.data-test]="'textarea-input'"
  ></textarea>
  <mat-hint>
    <div class="custom-message">
      {{ customMessage }}
    </div>
    <div *ngIf="maxlength">{{ textarea.value.length }}/{{ maxlength }}</div>
  </mat-hint>
</mat-form-field>
