export class CalculationsUtils {
  /**
   * CalculatePercentageVariation
   * @param currentValue
   * @param newValue
   * @return number
   */
  static calculatePercentageVariation(currentValue: number, newValue: number): number {
    return (newValue * 100) / currentValue - 100;
  }

  /**
   * AddPercentageVariation
   * @param currentValue
   * @param percentageVariation
   * @return number
   */
  static addPercentageVariation(currentValue: number, percentageVariation: number): number {
    return currentValue + (currentValue * percentageVariation) / 100;
  }
}
