import { Component, Input } from '@angular/core';
import { TrafficLightsColor, trafficLightsColorDescriptionMap } from '../../types/traffic-lights.type';

@Component({
  selector: 'app-crosschecks-indication',
  styleUrls: ['./crosschecks-indication.component.scss'],
  templateUrl: './crosschecks-indication.component.html',
})
export class CrosschecksIndicationComponent {
  @Input() trafficLightStatusColor: TrafficLightsColor = 'notAvailable';
  @Input() trafficLightAdjStatusColor: TrafficLightsColor = 'notAvailable';
  @Input() showColorTextDescription: boolean = false;

  TRAFFIC_LIGHTS_COLOR_DESCRIPTION_MAP = trafficLightsColorDescriptionMap;
}
