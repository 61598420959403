<ng-container *ngIf="isMetadataAvailable && scenarioMetaDataToolTip" [ngSwitch]="technicalName">
  <div class="metadata-wrapper" [class]="technicalName">
    <ng-container *ngSwitchCase="METADATA_TEMPLATES.LIST_PRICE_INCL_TAX">
      <div class="model-code">
        <p><b> Model Code </b>{{ scenarioMetaDataToolTip?.modelCode }}</p>
      </div>

      <div class="description">
        <p>Tax includes the following components:</p>
      </div>

      <div class="taxes-container">
        <div class="taxes" *ngFor="let tax of scenarioMetaDataToolTip?.taxes">
          <p>
            <b>{{ tax.taxType }}</b> ({{ tax.percentage | percent | nullLabel }})
          </p>
          <p>
            {{ (tax.value | number: '1.2-2' | nullLabel) + ' ' + currencySymbol }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="METADATA_TEMPLATES.INDICATIVE_LEASE_RATE">
      <div class="model-code">
        <p><b> Model Code </b>{{ scenarioMetaDataToolTip?.modelCode }}</p>
      </div>

      <div class="description">
        <p>Lease rates are calculated on the basis of the following standard parameters:</p>
      </div>

      <div class="params-container">
        <div class="params">
          <b>Duration</b>
          <p>
            {{ scenarioMetaDataToolTip?.duration | nullLabel }}
          </p>
        </div>
        <div class="params">
          <b>Km per year</b>
          <p>
            {{ scenarioMetaDataToolTip?.kmPerYear | nullLabel }}
          </p>
        </div>
        <div class="params">
          <b>Interest Rate</b>
          <p>
            {{ scenarioMetaDataToolTip?.interestRate | nullLabel }}
          </p>
        </div>
        <div class="params">
          <b>Down Payment</b>
          <p>
            {{ scenarioMetaDataToolTip?.downPayment | nullLabel }}
          </p>
        </div>
        <div class="params">
          <b>CRV</b>
          <p>
            {{ scenarioMetaDataToolTip?.crv | nullLabel }}
          </p>
        </div>
        <div class="params">
          <b>Financial Product Type</b>
          <p>
            {{ scenarioMetaDataToolTip?.finProd | nullLabel }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="METADATA_TEMPLATES.RECOMMENDED_RETAIL_PRICE_REASON_CODE">
      <div class="model-code">
        <p><b> Model Code </b>{{ scenarioMetaDataToolTip?.modelCode }}</p>
      </div>

      <div class="description">
        <p>The following characteristics have changed:</p>
      </div>

      <div class="characteristics-container">
        <div class="characteristics">
          <p><b>NEW:</b> {{ scenarioMetaDataToolTip?.new?.toString() | nullLabel }}</p>
        </div>
        <div class="characteristics">
          <p><b>No longer included:</b> {{ scenarioMetaDataToolTip?.removed?.toString() | nullLabel }}</p>
        </div>
      </div>
    </ng-container>
  </div>
</ng-container>
