import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FtdNotifierService } from '../../common/services/ftd-notifier/ftd-notifier.service';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router, private toastService: FtdNotifierService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const user = this.authService.getLoggedInUser();

    if (!user) {
      this.router.navigate(['/main-scenario']);
      return false;
    } else {
      const userScenarioPermission = user.permissions.getHasPermissionToActionGeneric('CREATE_USER_SCENARIO');
      if (!userScenarioPermission) {
        this.router.navigate(['/main-scenario']);
        this.toastService.showError('No permissions found. Redirecting to Main Scenario.');
      }
      return userScenarioPermission;
    }
  }
}
