<ng-container [ngSwitch]="inputType">
  <ng-container *ngSwitchCase="'percentage'">
    <ng-container *ngTemplateOutlet="inputField; context: percentageCurrencyContext"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'currency'">
    <ng-container *ngTemplateOutlet="inputField; context: percentageCurrencyContext"></ng-container>
  </ng-container>
  <ng-container *ngSwitchCase="'text'">
    <ng-container *ngTemplateOutlet="inputField; context: textContext"></ng-container>
  </ng-container>
</ng-container>

<ng-template #inputField let-textAlign="textAlign">
  <div
    class="ftd-table-input-field input-{{ inputStatus }}-{{ inputType }}"
    [attr.data-test]="'ftd-table-input-status-' + inputStatus"
    [formGroup]="form"
  >
    <mat-icon
      *ngIf="
        (inputType === 'text' && icon && iconPosition === 'left') ||
        (inputStatus !== 'default' && inputStatus !== 'saved' && inputStatus !== 'no_status')
      "
      class="mat-icon-left {{ inputStatus }}-icon"
      >{{
        inputStatus !== 'default' && inputStatus !== 'saved' && inputStatus !== 'no_status' ? 'hourglass_full' : icon
      }}</mat-icon
    >
    <ng-container *ngIf="isMatrixView; else tableView" formArrayName="rows">
      <ng-container [formGroupName]="rowIndex">
        <input
          *ngIf="inputType === 'currency'; else withoutMask"
          class="text-{{ textAlign }} input-{{ inputStatus }}"
          type="text"
          [placeholder]="placeholder"
          [formControlName]="controlName"
          [name]="name"
          [id]="id"
          appFtdCurrencyMask
          [options]="currencyMaskOption"
          #textField
          autocomplete="off"
          (paste)="onPasteEvent($event)"
          (keyup)="onKeyUpEvent($event)"
        />

        <ng-template #withoutMask>
          <input
            *ngIf="inputType === 'text'; else inputNumber"
            class="text-{{ textAlign }} input-{{ inputStatus }}"
            type="text"
            [placeholder]="placeholder"
            [formControlName]="controlName"
            [name]="name"
            [id]="id"
            #textField
          />
        </ng-template>

        <ng-template #inputNumber>
          <input
            class="text-{{ textAlign }} input-{{ inputStatus }}"
            type="text"
            [placeholder]="placeholder"
            [formControlName]="controlName"
            [name]="name"
            [id]="id"
            #textField
            appFtdCurrencyMask
            [options]="currencyMaskOption"
            (paste)="onPasteEvent($event)"
            (keyup)="onKeyUpEvent($event)"
          />
        </ng-template>
      </ng-container>
    </ng-container>
    <ng-template #tableView>
      <ng-container>
        <input
          *ngIf="inputType === 'currency'; else withoutMask"
          class="text-{{ textAlign }} input-{{ inputStatus }}"
          type="text"
          [placeholder]="placeholder"
          [formControlName]="controlName"
          [name]="name"
          [id]="id"
          appFtdCurrencyMask
          [options]="currencyMaskOption"
          #textField
          autocomplete="off"
        />

        <ng-template #withoutMask>
          <input
            class="text-{{ textAlign }} input-{{ inputStatus }}"
            type="text"
            [placeholder]="placeholder"
            [formControlName]="controlName"
            [name]="name"
            [id]="id"
            #textField
          />
        </ng-template>
      </ng-container>
    </ng-template>
    <mat-icon
      *ngIf="inputType === 'text' && icon && iconPosition === 'right'"
      class="mat-icon-right {{ inputStatus }}-icon"
      >{{ icon }}</mat-icon
    >
  </div>
</ng-template>
