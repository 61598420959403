/**
 * Used for data-mapping between backend & frontend
 * Every ENUM-type is corresponding to a column's section & hold different keys for accessing backend's data.
 */
export type MatrixColumnTechnicalName =
  | CurrentPricingTechnicalName
  | CurrentFinancialTechnicalName
  | CurrentCrossCheckTechnicalName
  | PriceEngineRecommendationTechnicalName
  | PriceEditorTechnicalName
  | ForecastedFinancialTechnicalName
  | ForecastedCrosscheckTechnicalName;

export enum CurrentPricingTechnicalName {
  list_price_incl_tax = 'listPriceInclTax',
  indicative_lease_rate = 'indicativeLeaseRate',
  effective_since = 'effectiveSince',
  price_change_count = 'priceChangeCount',
  typically_equipped_price = 'typicallyEquippedPrice',
  list_price_excl_tax = 'listPriceExclTax',
  typically_equipped_lease_rate = 'typicallyEquippedLeaseRate',
}

export enum CurrentFinancialTechnicalName {
  budget_volume_retail = 'budgetVolumeRetail',
  forecast_volume_retail = 'forecastVolumeRetail',
  retail_volume_achievement_pct = 'retailVolumeAchievementPct',
  expected_volume_retail = 'expectedVolumeRetail',
  current_cm_per_unit = 'currentCmPerUnit',
  typically_equipped_current_cm_per_unit = 'typicallyEquippedCurrentCmPerUnit',
  forecast_1_cm_per_unit = 'forecast1CmPerUnit',
  forecast_2_cm_per_unit = 'forecast2CmPerUnit',
  budget_cm_per_unit = 'budgetCmPerUnit',
  co2_basecar = 'co2BaseCar',
  retail_volume_actual_abs = 'retailVolumeActualAbs',
}

export enum CurrentCrossCheckTechnicalName {
  summary = 'summaryTrafficLight',
  sales_pipeline = 'salesPipelineTrafficLight',
  production_pipeline = 'productionPipelineTrafficLight',
  stock = 'stockTrafficLight',
  competition = 'competitionTrafficLight',
  markets = 'marketsTrafficLight',
  portfolio = 'portfolioTrafficLight',
  channels = 'channelsTrafficLight',
  micro_macro = 'microMacroTrafficLight',
}

export enum PriceEngineRecommendationTechnicalName {
  recommended_price_engine = 'recommendedPriceEngine',
  indicative_lease_rate_engine = 'indicativeLeaseRateEngine',
  predictionEvaluationIndicator = 'predictionEvaluationIndicator',
  typically_equipped_price_engine = 'typicallyEquippedPriceEngine',
  typically_equipped_lease_rate_engine = 'typicallyEquippedLeaseRateEngine',
  delta_current_price_engine_abs = 'deltaCurrentPriceEngineAbs',
}

export enum PriceEditorTechnicalName {
  price_point_price = 'pricePointPrice',
  price_point_percentage = 'pricePointPercentage',
  indicative_lease_rate_adj = 'indicativeLeaseRateAdj',
  typically_equipped_price_adj = 'typicallyEquippedPriceAdj',
  typically_equipped_lease_rate_adj = 'typicallyEquippedLeaseRateAdj',
  effective_date = 'effectiveDate',
  comments = 'comments',
  notes = 'notes',
  delta_current_price_editor = 'deltaCurrentPriceEditor',
  delta_current_indicative_lease_rate = 'deltaCurrentIndicativeLeaseRate',
  status = 'status',
  adjusted_list_price_reason_code = 'adjustedListPriceReasonCode',
  recommended_retail_price = 'recommendedRetailPrice',
  recommended_retail_price_valid_from_date = 'recommendedRetailPriceValidFromDate',
  recommended_retail_price_reason_code = 'recommendedRetailPriceReasonCode',
  list_price_excl_tax_adj = 'listPriceExclTaxAdj',
}

export enum ForecastedFinancialTechnicalName {
  retail_forecast = 'retailForecast',
  expected_volume_orders_adj = 'expectedVolumeRetailAdj',
  forecast_latest_cm_per_unit = 'forecastLatestCmPerUnit',
  delta_expected_volume_retail_current_adj = 'deltaExpectedVolumeRetailCurrentAdj',
  delta_total_cm_abs = 'deltaTotalCmAbs',
  budget_volume_retail_next_year = 'budgetVolumeRetailNextYear',
  forecast_volume_retail = 'forecastVolumeRetail',
  delta_cm_per_unit = 'deltaCmPerUnit',
  adjusted_cm_per_unit = 'adjustedCmPerUnit',
  current_cm_per_unit = 'currentCmPerUnit',
  budget_next_year_cm_per_unit = 'budgetNextYearCmPerUnit',
}

export enum ForecastedCrosscheckTechnicalName {
  sales_pipeline = 'salesPipelineTrafficLightAdj',
  production_pipeline = 'productionPipelineTrafficLightAdj',
  stock = 'stockTrafficLightAdj',
  competition = 'competitionTrafficLightAdj',
  markets = 'marketsTrafficLightAdj',
  portfolio = 'portfolioTrafficLightAdj',
  channels = 'channelsTrafficLightAdj',
  micro_macro = 'microMacroTrafficLightAdj',
}
