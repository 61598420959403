<div class="create-discount-stepper-container">
  <div class="empty-div"></div>
  <app-ftd-stepper
    [currentStep]="currentStep"
    [stepperData]="stepperData"
    (goToStepEvent)="goToStep($event)"
    [maxStep]="this.maxStep"
  ></app-ftd-stepper>
  <app-ftd-custom-modal-header class="close-button-wrapper"></app-ftd-custom-modal-header>
</div>

<mat-divider style="width: 576px"></mat-divider>
<app-ftd-custom-modal id="create-discount-form">
  <span class="create-discount-body-title">Create Discount</span>
  <app-ftd-custom-modal-body *ngIf="currentStep === 1" [isRequestProcessing]="isRequestProcessing">
    <div class="mb-3">
      <form class="create-discount-body" [formGroup]="discountTypeForm">
        <div class="grid">
          <label class="form-label">Market*</label>
          <app-ftd-dropdown
            [isSearchVisible]="true"
            [form]="discountTypeForm"
            [label]="'Select market'"
            [controlName]="'market'"
            [options]="marketsOptions"
            [isFlagsVisible]="true"
            [isLabelVisible]="false"
            (selectedOption)="getUserBrands($event)"
          ></app-ftd-dropdown>
          <label class="form-label">Category*</label>
          <app-ftd-dropdown
            (selectedOption)="selectedCategory($event)"
            [form]="discountTypeForm"
            [label]="'Select category'"
            [controlName]="'discountCategory'"
            [options]="categoryOptions"
            [isLabelVisible]="false"
          ></app-ftd-dropdown>
          <label class="form-label">Sales Channel*</label>
          <app-ftd-multi-dropdown
            [form]="discountTypeForm"
            [label]="'Select sales channel'"
            [controlName]="'salesChannels'"
            [options]="salesChannelOptions"
            [isLabelVisible]="false"
          ></app-ftd-multi-dropdown>
        </div>
        <div class="grid">
          <label class="form-label">Brand*</label>
          <app-ftd-dropdown
            [disabled]="brandsOptions.length === 0"
            [form]="discountTypeForm"
            [label]="'Select brand'"
            [controlName]="'brand'"
            [options]="brandsOptions"
            [isLabelVisible]="false"
          ></app-ftd-dropdown>
          <label class="form-label">Discount Type*</label>
          <app-ftd-dropdown
            [form]="discountTypeForm"
            [label]="'Select type'"
            [controlName]="'discountType'"
            [options]="discountTypeOptions"
            [isLabelVisible]="false"
          ></app-ftd-dropdown>
          <label class="form-label">Name*</label>
          <app-ftd-input [form]="discountTypeForm" [placeholder]="'Name'" [controlName]="'name'"></app-ftd-input>
        </div>
      </form>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-body *ngIf="currentStep === 2" [isRequestProcessing]="isRequestProcessing">
    <div class="mb-3">
      <form class="create-discount-body" [formGroup]="discountSettingsForm">
        <div class="grid">
          <label class="form-label">Valid As Of*</label>
          <app-ftd-date-picker
            [id]="'startDate'"
            [placeholder]="'Date'"
            [daysAhead]="0"
            [name]="'startDate'"
            [enableMinDate]="true"
            [form]="discountSettingsForm"
            [controlName]="'startDate'"
            [pickRanges]="false"
            class="current-step"
          ></app-ftd-date-picker>
          <div class="form-label-info-dropdown-container">
            <div class="form-label-info">
              <label class="form-label">Application Level*</label>
              <mat-icon [matTooltip]="applicationLevelTooltipMessage" [matTooltipPosition]="'above'">info</mat-icon>
            </div>
            <app-ftd-dropdown
              [form]="discountSettingsForm"
              [label]="'App. Level'"
              [controlName]="'applicationLevel'"
              [options]="appLevelOptions"
              [isLabelVisible]="false"
            ></app-ftd-dropdown>
          </div>
        </div>
        <div class="grid">
          <label class="form-label">Valid Until*</label>
          <app-ftd-date-picker
            [id]="'endDate'"
            [placeholder]="'Date'"
            [daysAhead]="0"
            [name]="'endDate'"
            [controlName]="'endDate'"
            [minDate]="minEndDate"
            [enableMinDate]="true"
            [pickRanges]="false"
            [form]="discountSettingsForm"
          ></app-ftd-date-picker>
          <div class="form-label-info-dropdown-container">
            <div class="form-label-info">
              <label class="form-label">Benefit Type*</label>
              <mat-icon [matTooltip]="benefitTypeTooltipMessage" [matTooltipPosition]="'above'">info</mat-icon>
            </div>
            <app-ftd-dropdown
              [form]="discountSettingsForm"
              [label]="'Benefit Type'"
              [controlName]="'benefitType'"
              [options]="benefitTypeOptions"
              [isLabelVisible]="false"
            ></app-ftd-dropdown>
          </div>
        </div>
      </form>
    </div>
  </app-ftd-custom-modal-body>

  <div class="create-discount-footer">
    <app-ftd-button
      (btnClick)="onClickCancel()"
      [id]="'cancel-create-discount-btn'"
      [label]="'Cancel'"
      [hierarchy]="'tertiary'"
      ariaText="Cancel"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="false"
    ></app-ftd-button>
    <div class="create-discount-footer-buttons-wrapper">
      <app-ftd-button
        (btnClick)="previousStep()"
        [id]="'create-discount-btn'"
        [label]="'Previous'"
        [hierarchy]="'secondary'"
        ariaText="Previous"
        [matTooltip]="''"
        matTooltipPosition="above"
        [matTooltipDisabled]="false"
        [disabled]="currentStep === 1"
        *ngIf="currentStep !== 1"
      ></app-ftd-button>
      <app-ftd-button
        *ngIf="currentStep < stepperData.length"
        (btnClick)="nextStep()"
        [id]="'continue-discount-btn'"
        [label]="'Continue'"
        ariaText="Continue"
        [matTooltip]="''"
        matTooltipPosition="above"
        [matTooltipDisabled]="false"
        [disabled]="!discountTypeForm.valid"
      ></app-ftd-button>
      <app-ftd-button
        *ngIf="currentStep === stepperData.length"
        (btnClick)="createDiscount()"
        [id]="'create-discount-btn'"
        [label]="'Create'"
        ariaText="Create"
        [matTooltip]="''"
        matTooltipPosition="above"
        [matTooltipDisabled]="false"
        [disabled]="!discountTypeForm.valid || !discountSettingsForm.valid || isRequestProcessing"
      ></app-ftd-button>
    </div>
  </div>
</app-ftd-custom-modal>
