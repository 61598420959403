export interface ISkeletonTheme {
  hasTitle?: boolean;
  hasHeaderRight?: boolean;
  hasFiltersToggle?: boolean;
  hasChartTitle?: boolean;
  filtersNumber?: number;
  legendNumber?: number;
  tilesNumber?: number;
  chartNames?: string[];
}

/**
 * Sales Pipeline Skeleton themes
 */

/**
 * Lease Returns
 */
export class LeaseReturnsSkeletonTheme implements ISkeletonTheme {
  hasTitle = true;
  hasHeaderRight = true;
  hasFiltersToggle = true;
  hasChartTitle = false;
  filtersNumber = 2;
  legendNumber = 5;
  tilesNumber = 0;
  chartNames = ['lease_returns'];
}

/**
 * Incoming Orders
 */
export class OrderIntakeSkeletonTheme implements ISkeletonTheme {
  hasTitle = true;
  hasHeaderRight = true;
  hasFiltersToggle = true;
  hasChartTitle = false;
  filtersNumber = 2;
  legendNumber = 7;
  tilesNumber = 1;
  chartNames = ['orderIntake'];
}

/**
 * Calendarized Order Banks
 */
export class CalendarizedOrderBankSkeletonTheme implements ISkeletonTheme {
  hasTitle = true;
  hasHeaderRight = true;
  hasFiltersToggle = true;
  hasChartTitle = false;
  filtersNumber = 1;
  legendNumber = 4;
  tilesNumber = 2;
  chartNames = ['calendarized_order_bank'];
}

/**
 * Retail Sales
 */
export class RetailSalesSkeletonTheme implements ISkeletonTheme {
  hasTitle = true;
  hasHeaderRight = true;
  hasFiltersToggle = true;
  hasChartTitle = false;
  filtersNumber = 2;
  legendNumber = 7;
  tilesNumber = 1;
  chartNames = ['retail_sales'];
}

/**
 * Stock Skeleton themes
 */

/**
 * Stock Coverage
 */
export class StockCoverageSkeletonTheme implements ISkeletonTheme {
  hasTitle = true;
  hasHeaderRight = true;
  hasFiltersToggle = true;
  hasChartTitle = true;
  filtersNumber = 2;
  legendNumber = 4;
  tilesNumber = 1;
  chartNames = ['stock_coverage_bar', 'stock_coverage_line'];
}

/**
 * Stock Age
 */
export class StockAgeSkeletonTheme implements ISkeletonTheme {
  hasTitle = true;
  hasHeaderRight = true;
  hasFiltersToggle = false;
  hasChartTitle = true;
  filtersNumber = 2;
  legendNumber = 7;
  tilesNumber = 2;
  chartNames = ['stock_age'];
}

/**
 * Stock Mix
 */
export class StockMixSkeletonTheme implements ISkeletonTheme {
  hasTitle = true;
  hasHeaderRight = true;
  hasFiltersToggle = false;
  hasChartTitle = true;
  filtersNumber = 2;
  legendNumber = 2;
  tilesNumber = 2;
  chartNames = ['stock_coverage'];
}

/**
 * Markets themes
 */
export class MarketsSkeletonTheme implements ISkeletonTheme {
  hasTitle = false;
  hasHeaderRight = false;
  hasFiltersToggle = false;
  hasChartTitle = true;
  filtersNumber = 0;
  legendNumber = 2;
  tilesNumber = 0;
  chartNames = ['markets'];
}

/**
 * Portfolio themes
 */

/**
 * InternalPriceLadder
 */
export class InternalPriceLadderSkeletonTheme implements ISkeletonTheme {
  hasTitle = false;
  hasHeaderRight = true;
  hasFiltersToggle = false;
  hasChartTitle = true;
  filtersNumber = 2;
  legendNumber = 2;
  tilesNumber = 0;
  chartNames = ['internal_price_ladder'];
}

export type SkeletonThemeKey =
  | 'LeaseReturns'
  | 'OrderIntake'
  | 'CalendarizedOrderBank'
  | 'RetailSales'
  | 'StockCoverage'
  | 'StockAge'
  | 'StockMix'
  | 'Markets'
  | 'InternalPriceLadder';

export class SkeletonThemeTemplates {
  static readonly LeaseReturns = LeaseReturnsSkeletonTheme;
  static readonly OrderIntake = OrderIntakeSkeletonTheme;
  static readonly CalendarizedOrderBank = CalendarizedOrderBankSkeletonTheme;
  static readonly RetailSales = RetailSalesSkeletonTheme;
  static readonly StockCoverage = StockCoverageSkeletonTheme;
  static readonly StockAge = StockAgeSkeletonTheme;
  static readonly StockMix = StockMixSkeletonTheme;
  static readonly Markets = MarketsSkeletonTheme;
  static readonly InternalPriceLadder = InternalPriceLadderSkeletonTheme;
}
