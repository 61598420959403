import { AppThemeColors } from '../../../../common/constants/app-theme-colors';
import { ColorSchemeEnum } from '../../../enums/color-scheme.enum';
import { DiscountApprovalStatus } from '../../../enums/discount-approval-status.enum';

export const discountStatusMap: Map<DiscountApprovalStatus, IDiscountStatusMap> = new Map<
  DiscountApprovalStatus,
  IDiscountStatusMap
>([
  [
    DiscountApprovalStatus.SUBMITTED,
    {
      backgroundColor: AppThemeColors['ftd-attention'].default,
      chipText: '1/4 Pending',
      colorScheme: ColorSchemeEnum.PENDING,
      text: 'Pending',
      textColor: AppThemeColors['ftd-status-pending']['300'],
      tooltip: 'Submitted by editor, waiting for lead editor approval',
    },
  ],
  [
    DiscountApprovalStatus.LVL1_APPROVED,
    {
      backgroundColor: AppThemeColors['ftd-attention'].default,
      chipText: '2/4 Pending',
      colorScheme: ColorSchemeEnum.PENDING,
      text: 'Pending',
      textColor: AppThemeColors['ftd-status-pending']['300'],
      tooltip: 'Approved by lead editor, waiting for controlling approval',
    },
  ],
  [
    DiscountApprovalStatus.NSC_APPROVED,
    {
      backgroundColor: AppThemeColors['ftd-attention'].default,
      chipText: '3/4 Pending',
      colorScheme: ColorSchemeEnum.PENDING,
      text: 'Pending',
      textColor: AppThemeColors['ftd-status-pending']['300'],
      tooltip: 'Approved by controlling, waiting for release by lead editor',
    },
  ],
  [
    DiscountApprovalStatus.APPROVED,
    {
      backgroundColor: AppThemeColors['ftd-positive'].default,
      chipText: '4/4 Complete',
      colorScheme: ColorSchemeEnum.ACTIVE,
      text: 'Complete',
      textColor: AppThemeColors['ftd-opportunity']['200'],
      tooltip: 'Released by lead editor, waiting for valid as of date',
    },
  ],
  [
    DiscountApprovalStatus.EXPORTED,
    {
      backgroundColor: AppThemeColors['ftd-positive'].default,
      chipText: 'Active',
      colorScheme: ColorSchemeEnum.ACTIVE,
      text: 'Active',
      textColor: AppThemeColors['ftd-opportunity']['200'],
      tooltip: 'Discount matrix is active',
    },
  ],
  [
    DiscountApprovalStatus.REJECTED,
    {
      backgroundColor: AppThemeColors['ftd-negative'].default,
      chipText: 'Rejected',
      colorScheme: ColorSchemeEnum.REJECTED,
      text: 'Rejected',
      textColor: AppThemeColors['ftd-risk']['200'],
      tooltip: 'Discount matrix got rejected during approval process',
    },
  ],
  [
    DiscountApprovalStatus.RETIRED,
    {
      backgroundColor: AppThemeColors['ftd-grey']['500'],
      chipText: 'Retired',
      colorScheme: ColorSchemeEnum.RETIRED,
      text: 'Retired',
      textColor: AppThemeColors['ftd-grey']['700'],
      tooltip: 'Validity of discount matrix is exceeded',
    },
  ],
]);

export interface IDiscountStatusMap {
  colorScheme: ColorSchemeEnum;
  text: string;
  textColor: string;
  backgroundColor: string;
  chipText: string;
  tooltip: string;
}
