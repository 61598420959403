import { IPermission, Permissions } from './permissions.model';

export class User {
  public b2xroles: string[];
  public departmentnumber: string;
  public employeenumber: number;
  public employeetype: string;
  public family_name: string;
  public given_name: string;
  public groups: string[];
  public inumber: string;
  public name: string;
  public permissions: Permissions;
  public salutation: string;
  public sub: string;

  constructor(user: IUserResponse) {
    this.given_name = user.given_name;
    this.family_name = user.family_name;
    this.name = user.name;
    this.departmentnumber = user.departmentnumber;
    this.salutation = user.salutation;
    this.inumber = user.inumber;
    this.employeenumber = user.employeenumber;
    this.employeetype = user.employeetype;
    this.b2xroles = user.b2xroles;
    this.sub = user.sub;
    this.groups = user.groups;
    this.permissions = new Permissions(user.permissions);
  }

  getUserName(): string {
    return `${this?.given_name} ${this?.family_name}`;
  }
}

export interface IUserResponse {
  b2xroles: string[];
  departmentnumber: string;
  employeenumber: number;
  employeetype: string;
  family_name: string;
  given_name: string;
  groups: string[];
  inumber: string;
  name: string;
  permissions: IPermission[];
  salutation: string;
  sub: string;
}
