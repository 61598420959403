<div class="matrix-view-discount-actions-toolbar-content">
  <span [attr.data-test]="'actions-toolbar-selected-discount-points'">{{ data.message }}</span>
  <div class="matrix-view-discount-actions-toolbar-text"></div>
  <div class="matrix-view-discount-actions-toolbar-actions">
    <div
      (click)="onEditDiscountsClicked()"
      class="actions-edit-discounts"
      [class.disabled]="data.actions.isEditDiscountsDisabled"
      *ngIf="data.actions.isEditDiscounts"
      [attr.data-test]="'actions-toolbar-edit-discount-points'"
    >
      <mat-icon>edit</mat-icon>
      <span>EDIT DISCOUNTS</span>
    </div>
  </div>
</div>
