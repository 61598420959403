<app-ftd-custom-modal>
  <app-ftd-custom-modal-header
    [titleLeftMatIcon]="'warning_amber'"
    [colorLeftMatIcon]="'warning'"
    [title]="title"
  ></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body>
    <div class="mb-3">
      <span [innerHTML]="description"></span>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer [cancelButtonText]="'NO, GO BACK'">
    <app-ftd-button
      [id]="'submit-status-btn'"
      (btnClick)="action(true)"
      [label]="'yes, I’m sure'"
      ariaText="Confirm Submit"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="true"
      [attr.data-test]="'override-status-modal-submit-btn'"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
