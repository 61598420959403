import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IMatrixColumnConfig } from '../../../matrix-view/models/matrix-view.model';
import {
  MATRIX_VIEW_MAX_PINNED_COLUMNS,
  MATRIX_VIEW_MIN_PINNED_COLUMNS,
} from 'src/app/matrix-view/constants/matrix-view-columns.config';
import { Subscription } from 'rxjs';

interface IMatrixViewColumnsOrderingModalForm {
  search: FormControl<string>;
}

@Component({
  selector: 'app-ftd-matrix-view-columns-ordering-component',
  styleUrls: ['./ftd-matrix-view-columns-ordering-component.component.scss'],
  templateUrl: './ftd-matrix-view-columns-ordering-component.component.html',
})

/**
 * @class FtdMatrixViewColumnsOrderingComponentComponent
 */
export class FtdMatrixViewColumnsOrderingComponentComponent implements OnInit, OnDestroy {
  @Input() matrixColumnConfig: IMatrixColumnConfig[] = [];

  public isOnDrag: boolean = false;
  public form: FormGroup = new FormGroup<IMatrixViewColumnsOrderingModalForm>({
    search: new FormControl<string>('', {
      nonNullable: true,
    }),
  });

  private subscriptions: Subscription[] = [];

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initSearchListener();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * InitSearchListener
   * @private
   */
  private initSearchListener(): void {
    const subscription: Subscription = this.form.controls.search.valueChanges.subscribe((search: string): void => {
      this.filterSearchResults(search);
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Filter Matrix Column Config Search results
   * FilterSearchResults
   * @param search
   */
  filterSearchResults(search: string): void {
    this.matrixColumnConfig.forEach((matrixColumnConfig: IMatrixColumnConfig) => {
      matrixColumnConfig?.children?.forEach((innerMatrixColumnConfig: IMatrixColumnConfig) => {
        if (search.length === 0) {
          innerMatrixColumnConfig.isHidden = false;
        } else {
          innerMatrixColumnConfig.isHidden = !innerMatrixColumnConfig?.displayName
            .toLowerCase()
            .includes(search.toLowerCase());
        }
      });

      if (
        !matrixColumnConfig?.children?.filter(
          (innerMatrixColumnConfig: IMatrixColumnConfig) => !innerMatrixColumnConfig.isHidden
        ).length
      ) {
        matrixColumnConfig.isHidden = true;
      } else if (search.length === 0) {
        matrixColumnConfig.isHidden = false;
      }
    });
  }

  /**
   * Handle Drag And Drop Logic
   * @param event
   * @param matrixColumnConfig
   */
  drop(event: CdkDragDrop<string[]>, matrixColumnConfig: IMatrixColumnConfig[]): void {
    moveItemInArray(matrixColumnConfig, event.previousIndex, event.currentIndex);
  }

  /**
   * SetChildCheck
   * @param matrixColumnConfig
   * @param isVisible
   */
  setChildVisibility(matrixColumnConfig: IMatrixColumnConfig, isVisible: boolean): void {
    matrixColumnConfig.isVisible = isVisible;
    matrixColumnConfig.children?.forEach((item: IMatrixColumnConfig): void => {
      item.isVisible = isVisible;
    });
  }

  /**
   * SetParentVisibility
   * @param matrixColumnConfig
   * @param parent
   * @param isVisible
   */
  setParentVisibility(matrixColumnConfig: IMatrixColumnConfig, parent: IMatrixColumnConfig, isVisible: boolean): void {
    if (isVisible && !parent.isVisible) {
      parent.isVisible = isVisible;
      // Unpin every non selected column
      parent.children?.forEach((item: IMatrixColumnConfig): void => {
        item.isNotCollapsible = false;
      });
      matrixColumnConfig.isNotCollapsible = true;
    }
    matrixColumnConfig.isVisible = isVisible;
  }

  /**
   * IsOnDrag
   */
  setIsOnDrag(isOnDrag: boolean): void {
    this.isOnDrag = isOnDrag;
  }

  /**
   * CheckIsNotCollapsible
   * @param parentColumn
   * @param newValue
   */
  checkIsNotCollapsible(parentColumn: IMatrixColumnConfig, newValue: boolean): boolean {
    const pinnedColumns: number = parentColumn.children!.filter(
      (el: IMatrixColumnConfig) => el.isNotCollapsible
    ).length;
    if (newValue) {
      return pinnedColumns < MATRIX_VIEW_MAX_PINNED_COLUMNS;
    } else {
      return pinnedColumns > MATRIX_VIEW_MIN_PINNED_COLUMNS;
    }
  }
}
