import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ftd-custom-modal-footer',
  styleUrls: ['./ftd-custom-modal-footer.component.scss'],
  templateUrl: './ftd-custom-modal-footer.component.html',
})
export class FtdCustomModalFooterComponent {
  /**
   * Add / remove default "Cancel" button on the most left part in the modal-footer
   */
  @Input() hideCancelButton: boolean = false;
  @Input() cancelButtonText: string = 'Cancel';

  /**
   * Callback event to fire after clicking on "Cancel" button when visible
   */
  @Output() cancelButtonClicked: EventEmitter<any> = new EventEmitter();

  handleCancelClick($event: MouseEvent): void {
    this.cancelButtonClicked.emit($event);
  }
}
