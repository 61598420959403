import { Injectable } from '@angular/core';
import { ScenarioContext } from 'src/app/common/enums/scenario-context.enum';
import { ScenarioDefaultIds } from '../../../common/enums/scenario-default-ids.enum';

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private currentScenarioContext: ScenarioContext = ScenarioContext.mainScenario;
  private _scenarioId: string | ScenarioDefaultIds.governance = ScenarioDefaultIds.governance;

  get scenarioId(): string {
    return this._scenarioId;
  }

  set scenarioId(value: ScenarioDefaultIds.governance | string) {
    this._scenarioId = value;
  }

  setCurrentScenarioContext(scenario: ScenarioContext): void {
    this.currentScenarioContext = scenario;
  }

  getCurrentScenarioContext(): ScenarioContext {
    return this.currentScenarioContext;
  }
}
