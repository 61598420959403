import { BenefitType, IDiscount } from '../../../../../graphql/services/gql-api.service';
import { Component, Inject, Optional } from '@angular/core';
import { DiscountMatrixViewDataFormattingService } from '../../services/matrix-view-data-formatting/matrix-view-data-formatting.service';
import { DiscountMatrixViewFormService } from '../../services/matrix-view-form/matrix-view-form.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ICurrencyMaskConfig } from '../../../../../common/directives/app-currency-mask/lib/currency-mask.config';
import { IEditDiscountsForm } from './edit-discounts-modal.model';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-edit-discounts-modal',
  templateUrl: './edit-discounts-modal.component.html',
})
/**
 * @class EditDiscountsModalComponent
 */
export class EditDiscountsModalComponent {
  public title: string = 'Edit Discounts';
  public form: FormGroup = new FormGroup<IEditDiscountsForm>({
    amount: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  public currencyMaskOption: ICurrencyMaskConfig = {
    allowNegative: true,
    decimal: '.',
    precision: 1,
    suffix: '%',
  };

  public currencySymbol: string = getCurrencySymbol(
    this.discountMatrixViewDataFormattingService.getMatrixViewCurrency(),
    'narrow'
  );

  private currency: string = '%';
  public discountBenefitTypeContext: string = 'gross';

  /**
   * @constructor
   * @param data
   * @param dialog
   * @param dialogRef
   * @param discountMatrixViewFormService
   * @param discountMatrixViewDataFormattingService
   */
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { discount: IDiscount },
    public dialog: MatDialog,
    private dialogRef: MatDialogRef<EditDiscountsModalComponent>,
    private discountMatrixViewFormService: DiscountMatrixViewFormService,
    private discountMatrixViewDataFormattingService: DiscountMatrixViewDataFormattingService
  ) {
    this.setDiscountBenefitTypeContext(this.data.discount.benefitType.toUpperCase() as BenefitType);
  }

  /**
   * SetCurrencyContext
   * @param benefitType
   */
  setDiscountBenefitTypeContext(benefitType: BenefitType): void {
    const isPercentageSelected: boolean = benefitType === BenefitType.Relative;

    this.discountBenefitTypeContext = isPercentageSelected ? 'net' : 'gross';
    this.currency = isPercentageSelected ? '%' : this.currencySymbol;

    this.currencyMaskOption.precision = isPercentageSelected ? 1 : 2;
    this.currencyMaskOption.suffix = this.currency;
  }

  /**
   * TotalModels
   */
  get totalModels(): number {
    return this.discountMatrixViewFormService.getFormTotalRowsCarModels();
  }

  /**
   * OnSubmit
   */
  onSubmit(): void {
    if (this.form.valid) {
      this.submitEditDiscounts();
    }
  }

  /**
   * SubmitEditDiscounts
   */
  submitEditDiscounts(): void {
    this.discountMatrixViewFormService.updateFormRowCheckedValues(
      this.currency === '%' ? 'percentage' : 'currency',
      this.form.get('amount')?.value
    );
    this.dialogRef.close();
  }
}
