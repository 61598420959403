import { AuthService } from '../../../auth/services/auth.service';
import { CalculationsUtils } from '../../../common/utils/calculations.utils';
import { ContextService } from '../context/context.service';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IGranularityDto } from '../../models/api.model';
import { IPriceSubmission, IPriceSubmissionSubmitModal } from '../../models/price-submission.model';
import { ISubmitPricePointsMutationVariables } from '../../../graphql/services/gql-api.service';

import { Injectable, OnDestroy } from '@angular/core';
import { MatrixViewDataFormattingService } from '../matrix-view-data-formatting/matrix-view-data-formatting.service';
import { PricePointApprovalStatus } from '../../enums/price-point-approval-status.enum';
import { Subject, Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';
import { distinctUntilChanged } from 'rxjs/operators';
import { getCurrencySymbol } from '@angular/common';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';

dayjs.extend(isSameOrAfter);

@Injectable({
  providedIn: 'root',
})
/**
 * @class MatrixViewFormService
 */
export class MatrixViewFormService implements OnDestroy {
  private createUpdatePricePoint: IPriceSubmission | any = null;
  private currentUser!: User | null;
  public formValueChangedSubject$: Subject<FormGroup> = new Subject<FormGroup>();

  private subscriptions: Subscription[] = [];

  /**
   * EmitFormValueChanged
   * @param formGroup
   */
  private emitFormValueChanged(formGroup: FormGroup): void {
    this.formValueChangedSubject$.next(formGroup);
  }

  private form!: FormGroup;

  /**
   * @constructor
   * @param fb
   * @param authService
   * @param contextService
   * @param matrixViewDataFormattingService
   */
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private contextService: ContextService,
    private matrixViewDataFormattingService: MatrixViewDataFormattingService
  ) {
    this.form = this.fb.group({
      rows: this.fb.array([]),
    });

    this.initFormValueChangesListener();
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * GetFormArray
   * @return FormArray<FormGroup>
   */
  getFormArray(): FormArray<FormGroup> {
    return this.form.get('rows') as FormArray<FormGroup>;
  }

  /**
   * GetForm
   * @return FormGroup
   */
  getForm(): FormGroup {
    return this.form;
  }

  /**
   * SetForm
   * @param formGroup
   */
  setForm(formGroup: FormGroup): void {
    this.form = formGroup;
  }

  /**
   * UpdateFormRowCheckedValues
   * @param context
   * @param value
   * @param effectiveDate
   * @param comment
   */
  updateFormRowCheckedValues(
    context: 'percentage' | 'currency',
    value: number,
    effectiveDate: string,
    comment: string
  ): void {
    this.getFormRowsChecked().forEach((item: FormGroup): void => {
      if (!isNaN(Number(value)) && !isNaN(item.value.scenarioData?.priceEditorData?.listPriceInclTax)) {
        const currentPrice: number = item.value.scenarioData?.priceEditorData?.listPriceInclTax as number;
        const adjustPercentage: number =
          context === 'percentage'
            ? Number(value)
            : CalculationsUtils.calculatePercentageVariation(currentPrice, currentPrice + value);

        const newPrice: string = CalculationsUtils.addPercentageVariation(currentPrice, adjustPercentage).toFixed(4);

        item.get('comment')?.setValue(comment);
        item.get('price')?.setValue(newPrice);
        item.get('effectiveDate')?.setValue(effectiveDate);
        item.get('pricePointPercentage')?.setValue(adjustPercentage);
      }
    });
  }

  /**
   * ClearFormValues
   */
  clearFormValues(): void {
    this.getFormArray().controls.forEach((item: FormGroup): void => {
      item.get('price')?.setValue(null);
      item.get('effectiveDate')?.setValue(null);
      item.get('pricePointPercentage')?.setValue(null);
      item.get('checkboxStatus')?.setValue(false);
      item.get('comment')?.setValue('');
    });
  }

  /**
   * ResetForm
   * Reset form and keep existing data, if clearData is true, clear the form also
   * @param clearData boolean
   */
  resetForm(clearData: boolean = false): void {
    this.form.reset(!clearData ? this.form.value : undefined);
    this.getForm().markAsPristine();
    this.setFormValidRowsUnchecked();
  }

  /**
   * SetFormValidRowsUnchecked
   */
  setFormValidRowsUnchecked(): void {
    this.getFormRowsChecked().forEach((item: FormGroup): void => {
      item.controls?.checkboxStatus.setValue(false);
    });
  }

  /**
   * GetFormValidRows
   * @return FormGroup[]
   */
  getFormValidRows(): FormGroup[] {
    return this.getFormArray().controls.filter((item: FormGroup) => {
      return (
        (item.controls.price.dirty || item.controls.price.touched) &&
        (item.controls.effectiveDate.dirty || item.controls.effectiveDate.touched) &&
        item.controls.price.valid &&
        item.controls.price.value &&
        item.controls.effectiveDate.valid &&
        item.controls.effectiveDate.value
      );
    });
  }

  /**
   * GetFormValidRowsChecked
   * @return FormGroup[]
   */
  getFormValidRowsChecked(): FormGroup[] {
    return this.getFormArray().controls.filter((item: FormGroup) => {
      return (
        item.controls.checkboxStatus.valid &&
        item.controls.checkboxStatus.value &&
        item.controls.price.valid &&
        item.controls.price.value &&
        item.controls.effectiveDate.valid &&
        item.controls.effectiveDate.value
      );
    });
  }

  /**
   * GetFormRowsChecked
   * @return FormGroup[]
   */
  getFormRowsChecked(): FormGroup[] {
    return this.getFormArray().controls.filter((item: FormGroup) => {
      return item.controls.checkboxStatus.valid && item.controls.checkboxStatus.value;
    });
  }

  /**
   * GetFormRowsCheckedAndExported
   * @return FormGroup[]
   */
  getFormRowsCheckedAndExported(): FormGroup[] {
    return this.getFormArray().controls.filter((item: FormGroup) => {
      return (
        item.controls.checkboxStatus.valid &&
        item.controls.checkboxStatus.value &&
        item.value.scenarioData?.priceEditorData?.governancePricePointStatus === PricePointApprovalStatus.exported
      );
    });
  }

  /**
   * GetPriceSubmissionList
   * @param isSave
   * @param isOverride
   * @return CreateUpdatePricePointInput
   */
  getPriceSubmissionList(isSave: boolean = false, isOverride: boolean = false): ISubmitPricePointsMutationVariables {
    this.createUpdatePricePoint = {
      isOverride: isOverride,
      pricePoints: [],
      scenarioId: this.contextService.scenarioId,
    };
    const formRows: FormGroup[] = isSave ? this.getFormValidRows() : this.getFormValidRowsChecked();

    formRows.forEach((item: FormGroup): void => {
      this.createUpdatePricePoint.pricePoints.push({
        comment: item.value.comment,
        currency: this.matrixViewDataFormattingService.getMatrixViewCurrency(),
        effectiveDate: dayjs(item.value.effectiveDate).format('YYYY-MM-DDThh:mm:ss.sssZ'),
        filterId: item.value.scenarioData?.id,
        id: item.value.scenarioData?.priceEditorData?.id,
        netPrice: item.value.scenarioData?.priceEditorData?.netPrice,
        price: item.value.price,
      });
    });

    return this.createUpdatePricePoint;
  }

  /**
   * GetFormValidRowsCarModels
   * isValid
   * @return { mini: GranularityDto[]; bmw: GranularityDto[] }
   */
  getFormValidRowsCarModels(isValid: boolean = true): { mini: IGranularityDto[]; bmw: IGranularityDto[] } {
    const rows: FormGroup[] = isValid ? this.getFormValidRowsChecked() : this.getFormRowsChecked();
    const bmw: IGranularityDto[] = rows
      .filter((item: FormGroup): boolean => item.value.scenarioData.granularity.brand === 'BMW')
      .map((item: FormGroup) => item.value.scenarioData.granularity as IGranularityDto);

    const mini: IGranularityDto[] = rows
      .filter((item: FormGroup): boolean => item.value.scenarioData.granularity.brand === 'MINI')
      .map((item: FormGroup) => item.value.scenarioData.granularity as IGranularityDto);

    return {
      bmw,
      mini,
    };
  }

  /*
   * GetPriceSubmissionList
   * @param isSave
   * @return CreateUpdatePricePointInput
   */
  getPriceSubmissionsSubmitModal(): {
    bmw: IPriceSubmissionSubmitModal[];
    mini: IPriceSubmissionSubmitModal[];
  } {
    const pricePoint: {
      bmw: IPriceSubmissionSubmitModal[];
      mini: IPriceSubmissionSubmitModal[];
    } = { bmw: [], mini: [] };
    this.getFormValidRowsChecked().forEach((item: FormGroup): void => {
      const princePointSubmit: IPriceSubmissionSubmitModal = {
        brand: item.value.scenarioData?.granularity.brand,
        currency: getCurrencySymbol(this.matrixViewDataFormattingService.getMatrixViewCurrency(), 'narrow'),
        effectiveDate: dayjs(item.value.effectiveDate).format('D MMM YYYY'),
        id: item.value.scenarioData?.priceEditorData?.id,
        listPriceExclTax: item.value.listPriceExclTax,
        listPriceInclTax: item.value.scenarioData?.priceEditorData?.listPriceInclTax || 0,
        model: item.value.scenarioData?.granularity.model,
        modelCode: item.value.scenarioData?.granularity.modelCode,
        netPrice: item.value.scenarioData?.priceEditorData?.netPrice,
        netPriceDifferenceStatus:
          item.value.scenarioData?.priceEditorData?.netPrice -
            (item.value.scenarioData?.priceEditorData?.listPriceExclTax || 0) >
          0
            ? 'increase'
            : 'decrease',
        netPricePointPercentage: item.value.netPricePointPercentage,
        price: item.value.price,
        priceDifferenceStatus:
          item.value.price - (item.value.scenarioData?.priceEditorData?.listPriceInclTax || 0) > 0
            ? 'increase'
            : 'decrease',
        pricePercentage: item.value.pricePointPercentage,
        status: item.value.scenarioData?.priceEditorData?.governancePricePointStatus,
      };
      if (item.value.scenarioData?.granularity.brand === 'BMW') {
        pricePoint.bmw.push(princePointSubmit);
      } else if (item.value.scenarioData?.granularity.brand === 'MINI') {
        pricePoint.mini.push(princePointSubmit);
      }
    });
    return pricePoint;
  }

  /**
   * GetFormTotalRowsCarModels
   * @return number
   */
  getFormTotalRowsCarModels(): number {
    const formValidRowsCarModels: { mini: IGranularityDto[]; bmw: IGranularityDto[] } =
      this.getFormValidRowsCarModels(false);

    return formValidRowsCarModels.bmw.length + formValidRowsCarModels.mini.length;
  }

  /**
   * IsFormRowsValidPriceMissingEffectiveDate
   * @return boolean
   */
  isFormRowsValidPriceMissingEffectiveDate(): boolean {
    return (
      this.getFormArray().controls.filter((item: FormGroup) => {
        return (
          (item.controls.price.dirty ||
            item.controls.price.touched ||
            item.controls.effectiveDate.dirty ||
            item.controls.effectiveDate.touched) &&
          item.controls.price.valid &&
          item.controls.price.value &&
          item.controls.effectiveDate.invalid &&
          !item.controls.effectiveDate.value
        );
      }).length > 0
    );
  }

  /**
   * IsFormRowsValidEffectiveDateMissingPrice
   * @return boolean
   */
  isFormRowsValidEffectiveDateMissingPrice(): boolean {
    return (
      this.getFormArray().controls.filter((item: FormGroup) => {
        return (
          (item.controls.effectiveDate.dirty || item.controls.effectiveDate.touched) &&
          item.controls.effectiveDate.valid &&
          item.controls.effectiveDate.value &&
          item.controls.price.invalid &&
          !item.controls.price.value
        );
      }).length > 0
    );
  }

  /*
   * Valid: This property returns true if the element’s contents are valid and false otherwise.
   * invalid: This property returns true if the element’s contents are invalid and false otherwise.
   * pristine: This property returns true if the element’s contents have not been changed.
   * dirty: This property returns true if the element’s contents have been changed.
   * untouched: This property returns true if the user has not visited the element.
   * touched: This property returns true if the user has visited the element.
   *
   * SetFormValidations
   * @param formGroup
   */
  setFormValidations(formGroup: FormGroup): void {
    this.getFormArray().controls.forEach((form: FormGroup) => {
      if (
        ((form.controls.price.dirty || form.controls.price.touched) && form.controls.price.value) ||
        ((form.controls.effectiveDate.dirty || form.controls.effectiveDate.touched) &&
          form.controls.effectiveDate.value &&
          !form.controls.price.hasValidator(Validators.required))
      ) {
        form.controls.price.setValidators(Validators.required);
        form.controls.price.markAsTouched();
        form.controls.price.markAsDirty();

        if (!form.controls.price.value) {
          form.controls.price.setErrors({ required: true });
        }

        form.controls.effectiveDate.setValidators(Validators.required);
        form.controls.effectiveDate.markAsTouched();
        form.controls.effectiveDate.markAsDirty();

        if (
          !form.controls.effectiveDate.value ||
          (form.controls.effectiveDate.value && !dayjs(form.controls.effectiveDate.value).isSameOrAfter(dayjs(), 'day'))
        ) {
          form.controls.effectiveDate.setErrors({ required: true });
        }
      } else if (
        form.controls.price.hasValidator(Validators.required) ||
        form.controls.effectiveDate.hasValidator(Validators.required)
      ) {
        form.controls.price.clearValidators();
        form.controls.price.markAsUntouched();
        form.controls.price.markAsPristine();
        form.controls.price.setErrors(null);

        form.controls.effectiveDate.clearValidators();
        form.controls.effectiveDate.markAsUntouched();
        form.controls.effectiveDate.markAsPristine();
        form.controls.effectiveDate.setErrors(null);
      }

      if (form.controls.checkboxStatus.value) {
        form.controls.price.markAsTouched();
        form.controls.price.markAsDirty();

        if (!form.controls.price.value) {
          form.controls.price.setValidators(Validators.required);
          form.controls.price.setErrors({ required: true });
        }

        form.controls.effectiveDate.markAsTouched();
        form.controls.effectiveDate.markAsDirty();

        if (
          !form.controls.effectiveDate.value ||
          (form.controls.effectiveDate.value && !dayjs(form.controls.effectiveDate.value).isSameOrAfter(dayjs(), 'day'))
        ) {
          form.controls.effectiveDate.setValidators(Validators.required);
          form.controls.effectiveDate.setErrors({ required: true });
        }
      }
    });

    this.emitFormValueChanged(formGroup);
  }

  /**
   * InitFormValueChangesListener
   * @private
   */
  private initFormValueChangesListener(): void {
    const subscription: Subscription = this.form.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((formGroup: FormGroup): void => {
        this.setFormValidations(formGroup);
      });
    this.subscriptions.push(subscription);
  }
}
