<div class="crosschecks-chart-wrapper">
  <div class="crosschecks-chart-filter-wrapper" *ngIf="filters && _context === CHART">
    <div class="crosscheck-header-wrapper">
      <div class="crosscheck-model-wrapper">
        <ng-container *ngIf="!alternativeModelHeaderText; else alternativeModelHeaderTextRef">
          <span class="crosscheck-model-header-text">{{ modelHeaderText }}</span>
          <span class="crosscheck-model-header-sub-text">{{ modelHeaderSubText }}</span>
          <ng-container *ngIf="filters.model !== 'ALL'">
            <span class="crosscheck-model-header-divider"></span>
            <span *ngIf="filters.modelCode !== 'ALL'" class="crosscheck-model-header-sub-text-model-code">
              {{ modelHeaderSubTextModelCode }}
            </span>
            <span class="crosscheck-model-header-sub-text-model">{{ modelHeaderSubTextModel }}</span>
          </ng-container>
        </ng-container>
        <ng-template #alternativeModelHeaderTextRef>
          <div class="crosscheck-model-header-text-alternative">
            {{ alternativeModelHeaderText }}
          </div>
        </ng-template>
      </div>
      <app-crosschecks-indication
        *ngIf="crosscheckIndicationVisible"
        [trafficLightStatusColor]="trafficLightStatus"
        [trafficLightAdjStatusColor]="trafficLightAdjStatus"
      ></app-crosschecks-indication>
    </div>
  </div>

  <div class="crosschecks-chart-wrapper" [hidden]="_context !== CHART">
    <ng-content></ng-content>
  </div>

  <app-ftd-skeleton [theme]="skeletonTheme" *ngIf="_context === LOADING"></app-ftd-skeleton>

  <div class="crosschecks-empty-wrapper" *ngIf="isChartEmpty">
    <img src="{{ imagePlaceholderPathToAssets }}" [alt]="context" />
    <span class="crosschecks-empty-text"> {{ message }} </span>
    <span class="crosschecks-empty-sub-text"> {{ subMessage }} </span>
  </div>
</div>
