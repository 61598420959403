import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bullet, LinearGradient, Scrollbar, color } from '@amcharts/amcharts5';
import { ChartAxisType, GenericChartValueFields } from '../../enums/ftd-generic-chart.enum';
import { ColumnSeries, IXYSeriesSettings } from '@amcharts/amcharts5/xy';
import { FtdAm5ChartsThemeHelper, FtdChartCustomThemes } from '../../themes/ftd-am5-charts.theme';
import { FtdBaseChart } from '../../ftd-base-chart.abstract';
import {
  GenericChartVerticalSpacer,
  IGenericChartData,
  IGenericChartHeadings,
} from '../../models/ftd-generic-chart.model';
import { XYChart } from '@amcharts/amcharts5/.internal/charts/xy/XYChart';

let _rootId: number = 0;

@Component({
  selector: 'app-ftd-bar-chart',
  templateUrl: './ftd-bar-chart.component.html',
})
export class FtdBarChartComponent extends FtdBaseChart implements AfterViewInit, OnInit {
  @Input() rootId!: string;
  @Input() budget?: number;
  @Input() stacked: boolean = true;
  @Input() clustered: boolean = true;
  @Input() showPercentage: boolean = true;
  @Input() verticalRangeValue?: number;

  @Input() set chartLegend(currentLegend: string[]) {
    this.configLegendOrientation(currentLegend);
  }

  @Input() set data(value: IGenericChartData) {
    if (value) {
      this._data = value;
      if (this.chart?.series) {
        this.setXAxis();
        this.setYAxis();
        if (this.isScrollBarVisible) {
          this.setScrollBar();
        }
        this.chart.series.clear();
        // Set Series
        this._data.headings.forEach((barChartHeadings: IGenericChartHeadings) => {
          this.setSeries(barChartHeadings.description, barChartHeadings.key);
        });
        this.setVerticalSeparators();
        this.setHorizontalBullets();
        if (this.isSysDateIndicatorVisible) {
          this.setSysDateIndicator();
        }
      }
    }
  }

  @Output() seriesOnClick: EventEmitter<any> = new EventEmitter();
  series!: ColumnSeries;

  ngOnInit() {
    _rootId += 1;
    this.rootId = `ftd-bar-chart-ref-${_rootId}`;
  }

  /**
   * NgAfterViewInit
   */
  ngAfterViewInit(): void {
    this.initChart();
  }

  /**
   * InitChart
   */
  initChart(): void {
    // Set Root (ftd-bar-chart-ref-${id}})
    this.setRoot();

    // Set Theme
    this.setTheme();

    // Set Chart
    this.setChart();

    // Create Y-axis
    this.setYAxis();

    // Create X-Axis
    this.setXAxis();

    // Set Label Description
    this.setAxisLabelDescription();

    // Set Series
    this._data.headings.forEach((barChartHeadings: IGenericChartHeadings) => {
      this.setSeries(barChartHeadings.description, barChartHeadings.key);
    });

    this.setHorizontalBullets();

    // Add Budget Indicator if enabled
    if (this.budget) {
      this.setBudgetIndicator(this.budget);
    }

    // Add Range Value Indicator if enabled
    if (this.verticalRangeValue) {
      this.setVerticalRange(this.verticalRangeValue);
    }

    // Add Cursor tooltip if enabled
    if (this.isCursorVisible) {
      this.setCursor();
    }

    // Add scrollbar
    if (this.isScrollBarVisible) {
      this.setScrollBar();
    }

    // Add SysDate Indicator if enabled
    if (this.isSysDateIndicatorVisible) {
      this.setSysDateIndicator();
    }

    // Add Vertical Separators
    this.setVerticalSeparators();
  }

  /**
   * SetRoot
   * @private
   */
  private setRoot(): void {
    this.root = this.am5.Root.new(this.rootId);
  }

  /**
   * SetTheme
   * @private
   */

  private setTheme(): void {
    this.chartCustomTheme
      ? this.root.setThemes([
          this.am5themes_Ftd.new(this.root),
          FtdChartCustomThemes[this.chartCustomTheme].new(this.root),
        ])
      : this.root.setThemes([this.am5themes_Ftd.new(this.root)]);
  }

  /**
   * SetChart
   * @private
   */
  private setChart(): void {
    this.chart = this.root.container.children.push(this.setChartOrientation());
  }

  /**
   * SetChartOrientation
   * @private
   */
  private setChartOrientation(): XYChart {
    return this.horizontalLayout
      ? this.am5xy.XYChart.new(this.root, {
          layout: this.root.verticalLayout,
        })
      : this.am5xy.XYChart.new(this.root, {
          layout: this.root.verticalLayout,
          maxTooltipDistance: 0,
        });
  }

  /**
   * SetYAxis
   * @protected
   */
  protected setYAxis(): void {
    super.setYAxis();
  }

  /**
   * SetXAxis
   * @protected
   */
  protected setXAxis(): void {
    super.setXAxis();
    this.xAxis.get('renderer').setAll({
      cellEndLocation: 0.8,
      cellStartLocation: 0.2,
    });
  }

  /**
   * SetAxisLabelDescription
   * @protected
   */

  protected setAxisLabelDescription() {
    super.setAxisLabelDescription();
  }

  /**
   * SetCursor
   * @private
   */
  private setCursor(): void {
    this.chart.set('cursor', this.am5xy.XYCursor.new(this.root, {}));
  }

  /**
   * CreateSeries
   * @param name
   * @param valueField
   * @private
   */

  private setSeriesRegardingLayout(name: string, valueField: string): ColumnSeries {
    return this.horizontalLayout
      ? this.am5xy.ColumnSeries.new(this.root, {
          baseAxis: this.yAxis,
          categoryYField: this.categoryField,
          layer: -1,
          name: name,
          stacked: true,
          valueXField: valueField,
          xAxis: this.xAxis,
          yAxis: this.yAxis,
        })
      : this.am5xy.ColumnSeries.new(this.root, {
          categoryXField: this.axisType === ChartAxisType.CATEGORY ? GenericChartValueFields.X : undefined,
          clustered: this.clustered,
          name: name,
          stacked: this.stacked,
          valueXField: GenericChartValueFields.X,
          valueYField: valueField,
          xAxis: this.xAxis,
          yAxis: this.yAxis,
        });
  }

  private setBullets(): Bullet | undefined {
    if (this.horizontalLayout && this.showPercentage) {
      const bullet = this.am5.Bullet.new(this.root, {
        sprite: this.am5.Label.new(this.root, {
          centerX: this.am5.p50,
          centerY: this.am5.p50,
          fill: this.root.interfaceColors.get('alternativeText'),
          populateText: true,
          text: "{valueXTotalPercent.formatNumber('#.')}" + '%',
          themeTags: ['ftd-bullet-label'],
        }),
      });
      bullet.set('id', `label_${bullet.uid}`);
      return bullet;
    } else {
      const bullet = this.am5.Bullet.new(this.root, {
        locationX: 0.5,
        locationY: 1,
        sprite: this.am5.Label.new(this.root, {
          centerX: 23,
          centerY: 30,
          populateText: true,
          text: '{valueY}',
          textAlign: 'center',
          textBaseline: 'bottom',
          themeTags: ['ftd-bullet-label'],
          visible: this.isLabelValuesVisible,
        }),
      });
      bullet.set('id', `label_${bullet.uid}`);
      return bullet;
    }
  }

  private setSeries(name: string, valueYField: string): void {
    this.series = this.chart.series.push(this.setSeriesRegardingLayout(name, valueYField));
    const defaultColumnWidth = this.am5.percent(80);
    this.series.columns.template.setAll({
      tooltip: FtdAm5ChartsThemeHelper.getTooltip(this.root, 'vertical', this.isTooltipVisible),
      tooltipHTML: FtdAm5ChartsThemeHelper.buildChartTooltipHTML([{ key: '{name}', value: '{valueY}' }]),
      tooltipY: 0,
      width: !this.stacked ? this.am5.percent(100) : defaultColumnWidth,
    });

    this.series.columns.template.events.on(
      'click',
      (event) => {
        this.seriesOnClick.emit(event.target.dataItem?.dataContext);
      },
      this
    );

    this.series.bullets.push(() => {
      return this.setBullets();
    });

    // Set Data
    this.series.data.setAll(this._data.fields);
  }

  private setHorizontalBullets() {
    if (!this.horizontalLayout) {
      return;
    }
    this.series.bullets.push(() => {
      return this.am5.Bullet.new(this.root, {
        locationX: 1,
        locationY: 0.5,
        sprite: this.am5.Label.new(this.root, {
          fill: this.root.interfaceColors.get('alternativeText'),
          paddingLeft: 60,
          populateText: true,
          text: '{valueXTotal}',
          themeTags: ['ftd-horizontal-total'],
        }),
      });
    });
  }

  /**
   * SetBudgetIndicator
   * @private
   */
  private setBudgetIndicator(budget: number) {
    const seriesRangeDataItem = this.yAxis.makeDataItem({
      value: budget,
    });
    this.yAxis.createAxisRange(seriesRangeDataItem);
    seriesRangeDataItem.get('grid').setAll({
      layer: 2,
      stroke: this.am5.color(0xfc7f0d),
      strokeOpacity: 1,
      visible: true,
    });
  }

  /**
   * SetScrollBar
   * @private
   */
  private setScrollBar() {
    const scrollBarX = Scrollbar.new(this.root, { orientation: 'horizontal' });
    scrollBarX.get('background')?.set('forceHidden', true);
    scrollBarX.thumb.setAll({
      fillGradient: LinearGradient.new(this.root, {
        rotation: 0,
        stops: [
          {
            color: color(0x565b76),
          },
          {
            color: color(0x7b8ec0),
          },
        ],
      }),
      height: 8,
    });

    this.chart.set('scrollbarX', scrollBarX);
    this.chart.bottomAxesContainer.children.push(scrollBarX);
  }

  /**
   * SetVerticalRange
   * @private
   */
  private setVerticalRange(range: number) {
    if (!this.horizontalLayout) {
      const seriesRangeDataItem = this.yAxis.makeDataItem({
        value: range,
      });
      this.yAxis.createAxisRange(seriesRangeDataItem);
      seriesRangeDataItem.get('grid').setAll({
        layer: 2,
        stroke: this.am5.color(0xfc7f0d),
        strokeOpacity: 1,
        visible: true,
      });
    } else {
      const seriesRangeDataItem = this.xAxis.makeDataItem({
        value: range,
      });
      this.xAxis.createAxisRange(seriesRangeDataItem);
      seriesRangeDataItem.get('grid').setAll({
        layer: 2,
        stroke: this.am5.color(0xffffff),
        strokeDasharray: [5, 5],
        strokeOpacity: 1,
        strokeWidth: 2,
        visible: true,
      });
    }
  }

  /**
   * SetVerticalSeparators
   * @private
   */
  private setVerticalSeparators() {
    // Add series range
    this._data.fields.forEach((data, index) => {
      if (data['verticalSpacer']) {
        // Create new series from spacer position
        const seriesRangeDataItem = this.xAxis.makeDataItem({ category: data.xAxisValue });
        this.xAxis.createAxisRange(seriesRangeDataItem);

        // Set both series labels to hidden
        seriesRangeDataItem.get('label').set('visible', false);
        this.xAxis.dataItems[index].set('label', this.am5.Label.new(this.root, { visible: false }));

        seriesRangeDataItem.get('grid').setAll({
          location: (data as GenericChartVerticalSpacer).location,
          stroke: (data as GenericChartVerticalSpacer).stroke,
          strokeDasharray: (data as GenericChartVerticalSpacer).strokeDasharray,
          strokeOpacity: (data as GenericChartVerticalSpacer).strokeOpacity,
          visible: (data as GenericChartVerticalSpacer).visible,
        });
      }
    });
  }

  /**
   * ConfigLegendOrientation
   * @private
   */

  private configLegendOrientation(currentLegend: string[]) {
    this.horizontalLayout
      ? this.matchValueFieldWithOrientation('valueXField', currentLegend)
      : this.matchValueFieldWithOrientation('valueYField', currentLegend);
  }

  /**
   * MatchValueFieldWithOrientation
   * @private
   */

  private matchValueFieldWithOrientation(fieldMatch: keyof IXYSeriesSettings, currentLegend: string[]) {
    this.chart?.series?.each((serie) => {
      currentLegend.includes(serie.get(fieldMatch)!) ? serie.show() : serie.hide();
    });
  }
}
