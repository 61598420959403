import { AuthService } from '../../../../../auth/services/auth.service';
import { BUTTON_SKELETON_THEME, DROPDOWN_SKELETON_THEME } from '../../constants/skeloton-themes';
import { Brand } from '../../models/brand.model';
import { CacheFiltersService } from 'src/app/common/services/cache-filters/cache-filters.service';
import {
  CachedFilters,
  FiltersDropdownHandler,
  FiltersForm,
  FiltersList,
  IGranularity,
  ISelectedFiltersForm,
  ISelectedFiltersFormConfirmed,
} from '../../models/app.model';
import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { ContextService } from '../../services/context/context.service';
import { DiscountMatrixViewFormService } from '../../services/matrix-view-form/matrix-view-form.service';
import { FilterDisplayName } from '../../enums/filter-enum';
import { FilterItemsResponse, SideFiltersService } from 'src/app/common/services/side-filter/side-filter.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GranularityService } from '../../services/granularity/granularity.service';
import { GranularityType } from '../../enums/granularity-type.enum';
import { IFilterIdsWithPriceFilled, IFilters } from '../../models/api.model';
import { IFtdDropdownOption } from 'src/app/common/models/ftd-dropdown-option.model';
import { ISkeletonTheme } from '../../models/skeloton-theme.model';
import { KeyValue } from '@angular/common';
import { MatrixLandingViewComponentDataContext } from '../matrix-landing-view/matrix-landing-view.component';
import { MessageService } from 'src/app/common/services/message/message.service';
import { PLANNING_HORIZON_OPTIONS } from '../../constants/select-filter-options';
import { PlanningHorizonValue } from '../../enums/planning-horizon-value.enum';
import { ScenarioMarketParameter } from '../../enums/scenario-market-parameter.enum';
import { Subscription } from 'rxjs';
import { User } from '../../../../../auth/models/user.model';
import flatten from 'lodash.flatten';
import sortBy from 'lodash.sortby';
import uniqBy from 'lodash.uniqby';

@Component({
  selector: 'app-discount-matrix-view-side-filters',
  styleUrls: ['./matrix-view-side-filters.component.scss'],
  templateUrl: './matrix-view-side-filters.component.html',
})
export class DiscountMatrixViewSideFiltersComponent implements OnInit, OnDestroy {
  dropdownSkeletonTheme: ISkeletonTheme = DROPDOWN_SKELETON_THEME;
  buttonSkeletonTheme: ISkeletonTheme = BUTTON_SKELETON_THEME;
  dropdownDefaultProps = { disabled: true, value: [] };
  filtersForm!: FormGroup;
  cachedFilters?: CachedFilters;
  confirmButtonDisabled: boolean = true;
  isMarketValueSet: boolean = false;
  filtersList: Map<string, FiltersList> = new Map();
  planningHorizonList: IFtdDropdownOption<PlanningHorizonValue>[] = PLANNING_HORIZON_OPTIONS;
  filtersResponse!: FilterItemsResponse;
  filtersData!: IFilters;
  editorFilledToggleToolTip: string = 'Select this option to only see the current proposed price changes';
  filterIdsWithPriceFilled: IFilterIdsWithPriceFilled[] = [];

  private subscriptions: Subscription[] = [];

  /**
   * Emit filters, market, planning-horizon, red-crosscheck, ..., based on selected options in the select-filters.
   */
  @Output() confirmButtonClicked: EventEmitter<ISelectedFiltersFormConfirmed> = new EventEmitter();

  currentUser!: User | null;
  isUserMarketLoaded: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private messageService: MessageService,
    private matrixViewFormService: DiscountMatrixViewFormService,
    private authService: AuthService,
    private filtersService: SideFiltersService,
    private granularityService: GranularityService,
    private contextService: ContextService,
    private cacheFiltersService: CacheFiltersService
  ) {
    const subscription: Subscription = this.authService
      .getLoggedInUserAsObservable()
      .subscribe((user: User | null) => (this.currentUser = user));
    this.subscriptions.push(subscription);

    this.cachedFilters = this.cacheFiltersService.getMatrixViewSideFilters();
  }

  ngOnInit(): void {
    this.initForm();
    this.handleFilterFormValuesChanges();
    this.transformOptionsForView(this.filtersService.getDefaultFilters());
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  /**
   * Ignore default angular key value pipe sorting on the filters map.
   * @returns
   * @param _a
   * @param _b
   */
  preserveOriginalKeyValueOrder = (_a: KeyValue<string, FiltersList>, _b: KeyValue<string, FiltersList>): number => {
    return 0;
  };

  /**
   * Price editor field toggle handler.
   *
   */
  handlePriceEditorFilledChanges() {
    const priceEditorFilled: boolean = this.filtersForm?.get('priceEditorFilled')?.value;
    const market: string = this.filtersForm.get('market')?.value as string;

    if (priceEditorFilled && market) {
      this.confirmButtonDisabled = true;

      this.filtersService
        .getFiltersWithPriceEditorFilled(market, this.contextService.discountId, this.getUserBrands())
        .subscribe({
          error: (error) => {
            this.messageService.showError(error.reason);
            this.confirmButtonDisabled = false;
          },
          next: (priceEditorFilledResponse) => {
            this.filterIdsWithPriceFilled = priceEditorFilledResponse;
            this.confirmButtonDisabled = false;
          },
        });
    }
    this.filterIdsWithPriceFilled = [];
  }

  get selectedBrands(): IGranularity[] {
    return this.filtersForm.controls.brand.value;
  }

  get selectedMarket(): IGranularity[] {
    return this.filtersForm.controls.market.value;
  }

  private get handleFormValueChanges(): FiltersDropdownHandler {
    return {
      market: (
        selectedMarket?: ScenarioMarketParameter,
        resetMatrixViewToDefaultState: boolean = true,
        initCachedFilters: boolean = false
      ): void => {
        this.isUserMarketLoaded = false;
        this.confirmButtonDisabled = true;
        this.isMarketValueSet = false;
        if (selectedMarket) {
          this.isMarketValueSet = true;

          this.resetFormAfterMarketChange(selectedMarket);
          this.filtersService.getFiltersForMarket(selectedMarket, this.getUserBrands()).subscribe({
            error: (error) => {
              this.messageService.showError(error.reason);
            },
            next: (data) => {
              this.filtersResponse = this.filtersService.baseFilterData;
              this.filtersData = data;

              this.transformOptionsForView(data);
              this.toggleFiltersViewState(true);

              if (resetMatrixViewToDefaultState) {
                this.resetMatrixViewToDefaultState();
              }
              if (initCachedFilters) {
                this.initMatrixViewWithCachedSideFilters();
              }

              this.confirmButtonDisabled = false;
              this.isUserMarketLoaded = true;
            },
          });

          this.handlePriceEditorFilledChanges();
        } else {
          this.toggleFiltersViewState(false);
        }
      },
    } as FiltersDropdownHandler;
  }

  private getMarketFromFilter(): ScenarioMarketParameter {
    const market: ScenarioMarketParameter = this.filtersForm?.get('market')?.value;
    return market || ScenarioMarketParameter.DE;
  }

  /**
   * Initialize angular form by setting up filter controls & default values.
   */
  private initForm(): void {
    this.filtersForm = this.formBuilder.group<FiltersForm>({
      brand: new FormControl<IGranularity[]>({ ...this.dropdownDefaultProps }),
      eSeries: new FormControl<IGranularity[] | null>({ ...this.dropdownDefaultProps }),
      market: new FormControl<string | null>('', Validators.required),
      model: new FormControl<IGranularity[] | null>({ ...this.dropdownDefaultProps }),
      planningHorizon: new FormControl<PlanningHorizonValue | null>({
        ...this.dropdownDefaultProps,
        disabled: true,
        value: PlanningHorizonValue.NEXT_MONTH,
      }),
      powertrain: new FormControl({ ...this.dropdownDefaultProps }),
      priceEditorFilled: new FormControl<boolean | null>(false),
      segment: new FormControl<IGranularity[] | null>({ ...this.dropdownDefaultProps }),
      series: new FormControl<IGranularity[] | null>({ ...this.dropdownDefaultProps }),

      /**
       * Red crosschecks
       * are temporary hidden (https://atc.bmwgroup.net/jira/browse/FSMPE-3135)
       * this.redCrosscheckList = RED_CROSSCHECKS_OPTIONS;
       */
    });

    if (this.cachedFilters?.market) {
      this.filtersForm.get('market')?.patchValue(this.cachedFilters.market);
      this.filtersForm.get('priceEditorFilled')?.patchValue(this.cachedFilters?.priceEditorFilled);
      this.handleFormValueChanges.market(this.cachedFilters.market as ScenarioMarketParameter, false, true);
    }
  }

  /**
   * Enable or disable filters based on filter selection.
   * @param enable Boolean, enable or disable filters.
   */
  private toggleFiltersViewState(enable: boolean): void {
    Object.keys(this.filtersForm.controls).forEach((controlKey: string) => {
      if (!['market', 'priceEditorFilled'].includes(controlKey)) {
        if (enable) {
          this.filtersForm.controls[controlKey].enable();
        } else {
          this.filtersForm.controls[controlKey].disable();
        }
      }
    });
  }

  /**
   * Bind filter value change handlers to form controls.
   */
  private handleFilterFormValuesChanges(): void {
    Object.keys(this.handleFormValueChanges).forEach((filterKey: string) => {
      const dropdownValueChangeSubscription = this.filtersForm.controls[filterKey].valueChanges.subscribe(
        (selectedValue: IGranularity[] & ScenarioMarketParameter) => {
          this.handleFormValueChanges[filterKey as keyof FiltersDropdownHandler](selectedValue);
        }
      );

      this.subscriptions.push(dropdownValueChangeSubscription);
    });
  }

  /**
   * Transform data structure for UI for dropdown rendering.
   */
  private transformOptionsForView(filterData: IFilters) {
    this.filtersList = this.filtersService.transformOptionsForView(filterData, this.getUserMarkets());
  }

  /**
   * Apply filter handler.
   */
  confirmSelectedFilters(): void {
    const market: ScenarioMarketParameter = this.getMarketFromFilter();

    this.currentUser?.permissions.setSelectedPermissions(market, this.getUserBrands() as Brand[]);

    if (this.currentUser) {
      this.authService.updateLoggedInUserAndNotifyObservers(this.currentUser);
    }

    const selectedMultiFilters = [];
    for (const controlKey in this.filtersForm.controls) {
      if (
        Array.isArray(this.filtersForm.controls[controlKey].value) &&
        this.filtersForm.controls[controlKey].value.length
      ) {
        selectedMultiFilters.push(
          this.filtersForm.controls[controlKey] &&
            (this.filtersForm.controls[controlKey].value as unknown as IGranularity)
        );
      }
    }
    // Select all brand options if market is selected but brand not
    if (this.selectedMarket && !this.filtersForm.controls.brand.value.length) {
      this.filtersForm.controls.brand.patchValue(
        this.filtersList.get('brand')!.options!.map((obj: IFtdDropdownOption<string>) => obj.value)
      );
    }
    const formValue: ISelectedFiltersForm = this.filtersService.ifFiltersConditions(
      this.filtersForm.value,
      this.filterIdsWithPriceFilled
    );

    const selectedFilterList = flatten([
      ...formValue.brand,
      ...formValue.series,
      ...formValue.eSeries,
      ...formValue.model,
    ]);

    // Recursively add missing parent-granularities to selected filters
    const bmwFiltersSet: IGranularity[] = [];
    const miniFiltersSet: IGranularity[] = [];
    const addMissingParentOfGranularity = (granularity: IGranularity, brand: Brand) => {
      const parentToAdd = this.granularityService.getGranularityParents(granularity);

      if (parentToAdd) {
        brand === Brand.BMW ? bmwFiltersSet.push(parentToAdd) : miniFiltersSet.push(parentToAdd);
        addMissingParentOfGranularity(parentToAdd, brand);
      }
    };

    let bmwDataset: IGranularity[] = [];
    let miniDataset: IGranularity[] = [];

    if (selectedFilterList.length) {
      bmwDataset = selectedFilterList.filter((granularity: IGranularity): boolean => granularity.brand === Brand.BMW);
      miniDataset = selectedFilterList.filter((granularity: IGranularity): boolean => granularity.brand === Brand.MINI);
    }

    if (bmwDataset.length) {
      bmwDataset
        .filter((item: IGranularity): boolean => item.type !== GranularityType.BRAND)
        .forEach((item: IGranularity) => addMissingParentOfGranularity(item, Brand.BMW));
    }

    if (miniDataset.length) {
      miniDataset
        .filter((item: IGranularity): boolean => item.type !== GranularityType.BRAND)
        .forEach((item: IGranularity) => addMissingParentOfGranularity(item, Brand.MINI));
    }

    const _selectedFilterList: IGranularity[] = [
      ...this.returnFilteredGranularitiesOrdered(bmwFiltersSet, bmwDataset),
      ...this.returnFilteredGranularitiesOrdered(miniFiltersSet, miniDataset),
    ];

    const selectedValue: ISelectedFiltersFormConfirmed = {
      ...formValue,
      dataContext: this.getDataContext(_selectedFilterList),
      filterIdsWithPriceFilled: this.filterIdsWithPriceFilled,
      selectedFilterList: _selectedFilterList,
      shouldResetMatrixViewToDefaultState: false,
    };

    this.cacheFiltersService.setMatrixViewSideFilters(this.filtersForm.value);
    this.cachedFilters = this.cacheFiltersService.getMatrixViewSideFilters();
    this.confirmButtonClicked.emit(selectedValue);
  }

  /**
   * Get markets user has permissions to.
   * @returns List of markets.
   */
  private getUserMarkets(): IFtdDropdownOption<string>[] {
    return this.currentUser?.permissions.getMarkets() as IFtdDropdownOption<string>[];
  }

  private getUserBrands(): string[] {
    return this.currentUser?.permissions.getBrandsFromMarket(this.getMarketFromFilter()) as string[];
  }

  /**
   * Get filter display names from enum.
   * @param key Enum key.
   * @returns Enum value.
   */
  private getFilterDisplayName(key: string): FilterDisplayName {
    return FilterDisplayName[key as keyof typeof FilterDisplayName];
  }

  /**
   * Track by angular function to uniquely identify HTMl elements in a loop.
   * @param _index item index in an Array
   * @param option Dropdown option
   * @returns unique identifier
   */
  optionTrackBy(_index: number, option: KeyValue<string, FiltersList>): string {
    return option.key;
  }

  /**
   * Resets form after changing market
   * @param market set the current market
   */
  private resetFormAfterMarketChange(market: ScenarioMarketParameter) {
    this.matrixViewFormService.resetForm();
    this.filtersForm.reset(
      {
        brand: [],
        eSeries: [],
        market: market,
        model: [],
        modelCode: [],
        planningHorizon: PlanningHorizonValue.NEXT_MONTH,
        powertrain: [],
        priceEditorFilled: this.filtersForm?.get('priceEditorFilled')?.value,
        segment: [],
        series: [],
      },
      { emitEvent: false }
    );
  }

  /**
   * Reset filter & matrix view state.
   * @param priceEditorFilled
   */
  resetSelectFilters(priceEditorFilled = false): void {
    this.matrixViewFormService.resetForm();
    this.filtersForm.reset({
      brand: [],
      eSeries: [],
      market: null,
      model: [],
      modelCode: [],
      planningHorizon: PlanningHorizonValue.NEXT_MONTH,
      powertrain: [],
      priceEditorFilled: priceEditorFilled,
      segment: [],
      series: [],
      /**
       * Planning horizon, Red crosschecks and Price editor filled
       * are temporary hidden (https://atc.bmwgroup.net/jira/browse/FSMPE-3135)
       * redCrosschecks: null,
       */
    } as ISelectedFiltersForm);

    this.resetMatrixViewToDefaultState();
    this.currentUser?.permissions.setSelectedPermissions(null, []);
    this.cacheFiltersService.clearMatrixViewSideFilters();
    this.isUserMarketLoaded = true;
  }

  /**
   * Matrix view reset event emit.
   */
  private resetMatrixViewToDefaultState() {
    this.confirmButtonClicked.emit({
      ...this.filtersForm.value,
      dataContext: MatrixLandingViewComponentDataContext.APPLY_FILTERS,
      filterIdsWithPriceFilled: [],
      selectedFilterList: [],
      shouldResetMatrixViewToDefaultState: true,
    });
  }

  private initMatrixViewWithCachedSideFilters(): void {
    Object.keys(this.filtersForm.controls).forEach((controlKey: string) => {
      if (controlKey !== 'market') {
        this.filtersForm.get(controlKey)?.patchValue(this.cachedFilters![controlKey as keyof CachedFilters]);
      }
    });

    this.confirmSelectedFilters();
  }

  private getDataContext(selectedFilterList: IGranularity[]): MatrixLandingViewComponentDataContext {
    return selectedFilterList.length
      ? MatrixLandingViewComponentDataContext.MATRIX_VIEW_DATA
      : MatrixLandingViewComponentDataContext.NO_RESULTS;
  }

  /**
   * Private method that takes the dataset with the missing parents check combined original dataset sent by the filters
   * and returns an ordered list of granularities for that brand for the matrix view table tree.
   * @param filtersSet
   * @param dataset
   * @returns Granularity[]
   * @private
   */
  private returnFilteredGranularitiesOrdered(filtersSet: IGranularity[], dataset: IGranularity[]): IGranularity[] {
    const fullDataset: IGranularity[] = [...filtersSet, ...dataset];

    return uniqBy(
      [
        ...fullDataset.filter((item: IGranularity): boolean => item.type === GranularityType.BRAND),
        ...sortBy(
          fullDataset.filter((item: IGranularity): boolean => item.type !== GranularityType.BRAND),
          ['series', 'segment', 'eSeries'],
          ['asc', 'asc', 'asc']
        ),
      ],
      'id'
    );
  }
}
