import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
} from '@angular/core';
import { FtdTabComponent } from '../ftd-tab/ftd-tab.component';
import { MatTab, MatTabGroup } from '@angular/material/tabs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ftd-tab-group',
  templateUrl: './ftd-tab-group.component.html',
})
export class FtdTabGroupComponent implements AfterViewInit {
  @Input() stretchTabs: boolean = true;
  @Input() dynamicHeight: boolean = false;
  @Input() animation: boolean = true;
  @Input() selectedIndex: number = 0;
  @ViewChild(MatTabGroup)
  tabGroup!: MatTabGroup;

  @Output() tabIndexChange: EventEmitter<number> = new EventEmitter();

  @ContentChildren(FtdTabComponent) protected tabs!: QueryList<FtdTabComponent>;

  constructor(private readonly cdr: ChangeDetectorRef, private readonly element: ElementRef) {
    this.tabGroupInit();
  }

  ngAfterViewInit() {
    this.setupTabs();
    this.cdr.detectChanges();
  }

  private setupTabs() {
    const matTabsFromQueryList = this.tabs.map((tab) => tab.matTab);
    const list = new QueryList<MatTab>();
    list.reset([matTabsFromQueryList]);
    this.tabGroup._allTabs = list;
    this.tabGroup.stretchTabs = this.stretchTabs;
    this.tabGroup.selectedIndex = this.selectedIndex;
    this.checkAnimation();
    this.tabGroup.ngAfterContentInit();
  }

  private tabGroupInit() {
    this.element.nativeElement.classList.add('ftd-tab');
    this.element.nativeElement.classList.add('panel-tabs');
  }

  private checkAnimation() {
    if (!this.animation) {
      this.tabGroup.animationDuration = 0;
    }
  }

  onChangeTab(changeEvent: number) {
    this.tabIndexChange.emit(changeEvent);
  }
}
