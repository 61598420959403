<app-crosschecks-chart-wrapper
  [context]="crosschecksChartContext"
  [skeletonTheme]="'LeaseReturns'"
  [filters]="filters"
  [crosscheckIndicationVisible]="!!crosscheckIndication"
  [trafficLightStatus]="crosscheckIndication!"
  [trafficLightAdjStatus]="crosscheckIndicationForecasted!"
>
  <app-chart-filters
    (showValuesToggleEvent)="showValuesToggle($event)"
    [dropdownDisabled]="dropdownDisabled"
    [dropdownList]="dropdownList"
    [dropdownOptions]="dropdownOptions"
  ></app-chart-filters>

  <div id="lease-returns-chart-container">
    <app-ftd-line-and-bar-chart
      *ngIf="initChart"
      [data]="LEASE_RETURNS_CHART_DATA_MOCK"
      [rootId]="'ftd-bar-chart-ref-lease-returns'"
      [isLabelValuesVisible]="isChartValuesVisible"
      [chartCustomTheme]="'LeaseReturnsCustomTheme'"
      [barChartKeys]="['totalLeaseReturns', 'expectedTotalLeaseReturns']"
      [linesChartKeys]="[
        'averageLeaseRateReturn',
        'currentLeaseRate',
        'expectedLeaseRate',
        'forecastAverageLeaseRateReturn',
        'returningCustomersCompared'
      ]"
      [primaryUnitLabel]="'Units'"
      [secondaryUnitLabel]="'Lease Rates'"
      [clustered]="false"
      [renderXAxisTop]="true"
      [renderYAxisRight]="true"
      [chartLegend]="chartLegend"
    ></app-ftd-line-and-bar-chart>
  </div>

  <form [formGroup]="chartLegendForm">
    <app-crosschecks-chart-filter-group formControlName="leaseReturnsFields">
      <app-chart-filter-item
        *ngFor="let legendConfig of chartLegendsConfigs"
        [value]="legendConfig.fieldName"
        [label]="legendConfig.label"
        [legendColor]="legendConfig.legendColor"
        [legendImagePath]="legendConfig.imagePath"
        [legendImageHeight]="legendConfig.legendImageHeight"
        [legendImageWidth]="legendConfig.legendImageWidth"
        [legendType]="legendConfig.type"
      ></app-chart-filter-item>
    </app-crosschecks-chart-filter-group>
  </form>
</app-crosschecks-chart-wrapper>
