import { ActivatedRouteSnapshot, Event, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { IBreadcrumb } from '../../models/breadcrumb.model';
import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private readonly _breadcrumbs$ = new BehaviorSubject<IBreadcrumb[]>([]);
  readonly breadcrumbs$ = this._breadcrumbs$.asObservable();

  constructor(private router: Router) {
    this.breadcrumbsInit(router.routerState.snapshot.root);

    router.events.pipe(filter((event: Event) => event instanceof NavigationEnd)).subscribe(() => {
      this.breadcrumbsInit(router.routerState.snapshot.root);
    });
  }

  private breadcrumbsInit(root: ActivatedRouteSnapshot) {
    const breadcrumbs: IBreadcrumb[] = [];

    breadcrumbs.push({ label: 'Main Scenario', url: '/main-scenario' });

    if (Object.entries(root.queryParams).length && root.queryParams.scenario !== 'mainScenario') {
      breadcrumbs.push({ label: 'User Scenario', url: '/user-scenario/' + root.queryParams.scenarioId });
    }

    this.addBreadcrumb(root, [], breadcrumbs);

    this._breadcrumbs$.next(breadcrumbs);
  }

  private addBreadcrumb(route: ActivatedRouteSnapshot, parentUrl: string[], breadcrumbs: IBreadcrumb[]) {
    if (route) {
      const routeUrl = parentUrl.concat(route.url.map((url) => url.path));
      if (route.data.breadcrumb) {
        const breadcrumb = {
          label: route.data.breadcrumb,
          url: '/' + routeUrl.join('/'),
        };
        breadcrumbs.push(breadcrumb);
      }

      this.addBreadcrumb(<ActivatedRouteSnapshot>route.firstChild, routeUrl, breadcrumbs);
    }
  }
}
