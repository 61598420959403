import { APP_FSM_RELATED_EXTERNAL_LINKS } from '../../../common/constants/app-fsm-related-external-links';
import { ApprovalModalType } from '../../enums/approval-status.enum';
import { ApproveScenarioModalComponent } from '../../../scenario/modals/approve-scenario-modal/approve-scenario-modal.component';
import { AuthService } from '../../../auth/services/auth.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ContextService } from '../../services/context/context.service';
import { ExportExcelService } from '../../../common/services/export-excel/export-excel.service';
import { FtdModalSize } from '../../../common/components/ftd-modal/ftd-modal-size.enum';
import {
  FtdProgressBarModalComponent,
  IProgressBarModalData,
} from '../../../common/components/ftd-progress-bar-modal/ftd-progress-bar-modal.component';
import { GovernanceService } from '../../services/governance/governance.service';
import { ICreateUpdatePricePointMutationVariables } from '../../../graphql/services/gql-api.service';
import { IExcelExportData } from '../../models/excel-export-data.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatrixDataExcelExportService } from '../../services/matrix-data-excel-export/matrix-data-excel-export.service';
import { MatrixViewColumnsOrderingModalComponent } from '../modals/matrix-view-columns-ordering-modal/matrix-view-columns-ordering-modal.component';
import { MatrixViewDataFormattingService } from '../../services/matrix-view-data-formatting/matrix-view-data-formatting.service';
import { MatrixViewDataService } from '../../services/matrix-view-data/matrix-view-data.service';
import { MatrixViewFormService } from '../../services/matrix-view-form/matrix-view-form.service';
import { MessageService } from 'src/app/common/services/message/message.service';
import { ScenarioContext } from '../../../common/enums/scenario-context.enum';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';
import { getCurrencySymbol } from '@angular/common';
import { showElementByPermission } from '../../helpers/show-element-by-permission.helper';
import dayjs from 'dayjs';

export enum SUBMIT_SCENARIO_MESSAGES {
  INCLUDE_DATE = 'You need to include Effective Date to submit the scenario',
  INCLUDE_PRICE = 'You need to include Price to submit the scenario',
  INCLUDE_PRICE_AND_DATE = 'You need to include an Price and an Effective Date to submit the scenario',
}

export enum SAVE_SCENARIO_MESSAGES {
  INCLUDE_DATE = 'You need to include Effective Date to save the scenario',
  INCLUDE_PRICE = 'You need to include Price to save the scenario',
  INCLUDE_PRICE_AND_DATE = 'You need to include an Price and an Effective Date to save the scenario',
}

export enum SAVE_BUTTON_TOOLTIP {
  ENABLED = 'Pressing this button will save and simulate the prices',
  DISABLED = "You don't have any changes to save",
}

@Component({
  selector: 'app-matrix-view-table-header',
  styleUrls: ['./matrix-view-table-header.component.scss'],
  templateUrl: './matrix-view-table-header.component.html',
})
/**
 * @class
 * MatrixViewTableHeaderComponent
 */
export class MatrixViewTableHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string = 'Scenario';
  @Input() description: string = '';
  @Input() isTitleHidden: boolean = false;
  @Input() isDescriptionHidden: boolean = false;
  @Input() isSaveButtonHidden: boolean = false;
  @Input() isExportButtonHidden: boolean = false;
  @Input() isSubmitScenarioButtonHidden: boolean = false;
  @Input() isSubmitScenarioButtonDisabled: boolean = true;
  @Input() isApproveScenarioButtonHidden: boolean = false;
  @Input() isApproveScenarioButtonDisabled: boolean = false;
  @Input() isUnlockScenarioButtonHidden: boolean = false;
  @Input() isUnlockScenarioButtonDisabled: boolean = true;
  @Input() isActionButtonsDividerHidden: boolean = false;
  @Input() isDividerHidden: boolean = false;
  @Input() isSaveDisabled: boolean = true;

  @Output() toggleFullscreen: EventEmitter<any> = new EventEmitter<any>();

  public currentUser?: User | null;
  public context: ScenarioContext = ScenarioContext.mainScenario;
  public loading: boolean = false;
  releaseModal = {
    description: 'You are giving the final approve of the prices. After this operation, the prices will be released.',
    label: 'Release',
    title: 'Release Prices',
    type: ApprovalModalType.RELEASE,
  };

  approveModal = {
    description: 'You are approving the price points.',
    label: 'Approve',
    title: 'Approve Prices',
    type: ApprovalModalType.APPROVE,
  };

  APP_FSM_RELATED_EXTERNAL_LINKS = APP_FSM_RELATED_EXTERNAL_LINKS;

  get saveToolTip(): string {
    return this.isSaveDisabled ? SAVE_BUTTON_TOOLTIP.DISABLED : SAVE_BUTTON_TOOLTIP.ENABLED;
  }

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param dialog
   * @param matrixViewFormService
   * @param matrixViewDataFormattingService
   * @param exportExcelService
   * @param matrixDataExcelExportService
   * @param matrixViewDataService
   * @param messageService
   * @param governanceService
   * @param authService
   * @param contextService
   */
  constructor(
    public dialog: MatDialog,
    private matrixViewFormService: MatrixViewFormService,
    private matrixViewDataFormattingService: MatrixViewDataFormattingService,
    private exportExcelService: ExportExcelService,
    private matrixDataExcelExportService: MatrixDataExcelExportService,
    private matrixViewDataService: MatrixViewDataService,
    private messageService: MessageService,
    private governanceService: GovernanceService,
    private authService: AuthService,
    private contextService: ContextService
  ) {
    this.context = this.contextService.getCurrentScenarioContext();
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initUserListener();
    this.matrixViewFormService?.resetForm();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * InitUserListener
   */
  initUserListener(): void {
    const subscription: Subscription = this.authService
      .getLoggedInUserAsObservable()
      .subscribe((user: User | null) => (this.currentUser = user));
    this.subscriptions.push(subscription);
  }

  /**
   * OpenApproveScenarioModal
   */
  openApproveScenarioModal(modalText: any): void {
    const dialogRef: MatDialogRef<ApproveScenarioModalComponent> = this.dialog.open(ApproveScenarioModalComponent, {
      data: modalText,
      maxWidth: FtdModalSize.S,
    });

    const subscription: Subscription = dialogRef.afterClosed().subscribe((): void => {});
    this.subscriptions.push(subscription);
  }

  /**
   * SubmitScenarioDisableStateMessage
   * @return string
   */
  submitScenarioDisableStateMessage(): string {
    switch (true) {
      case !(this.matrixViewFormService.getForm().touched || this.matrixViewFormService.getForm().dirty):
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_PRICE_AND_DATE;
      case this.matrixViewFormService.getForm().invalid &&
        this.matrixViewFormService.isFormRowsValidPriceMissingEffectiveDate():
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_DATE;
      case this.matrixViewFormService.getForm().invalid &&
        this.matrixViewFormService.isFormRowsValidEffectiveDateMissingPrice():
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_PRICE;
      case this.isSubmitScenarioButtonDisabled:
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_PRICE_AND_DATE;
      default:
        return '';
    }
  }

  /**
   * OpenProgressBarModal
   * @param asyncCallback
   * @return MatDialogRef<FtdProgressBarModalComponent>
   */
  openProgressBarModal(asyncCallback?: () => Promise<void>): MatDialogRef<FtdProgressBarModalComponent> {
    return this.dialog.open(FtdProgressBarModalComponent, {
      data: {
        asyncCallback,
        colorLeftMatIcon: 'white',
        description: 'The export of this file will take a few seconds',
        title: 'Export Excel',
        titleLeftMatIcon: 'download',
      } as IProgressBarModalData,
      maxWidth: FtdModalSize.S,
      width: '90%',
    });
  }

  /**
   * OpenProgressBarModalAndDownloadExcelFileBasedOnSelectedFilters
   */
  openProgressBarModalAndDownloadExcelFileBasedOnSelectedFilters(): void {
    this.openProgressBarModal(() => this.downloadExcelFileBasedOnSelectedFilters());
  }

  /**
   * OnClickFullscreenBtn
   */
  onClickFullscreenBtn($event: MouseEvent): void {
    this.toggleFullscreen.emit($event);
  }

  /**
   * DownloadExcelFileBasedOnSelectedFilters
   * @private
   * @async
   */
  private async downloadExcelFileBasedOnSelectedFilters(): Promise<void> {
    try {
      const qxNumber = this.currentUser?.sub ? this.currentUser.sub : this.currentUser?.name;
      const excelExportData: IExcelExportData = await this.matrixDataExcelExportService
        .buildExcelExportDataBasedOnFilters(
          this.context,
          this.contextService.scenarioId,
          `My Pricing View ${qxNumber?.toUpperCase()}`,
          this.matrixViewDataService.getCurrentSelectedFilters()
        )
        .toPromise();

      await this.downloadExcelFile(excelExportData);
    } catch (e) {
      this.messageService.showError('An error occurred while preprocessing the data for the Excel download', 4);
    }
  }

  /**
   * DownloadExcelFile
   * @param excelExportData
   * @private
   * @return Promise<void>
   */
  private async downloadExcelFile(excelExportData: IExcelExportData): Promise<void> {
    const date: Date = new Date();
    const marketCurrency: string = getCurrencySymbol(
      this.matrixViewDataFormattingService.getMatrixViewCurrency(),
      'narrow'
    );
    try {
      return await this.exportExcelService.exportExcel({
        currency: marketCurrency,
        data: excelExportData,
        title: dayjs(date).format('YYYYMMDD') + '_NCPE_Excel_download',
      });
    } catch (error) {
      return this.messageService.showError(error as string);
    }
  }

  /**
   * OpenMatrixViewColumnsOrderingModal
   */
  openMatrixViewColumnsOrderingModal(): void {
    const dialogRef: MatDialogRef<MatrixViewColumnsOrderingModalComponent> = this.dialog.open(
      MatrixViewColumnsOrderingModalComponent,
      {
        maxWidth: FtdModalSize.M,
      }
    );

    const subscription: Subscription = dialogRef.afterClosed().subscribe((): void => {});
    this.subscriptions.push(subscription);
  }

  saveScenarioStateMessage(): string {
    switch (true) {
      case !(this.matrixViewFormService.getForm().touched || this.matrixViewFormService.getForm().dirty):
        return SAVE_SCENARIO_MESSAGES.INCLUDE_PRICE_AND_DATE;
      case this.matrixViewFormService.getForm().invalid &&
        this.matrixViewFormService.isFormRowsValidPriceMissingEffectiveDate():
        return SAVE_SCENARIO_MESSAGES.INCLUDE_DATE;
      case this.matrixViewFormService.getForm().invalid &&
        this.matrixViewFormService.isFormRowsValidEffectiveDateMissingPrice():
        return SAVE_SCENARIO_MESSAGES.INCLUDE_PRICE;
      default:
        return '';
    }
  }

  /**
   * SaveScenario
   */
  saveScenario(): void {
    const isPermitToSave: string = this.saveScenarioStateMessage();
    this.loading = true;
    this.isSaveDisabled = true;
    if (isPermitToSave !== '') {
      this.messageService.showError(isPermitToSave);
      this.isSaveDisabled = false;
      return;
    }
    const priceSubmissionList: ICreateUpdatePricePointMutationVariables =
      this.matrixViewFormService.getPriceSubmissionList(true);

    const subscription: Subscription = this.governanceService.createUpdatePricePoint(priceSubmissionList).subscribe({
      error: (): void => {
        this.loading = false;
        this.onSaveScenarioError();
      },
      next: (): void => {
        this.matrixViewDataService.notifyMatrixViewToReflectPriceChanges();
        this.onSaveScenarioSuccess();
        this.loading = false;
        this.matrixViewFormService.resetForm();
      },
    });
    this.subscriptions.push(subscription);
  }

  /**
   * OnSaveScenarioSuccess
   */
  private onSaveScenarioSuccess(): void {
    this.messageService.showSuccess(`Scenario Saved`);
  }

  /**
   * OnSaveScenarioError
   */
  private onSaveScenarioError(): void {
    this.messageService.showError(`Error in Scenario Save`);
  }

  /**
   * ShowDivider
   * @return boolean
   */
  get showDivider(): boolean {
    return Boolean(
      this.context === ScenarioContext.mainScenario &&
        this.currentUser?.permissions?.getHasPermissionToApproveMainScenario() &&
        this.currentUser?.permissions?.getHasPermissionToDownloadScenario()
    );
  }

  /**
   * GetShowHorizontalDivider
   * @return boolean
   */
  getShowHorizontalDivider(): boolean {
    return showElementByPermission(this.context, this.currentUser);
  }
}
