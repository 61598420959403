export enum ChartAxisType {
  DATE = 'DATE',
  CATEGORY = 'CATEGORY',
  VALUE = 'VALUE',
}

export enum ChartAxisLayout {
  VERTICAL = 'VERTICAL',
  HORIZONTAL = 'HORIZONTAL',
}

export enum GenericChartValueFields {
  X = 'xAxisValue',
  Y = 'yAxisValue',
}
