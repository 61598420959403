import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FtdCurrencyPipe } from '../../../common/pipes/ftd-currency/ftd-currency.pipe';
import { FtdNotifierService } from '../../../common/services/ftd-notifier/ftd-notifier.service';
import { GovernanceService } from '../../../matrix-view/services/governance/governance.service';
import {
  IPricePointOperationError,
  ISubmitPricePointsMutationVariables,
  ISubmitPricePointsResponse,
} from '../../../graphql/services/gql-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatrixViewDataService } from '../../../matrix-view/services/matrix-view-data/matrix-view-data.service';
import { MatrixViewFormService } from '../../../matrix-view/services/matrix-view-form/matrix-view-form.service';
import { PricePointApprovalStatus } from '../../../matrix-view/enums/price-point-approval-status.enum';

import {
  IPriceSubmissionSubmitModal,
  IPriceSubmissionSubmitModalBrands,
} from '../../../matrix-view/models/price-submission.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-submit-scenario-modal',
  styleUrls: ['./submit-scenario-modal.component.scss'],
  templateUrl: './submit-scenario-modal.component.html',
})
export class SubmitScenarioModalComponent implements OnInit, OnDestroy {
  displayedColumns: string[] = ['model', 'currentPrice', 'newPriceAndDate', 'priceDifference'];
  currency: string = 'euros';
  dataSource: {
    bmw: IPriceSubmissionSubmitModal[];
    mini: IPriceSubmissionSubmitModal[];
  } = { bmw: [], mini: [] };

  isAllPricePointsSubmissionPossible: boolean = true;
  context: 'override' | 'submit' = 'submit';

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  private _title: string = 'Submit Scenario';
  public isSubmitButtonDisabled: boolean = false;
  public isRequestProcessing: boolean = false;

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param notifierService
   * @param dialogRef
   * @param matrixViewFormService
   * @param governanceService
   * @param matrixViewDataService
   * @param ftdCurrencyPipe
   * @param data
   */
  constructor(
    private notifierService: FtdNotifierService,
    private dialogRef: MatDialogRef<SubmitScenarioModalComponent>,
    private matrixViewFormService: MatrixViewFormService,
    private governanceService: GovernanceService,
    private matrixViewDataService: MatrixViewDataService,
    private ftdCurrencyPipe: FtdCurrencyPipe,
    @Inject(MAT_DIALOG_DATA) public data: IPriceSubmissionSubmitModalBrands
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.setDataSource();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * SetCarModels
   * @private
   */
  private setDataSource(): void {
    this.dataSource = this.data.data;
    this.context = this.data.context;
    if (this.context === 'submit') {
      this.setIsAllPricePointsSubmissionPossible();
    }
  }

  /**
   * SubmitNewScenario
   * @param priceSubmissionList
   */
  submitNewScenario(
    priceSubmissionList: ISubmitPricePointsMutationVariables = this.matrixViewFormService.getPriceSubmissionList(
      false,
      this.context === 'override'
    )
  ): void {
    this.isSubmitButtonDisabled = true;
    this.isRequestProcessing = true;
    const subscription: Subscription = this.governanceService.submitPricePoints(priceSubmissionList).subscribe({
      error: (): void => {
        this.isSubmitButtonDisabled = false;
        this.isRequestProcessing = false;
        this.notifierService.showError(`Error in Scenario Submission`);
      },
      next: (data: ISubmitPricePointsResponse): void => {
        this.isSubmitButtonDisabled = false;
        this.isRequestProcessing = false;

        if (data.errors?.length) {
          this.onSubmitScenarioError(data.errors);
        } else {
          this.onSubmitScenarioSuccess(priceSubmissionList);
        }
      },
    });
    this.subscriptions.push(subscription);
  }

  /**
   * OnSubmitScenarioSuccess
   * @priceSubmissionList
   */
  onSubmitScenarioSuccess(priceSubmissionList: ISubmitPricePointsMutationVariables): void {
    this.dialogRef.close();
    this.matrixViewFormService.resetForm();
    this.matrixViewDataService.notifyMatrixViewToReflectPriceChanges();
    if (Array.isArray(priceSubmissionList.pricePoints)) {
      const successMessage: string = `${priceSubmissionList.pricePoints.length} models changed the price successfully`;
      this.notifierService.showSuccess(successMessage);
    }
  }

  /**
   * OnSubmitScenarioError
   * @errors
   */
  onSubmitScenarioError(errors: Array<IPricePointOperationError>): void {
    errors.forEach((error: IPricePointOperationError): void => {
      this.notifierService.showError(error.message as string);
    });
  }

  /**
   * FormatPrice
   * @param price
   * @param currency
   * @param showPlusSign
   */
  formatPrice(price: number, currency: string, showPlusSign: boolean = false): string {
    if (showPlusSign && price > 0) {
      return '+' + this.ftdCurrencyPipe.transform(price || 0, 4, '.', ',', '', currency) || 'N/A';
    } else {
      return this.ftdCurrencyPipe.transform(price || 0, 4, '.', ',', '', currency) || 'N/A';
    }
  }

  /**
   * SetIsAllPricePointsSubmissionPossible
   */
  setIsAllPricePointsSubmissionPossible(): void {
    Object.values(this.dataSource).forEach((priceSubmissionSubmitModal: IPriceSubmissionSubmitModal[]): void => {
      priceSubmissionSubmitModal.forEach((price: IPriceSubmissionSubmitModal): void => {
        if (!this.governanceService.isPricePointSubmissionPossible(price.status)) {
          this.isAllPricePointsSubmissionPossible = false;
        }
      });
    });
  }

  /**
   * IsPricePointSubmissionPossible
   */
  isPricePointSubmissionPossible(status: PricePointApprovalStatus): boolean {
    return this.governanceService.isPricePointSubmissionPossible(status) || this.context === 'override';
  }

  formatPercentage(pricePercentage: number): string {
    return pricePercentage ? (+pricePercentage).toFixed(1) + '%' : 'N/A';
  }
}
