import { Component, Input } from '@angular/core';
import { ICrosschecksTrafficLights, IGranularity } from '../../../models/app.model';
import { MatrixColumnTechnicalName } from 'src/app/matrix-view/enums/matrix-column-technical-name.enum';
import { ScenarioContext } from '../../../../common/enums/scenario-context.enum';

@Component({
  selector: 'app-mv-crosscheck',
  styleUrls: ['./mv-crosscheck.component.scss'],
  templateUrl: './mv-crosscheck.component.html',
})
export class MvCrossCheckComponent {
  @Input() crossCheckResultsItem?: ICrosschecksTrafficLights;
  @Input() granularity!: IGranularity;
  @Input() scenario?: ScenarioContext;
  @Input() scenarioId?: unknown;
  @Input() technicalName?: MatrixColumnTechnicalName;
}
