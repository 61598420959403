import { CommonModule } from '../../common.module';
import { FtdTabComponent } from './ftd-tab/ftd-tab.component';
import { FtdTabGroupComponent } from './ftd-tab-group/ftd-tab-group.component';
import { FtdTabLabelComponent } from './ftd-tab-label/ftd-tab-label.component';
import { MaterialModule } from '../../material.module';
import { CommonModule as NgCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [FtdTabGroupComponent, FtdTabComponent, FtdTabLabelComponent],
  exports: [FtdTabGroupComponent, FtdTabComponent, FtdTabLabelComponent],
  imports: [MaterialModule, NgCommonModule, CommonModule],
})
export class FtdTabsModule {}
