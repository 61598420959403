<div class="ftd-snackbar-content" [attr.data-test]="'ftd-snackbar-notification'">
  <div class="ftd-snackbar-icon" [ngSwitch]="data.type">
    <mat-icon *ngSwitchCase="'info'">info_outline</mat-icon>
    <mat-icon *ngSwitchCase="'success'">check_circle_outline</mat-icon>
    <mat-icon *ngSwitchCase="'error'">report_gmailerrorred</mat-icon>
    <mat-icon *ngSwitchCase="'warning'">warning_amber</mat-icon>
  </div>
  <div class="ftd-snackbar-text">
    <p>{{ data.message }}</p>
  </div>
  <div *ngIf="data.hasClose" class="ftd-snackbar-close">
    <button aria-label="Close notification" (click)="onClose()" data-test="close-snackbar-notification-btn">
      <mat-icon>close</mat-icon>
    </button>
  </div>
</div>
