import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../auth/services/auth.service';
import { Base64Utils } from '../common/utils/base64.utils';
import { Component, OnInit } from '@angular/core';
import { FilterManagementService } from '../common/services/filter-management/filter-management.service';
import { IFilters, IGranularityDto } from '../matrix-view/models/api.model';
import { ScenarioMarketParameter } from '../matrix-view/enums/scenario-market-parameter.enum';
import { SideFiltersService } from '../common/services/side-filter/side-filter.service';
import { User } from '../auth/models/user.model';

export interface ICrosschecksFilters extends IGranularityDto {
  marketLabel?: string | number;
}

@Component({
  selector: 'app-crosschecks',
  styleUrls: ['./crosschecks.component.scss'],
  templateUrl: './crosschecks.component.html',
})
export class CrosschecksComponent implements OnInit {
  currentUser: User | null = null;
  crosschecksFilters!: IFilters;
  urlFilters!: IGranularityDto;
  selectedTab: string = 'sales-pipeline';
  hashedUrl!: string;
  showFiltersFlag: boolean = true;
  queryParams?: Params;
  isCrosschecksFiltersAllowed: boolean = true;
  crosschecksFiltersAllowedRoutes: string[] = [
    'sales-pipeline',
    'production',
    'stock',
    'competition',
    'markets',
    'channels',
  ];

  constructor(
    private filterManagement: FilterManagementService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private sideFiltersService: SideFiltersService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
    this.route.queryParams.subscribe((params: Params) => (this.queryParams = params));
  }

  ngOnInit(): void {
    this.getFilters();
    this.setSelectedTab(this.getSelectedTabFromUrl);
  }

  /**
   * GetUserBrands
   * @private
   * @return string[]
   */
  private getUserBrands(): string[] {
    return this.currentUser?.permissions.getBrandsFromMarket(
      this.urlFilters.market as ScenarioMarketParameter
    ) as string[];
  }

  getFilters(): void {
    this.hashedUrl = this.filterManagement.getHashedFilter();
    this.urlFilters = this.filterManagement.getUrlFilters();

    this.sideFiltersService
      .getFiltersForMarketCrosschecks(this.urlFilters.market, this.getUserBrands())
      .subscribe((filters: IFilters): void => {
        this.crosschecksFilters = filters;
      });
  }

  onClickApply(): void {
    this.urlFilters = this.filterManagement.filters;
    this.hashedUrl = Base64Utils.encodeBtoa(JSON.stringify(this.filterManagement.filters));
    if (this.selectedTab !== 'markets' && this.selectedTab !== 'macroeconomics') {
      this.router.navigate([
        `/crosschecks/${this.getSelectedContextFromUrl}/${this.getSelectedTabFromUrl}/${
          window.location.href.split('/')[6]
        }/${this.hashedUrl}`,
      ]);
    } else {
      this.router.navigate([
        `/crosschecks/${this.getSelectedContextFromUrl}/${this.getSelectedTabFromUrl}/${this.hashedUrl}`,
      ]);
    }
  }

  setSelectedTab(tabId: string): void {
    this.selectedTab = tabId;
    this.checkCrosschecksFilterAllowed();
  }

  /**
   * GetSelectedTabFromUrl
   * @return string
   */
  get getSelectedTabFromUrl(): string {
    return window.location.href.split('/')[5];
  }

  /**
   * GetSelectedTabFromUrl
   * @return string
   */
  get getSelectedContextFromUrl(): string {
    return window.location.href.split('/')[4];
  }

  toggleShowFiltersButtonVisible(): void {
    this.showFiltersFlag = !this.showFiltersFlag;
  }

  /**
   * CheckCrosschecksFilterAllowed
   * @private
   * @return void
   */
  private checkCrosschecksFilterAllowed(): void {
    this.isCrosschecksFiltersAllowed = this.crosschecksFiltersAllowedRoutes.includes(this.selectedTab);
  }

  get hideAndShowFiltersClass(): string {
    return this.showFiltersFlag ? 'show-crosscheck-filters' : 'hide-crosscheck-filters';
  }

  get hideAndShowMatCardSpacingClass(): string {
    return this.showFiltersFlag ? 'crosschecks-mat-card-show' : 'crosschecks-mat-card-hide';
  }
}
