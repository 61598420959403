import { ApolloQueryResult, NetworkStatus } from '@apollo/client/core';
import { Brand } from 'src/app/matrix-view/models/brand.model';
import {
  CrosschecksTrafficLightUIProps,
  ICalendarizedOrderBankDataResponse,
  IMacroeconomicsTooltips,
  IMarketsCrosscheckDataResponse,
  IMarketsTooltips,
  IOrderIntakeApiResponse,
  IOrderIntakeData,
  IPortfolioTooltips,
  IRetailSalesData,
  ISalesPipelineOverviewResponse,
  ISalesPipelineTooltips,
  IStockOverviewResponse,
  IStockTooltips,
  TrafficLightsResponse,
} from '../../matrix-view/models/api.model';
import { GranularityType } from 'src/app/matrix-view/enums/granularity-type.enum';
import { IFtdDropdownOptionsGroup } from 'src/app/common/models/ftd-dropdown-option.model';
import { IGranularity } from 'src/app/matrix-view/models/app.model';
import {
  IMarketsCcTrafficLightsOverviewQuery,
  IMarketsCrossChecksQuery,
  IMarketsTrafficLightsQuery,
  IPortfolioInternalPriceCcTrafficLightsOverviewQuery,
  IPortfolioInternalPriceQuery,
  IPortfolioTrafficLightsQuery,
  ISalesPipelineCcTrafficLightsOverviewQuery,
  ISalesPipelineCrossChecksQuery,
  ISalesPipelineTrafficLightsQuery,
  IStockAgeCcTrafficLightsOverviewQuery,
  IStockAgeCrosscheckData,
  IStockCoverageCcTrafficLightsOverviewQuery,
  IStockTrafficLightsQuery,
} from 'src/app/graphql/services/gql-api.service';

export const CROSSCHECK_SALESPIPELINE_TOOLTIP_RESPONSE_MOCK: ISalesPipelineTooltips = {
  calendarizedOrderBank: 'Shows the retail actuals compared to the planned budget YTD and according to forecast',
  leaseReturns: 'Shows the number of past and expected lease returns along with the lease rate developments',
  orderIntake: 'Shows the actual development of orders YTD and compares different forecasts for the coming months',
  retailSales: 'Shows the actual development of retail YTD and compares different forecasts for the coming months',
  salesOpportunities: '',
};

export const CROSSCHECK_STOCK_TOOLTIP_RESPONSE_MOCK: IStockTooltips = {
  stockAge: 'Shows the breakdown of stock age by e-series into age groups from 0 to over 365 days',
  stockCoverage: 'Shows the number of total stock and compares current stock coverage to ISP stock coverage over time',
  stockMix: 'Shows the key stock KPIs for all relevant e-series models in comparison',
};

export const CROSSCHECK_MARKETS_TOOLTIP_RESPONSE_MOCK: IMarketsTooltips = {
  markets: 'Shows the price points in comparison to their relevant markets within the region',
};

export const CROSSCHECK_PORTFOLIO_TOOLTIP_RESPONSE_MOCK: IPortfolioTooltips = {
  internalLeaseRateLadder:
    'Shows the price points and lease rates of comparable internal models and flags potential inconsistencies in hierarchies',
  internalPriceLadder:
    'Shows the price points and lease rates of comparable internal models and flags potential inconsistencies in hierarchies',
  leaseRateDistribution: '',
  priceDistribution: '',
};

export const CROSSCHECK_MACROECONOMICS_TOOLTIP_RESPONSE_MOCK: IMacroeconomicsTooltips = {
  subsidiesTaxation:
    'Shows the relevant information on subsidies & taxation that should be taken into account for pricing decisions',
};

export const CROSSCHECK_TOOLTIP_RESPONSE_MOCK = {
  macroeconomics: CROSSCHECK_MACROECONOMICS_TOOLTIP_RESPONSE_MOCK,
  markets: CROSSCHECK_MARKETS_TOOLTIP_RESPONSE_MOCK,
  portfolio: CROSSCHECK_PORTFOLIO_TOOLTIP_RESPONSE_MOCK,
  salesPipeline: CROSSCHECK_SALESPIPELINE_TOOLTIP_RESPONSE_MOCK,
  stock: CROSSCHECK_STOCK_TOOLTIP_RESPONSE_MOCK,
};

export const CROSSCHECK_DATA_ORDER_INTAKE_GQL_RESPONSE_MOCK: any = {
  data: {
    salesPipelineCrossChecks: {
      __typename: 'SalesPipelineCrossCheckData',
      data: {
        frequency: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        scalar: {
          openVolumeYe: {
            displayName: 'OPEN VOLUME YE',
            displayNameDetails: 'based on estimations',
            value: 115,
          },
          openVolumeYeRatio: 13,
        },
        timeSeries: [
          {
            actualsPreviousYear: null,
            actualsThisYear: 75,
            date: '2023-01-01',
            enginePredictedOrderIntakeVolume: null,
            enginePredictedOrderIntakeVolumeAdj: null,
            orderIntakeBudget: 49,
            orderIntakeLatestForecast: 78,
            orderIntakeSecondLatestForecast: 78,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 40,
            date: '2023-02-01',
            enginePredictedOrderIntakeVolume: null,
            enginePredictedOrderIntakeVolumeAdj: null,
            orderIntakeBudget: 62,
            orderIntakeLatestForecast: 38,
            orderIntakeSecondLatestForecast: 38,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 134,
            date: '2023-03-01',
            enginePredictedOrderIntakeVolume: null,
            enginePredictedOrderIntakeVolumeAdj: null,
            orderIntakeBudget: 72,
            orderIntakeLatestForecast: 149,
            orderIntakeSecondLatestForecast: 149,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 29,
            date: '2023-04-01',
            enginePredictedOrderIntakeVolume: null,
            enginePredictedOrderIntakeVolumeAdj: null,
            orderIntakeBudget: 86,
            orderIntakeLatestForecast: 29,
            orderIntakeSecondLatestForecast: 29,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 24,
            date: '2023-05-01',
            enginePredictedOrderIntakeVolume: null,
            enginePredictedOrderIntakeVolumeAdj: null,
            orderIntakeBudget: 106,
            orderIntakeLatestForecast: 25,
            orderIntakeSecondLatestForecast: 25,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 76,
            date: '2023-06-01',
            enginePredictedOrderIntakeVolume: null,
            enginePredictedOrderIntakeVolumeAdj: null,
            orderIntakeBudget: 84,
            orderIntakeLatestForecast: 77,
            orderIntakeSecondLatestForecast: 77,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 35,
            date: '2023-07-01',
            enginePredictedOrderIntakeVolume: null,
            enginePredictedOrderIntakeVolumeAdj: null,
            orderIntakeBudget: 69,
            orderIntakeLatestForecast: 45,
            orderIntakeSecondLatestForecast: 45,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 24,
            date: '2023-08-01',
            enginePredictedOrderIntakeVolume: null,
            enginePredictedOrderIntakeVolumeAdj: null,
            orderIntakeBudget: 85,
            orderIntakeLatestForecast: 43,
            orderIntakeSecondLatestForecast: 131,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 28,
            date: '2023-09-01',
            enginePredictedOrderIntakeVolume: null,
            enginePredictedOrderIntakeVolumeAdj: null,
            orderIntakeBudget: 92,
            orderIntakeLatestForecast: 44,
            orderIntakeSecondLatestForecast: 62,
          },
          {
            actualsPreviousYear: 23,
            actualsThisYear: 7,
            date: '2023-10-01',
            enginePredictedOrderIntakeVolume: 71,
            enginePredictedOrderIntakeVolumeAdj: 71,
            orderIntakeBudget: 56,
            orderIntakeLatestForecast: 24,
            orderIntakeSecondLatestForecast: 35,
          },
          {
            actualsPreviousYear: 53,
            actualsThisYear: 0,
            date: '2023-11-01',
            enginePredictedOrderIntakeVolume: 71,
            enginePredictedOrderIntakeVolumeAdj: 71,
            orderIntakeBudget: 50,
            orderIntakeLatestForecast: 81,
            orderIntakeSecondLatestForecast: 78,
          },
          {
            actualsPreviousYear: 59,
            actualsThisYear: 0,
            date: '2023-12-01',
            enginePredictedOrderIntakeVolume: 71,
            enginePredictedOrderIntakeVolumeAdj: 71,
            orderIntakeBudget: 46,
            orderIntakeLatestForecast: 36,
            orderIntakeSecondLatestForecast: 31,
          },
        ],
        timeSeriesProperties: [
          'actualsThisYear',
          'actualsPreviousYear',
          'orderIntakeBudget',
          'enginePredictedOrderIntakeVolume',
          'enginePredictedOrderIntakeVolumeAdj',
          'orderIntakeLatestForecastFC11',
          'orderIntakeSecondLatestForecastFC09',
        ],
        trafficLight: {
          arrow: 1,
          color: 'red',
        },
        trafficLightAdj: {
          arrow: 1,
          color: 'red',
        },
      },
    },
  },
  loading: false,
  networkStatus: NetworkStatus.ready,
};

export const CROSSCHECK_DATA_ORDER_INTAKE_API_RESPONSE_MOCK: IOrderIntakeApiResponse = {
  frequency: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  scalar: {
    openVolumeYe: {
      displayName: 'OPEN VOLUME YE',
      displayNameDetails: 'based on estimations',
      value: 115,
    },
    openVolumeYeRatio: 13,
  },
  timeSeries: [
    {
      actualsPreviousYear: null,
      actualsThisYear: 75,
      date: '2023-01-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 49,
      orderIntakeLatestForecast: 78,
      orderIntakeSecondLatestForecast: 78,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 40,
      date: '2023-02-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 62,
      orderIntakeLatestForecast: 38,
      orderIntakeSecondLatestForecast: 38,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 134,
      date: '2023-03-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 72,
      orderIntakeLatestForecast: 149,
      orderIntakeSecondLatestForecast: 149,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 29,
      date: '2023-04-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 86,
      orderIntakeLatestForecast: 29,
      orderIntakeSecondLatestForecast: 29,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 24,
      date: '2023-05-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 106,
      orderIntakeLatestForecast: 25,
      orderIntakeSecondLatestForecast: 25,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 76,
      date: '2023-06-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 84,
      orderIntakeLatestForecast: 77,
      orderIntakeSecondLatestForecast: 77,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 35,
      date: '2023-07-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 69,
      orderIntakeLatestForecast: 45,
      orderIntakeSecondLatestForecast: 45,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 24,
      date: '2023-08-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 85,
      orderIntakeLatestForecast: 43,
      orderIntakeSecondLatestForecast: 131,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 28,
      date: '2023-09-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 92,
      orderIntakeLatestForecast: 44,
      orderIntakeSecondLatestForecast: 62,
    },
    {
      actualsPreviousYear: 23,
      actualsThisYear: 7,
      date: '2023-10-01',
      enginePredictedOrderIntakeVolume: 71,
      enginePredictedOrderIntakeVolumeAdj: 71,
      orderIntakeBudget: 56,
      orderIntakeLatestForecast: 24,
      orderIntakeSecondLatestForecast: 35,
    },
    {
      actualsPreviousYear: 53,
      actualsThisYear: 0,
      date: '2023-11-01',
      enginePredictedOrderIntakeVolume: 71,
      enginePredictedOrderIntakeVolumeAdj: 71,
      orderIntakeBudget: 50,
      orderIntakeLatestForecast: 81,
      orderIntakeSecondLatestForecast: 78,
    },
    {
      actualsPreviousYear: 59,
      actualsThisYear: 0,
      date: '2023-12-01',
      enginePredictedOrderIntakeVolume: 71,
      enginePredictedOrderIntakeVolumeAdj: 71,
      orderIntakeBudget: 46,
      orderIntakeLatestForecast: 36,
      orderIntakeSecondLatestForecast: 31,
    },
  ],
  timeSeriesProperties: [
    'actualsThisYear',
    'actualsPreviousYear',
    'orderIntakeBudget',
    'enginePredictedOrderIntakeVolume',
    'enginePredictedOrderIntakeVolumeAdj',
    'orderIntakeLatestForecastFC11',
    'orderIntakeSecondLatestForecastFC09',
  ],
  trafficLight: {
    arrow: 1,
    color: 'red',
    weight: 1,
  },
  trafficLightAdj: {
    arrow: 1,
    color: 'red',
    weight: 1,
  },
};

export const CROSSCHECK_DATA_ORDER_INTAKE_RESPONSE_MOCK: IOrderIntakeData = {
  headings: [
    {
      description: '1 series',
      key: 'actualsThisYear',
    },
    { description: '1 series', key: 'actualsThisYear' },
    { description: '1 series', key: 'actualsPreviousYear' },
    { description: '1 series', key: 'orderIntakeBudget' },
    { description: '1 series', key: 'enginePredictedOrderIntakeVolume' },
    { description: '1 series', key: 'enginePredictedOrderIntakeVolumeAdj' },
    { description: '1 series', key: 'orderIntakeLatestForecast' },
    { description: '1 series', key: 'orderIntakeSecondLatestForecast' },
  ],
  openVolumeYe: {
    displayName: 'OPEN VOLUME YE',
    displayNameDetails: 'based on estimations',
    displaySubValue: '13%',
    value: '115',
  },
  xaxis: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  yaxis: [
    {
      actualsPreviousYear: null,
      actualsThisYear: 75,
      date: '2023-01-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 49,
      orderIntakeLatestForecast: 78,
      orderIntakeSecondLatestForecast: 78,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 40,
      date: '2023-02-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 62,
      orderIntakeLatestForecast: 38,
      orderIntakeSecondLatestForecast: 38,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 134,
      date: '2023-03-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 72,
      orderIntakeLatestForecast: 149,
      orderIntakeSecondLatestForecast: 149,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 29,
      date: '2023-04-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 86,
      orderIntakeLatestForecast: 29,
      orderIntakeSecondLatestForecast: 29,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 24,
      date: '2023-05-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 106,
      orderIntakeLatestForecast: 25,
      orderIntakeSecondLatestForecast: 25,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 76,
      date: '2023-06-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 84,
      orderIntakeLatestForecast: 77,
      orderIntakeSecondLatestForecast: 77,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 35,
      date: '2023-07-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 69,
      orderIntakeLatestForecast: 45,
      orderIntakeSecondLatestForecast: 45,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 24,
      date: '2023-08-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 85,
      orderIntakeLatestForecast: 43,
      orderIntakeSecondLatestForecast: 131,
    },
    {
      actualsPreviousYear: null,
      actualsThisYear: 28,
      date: '2023-09-01',
      enginePredictedOrderIntakeVolume: null,
      enginePredictedOrderIntakeVolumeAdj: null,
      orderIntakeBudget: 92,
      orderIntakeLatestForecast: 44,
      orderIntakeSecondLatestForecast: 62,
    },
    {
      actualsPreviousYear: 23,
      actualsThisYear: 7,
      date: '2023-10-01',
      enginePredictedOrderIntakeVolume: 71,
      enginePredictedOrderIntakeVolumeAdj: 71,
      orderIntakeBudget: 56,
      orderIntakeLatestForecast: 24,
      orderIntakeSecondLatestForecast: 35,
    },
    {
      actualsPreviousYear: 53,
      actualsThisYear: 0,
      date: '2023-11-01',
      enginePredictedOrderIntakeVolume: 71,
      enginePredictedOrderIntakeVolumeAdj: 71,
      orderIntakeBudget: 50,
      orderIntakeLatestForecast: 81,
      orderIntakeSecondLatestForecast: 78,
    },
    {
      actualsPreviousYear: 59,
      actualsThisYear: 0,
      date: '2023-12-01',
      enginePredictedOrderIntakeVolume: 71,
      enginePredictedOrderIntakeVolumeAdj: 71,
      orderIntakeBudget: 46,
      orderIntakeLatestForecast: 36,
      orderIntakeSecondLatestForecast: 31,
    },
  ],
};

export const CROSSCHECK_DATA_SALES_PIPELINE_OVERVIEW_RESPONSE_MOCK: ISalesPipelineOverviewResponse = {
  sales_pipeline_overview: {
    calendarized_order_bank: {
      arrow: {
        value: 'down',
      },
    },
    crosscheck_recommendation: {
      arrow: {
        value: 'up',
      },
    },
    incoming_orders: {
      arrow: {
        value: 'up',
      },
      weight: 0.8,
    },
    lease_returns: {
      arrow: {
        value: 'down',
      },
    },
    retail: {
      arrow: {
        value: 'down',
      },
      weight: 0.2,
    },
  },
};

export const CROSSCHECK_DATA_RETAILS_SALES_RESPONSE_MOCK: IRetailSalesData = {
  filters: { comparison_vs: [], sales_channel: [] },
  headings: [
    {
      description: '1 series',
      key: 'actualsThisYear',
    },
    {
      description: '1 series',
      key: 'actualsPreviousYear',
    },
    {
      description: '1 series',
      key: 'retailBudget',
    },
    {
      description: '1 series',
      key: 'enginePredictedRetailVolume',
    },
    {
      description: '1 series',
      key: 'expectedVolumeRetailAdjTS',
    },
    {
      description: '1 series',
      key: 'retailLatestForecast',
    },
    {
      description: '1 series',
      key: 'retailSecondLatestForecast',
    },
  ],
  scalar: {
    absDiffCumulatedBudget: 0,
    absDiffExpectedVolumeBudget: 0,
    budgetVolumeRetail: 0,
    expectedVolumeAchievement: 0,
    expectedVolumeRetail: 0,
    openVolumeYe: {
      displayName: '',
      displayNameDetails: '',
      ratioRetailOpenVolumeBudget: '',
      value: '',
    },
    ratioCumulatedBudget: 0,
    ratioExpectedRetailBudget: 0,
    ratioExpectedVolumeBudget: 0,
    retailCount: 0,
  },
  timeSeriesProperties: [
    'actualsThisYear',
    'actualsPreviousYear',
    'retailBudget',
    'enginePredictedRetailVolume',
    'expectedVolumeRetailAdjTS',
    'retailLatestForecastFC11',
    'retailSecondLatestForecastFC09',
  ],
  xaxis: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
  yaxis: [
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-01-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-02-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 4.0,
      actualsThisYear: 0.0,
      date: '2023-03-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-04-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-05-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-06-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-07-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-08-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 4.0,
      date: '2023-09-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-10-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-11-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
    {
      actualsPreviousYear: 0.0,
      actualsThisYear: 0.0,
      date: '2023-12-01',
      enginePredictedRetailVolume: 0.0,
      retailBudget: 0.0,
    },
  ],
};

export const CROSSCHECK_RETAILS_SALES_API_RESPONSE_MOCK: ApolloQueryResult<ISalesPipelineCrossChecksQuery> = {
  data: {
    salesPipelineCrossChecks: {
      __typename: 'SalesPipelineCrossCheckData',
      data: {
        frequency: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        scalar: {
          absDiffCumulatedBudget: 295,
          absDiffExpectedVolumeBudget: -197,
          budgetVolumeRetail: 798,
          expectedVolumeAchievement: 507,
          expectedVolumeRetail: 601,
          openVolumeYe: {
            displayName: 'OPEN VOLUME YE',
            displayNameDetails: 'based on estimations',
            ratioRetailOpenVolumeBudget: 25,
            value: 197,
          },
          ratioCumulatedBudget: 63,
          ratioExpectedRetailBudget: 64,
          ratioExpectedVolumeBudget: 75,
          retailBudgetScalar: 798,
          retailCount: 503,
        },
        timeSeries: [
          {
            actualsPreviousYear: null,
            actualsThisYear: 45,
            date: '2023-01-01',
            enginePredictedRetailVolume: null,
            expectedVolumeRetailAdjTS: null,
            retailBudget: 8,
            retailLatestForecast: 45,
            retailSecondLatestForecast: 45,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 54,
            date: '2023-02-01',
            enginePredictedRetailVolume: null,
            expectedVolumeRetailAdjTS: null,
            retailBudget: 55,
            retailLatestForecast: 54,
            retailSecondLatestForecast: 54,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 105,
            date: '2023-03-01',
            enginePredictedRetailVolume: null,
            expectedVolumeRetailAdjTS: null,
            retailBudget: 79,
            retailLatestForecast: 105,
            retailSecondLatestForecast: 105,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 53,
            date: '2023-04-01',
            enginePredictedRetailVolume: null,
            expectedVolumeRetailAdjTS: null,
            retailBudget: 61,
            retailLatestForecast: 53,
            retailSecondLatestForecast: 53,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 34,
            date: '2023-05-01',
            enginePredictedRetailVolume: null,
            expectedVolumeRetailAdjTS: null,
            retailBudget: 113,
            retailLatestForecast: 34,
            retailSecondLatestForecast: 34,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 48,
            date: '2023-06-01',
            enginePredictedRetailVolume: null,
            expectedVolumeRetailAdjTS: null,
            retailBudget: 87,
            retailLatestForecast: 48,
            retailSecondLatestForecast: 47,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 63,
            date: '2023-07-01',
            enginePredictedRetailVolume: null,
            expectedVolumeRetailAdjTS: null,
            retailBudget: 81,
            retailLatestForecast: 63,
            retailSecondLatestForecast: 63,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 67,
            date: '2023-08-01',
            enginePredictedRetailVolume: null,
            expectedVolumeRetailAdjTS: null,
            retailBudget: 62,
            retailLatestForecast: 72,
            retailSecondLatestForecast: 33,
          },
          {
            actualsPreviousYear: null,
            actualsThisYear: 34,
            date: '2023-09-01',
            enginePredictedRetailVolume: null,
            expectedVolumeRetailAdjTS: null,
            retailBudget: 55,
            retailLatestForecast: 33,
            retailSecondLatestForecast: 26,
          },
          {
            actualsPreviousYear: 10,
            actualsThisYear: 23,
            date: '2023-10-01',
            enginePredictedRetailVolume: 33,
            expectedVolumeRetailAdjTS: 33,
            retailBudget: 90,
            retailLatestForecast: 29,
            retailSecondLatestForecast: 24,
          },
          {
            actualsPreviousYear: 74,
            actualsThisYear: 0,
            date: '2023-11-01',
            enginePredictedRetailVolume: 35,
            expectedVolumeRetailAdjTS: 35,
            retailBudget: 40,
            retailLatestForecast: 58,
            retailSecondLatestForecast: 53,
          },
          {
            actualsPreviousYear: 101,
            actualsThisYear: 0,
            date: '2023-12-01',
            enginePredictedRetailVolume: 26,
            expectedVolumeRetailAdjTS: 26,
            retailBudget: 67,
            retailLatestForecast: 33,
            retailSecondLatestForecast: 130,
          },
        ],
        timeSeriesProperties: [
          'actualsThisYear',
          'actualsPreviousYear',
          'retailBudget',
          'enginePredictedRetailVolume',
          'expectedVolumeRetailAdjTS',
          'retailLatestForecast',
          'retailSecondLatestForecast',
        ],
      },
    },
  },
  loading: false,
  networkStatus: NetworkStatus.ready,
};
export const CROSSCHECK_DATA_STOCK_OVERVIEW_RESPONSE_RESPONSE_MOCK: IStockOverviewResponse = {
  stock_overview: {
    stock_age: {
      arrow: {
        value: 'down',
      },
    },
    stock_coverage: {
      arrow: {
        value: 'down',
      },
      weight: 1,
    },
    stock_mix: {
      arrow: {
        value: 'down',
      },
    },
  },
};

export const SALES_PIPELINE_OVERVIEW_DATA: ISalesPipelineOverviewResponse = {
  sales_pipeline_overview: {
    calendarized_order_bank: {
      arrow: {
        value: 'down',
      },
    },
    crosscheck_recommendation: {
      arrow: {
        value: 'up',
      },
    },
    incoming_orders: {
      arrow: {
        value: 'up',
      },
      weight: 0.8,
    },
    lease_returns: {
      arrow: {
        value: 'down',
      },
    },
    retail: {
      arrow: {
        value: 'down',
      },
      weight: 0.2,
    },
  },
};

export const CALENDARIZED_ORDER_BANK_DATA_RESPONSE_MOCK: ICalendarizedOrderBankDataResponse = {
  calendarized_order_bank: {
    data: [
      {
        budget: 19688,
        period: 'Jan',
        retail: 2394,
      },
      {
        budget: 22872,
        period: 'Feb',
        retail: 2941,
      },
      {
        budget: 30424,
        period: 'Mar',
        retail: 6478,
      },
      {
        budget: 28784,
        period: 'Apr',
        retail: 3726,
      },
      {
        budget: 28848,
        forecast_not_covered_by_ob_f06: 2547,
        order_bank: {
          percentage: 25.76,
          value: 656,
        },
        period: 'May',
        retail: 180,
      },
      {
        budget: 25088,
        forecast_not_covered_by_ob_f06: 2062,
        order_bank: {
          percentage: 43.16,
          value: 890,
        },
        period: 'Jun',
      },
      {
        budget: 20216,
        forecast_not_covered_by_ob_f06: 1821,
        order_bank: {
          percentage: 14.5,
          value: 264,
        },
        period: 'Jul',
      },
      {
        budget: 18072,
        forecast_not_covered_by_ob_f06: 1142,
        order_bank: {
          percentage: 0,
          value: 0,
        },
        period: 'Aug',
      },
      {
        budget: 21320,
        forecast_not_covered_by_ob_f06: 1601,
        order_bank: {
          percentage: 0,
          value: 0,
        },
        period: 'Sep',
      },
      {
        budget: 26584,
        forecast_not_covered_by_ob_f06: 1821,
        order_bank: {
          percentage: 0,
          value: 0,
        },
        period: 'Oct',
      },
      {
        budget: 28280,
        forecast_not_covered_by_ob_f06: 2132,
        order_bank: {
          percentage: 0,
          value: 0,
        },
        period: 'Nov',
      },
      {
        budget: 25352,
        forecast_not_covered_by_ob_f06: 1366,
        order_bank: {
          percentage: 0,
          value: 0,
        },
        period: 'Dec',
      },
      {
        order_bank: {
          value: 7956,
        },
        period: 2023,
      },
    ],
    order_bank_reach: {
      delta: 0,
      months: 0,
    },
    total_order_bank_cy: {
      current: 7956,
      forecasted: 15912,
    },
  },
};

export const PORTFOLIO_PRICE_LADDER_DATA_GQL_RESPONSE_MOCK: ApolloQueryResult<IPortfolioInternalPriceQuery> = {
  data: {
    portfolioInternalPrice: {
      internalPriceLadderData: [
        {
          data: [
            {
              eRange: undefined,
              enginePower: 85,
              hasAdjustedPrice: true,
              indicativeLeaseRate: undefined,
              indicativeLeaseRateAdj: undefined,
              listPriceExclTax: 28823.53,
              listPriceExclTaxAdj: 41160,
              listPriceInclTax: 34300,
              listPriceInclTaxAdj: 41160,
              model: '116d',
              ph_id: 5145,
              priceLadderFulfilled: 'green',
              priceLadderFulfilledAdj: 'green',
              typicallyEquippedLeaseRate: undefined,
              typicallyEquippedLeaseRateAdj: undefined,
              typicallyEquippedNetPrice: 41215.97,
              typicallyEquippedNetPriceAdj: 48075.97,
              typicallyEquippedPrice: 42530.01,
              typicallyEquippedPriceAdj: 49390.01,
              volumeMix: 0.05,
            },
            {
              eRange: undefined,
              enginePower: 110,
              hasAdjustedPrice: true,
              indicativeLeaseRate: undefined,
              indicativeLeaseRateAdj: undefined,
              listPriceExclTax: 32436.97,
              listPriceExclTaxAdj: 32436.97,
              listPriceInclTax: 38599.99,
              listPriceInclTaxAdj: 38599.99,
              model: '118d',
              ph_id: 5147,
              priceLadderFulfilled: 'red',
              priceLadderFulfilledAdj: 'red',
              typicallyEquippedLeaseRate: undefined,
              typicallyEquippedLeaseRateAdj: undefined,
              typicallyEquippedNetPrice: 46129.41,
              typicallyEquippedNetPriceAdj: 46129.41,
              typicallyEquippedPrice: 47560,
              typicallyEquippedPriceAdj: 47560,
              volumeMix: 0.1,
            },
          ],
          eSeries: 'F40 Pre LCI',
          segment: 'UKL2+',
          series: '1 series',
        },
        {
          data: [
            {
              eRange: 250,
              enginePower: 135,
              hasAdjustedPrice: false,
              indicativeLeaseRate: undefined,
              indicativeLeaseRateAdj: undefined,
              listPriceExclTax: 31092.44,
              listPriceExclTaxAdj: 31092.44,
              listPriceInclTax: 37000,
              listPriceInclTaxAdj: 37000,
              model: '220i',
              ph_id: 5158,
              priceLadderFulfilled: 'green',
              priceLadderFulfilledAdj: 'green',
              typicallyEquippedLeaseRate: undefined,
              typicallyEquippedLeaseRateAdj: undefined,
              typicallyEquippedNetPrice: undefined,
              typicallyEquippedNetPriceAdj: undefined,
              typicallyEquippedPrice: undefined,
              typicallyEquippedPriceAdj: undefined,
              volumeMix: 0.31,
            },
          ],
          eSeries: 'F22 LCI',
          segment: 'UKL2+',
          series: '2 series',
        },
        {
          data: [
            {
              eRange: undefined,
              enginePower: 100,
              hasAdjustedPrice: false,
              indicativeLeaseRate: 391.36,
              indicativeLeaseRateAdj: 391.36,
              listPriceExclTax: 23949.58,
              listPriceExclTaxAdj: 23949.58,
              listPriceInclTax: 28500,
              listPriceInclTaxAdj: 28500,
              model: 'Cooper',
              ph_id: 5307,
              priceLadderFulfilled: 'green',
              priceLadderFulfilledAdj: 'green',
              typicallyEquippedLeaseRate: undefined,
              typicallyEquippedLeaseRateAdj: undefined,
              typicallyEquippedNetPrice: undefined,
              typicallyEquippedNetPriceAdj: undefined,
              typicallyEquippedPrice: undefined,
              typicallyEquippedPriceAdj: undefined,
              volumeMix: 0,
            },
          ],
          eSeries: 'F60 Pre LCI',
          segment: 'UKL2+',
          series: 'Countryman',
        },
      ],
    },
  },
  loading: false,
  networkStatus: NetworkStatus.ready,
};

export const CROSSCHECKS_TRAFFIC_LIGHTS_GQL_RESPONSE_MOCK: TrafficLightsResponse = {
  trafficLight: { arrow: 0, color: 'green', weight: 1 },
  trafficLightAdj: { arrow: 0, color: 'red', weight: 1 },
};

export const CROSSCHECKS_TRAFFIC_LIGHTS_UI_RESPONSE_MOCK: CrosschecksTrafficLightUIProps = {
  arrow: 1,
  color: 'green',
  weight: '100',
};

export const SALES_PIPELINE_TRAFFIC_LIGHTS_GQL_RESPONSE_MOCK: ApolloQueryResult<ISalesPipelineTrafficLightsQuery> = {
  data: {
    scenarioDataByFilterIds: [
      {
        salesPipelineTrafficLight: '{"color":"green","arrow":null}',
        salesPipelineTrafficLightAdj: '{"color":"red","arrow":null}',
      },
    ],
  },
  loading: false,
  networkStatus: NetworkStatus.ready,
};

export const SALES_PIPELINE_TRAFFIC_LIGHTS_OVERVIEW_GQL_RESPONSE_MOCK: ApolloQueryResult<ISalesPipelineCcTrafficLightsOverviewQuery> =
  {
    data: {
      salesPipelineCcTrafficLightsOverview: {
        data: {
          trafficLight: {
            arrow: 1,
            color: 'green',
            weight: 0.9,
          },
        },
      },
    },
    loading: false,
    networkStatus: NetworkStatus.ready,
  };

export const SALES_PIPELINE_TRAFFIC_LIGHTS_GQL_RESPONSE_MOCK_ERROR: ApolloQueryResult<ISalesPipelineTrafficLightsQuery> =
  {
    data: {
      scenarioDataByFilterIds: [
        {
          salesPipelineTrafficLight: '{"color":"green","arrow":null,"weight":2}',
          salesPipelineTrafficLightAdj: '{"color":"red","arrow":null,"weight":2}',
        },
      ],
    },
    loading: false,
    networkStatus: NetworkStatus.ready,
  };

export const STOCK_TRAFFIC_LIGHTS_GQL_RESPONSE_MOCK: ApolloQueryResult<IStockTrafficLightsQuery> = {
  data: {
    scenarioDataByFilterIds: [
      {
        stockTrafficLight: '{"color":"green","arrow":null}',
        stockTrafficLightAdj: '{"color":"red","arrow":null}',
      },
    ],
  },
  loading: false,
  networkStatus: NetworkStatus.ready,
};

export const MARKETS_TRAFFIC_LIGHTS_GQL_RESPONSE_MOCK: ApolloQueryResult<IMarketsTrafficLightsQuery> = {
  data: {
    scenarioDataByFilterIds: [
      {
        marketsTrafficLight: '{"color":"green","arrow":null}',
        marketsTrafficLightAdj: '{"color":"red","arrow":null}',
      },
    ],
  },
  loading: false,
  networkStatus: NetworkStatus.ready,
};

export const MARKETS_TRAFFIC_LIGHTS_OVERVIEW_GQL_RESPONSE_MOCK: ApolloQueryResult<IMarketsCcTrafficLightsOverviewQuery> =
  {
    data: {
      marketsCcTrafficLightsOverview: {
        data: {
          trafficLight: {
            arrow: 1,
            color: 'green',
            weight: 0.9,
          },
        },
      },
    },
    loading: false,
    networkStatus: NetworkStatus.ready,
  };

export const PORTFOLIO_TRAFFIC_LIGHTS_GQL_RESPONSE_MOCK: ApolloQueryResult<IPortfolioTrafficLightsQuery> = {
  data: {
    scenarioDataByFilterIds: [
      {
        portfolioTrafficLight: '{"color":"green","arrow":null}',
        portfolioTrafficLightAdj: '{"color":"red","arrow":null}',
      },
    ],
  },
  loading: false,
  networkStatus: NetworkStatus.ready,
};

export const PORTFOLIO_TRAFFIC_LIGHTS_OVERVIEW_GQL_RESPONSE_MOCK: ApolloQueryResult<IPortfolioInternalPriceCcTrafficLightsOverviewQuery> =
  {
    data: {
      portfolioInternalPriceCcTrafficLightsOverview: {
        data: {
          trafficLight: {
            arrow: 1,
            color: 'green',
            weight: 0.9,
          },
        },
      },
    },
    loading: false,
    networkStatus: NetworkStatus.ready,
  };

export const STOCK_AGE_TRAFFIC_LIGHTS_OVERVIEW_GQL_RESPONSE_MOCK: ApolloQueryResult<IStockAgeCcTrafficLightsOverviewQuery> =
  {
    data: {
      stockAgeCcTrafficLightsOverview: {
        data: {
          trafficLight: {
            arrow: 1,
            color: 'green',
            weight: 0.9,
          },
        },
      },
    },
    loading: false,
    networkStatus: NetworkStatus.ready,
  };

export const STOCK_COVERAGE_TRAFFIC_LIGHTS_OVERVIEW_GQL_RESPONSE_MOCK: ApolloQueryResult<IStockCoverageCcTrafficLightsOverviewQuery> =
  {
    data: {
      stockCoverageCcTrafficLightsOverview: {
        data: {
          trafficLight: {
            arrow: 1,
            color: 'green',
            weight: 0.9,
          },
        },
      },
    },
    loading: false,
    networkStatus: NetworkStatus.ready,
  };

export const STOCK_AGE_OPTIONS_MOCK: IFtdDropdownOptionsGroup<IGranularity>[] = [
  {
    id: 0,
    label: 'BMW | F40 Pre LCI',
    options: [
      {
        id: 1,
        label: '116d',
        value: {
          brand: Brand.BMW,
          eSeries: 'F40 Pre LCI',
          id: 'DE-BMW-UKL2+-1 series-F40 Pre LCI-116i-ICE',
          market: 'DE',
          model: '116d',
          powertrain: 'ICE',
          segment: 'UKL2+',
          series: '1 series',
          type: GranularityType.MODEL,
        } as IGranularity,
      },
      {
        id: 2,
        label: '118d',
        value: {
          brand: Brand.BMW,
          eSeries: 'F40 Pre LCI',
          id: 'DE-BMW-UKL2+-1 series-F40 Pre LCI-118d-ICE',
          market: 'DE',
          model: '118d',
          powertrain: 'ICE',
          segment: 'UKL2+',
          series: '1 series',
          type: GranularityType.MODEL,
        } as IGranularity,
      },
    ],
  },
  {
    id: 1,
    label: 'MINI | F54 LCI',
    options: [
      {
        id: 1,
        label: 'Cooper',
        value: {
          brand: Brand.MINI,
          eSeries: 'F54 LCI',
          id: 'DE-MINI-UKL2+-Mini Clubman-F54 LCI-Cooper-ICE',
          market: 'DE',
          model: 'Cooper',
          powertrain: 'ICE',
          segment: 'UKL2+',
          series: 'Mini Clubman',
          type: GranularityType.MODEL,
        } as IGranularity,
      },
      {
        id: 1,
        label: 'Countryman Cooper',
        value: {
          brand: Brand.MINI,
          eSeries: 'F54 LCI',
          id: 'DE-MINI-UKL2+-Mini Clubman-F54 LCI-Cooper D-ICE',
          market: 'DE',
          model: 'Countryman Cooper',
          powertrain: 'ICE',
          segment: 'UKL2+',
          series: 'Mini Clubman',
          type: GranularityType.MODEL,
        } as IGranularity,
      },
    ],
  },
];

export const CROSSCHECK_DATA_STOCK_COVERAGE_RESPONSE_MOCK = {
  scalar: {
    currentActualStockUnits: 240,
    currentStockCoverage: 6.486486486486487,
    deltaCurrentStockCoverageToISP: null,
    deltaCurrentToSoYActualStockUnits: -100,
    listPriceInclTax: 37000.35,
    listPriceInclTaxAdjFilled: 37000.35,
  },
  stockCoverage: [
    {
      date: '2024-06-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: -1,
      stockCoverageTSAdj: 2,
    },
    {
      date: '2024-03-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 2,
      stockCoverageTSAdj: 5,
    },
    {
      date: '2023-10-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 6,
      stockCoverageTSAdj: 6,
    },
    {
      date: '2024-08-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: -3,
      stockCoverageTSAdj: 0,
    },
    {
      date: '2022-05-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 7,
      stockCoverageTSAdj: 7,
    },
    {
      date: '2024-05-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 0,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2024-04-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 1,
      stockCoverageTSAdj: 4,
    },
    {
      date: '2024-11-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: -6,
      stockCoverageTSAdj: -3,
    },
    {
      date: '2023-11-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 6,
      stockCoverageTSAdj: 6,
    },
    {
      date: '2023-03-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2024-02-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 4,
    },
    {
      date: '2022-06-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2022-01-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 5,
      stockCoverageTSAdj: 5,
    },
    {
      date: '2023-07-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2023-01-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 10,
      stockCoverageTSAdj: 10,
    },
    {
      date: '2024-09-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: -4,
      stockCoverageTSAdj: -1,
    },
    {
      date: '2024-12-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: -7,
      stockCoverageTSAdj: -4,
    },
    {
      date: '2024-10-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: -5,
      stockCoverageTSAdj: -2,
    },
    {
      date: '2022-12-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 4,
      stockCoverageTSAdj: 4,
    },
    {
      date: '2023-05-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2023-08-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2023-06-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2023-04-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 5,
      stockCoverageTSAdj: 5,
    },
    {
      date: '2022-02-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 5,
      stockCoverageTSAdj: 5,
    },
    {
      date: '2022-07-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 7,
      stockCoverageTSAdj: 7,
    },
    {
      date: '2022-11-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2024-01-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 10,
      stockCoverageTSAdj: 10,
    },
    {
      date: '2023-12-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 8,
      stockCoverageTSAdj: 8,
    },
    {
      date: '2023-02-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 5,
      stockCoverageTSAdj: 5,
    },
    {
      date: '2023-09-01T00:00:00.000Z',
      idealStockCoverageTS: 1,
      stockCoverageTS: 5,
      stockCoverageTSAdj: 5,
    },
    {
      date: '2022-10-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 6,
      stockCoverageTSAdj: 6,
    },
    {
      date: '2022-03-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2024-07-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: -2,
      stockCoverageTSAdj: 1,
    },
    {
      date: '2022-09-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 6,
      stockCoverageTSAdj: 6,
    },
    {
      date: '2022-04-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 3,
      stockCoverageTSAdj: 3,
    },
    {
      date: '2022-08-01T00:00:00.000Z',
      idealStockCoverageTS: 0,
      stockCoverageTS: 9,
      stockCoverageTSAdj: 9,
    },
  ],
  stockUnits: [
    {
      date: '2024-06-01T00:00:00.000Z',
      stockUnitsActualsTS: -38,
      stockUnitsForecastTS: -38,
      stockUnitsForecastTSAdj: 70,
    },
    {
      date: '2024-03-01T00:00:00.000Z',
      stockUnitsActualsTS: 127,
      stockUnitsForecastTS: 127,
      stockUnitsForecastTSAdj: 157,
    },
    {
      date: '2023-10-01T00:00:00.000Z',
      stockUnitsActualsTS: 263,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2024-08-01T00:00:00.000Z',
      stockUnitsActualsTS: -147,
      stockUnitsForecastTS: -147,
      stockUnitsForecastTSAdj: 12,
    },
    {
      date: '2022-05-01T00:00:00.000Z',
      stockUnitsActualsTS: 341,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2024-05-01T00:00:00.000Z',
      stockUnitsActualsTS: 17,
      stockUnitsForecastTS: 17,
      stockUnitsForecastTSAdj: 99,
    },
    {
      date: '2024-04-01T00:00:00.000Z',
      stockUnitsActualsTS: 72,
      stockUnitsForecastTS: 72,
      stockUnitsForecastTSAdj: 128,
    },
    {
      date: '2024-11-01T00:00:00.000Z',
      stockUnitsActualsTS: -311,
      stockUnitsForecastTS: -311,
      stockUnitsForecastTSAdj: -76,
    },
    {
      date: '2023-11-01T00:00:00.000Z',
      stockUnitsActualsTS: 240,
      stockUnitsForecastTS: 239,
      stockUnitsForecastTSAdj: 239,
    },
    {
      date: '2023-03-01T00:00:00.000Z',
      stockUnitsActualsTS: 282,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2024-02-01T00:00:00.000Z',
      stockUnitsActualsTS: 181,
      stockUnitsForecastTS: 181,
      stockUnitsForecastTSAdj: 186,
    },
    {
      date: '2022-06-01T00:00:00.000Z',
      stockUnitsActualsTS: 348,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2022-01-01T00:00:00.000Z',
      stockUnitsActualsTS: 310,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2023-07-01T00:00:00.000Z',
      stockUnitsActualsTS: 280,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2023-01-01T00:00:00.000Z',
      stockUnitsActualsTS: 333,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2024-09-01T00:00:00.000Z',
      stockUnitsActualsTS: -202,
      stockUnitsForecastTS: -202,
      stockUnitsForecastTSAdj: -17,
    },
    {
      date: '2024-12-01T00:00:00.000Z',
      stockUnitsActualsTS: -366,
      stockUnitsForecastTS: -366,
      stockUnitsForecastTSAdj: -105,
    },
    {
      date: '2024-10-01T00:00:00.000Z',
      stockUnitsActualsTS: -256,
      stockUnitsForecastTS: -256,
      stockUnitsForecastTSAdj: -47,
    },
    {
      date: '2022-12-01T00:00:00.000Z',
      stockUnitsActualsTS: 340,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2023-05-01T00:00:00.000Z',
      stockUnitsActualsTS: 280,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2023-08-01T00:00:00.000Z',
      stockUnitsActualsTS: 286,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2023-06-01T00:00:00.000Z',
      stockUnitsActualsTS: 286,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2023-04-01T00:00:00.000Z',
      stockUnitsActualsTS: 279,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2022-02-01T00:00:00.000Z',
      stockUnitsActualsTS: 314,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2022-07-01T00:00:00.000Z',
      stockUnitsActualsTS: 359,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2022-11-01T00:00:00.000Z',
      stockUnitsActualsTS: 378,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2024-01-01T00:00:00.000Z',
      stockUnitsActualsTS: 236,
      stockUnitsForecastTS: 236,
      stockUnitsForecastTSAdj: 236,
    },
    {
      date: '2023-12-01T00:00:00.000Z',
      stockUnitsActualsTS: 260,
      stockUnitsForecastTS: 260,
      stockUnitsForecastTSAdj: 260,
    },
    {
      date: '2023-02-01T00:00:00.000Z',
      stockUnitsActualsTS: 307,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2023-09-01T00:00:00.000Z',
      stockUnitsActualsTS: 269,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2022-10-01T00:00:00.000Z',
      stockUnitsActualsTS: 352,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2022-03-01T00:00:00.000Z',
      stockUnitsActualsTS: 329,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2024-07-01T00:00:00.000Z',
      stockUnitsActualsTS: -92,
      stockUnitsForecastTS: -92,
      stockUnitsForecastTSAdj: 41,
    },
    {
      date: '2022-09-01T00:00:00.000Z',
      stockUnitsActualsTS: 336,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2022-04-01T00:00:00.000Z',
      stockUnitsActualsTS: 324,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
    {
      date: '2022-08-01T00:00:00.000Z',
      stockUnitsActualsTS: 350,
      stockUnitsForecastTS: 0,
      stockUnitsForecastTSAdj: 0,
    },
  ],
  tooltipText: {
    stockCoverage:
      'Stock coverage is the duration in months\n' +
      'that the currently accessible stock lasts\n' +
      'without additional production',
    stockUnits:
      'Accessible stock defined as\nvehicles in group stock located at \ncompound in market or in dealer \nstock located at dealership in\nmarket, neither customer sold, nor \n2nd price level, nor blocked \n(tech, LVB, logistic)',
  },
};

export const CROSSCHECK_DATA_MARKET_RESPONSE_MOCK: IMarketsCrosscheckDataResponse = {
  leaseRateData: [
    {
      baseGrossPrice: undefined,
      baseGrossPriceAdj: undefined,
      baseNetPrice: undefined,
      baseNetPriceAdj: undefined,
      market: 'DE',
      marketGrossPriceRatio: undefined,
      marketGrossPriceRatioAdj: undefined,
      marketNetPriceRatio: undefined,
      marketNetPriceRatioAdj: undefined,
      modelCode: 'LV31',
      modelCodeAdj: 'LV31',
      typicallyEquippedGrossPrice: undefined,
      typicallyEquippedGrossPriceAdj: undefined,
      typicallyEquippedNetPrice: undefined,
      typicallyEquippedNetPriceAdj: undefined,
    },
    {
      baseGrossPrice: undefined,
      baseGrossPriceAdj: undefined,
      baseNetPrice: undefined,
      baseNetPriceAdj: undefined,
      market: 'IT',
      marketGrossPriceRatio: undefined,
      marketGrossPriceRatioAdj: undefined,
      marketNetPriceRatio: undefined,
      marketNetPriceRatioAdj: undefined,
      modelCode: 'LV31',
      modelCodeAdj: 'LV31',
      typicallyEquippedGrossPrice: undefined,
      typicallyEquippedGrossPriceAdj: undefined,
      typicallyEquippedNetPrice: undefined,
      typicallyEquippedNetPriceAdj: undefined,
    },
    {
      baseGrossPrice: undefined,
      baseGrossPriceAdj: undefined,
      baseNetPrice: undefined,
      baseNetPriceAdj: undefined,
      market: 'ES',
      marketGrossPriceRatio: undefined,
      marketGrossPriceRatioAdj: undefined,
      marketNetPriceRatio: undefined,
      marketNetPriceRatioAdj: undefined,
      modelCode: 'LV31',
      modelCodeAdj: 'LV31',
      typicallyEquippedGrossPrice: undefined,
      typicallyEquippedGrossPriceAdj: undefined,
      typicallyEquippedNetPrice: undefined,
      typicallyEquippedNetPriceAdj: undefined,
    },
  ],
  priceData: [
    {
      baseGrossPrice: 30420.17,
      baseGrossPriceAdj: 31000,
      baseNetPrice: 36200,
      baseNetPriceAdj: undefined,
      hasAdjustedPrice: false,
      market: 'DE',
      marketGrossPriceRatio: 0,
      marketGrossPriceRatioAdj: undefined,
      marketNetPriceRatio: 0,
      marketNetPriceRatioAdj: undefined,
      modelCode: 'LV31',
      modelCodeAdj: 'LV31',
      typicallyEquippedGrossPrice: undefined,
      typicallyEquippedGrossPriceAdj: undefined,
      typicallyEquippedNetPrice: 40200,
      typicallyEquippedNetPriceAdj: undefined,
    },
    {
      baseGrossPrice: 32018.14,
      baseGrossPriceAdj: 31000,
      baseNetPrice: 40000.01,
      baseNetPriceAdj: 41500,
      hasAdjustedPrice: true,
      market: 'IT',
      marketGrossPriceRatio: 0.05,
      marketGrossPriceRatioAdj: 31000,
      marketNetPriceRatio: 0.1,
      marketNetPriceRatioAdj: 0.15,
      modelCode: 'LV31',
      modelCodeAdj: 'LV31',
      typicallyEquippedGrossPrice: undefined,
      typicallyEquippedGrossPriceAdj: undefined,
      typicallyEquippedNetPrice: 36200,
      typicallyEquippedNetPriceAdj: undefined,
    },
    {
      baseGrossPrice: 27727.62,
      baseGrossPriceAdj: undefined,
      baseNetPrice: 34867.48,
      baseNetPriceAdj: undefined,
      hasAdjustedPrice: false,
      market: 'ES',
      marketGrossPriceRatio: -0.09,
      marketGrossPriceRatioAdj: undefined,
      marketNetPriceRatio: -0.04,
      marketNetPriceRatioAdj: undefined,
      modelCode: 'LV31',
      modelCodeAdj: 'LV31',
      typicallyEquippedGrossPrice: undefined,
      typicallyEquippedGrossPriceAdj: undefined,
      typicallyEquippedNetPrice: 34200,
      typicallyEquippedNetPriceAdj: undefined,
    },
  ],
};

export const CROSSCHECK_DATA_MARKET_GQL_RESPONSE_MOCK: ApolloQueryResult<IMarketsCrossChecksQuery> = {
  data: {
    marketsCrossChecks: [
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'CH',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'GR',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'AT',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'GB',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'PT',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'DE',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'BE',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'ES',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'FR',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
      {
        leaseRate: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
        market: 'PL',
        modelCode: 'LV31',
        price: {
          baseGrossPrice: 0,
          baseGrossPriceAdj: 0,
          baseNetPrice: 0,
          baseNetPriceAdj: 0,
          marketGrossPriceRatio: 0,
          marketGrossPriceRatioAdj: 0,
          marketNetPriceRatio: 0,
          marketNetPriceRatioAdj: 0,
          typicallyEquippedGrossPrice: 0,
          typicallyEquippedGrossPriceAdj: 0,
          typicallyEquippedNetPrice: 0,
          typicallyEquippedNetPriceAdj: 0,
        },
      },
    ],
  },
  loading: false,
  networkStatus: NetworkStatus.ready,
};

export const STOCK_AGE_DATA_GQL_RESPONSE_MOCK: IStockAgeCrosscheckData = {
  historic: [
    {
      countPerBucket0_10Days: 17,
      countPerBucket11_30Days: 16,
      countPerBucket31_90Days: 32,
      countPerBucket91_180Days: 112,
      countPerBucket181_270Days: 2,
      countPerBucket271_365Days: null,
      countPerBucket365Days: null,
      date: '2023-01-01',
      historicSumGranularBucket0_10Days: 17,
      historicSumGranularBucket11_20Days: 15,
      historicSumGranularBucket21_30Days: 1,
      historicSumGranularBucket31_40Days: null,
      historicSumGranularBucket41_50Days: 2,
      historicSumGranularBucket51_60Days: 6,
      historicSumGranularBucket61_70Days: 5,
      historicSumGranularBucket71_80Days: 9,
      historicSumGranularBucket81_90Days: 10,
      historicSumGranularBucket91_100Days: 6,
      historicSumGranularBucket101_110Days: 18,
      historicSumGranularBucket111_120Days: 14,
      historicSumGranularBucket121_130Days: 16,
      historicSumGranularBucket131_140Days: 26,
      historicSumGranularBucket141_150Days: 7,
      historicSumGranularBucket151_160Days: 6,
      historicSumGranularBucket161_170Days: 14,
      historicSumGranularBucket171_180Days: 5,
      historicSumGranularBucket181_195Days: 2,
      historicSumGranularBucket196_210Days: null,
      historicSumGranularBucket211_225Days: null,
      historicSumGranularBucket226_240Days: null,
      historicSumGranularBucket241_255Days: null,
      historicSumGranularBucket256_270Days: null,
      historicSumGranularBucket271_285Days: null,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      monthlyCount: 179,
      monthlyMedianAge: 99,
    },
    {
      countPerBucket0_10Days: 13,
      countPerBucket11_30Days: 29,
      countPerBucket31_90Days: 23,
      countPerBucket91_180Days: 110,
      countPerBucket181_270Days: 25,
      countPerBucket271_365Days: null,
      countPerBucket365Days: null,
      date: '2023-02-01',
      historicSumGranularBucket0_10Days: 13,
      historicSumGranularBucket11_20Days: 21,
      historicSumGranularBucket21_30Days: 8,
      historicSumGranularBucket31_40Days: 8,
      historicSumGranularBucket41_50Days: 6,
      historicSumGranularBucket51_60Days: 1,
      historicSumGranularBucket61_70Days: null,
      historicSumGranularBucket71_80Days: 2,
      historicSumGranularBucket81_90Days: 6,
      historicSumGranularBucket91_100Days: 5,
      historicSumGranularBucket101_110Days: 12,
      historicSumGranularBucket111_120Days: 8,
      historicSumGranularBucket121_130Days: 6,
      historicSumGranularBucket131_140Days: 22,
      historicSumGranularBucket141_150Days: 7,
      historicSumGranularBucket151_160Days: 19,
      historicSumGranularBucket161_170Days: 22,
      historicSumGranularBucket171_180Days: 9,
      historicSumGranularBucket181_195Days: 17,
      historicSumGranularBucket196_210Days: 6,
      historicSumGranularBucket211_225Days: 2,
      historicSumGranularBucket226_240Days: null,
      historicSumGranularBucket241_255Days: null,
      historicSumGranularBucket256_270Days: null,
      historicSumGranularBucket271_285Days: null,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      monthlyCount: 200,
      monthlyMedianAge: 111,
    },
    {
      countPerBucket0_10Days: 46,
      countPerBucket11_30Days: 14,
      countPerBucket31_90Days: 36,
      countPerBucket91_180Days: 68,
      countPerBucket181_270Days: 71,
      countPerBucket271_365Days: null,
      countPerBucket365Days: null,
      date: '2023-03-01',
      historicSumGranularBucket0_10Days: 46,
      historicSumGranularBucket11_20Days: 8,
      historicSumGranularBucket21_30Days: 6,
      historicSumGranularBucket31_40Days: 4,
      historicSumGranularBucket41_50Days: 8,
      historicSumGranularBucket51_60Days: 10,
      historicSumGranularBucket61_70Days: 6,
      historicSumGranularBucket71_80Days: 7,
      historicSumGranularBucket81_90Days: 1,
      historicSumGranularBucket91_100Days: null,
      historicSumGranularBucket101_110Days: 2,
      historicSumGranularBucket111_120Days: 6,
      historicSumGranularBucket121_130Days: 5,
      historicSumGranularBucket131_140Days: 11,
      historicSumGranularBucket141_150Days: 9,
      historicSumGranularBucket151_160Days: 5,
      historicSumGranularBucket161_170Days: 18,
      historicSumGranularBucket171_180Days: 12,
      historicSumGranularBucket181_195Days: 29,
      historicSumGranularBucket196_210Days: 18,
      historicSumGranularBucket211_225Days: 15,
      historicSumGranularBucket226_240Days: 7,
      historicSumGranularBucket241_255Days: 2,
      historicSumGranularBucket256_270Days: null,
      historicSumGranularBucket271_285Days: null,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      monthlyCount: 235,
      monthlyMedianAge: 115,
    },
    {
      countPerBucket0_10Days: 25,
      countPerBucket11_30Days: 40,
      countPerBucket31_90Days: 62,
      countPerBucket91_180Days: 47,
      countPerBucket181_270Days: 94,
      countPerBucket271_365Days: 2,
      countPerBucket365Days: null,
      date: '2023-04-01',
      historicSumGranularBucket0_10Days: 25,
      historicSumGranularBucket11_20Days: 26,
      historicSumGranularBucket21_30Days: 14,
      historicSumGranularBucket31_40Days: 30,
      historicSumGranularBucket41_50Days: 6,
      historicSumGranularBucket51_60Days: 5,
      historicSumGranularBucket61_70Days: 4,
      historicSumGranularBucket71_80Days: 7,
      historicSumGranularBucket81_90Days: 10,
      historicSumGranularBucket91_100Days: 6,
      historicSumGranularBucket101_110Days: 7,
      historicSumGranularBucket111_120Days: 1,
      historicSumGranularBucket121_130Days: null,
      historicSumGranularBucket131_140Days: 2,
      historicSumGranularBucket141_150Days: 6,
      historicSumGranularBucket151_160Days: 5,
      historicSumGranularBucket161_170Days: 11,
      historicSumGranularBucket171_180Days: 9,
      historicSumGranularBucket181_195Days: 16,
      historicSumGranularBucket196_210Days: 17,
      historicSumGranularBucket211_225Days: 28,
      historicSumGranularBucket226_240Days: 12,
      historicSumGranularBucket241_255Days: 14,
      historicSumGranularBucket256_270Days: 7,
      historicSumGranularBucket271_285Days: 2,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      monthlyCount: 270,
      monthlyMedianAge: 118,
    },
    {
      countPerBucket0_10Days: 95,
      countPerBucket11_30Days: 156,
      countPerBucket31_90Days: 32,
      countPerBucket91_180Days: 43,
      countPerBucket181_270Days: 90,
      countPerBucket271_365Days: 19,
      countPerBucket365Days: null,
      date: '2023-05-01',
      historicSumGranularBucket0_10Days: 95,
      historicSumGranularBucket11_20Days: 151,
      historicSumGranularBucket21_30Days: 5,
      historicSumGranularBucket31_40Days: 9,
      historicSumGranularBucket41_50Days: 7,
      historicSumGranularBucket51_60Days: 3,
      historicSumGranularBucket61_70Days: 6,
      historicSumGranularBucket71_80Days: 3,
      historicSumGranularBucket81_90Days: 4,
      historicSumGranularBucket91_100Days: 5,
      historicSumGranularBucket101_110Days: 6,
      historicSumGranularBucket111_120Days: 7,
      historicSumGranularBucket121_130Days: 10,
      historicSumGranularBucket131_140Days: 7,
      historicSumGranularBucket141_150Days: null,
      historicSumGranularBucket151_160Days: null,
      historicSumGranularBucket161_170Days: 2,
      historicSumGranularBucket171_180Days: 6,
      historicSumGranularBucket181_195Days: 14,
      historicSumGranularBucket196_210Days: 10,
      historicSumGranularBucket211_225Days: 15,
      historicSumGranularBucket226_240Days: 17,
      historicSumGranularBucket241_255Days: 24,
      historicSumGranularBucket256_270Days: 10,
      historicSumGranularBucket271_285Days: 11,
      historicSumGranularBucket286_300Days: 7,
      historicSumGranularBucket301_315Days: 1,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      monthlyCount: 435,
      monthlyMedianAge: 83,
    },
    {
      countPerBucket0_10Days: 145,
      countPerBucket11_30Days: 71,
      countPerBucket31_90Days: 96,
      countPerBucket91_180Days: 48,
      countPerBucket181_270Days: 52,
      countPerBucket271_365Days: 35,
      countPerBucket365Days: null,
      date: '2023-06-01',
      historicSumGranularBucket0_10Days: 145,
      historicSumGranularBucket11_20Days: 58,
      historicSumGranularBucket21_30Days: 13,
      historicSumGranularBucket31_40Days: 57,
      historicSumGranularBucket41_50Days: 18,
      historicSumGranularBucket51_60Days: 4,
      historicSumGranularBucket61_70Days: 8,
      historicSumGranularBucket71_80Days: 6,
      historicSumGranularBucket81_90Days: 3,
      historicSumGranularBucket91_100Days: 6,
      historicSumGranularBucket101_110Days: 3,
      historicSumGranularBucket111_120Days: 4,
      historicSumGranularBucket121_130Days: 5,
      historicSumGranularBucket131_140Days: 6,
      historicSumGranularBucket141_150Days: 7,
      historicSumGranularBucket151_160Days: 10,
      historicSumGranularBucket161_170Days: 7,
      historicSumGranularBucket171_180Days: null,
      historicSumGranularBucket181_195Days: 1,
      historicSumGranularBucket196_210Days: 7,
      historicSumGranularBucket211_225Days: 14,
      historicSumGranularBucket226_240Days: 7,
      historicSumGranularBucket241_255Days: 12,
      historicSumGranularBucket256_270Days: 11,
      historicSumGranularBucket271_285Days: 15,
      historicSumGranularBucket286_300Days: 6,
      historicSumGranularBucket301_315Days: 9,
      historicSumGranularBucket316_330Days: 4,
      historicSumGranularBucket331_345Days: 1,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      monthlyCount: 447,
      monthlyMedianAge: 78,
    },
    {
      countPerBucket0_10Days: 162,
      countPerBucket11_30Days: 143,
      countPerBucket31_90Days: 95,
      countPerBucket91_180Days: 44,
      countPerBucket181_270Days: 39,
      countPerBucket271_365Days: 35,
      countPerBucket365Days: 1,
      date: '2023-07-01',
      historicSumGranularBucket0_10Days: 162,
      historicSumGranularBucket11_20Days: 89,
      historicSumGranularBucket21_30Days: 54,
      historicSumGranularBucket31_40Days: 16,
      historicSumGranularBucket41_50Days: 4,
      historicSumGranularBucket51_60Days: 5,
      historicSumGranularBucket61_70Days: 59,
      historicSumGranularBucket71_80Days: 6,
      historicSumGranularBucket81_90Days: 5,
      historicSumGranularBucket91_100Days: 4,
      historicSumGranularBucket101_110Days: 7,
      historicSumGranularBucket111_120Days: 4,
      historicSumGranularBucket121_130Days: 6,
      historicSumGranularBucket131_140Days: 3,
      historicSumGranularBucket141_150Days: 4,
      historicSumGranularBucket151_160Days: 5,
      historicSumGranularBucket161_170Days: 6,
      historicSumGranularBucket171_180Days: 5,
      historicSumGranularBucket181_195Days: 13,
      historicSumGranularBucket196_210Days: 3,
      historicSumGranularBucket211_225Days: 1,
      historicSumGranularBucket226_240Days: 4,
      historicSumGranularBucket241_255Days: 8,
      historicSumGranularBucket256_270Days: 10,
      historicSumGranularBucket271_285Days: 8,
      historicSumGranularBucket286_300Days: 9,
      historicSumGranularBucket301_315Days: 8,
      historicSumGranularBucket316_330Days: 5,
      historicSumGranularBucket331_345Days: 3,
      historicSumGranularBucket346_365Days: 2,
      historicSumGranularBucket365Days: 1,
      monthlyCount: 519,
      monthlyMedianAge: 67,
    },
    {
      countPerBucket0_10Days: 90,
      countPerBucket11_30Days: 154,
      countPerBucket31_90Days: 119,
      countPerBucket91_180Days: 95,
      countPerBucket181_270Days: 29,
      countPerBucket271_365Days: 32,
      countPerBucket365Days: 1,
      date: '2023-08-01',
      historicSumGranularBucket0_10Days: 90,
      historicSumGranularBucket11_20Days: 68,
      historicSumGranularBucket21_30Days: 86,
      historicSumGranularBucket31_40Days: 26,
      historicSumGranularBucket41_50Days: 56,
      historicSumGranularBucket51_60Days: 21,
      historicSumGranularBucket61_70Days: 8,
      historicSumGranularBucket71_80Days: 5,
      historicSumGranularBucket81_90Days: 3,
      historicSumGranularBucket91_100Days: 48,
      historicSumGranularBucket101_110Days: 18,
      historicSumGranularBucket111_120Days: 4,
      historicSumGranularBucket121_130Days: 5,
      historicSumGranularBucket131_140Days: 5,
      historicSumGranularBucket141_150Days: 4,
      historicSumGranularBucket151_160Days: 4,
      historicSumGranularBucket161_170Days: 4,
      historicSumGranularBucket171_180Days: 3,
      historicSumGranularBucket181_195Days: 5,
      historicSumGranularBucket196_210Days: 10,
      historicSumGranularBucket211_225Days: 8,
      historicSumGranularBucket226_240Days: 2,
      historicSumGranularBucket241_255Days: 1,
      historicSumGranularBucket256_270Days: 3,
      historicSumGranularBucket271_285Days: 3,
      historicSumGranularBucket286_300Days: 9,
      historicSumGranularBucket301_315Days: 4,
      historicSumGranularBucket316_330Days: 6,
      historicSumGranularBucket331_345Days: 5,
      historicSumGranularBucket346_365Days: 5,
      historicSumGranularBucket365Days: 1,
      monthlyCount: 520,
      monthlyMedianAge: 70,
    },
    {
      countPerBucket0_10Days: 52,
      countPerBucket11_30Days: 29,
      countPerBucket31_90Days: 290,
      countPerBucket91_180Days: 97,
      countPerBucket181_270Days: 29,
      countPerBucket271_365Days: 13,
      countPerBucket365Days: 4,
      date: '2023-09-01',
      historicSumGranularBucket0_10Days: 52,
      historicSumGranularBucket11_20Days: 15,
      historicSumGranularBucket21_30Days: 14,
      historicSumGranularBucket31_40Days: 82,
      historicSumGranularBucket41_50Days: 63,
      historicSumGranularBucket51_60Days: 54,
      historicSumGranularBucket61_70Days: 20,
      historicSumGranularBucket71_80Days: 50,
      historicSumGranularBucket81_90Days: 21,
      historicSumGranularBucket91_100Days: 8,
      historicSumGranularBucket101_110Days: 5,
      historicSumGranularBucket111_120Days: 3,
      historicSumGranularBucket121_130Days: 48,
      historicSumGranularBucket131_140Days: 18,
      historicSumGranularBucket141_150Days: 4,
      historicSumGranularBucket151_160Days: 2,
      historicSumGranularBucket161_170Days: 5,
      historicSumGranularBucket171_180Days: 4,
      historicSumGranularBucket181_195Days: 5,
      historicSumGranularBucket196_210Days: 4,
      historicSumGranularBucket211_225Days: 5,
      historicSumGranularBucket226_240Days: 7,
      historicSumGranularBucket241_255Days: 6,
      historicSumGranularBucket256_270Days: 2,
      historicSumGranularBucket271_285Days: null,
      historicSumGranularBucket286_300Days: 2,
      historicSumGranularBucket301_315Days: 2,
      historicSumGranularBucket316_330Days: 5,
      historicSumGranularBucket331_345Days: 1,
      historicSumGranularBucket346_365Days: 3,
      historicSumGranularBucket365Days: 4,
      monthlyCount: 514,
      monthlyMedianAge: 80,
    },
    {
      countPerBucket0_10Days: 40,
      countPerBucket11_30Days: 33,
      countPerBucket31_90Days: 240,
      countPerBucket91_180Days: 172,
      countPerBucket181_270Days: 31,
      countPerBucket271_365Days: 12,
      countPerBucket365Days: 4,
      date: '2023-10-01',
      historicSumGranularBucket0_10Days: 40,
      historicSumGranularBucket11_20Days: 27,
      historicSumGranularBucket21_30Days: 6,
      historicSumGranularBucket31_40Days: 27,
      historicSumGranularBucket41_50Days: 5,
      historicSumGranularBucket51_60Days: 35,
      historicSumGranularBucket61_70Days: 52,
      historicSumGranularBucket71_80Days: 73,
      historicSumGranularBucket81_90Days: 48,
      historicSumGranularBucket91_100Days: 34,
      historicSumGranularBucket101_110Days: 34,
      historicSumGranularBucket111_120Days: 17,
      historicSumGranularBucket121_130Days: 9,
      historicSumGranularBucket131_140Days: 4,
      historicSumGranularBucket141_150Days: 10,
      historicSumGranularBucket151_160Days: 54,
      historicSumGranularBucket161_170Days: 6,
      historicSumGranularBucket171_180Days: 4,
      historicSumGranularBucket181_195Days: 5,
      historicSumGranularBucket196_210Days: 5,
      historicSumGranularBucket211_225Days: 5,
      historicSumGranularBucket226_240Days: 4,
      historicSumGranularBucket241_255Days: 6,
      historicSumGranularBucket256_270Days: 6,
      historicSumGranularBucket271_285Days: 6,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: 3,
      historicSumGranularBucket331_345Days: 1,
      historicSumGranularBucket346_365Days: 2,
      historicSumGranularBucket365Days: 4,
      monthlyCount: 532,
      monthlyMedianAge: 96,
    },
    {
      countPerBucket0_10Days: 28,
      countPerBucket11_30Days: 62,
      countPerBucket31_90Days: 124,
      countPerBucket91_180Days: 281,
      countPerBucket181_270Days: 30,
      countPerBucket271_365Days: 10,
      countPerBucket365Days: 6,
      date: '2023-11-01',
      historicSumGranularBucket0_10Days: 28,
      historicSumGranularBucket11_20Days: 39,
      historicSumGranularBucket21_30Days: 23,
      historicSumGranularBucket31_40Days: 21,
      historicSumGranularBucket41_50Days: 3,
      historicSumGranularBucket51_60Days: 8,
      historicSumGranularBucket61_70Days: 4,
      historicSumGranularBucket71_80Days: 17,
      historicSumGranularBucket81_90Days: 71,
      historicSumGranularBucket91_100Days: 66,
      historicSumGranularBucket101_110Days: 55,
      historicSumGranularBucket111_120Days: 29,
      historicSumGranularBucket121_130Days: 37,
      historicSumGranularBucket131_140Days: 19,
      historicSumGranularBucket141_150Days: 9,
      historicSumGranularBucket151_160Days: 3,
      historicSumGranularBucket161_170Days: 5,
      historicSumGranularBucket171_180Days: 58,
      historicSumGranularBucket181_195Days: 8,
      historicSumGranularBucket196_210Days: 2,
      historicSumGranularBucket211_225Days: 5,
      historicSumGranularBucket226_240Days: 5,
      historicSumGranularBucket241_255Days: 4,
      historicSumGranularBucket256_270Days: 6,
      historicSumGranularBucket271_285Days: 5,
      historicSumGranularBucket286_300Days: 1,
      historicSumGranularBucket301_315Days: 3,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: 1,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: 6,
      monthlyCount: 541,
      monthlyMedianAge: 106,
    },
  ],
  scalar: [
    {
      medianAgeCurrentMonth: 106,
      medianAgeDiff: 11,
      over180PercentageDiff: 0,
      percentageCurrentMonth: 9,
    },
  ],
  stockAge: [
    {
      countPerBucket0_10Days: 575,
      countPerBucket11_30Days: 760,
      countPerBucket31_90Days: 1223,
      countPerBucket91_180Days: 2176,
      countPerBucket181_270Days: 786,
      countPerBucket271_365Days: 432,
      countPerBucket365Days: 122,
      eSeries: 'G21 LCI',
      eSeriesCount: 6074,
      eSeriesPercentage0_10Days: 9,
      eSeriesPercentage11_30Days: 13,
      eSeriesPercentage31_90Days: 20,
      eSeriesPercentage91_180Days: 36,
      eSeriesPercentage181_270Days: 13,
      eSeriesPercentage271_365Days: 7,
      eSeriesPercentage365Days: 2,
    },
    {
      countPerBucket0_10Days: 25,
      countPerBucket11_30Days: 31,
      countPerBucket31_90Days: 63,
      countPerBucket91_180Days: 71,
      countPerBucket181_270Days: 52,
      countPerBucket271_365Days: 17,
      countPerBucket365Days: 9,
      eSeries: 'G80 Pre LCI',
      eSeriesCount: 268,
      eSeriesPercentage0_10Days: 9,
      eSeriesPercentage11_30Days: 12,
      eSeriesPercentage31_90Days: 24,
      eSeriesPercentage91_180Days: 26,
      eSeriesPercentage181_270Days: 19,
      eSeriesPercentage271_365Days: 6,
      eSeriesPercentage365Days: 3,
    },
    {
      countPerBucket0_10Days: 9,
      countPerBucket11_30Days: 4,
      countPerBucket31_90Days: 3,
      countPerBucket91_180Days: 28,
      countPerBucket181_270Days: 46,
      countPerBucket271_365Days: 8,
      countPerBucket365Days: null,
      eSeries: 'G81 Pre LCI',
      eSeriesCount: 98,
      eSeriesPercentage0_10Days: 9,
      eSeriesPercentage11_30Days: 4,
      eSeriesPercentage31_90Days: 3,
      eSeriesPercentage91_180Days: 29,
      eSeriesPercentage181_270Days: 47,
      eSeriesPercentage271_365Days: 8,
      eSeriesPercentage365Days: null,
    },
    {
      countPerBucket0_10Days: 4,
      countPerBucket11_30Days: 3,
      countPerBucket31_90Days: 4,
      countPerBucket91_180Days: null,
      countPerBucket181_270Days: null,
      countPerBucket271_365Days: null,
      countPerBucket365Days: 79,
      eSeries: 'G20 Pre LCI',
      eSeriesCount: 90,
      eSeriesPercentage0_10Days: 4,
      eSeriesPercentage11_30Days: 3,
      eSeriesPercentage31_90Days: 4,
      eSeriesPercentage91_180Days: null,
      eSeriesPercentage181_270Days: null,
      eSeriesPercentage271_365Days: null,
      eSeriesPercentage365Days: 88,
    },
    {
      countPerBucket0_10Days: null,
      countPerBucket11_30Days: null,
      countPerBucket31_90Days: null,
      countPerBucket91_180Days: null,
      countPerBucket181_270Days: null,
      countPerBucket271_365Days: null,
      countPerBucket365Days: 1,
      eSeries: 'F34 LCI',
      eSeriesCount: 1,
      eSeriesPercentage0_10Days: null,
      eSeriesPercentage11_30Days: null,
      eSeriesPercentage31_90Days: null,
      eSeriesPercentage91_180Days: null,
      eSeriesPercentage181_270Days: null,
      eSeriesPercentage271_365Days: null,
      eSeriesPercentage365Days: 100,
    },
    {
      countPerBucket0_10Days: 596,
      countPerBucket11_30Days: 614,
      countPerBucket31_90Days: 555,
      countPerBucket91_180Days: 492,
      countPerBucket181_270Days: 286,
      countPerBucket271_365Days: 140,
      countPerBucket365Days: 28,
      eSeries: 'G20 LCI',
      eSeriesCount: 2711,
      eSeriesPercentage0_10Days: 22,
      eSeriesPercentage11_30Days: 23,
      eSeriesPercentage31_90Days: 20,
      eSeriesPercentage91_180Days: 18,
      eSeriesPercentage181_270Days: 11,
      eSeriesPercentage271_365Days: 5,
      eSeriesPercentage365Days: 1,
    },
    {
      countPerBucket0_10Days: 1,
      countPerBucket11_30Days: 2,
      countPerBucket31_90Days: null,
      countPerBucket91_180Days: null,
      countPerBucket181_270Days: null,
      countPerBucket271_365Days: null,
      countPerBucket365Days: 34,
      eSeries: 'G21 Pre LCI',
      eSeriesCount: 37,
      eSeriesPercentage0_10Days: 3,
      eSeriesPercentage11_30Days: 5,
      eSeriesPercentage31_90Days: null,
      eSeriesPercentage91_180Days: null,
      eSeriesPercentage181_270Days: null,
      eSeriesPercentage271_365Days: null,
      eSeriesPercentage365Days: 92,
    },
  ],
};
