import { Injectable } from '@angular/core';
import { ScenarioDefaultIds } from '../../../../../common/enums/scenario-default-ids.enum';

export enum DiscountContext {
  read = 'read',
  create = 'write',
}

@Injectable({
  providedIn: 'root',
})
export class ContextService {
  private currentDiscountContext: DiscountContext = DiscountContext.read;
  private _discountId!: string;

  constructor() {}

  get discountId(): string {
    // TODO: REMOVE ME AFTER DISCOUNT BY FILTER ID QUERY INTEGRATION
    return ScenarioDefaultIds.governance || this._discountId;
  }

  set discountId(value: string) {
    this._discountId = value;
  }

  setCurrentDiscountContext(discount: DiscountContext): void {
    this.currentDiscountContext = discount;
  }

  getCurrentDiscountContext(): DiscountContext {
    return this.currentDiscountContext;
  }
}
