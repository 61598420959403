import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { FtdCurrencyPipe } from '../../pipes/ftd-currency/ftd-currency.pipe';
import { ICurrencyMaskConfig } from '../../directives/app-currency-mask/lib/currency-mask.config';
import { IFtdDropdownOption } from '../../models/ftd-dropdown-option.model';
import { IPriceRange } from '../../models/price-range.model';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSliderDragEvent } from '@angular/material/slider';

@Component({
  selector: 'app-ftd-price-range-select-slider',
  styleUrls: ['./ftd-price-range-select-slider.component.scss'],
  templateUrl: './ftd-price-range-select-slider.component.html',
})
export class FtdPriceRangeSelectSliderComponent implements OnInit {
  @Input() minRangeValue: number = 0;
  @Input() maxRangeValue: number = 100_000;
  @Input() currency: string = '€';
  @Input() set minValue(value: number) {
    if (this.formGroup) {
      this.minPriceValue = value;
      this.validateMinPriceValuesOnChanges();
      this.updateDropdownSelectedDisplayedValue();
    }
  }

  @Input() set maxValue(value: number) {
    if (this.formGroup) {
      this.maxPriceValue = value;
      this.validateMaxPriceValuesOnChanges();
      this.updateDropdownSelectedDisplayedValue();
    }
  }

  @Output() priceRangeApplied: EventEmitter<IPriceRange> = new EventEmitter<IPriceRange>();

  formGroup!: FormGroup;

  singleOptionList!: IFtdDropdownOption[];

  defaultValue!: IFtdDropdownOption;

  currencyMaskOption: ICurrencyMaskConfig = {
    allowNegative: false,
    precision: 0,
  };

  priceCorrectionOnError = 2000;

  private popoverTrigger!: MatMenuTrigger;

  constructor(private ftdCurrencyPipe: FtdCurrencyPipe) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      max: new FormControl(this.maxRangeValue),
      min: new FormControl(this.minRangeValue),
      // Fake FormControl to display applied price's range
      range: new FormControl({ max: this.maxRangeValue, min: this.minRangeValue }),
    });

    this.updateDropdownSelectedDisplayedValue();
  }

  validateMinPriceValuesOnChanges(): void {
    const minPriceControl = this.formGroup.get('min');
    const maxPriceControl = this.formGroup.get('max');
    if (this.minPriceValue < this.minRangeValue) {
      minPriceControl?.setValue(this.minRangeValue);
    } else if (this.minPriceValue > this.maxRangeValue) {
      minPriceControl?.setValue(this.maxRangeValue - this.priceCorrectionOnError);
      maxPriceControl?.setValue(this.maxRangeValue);
    } else if (this.minPriceValue >= this.maxPriceValue) {
      maxPriceControl?.setValue(this.minPriceValue + this.priceCorrectionOnError);
    } else {
      minPriceControl?.setValue(this.minPriceValue);
    }
  }

  validateMaxPriceValuesOnChanges(): void {
    const minPriceControl = this.formGroup.get('min');
    const maxPriceControl = this.formGroup.get('max');
    if (this.maxPriceValue > this.maxRangeValue) {
      maxPriceControl?.setValue(this.maxRangeValue);
    } else if (this.maxPriceValue < this.minRangeValue) {
      maxPriceControl?.setValue(this.minRangeValue + this.priceCorrectionOnError);
      minPriceControl?.setValue(this.minRangeValue);
    } else if (this.maxPriceValue <= this.minPriceValue) {
      minPriceControl?.setValue(this.maxPriceValue - this.priceCorrectionOnError);
    } else {
      maxPriceControl?.setValue(this.maxPriceValue);
    }
  }

  handleMinPriceDragEnded(event: MatSliderDragEvent): void {
    this.minPriceValue = event.value;
  }

  handleMaxPriceDragEnded(event: MatSliderDragEvent): void {
    this.maxPriceValue = event.value;
  }

  applySelectedPriceRange(): void {
    this.updateDropdownSelectedDisplayedValue();
    this.priceRangeApplied.emit({
      maxPrice: this.maxPriceValue,
      minPrice: this.minPriceValue,
    });
    this.closePopover();
  }

  closePopover(): void {
    this.popoverTrigger.closeMenu();
  }

  initializedPopoverTrigger($event: MatMenuTrigger): void {
    this.popoverTrigger = $event;
  }

  private updateDropdownSelectedDisplayedValue(): void {
    this.singleOptionList = [this.buildSelectedOption()];
    this.defaultValue = this.singleOptionList[0];
  }

  private buildSelectedOption(): IFtdDropdownOption {
    const min = this.formValue?.min || 0;
    const max = this.formValue?.max || 0;
    const minPriceFormatted: string = this.formatPrice(min);
    const maxPriceFormatted: string = this.formatPrice(max);

    return {
      id: 'selected-price-rance-with-currency-option',
      label: `${minPriceFormatted} - ${maxPriceFormatted}`,
      value: { max, min },
    };
  }

  private formatPrice(value: number): string {
    return this.ftdCurrencyPipe.transform(value, 0, '', ',', '', this.currency);
  }

  get formValue() {
    return this.formGroup.value;
  }

  get minPriceValue(): number {
    return this.formValue?.min || 0;
  }

  set minPriceValue(value: number) {
    this.formGroup.get('min')?.setValue(value);
  }

  get maxPriceValue(): number {
    return this.formValue?.max || 0;
  }

  set maxPriceValue(value: number) {
    this.formGroup.get('max')?.setValue(value);
  }

  get isApplyButtonDisabled(): boolean {
    return this.formGroup?.pristine;
  }
}
