/**
 * Different Matrix-Column-Section's names (Expandable main-columns)
 */
export enum MatrixColumnSectionKey {
  CURRENT_PRICING = 'CURRENT_PRICING',
  CURRENT_FINANCIALS = 'CURRENT_FINANCIALS',
  CURRENT_CROSSCHECKS = 'CURRENT_CROSSCHECKS',
  PRICE_ENGINE_RECOMMENDATION = 'PRICE_ENGINE_RECOMMENDATION',
  PRICE_EDITOR = 'PRICE_EDITOR',
  FORECASTED_FINANCIALS = 'FORECASTED_FINANCIALS',
  FORECASTED_CROSSCHECKS = 'FORECASTED_CROSSCHECKS',
  ACTIONS = 'ACTIONS',
}
