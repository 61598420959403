<table cdk-table [dataSource]="discountSummaryData" id="summary-table" recycleRows>
  <caption class="sr-only">
    Discounts Summary Table
  </caption>
  <ng-container
    *ngFor="let categoryCol of columnCategory; first as isFirst; last as isLast"
    [matColumnDef]="categoryCol.name"
    [sticky]="isFirst"
  >
    <th
      cdk-header-cell
      *cdkHeaderCellDef
      [attr.colspan]="categoryCol.numOfColumns"
      [ngClass]="{ 'border-separator': !isLast }"
    >
      {{ categoryCol.displayName }}
    </th>
  </ng-container>
  <ng-container *ngFor="let col of columns; index as idx" [matColumnDef]="col.name" [sticky]="col.isSticky">
    <th cdk-header-cell *cdkHeaderCellDef [ngClass]="{ 'border-separator': lastChildOfCategory.includes(col.name) }">
      <app-ftd-expanded-tooltip [hasBackdrop]="false" [closeMenuOnMouseLeave]="true">
        <div tooltipTrigger class="height-100">
          <div>{{ col.displayName }}</div>
          <span *ngIf="col.secondDisplayName">| {{ col.secondDisplayName }}</span>
        </div>
        <div tooltipContent class="tooltip-wrapper" *ngIf="col.metadata.description">
          {{ col.metadata.description }}
        </div>
      </app-ftd-expanded-tooltip>
    </th>
    <td
      cdk-cell
      *cdkCellDef="let element"
      [ngClass]="{ 'border-separator': lastChildOfCategory.includes(col.name) }"
      (click)="col.name !== 'actions' ? openDiscountMatrix(element.id) : ''"
    >
      <app-discounts-summary-table-column-map
        [column]="col"
        [dataItem]="element"
        [index]="idx"
      ></app-discounts-summary-table-column-map>
    </td>
  </ng-container>
  <tr cdk-header-row *cdkHeaderRowDef="columnCategories; sticky: true" class="summary-columns-category"></tr>
  <tr cdk-header-row *cdkHeaderRowDef="columnsToDisplay; sticky: true"></tr>
  <tr
    cdk-row
    *cdkRowDef="let row; columns: columnsToDisplay"
    class="discount-status-{{ discountStatusMap.get(row.approvalStatus)?.colorScheme }}"
  ></tr>
</table>
