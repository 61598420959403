import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
})
export class LoadingComponent {
  @Input() color: keyof typeof LoadSpinnerColor | LoadSpinnerColor = LoadSpinnerColor.primary;
  @Input() diameter?: number = 100;
}

export enum LoadSpinnerColor {
  primary = 'primary',
  white = 'white',
}
