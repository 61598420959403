import { ActivatedRoute, Data } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { CrosschecksContext } from '../../../enums/crosschecks-context.enum';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-competition-page',
  templateUrl: './competition-page.component.html',
})
export class CompetitionPageComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];
  context: CrosschecksContext = CrosschecksContext.CURRENT;

  /**
   * Constructor
   * @param route
   */
  constructor(private readonly route: ActivatedRoute) {
    const routeSubscription: Subscription = this.route.data.subscribe((data: Data): void => {
      this.context = data.context;
    });

    this.subscriptions.push(routeSubscription);
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }
}
