<div [id]="id" class="ftd-toggle-button-container" [class.disabled]="disabled">
  <input id="active" type="radio" name="switch" [checked]="checked" hidden [disabled]="disabled" />
  <input id="inactive" type="radio" name="switch" [checked]="!checked" hidden [disabled]="disabled" />

  <label class="active" for="active" (click)="setChecked(true)" [class.checked]="checked">
    <mat-icon class="ftd-toggle-button-active-icon" *ngIf="isActiveIconVisible">{{ activeIcon }}</mat-icon>
    <span class="ftd-toggle-button-active-name"> {{ activeName }} </span>
  </label>
  <label class="inactive" for="inactive" (click)="setChecked(false)" [class.checked]="!checked">
    <mat-icon class="ftd-toggle-button-inactive-icon" *ngIf="isInactiveIconVisible">{{ inactiveIcon }}</mat-icon>
    <span class="ftd-toggle-button-inactive-name"> {{ inactiveName }} </span>
  </label>
</div>
