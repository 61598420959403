<header class="ftd-header">
  <div class="ftd-header-logos-list">
    <img src="assets/images/logoBmwGroupMonoWhite.svg" alt="BMW Group logo" />
    <img src="assets/images/logoBmwMonoWhite.svg" alt="BMW logo" />
    <img src="assets/images/logoMiniMonoWhite.svg" alt="Mini logo" />
  </div>
  <div class="ftd-header-app-indicator">
    <div class="ftd-header-app-logos">
      <img ngSrc="assets/images/logoBmwNcpeMonoWhite.svg" alt="NCPE" height="33" width="97" />
    </div>
    <div class="user-manual">
      <a class="item-text" aria-label="User Manual" [href]="APP_FSM_USER_MANUAL_LINK" target="_blank">
        <mat-icon matTooltip="User Manual">help</mat-icon>
      </a>
    </div>

    <nav *ngIf="!isUserHidden" class="ftd-header-menu" #nav>
      <button
        aria-label="menu"
        mat-button
        [matMenuTriggerFor]="menu"
        (onMenuOpen)="menuOpen()"
        (onMenuClose)="menuClose()"
      >
        <div id="ftd-header-button-label">
          <img ngSrc="assets/images/user.svg" alt="User" height="32" width="32" />
          <div aria-label="User information" class="ftd-header-menu-info">
            {{ userName }}<br />
            <span>
              {{ userHighestHierarchyRoleDefinition }}
            </span>
            <span *ngIf="userRoles!.length > 1"> (+{{ userRoles!.length - 1 }}) </span>
          </div>
          <div class="mat-icon-container">
            <mat-icon>keyboard_arrow_down</mat-icon>
          </div>
        </div>
      </button>

      <mat-menu #menu="matMenu" class="ftd-header-submenu">
        <div>
          <button
            aria-label="Navigate to Roles & Permissions"
            routerLink="user-roles"
            mat-menu-item
            class="ftd-header-menu-dropdown-item"
          >
            <span>Roles & Permissions</span>
          </button>
          <button
            aria-label="Navigate to Crosschecks Settings"
            routerLink="crosschecks/settings"
            mat-menu-item
            class="ftd-header-menu-dropdown-item"
          >
            <span>Crosschecks Settings</span>
          </button>
        </div>
        <hr class="divisor-line" />
        <button
          aria-label="Logout"
          mat-menu-item
          class="ftd-header-menu-dropdown-item ftd-header-menu-logout"
          (click)="onLogOut($event)"
        >
          <mat-icon>logout</mat-icon>
          <span>{{ 'Logout' | titlecase }}</span>
        </button>
      </mat-menu>
    </nav>
  </div>
</header>
