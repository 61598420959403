import { ColumnSeries, LineSeries } from '@amcharts/amcharts5/xy';
import { DataItem, Theme } from '@amcharts/amcharts5';
import { GenericChartFields } from '../../../models/ftd-generic-chart.model';
import { ISeriesDataItem, Series } from '@amcharts/amcharts5/.internal/core/render/Series';
import { am5 } from '../../../ftd-base-chart.abstract';
import { leaseReturnsSeriesName } from '../../../enums/crosschecks/sales-pipeline/ftd-lease-returns.enum';

export class FtdLeaseReturnsCustomTheme extends Theme {
  setupDefaultRules() {
    super.setupDefaultRules();
    this.setupGridRules();
    this.setupLineRules();
    this.setupColumnRules();
  }

  protected setupGridRules() {
    this.rule('Grid', ['x']).setAll({
      visible: false,
    });

    this.rule('Grid', ['y']).setAll({
      stroke: am5.color(0xffffff),
      strokeOpacity: 0.1,
    });

    this.rule('AxisLabel', ['ftd-yAxis-right']).set('paddingLeft', 14);

    this.rule('AxisRendererX').setAll({
      cellEndLocation: 0.8,
      cellStartLocation: 0.2,
    });

    this.rule('ValueAxis', ['ftd-yAxis-right']).setAll({
      numberFormat: "#,### '€'",
    });

    this.rule('Label', ['ftd-chart-label', 'ftd-yAxis-right']).set('x', 18);
  }

  protected setupLineRules() {
    this.rule('LineSeries').adapters.add('stroke', (stroke: am5.Color | undefined, target: LineSeries) => {
      target.strokes.template.set('strokeWidth', 2);
      switch (target.get('name')) {
        case leaseReturnsSeriesName.averageLeaseRateReturn:
          return am5.color(0x52a2eb);
        case leaseReturnsSeriesName.currentLeaseRate:
          return am5.color(0xf28f33);
        case leaseReturnsSeriesName.forecastAverageLeaseRateReturn:
          target.strokes.template.set('strokeDasharray', [10, 5]);
          return am5.color(0x52a2eb);
        case leaseReturnsSeriesName.expectedLeaseRate:
          target.strokes.template.set('strokeDasharray', [10, 5]);
          return am5.color(0xf28f33);
        case leaseReturnsSeriesName.returningCustomersCompared:
          target.strokes.template.set('opacity', 0);
      }
      return stroke;
    });

    this.rule('LineSeries').adapters.add('fill', (fill: am5.Color | undefined, target: LineSeries) => {
      switch (target.get('name')) {
        case leaseReturnsSeriesName.averageLeaseRateReturn:
          return am5.color(0x52a2eb);
        case leaseReturnsSeriesName.currentLeaseRate:
          return am5.color(0xf28f33);
        case leaseReturnsSeriesName.expectedLeaseRate:
          return am5.color(0xf28f33);
        case leaseReturnsSeriesName.forecastAverageLeaseRateReturn:
          return am5.color(0x52a2eb);
      }
      return fill;
    });

    // Set special current month tooltip
    this.rule('Label').adapters.add('html', (html, target) => {
      const seriesName = (target.dataItem?.component as Series)?.get('name');
      if (
        seriesName === leaseReturnsSeriesName.currentLeaseRate ||
        seriesName === leaseReturnsSeriesName.averageLeaseRateReturn
      ) {
        if (
          new Date((target.dataItem?.dataContext as GenericChartFields)?.xAxisValue as number).getMonth() ===
          new Date().getMonth()
        ) {
          return html
            ? this.buildCurrentMonthLeaseRateTooltip(seriesName, target.dataItem?.dataContext as GenericChartFields)
            : html;
        }
      }
      return html;
    });

    // Set top blue chips for Returning customers compared to retail
    this.rule('LineSeries').events.once('datavalidated', (event) => {
      if (event.target.get('name') === leaseReturnsSeriesName.returningCustomersCompared) {
        event.target.bullets.clear();
        event.target.bullets.push((root: am5.Root, dataSeries: am5.Series, dataItem: DataItem<ISeriesDataItem>) => {
          const label = am5.Label.new(root, {
            dx: -30,
            html: this.buildReturningCustomersLabelHtml(
              (dataItem.dataContext as GenericChartFields).returningCustomersCompared as number
            ),
            position: 'absolute',
            y: 0,
          });
          label.adapters.add('y', (y: number | am5.Percent | null | undefined, target: am5.Label) => {
            target.set('dy', Number(y) * -1 - 40);
            return y;
          });
          label.on('x', (x: number | am5.Percent | null | undefined, target: am5.Label | undefined): void => {
            Number(x) < 0 || Number(x) > Number(event.target.chart?.plotContainer.width())
              ? label?.hide()
              : label?.show();
          });
          return am5.Bullet.new(root, {
            sprite: label,
          });
        });
      }
    });
  }

  protected setupColumnRules() {
    this.rule('ColumnSeries').adapters.add('fill', (fill: am5.Color | undefined, target: ColumnSeries) => {
      switch (target.get('name')) {
        case leaseReturnsSeriesName.totalLeaseReturns:
          return am5.color(0x79ddc0);
        case leaseReturnsSeriesName.expectedTotalLeaseReturns:
          return am5.color(0xd6d6d6);
      }
      return fill;
    });
  }

  private buildCurrentMonthLeaseRateTooltip(seriesName: string, data: GenericChartFields): string {
    switch (seriesName) {
      case leaseReturnsSeriesName.currentLeaseRate:
        return `
      <div style='font-family:BMWGroupTNCondensedPro-Regular; font-size: 16px; font-weight: 400;padding: 8px;background-color:#282f47; border-radius: 5px;'>
        <div style='display:flex;justify-content:space-between;'>
          <span style='font-family:BMWGroupTNCondensedPro-Bold;margin-right:8px'>Lease Rate</span> <span>${
            data.currentLeaseRate
          }${data.currency}</span>
        </div>
        <div style='display:flex;justify-content:space-between;margin-top:14px;gap:28px;'>
          <div>
            <span style='font-family:BMWGroupTNCondensedPro-Bold;'>${
              ((data.totalLeaseRateUnits as number) * (data.lessThanLeaseReturnPercentage as number)) / 100
            } units</span>
            <span style='color: #E0E1E1; font-size:14px;'>(${data.lessThanLeaseReturnPercentage}%)</span>
          </div>
          <span>less than ${data.currentLeaseRate}${data.currency}</span>
        </div>
        <div style='display:flex;justify-content:space-between;margin-top:14px;gap:28px;'>
          <div>
            <span style='font-family:BMWGroupTNCondensedPro-Bold;'>${
              ((data.totalLeaseRateUnits as number) * (data.moreThanLeaseReturnPercentage as number)) / 100
            } units</span>
            <span style='color: #E0E1E1; font-size:14px;'>(${data.moreThanLeaseReturnPercentage}%)</span>
          </div>
          <span>more than ${data.currentLeaseRate}${data.currency}</span>
        </div>
      </div>
      `;
      case leaseReturnsSeriesName.averageLeaseRateReturn:
        return `
      <div style='font-family:BMWGroupTNCondensedPro-Regular; font-size: 16px; font-weight: 400;padding: 8px;background-color:#282f47; border-radius: 5px;'>
        <div style='display:flex;justify-content:space-between;'>
          <span style='font-family:BMWGroupTNCondensedPro-Bold;margin-right:8px'>Avg. lease rate</span> <span>${
            data.averageLeaseRateReturn
          }${data.currency}</span>
        </div>
        <div style='display:flex;justify-content:space-between;margin-top:14px;gap:28px;'>
          <div>
            <span style='font-family:BMWGroupTNCondensedPro-Bold;'>${
              ((data.totalAverageLeaseRateUnits as number) * (data.lessThanAverageLeaseReturnPercentage as number)) /
              100
            } units</span>
            <span style='color: #E0E1E1; font-size:14px;'>(${data.lessThanAverageLeaseReturnPercentage}%)</span>
          </div>
          <span>less than ${data.averageLeaseRateReturn}${data.currency}</span>
        </div>
        <div style='display:flex;justify-content:space-between;margin-top:14px;gap:28px;'>
          <div>
            <span style='font-family:BMWGroupTNCondensedPro-Bold;'>${
              ((data.totalAverageLeaseRateUnits as number) * (data.moreThanAverageLeaseReturnPercentage as number)) /
              100
            } units</span>
            <span style='color: #E0E1E1; font-size:14px;'>(${data.moreThanAverageLeaseReturnPercentage}%)</span>
          </div>
          <span>more than ${data.averageLeaseRateReturn}${data.currency}</span>
        </div>
      </div>
      `;
      default:
        return '';
    }
  }

  private buildReturningCustomersLabelHtml(value: number): string {
    return `
      <div style='width:40px;'>
        <span style='padding-top:5px;padding-bottom:5px;padding-left:8px:padding-right:8px;background-color:#0071C5;border-radius:12px;font-family:BMWGroupTNCondensedPro-Regular; font-size: 12px; font-weight: 700'>
        &nbsp;&nbsp;&nbsp;${value}%&nbsp;&nbsp;&nbsp;
        </span>
      </div>
      `;
  }
}
