/* eslint-disable */
import { AuthService } from '../../../auth/services/auth.service';
import {
  BrandsApproveModal,
  BrandsApproveModalKey,
  IApproveModel,
  IProductHierarchyExtension,
} from '../../../matrix-view/models/approve.model';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../../../matrix-view/services/context/context.service';
import { FtdNotifierService } from '../../../common/services/ftd-notifier/ftd-notifier.service';
import { GranularityService } from '../../../matrix-view/services/granularity/granularity.service';
import { IApiErrorResponse } from '../../../core/error-handling/errors/models/api-error.model';
import { IApprovalStatusBySeriesResponse, IProductHierarchy } from '../../../graphql/services/gql-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatrixViewDataService } from '../../../matrix-view/services/matrix-view-data/matrix-view-data.service';
import { MessageService } from '../../../common/services/message/message.service';
import { ScenarioMarketParameter } from '../../../matrix-view/enums/scenario-market-parameter.enum';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';
import { GovernanceService } from 'src/app/matrix-view/services/governance/governance.service';
import { ApprovalErrorMessages, ApprovalModalType } from 'src/app/matrix-view/enums/approval-status.enum';
import { Brand } from 'src/app/matrix-view/models/brand.model';

@Component({
  selector: 'app-approve-scenario-modal',
  styleUrls: ['./approve-scenario-modal.component.scss'],
  templateUrl: './approve-scenario-modal.component.html',
})
/**
 * @class ApproveScenarioModalComponent
 */
export class ApproveScenarioModalComponent implements OnInit, OnDestroy {
  public title: string = 'Approve Prices';
  public isReleaseButtonDisabled: boolean = true;
  public isRequestProcessing: boolean = false;
  public errorMessage: string = '';
  private currentUser!: User | null;
  public market: ScenarioMarketParameter | null = null;
  public brands: BrandsApproveModal = {
    BMW: {
      data: [],
      isChecked: false,
      isExpanded: true,
    },
    MINI: {
      data: [],
      isChecked: false,
      isExpanded: true,
    },
  };

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param data
   * @param notifierService
   * @param dialogRef
   * @param authService
   * @param matrixViewDataService
   * @param messageService
   * @param contextService
   * @param granularityService
   * @param governanceService
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IApproveModel | any,
    private notifierService: FtdNotifierService,
    private dialogRef: MatDialogRef<ApproveScenarioModalComponent>,
    private authService: AuthService,
    private matrixViewDataService: MatrixViewDataService,
    private messageService: MessageService,
    private contextService: ContextService,
    private granularityService: GranularityService,
    private governanceService: GovernanceService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
    this.market = this.currentUser!.permissions.getSelectedMarket();
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.getProductHierarchy();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * GetProductHierarchy
   */
  getProductHierarchy(): void {
    this.isRequestProcessing = true;
    this.isReleaseButtonDisabled = true;
    this.granularityService.getProductHierarchy().subscribe({
      error: (error): void => {
        this.messageService.showError(error.reason);
        this.isRequestProcessing = false;
      },
      next: (brands: { BMW: IProductHierarchy[]; MINI: IProductHierarchy[] }): void => {
        this.brands.BMW.data = brands.BMW as IProductHierarchyExtension[];
        this.brands.MINI.data = brands.MINI as IProductHierarchyExtension[];
        this.getApprovalStatus();
      },
    });
  }

  /**
   * getApprovalStatus
   */
  getApprovalStatus(): void {
    const scenarioId: string = this.contextService.scenarioId;
    const isRelease: boolean = this.data.type === ApprovalModalType.RELEASE;
    const phIds: string[] = [
      ...this.brands.BMW.data.map((series: IProductHierarchyExtension) => series.pathId.toString()),
      ...this.brands.MINI.data.map((series: IProductHierarchyExtension) => series.pathId.toString()),
    ];
    const subscription: Subscription = this.governanceService
      .getApprovalStatusBySeries(scenarioId, phIds, isRelease)
      .subscribe({
        next: (data: IApprovalStatusBySeriesResponse[]): void => {
          this.getBrands().forEach((brand: string): void => {
            this.brands[brand as BrandsApproveModalKey].data = this.brands[brand as BrandsApproveModalKey].data.map(
              (series: IProductHierarchyExtension): IProductHierarchyExtension => {
                const seriesCanApprove: IApprovalStatusBySeriesResponse = data.find(
                  (serie: IApprovalStatusBySeriesResponse): boolean => Number(serie.pathId) === series.pathId
                ) as IApprovalStatusBySeriesResponse;
                return {
                  ...series,
                  isDisabled: seriesCanApprove ? !seriesCanApprove.canApprove : true,
                };
              }
            );
          });
          const userBrands: string[] = this.getBrands();
          this.getErrorMessages(userBrands);
          this.isRequestProcessing = false;
        },
        error: (error): void => {
          this.messageService.showError(error.reason);
          this.isRequestProcessing = false;
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * Get error message string
   */
  getErrorMessages(userBrands: string[]): void {
    userBrands.forEach((brand: string): void => {
      if (
        !this.brands[brand as keyof BrandsApproveModal].data.some(
          (serie: IProductHierarchyExtension) => serie.isDisabled
        )
      ) {
        return;
      }
      if (this.getBrandByKey(brand).data.some((serie: IProductHierarchyExtension) => serie.isDisabled)) {
        this.errorMessage =
          this.data.type === ApprovalModalType.RELEASE
            ? ApprovalErrorMessages.NO_RELEASE_STATUS_PRICE_POINTS
            : ApprovalErrorMessages.NO_APPROVE_STATUS_PRICE_POINTS;
        if (this.getBrandByKey(brand).data.every((serie: IProductHierarchyExtension) => serie.isDisabled)) {
          this.errorMessage =
            this.data.type === ApprovalModalType.RELEASE
              ? ApprovalErrorMessages.RELEASE_NOT_POSSIBLE
              : ApprovalErrorMessages.APPROVE_NOT_POSSIBLE;
        }
      }
    });
  }

  /**
   * GetBrands
   * Get Brands List
   */
  getBrands(): string[] {
    let userBrands: Brand[] = [];
    switch (this.data.type) {
      case ApprovalModalType.APPROVE:
        userBrands = this.currentUser?.permissions.getBrandsFromApproveMainScenario()!;
        break;
      case ApprovalModalType.RELEASE:
        userBrands = this.currentUser?.permissions.getBrandsFromReleaseMainScenario()!;
        break;
    }
    return userBrands;
  }

  /**
   * GetBrandByKey
   * @param brand
   * Get IProductHierarchyExtension of a given Brand
   */
  getBrandByKey(brand: string): { data: IProductHierarchyExtension[]; isChecked: boolean; isExpanded: boolean } {
    return this.brands[brand as BrandsApproveModalKey];
  }

  /**
   * SelectAllSeries
   * @param brand
   * Check or uncheck all series of a given Brand
   */
  selectAllSeries(brand: string): void {
    this.brands[brand as BrandsApproveModalKey].isChecked = !this.brands[brand as BrandsApproveModalKey].isChecked;
    this.brands[brand as BrandsApproveModalKey].data.forEach((series: IProductHierarchyExtension): void => {
      series.isChecked = this.brands[brand as BrandsApproveModalKey].isChecked && !series.isDisabled;
    });
  }

  /**
   * SelectSeries
   * @param series
   * Check or uncheck all series of a given Brand
   */
  selectSeries(series: IProductHierarchyExtension): void {
    series.isChecked = !series.isChecked;
    this.isReleaseButtonDisabled =
      this.brands.BMW.data.every((serie: IProductHierarchyExtension) => !serie.isChecked) &&
      this.brands.MINI.data.every((serie: IProductHierarchyExtension) => !serie.isChecked);
  }

  /**
   * IsAllSeriesSelected
   * @param brand
   */
  isAllSeriesSelected(brand: string): boolean {
    return (
      this.brands[brand as BrandsApproveModalKey].data.filter((item: IProductHierarchyExtension) => item.isChecked)
        .length > 0 &&
      !this.brands[brand as BrandsApproveModalKey].data.every((item: IProductHierarchyExtension) => item.isChecked)
    );
  }

  /**
   * isBrandDisabled
   * @param brand
   */
  isBrandDisabled(brand: string): boolean {
    return this.brands[brand as BrandsApproveModalKey].data.some((serie) => serie.isDisabled);
  }

  /**
   * getPayload
   * @return string[] of PHIds
   */
  getPayload(): string[] {
    return [
      ...this.brands.BMW.data
        .filter((item: IProductHierarchyExtension) => item.isChecked)
        .map((item: IProductHierarchyExtension) => item.pathId.toString()),
      ...this.brands.MINI.data
        .filter((item: IProductHierarchyExtension) => item.isChecked)
        .map((item: IProductHierarchyExtension) => item.pathId.toString()),
    ];
  }

  /**
   * ApproveScenario
   */
  approveScenario(): void {
    const scenarioId: string = this.contextService.scenarioId;
    const seriesPHIds: string[] = this.getPayload();
    if (seriesPHIds.length > 0) {
      this.isReleaseButtonDisabled = true;
      this.isRequestProcessing = true;

      const subscription: Subscription = this.governanceService
        .approveSeriesPricePoints(scenarioId, seriesPHIds, this.data.type)
        .subscribe({
          error: (errorData: IApiErrorResponse): void => {
            this.onApproveError(errorData?.error?.error_user_msg);
            this.isReleaseButtonDisabled = false;
            this.isRequestProcessing = false;
          },
          next: (): void => {
            this.isReleaseButtonDisabled = false;
            this.isRequestProcessing = false;
            this.matrixViewDataService.notifyMatrixViewToReflectPriceChanges();
            this.onApproveSuccess();
            this.dialogRef.close();
          },
        });
      this.subscriptions.push(subscription);
    }
  }

  /**
   * OnApproveSuccess
   */
  onApproveSuccess(): void {
    this.notifierService.showSuccess(`Main scenario approved`);
  }

  /**
   * OnApproveSuccess
   */
  onApproveError(errorMessage: string): void {
    this.notifierService.showError(errorMessage);
  }
}
