import { AuthError } from './enums/auth-error.enum';
import { CrossCheckApiErrors } from './enums/crosscheck-api-error.enum';
import { GovernanceApiError } from './enums/governance-api-error.enum';
import { HttpErrors } from './enums/http-error.enum';
import { IFailure } from './models/failure';
import { MatrixViewColumnsApiError } from './enums/matrix-view-columns-api-error';
import { ScenarioApiError } from './enums/scenario-api-error.enum';
import { SideFiltersApiError } from './enums/side-filters-api-error';

/**
 * REST-API errors
 */
export const UnauthorizedError: IFailure<HttpErrors.UNAUTHORIZED> = {
  reason: 'Unauthorized',
  type: HttpErrors.UNAUTHORIZED,
};

/**
 * Auth API errors
 */
export const FetchUserProfileApiError: IFailure<AuthError.FETCH_USER_PROFILE_FAILED> = {
  reason: `Error fetching current user's profile`,
  type: AuthError.FETCH_USER_PROFILE_FAILED,
};
export const LogoutUserApiError: IFailure<AuthError.LOGOUT_FAILED> = {
  reason: `Logout failed`,
  type: AuthError.LOGOUT_FAILED,
};

/**
 * Scenario API errors
 */
export const FetchScenarioDataApiError: IFailure<ScenarioApiError.FETCH_SCENARIO_DATA_ERROR> = {
  reason: `Error fetching scenario data for matrix view configuration`,
  type: ScenarioApiError.FETCH_SCENARIO_DATA_ERROR,
};
export const MapScenarioDataError: IFailure<ScenarioApiError.MAP_SCENARIO_DATA_ERROR> = {
  reason: 'Something went wrong when mapping crosscheck data',
  type: ScenarioApiError.MAP_SCENARIO_DATA_ERROR,
};
export const CreateScenarioDataError: IFailure<ScenarioApiError.CREATE_NEW_SCENARIO_DATA_ERROR> = {
  reason: 'Something went wrong. Please try again.',
  type: ScenarioApiError.CREATE_NEW_SCENARIO_DATA_ERROR,
};
export const DeleteScenarioDataError: IFailure<ScenarioApiError.DELETE_SCENARIO_DATA_ERROR> = {
  reason: 'Error deleting scenario. Please try again..',
  type: ScenarioApiError.DELETE_SCENARIO_DATA_ERROR,
};
export const FetchScenarioListDataApiError: IFailure<ScenarioApiError.FETCH_SCENARIO_LIST_DATA_ERROR> = {
  reason: 'Error fetching scenario list data',
  type: ScenarioApiError.FETCH_SCENARIO_LIST_DATA_ERROR,
};

/**
 * Governance API errors
 */
export const GovernanceDataError: IFailure<GovernanceApiError.GOVERNANCE_DATA_ERROR> = {
  reason: `Error in data format`,
  type: GovernanceApiError.GOVERNANCE_DATA_ERROR,
};
export const GovernanceUpdateError: IFailure<GovernanceApiError.GOVERNANCE_UPDATE_ERROR> = {
  reason: `Error in update request`,
  type: GovernanceApiError.GOVERNANCE_UPDATE_ERROR,
};
export const GovernanceApproveError: IFailure<GovernanceApiError.GOVERNANCE_APPROVE_ERROR> = {
  reason: `Error in approve request`,
  type: GovernanceApiError.GOVERNANCE_APPROVE_ERROR,
};
export const GovernanceApprovePossibleError: IFailure<GovernanceApiError.GOVERNANCE_APPROVE_ERROR> = {
  reason: `Error in approve possible request`,
  type: GovernanceApiError.GOVERNANCE_APPROVE_ERROR,
};

/**
 * Crosschecks errors
 */
export const FetchCrosscheckDataApiError: IFailure<CrossCheckApiErrors.FETCH_CROSSCHECK_DATA_ERROR> = {
  reason: `Error fetching crosscheck data for matrix view`,
  type: CrossCheckApiErrors.FETCH_CROSSCHECK_DATA_ERROR,
};
export const MapCrosscheckDataError: IFailure<CrossCheckApiErrors.MAP_CROSSCHECK_DATA_ERROR> = {
  reason: 'Something went wrong when mapping crosscheck data',
  type: CrossCheckApiErrors.MAP_CROSSCHECK_DATA_ERROR,
};

/**
 * Granularity errors
 */
export const FetchGranularityDataApiError: IFailure<ScenarioApiError.FETCH_GRANULARITY_DATA_ERROR> = {
  reason: `Error fetching granularity data for matrix view configuration`,
  type: ScenarioApiError.FETCH_GRANULARITY_DATA_ERROR,
};
export const MapGranularityDataError: IFailure<ScenarioApiError.MAP_GRANULARITY_DATA_ERROR> = {
  reason: 'Something went wrong when mapping granularity data',
  type: ScenarioApiError.MAP_GRANULARITY_DATA_ERROR,
};

export const FetchFilterDataApiError: IFailure<SideFiltersApiError.FETCH_FILTER_DATA_ERROR> = {
  reason: `Error fetching filter data for matrix view configuration`,
  type: SideFiltersApiError.FETCH_FILTER_DATA_ERROR,
};

export const EmptyFilterDataError: IFailure<SideFiltersApiError.FETCH_FILTER_DATA_ERROR> = {
  reason: `Filters data not found.`,
  type: SideFiltersApiError.FETCH_FILTER_DATA_ERROR,
};

export const MapFilterDataError: IFailure<SideFiltersApiError.MAP_FILTER_DATA_ERROR> = {
  reason: 'Something went wrong when mapping granularity data',
  type: SideFiltersApiError.MAP_FILTER_DATA_ERROR,
};

/**
 * Matrix view column errors
 */

export const FetchMatrixViewColumnsDataApiError: IFailure<MatrixViewColumnsApiError.FETCH_MATRIX_VIEW_COLUMNS_DATA_ERROR> =
  {
    reason: `Error fetching matrix view columns data for matrix view configuration`,
    type: MatrixViewColumnsApiError.FETCH_MATRIX_VIEW_COLUMNS_DATA_ERROR,
  };

export const EmptyMatrixViewColumnsDataError: IFailure<MatrixViewColumnsApiError.FETCH_MATRIX_VIEW_COLUMNS_DATA_ERROR> =
  {
    reason: `Matrix view columns data not found.`,
    type: MatrixViewColumnsApiError.FETCH_MATRIX_VIEW_COLUMNS_DATA_ERROR,
  };

export const MapMatrixViewColumnsDataError: IFailure<MatrixViewColumnsApiError.MAP_MATRIX_VIEW_COLUMNS_DATA_ERROR> = {
  reason: 'Something went wrong when mapping matrix view columns data',
  type: MatrixViewColumnsApiError.MAP_MATRIX_VIEW_COLUMNS_DATA_ERROR,
};
