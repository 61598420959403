import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-ftd-toggle-filters',
  styleUrls: ['./ftd-toggle-filters.component.scss'],
  templateUrl: './ftd-toggle-filters.component.html',
})
export class FtdToggleFiltersComponent {
  public isFiltersHidden: boolean = false;
  @Output() toggleFiltersClickEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  public toggleFiltersClick(open: MouseEvent): void {
    this.isFiltersHidden = !this.isFiltersHidden;
    this.toggleFiltersClickEvent.emit(open);
  }
}
