<ng-container *ngIf="indicator > 0; else nodata">
  <div class="prediction-evaluation-indicator-container">
    <div class="prediction-evaluation-indicator">
      <div class="prediction-evaluation-indicator-value prediction-evaluation-indicator-background"></div>
      <div [class]="'prediction-evaluation-indicator-value-' + indicator"></div>
      <span class="prediction-evaluation-indicator-description">
        {{ predictionEvaluationIndicatorDescriptionMap.get(indicator) }}
      </span>
    </div>
  </div>
</ng-container>
<ng-template #nodata>
  <span class="prediction-evaluation-indicator-na"> N/A </span>
</ng-template>
