import { ActivatedRoute, Data } from '@angular/router';
import { Base64Utils } from '../../../../common/utils/base64.utils';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../../../../matrix-view/services/context/context.service';
import { CrossCheckContextType } from '../../../../graphql/services/gql-api.service';
import { CrossCheckContextTypeMap, CrosschecksContext } from '../../../enums/crosschecks-context.enum';
import { CrosschecksService } from 'src/app/crosschecks/services/crosschecks/crosschecks.service';
import {
  CrosschecksTrafficLightUIProps,
  IGranularityDto,
  IPortfolioTooltips,
  ITooltipDataResponse,
} from 'src/app/matrix-view/models/api.model';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { TrafficLightsArrow, TrafficLightsColor } from 'src/app/crosschecks/types/traffic-lights.type';

@Component({
  selector: 'app-portfolio-page',
  styleUrls: ['./portfolio-page.component.scss'],
  templateUrl: './portfolio-page.component.html',
})
export class PortfolioPageComponent implements OnInit, OnDestroy {
  selectedTab: number = 0;
  context: CrosschecksContext = CrosschecksContext.CURRENT;
  tooltips?: IPortfolioTooltips;
  private subscriptions: Subscription[] = [];
  private portfolioTabs: string[] = [
    'internal-price-ladder',
    'price-distribution',
    'internal-lease-rate-ladder',
    'lease-rate-distribution',
  ];

  isOverviewRequestLoaded: boolean = false;
  internalPriceLadderPercentage: string = '';
  internalPriceLadderStatus: TrafficLightsArrow = 'keep';
  crosscheckIndication: TrafficLightsColor = 'notAvailable';
  crosscheckIndicationForecasted: TrafficLightsColor = 'notAvailable';

  trafficLightInternalPriceAndLeaseRateLadder?: CrosschecksTrafficLightUIProps;

  _filters: string = '';
  @Input() set filters(value: string) {
    this._filters = this.getParamMapId;
    this.loadTrafficLights();
    this.loadTooltips();
    this.loadTrafficLightsOverview();
  }

  get filters(): string {
    return this._filters;
  }

  /**
   * Constructor
   * @param route
   * @param location
   * @param crosscheckService
   * @param contextService
   */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private crosscheckService: CrosschecksService,
    private contextService: ContextService
  ) {
    const routeSubscription: Subscription = this.route.data.subscribe((data: Data): void => {
      this.selectedTab = data.index;
      this.context = data.context;
    });

    this.subscriptions.push(routeSubscription);
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.filters = this.getParamMapId;
    this.isOverviewRequestLoaded = true;
    this.internalPriceLadderPercentage = '100';
    this.internalPriceLadderStatus = 'keep';
    this.loadTooltips();
    this.loadTrafficLights();
    this.loadTrafficLightsOverview();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * ChangeTab
   * @param changeEvent
   */
  changeTab(changeEvent: number): void {
    const changedTabURL: string = `/crosschecks/${this.context}/portfolio/${this.portfolioTabs[changeEvent]}/${this.filters}`;
    this.selectedTab = changeEvent;
    this.location.go(changedTabURL);
  }

  /**
   * IsSelectedTabInternalPriceLadder
   * @return boolean
   */
  get isSelectedTabInternalPriceLadder(): boolean {
    return this.selectedTab === 0;
  }

  /**
   * IsSelectedTabPriceDistribution
   * @return boolean
   */
  get isSelectedTabPriceDistribution(): boolean {
    return this.selectedTab === 1;
  }

  /**
   * IsSelectedTabInternalLeaseRateLadder
   * @return boolean
   */
  get isSelectedTabInternalLeaseRateLadder(): boolean {
    return this.selectedTab === 2;
  }

  /**
   * IsSelectedTabLeaseRateDistribution
   * @return boolean
   */
  get isSelectedTabLeaseRateDistribution(): boolean {
    return this.selectedTab === 3;
  }

  /**
   * GetParamMapId
   * @private
   * @return string
   */
  private get getParamMapId(): string {
    return this.route.snapshot.paramMap.get('id') ?? '';
  }

  /**
   * LoadTooltips
   * @private
   */
  private loadTooltips(): void {
    const subscription: Subscription = this.crosscheckService.getTooltipData('DE', 'portfolio').subscribe({
      next: (response: ITooltipDataResponse): void => {
        this.tooltips = response as IPortfolioTooltips;
      },
    });
    this.subscriptions.push(subscription);
  }

  private loadTrafficLights(): void {
    const granularity: IGranularityDto = Base64Utils.decodeAtobToJson(this.filters);
    if (granularity.id) {
      const trafficLightsSubscription: Subscription = this.crosscheckService
        .getPortfolioTrafficLights(this.contextService.scenarioId, granularity.id, granularity.market)
        .subscribe((trafficLights) => {
          this.crosscheckIndication = trafficLights.trafficLight.color;
          this.crosscheckIndicationForecasted = trafficLights.trafficLightAdj.color;
        });
      this.subscriptions.push(trafficLightsSubscription);
    }
  }

  private loadTrafficLightsOverview(): void {
    const granularity: IGranularityDto = Base64Utils.decodeAtobToJson(this.filters);
    if (granularity.id) {
      const trafficLightsSubscription: Subscription = this.crosscheckService
        .getPortfolioTrafficLightsOverview(
          this.contextService.scenarioId,
          granularity.id,
          granularity.market,
          CrossCheckContextTypeMap.get(this.context) as CrossCheckContextType
        )
        .subscribe((response) => {
          this.trafficLightInternalPriceAndLeaseRateLadder = response;
        });
      this.subscriptions.push(trafficLightsSubscription);
    }
  }
}
