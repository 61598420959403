import { AuthService } from '../../../auth/services/auth.service';
import { CacheFiltersService } from '../../../common/services/cache-filters/cache-filters.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { DiscountManagementService } from '../../services/discount-management.service';
import { IDiscount } from '../../../graphql/services/gql-api.service';
import { IDiscountsConfig, SUMMARY_DISCOUNTS_CONFIG } from '../../configs/discounts.config';
import { IGranularity } from '../../../matrix-view/models/app.model';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';

@Component({
  selector: 'app-discounts-summary-page',
  styleUrls: ['./discounts-summary.component.scss'],
  templateUrl: './discounts-summary.component.html',
})

/**
 * @class DiscountsSummaryComponent
 */
export class DiscountsSummaryComponent implements OnInit, OnDestroy {
  protected readonly SUMMARY_DISCOUNTS_CONFIG: IDiscountsConfig = SUMMARY_DISCOUNTS_CONFIG;
  private subscriptions: Subscription[] = [];
  discountSummaryData: IDiscount[] = [];
  isRequestProcessing: boolean = true;
  isFilterApplied: boolean = true;
  hasError: boolean = false;
  currentUser: User | null;

  /**
   * @constructor
   * @param discountManagementService
   * @param authService
   * @param cacheFiltersService
   */
  constructor(
    private readonly discountManagementService: DiscountManagementService,
    private authService: AuthService,
    private cacheFiltersService: CacheFiltersService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.cacheFiltersService.initDiscountsFilters();
    if (!this.cacheFiltersService.getDiscountsFilters()) {
      this.isFilterApplied = false;
      this.isRequestProcessing = false;
    }
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  onClickApplyFilters(payload: { brand: IGranularity[]; market: string }): void {
    this.isRequestProcessing = true;
    this.discountSummaryData = [];
    this.hasError = false;
    const filters = this.cacheFiltersService.getDiscountsFilters();

    if (filters?.brand!.length) {
      if (payload.brand.length > 0 || filters?.brand!.length) {
        this.isFilterApplied = true;
        const subscription: Subscription = this.discountManagementService
          .getDiscounts({
            brands: payload!.brand.map((x) => x.brand),
            market: payload!.market,
          })
          .subscribe(
            (data: IDiscount[]): void => {
              this.discountSummaryData = data;
              this.isRequestProcessing = false;
            },
            () => (this.hasError = true)
          );
        this.subscriptions.push(subscription);
      } else {
        this.isFilterApplied = false;
        this.discountSummaryData = [];
      }
    } else {
      this.isFilterApplied = false;
      this.discountSummaryData = [];
    }
  }

  emitMarketFiltersChanged(payload?: { brand: IGranularity[]; market: string }): void {
    this.isFilterApplied = false;
    this.hasError = false;
    this.discountSummaryData = [];

    if (payload && payload.brand.length > 0) {
      const subscription: Subscription = this.discountManagementService
        .getDiscounts({
          brands: payload.brand.map((x) => x.brand),
          market: payload.market,
        })
        .subscribe(
          (data: IDiscount[]): void => {
            this.discountSummaryData = data;
          },
          (): boolean => (this.hasError = true)
        );
      this.subscriptions.push(subscription);
    } else {
      this.isFilterApplied = false;
      this.discountSummaryData = [];
    }
  }
}
