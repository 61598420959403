import { IFtdDropdownOption } from 'src/app/common/models/ftd-dropdown-option.model';
import { PlanningHorizonValue } from '../enums/planning-horizon-value.enum';
import { RedCrosscheckValue } from '../enums/red-crosscheck-value.enum';

export const PLANNING_HORIZON_OPTIONS: IFtdDropdownOption<PlanningHorizonValue>[] = [
  {
    id: 0,
    label: 'Next Month',
    value: PlanningHorizonValue.NEXT_MONTH,
  },
  {
    id: 1,
    label: 'Next Year',
    value: PlanningHorizonValue.NEXT_YEAR,
  },
  {
    id: 2,
    label: 'MTD',
    value: PlanningHorizonValue.MTD,
  },
  {
    id: 3,
    label: 'YTD',
    value: PlanningHorizonValue.YTD,
  },
  {
    id: 4,
    label: 'Last Year',
    value: PlanningHorizonValue.LAST_YEAR,
  },
  {
    id: 5,
    label: 'Last Month',
    value: PlanningHorizonValue.LAST_MONTH,
  },
  {
    id: 6,
    label: 'Full Month',
    value: PlanningHorizonValue.FULL_MONTH,
  },
  {
    id: 7,
    label: 'Full Year',
    value: PlanningHorizonValue.FULL_YEAR,
  },
  {
    id: 8,
    label: 'YE',
    value: PlanningHorizonValue.YE,
  },
];

export const RED_CROSSCHECKS_OPTIONS: IFtdDropdownOption<RedCrosscheckValue>[] = [
  {
    id: 0,
    label: '>1',
    value: RedCrosscheckValue.GREATER_ONE,
  },
  {
    id: 1,
    label: '>2',
    value: RedCrosscheckValue.GREATER_TWO,
  },
  {
    id: 2,
    label: '>3',
    value: RedCrosscheckValue.GREATER_THREE,
  },
  {
    id: 3,
    label: '>4',
    value: RedCrosscheckValue.GREATER_FOUR,
  },
  {
    id: 4,
    label: '>5',
    value: RedCrosscheckValue.GREATER_FIVE,
  },
];

export const PRICE_EDITOR_FILLED_OPTIONS: IFtdDropdownOption<boolean>[] = [
  {
    id: 0,
    label: 'Not filled',
    value: false,
  },
  {
    id: 1,
    label: 'Filled',
    value: true,
  },
];
