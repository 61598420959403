<div class="ftd-modal">
  <div class="dialog-title-box">
    <div class="dialog-title-text">
      <mat-icon *ngIf="data.icon?.icon" [style.color]="data.icon?.iconColor">{{ data.icon?.icon }}</mat-icon>
      <h2>{{ data.title }}</h2>
    </div>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div mat-dialog-content *ngIf="data.content">
    {{ data.content }}
  </div>
  <div mat-dialog-actions *ngIf="buttonsAreaActivated">
    <app-ftd-button></app-ftd-button>
    <app-ftd-button></app-ftd-button>
  </div>
</div>
