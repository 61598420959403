import { Apollo } from 'apollo-angular';
import { CommentsAndNotesContext } from '../../models/comments-and-notes.model';
import { IAddCommentGQL, IAddNoteGQL, INote } from '../../../graphql/services/gql-api.service';
import { Injectable } from '@angular/core';
import { MutationResult } from 'apollo-angular/types';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class CommentsAndNotesService {
  /**
   * @constructor
   * @param addCommentGQL
   * @param addNoteGQL
   * @param apollo
   */
  constructor(private addCommentGQL: IAddCommentGQL, private addNoteGQL: IAddNoteGQL, private apollo: Apollo) {}

  /**
   * PostComment
   * @param filterId
   * @param text
   * @param userId
   * @param userName
   * @return Observable<MutationResult<Comment>>
   * @private
   */
  private postComment(
    filterId: string,
    text: string,
    userId: string,
    userName: string,
    pricePointId: string
  ): Observable<Comment> {
    return this.addCommentGQL
      .mutate({
        filterId,
        pricePointId,
        text,
      })
      .pipe(
        map((response: any) => {
          return response.data.addComment;
        }),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error);
          }
        })
      );
  }

  /**
   * PostNote
   * @param filterId
   * @param scenarioId
   * @param text
   * @param userId
   * @param userName
   * @param pricePointId
   * @return Observable<MutationResult<Note>>
   * @private
   */
  private postNote(
    filterId: string,
    scenarioId: string,
    text: string,
    userId: string,
    userName: string,
    pricePointId: string
  ): Observable<INote> {
    return this.addNoteGQL
      .mutate({
        filterId,
        pricePointId,
        scenarioId,
        text,
      })
      .pipe(
        map((response: any) => {
          return response.data.addNote;
        }),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error);
          }
        })
      );
  }

  /**
   * PostCommentsNotes
   * @return @Observable<MutationResult<any>>
   * @param context
   * @param filterId
   * @param scenarioId
   * @param text
   * @param userId
   * @param userName
   * @param pricePointId
   * @return Observable<MutationResult<Comment>> | Observable<MutationResult<Note>>
   */
  postCommentsNotes(
    context: CommentsAndNotesContext,
    scenarioId: string,
    filterId: string,
    text: string,
    userId: string,
    userName: string,
    pricePointId: string
  ): Observable<MutationResult<Comment>> | Observable<MutationResult<INote>> | Observable<any> {
    return context === CommentsAndNotesContext.COMMENTS
      ? this.postComment(filterId, text, userId, userName, pricePointId)
      : this.postNote(filterId, scenarioId, text, userId, userName, pricePointId);
  }
}
