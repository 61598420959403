<mat-toolbar class="discounts-header">
  <div class="title-container-return-button-container">
    <div class="return-button-container">
      <app-ftd-breadcrumbs></app-ftd-breadcrumbs>
    </div>

    <div class="discounts-header-wrapper">
      <div class="title-container">
        <h2 class="title-text">{{ title }}</h2>

        <app-ftd-chip
          *ngIf="!isStatusHidden"
          [chipText]="chipStyle!.chipText"
          [chipColor]="chipStyle!.backgroundColor"
          [chipTextColor]="chipStyle!.textColor"
          [enableRemoveButton]="false"
          class="chip-location"
        ></app-ftd-chip>
      </div>

      <div class="discounts-header-buttons-wrapper">
        <app-ftd-button
          *ngIf="!isDraftsHidden && currentUser?.permissions?.getHasPermissionToCreateDiscount()"
          data-test="btn-drafts"
          class="ml-2"
          [fillWidth]="true"
          label="Drafts"
          ariaText="Drafts"
          icon="folder_open"
          hierarchy="secondary"
          size="S"
          (click)="openDiscountDraftsModal()"
        ></app-ftd-button>
        <app-ftd-button
          *ngIf="!isCreateDiscountHidden && currentUser?.permissions?.getHasPermissionToCreateDiscount()"
          data-test="btn-create-discount"
          class="ml-2"
          [fillWidth]="true"
          label="Create Discount"
          ariaText="Create Discount"
          icon="add"
          hierarchy="primary"
          size="S"
          (btnClick)="openCreateDiscountModal()"
        ></app-ftd-button>

        <app-ftd-button
          *ngIf="!isSaveHidden && currentUser?.permissions?.getHasPermissionToCreateDiscount()"
          class="ml-2"
          [disabled]="isSaveDisabled"
          [matTooltip]="isSaveDisabledTooltip"
          icon="save"
          [label]="'Save'"
          ariaText="Save Discount"
          hierarchy="secondary"
          size="S"
          [isLoading]="isSaveLoading"
          (btnClick)="save()"
          [attr.data-test]="'save-scenario-btn'"
        >
        </app-ftd-button>

        <app-ftd-button
          *ngIf="!isSubmitHidden && currentUser?.permissions?.getHasPermissionToCreateDiscount()"
          label="Submit"
          class="ml-2"
          icon="task_alt"
          hierarchy="green"
          size="S"
          (btnClick)="openSubmitScenarioModal()"
          [attr.data-test]="'submit-discount-btn'"
        ></app-ftd-button>

        <app-ftd-button
          *ngIf="!isWithdrawHidden && currentUser?.permissions?.getHasPermissionToWithdrawDiscount()"
          class="ml-2"
          [id]="'withdraw-btn'"
          icon="replay"
          label="Withdraw"
          ariaText="Withdraw Discount"
          hierarchy="primary"
          size="S"
        ></app-ftd-button>

        <app-ftd-button
          *ngIf="!isRejectHidden && currentUser?.permissions?.getHasPermissionToRejectDiscount()"
          class="ml-2"
          [disabled]="false"
          [matTooltip]="''"
          icon="close"
          [label]="'Reject'"
          ariaText="Reject Discount"
          hierarchy="red"
          size="S"
          [isLoading]="false"
        >
        </app-ftd-button>

        <app-ftd-button
          *ngIf="
            !isApproveHidden &&
            (currentUser?.permissions?.getHasPermissionToApproveBy1Discount() ||
              currentUser?.permissions?.getHasPermissionToApproveBy2Discount())
          "
          class="ml-2"
          [id]="'approve-btn'"
          icon="task_alt"
          label="Approve"
          ariaText="Approve Discount"
          hierarchy="green"
          size="S"
        ></app-ftd-button>

        <app-ftd-button
          *ngIf="!isReleaseHidden && currentUser?.permissions?.getHasPermissionToReleaseDiscount()"
          class="ml-2"
          [id]="'release-btn'"
          icon="open_in_new"
          label="Release"
          ariaText="Release Discount"
          hierarchy="primary"
          size="S"
        ></app-ftd-button>
      </div>
    </div>
  </div>
</mat-toolbar>
