<ftd-tab-group [selectedIndex]="selectedTab" (tabIndexChange)="changeTab($event)">
  <ftd-tab [disabled]="true">
    <ftd-tab-label tabLabel [title]="'Lease Returns'" [info]="true" [infoText]="tooltips?.leaseReturns"></ftd-tab-label>
    <app-lease-returns
      [granularity]="filters"
      [crosscheckIndication]="crosscheckIndication"
      [crosscheckIndicationForecasted]="crosscheckIndicationForecasted"
      *ngIf="isSelectedTabLeaseReturns"
    ></app-lease-returns>
  </ftd-tab>
  <ftd-tab>
    <ftd-tab-label
      tabLabel
      [title]="'Order Intake'"
      [percentage]="trafficLightOrderIntakeOverview?.weight || ''"
      [trafficLights]="!!trafficLightOrderIntakeOverview"
      [trafficLightColor]="trafficLightOrderIntakeOverview?.color"
      [info]="true"
      [infoText]="tooltips?.orderIntake"
    ></ftd-tab-label>
    <app-order-intake
      [granularity]="filters"
      [crosscheckIndication]="crosscheckIndication"
      [crosscheckIndicationForecasted]="crosscheckIndicationForecasted"
      *ngIf="isSelectedTabOrderIntake"
    ></app-order-intake>
  </ftd-tab>
  <ftd-tab [disabled]="true">
    <ftd-tab-label
      tabLabel
      [title]="'Calendarized Order Bank'"
      [info]="true"
      [infoText]="tooltips?.calendarizedOrderBank"
    ></ftd-tab-label>
    <app-calendarized-order-bank
      [granularity]="filters"
      *ngIf="isSelectedTabCalendarizedOrderBank"
      [crosscheckIndication]="crosscheckIndication"
      [crosscheckIndicationForecasted]="crosscheckIndicationForecasted"
    ></app-calendarized-order-bank>
  </ftd-tab>
  <ftd-tab>
    <ftd-tab-label
      tabLabel
      [title]="'Retail'"
      [percentage]="trafficLightRetailOverview?.weight || ''"
      [trafficLights]="!!trafficLightRetailOverview"
      [trafficLightColor]="trafficLightRetailOverview?.color"
      [info]="true"
      [infoText]="tooltips?.retailSales"
    ></ftd-tab-label>
    <app-retail-sales
      [granularity]="filters"
      [crosscheckIndication]="crosscheckIndication"
      [crosscheckIndicationForecasted]="crosscheckIndicationForecasted"
      *ngIf="isSelectedTabRetailSales"
    ></app-retail-sales>
  </ftd-tab>
  <ftd-tab [disabled]="true">
    <ftd-tab-label
      tabLabel
      [title]="'Sales Opportunities'"
      [info]="true"
      [infoText]="tooltips?.salesOpportunities"
    ></ftd-tab-label>
  </ftd-tab>
</ftd-tab-group>
