import { GranularityLevel } from '../../enums/granularity-level.enum';
import { GranularityPropName } from '../../enums/granularity-prop-name.enum';
import { GranularityType } from '../../enums/granularity-type.enum';
import { IGranularity } from '../../models/app.model';
import { IGranularityDto } from '../../models/api.model';
import { IProductHierarchy } from '../../../../../graphql/services/gql-api.service';

export enum Level {
  MARKET = 'market',
  BRAND = 'brand',
  SEGMENT = 'segment',
  SERIES = 'series',
  E_SERIES = 'eSeries',
  MODEL = 'model',
  MODEL_CODE = 'modelCode',
}

/**
 * @class GranularityUtils
 * Utils for checking the granularity-type & handling {@link IGranularityDto}
 */
export class GranularityUtils {
  public static ROOT_ROW_VALUE: string = 'all'.toLowerCase();

  /**
   * IsBrand
   * @param granularity
   * @return boolean
   */
  static isBrand(granularity: IGranularityDto): boolean {
    return granularity.level === Level.BRAND;
  }

  /**
   * IsSegment
   * @param granularity
   * @return boolean
   */
  static isSegment(granularity: IGranularityDto): boolean {
    return granularity.level === Level.SEGMENT;
  }

  /**
   * IsSeries
   * @param granularity
   * @return boolean
   */
  static isSeries(granularity: IGranularityDto): boolean {
    return granularity.level === Level.SERIES;
  }

  /**
   * IsEseries
   * @param granularity
   * @return boolean
   */
  static isEseries(granularity: IGranularityDto): boolean {
    return granularity.level === Level.E_SERIES;
  }

  /**
   * IsModel
   * @param granularity
   * @return boolean
   */
  static isModel(granularity: IGranularityDto): boolean {
    return granularity.level === Level.MODEL;
  }

  /**
   * IsModelCode
   * @param granularity
   * @return boolean
   */
  static isModelCode(granularity: IGranularityDto): boolean {
    return granularity.level === Level.MODEL_CODE;
  }

  /**
   * GetGranularityType
   * @param granularity
   * @return GranularityType
   */
  static getGranularityType(granularity: IGranularityDto): GranularityType {
    if (this.isBrand(granularity)) {
      return GranularityType.BRAND;
    } else if (this.isSegment(granularity)) {
      return GranularityType.SEGMENT;
    } else if (this.isSeries(granularity)) {
      return GranularityType.SERIES;
    } else if (this.isEseries(granularity)) {
      return GranularityType.E_SERIES;
    } else if (this.isModel(granularity)) {
      return GranularityType.MODEL;
    } else if (this.isModelCode(granularity)) {
      return GranularityType.MODEL_CODE;
    }
    return GranularityType.UNKNOWN;
  }

  /**
   * GetChildrenGranularityType
   * @param granularity
   * @return GranularityType | undefined
   */
  static getChildrenGranularityType(granularity: IGranularity): GranularityType | undefined {
    return this.getChildrenGranularityTypeFromType(granularity.type as any);
  }

  /**
   * GetChildrenGranularityTypeFromType
   * @param granularityType
   * @return GranularityType | undefined
   */
  static getChildrenGranularityTypeFromType(granularityType: GranularityType): GranularityType | undefined {
    const parentToChildGranularityTypeMap: Map<GranularityType, GranularityType> = new Map<
      GranularityType,
      GranularityType
    >([
      [GranularityType.BRAND, GranularityType.SERIES],
      [GranularityType.SERIES, GranularityType.E_SERIES],
      [GranularityType.E_SERIES, GranularityType.MODEL],
      [GranularityType.ESERIES, GranularityType.MODEL],
      [GranularityType.MODEL, GranularityType.MODEL_CODE],
      [GranularityType.MODEL_CODE, GranularityType.UNKNOWN],
    ]);
    return parentToChildGranularityTypeMap.get(granularityType);
  }

  /**
   * GetGranularityDisplayName
   * @param granularity
   * @return string
   */
  static getGranularityDisplayName(granularity: IGranularity): string {
    const granularityDisplayNameMap: Map<GranularityType, string> = new Map<GranularityType, string>([
      [GranularityType.BRAND, granularity?.brand],
      [GranularityType.SEGMENT, granularity?.segment],
      [GranularityType.SERIES, granularity?.series],
      [GranularityType.E_SERIES, granularity?.eSeries],
      [GranularityType.ESERIES, granularity?.eSeries],
      [GranularityType.MODEL, granularity?.model],
      [GranularityType.MODEL_CODE, granularity?.modelCode || ''],
    ]);
    return granularityDisplayNameMap.get(granularity.type as any) || '';
  }

  /**
   * GetGranularityPropertyName
   * @param granularityType
   * @return string
   */
  static getGranularityPropertyName(granularityType: GranularityType): string {
    const granularityPropertyName: Map<GranularityType, string> = new Map<GranularityType, string>([
      [GranularityType.BRAND, GranularityPropName.BRAND],
      [GranularityType.SERIES, GranularityPropName.SERIES],
      [GranularityType.E_SERIES, GranularityPropName.E_SERIES],
      [GranularityType.ESERIES, GranularityPropName.E_SERIES],
      [GranularityType.MODEL, GranularityPropName.MODEL],
      [GranularityType.MODEL_CODE, GranularityPropName.MODEL_CODE],
      [GranularityType.SEGMENT, GranularityPropName.SEGMENT],
      [GranularityType.POWERTRAIN, GranularityPropName.POWERTRAIN],
    ]);
    return granularityPropertyName.get(granularityType as any) || '';
  }

  /**
   * GetGranularityTypeFromPropertyName
   * @param granularityPropName
   * @return string
   */
  static getGranularityTypeFromPropertyName(granularityPropName: GranularityPropName | string): string {
    const granularityTypeFromPropertyName: Map<GranularityPropName, string> = new Map<GranularityPropName, string>([
      [GranularityPropName.BRAND, GranularityType.BRAND],
      [GranularityPropName.SERIES, GranularityType.SERIES],
      [GranularityPropName.E_SERIES, GranularityType.ESERIES],
      [GranularityPropName.MODEL, GranularityType.MODEL],
      [GranularityPropName.MODEL_CODE, GranularityType.MODEL_CODE],
      [GranularityPropName.SEGMENT, GranularityType.SEGMENT],
      [GranularityPropName.POWERTRAIN, GranularityType.POWERTRAIN],
    ]);
    return granularityTypeFromPropertyName.get(granularityPropName as any) || '';
  }

  /**
   * GetGranularityLevels
   * @return Map<GranularityType, number>
   */
  static getGranularityLevels(): Map<GranularityType, number> {
    const granularityLevelsMap: Map<GranularityType, number> = new Map<GranularityType, number>([
      [GranularityType.BRAND, GranularityLevel.BRAND],
      [GranularityType.SERIES, GranularityLevel.SERIES],
      [GranularityType.E_SERIES, GranularityLevel.E_SERIES],
      [GranularityType.ESERIES, GranularityLevel.ESERIES],
      [GranularityType.MODEL, GranularityLevel.MODEL],
      [GranularityType.MODEL_CODE, GranularityLevel.MODEL_CODE],
    ]);
    return granularityLevelsMap;
  }

  static buildLegacyFilterIdFromProductHierarchy(productHierarchy: IProductHierarchy): string {
    const { market, brand, segment, series, eSeries, model, modelCode, powertrain } = productHierarchy;

    const elements = [
      market || 'ALL',
      brand || 'ALL',
      segment || 'ALL',
      series || 'ALL',
      eSeries || 'ALL',
      model || 'ALL',
      modelCode || 'ALL',
      powertrain || 'ALL',
    ];

    return elements.join('-');
  }

  static findProductHierarchyByLegacyFilterId(
    legacyFilterId: string,
    productHierarchyList: IProductHierarchy[] = []
  ): IProductHierarchy | null {
    for (const item of productHierarchyList) {
      const itemPath = this.buildLegacyFilterIdFromProductHierarchy(item);

      if (itemPath.toLowerCase() === legacyFilterId.toLowerCase()) {
        return item;
      }

      if (item.children && item.children.length > 0) {
        const result = this.findProductHierarchyByLegacyFilterId(legacyFilterId, item.children);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }
}
