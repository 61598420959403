import {
  ISummaryTableColumnCategoryModel,
  ISummaryTableColumnModel,
  SummaryTableColumnType,
} from '../../../models/discounts-summary-table.model';

export const summaryTableColumns: ISummaryTableColumnModel[] = [
  {
    category: 'DISCOUNT_AMOUNT',
    columnType: SummaryTableColumnType.CURRENCY,
    displayName: 'Absolute Discount',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Range of the absolute discount amount before tax',
    },
    name: 'absoluteDiscountNetRange',
    position: 4,
    secondDisplayName: 'Net',
    technicalName: 'absoluteDiscountNetRange',
  },
  {
    category: 'DISCOUNT_AMOUNT',
    columnType: SummaryTableColumnType.CURRENCY,
    displayName: 'Absolute Discount',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Range of the absolute discount amount after tax',
    },
    name: 'absoluteDiscountInclTaxRange',
    position: 5,
    secondDisplayName: 'Incl. Tax',
    technicalName: 'absoluteDiscountInclTaxRange',
  },
  {
    category: 'DISCOUNT_AMOUNT',
    columnType: SummaryTableColumnType.PERCENTAGE,
    displayName: 'Relative Discount',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Range of the relative discount amount',
    },
    name: 'relativeDiscountNetRange',
    position: 6,
    secondDisplayName: '',
    technicalName: 'relativeDiscountNetRange',
  },
  {
    category: 'DISCOUNT_AMOUNT',
    columnType: SummaryTableColumnType.TEXT,
    displayName: 'Benefit Type',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Benefit type of the discount',
    },
    name: 'benefitType',
    position: 7,
    secondDisplayName: '',
    technicalName: 'benefitType',
  },
  {
    category: 'DISCOUNT_CLASSIFICATION',
    columnType: SummaryTableColumnType.TEXT,
    displayName: 'Category',
    isSticky: true,
    isVisible: true,
    metadata: {
      description: 'Classification of the discount matrix into "Special B2C" and "Campaign"',
    },
    name: 'discountCategory',
    position: 1,
    secondDisplayName: '',
    technicalName: 'discountCategory',
  },
  {
    category: 'DISCOUNT_CLASSIFICATION',
    columnType: SummaryTableColumnType.TEXT,
    displayName: 'Type',
    isSticky: true,
    isVisible: true,
    metadata: {
      description: 'Specified discount type',
    },
    name: 'discountType',
    position: 2,
    secondDisplayName: '',
    technicalName: 'discountType',
  },
  {
    category: 'DISCOUNT_CLASSIFICATION',
    columnType: SummaryTableColumnType.TEXT,
    displayName: 'Name',
    isSticky: true,
    isVisible: true,
    metadata: {
      description: 'The discount name is only shown in the DMT system',
    },
    name: 'name',
    position: 3,
    secondDisplayName: '',
    technicalName: 'name',
  },
  {
    category: 'DISCOUNT_VALIDITY',
    columnType: SummaryTableColumnType.DATE,
    displayName: 'Valid as Of',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Period when the discount is active',
    },
    name: 'startDate',
    position: 8,
    secondDisplayName: '',
    technicalName: 'startDate',
  },
  {
    category: 'DISCOUNT_VALIDITY',
    columnType: SummaryTableColumnType.DATE,
    displayName: 'Valid Until',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Period when the discount is active',
    },
    name: 'endDate',
    position: 9,
    secondDisplayName: '',
    technicalName: 'endDate',
  },
  {
    category: 'DISCOUNT_VALIDITY',
    columnType: SummaryTableColumnType.TEXT,
    displayName: 'Sales Channels',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Date until when the discount is active',
    },
    name: 'salesChannels',
    position: 10,
    secondDisplayName: '',
    technicalName: 'salesChannels',
  },
  {
    category: 'IDENTIFICATION',
    columnType: SummaryTableColumnType.TEXT,
    displayName: 'ID',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Unique identifier for the discount matrix',
    },
    name: 'id',
    position: 11,
    secondDisplayName: '',
    technicalName: 'id',
  },
  {
    category: 'ACTIONS',
    columnType: SummaryTableColumnType.STATUS,
    displayName: 'Status',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Status of the respective discount matrix',
    },
    name: 'approvalStatus',
    position: 12,
    secondDisplayName: '',
    technicalName: 'approvalStatus',
  },
  {
    category: 'ACTIONS',
    columnType: SummaryTableColumnType.ACTIONS,
    displayName: 'Notes & Comments',
    isSticky: false,
    isVisible: true,
    metadata: {
      description: 'Access personal notes and public comments',
    },
    name: 'actions',
    position: 13,
    secondDisplayName: '',
    technicalName: 'id',
  },
];

export const summaryTableColumnCategories: ISummaryTableColumnCategoryModel[] = [
  {
    displayName: 'Discount Classification',
    name: 'DISCOUNT_CLASSIFICATION',
    numOfColumns: 3,
  },
  {
    displayName: 'Discount Amount',
    name: 'DISCOUNT_AMOUNT',
    numOfColumns: 4,
  },
  {
    displayName: 'Discount Validity',
    name: 'DISCOUNT_VALIDITY',
    numOfColumns: 3,
  },
  {
    displayName: 'Identification',
    name: 'IDENTIFICATION',
    numOfColumns: 1,
  },
  {
    displayName: 'Actions',
    name: 'ACTIONS',
    numOfColumns: 2,
  },
];
