import { Component, Input } from '@angular/core';
import {
  IIndicativeLeaseRateMetaData,
  IListPriceInclTaxMetaData,
  IRecommendedRetailPriceReasonCodeMetaData,
  IScenarioMetaDataBuilder,
} from '../../../models/app.model';
import { MatrixColumnTechnicalName } from '../../../enums/matrix-column-technical-name.enum';
import { MetaDataTemplates } from '../../../enums/matrix-column-metadata-templates.enum';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-discount-mv-column-metadata',
  styleUrls: ['./mv-column-metadata.component.scss'],
  templateUrl: './mv-column-metadata.component.html',
})
/**
 * @class MvColumnMetadataComponent
 */
export class MvColumnMetadataComponent {
  /**
   * @Input
   * scenarioMetaData
   */
  @Input() scenarioMetaData?: IScenarioMetaDataBuilder;

  /**
   * @Input
   * technicalName
   */
  @Input() technicalName?: MatrixColumnTechnicalName;

  /**
   * @MetaDataTemplates
   * METADATA_TEMPLATES
   */
  METADATA_TEMPLATES = MetaDataTemplates;

  /**
   * AllowedToolTips
   * @private
   */
  private allowedToolTips: string[] = ['listPriceInclTax', 'indicativeLeaseRate', 'recommendedRetailPriceReasonCode'];

  /**
   * ScenarioMetaDataToolTip
   */
  get scenarioMetaDataToolTip():
    | IListPriceInclTaxMetaData
    | IIndicativeLeaseRateMetaData
    | IRecommendedRetailPriceReasonCodeMetaData
    | any {
    return this.isMetadataAvailable && this.scenarioMetaData && this.technicalName
      ? (this.scenarioMetaData![this.technicalName! as keyof IScenarioMetaDataBuilder] as
          | IListPriceInclTaxMetaData
          | IIndicativeLeaseRateMetaData
          | IRecommendedRetailPriceReasonCodeMetaData)
      : ({} as IListPriceInclTaxMetaData | IIndicativeLeaseRateMetaData | IRecommendedRetailPriceReasonCodeMetaData);
  }

  /**
   * IsMetadataAvailable
   * @return boolean
   */
  get isMetadataAvailable(): boolean {
    return this.allowedToolTips.includes(this.technicalName || '');
  }

  /**
   * CurrencySymbol
   * @private
   */
  currencySymbol: string = '€';

  /**
   * _currency
   * @private
   */
  private _currency: string = 'EUR';

  /**
   * @Input
   * @get
   * currency
   */
  get currency(): string {
    return this._currency;
  }

  /**
   * @Input
   * @set
   * currency
   */
  @Input() set currency(currency: string) {
    this._currency = currency;
    this.currencySymbol = getCurrencySymbol(this.currency, 'narrow');
  }
}
