import { AuthService } from '../../../auth/services/auth.service';
import {
  CommentsAndNotesContext,
  ICommentSubmissionModel,
  ICommentsAndNotesModel,
} from '../../../matrix-view/models/comments-and-notes.model';
import { CommentsAndNotesService } from '../../../matrix-view/services/comments/comments-and-notes.service';
import { Component, Inject, OnChanges, OnDestroy, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FtdNotifierService } from '../../services/ftd-notifier/ftd-notifier.service';
import { GovernanceService } from '../../../matrix-view/services/governance/governance.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IComment, INote } from '../../../graphql/services/gql-api.service';
import { PricePointApprovalStatus } from '../../../matrix-view/enums/price-point-approval-status.enum';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';
import sortby from 'lodash.sortby';

interface ICommentForm {
  message: FormControl<string>;
}

@Component({
  selector: 'app-ftd-comment',
  styleUrls: ['./ftd-comment.component.scss'],
  templateUrl: './ftd-comment.component.html',
})
export class FtdCommentComponent implements OnDestroy, OnChanges {
  public governancePricePointStatus?: PricePointApprovalStatus;
  public comments: ICommentsAndNotesModel[] = [];
  public model: string = '';
  private filterId: string = '';
  private scenarioId: string = '';
  private subscriptions: Subscription[] = [];
  public currentUser!: User | null;
  public context: CommentsAndNotesContext = CommentsAndNotesContext.COMMENTS;
  protected readonly CommentsAndNotesContext = CommentsAndNotesContext;
  public isRequestProcessing: boolean = false;
  public form: FormGroup = new FormGroup<ICommentForm>({
    message: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  /**
   * @constructor
   * @param data
   * @param dialogRef
   * @param authService
   * @param governanceService
   * @param notifierService
   * @param commentsAndNotesService
   */
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      model: string;
      filterId: string;
      scenarioId: string;
      comments: ICommentsAndNotesModel[];
      context: CommentsAndNotesContext;
      governancePricePointStatus: PricePointApprovalStatus;
      pricePointId: string;
    },
    private dialogRef: MatDialogRef<FtdCommentComponent>,
    public authService: AuthService,
    public governanceService: GovernanceService,
    private notifierService: FtdNotifierService,
    private commentsAndNotesService: CommentsAndNotesService
  ) {
    this.setInitialProps();
  }

  /**
   * SetInitialProps
   */
  setInitialProps(): void {
    this.currentUser = this.authService.getLoggedInUser();
    this.model = this.data.model;
    this.scenarioId = this.data.scenarioId;
    this.filterId = this.data.filterId;
    this.context = this.data.context;
    this.comments = sortby(this.data.comments, ['createdAt'], ['desc']);
    this.governancePricePointStatus = this.data.governancePricePointStatus;
    if (this.isLastStep(this.data.governancePricePointStatus)) {
      this.form.get('message')?.disable();
    }
  }

  /**
   * NgOnChanges
   */
  ngOnChanges(): void {
    this.setInitialProps();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * OnSubmit
   */
  onSubmit(): void {
    if (this.form.valid) {
      this.submitPricePointComment();
    }
  }

  /**
   * GetCommentPayloadGQL
   * @param message
   * @return CommentSubmissionModel
   */
  getCommentPayloadGQL(message: string = this.form.get('message')?.value): ICommentSubmissionModel {
    return {
      filterId: this.data.filterId,
      pricePointId: this.data.pricePointId,
      text: message,
      userId: this.currentUser!.sub,
      userName: this.currentUser!.getUserName(),
    };
  }

  /**
   * SubmitPricePointComment
   */
  submitPricePointComment(): void {
    this.isRequestProcessing = true;

    const payload: ICommentSubmissionModel = this.getCommentPayloadGQL();

    const subscription: Subscription = this.commentsAndNotesService
      .postCommentsNotes(
        this.context,
        this.scenarioId,
        payload.filterId,
        payload.text,
        payload.userId,
        payload.userName,
        payload.pricePointId
      )
      .subscribe({
        error: (): void => {
          this.isRequestProcessing = false;
        },
        next: (result: IComment | INote): void => {
          const newComment: ICommentsAndNotesModel = {
            createdAt: result.createdAt,
            filterId: payload.filterId,
            id: result.id,
            text: payload.text,
            userId: payload.userId,
            userName: payload.userName,
          };
          this.comments = [...this.comments, newComment];
          this.form.reset();
          this.isRequestProcessing = false;
          this.onSubmitPricePointCommentSuccess();
        },
      });

    this.subscriptions.push(subscription);
  }

  /**
   * OnSubmitPricePointCommentSuccess
   */
  onSubmitPricePointCommentSuccess(): void {
    this.notifierService.showSuccess(
      this.context === CommentsAndNotesContext.COMMENTS ? `Comment saved` : `Note saved`
    );
  }

  getTooltipText(status?: PricePointApprovalStatus): string {
    return this.selectText(this.context, status);
  }

  isTooltipDisabled(status?: PricePointApprovalStatus): boolean {
    return !(this.isRequestProcessing || this.form.invalid || status === 'approved_by_2' || status === 'exported');
  }

  isButtonDisabled(status?: PricePointApprovalStatus): boolean {
    return this.isRequestProcessing || this.form.invalid || status === 'approved_by_2' || status === 'exported';
  }

  private isLastStep(status?: PricePointApprovalStatus) {
    return status === 'approved_by_2' || status === 'exported';
  }

  private selectText(context: CommentsAndNotesContext, status?: PricePointApprovalStatus) {
    if (!this.isLastStep(status)) {
      return 'You must type a message to be able to submit';
    } else if (status === PricePointApprovalStatus.exported) {
      return context === CommentsAndNotesContext.COMMENTS
        ? 'After the price has been exported you cannot type a comment'
        : 'After the price has been exported you cannot type a note';
    }
    return context === CommentsAndNotesContext.COMMENTS
      ? 'After 4/4 approval price point you cannot type a comment'
      : 'After 4/4 approval price point you cannot type a note';
  }
}
