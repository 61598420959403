<app-crosschecks-chart-wrapper
  [context]="crosschecksChartContext"
  [skeletonTheme]="'CalendarizedOrderBank'"
  [filters]="filters"
  [crosscheckIndicationVisible]="true"
  [trafficLightStatus]="crosscheckIndication!"
  [trafficLightAdjStatus]="crosscheckIndicationForecasted!"
>
  <app-chart-filters
    (showValuesToggleEvent)="showValuesToggle($event)"
    [dropdownList]="dropdownList"
    [dropdownOptions]="dropdownOptions"
  ></app-chart-filters>

  <div class="container">
    <div class="chart-container">
      <app-ftd-line-and-bar-chart
        *ngIf="initChart"
        [data]="chartData"
        [isSysDateIndicatorVisible]="true"
        [rootId]="'ftd-bar-chart-ref-calendarized-order-bank'"
        [isLabelValuesVisible]="isChartValuesVisible"
        [barChartKeys]="['retail', 'order_bank_value', currentForecastName]"
        [linesChartKeys]="['budget']"
        [clustered]="false"
        [chartLegend]="chartLegend"
        [primaryUnitLabel]="'Units'"
        [chartCustomTheme]="'CalendarizedOrderBankCustomTheme'"
        [axisType]="ChartAxisType.CATEGORY"
        [isScrollBarVisible]="true"
      ></app-ftd-line-and-bar-chart>
    </div>
    <div class="tile-container" fxLayout="column">
      <app-ftd-chart-info-tile
        *ngIf="totalOrderBankCY"
        [displayName]="totalOrderBankCY.displayName"
        [displayValue]="totalOrderBankCY.value"
        [displayNameDetails]="totalOrderBankCY.displayNameDetails"
      ></app-ftd-chart-info-tile>

      <app-ftd-chart-info-tile
        *ngIf="orderBankReach"
        [displayName]="orderBankReach.display_name"
        [displayValue]="orderBankReach.value"
        [displayNameDetails]="orderBankReach.display_name_details"
        [displayNameDetailsColor]="orderBankReach.color"
      ></app-ftd-chart-info-tile>
    </div>
  </div>

  <form [formGroup]="chartLegendForm">
    <app-crosschecks-chart-filter-group formControlName="calendarizedOrderBankFields">
      <app-chart-filter-item
        *ngFor="let legendConfig of chartLegendsConfigs"
        [value]="legendConfig.fieldName"
        [label]="legendConfig.label"
        [legendType]="legendConfig.type"
        [legendColor]="legendConfig.legendColor"
        [legendImagePath]="legendConfig.imagePath"
      >
      </app-chart-filter-item>
    </app-crosschecks-chart-filter-group>
  </form>
</app-crosschecks-chart-wrapper>
