export enum StockAgePropsEnum {
  countPerBucket0_10Days = 'countPerBucket0_10Days',
  countPerBucket11_30Days = 'countPerBucket11_30Days',
  countPerBucket31_90Days = 'countPerBucket31_90Days',
  countPerBucket91_180Days = 'countPerBucket91_180Days',
  countPerBucket181_270Days = 'countPerBucket181_270Days',
  countPerBucket271_365Days = 'countPerBucket271_365Days',
  countPerBucket365Days = 'countPerBucket365Days',
}

export enum StockAgeLabelEnum {
  countPerBucket0_10Days = '0-10 days',
  countPerBucket11_30Days = '11-30 days',
  countPerBucket31_90Days = '31-90 days',
  countPerBucket91_180Days = '91-180 days',
  countPerBucket181_270Days = '181-270 days',
  countPerBucket271_365Days = '271-365 days',
  countPerBucket365Days = '>365 days',
}
