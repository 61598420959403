import { APP_SYMBOLS } from '../../../../common/constants/app-symbols';
import { CalculationsUtils } from 'src/app/common/utils/calculations.utils';
import {
  ColumnsWithPercent,
  IMatrixColumnConfig,
  IMatrixViewDataSourceItem,
} from 'src/app/matrix-view/models/matrix-view.model';
import { Component, Input } from '@angular/core';
import { ContextService } from 'src/app/matrix-view/services/context/context.service';
import { FormArray, FormGroup } from '@angular/forms';
import { GranularityType } from 'src/app/matrix-view/enums/granularity-type.enum';
import { ICrosschecksTrafficLights } from '../../../models/app.model';
import { IScenarioDataWithPricePoint } from '../../../../graphql/services/gql-api.service';
import { InputStatus } from '../../../../common/enums/table-input-status.enum';
import { MatrixColumnSectionKey } from 'src/app/matrix-view/enums/matrix-column-section-key.enum';
import { MatrixColumnType } from 'src/app/matrix-view/enums/matrix-column-type.enum';
import { MatrixViewFormService } from 'src/app/matrix-view/services/matrix-view-form/matrix-view-form.service';
import { NumberWithPercentInput } from '../mv-number-percent/mv-number-percent.component';
import { PricePointApprovalStatus } from '../../../enums/price-point-approval-status.enum';
import { ScenarioContext } from 'src/app/common/enums/scenario-context.enum';
import { memo } from '../../../helpers/memoize';

@Component({
  selector: 'app-mv-column-wrapper',
  styleUrls: ['./mv-column-wrapper.component.scss'],
  templateUrl: './mv-column-wrapper.component.html',
})
export class MvColumnWrapperComponent {
  @Input() index!: number;
  @Input() columnConfig!: IMatrixColumnConfig;
  @Input() dataSourceItem!: IMatrixViewDataSourceItem;

  CROSSCHECKS_NOT_AVAILABLE: string[] = [
    'productionPipelineTrafficLightAdj',
    'productionPipelineTrafficLight',
    'competitionTrafficLightAdj',
    'competitionTrafficLight',
    'channelsTrafficLightAdj',
    'channelsTrafficLight',
  ];

  COLUMN_TYPE = MatrixColumnType;
  PRICE_POINT_APPROVAL_STATUS = PricePointApprovalStatus;
  COLUMN_SECTION = MatrixColumnSectionKey;
  GRANULARITY_TYPE = GranularityType;
  SCENARIO_CONTEXT = ScenarioContext;
  CO2_CAR = 'co2BaseCar';
  DELTA_CM = 'deltaCmPerUnit';
  TE_LEASE_RATE_ADJ = 'typicallyEquippedLeaseRateAdj';
  DELTA_LEASE_RATE_ADJ = 'deltaCurrentIndicativeLeaseRate';
  protected readonly APP_SYMBOLS = APP_SYMBOLS;

  constructor(private contextService: ContextService, private matrixViewFormService: MatrixViewFormService) {}

  get scenarioContext(): ScenarioContext {
    return this.contextService.getCurrentScenarioContext();
  }

  get matrixViewFormGroup(): FormGroup {
    return this.matrixViewFormService.getForm();
  }

  get isBulkSelection(): boolean {
    return (this.dataSourceItem?.priceEditorData &&
      this.dataSourceItem.granularity.type === this.GRANULARITY_TYPE.MODEL_CODE)!!;
  }

  get matrixViewFormArray(): FormArray<FormGroup> {
    return this.matrixViewFormService.getFormArray();
  }

  getPriceEditorInputStatus(index: number): InputStatus {
    const inputStatus = this.dataSourceItem.priceEditorData?.governancePricePointStatus
      ? (this.dataSourceItem.priceEditorData?.governancePricePointStatus as InputStatus)
      : 'default';
    return this.matrixViewFormArray.at(index).get('price')?.invalid &&
      (this.matrixViewFormArray.at(index).get('price')?.dirty ||
        this.matrixViewFormArray.at(index).get('price')?.touched)
      ? 'error'
      : inputStatus;
  }

  getEffectiveDateInputStatus(index: number): InputStatus {
    return this.matrixViewFormArray.at(index).get('effectiveDate')?.invalid &&
      (this.matrixViewFormArray.at(index).get('effectiveDate')?.dirty ||
        this.matrixViewFormArray.at(index).get('effectiveDate')?.touched)
      ? 'error'
      : 'default';
  }

  isEffectiveDateDirtyOrTouched(index: number): boolean {
    return Boolean(
      this.matrixViewFormArray.at(index)?.get('effectiveDate')?.dirty ||
        this.matrixViewFormArray.at(index)?.get('effectiveDate')?.touched
    );
  }

  setEffectiveDateDirtyAndTouched(index: number): void {
    this.matrixViewFormArray.at(index)?.get('effectiveDate')?.markAsDirty();
    this.matrixViewFormArray.at(index)?.get('effectiveDate')?.markAsTouched();
  }

  /**
   * PriceInputHandler
   * Add 00000 to the input if needed
   * @param index
   */
  priceInputHandler(index: number): void {
    if (!this.matrixViewFormArray.at(index)?.get('price')?.value) {
      this.matrixViewFormArray.at(index)?.get('price')?.setValue(0.0);
    }
  }

  /**
   * OnPriceInputChange
   * @param value
   * @param index
   */
  onPriceInputChange(value: string, index: number): void {
    // Remove commas from formatting
    value = value.replace(/,/g, '');
    // Reset input if empty
    if (value === '') {
      this.matrixViewFormArray.at(index)?.get('pricePointPercentage')?.reset();
    } else {
      const currentPrice = this.dataSourceItem.priceEditorData?.listPriceInclTax;
      const variation = this.calculatePercentageVariation(value, currentPrice);
      this.matrixViewFormArray.at(index)?.get('pricePointPercentage')?.setValue(variation);
    }
  }

  onPricePercentageInputChange(value: string, index: number): void {
    // Remove commas from formating
    value = value.replace(/,/g, '');
    // Reset input if empty
    if (value === '') {
      this.matrixViewFormArray.at(index)?.get('price')?.reset();
    } else {
      const adjustPercentage = Number(value);
      const currentPrice = this.dataSourceItem.priceEditorData?.listPriceInclTax as number;
      if (!isNaN(adjustPercentage) && !isNaN(currentPrice)) {
        const newPrice = CalculationsUtils.addPercentageVariation(currentPrice, adjustPercentage).toFixed(4);
        this.matrixViewFormArray.at(index)?.get('price')?.setValue(newPrice);
      }
    }
  }

  calculatePercentageVariation(adjustedPrice: number | string, currentPrice: number | string | undefined): string {
    if (adjustedPrice !== '' && !isNaN(Number(adjustedPrice)) && !isNaN(Number(currentPrice))) {
      return CalculationsUtils.calculatePercentageVariation(Number(currentPrice), Number(adjustedPrice)).toFixed(1);
    }
    return '';
  }

  isColumnWithPercent = memo((columnName: IScenarioDataWithPricePoint): boolean => {
    if (!Object.values(ColumnsWithPercent).includes(columnName as unknown as ColumnsWithPercent)) {
      return false;
    }
    if ((columnName as unknown as ColumnsWithPercent) === ColumnsWithPercent.EXPECTED_VOLUME_RETAIL) {
      return !!(
        this.dataSourceItem.scenarioOutput!.expectedVolumeRetailAdj! &&
        this.dataSourceItem.scenarioOutput!.forecastVolumeRetail!
      );
    } else {
      return !!this.dataSourceItem.scenarioOutput![columnName as unknown as keyof IScenarioDataWithPricePoint]!;
    }
  });

  getPercentageByColumnName = memo((columnName: IScenarioDataWithPricePoint): NumberWithPercentInput => {
    let number: string, percentage: string;
    if ((columnName as unknown as ColumnsWithPercent) === ColumnsWithPercent.EXPECTED_VOLUME_RETAIL) {
      return {
        number: this.dataSourceItem.scenarioOutput!.expectedVolumeRetailAdj!,
        percentage:
          this.dataSourceItem.scenarioOutput!.expectedVolumeRetailAdj! /
            this.dataSourceItem.scenarioOutput!.forecastVolumeRetail! -
          1,
      };
    } else {
      [number, percentage] = this.dataSourceItem
        .scenarioOutput![columnName as unknown as keyof IScenarioDataWithPricePoint]!.toString()
        .split('|');
      return {
        number: parseInt(number),
        percentage: parseFloat(percentage),
      };
    }
  });

  buildCrosscheckResultItem = memo((technicalName: IScenarioDataWithPricePoint): ICrosschecksTrafficLights => {
    if (this.CROSSCHECKS_NOT_AVAILABLE.includes(this.columnConfig.technicalName)) {
      return { arrow: 'notAvailable', color: 'notAvailable' };
    }

    return this.dataSourceItem.scenarioOutput![technicalName as unknown as keyof IScenarioDataWithPricePoint]
      ? JSON.parse(
          String(this.dataSourceItem.scenarioOutput![technicalName as unknown as keyof IScenarioDataWithPricePoint])
        )
      : { arrow: null, color: null };
  });
}
