import { AbandonScenarioModalComponent } from '../scenario/modals/abandon-scenario-modal/abandon-scenario-modal.component';
import { CommonModule } from '../common/common.module';
import { EditScenarioModalComponent } from '../scenario/modals/edit-scenario-modal/edit-scenario-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatMenuModule } from '@angular/material/menu';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MaterialModule } from '../common/material.module';
import { MatrixLandingViewComponent } from './components/matrix-landing-view/matrix-landing-view.component';
import { MatrixViewColumnsOrderingModalComponent } from './components/modals/matrix-view-columns-ordering-modal/matrix-view-columns-ordering-modal.component';
import { MatrixViewComponent } from './components/matrix-view/matrix-view.component';
import { MatrixViewDataComponent } from './components/matrix-view-data/matrix-view-data.component';
import { MatrixViewSideFiltersComponent } from './components/matrix-view-side-filters/matrix-view-side-filters.component';
import { MatrixViewSubheaderComponent } from './components/matrix-view-subheader/matrix-view-subheader.component';
import { MatrixViewTableHeaderComponent } from './components/matrix-view-table-header/matrix-view-table-header.component';
import { MatrixViewTrafficLightsLegendsComponent } from './components/matrix-view-traffic-lights-legends/matrix-view-traffic-lights-legends.component';
import { MatrixViewWrapperComponent } from './components/matrix-view-wrapper/matrix-view-wrapper.component';
import { MvApprovalStatusDisplayComponent } from './components/matrix-view-columns/mv-approval-status-display/mv-approval-status-display.component';
import { MvChangedValueComponent } from './components/matrix-view-columns/mv-changed-value/mv-changed-value.component';
import { MvColumnActionComponent } from './components/matrix-view-columns/mv-column-action/mv-column-action.component';
import { MvColumnMetadataComponent } from './components/matrix-view-columns/mv-column-metadata/mv-column-metadata.component';
import { MvColumnPriceInputEditorComponent } from './components/matrix-view-columns/mv-column-price-input-editor/mv-column-price-input-editor.component';
import { MvColumnWrapperComponent } from './components/matrix-view-columns/mv-column-wrapper/mv-column-wrapper.component';
import { MvCrossCheckComponent } from './components/matrix-view-columns/mv-crosscheck/mv-crosscheck.component';
import { MvDateDisplayComponent } from './components/matrix-view-columns/mv-date-display/mv-date-display.component';
import { MvDisabledDisplayComponent } from './components/matrix-view-columns/mv-disabled-display/mv-disabled-display.component';
import { MvExpandedTooltipColumnHeaderComponent } from './components/matrix-view-columns/mv-expanded-tooltip-column-header/mv-expanded-tooltip-column-header.component';
import { MvNumberDisplayComponent } from './components/matrix-view-columns/mv-number-display/mv-number-display.component';
import { MvNumberPercentComponent } from './components/matrix-view-columns/mv-number-percent/mv-number-percent.component';
import { MvPpDisplayComponent } from './components/matrix-view-columns/mv-pp-display/mv-pp-display.component';
import { MvPredictionEvaluationIndicatorComponent } from './components/matrix-view-columns/mv-prediction-evaluation-indicator/mv-prediction-evaluation-indicator.component';
import { MvPriceDisplayComponent } from './components/matrix-view-columns/mv-price-display/mv-price-display.component';
import { MvRowNameComponent } from './components/matrix-view-columns/mv-row-name/mv-row-name.component';
import { MvTextDisplayComponent } from './components/matrix-view-columns/mv-text-display/mv-text-display.component';
import { MvUnitMapComponent } from './components/matrix-view-columns/mv-unit-map/mv-unit-map.component';
import { NewScenarioModalComponent } from '../scenario/modals/new-scenario-modal/new-scenario-modal.component';
import { NgModule } from '@angular/core';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { PriceApprovalStatusChipComponent } from './components/matrix-view-columns/mv-column-price-input-editor/price-approval-status-chip/price-approval-status-chip.component';
import { PriceApprovalStatusChipModalComponent } from './components/modals/price-approval-status-chip-modal/price-approval-status-chip-modal.component';
import { PriceApprovalStatusChipModalItemComponent } from './components/modals/price-approval-status-chip-modal/price-approval-status-chip-modal-item/price-approval-status-chip-modal-item.component';
import { RouterModule } from '@angular/router';
import { ScenarioDeleteModalComponent } from '../scenario/modals/scenario-delete-modal/scenario-delete-modal.component';
import { ScenarioListModalComponent } from '../scenario/modals/scenario-list-modal/scenario-list-modal.component';
import { SubmitScenarioModalComponent } from '../scenario/modals/submit-scenario-modal/submit-scenario-modal.component';

@NgModule({
  declarations: [
    MatrixViewComponent,
    MatrixViewDataComponent,
    MvColumnWrapperComponent,
    MvRowNameComponent,
    MvPriceDisplayComponent,
    MvNumberDisplayComponent,
    MvNumberPercentComponent,
    MvColumnPriceInputEditorComponent,
    MvCrossCheckComponent,
    MatrixViewWrapperComponent,
    MatrixViewSideFiltersComponent,
    MatrixViewSubheaderComponent,
    MatrixLandingViewComponent,
    MvTextDisplayComponent,
    MvDateDisplayComponent,
    MvDisabledDisplayComponent,
    NewScenarioModalComponent,
    ScenarioListModalComponent,
    ScenarioDeleteModalComponent,
    MvColumnActionComponent,
    SubmitScenarioModalComponent,
    EditScenarioModalComponent,
    MatrixViewTableHeaderComponent,
    AbandonScenarioModalComponent,
    MatrixViewColumnsOrderingModalComponent,
    MvChangedValueComponent,
    MvPpDisplayComponent,
    MvPredictionEvaluationIndicatorComponent,
    MvUnitMapComponent,
    MvApprovalStatusDisplayComponent,
    PriceApprovalStatusChipComponent,
    PriceApprovalStatusChipModalComponent,
    PriceApprovalStatusChipModalItemComponent,
    MatrixViewTrafficLightsLegendsComponent,
    MvColumnMetadataComponent,
    MvExpandedTooltipColumnHeaderComponent,
  ],
  exports: [MatrixViewWrapperComponent, MatrixViewSubheaderComponent, MatrixViewSideFiltersComponent],
  imports: [
    CommonModule,
    MaterialModule,
    MatDividerModule,
    RouterModule,
    NgxSkeletonLoaderModule,
    MatExpansionModule,
    MatSidenavModule,
    MatDatepickerModule,
    MatMenuModule,
  ],
})
export class MatrixViewModule {}
