<ng-container *ngIf="!isGroupedDropdown; else groupDropdown">
  <mat-form-field
    [ngClass]="{ isTableDropdown: isGroupedDropdown }"
    appearance="outline"
    [formGroup]="form"
    id="ftd-dropdown-{{ id }}"
    [class.full-width]="fillWidth"
    (click)="handleSelectFormClick()"
    [floatLabel]="'auto'"
  >
    <mat-label *ngIf="isLabelVisible">{{ label }}</mat-label>
    <mat-select
      #matSelectRef
      [id]="id"
      [formControlName]="controlName"
      [disableOptionCentering]="true"
      [placeholder]="label"
    >
      <mat-option *ngIf="isSearchVisible" class="map-option-search-input">
        <ngx-mat-select-search
          placeholderLabel="Search"
          [formControlName]="controlName + 'Search'"
          noEntriesFoundLabel="No matching found"
        >
        </ngx-mat-select-search>
      </mat-option>

      <mat-option
        #matOption
        *ngFor="let option of options; trackBy: optionTrackBy"
        [value]="option.value"
        [disabled]="option.disabled!"
      >
        <div class="option-wrapper">
          <mat-icon
            *ngIf="isRadioButtonVisible"
            fontIcon="{{ matOption.selected ? 'radio_button_checked' : 'radio_button_unchecked' }}"
          ></mat-icon>
          <svg *ngIf="isFlagsVisible" class="flag" width="20" height="20">
            <use [attr.xlink:href]="'./assets/images/flags/flags-sprite.svg#' + option.value" />
          </svg>
          {{ option.label }}
        </div>
      </mat-option>
    </mat-select>

    <mat-hint>
      <div class="custom-message">
        {{ customMessage }}
      </div>
    </mat-hint>
    <mat-error>
      {{ errorMessage }}
    </mat-error>
  </mat-form-field>
</ng-container>
<ng-template #groupDropdown>
  <mat-form-field
    [ngClass]="{ isTableDropdown: isGroupedDropdown }"
    appearance="fill"
    [formGroup]="form"
    id="ftd-dropdown-{{ id }}"
    [class.full-width]="fillWidth"
  >
    <mat-label *ngIf="isLabelVisible">{{ label }}</mat-label>
    <mat-select [id]="id" [formControlName]="controlName" [disableOptionCentering]="true">
      <mat-option *ngFor="let option of options" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>
