import { APP_SYMBOLS } from '../../../../common/constants/app-symbols';
import { Component, Input } from '@angular/core';
import { MatrixColumnTechnicalName } from '../../../enums/matrix-column-technical-name.enum';

@Component({
  selector: 'app-mv-date-display',
  styleUrls: ['./mv-date-display.component.scss'],
  templateUrl: './mv-date-display.component.html',
})
export class MvDateDisplayComponent {
  @Input() dateHolder!: any;

  @Input() technicalName?: MatrixColumnTechnicalName;

  @Input() nullableType: string = APP_SYMBOLS.NA;
}
