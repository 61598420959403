import { AppThemeColors } from '../../../../common/constants/app-theme-colors';
import { PricePointApprovalStatus } from '../enums/price-point-approval-status.enum';

export const PriceApprovalStatusToChipTextMap = new Map<PricePointApprovalStatus, IPriceApprovalStatusToChipData>([
  [
    PricePointApprovalStatus.proposed_to_pricing_lead,
    {
      backgroundColor: AppThemeColors['ftd-status-pending']['200'],
      text: '1/4',
      textColor: AppThemeColors['ftd-white'].default,
    },
  ],
  [
    PricePointApprovalStatus.rejected,
    {
      backgroundColor: AppThemeColors['ftd-risk']['200'],
      text: '1/4',
      textColor: AppThemeColors['ftd-white'].default,
    },
  ],
  [
    PricePointApprovalStatus.proposed_to_governance_board,
    {
      backgroundColor: AppThemeColors['ftd-status-pending']['200'],
      text: '2/4',
      textColor: AppThemeColors['ftd-white'].default,
    },
  ],
  [
    PricePointApprovalStatus.approved_by_1,
    {
      backgroundColor: AppThemeColors['ftd-status-pending']['200'],
      text: '3/4',
      textColor: AppThemeColors['ftd-white'].default,
    },
  ],
  [
    PricePointApprovalStatus.approved_by_2,
    {
      backgroundColor: AppThemeColors['ftd-grey']['500'],
      text: '4/4',
      textColor: AppThemeColors['ftd-grey']['700'],
    },
  ],
]);

export interface IPriceApprovalStatusToChipData {
  text: string;
  backgroundColor: string;
  textColor: string;
}
