<ng-container *ngIf="message">
  <div *ngIf="borderLight; else darkMode">
    <div class="alert-box alert-light">
      <div class="icon" *ngIf="iconTypeName !== ''">
        <mat-icon>{{ iconTypeName }}</mat-icon>
      </div>
      <div class="text">
        <label>{{ message }}</label>
      </div>
    </div>
  </div>

  <ng-template #darkMode>
    <div class="alert-box alert-dark">
      <div class="icon" *ngIf="iconTypeName !== ''">
        <mat-icon>{{ iconTypeName }}</mat-icon>
      </div>
      <div class="text">
        <strong>{{ message }}</strong>
      </div>
    </div>
  </ng-template>
</ng-container>
