import { AppMessageType, IAppMessage } from '../../models/app-message.model';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  private messageSubject = new Subject<IAppMessage>();

  showMessage(message: IAppMessage): void {
    this.messageSubject.next(message);
  }

  showInfo(text: string, duration?: number): void {
    this.messageSubject.next({
      duration,
      text,
      type: AppMessageType.INFO,
    });
  }

  showError(text: string, duration?: number): void {
    this.messageSubject.next({
      duration,
      text,
      type: AppMessageType.ERROR,
    });
  }

  showSuccess(text: string, duration?: number): void {
    this.messageSubject.next({
      duration,
      text,
      type: AppMessageType.SUCCESS,
    });
  }

  getNextMessage(): Observable<IAppMessage> {
    return this.messageSubject.asObservable();
  }
}
