export const AppThemeColors = {
  'ftd-attention': {
    default: '#FFE38E',
  },
  'ftd-chart': {
    negative: '#FF454A',
    positive: '#84E49C',
  },
  'ftd-grey': {
    200: '#F2F2F2',
    300: '#E0E1E1',
    500: '#A8A8A7',
    700: '#494949',
  },
  'ftd-info': {
    100: '#008FF0FF',
    200: '#0071C5FF',
  },
  'ftd-negative': {
    default: '#D47D7E',
  },
  'ftd-opportunity': {
    100: '#078007FF',
    200: '#005800',
  },
  'ftd-positive': {
    default: '#8CC59AFF',
  },
  'ftd-risk': {
    200: '#8B0C00',
  },
  'ftd-status-pending': {
    100: '#f8d260',
    200: '#d79e00',
    300: '#815515',
    400: '#393021',
  },
  'ftd-white': {
    default: '#FFFFFFFF',
  },
};
