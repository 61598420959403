export enum ApprovalStatus {
  saved = 'Saved',
  contains_comments_only = 'Contains Comments Only',
  proposed_to_pricing_lead = 'Proposed to Pricing Lead',
  proposed_to_governance_board = 'Approved by Pricing Lead',
  demote_status = 'Demote Status',
  promote_status = 'Promote Status',
  approved_by_1 = 'Approved by Controlling',
  approved_by_2 = 'Released to SPRINT/C1 approved',
  rejected = 'Rejected',
  withdraw = 'Withdraw',
  exported = 'Exported',
}

export enum ApprovalModalType {
  RELEASE = 'release',
  APPROVE = 'approve',
}

export enum ApprovalErrorMessages {
  NO_RELEASE_STATUS_PRICE_POINTS = 'There are price points that are not in status nsc-approved',
  NO_APPROVE_STATUS_PRICE_POINTS = 'There are price points that are not in status level-1 approved',
  RELEASE_NOT_POSSIBLE = 'There are price points that cannot be approved by Pricing Team Lead because other approvals are required',
  APPROVE_NOT_POSSIBLE = 'There are price points that cannot be approved by Controlling because other approvals are required',
}

export type KeyofApprovalStatus = keyof typeof ApprovalStatus;
