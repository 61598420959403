<app-ftd-custom-modal>
  <app-ftd-custom-modal-header [title]="model"></app-ftd-custom-modal-header>
  <h4 class="notes-title">{{ context === CommentsAndNotesContext.COMMENTS ? 'PUBLIC COMMENT' : 'PERSONAL NOTE' }}</h4>

  <app-ftd-custom-modal-body [isRequestProcessing]="isRequestProcessing">
    <div id="comments-container" appFtdScrollToBottom [attr.data-test]="'comments-container'">
      <div fxLayout="column" *ngIf="!comments.length" class="comments-container mb-3">
        - {{ context === CommentsAndNotesContext.COMMENTS ? 'No comments' : 'No notes' }} -
      </div>
      <div fxLayout="column" *ngFor="let comment of comments" class="comments-container-item mb-3">
        <h4
          class="m-0"
          [class.own-user-comment]="authService.isSameUserSub(comment.userId)"
          [class.user-comment]="!authService.isSameUserSub(comment.userId)"
        >
          {{ comment.userName }} <span class="timestamp">{{ comment.createdAt | date: "d MMM 'at' h:mm a" }}</span>
        </h4>
        <span>
          {{ comment.text }}
        </span>
      </div>
    </div>

    <div class="divider mb-3">
      <mat-divider></mat-divider>
    </div>

    <form
      *ngIf="currentUser?.permissions?.getHasPermissionToCreateUserScenario()"
      [formGroup]="form"
      (ngSubmit)="onSubmit()"
      fxLayout="row"
      id="textarea-container"
      novalidate
    >
      <app-ftd-textarea
        class="w-100"
        [form]="form"
        controlName="message"
        [placeholder]="context === CommentsAndNotesContext.COMMENTS ? 'Add a comment' : 'Add a note'"
        [rows]="1"
      ></app-ftd-textarea>
      <app-ftd-button
        [disabled]="isButtonDisabled(governancePricePointStatus)"
        [fillWidth]="true"
        [matTooltip]="getTooltipText(governancePricePointStatus)"
        matTooltipPosition="above"
        [matTooltipDisabled]="isTooltipDisabled(governancePricePointStatus)"
        ariaText="Submit Comment"
        class="ml-2"
        label="Submit"
        hierarchy="primary"
        size="M"
      ></app-ftd-button>
    </form>
  </app-ftd-custom-modal-body>
</app-ftd-custom-modal>
