<ftd-tab-group [selectedIndex]="selectedTab" (tabIndexChange)="changeTab($event)">
  <ftd-tab>
    <ftd-tab-label
      tabLabel
      [title]="'Stock Coverage'"
      [status]="stockCoverageStatus"
      [info]="true"
      [infoText]="tooltips?.stockCoverage"
      [percentage]="trafficLightStockCoverage?.weight || ''"
      [trafficLights]="!!trafficLightStockCoverage"
      [trafficLightColor]="trafficLightStockCoverage?.color"
    ></ftd-tab-label>
    <app-stock-coverage
      [granularity]="filters"
      [crosscheckIndication]="crosscheckIndication"
      [crosscheckIndicationForecasted]="crosscheckIndicationForecasted"
      *ngIf="isSelectedTabStockCoverage"
    ></app-stock-coverage>
  </ftd-tab>
  <ftd-tab [disabled]="true">
    <ftd-tab-label tabLabel [title]="'Stock Mix'" [info]="true" [infoText]="tooltips?.stockMix"></ftd-tab-label>
    <app-stock-mix
      *ngIf="isSelectedTabStockMix"
      [granularity]="filters"
      [crosscheckIndication]="crosscheckIndication"
      [crosscheckIndicationForecasted]="crosscheckIndicationForecasted"
    ></app-stock-mix>
  </ftd-tab>
  <ftd-tab>
    <ftd-tab-label
      tabLabel
      [title]="'Stock Age'"
      [info]="true"
      [infoText]="tooltips?.stockAge"
      [percentage]="trafficLightStockAge?.weight || ''"
      [trafficLights]="!!trafficLightStockAge"
      [trafficLightColor]="trafficLightStockAge?.color"
    ></ftd-tab-label>
    <app-stock-age
      [granularity]="filters"
      *ngIf="isSelectedTabStockAge"
      [crosscheckIndication]="crosscheckIndication"
      [crosscheckIndicationForecasted]="crosscheckIndicationForecasted"
    ></app-stock-age>
  </ftd-tab>
</ftd-tab-group>
