import { Accessible, StockPriceLevel } from '../../../../graphql/services/gql-api.service';
import { Base64Utils } from '../../../../common/utils/base64.utils';
import { ChartAxisType } from 'src/app/common/components/ftd-charts/enums/ftd-generic-chart.enum';
import { ChartLegendType } from '../../../../common/components/ftd-charts/enums/chart-legend-type.enum';
import { ChipColor, ChipSize } from '../../../../common/components/ftd-chip/ftd-chip-size.enum';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../../../../matrix-view/services/context/context.service';
import { CrosschecksChartContext } from '../../../enums/crosschecks-chart-context-messages.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import { DisplayNameColor } from '../../../../common/components/ftd-charts/ftd-chart-info-tile/ftd-chart-info-tile.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GranularityType } from '../../../../matrix-view/enums/granularity-type.enum';
import { IBarChartData } from 'src/app/common/components/ftd-charts/models/ftd-bar-chart.model';
import { IChartLegendConfig } from 'src/app/common/components/ftd-charts/models/ftd-chart-legend-config.model';
import { IFtdDropdownOption } from 'src/app/common/models/ftd-dropdown-option.model';
import {
  IGranularityDto,
  IHistoricChartData,
  IStockAgeResponseChartData,
  IStockAgeTileData,
} from '../../../../matrix-view/models/api.model';
import { StockAgeHelper } from '../../../helpers/stock/stock-age.helper';
import { StockAgeLabelEnum, StockAgePropsEnum } from '../../../enums/stock-age-props.enum';
import { Subscription } from 'rxjs';
import { TrafficLightsColor } from '../../../types/traffic-lights.type';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import dayjs from 'dayjs';

dayjs.extend(advancedFormat);

@Component({
  selector: 'app-stock-age',
  styleUrls: ['./stock-age.component.scss'],
  templateUrl: './stock-age.component.html',
})
export class StockAgeComponent implements OnInit, OnDestroy {
  horizontalChartData!: IBarChartData;
  popupChartData: IBarChartData | null = null;
  horizontalChartAxisType = ChartAxisType.VALUE;
  popupChartAxisType = ChartAxisType.CATEGORY;
  chipSize = ChipSize.SMALL;
  chipColor = ChipColor.ftdInfo200;
  chipText = '';
  filters!: IGranularityDto;
  reference: string = '';
  private _granularity: IGranularityDto = <IGranularityDto>{};
  @Input() set granularity(granularityData: string) {
    this.isVisible = false;
    this._granularity = Base64Utils.decodeAtobToJson(granularityData);
    this.filters = this._granularity;
    this.loadStockAgeData();
  }

  @Input() crosscheckIndication?: TrafficLightsColor;
  @Input() crosscheckIndicationForecasted?: TrafficLightsColor;

  isVisible: boolean = true;
  crosschecksChartContext: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  isChartValuesVisible: boolean = false;
  filtersForm!: FormGroup;
  consideredPrice: IFtdDropdownOption<string>[] = [];
  dropdownAccessible: IFtdDropdownOption<Accessible>[] = [
    {
      id: Accessible.Accessible,
      label: 'Accessible',
      value: Accessible.Accessible,
    },
    {
      id: Accessible.TotalStock,
      label: 'Total stock',
      value: Accessible.TotalStock,
    },
  ];

  dropdownStockPriceLevel: IFtdDropdownOption<StockPriceLevel>[] = [
    {
      id: StockPriceLevel.FirstPriceLevel,
      label: '1st price level',
      value: StockPriceLevel.FirstPriceLevel,
    },
    {
      id: StockPriceLevel.SecondPriceLevel,
      label: '2nd price level',
      value: StockPriceLevel.SecondPriceLevel,
    },
  ];

  private accessibleFilter: Accessible = Accessible.TotalStock;
  private stockPriceLevelFilter: StockPriceLevel = StockPriceLevel.FirstPriceLevel;

  tileData: IStockAgeTileData | null = null;

  chartLegendForm!: FormGroup;
  chartLegendFieldNames: string[] = [];
  chartLegendsConfigs: IChartLegendConfig[] = [];

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param formBuilder
   * @param crosscheckService
   * @param contextService
   */
  constructor(
    private formBuilder: FormBuilder,
    private crosscheckService: CrosschecksService,
    private contextService: ContextService
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe();
  }

  /**
   * NgUnsubscribe
   */
  ngUnsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * InitForm
   * @private
   */
  private initForm(): void {
    this.filtersForm = this.formBuilder.group({
      accessible: new FormControl<Accessible>({ disabled: true, value: Accessible.TotalStock }),
      stockPriceLevel: new FormControl<StockPriceLevel>({ disabled: false, value: StockPriceLevel.FirstPriceLevel }),
    });

    const accessibleSubscription = this.filtersForm.controls.accessible.valueChanges.subscribe((selected) => {
      this.accessibleFilter = selected;
      this.loadStockAgeData();
    });
    this.subscriptions.push(accessibleSubscription);

    const stockPriceLevelSubscription = this.filtersForm.controls.stockPriceLevel.valueChanges.subscribe((selected) => {
      this.stockPriceLevelFilter = selected;
      this.loadStockAgeData();
    });
    this.subscriptions.push(stockPriceLevelSubscription);

    this.chartLegendForm = this.formBuilder.group({
      stockAgeFields: new FormControl<string[]>([]),
    });

    const chartLegendFormSubscription: Subscription =
      this.chartLegendForm.controls.stockAgeFields.valueChanges.subscribe((selectedLegend: string[]): void => {
        this.chartLegendFieldNames = selectedLegend;
      });
    this.subscriptions.push(chartLegendFormSubscription);

    this.chartLegendFieldNames = this.chartLegendForm.controls.stockAgeFields.value;
    this.setStockAgeFormFields();
  }

  /**
   * LoadStockAgeData
   * @private
   */
  private loadStockAgeData(): void {
    this.crosschecksChartContext = CrosschecksChartContext.LOADING;
    this.isVisible = true;
    if (this._granularity.market) {
      this.crosscheckService
        .getStockAgeData(
          this._granularity.id!,
          this._granularity.market,
          this.contextService.scenarioId,
          this.accessibleFilter,
          this.stockPriceLevelFilter
        )
        .subscribe({
          error: (): void => {
            this.crosschecksChartContext = CrosschecksChartContext.ERROR;
          },
          next: (response): void => {
            this.chipText = dayjs(response.calculationDate).format('Do MMM');
            this.mapStockAgeDataResponse(response as IStockAgeResponseChartData);
          },
        });
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
    }
  }

  /**
   * MapStockAgeDataResponse
   * @private
   */
  private mapStockAgeDataResponse(response: IStockAgeResponseChartData): IBarChartData | void {
    if (response.stockAge.length) {
      this.horizontalChartData = {
        fields: response.stockAge.map((column) => {
          const { eSeriesCount, eSeries, ...data } = column;
          return {
            ...data,
            bulletAnchor: eSeriesCount,
            lastIndex: 0,
            selected: eSeries === this._granularity.eSeries,
            value: eSeries,
          };
        }),
        headings: [
          ...Object.keys(StockAgePropsEnum).map((key) => ({ description: '', key })),
          { description: '', key: 'lastIndex' },
        ],
      };
      if (this._granularity.type === GranularityType.MODEL_CODE || this._granularity.type === GranularityType.MODEL) {
        this.tileData = null;
        this.reference =
          this._granularity.type === GranularityType.MODEL_CODE
            ? this._granularity.modelCode!
            : this._granularity.model;
        this.mapHistoricChart(response.historic);
        response.scalar?.map((tileData: IStockAgeTileData) => (this.tileData = tileData));
      }
      this.crosschecksChartContext = CrosschecksChartContext.CHART;
      return this.horizontalChartData;
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_RESULTS_FOUND;
    }
  }

  /**
   * Set Stock Age form fields.
   * @private
   */
  private setStockAgeFormFields(): void {
    this.chartLegendFieldNames = [];
    Object.keys(StockAgeLabelEnum).forEach((key: string, index: number): void => {
      this.chartLegendFieldNames.push(key);
      this.chartLegendsConfigs.push({
        fieldName: key,
        label: StockAgeLabelEnum[key as keyof typeof StockAgeLabelEnum],
        legendColor: StockAgeHelper.getColor(index, 'fill'),
        type: ChartLegendType.SQUARE_DIV,
      });
    });
    this.chartLegendFieldNames.push('lastIndex');
    this.chartLegendForm.get('stockAgeFields')?.setValue(this.chartLegendFieldNames);
  }

  /**
   * Build Stock Age Historic Chart.
   * @param chartData
   * @private
   */
  private mapHistoricChart(chartData: IHistoricChartData[] | undefined) {
    this.popupChartData = null;
    if (chartData?.length) {
      this.popupChartData = {
        fields: chartData.map((data) => {
          const { date, ...historicData } = data;
          return {
            ...historicData,
            date,
            lastIndex: 0,
            xAxisValue: dayjs(date).format("MMM 'YY"),
          };
        }),
        headings: this.horizontalChartData.headings,
      };
    }
    return this.popupChartData;
  }

  protected readonly GranularityType = GranularityType;

  setColorString(value: number): DisplayNameColor {
    return value > 0 ? DisplayNameColor.green : DisplayNameColor.red;
  }
}
