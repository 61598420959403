<mat-form-field
  class="ftd-input"
  appearance="outline"
  [class.error-input]="hasInputErrors"
  [formGroup]="form"
  [ngClass]="{ 'full-width': fillWidth }"
>
  <mat-label *ngIf="label">{{ label }}</mat-label>
  <span *ngIf="prefixValue" matPrefix>{{ prefixValue }}</span>
  <mat-icon *ngIf="prefixIconName" matPrefix>{{ prefixIconName }}</mat-icon>
  <input
    *ngIf="!enableCurrencyMask; else inputWithCurrencyMaskRef"
    id="ftd-input-{{ id }}"
    matInput
    [placeholder]="placeholder"
    [attr.maxlength]="maxlength"
    [formControlName]="controlName"
  />
  <ng-template #inputWithCurrencyMaskRef>
    <input
      id="ftd-input-{{ id }}"
      matInput
      [placeholder]="placeholder"
      [attr.maxlength]="maxlength"
      [formControlName]="controlName"
      appFtdCurrencyMask
      [options]="currencyMaskOptions"
    />
  </ng-template>
  <mat-hint>
    <div class="custom-message">
      {{ customMessage }}
    </div>
    <div *ngIf="maxlength && !hideMaxlengthHint">{{ inputValue.length }}/{{ maxlength }}</div>
  </mat-hint>
  <mat-error>
    {{ errorMessage }}
  </mat-error>
</mat-form-field>
