import { Component, EventEmitter, Input, OnInit, Output, booleanAttribute } from '@angular/core';

export type BtnSize = 'L' | 'M' | 'S';
export type BtnHierarchy = 'primary' | 'secondary' | 'tertiary' | 'green' | 'quarternary' | 'red' | 'base';
export type BtnIconPosition = 'left' | 'right';
let nextId = 0;

@Component({
  selector: 'app-ftd-button',
  templateUrl: './ftd-button.component.html',
})
export class FtdButtonComponent implements OnInit {
  @Input() disabled: boolean = false;
  // eslint-disable-next-line no-plusplus
  @Input() id?: string = 'ftd-button-' + nextId++;
  @Input() ariaText?: string = '';
  @Input() icon?: string = '';
  @Input() fillWidth?: boolean = false;
  @Input() iconPosition?: BtnIconPosition = 'left';
  @Input() label?: string = '';
  @Input() size: BtnSize = 'M';
  @Input() hierarchy?: BtnHierarchy = 'primary';
  @Input() isLoading: boolean = false;
  @Input({ transform: booleanAttribute }) outlined?: boolean;
  position: string | undefined = '';
  @Output() btnClick: EventEmitter<any> = new EventEmitter<any>();

  onClick($event: MouseEvent): void {
    this.btnClick.emit($event);
  }

  ngOnInit(): void {
    if (this.label && this.icon) {
      this.position = this.iconPosition;
    }
  }
}
