import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { HttpLink } from 'apollo-angular/http';
import { NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { setContext } from '@apollo/client/link/context';

const uri = environment.gqlApiUrl;
export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> {
  const basic: ApolloLink = setContext(() => ({
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }));

  const link: ApolloLink = ApolloLink.from([basic, httpLink.create({ uri })]);
  const cache: InMemoryCache = new InMemoryCache({
    addTypename: false,
    resultCaching: false,
  });
  return {
    cache,
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      },
      query: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      },
      watchQuery: {
        errorPolicy: 'all',
        fetchPolicy: 'no-cache',
      },
    },
    link,
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      deps: [HttpLink],
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
    },
  ],
})
export class GraphQLModule {}
