import { APP_SYMBOLS } from '../../../../common/constants/app-symbols';
import {
  Accessible,
  IStockCoverageCrosscheckData,
  StockPriceLevel,
} from '../../../../graphql/services/gql-api.service';
import { Base64Utils } from '../../../../common/utils/base64.utils';
import { ChartAxisType } from '../../../../common/components/ftd-charts/enums/ftd-generic-chart.enum';
import { ChartLegendType } from '../../../../common/components/ftd-charts/enums/chart-legend-type.enum';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConsideredPriceType } from '../../../enums/crosschecks-chart-dropdowns-type.enum';
import { ContextService } from '../../../../matrix-view/services/context/context.service';
import { CrosschecksChartContext } from '../../../enums/crosschecks-chart-context-messages.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import { DecimalPipe } from '@angular/common';
import { DisplayNameColor } from '../../../../common/components/ftd-charts/ftd-chart-info-tile/ftd-chart-info-tile.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IBarChartData } from '../../../../common/components/ftd-charts/models/ftd-bar-chart.model';
import { IChartLegendConfig } from '../../../../common/components/ftd-charts/models/ftd-chart-legend-config.model';
import { IFtdDropdownOption } from '../../../../common/models/ftd-dropdown-option.model';
import { IGenericChartHeadings } from '../../../../common/components/ftd-charts/models/ftd-generic-chart.model';
import {
  IGranularityDto,
  IStockCoverageScalar,
  ITotalAccessibleStockCoverage,
} from '../../../../matrix-view/models/api.model';
import { ILineChartData } from '../../../../common/components/ftd-charts/models/ftd-line-chart.model';
import { Subscription } from 'rxjs';
import { TrafficLightsColor } from '../../../types/traffic-lights.type';
import { currencyMapHelper } from '../../../helpers/currency.helper';
import dayjs from 'dayjs';

@Component({
  selector: 'app-stock-coverage',
  styleUrls: ['./stock-coverage.component.scss'],
  templateUrl: './stock-coverage.component.html',
})
export class StockCoverageComponent implements OnInit, OnDestroy {
  protected readonly parseFloat = parseFloat;
  crosschecksChartContext: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  filters!: IGranularityDto;
  private _granularity: IGranularityDto = <IGranularityDto>{};

  @Input() set granularity(granularityData: string) {
    this._granularity = Base64Utils.decodeAtobToJson(granularityData);
    this.filters = this._granularity;
    this.currency = currencyMapHelper.get(this._granularity.market) as string;
    this.loadStockCoverageChartData();
  }

  @Input() crosscheckIndication?: TrafficLightsColor;
  @Input() crosscheckIndicationForecasted?: TrafficLightsColor;

  filtersForm!: FormGroup;
  barChartLegend: string[] = [];
  barChartData!: IBarChartData;
  barChartLegendForm!: FormGroup;
  barChartLegendFieldNames: string[] = [];
  currency!: string;
  private imagePathProposed = './assets/images/charts/legends/rectangle-dotted-orange.svg';
  private imagePathCurrent = './assets/images/charts/legends/rectangle-empty-dotted.svg';
  private imageStockLineCurrent = './assets/images/charts/legends/line-dotted-aqua.svg';
  private imageStockLineProposed = './assets/images/charts/legends/line-dotted-orange.svg';

  barChartLegendsConfigs: IChartLegendConfig[] = [
    {
      fieldName: 'stockUnitsActualsTS',
      label: 'Stock actuals',
      legendColor: '#52A2EB',
      type: ChartLegendType.SQUARE_DIV,
    },
    {
      fieldName: 'stockUnitsForecastTSAdj',
      imagePath: './assets/images/charts/legends/rectangle-empty-dotted.svg',
      label: 'Stock NCPE-Estimate (current price)',
      legendColor: '',
      legendImageHeight: 18,
      legendImageWidth: 18,
      type: ChartLegendType.IMAGE,
    },
  ];

  private accessibleFilter: Accessible = Accessible.TotalStock;
  private stockPriceLevelFilter: StockPriceLevel = StockPriceLevel.FirstPriceLevel;
  private priceContext: ConsideredPriceType = ConsideredPriceType.CURRENT_PRICE;
  stockUnitsTooltipText: string = '';
  stockCoverageTooltipText: string = '';
  lineChartData!: ILineChartData;
  lineChartLegend: string[] = [];
  lineChartLegendForm!: FormGroup;
  lineChartLegendFieldNames: string[] = [];
  lineChartLegendsConfigs: IChartLegendConfig[] = [
    {
      fieldName: 'idealStockCoverageTS',
      imagePath: './assets/images/charts/legends/line-orange-yellow.svg',
      label: 'Total ISP Stock Coverage',
      legendColor: '#F8C237',
      legendImageHeight: 4,
      legendImageWidth: 30,
      type: ChartLegendType.IMAGE,
    },
    {
      fieldName: 'stockCoverageTS',
      imagePath: './assets/images/charts/legends/line-aqua.svg',
      label: 'Total Stock Coverage',
      legendColor: '#79DDC0',
      legendImageHeight: 4,
      legendImageWidth: 30,
      type: ChartLegendType.IMAGE,
    },
    {
      fieldName: 'stockCoverageForecastTS',
      imagePath: './assets/images/charts/legends/line-dotted-aqua.svg',
      label: 'Stock coverage NCPE-Estimation (current price)',
      legendColor: '#79DDC0',
      legendImageHeight: 3,
      legendImageWidth: 30,
      type: ChartLegendType.IMAGE,
    },
  ];

  chartAxisType = ChartAxisType.CATEGORY;
  initBarChart: boolean = false;
  initLineChart: boolean = false;
  dropdownList: string[] = ['Considered Price', 'Stock Category', 'Price Level'];
  isChartValuesVisible: boolean = false;
  dropdownStockPriceLevel: IFtdDropdownOption<StockPriceLevel>[] = [
    {
      id: StockPriceLevel.FirstPriceLevel,
      label: '1st price level',
      value: StockPriceLevel.FirstPriceLevel,
    },
    {
      id: StockPriceLevel.SecondPriceLevel,
      label: '2nd price level',
      value: StockPriceLevel.SecondPriceLevel,
    },
  ];

  dropdownAccessible: IFtdDropdownOption<Accessible>[] = [
    {
      id: Accessible.Accessible,
      label: 'Accessible',
      value: Accessible.Accessible,
    },
    {
      id: Accessible.TotalStock,
      label: 'Stock actuals',
      value: Accessible.TotalStock,
    },
  ];

  consideredPrice: IFtdDropdownOption<string>[] = [];

  totalAccessibleStock: ITotalAccessibleStockCoverage = {
    currentActualStockUnits: 0,
    currentStockCoverage: '',
    deltaCurrentStockCoverage: '',
    deltaCurrentToSoY: '',
    stockCoverageDisplayName: 'Total Accessible Stock Coverage',
    stockUnitsDisplayName: '',
  };

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param formBuilder
   * @param crosscheckService
   * @param contextService
   * @param decimalPipe
   */
  constructor(
    private formBuilder: FormBuilder,
    private crosscheckService: CrosschecksService,
    private contextService: ContextService,
    private decimalPipe: DecimalPipe
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initLineChart = false;
    this.initBarChart = false;
    this.initForm();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe();
  }

  /**
   * NgUnsubscribe
   */
  ngUnsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  showValuesToggle(value: boolean): void {
    this.isChartValuesVisible = value;
  }

  private initForm(): void {
    this.filtersForm = this.formBuilder.group({
      accessible: new FormControl<Accessible>({ disabled: true, value: Accessible.TotalStock }),
      consideredPrice: new FormControl<ConsideredPriceType>({
        disabled: false,
        value: ConsideredPriceType.CURRENT_PRICE,
      }),
      showValuesToggle: new FormControl<boolean>({ disabled: false, value: true }),
      stockPriceLevel: new FormControl<StockPriceLevel>({ disabled: false, value: StockPriceLevel.FirstPriceLevel }),
    });
    this.showValuesToggle(true);
    const showValuesToggleSubscription: Subscription =
      this.filtersForm.controls.showValuesToggle.valueChanges.subscribe((value: boolean): void => {
        this.showValuesToggle(value);
      });
    this.subscriptions.push(showValuesToggleSubscription);

    const consideredPriceSubscription = this.filtersForm.controls.consideredPrice.valueChanges.subscribe(
      (value: ConsideredPriceType): void => {
        this.priceContext = value;
        this.loadStockCoverageChartData();
      }
    );
    this.subscriptions.push(consideredPriceSubscription);

    const accessibleSubscription = this.filtersForm.controls.accessible.valueChanges.subscribe((selected) => {
      this.accessibleFilter = selected;
      this.loadStockCoverageChartData();
    });
    this.subscriptions.push(accessibleSubscription);

    const stockPriceLevelSubscription = this.filtersForm.controls.stockPriceLevel.valueChanges.subscribe((selected) => {
      this.stockPriceLevelFilter = selected;
      this.loadStockCoverageChartData();
    });
    this.subscriptions.push(stockPriceLevelSubscription);
    this.barChartLegendForm = this.formBuilder.group({
      stockCoverageBarChartFields: new FormControl<string[]>([]),
    });
    this.lineChartLegendForm = this.formBuilder.group({
      stockCoverageLineChartFields: new FormControl<string[]>([]),
    });

    const barChartLegendFormSubscription: Subscription =
      this.barChartLegendForm.controls.stockCoverageBarChartFields.valueChanges.subscribe(
        (selectedLegend: string[]): void => {
          this.barChartLegend = selectedLegend;
        }
      );
    this.subscriptions.push(barChartLegendFormSubscription);
    this.barChartLegendFieldNames = this.barChartLegendForm.controls.stockCoverageBarChartFields.value;

    const lineChartLegendFormSubscription: Subscription =
      this.lineChartLegendForm.controls.stockCoverageLineChartFields.valueChanges.subscribe(
        (selectedLegend: string[]): void => {
          this.lineChartLegend = selectedLegend;
        }
      );
    this.subscriptions.push(lineChartLegendFormSubscription);
    this.lineChartLegendFieldNames = this.lineChartLegendForm.controls.stockCoverageLineChartFields.value;
  }

  /**
   * LoadStockCoverageData
   * @private
   */
  private loadStockCoverageChartData(): void {
    this.crosschecksChartContext = CrosschecksChartContext.LOADING;

    if (this._granularity.market) {
      const subscription: Subscription = this.crosscheckService
        .getStockCoverageData(
          this._granularity.id!,
          this._granularity.market,
          this.contextService.scenarioId,
          this.accessibleFilter,
          this.stockPriceLevelFilter
        )
        .subscribe({
          error: (): void => {
            this.crosschecksChartContext = CrosschecksChartContext.ERROR;
          },
          next: (response): void => {
            if (response.stockCoverage!.length > 0 || response.stockUnits!.length > 0) {
              this.setConsideredPriceDropdown(response.scalar as IStockCoverageScalar);
              this.setStockCoverageBarChartDataResponseOnNext(response, this.priceContext);
              this.setStockCoverageLineChartDataResponseOnNext(response, this.priceContext);
              this.crosschecksChartContext = CrosschecksChartContext.CHART;
            } else {
              this.crosschecksChartContext = CrosschecksChartContext.NO_RESULTS_FOUND;
            }
          },
        });
      this.subscriptions.push(subscription);
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
    }
  }

  private setConsideredPriceDropdown(scalar: IStockCoverageScalar): void {
    this.consideredPrice = [
      {
        id: ConsideredPriceType.CURRENT_PRICE,
        label: `Current price | ${scalar.listPriceInclTax?.toFixed(2)}${this.currency}` ?? 'N/A',
        value: ConsideredPriceType.CURRENT_PRICE,
      },
      {
        id: ConsideredPriceType.PROPOSED_PRICE,
        label: `Scenario price |  ${scalar.listPriceInclTaxAdjFilled?.toFixed(2)}${this.currency}` ?? 'N/A',
        value: ConsideredPriceType.PROPOSED_PRICE,
      },
    ];
  }

  private setStockCoverageBarChartDataResponseOnNext(
    response: IStockCoverageCrosscheckData,
    priceContext: ConsideredPriceType
  ): void {
    this.barChartData = this.setBarChartDataGivenConsideredPrice(response, priceContext);
    this.barChartLegend = [];
    this.barChartData.headings.forEach((heading: IGenericChartHeadings): void => {
      this.barChartLegend.push(heading.key);
    });
    this.barChartLegendForm.get('stockCoverageBarChartFields')?.setValue(this.barChartLegend);
    this.stockUnitsTooltipText = response.tooltipText!.stockUnits!;
    this.totalAccessibleStock.stockUnitsDisplayName = 'Total Accessible Stock';
    this.totalAccessibleStock.currentActualStockUnits = response.scalar?.currentActualStockUnits!;
    this.totalAccessibleStock.deltaCurrentToSoY =
      this.tileDescriptionValue(response.scalar?.deltaCurrentToSoYActualStockUnits?.toString()) || '0';

    this.initBarChart = true;
  }

  setBarChartDataGivenConsideredPrice(
    response: IStockCoverageCrosscheckData,
    priceContext: ConsideredPriceType
  ): IBarChartData {
    switch (priceContext) {
      case ConsideredPriceType.CURRENT_PRICE:
        this.barChartLegendsConfigs[1].label = 'Stock NCPE-Estimate (current price)';
        this.barChartLegendsConfigs[1].fieldName = 'stockUnitsForecastTS';
        this.barChartLegendsConfigs[1].imagePath = this.imagePathCurrent;
        return {
          fields: response.stockUnits!.map((item) => {
            const date = dayjs(item?.date).toDate().getTime();
            const today = new Date().getTime();
            if (date <= today) {
              return {
                stockUnitsActualsTS: item?.stockUnitsActualsTS,
                xAxisValue: dayjs(item?.date).format("MMM 'YY"),
              };
            } else {
              return {
                stockUnitsForecastTS: item?.stockUnitsForecastTS,
                xAxisValue: dayjs(item?.date).format("MMM 'YY"),
              };
            }
          }),
          headings: [
            {
              description: this.dropdownAccessible.find(
                (selected) => selected.id === this.filtersForm.get('accessible')?.value
              )?.label as string,
              key: 'stockUnitsActualsTS',
            },
            { description: 'Stock NCPE-Estimate (current price)', key: 'stockUnitsForecastTS' },
          ],
        };

      case ConsideredPriceType.PROPOSED_PRICE:
        this.barChartLegendsConfigs[1].label = 'Stock NCPE-Estimate (scenario price)';
        this.barChartLegendsConfigs[1].fieldName = 'stockUnitsForecastTSAdj';
        this.barChartLegendsConfigs[1].imagePath = this.imagePathProposed;
        return {
          fields: response.stockUnits!.map((item) => {
            const date = dayjs(item?.date).toDate().getTime();
            const today = new Date().getTime();
            if (date <= today) {
              return {
                stockUnitsActualsTS: item?.stockUnitsActualsTS,
                xAxisValue: dayjs(item?.date).format("MMM 'YY"),
              };
            } else {
              return {
                stockUnitsForecastTSAdj: item?.stockUnitsForecastTSAdj,
                xAxisValue: dayjs(item?.date).format("MMM 'YY"),
              };
            }
          }),
          headings: [
            {
              description: this.dropdownAccessible.find(
                (selected) => selected.id === this.filtersForm.get('accessible')?.value
              )?.label as string,
              key: 'stockUnitsActualsTS',
            },
            { description: 'Stock NCPE-Estimate (scenario price)', key: 'stockUnitsForecastTSAdj' },
          ],
        };
      default:
        return {
          fields: [],
          headings: [],
        };
    }
  }

  setLineChartDataGivenConsideredPrice(
    response: IStockCoverageCrosscheckData,
    priceContext: ConsideredPriceType
  ): IBarChartData {
    switch (priceContext) {
      case ConsideredPriceType.CURRENT_PRICE:
        return {
          fields: response.stockCoverage!.map((item) => {
            const date = dayjs(item!.date).toDate().getTime();
            const today = new Date().getTime();
            if (date <= today) {
              return {
                idealStockCoverageTS: item?.idealStockCoverageTS,
                stockCoverageTS: item?.stockCoverageTS,
                xAxisValue: dayjs(item!.date).format("MMM 'YY"),
              };
            } else {
              return {
                stockCoverageForecastTS: item?.stockCoverageForecastTS,
                xAxisValue: dayjs(item!.date).format("MMM 'YY"),
              };
            }
          }),
          headings: [
            {
              description: 'Total ISP Stock Coverage',
              key: 'idealStockCoverageTS',
            },
            {
              description: 'Total Stock Coverage',
              key: 'stockCoverageTS',
            },
            {
              description: 'Stock coverage NCPE-Estimation (current price)',
              key: 'stockCoverageForecastTS',
            },
          ],
        };

      case ConsideredPriceType.PROPOSED_PRICE:
        return {
          fields: response.stockCoverage!.map((item) => {
            const date = dayjs(item!.date).toDate().getTime();
            const today = new Date().getTime();
            if (date <= today) {
              return {
                idealStockCoverageTS: item?.idealStockCoverageTS,
                stockCoverageTSAdj: item?.stockCoverageTSAdj,
                xAxisValue: dayjs(item?.date).format("MMM 'YY"),
              };
            } else {
              return {
                stockCoverageForecastTSAdj: item?.stockCoverageForecastTSAdj,
                xAxisValue: dayjs(item?.date).format("MMM 'YY"),
              };
            }
          }),
          headings: [
            {
              description: 'Total ISP Stock Coverage',
              key: 'idealStockCoverageTS',
            },
            {
              description: 'Total Stock Coverage',
              key: 'stockCoverageTSAdj',
            },
            {
              description: 'Stock coverage NCPE-Estimation (scenario price)',
              key: 'stockCoverageForecastTSAdj',
            },
          ],
        };
      default:
        return {
          fields: [],
          headings: [],
        };
    }
  }

  private setStockCoverageLineChartDataResponseOnNext(
    response: IStockCoverageCrosscheckData,
    priceContext: ConsideredPriceType
  ): void {
    this.lineChartData = this.setLineChartDataGivenConsideredPrice(response, priceContext);
    this.lineChartLegend = [];
    this.lineChartData.headings.forEach((heading: IGenericChartHeadings): void => {
      this.lineChartLegend.push(heading.key);
    });
    this.lineChartLegendForm.get('stockCoverageLineChartFields')?.setValue(this.lineChartLegend);
    this.stockCoverageTooltipText = response.tooltipText!.stockCoverage!;
    this.totalAccessibleStock.currentStockCoverage = `${
      response.scalar?.currentStockCoverage ? response.scalar?.currentStockCoverage?.toFixed(1) : APP_SYMBOLS.NA
    } Months`;
    this.totalAccessibleStock.deltaCurrentStockCoverage =
      this.tileDescriptionValue(response.scalar?.deltaCurrentStockCoverageToISP?.toFixed(1)) ?? 'N/A';
    this.initLineChart = true;
    if (this.priceContext === ConsideredPriceType.CURRENT_PRICE) {
      this.lineChartLegendsConfigs[2].label = 'Stock coverage NCPE-Estimation (current price)';
      this.lineChartLegendsConfigs[2].fieldName = 'stockCoverageForecastTS';
      this.lineChartLegendsConfigs[2].imagePath = this.imageStockLineCurrent;
      this.lineChartLegendsConfigs[1].fieldName = 'stockCoverageTS';
    } else {
      this.lineChartLegendsConfigs[2].label = 'Stock coverage NCPE-Estimation (scenario price)';
      this.lineChartLegendsConfigs[2].fieldName = 'stockCoverageForecastTSAdj';
      this.lineChartLegendsConfigs[2].imagePath = this.imageStockLineProposed;
      this.lineChartLegendsConfigs[1].fieldName = 'stockCoverageTSAdj';
    }
    const dynamicLabel = this.priceContext === ConsideredPriceType.CURRENT_PRICE ? 'current price' : 'scenario price';

    this.lineChartLegendsConfigs[2].label = `Stock coverage NCPE-Estimation (${dynamicLabel})`;
  }

  private tileDescriptionValue(value: string | undefined): string | undefined {
    if (value) {
      return parseInt(value) > 0 ? `+${value}` : value;
    } else {
      return 'N/A';
    }
  }

  setColorString(value: number): DisplayNameColor {
    return value > 0 ? DisplayNameColor.green : DisplayNameColor.red;
  }
}
