/**
 *
 * @Directive
 * @appFtdCurrencyMask
 * Available options:
 *
 * align - Text alignment in input. (default: right)
 * allowNegative - If true can input negative values. (default: true)
 * decimal - Separator of decimals (default: '.')
 * precision - Number of decimal places (default: 2)
 * prefix - Money prefix (default: '$ ')
 * suffix - Money suffix (default: '')
 * thousands - Separator of thousands (default: ',')
 *
 * Default options
 * optionsTemplate = {
 *   align: 'right',
 *   allowNegative: true,
 *   decimal: '.',
 *   precision: 2,
 *   prefix: '',
 *   suffix: '',
 *   thousands: ',',
 * };
 *
 */
export * from './lib/currency-mask.config';
export * from './lib/currency-mask.directive';
export * from './lib/currency-mask.module';
