import { Component, Input } from '@angular/core';
import {
  CrosschecksChartContext,
  CrosschecksChartContextImagePaths,
  CrosschecksChartContextMessages,
} from '../../enums/crosschecks-chart-context-messages.enum';
import { IGranularityDto } from '../../../matrix-view/models/api.model';
import { SkeletonThemeKey } from 'src/app/common/components/ftd-skeleton/ftd-skeleton.theme';
import { TrafficLightsColor } from '../../types/traffic-lights.type';

@Component({
  selector: 'app-crosschecks-chart-wrapper',
  styleUrls: ['./crosschecks-chart-wrapper.component.scss'],
  templateUrl: './crosschecks-chart-wrapper.component.html',
})
export class CrosschecksChartWrapperComponent {
  imagePlaceholderPathToAssets: string = CrosschecksChartContextImagePaths.APPLY_FILTERS_SVG_PATH;
  _context: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  LOADING = CrosschecksChartContext.LOADING;
  CHART = CrosschecksChartContext.CHART;

  @Input() message?: string;
  @Input() subMessage?: string;
  @Input() skeletonTheme: SkeletonThemeKey = 'OrderIntake';
  @Input() crosscheckIndicationVisible: boolean = false;
  @Input() trafficLightStatus: TrafficLightsColor = 'grey';
  @Input() trafficLightAdjStatus: TrafficLightsColor = 'grey';
  @Input() alternativeModelHeaderText?: string;
  private _filters!: IGranularityDto;

  @Input() set context(value: CrosschecksChartContext) {
    this._context = value;
    this.setContext();
  }

  get context(): CrosschecksChartContext {
    return this._context;
  }

  @Input() set filters(value: IGranularityDto) {
    this._filters = value;
  }

  get filters(): IGranularityDto {
    return this._filters;
  }

  setContext(): void {
    switch (this.context) {
      case CrosschecksChartContext.NO_FILTER_APPLIED: {
        this.imagePlaceholderPathToAssets = CrosschecksChartContextImagePaths.APPLY_FILTERS_SVG_PATH;
        this.message = CrosschecksChartContextMessages.APPLY_FILTERS_SVG_PATH_MESSAGE;
        this.subMessage = CrosschecksChartContextMessages.APPLY_FILTERS_SVG_PATH_SUB_MESSAGE;
        break;
      }
      case CrosschecksChartContext.NO_RESULTS_FOUND: {
        this.imagePlaceholderPathToAssets = CrosschecksChartContextImagePaths.NO_RESULTS_FOUND_SVG_PATH;
        this.message = CrosschecksChartContextMessages.NO_RESULTS_FOUND_SVG_PATH_MESSAGE;
        this.subMessage = CrosschecksChartContextMessages.NO_RESULTS_FOUND_SVG_PATH_SUB_MESSAGE;
        break;
      }
      case CrosschecksChartContext.ERROR: {
        this.imagePlaceholderPathToAssets = CrosschecksChartContextImagePaths.ERROR_SVG_PATH;
        this.message = CrosschecksChartContextMessages.ERROR_SVG_PATH_MESSAGE;
        this.subMessage = CrosschecksChartContextMessages.ERROR_SVG_PATH_SUB_MESSAGE;
        break;
      }
      case CrosschecksChartContext.CHART:
      case CrosschecksChartContext.LOADING: {
        this.imagePlaceholderPathToAssets = '';
        this.message = '';
        this.subMessage = '';
        break;
      }
    }
  }

  get modelHeaderText(): string {
    return `${this.filters.brand || ''} ${this.filters.series || ''}`;
  }

  get modelHeaderSubText(): string {
    return ` ${this.filters.eSeries || ''}`;
  }

  get modelHeaderSubTextModel(): string {
    return `${this.filters.model !== 'ALL' ? this.filters.model || '' : ''}`;
  }

  get modelHeaderSubTextModelCode(): string {
    return `${this.filters.type === 'MODEL_CODE' ? this.filters.modelCode : ''} `;
  }

  get isChartEmpty(): boolean {
    return (
      this._context === CrosschecksChartContext.ERROR ||
      this._context === CrosschecksChartContext.NO_RESULTS_FOUND ||
      this._context === CrosschecksChartContext.NO_FILTER_APPLIED
    );
  }
}
