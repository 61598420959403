<app-ftd-expanded-tooltip [closeMenuOnMouseLeave]="true" [hasBackdrop]="false">
  <div tooltipTrigger class="height-100">
    <div class="height-100">
      <span class="header-text capitalize">{{ columnData.displayName }}</span>
      <span class="header-sub-text" *ngIf="columnData.secondaryDisplayName">
        | {{ columnData.secondaryDisplayName }}</span
      >
    </div>
  </div>
  <div tooltipContent class="tooltip-wrapper" *ngIf="isTooltipVisible">
    <div>
      <span *ngIf="columnData.metadata?.description">{{ columnData.metadata?.description | nullLabel }}</span>
      <mat-divider class="divider" *ngIf="columnData.metadata?.description"></mat-divider>
    </div>
    <div class="expanded-tooltip-wrapper">
      <span>Source: {{ columnData.metadata?.source | nullLabel }}</span>
      <span>{{ lastSourceCall }}: {{ columnData.metadata?.dataIngestionDate | date: 'd.M.yy' | nullLabel }}</span>
    </div>
  </div>
</app-ftd-expanded-tooltip>
