<ftd-tab-group [selectedIndex]="selectedTab" (tabIndexChange)="changeTab($event)">
  <ftd-tab>
    <ftd-tab-label
      tabLabel
      [title]="'Internal Price & Lease Rate Ladder'"
      [percentage]="trafficLightInternalPriceAndLeaseRateLadder?.weight || ''"
      [trafficLights]="!!trafficLightInternalPriceAndLeaseRateLadder"
      [trafficLightColor]="trafficLightInternalPriceAndLeaseRateLadder?.color"
      [status]="internalPriceLadderStatus"
      [info]="true"
      [infoText]="tooltips?.internalPriceLadder"
    ></ftd-tab-label>
    <app-internal-price-ladder
      [granularity]="filters"
      *ngIf="isSelectedTabInternalPriceLadder"
      [crosscheckIndication]="crosscheckIndication"
      [crosscheckIndicationForecasted]="crosscheckIndicationForecasted"
    ></app-internal-price-ladder>
  </ftd-tab>
  <ftd-tab [disabled]="true">
    <ftd-tab-label
      tabLabel
      [title]="'Price & Lease Rate Distribution'"
      [info]="true"
      [infoText]="tooltips?.priceDistribution"
    ></ftd-tab-label>
  </ftd-tab>
  <ftd-tab [disabled]="true">
    <ftd-tab-label
      tabLabel
      [title]="'Internal Price & Lease Rate Development'"
      [info]="true"
      [infoText]="tooltips?.internalLeaseRateLadder"
    ></ftd-tab-label>
  </ftd-tab>
</ftd-tab-group>
