import { Base64Utils } from '../../../../common/utils/base64.utils';
import { ChartAxisType } from '../../../../common/components/ftd-charts/enums/ftd-generic-chart.enum';
import { ChartLegendType } from '../../../../common/components/ftd-charts/enums/chart-legend-type.enum';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from 'src/app/matrix-view/services/context/context.service';
import { CrosschecksChartContext } from '../../../enums/crosschecks-chart-context-messages.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import { DisplayNameColor } from 'src/app/common/components/ftd-charts/ftd-chart-info-tile/ftd-chart-info-tile.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IChartLegendConfig } from '../../../../common/components/ftd-charts/models/ftd-chart-legend-config.model';
import { IFtdDropdownOption } from '../../../../common/models/ftd-dropdown-option.model';
import { IGenericChartHeadings } from '../../../../common/components/ftd-charts/models/ftd-generic-chart.model';
import {
  IGranularityDto,
  IOpenVolumeYe,
  IRetailSalesData,
  IRetailSalesScalarProps,
  IYaxisRetailSales,
} from '../../../../matrix-view/models/api.model';
import { ILineChartData } from '../../../../common/components/ftd-charts/models/ftd-line-chart.model';
import { RetailSeriesDisplayName, RetailTimeSeriesProps } from '../../../enums/retail-time-series-props.enum';
import { SalesPipelineCrossCheckType } from 'src/app/graphql/services/gql-api.service';
import { Subscription } from 'rxjs';
import { TrafficLightsColor } from 'src/app/crosschecks/types/traffic-lights.type';
import dayjs from 'dayjs';

@Component({
  selector: 'app-retail-sales',
  styleUrls: ['./retail-sales.component.scss'],
  templateUrl: './retail-sales.component.html',
})
export class RetailSalesComponent implements OnInit, OnDestroy {
  /**
   * Granularity for which chart data need to be fetched
   */
  filters!: IGranularityDto;
  private _granularity: IGranularityDto = <IGranularityDto>{};

  @Input() set granularity(granularityData: string) {
    this._granularity = Base64Utils.decodeAtobToJson(granularityData);
    this.filters = this._granularity;
    this.loadRetailSalesData();
  }

  /**
   * Traffic light value coming from overview request on Sales Pipeline
   */
  @Input() crosscheckIndication?: TrafficLightsColor;
  @Input() crosscheckIndicationForecasted?: TrafficLightsColor;

  chartData!: ILineChartData;
  openVolumeYe!: IOpenVolumeYe;
  budgetVolumeRetail!: number;
  retailCount!: number;
  scalarRetailSalesData!: IRetailSalesScalarProps;
  expectedVolumeRetail!: number;
  crosschecksChartContext: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  chartLegend: string[] = [];
  chartLegendForm!: FormGroup;
  chartLegendConfigs: IChartLegendConfig[] = [];
  isChartValuesVisible: boolean = false;
  dropdownDisabled: boolean[] = [true];
  dropdownList: string[] = ['Sales Channel'];
  dropdownOptions: IFtdDropdownOption<[string]>[][] = [
    [
      {
        id: 1,
        label: 'Private',
        value: ['private'],
      },
      {
        id: 2,
        label: 'Employee sales',
        value: ['employeeSales'],
      },
      {
        id: 3,
        label: 'Corporate/Professional',
        value: ['corporateProfessional'],
      },
      {
        id: 4,
        label: 'Corporate/Business',
        value: ['corporateBusiness'],
      },
      {
        id: 5,
        label: 'Corporate/Fleet',
        value: ['corporateFleet'],
      },
    ],
  ];

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param formBuilder
   * @param crosscheckService
   * @param contextService
   */
  constructor(
    private formBuilder: FormBuilder,
    private crosscheckService: CrosschecksService,
    private contextService: ContextService
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe();
  }

  /**
   * NgUnsubscribe
   */
  ngUnsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  setColorString(value: number): DisplayNameColor {
    return value > 0 ? DisplayNameColor.green : DisplayNameColor.red;
  }

  /**
   * InitForm
   * @private
   */
  private initForm(): void {
    this.chartLegendForm = this.formBuilder.group({
      retailSalesFields: new FormControl<string[]>([]),
    });

    const chartLegendFormSubscription: Subscription =
      this.chartLegendForm.controls.retailSalesFields.valueChanges.subscribe((selectedLegend: string[]): void => {
        this.chartLegend = selectedLegend;
      });
    this.subscriptions.push(chartLegendFormSubscription);

    this.chartLegend = this.chartLegendForm.controls.retailSalesFields.value;
  }

  /**
   * LoadRetailSalesData
   * @private
   */
  private loadRetailSalesData(): void {
    this.crosschecksChartContext = CrosschecksChartContext.LOADING;
    const subscription: Subscription = this.crosscheckService
      .getSalesPipelineRetailsSalesData(
        this._granularity,
        this.contextService.scenarioId,
        SalesPipelineCrossCheckType.Retail
      )
      .subscribe({
        error: (): void => {
          this.crosschecksChartContext = CrosschecksChartContext.ERROR;
        },
        next: (response: IRetailSalesData): void => {
          this.setRetailSalesDataResponseOnNext(response);
        },
      });
    this.subscriptions.push(subscription);
  }

  /**
   * ShowValuesToggle
   * @param value
   */
  showValuesToggle(value: boolean): void {
    this.isChartValuesVisible = value;
  }

  /**
   * SetRetailSalesDataResponse
   * @private
   * @param response
   */
  private setRetailSalesDataResponseOnNext(response: IRetailSalesData): void {
    if (response.yaxis.length > 0) {
      this.chartData = {
        fields: response.yaxis.map((item: IYaxisRetailSales) => {
          return {
            ...item,
            xAxisValue: dayjs(item?.date).format("MMM 'YY"),
          };
        }),
        headings: response.timeSeriesProperties.map((timeSeries: string) => {
          return {
            description: this._granularity.series,
            key: timeSeries.split('FC')[0],
          };
        }),
      };
      this.scalarRetailSalesData = response.scalar;

      // Set up chart legend
      this.setRetailSalesChartLegendConfig(response.timeSeriesProperties);
      this.crosschecksChartContext = CrosschecksChartContext.CHART;
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_RESULTS_FOUND;
    }
  }

  /**
   * SetRetailChartLegendConfig
   * @private
   * @param keys
   */
  private setRetailSalesChartLegendConfig(keys: string[]): void {
    this.chartLegend = [];
    this.chartData.headings.forEach((heading: IGenericChartHeadings): void => {
      this.chartLegend.push(heading.key);
    });
    this.chartLegendForm.get('retailSalesFields')?.setValue(this.chartLegend);

    this.chartLegendConfigs = [];
    keys.forEach((key: string): void => {
      switch (key.split('FC')[0]) {
        case RetailTimeSeriesProps.actualsThisYear:
          this.chartLegendConfigs.push({
            fieldName: RetailTimeSeriesProps.actualsThisYear,
            imageAlt: 'Yellow line from chart legend',
            imagePath: './assets/images/charts/legends/line-yellow.svg',
            label: RetailSeriesDisplayName.actualsThisYear,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 0,
            type: ChartLegendType.IMAGE,
          });
          break;
        case RetailTimeSeriesProps.actualsPreviousYear:
          this.chartLegendConfigs.push({
            fieldName: RetailTimeSeriesProps.actualsPreviousYear,
            imageAlt: 'Orange line from chart legend',
            imagePath: './assets/images/charts/legends/line-orange.svg',
            label: RetailSeriesDisplayName.actualsPreviousYear,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 1,
            type: ChartLegendType.IMAGE,
          });
          break;
        case RetailTimeSeriesProps.retailBudget:
          this.chartLegendConfigs.push({
            fieldName: RetailTimeSeriesProps.retailBudget,
            imageAlt: 'Green line from chart legend',
            imagePath: './assets/images/charts/legends/line-green.svg',
            label: RetailSeriesDisplayName.retailBudget,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 2,
            type: ChartLegendType.IMAGE,
          });
          break;
        case RetailTimeSeriesProps.retailSecondLatestForecast:
          this.chartLegendConfigs.push({
            fieldName: RetailTimeSeriesProps.retailSecondLatestForecast,
            imageAlt: 'Blue dotted line from chart legend',
            imagePath: './assets/images/charts/legends/line-dotted-blue.svg',
            label: `${RetailSeriesDisplayName.retailSecondLatestForecast} (FC${key.split('FC')[1]})`,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 3,
            type: ChartLegendType.IMAGE,
          });
          break;
        case RetailTimeSeriesProps.retailLatestForecast:
          this.chartLegendConfigs.push({
            fieldName: RetailTimeSeriesProps.retailLatestForecast,
            imageAlt: 'Pink dotted line from chart legend',
            imagePath: './assets/images/charts/legends/line-dotted-pink.svg',
            label: `${RetailSeriesDisplayName.retailLatestForecast} (FC${key.split('FC')[1]})`,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 4,
            type: ChartLegendType.IMAGE,
          });
          break;
        case RetailTimeSeriesProps.enginePredictedRetailVolume:
          this.chartLegendConfigs.push({
            fieldName: RetailTimeSeriesProps.enginePredictedRetailVolume,
            imageAlt: 'Purple dotted line from chart legend',
            imagePath: './assets/images/charts/legends/line-dotted-purple.svg',
            label: RetailSeriesDisplayName.enginePredictedRetailVolume,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 5,
            type: ChartLegendType.IMAGE,
          });
          break;
        case RetailTimeSeriesProps.expectedVolumeRetailAdjTS:
          this.chartLegendConfigs.push({
            fieldName: RetailTimeSeriesProps.expectedVolumeRetailAdjTS,
            imageAlt: 'Green dotted line from chart legend',
            imagePath: './assets/images/charts/legends/line-dotted-aqua.svg',
            label: RetailSeriesDisplayName.expectedVolumeRetailAdjTS,
            legendImageHeight: 4,
            legendImageWidth: 30,
            order: 6,
            type: ChartLegendType.IMAGE,
          });
          break;
      }
    });
    this.chartLegendConfigs.sort(
      (configA: IChartLegendConfig, configB: IChartLegendConfig) => configA.order! - configB.order!
    );
  }

  protected readonly ChartAxisType = ChartAxisType;
}
