import { AppThemeColors } from '../../../../../common/constants/app-theme-colors';
import { ChipSize } from '../../../../../common/components/ftd-chip/ftd-chip-size.enum';
import { Component, Input } from '@angular/core';
import { PriceApprovalChipInModalStatus } from '../price-approval-status-chip-modal.component';

@Component({
  selector: 'app-price-approval-status-chip-modal-item',
  styleUrls: ['./price-approval-status-chip-modal-item.component.scss'],
  templateUrl: './price-approval-status-chip-modal-item.component.html',
})
export class PriceApprovalStatusChipModalItemComponent {
  @Input() item!: IPriceApprovalStatusChipModalItem;

  chipStyleMap: Map<PriceApprovalChipInModalStatus, IPriceApprovalStatusChipModalItemChipStyle> = new Map([
    [
      PriceApprovalChipInModalStatus.pending,
      {
        backgroundColor: AppThemeColors['ftd-status-pending']['100'],
        textColor: AppThemeColors['ftd-status-pending']['300'],
      },
    ],
    [
      PriceApprovalChipInModalStatus.approved,
      {
        backgroundColor: AppThemeColors['ftd-positive']['default'],
        textColor: AppThemeColors['ftd-opportunity']['200'],
      },
    ],
    [
      PriceApprovalChipInModalStatus.done,
      {
        backgroundColor: AppThemeColors['ftd-positive']['default'],
        textColor: AppThemeColors['ftd-opportunity']['200'],
      },
    ],
    [
      PriceApprovalChipInModalStatus.refused,
      {
        backgroundColor: AppThemeColors['ftd-negative']['default'],
        textColor: AppThemeColors['ftd-risk']['200'],
      },
    ],
    [
      PriceApprovalChipInModalStatus.not_available,
      {
        backgroundColor: AppThemeColors['ftd-grey']['500'],
        textColor: AppThemeColors['ftd-grey']['700'],
      },
    ],
  ]);

  CHIP_SIZE = ChipSize;

  get chipStyle(): IPriceApprovalStatusChipModalItemChipStyle {
    return this.chipStyleMap.get(this.item.status) as IPriceApprovalStatusChipModalItemChipStyle;
  }

  get statusCssClass(): string {
    return this.item?.status?.replace(' ', '_')?.toLowerCase();
  }
}

export interface IPriceApprovalStatusChipModalItem {
  stepNumber: number;
  title: string;
  description: string;
  status: PriceApprovalChipInModalStatus;
  showDivider?: boolean;
  chipStyle: IPriceApprovalStatusChipModalItemChipStyle;
}

export interface IPriceApprovalStatusChipModalItemChipStyle {
  textColor: string;
  backgroundColor: string;
}
