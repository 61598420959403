import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { HttpLoaderService } from 'src/app/common/services/http-loader/http-loader.service';
import { Injectable, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable()
export class HttpLoaderInterceptor implements HttpInterceptor {
  private _httpLoaderStore = inject(HttpLoaderService);

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // Set the global loader to true
    this._httpLoaderStore.waitingForResponse = true;

    // Once after response is received set loadint o false
    return next.handle(request).pipe(finalize(() => (this._httpLoaderStore.waitingForResponse = false)));
  }
}
