import { Component, Input, OnInit } from '@angular/core';
import { MatrixColumnTechnicalName } from '../../../enums/matrix-column-technical-name.enum';

export const PREDICTION_EVALUATION_INDICATOR_DESCRIPTION_MAP: Map<number, string> = new Map<number, string>([
  [1, 'Preferred'],
  [2, 'Secondary'],
  [3, 'Secondary'],
  [4, 'Fallback'],
]);

@Component({
  selector: 'app-discount-mv-prediction-evaluation-indicator',
  styleUrls: ['./mv-prediction-evaluation-indicator.component.scss'],
  templateUrl: './mv-prediction-evaluation-indicator.component.html',
})

/**
 * @class MvPredictionEvaluationIndicatorComponent
 * @implements OnInit
 */
export class MvPredictionEvaluationIndicatorComponent implements OnInit {
  @Input() textHolder!: any;
  @Input() technicalName?: MatrixColumnTechnicalName;

  // Indicator private value
  indicator: number = 1;

  // Indicator description map
  predictionEvaluationIndicatorDescriptionMap: Map<number, string> = PREDICTION_EVALUATION_INDICATOR_DESCRIPTION_MAP;

  /**
   * NgOnInit
   * Set indicator value
   */
  ngOnInit(): void {
    this.indicator = this.textHolder[this.technicalName || ''] || 0;
  }
}
