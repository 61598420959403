import { AuthService } from '../../../../../auth/services/auth.service';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService, DiscountContext } from '../../services/context/context.service';
import { DiscountMatrixViewActionsToolbarComponent } from '../matrix-view-actions-toolbar/matrix-view-actions-toolbar.component';
import { DiscountMatrixViewFormService } from '../../services/matrix-view-form/matrix-view-form.service';
import { FormGroup } from '@angular/forms';
import { FtdFullscreenService } from 'src/app/common/services/ftd-fullscreen/ftd-fullscreen.service';
import { FtdNotifierService } from 'src/app/common/services/ftd-notifier/ftd-notifier.service';
import { IDiscount } from '../../../../../graphql/services/gql-api.service';
import { ISelectedFiltersFormConfirmed } from '../../models/app.model';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SUBMIT_SCENARIO_MESSAGES } from '../../../../../matrix-view/components/matrix-view-table-header/matrix-view-table-header.component';
import { Subscription } from 'rxjs';
import { User } from '../../../../../auth/models/user.model';

@Component({
  selector: 'app-discount-matrix-view-wrapper',
  styleUrls: ['./matrix-view-wrapper.component.scss'],
  templateUrl: './matrix-view-wrapper.component.html',
})
export class DiscountMatrixViewWrapperComponent implements OnInit, OnDestroy {
  @Input() title: string = 'Main Scenario';
  @Input() isReturnButtonHidden: boolean = true;
  @Input() isMatrixViewSideFiltersHidden: boolean = false;
  @Input() isMatrixViewDataHidden: boolean = false;
  @Input() isDescriptionHidden: boolean = true;
  @Input() isExportButtonHidden: boolean = false;
  @Input() isApproveScenarioButtonHidden: boolean = true;
  @Input() isUnlockScenarioButtonHidden: boolean = true;
  @Input() isSaveButtonHidden: boolean = true;
  @Input() isSubmitScenarioButtonHidden: boolean = true;
  @Input() isTitleHidden: boolean = true;
  @Input() description: string = '';
  @Input({ required: true }) mode!: DiscountContext;
  @Input({ required: true }) discount!: IDiscount;
  selectedFiltersValue!: ISelectedFiltersFormConfirmed;
  granularityDataLoaded: boolean = false;
  granularityHasData: boolean = false;
  isFullScreenDisabled: boolean = true;

  public currentUser?: User | null;

  isSaveDisabled: boolean = true;
  private isSubmitScenarioButtonDisabled: boolean = true;

  private matSnackBarRef: MatSnackBarRef<DiscountMatrixViewActionsToolbarComponent> | null = null;

  private formValidRowsChecked: FormGroup[] = [];
  formValidRows: FormGroup[] = [];
  private formRowsChecked: FormGroup[] = [];

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param fullscreenService
   * @param toastService
   * @param authService
   * @param matrixViewFormService
   * @param contextService
   * @param snackBar
   */
  constructor(
    private fullscreenService: FtdFullscreenService,
    private toastService: FtdNotifierService,
    private authService: AuthService,
    private matrixViewFormService: DiscountMatrixViewFormService,
    private contextService: ContextService,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.currentUser = this.authService.getLoggedInUser();
    this.contextService.setCurrentDiscountContext(this.mode);
    this.initMatrixViewFormValueChanged();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  private initMatrixViewFormValueChanged(): void {
    const subscription: Subscription = this.matrixViewFormService.formValueChangedSubject$.subscribe((): void => {
      this.formValidRowsChecked = this.matrixViewFormService.getFormValidRowsChecked();
      this.formValidRows = this.matrixViewFormService.getFormValidRows();
      this.triggerActionsToolbarHandler();
    });
    this.subscriptions.push(subscription);
  }

  private triggerActionsToolbarHandler(): void {
    if (
      (this.matrixViewFormService.getFormRowsChecked().length &&
        this.matrixViewFormService.getFormRowsChecked().length !== this.formRowsChecked.length) ||
      (this.matrixViewFormService.getFormRowsChecked().length &&
        this.isSubmitScenarioButtonDisabled !==
          !(this.formValidRowsChecked.length && this.matrixViewFormService.getForm().valid))
    ) {
      this.isSubmitScenarioButtonDisabled = !(
        this.formValidRowsChecked.length && this.matrixViewFormService.getForm().valid
      );
      this.actionsToolbarHandler();
    } else if (!this.matrixViewFormService.getFormRowsChecked().length) {
      this.snackBar?.dismiss();
      this.matSnackBarRef = null;
    }
    this.isSaveDisabled = !this.formValidRows.length;
    this.formRowsChecked = this.matrixViewFormService.getFormRowsChecked();
  }

  private actionsToolbarHandler(): void {
    if (!this.matSnackBarRef) {
      this.matSnackBarRef = this.snackBar.openFromComponent(DiscountMatrixViewActionsToolbarComponent, {
        data: {
          actions: {
            isEditDiscounts: true,
          },
          discount: this.discount,
          matrixViewDataSourceItems: this.matrixViewFormService
            .getFormRowsChecked()
            .map((item: FormGroup) => item.value.scenarioData),
          message: `${this.matrixViewFormService.getFormRowsChecked().length} Model(s) selected`,
        },
        horizontalPosition: 'center',
        panelClass: 'ftd-actions-toolbar',
      });
    } else {
      this.matSnackBarRef.instance.data.matrixViewDataSourceItems = this.matrixViewFormService
        .getFormRowsChecked()
        .map((item: FormGroup) => item.value.scenarioData);
      this.matSnackBarRef.instance.data.message = `${
        this.matrixViewFormService.getFormRowsChecked().length
      } Model(s) selected`;
    }
  }

  get isFilterSelected(): boolean {
    return (this.selectedFiltersValue as ISelectedFiltersFormConfirmed).selectedFilterList.length > 0;
  }

  get isFullScreenDisabledClass(): string {
    return this.isFullScreenDisabled ? 'matrix-view-data-container' : 'matrix-view-data-container-fullscreen';
  }

  /**
   * EnableFullscreen
   */
  enableFullscreen(): void {
    this.fullscreenService.isFullscreenDisable = !this.fullscreenService.isFullscreenDisable;
    this.isFullScreenDisabled = this.fullscreenService.isFullscreenDisable;
    if (!this.isFullScreenDisabled) {
      const duration: number = 0;
      const showCloseButton: true = true;
      this.toastService.showInfo('Press [Alt + Enter] to exit full screen', duration, showCloseButton);
    }
  }

  /**
   * HandleSelectFiltersValue
   * @param selectedValue
   */
  handleSelectFiltersValue(selectedValue: ISelectedFiltersFormConfirmed): void {
    this.selectedFiltersValue = selectedValue;
  }

  /**
   * HandleGranularityDataLoaded
   * @param granularityDataLoaded
   */
  handleGranularityDataLoaded(granularityDataLoaded: boolean): void {
    this.granularityDataLoaded = granularityDataLoaded;
  }

  /**
   * HandleGranularityHasData
   * @param granularityHasData
   */
  handleGranularityHasData(granularityHasData: boolean): void {
    this.granularityHasData = granularityHasData;
  }

  /**
   * @host
   * @listens keydown: alt + enter
   */
  @HostListener('document:keydown.alt.enter', ['$event'])
  onKeyDown() {
    this.enableFullscreen();
  }

  /**
   * SubmitScenarioDisableStateMessage
   * @return string
   */
  submitScenarioDisableStateMessage(): string {
    switch (true) {
      case !(this.matrixViewFormService.getForm().touched || this.matrixViewFormService.getForm().dirty):
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_PRICE_AND_DATE;
      case this.isSubmitScenarioButtonDisabled:
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_PRICE_AND_DATE;
      default:
        return '';
    }
  }
}
