<div class="main-filter-container">
  <mat-card class="country-card">
    <span class="country-title">Country</span>
    <span>{{ marketLabel }}</span>
  </mat-card>
  <div class="vertical-divider"></div>
  <form [formGroup]="crosschecksForm" novalidate class="main-filter-form">
    <div class="crosschecks-filters">
      <app-ftd-dropdown
        [isRadioButtonVisible]="true"
        [form]="crosschecksForm"
        [label]="'Brand'"
        [controlName]="'brand'"
        [options]="dropdownBrandList"
      ></app-ftd-dropdown>
      <app-ftd-dropdown
        [isRadioButtonVisible]="true"
        [form]="crosschecksForm"
        [label]="'Series'"
        [controlName]="'series'"
        [options]="dropdownSeriesList"
      ></app-ftd-dropdown>
      <app-ftd-dropdown
        [isRadioButtonVisible]="true"
        [form]="crosschecksForm"
        [label]="'E-Series'"
        [controlName]="'eSeries'"
        [options]="dropdownESeriesList"
      ></app-ftd-dropdown>
      <app-ftd-dropdown
        [isRadioButtonVisible]="true"
        [form]="crosschecksForm"
        [label]="'Model'"
        [controlName]="'model'"
        [options]="dropdownModelList"
      ></app-ftd-dropdown>
      <app-ftd-dropdown
        [isRadioButtonVisible]="true"
        [form]="crosschecksForm"
        [label]="'Model Code'"
        [controlName]="'modelCode'"
        [options]="dropdownModelCodeList"
      ></app-ftd-dropdown>
    </div>
  </form>
  <app-ftd-button
    [disabled]="isApplyBtnDisabled"
    (btnClick)="applyFilter()"
    ariaText="Apply Filter"
    [label]="'Apply'"
  ></app-ftd-button>
</div>
