<mat-toolbar class="matrix-view-subheader" fxLayout="row" fxLayoutAlign="space-between end">
  <div class="title-container-return-button-container">
    <div class="return-button-container" *ngIf="!isReturnButtonHidden">
      <app-ftd-breadcrumbs></app-ftd-breadcrumbs>
    </div>
    <div class="title-container">
      <h2>{{ title }}</h2>
    </div>
  </div>

  <div
    class="matrix-view-subheader-button-wrapper"
    fxLayout="row"
    *ngIf="
      currentUser?.permissions?.getHasPermissionToCreateUserScenario() &&
      !isCreateScenarioHidden &&
      discountContext !== discountReadContext
    "
  >
    <app-ftd-button
      *ngIf="currentUser?.permissions?.getHasPermissionToDiscountModule()"
      [id]="'btn-download-discounts'"
      data-test="btn-download"
      [fillWidth]="true"
      label="Download"
      ariaText="Go to discount summary"
      icon="download"
      hierarchy="secondary"
      size="S"
      (btnClick)="goToDiscountSummaryPage()"
    ></app-ftd-button>
    <app-ftd-button
      data-test="btn-save-discount"
      class="ml-2"
      [fillWidth]="true"
      label="Save"
      ariaText="Save"
      icon="folder_open"
      hierarchy="primary"
      size="S"
      (btnClick)="openScenarioListModal()"
    ></app-ftd-button>
    <app-ftd-button
      data-test="btn-submit-discount"
      class="ml-2"
      [fillWidth]="true"
      label="Submit"
      ariaText="Submit"
      icon="done"
      hierarchy="primary"
      size="S"
      (btnClick)="openCreateScenarioModal()"
    ></app-ftd-button>
  </div>

  <div
    class="matrix-view-subheader-button-wrapper"
    fxLayout="row"
    *ngIf="
      currentUser?.permissions?.getHasPermissionToCreateUserScenario() &&
      !isCreateScenarioHidden &&
      discountContext === discountReadContext
    "
  >
    <app-ftd-button
      *ngIf="currentUser?.permissions?.getHasPermissionToDiscountModule()"
      [id]="'btn-download-discounts'"
      data-test="btn-download"
      [fillWidth]="true"
      label="Download"
      ariaText="Go to discount summary"
      icon="download"
      hierarchy="secondary"
      size="S"
      (btnClick)="goToDiscountSummaryPage()"
    ></app-ftd-button>
    <app-ftd-button
      data-test="btn-save-discount"
      class="ml-2"
      [fillWidth]="true"
      label="Edit"
      ariaText="Edit"
      icon="design_services"
      hierarchy="primary"
      size="S"
      (btnClick)="openScenarioListModal()"
    ></app-ftd-button>
    <app-ftd-button
      data-test="btn-submit-discount"
      class="ml-2"
      [fillWidth]="true"
      label="Delete"
      ariaText="Delete"
      icon="delete"
      hierarchy="primary"
      size="S"
      (btnClick)="openCreateScenarioModal()"
    ></app-ftd-button>
  </div>
</mat-toolbar>
