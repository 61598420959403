import { Bullet, DataItem, Theme } from '@amcharts/amcharts5';
import { ColumnSeries, LineSeries } from '@amcharts/amcharts5/xy';
import { FtdAm5ChartsThemeHelper } from '../../ftd-am5-charts.theme';
import { GenericChartFields } from '../../../models/ftd-generic-chart.model';
import { ISeriesDataItem } from '@amcharts/amcharts5/.internal/core/render/Series';
import { am5 } from '../../../ftd-base-chart.abstract';
import dayjs from 'dayjs';

export class FtdCalendarizedOrderBankCustomTheme extends Theme {
  setupDefaultRules() {
    super.setupDefaultRules();
    this.setupGridRules();
    this.setupLineRules();
    this.setupColumnRules();
    this.setupTooltipRules();
    this.setBulletRule();
  }

  private setupGridRules() {
    this.rule('Grid', ['x']).setAll({
      visible: false,
    });

    this.rule('AxisRendererX').setAll({
      cellEndLocation: 0.8,
      cellStartLocation: 0.2,
    });
  }

  private setupLineRules() {
    this.rule('LineSeries').setup = (target: LineSeries) => {
      target.setAll({
        fill: am5.color(0xf28f33),
        stroke: am5.color(0xf28f33),
      });
      target.strokes.template.setAll({
        strokeWidth: 2,
      });
    };
  }

  private setupColumnRules() {
    this.rule('ColumnSeries').events.once('datavalidated', (event) => {
      if (event.target.get('name') === 'Retail Actuals') {
        event.target.columns.events.on('push', (col) => {
          const value = col.newValue.dataItem?.dataContext as GenericChartFields;
          if (value.xAxisValue === dayjs.monthsShort()[new Date().getMonth()]) {
            col.newValue.set('dx', 10);
          }
        });
      }
      if (event.target.get('name') === 'Calendarized Order Bank') {
        event.target.columns.events.on('push', (val) => {
          const value = val.newValue.dataItem?.dataContext as GenericChartFields;
          if (value.xAxisValue === dayjs.monthsShort()[new Date().getMonth()]) {
            val.newValue.set('dx', -10);
          }
        });
      }
    });

    this.rule('ColumnSeries').setup = (target: ColumnSeries) => {
      switch (target.get('name')) {
        case 'Retail Actuals':
          target.setAll({
            fill: am5.color(0x52a2eb),
          });
          break;
        case 'Calendarized Order Bank':
          target.setAll({
            fill: am5.color(0x79ddc0),
          });
          break;
        case 'Retail Forecast':
          target.columns.template.setAll({
            fillOpacity: 0,
            stroke: am5.color(0x8cc4f9),
            strokeDasharray: [2, 2],
            strokeOpacity: 1,
            strokeWidth: 1.5,
          });
          break;
      }
    };

    this.rule('ColumnSeries').events.once('datavalidated', (events) => {
      if (events.target.get('name') === 'Calendarized Order Bank') {
        events.target.bullets.push(
          (root: am5.Root, dataSeries: am5.Series, dataItem: DataItem<ISeriesDataItem>): am5.Bullet | undefined => {
            const orderBank = dataItem.dataContext as GenericChartFields;
            const orderBankPercentageLabel = this.buildLabelRegardingValue(orderBank);
            const label = am5.Label.new(root, {
              centerX: am5.p50,
              centerY: 28,
              fill: am5.color(0xe0e1e1),
              fontSize: 12,
              fontWeight: '400',
              layer: 3,
              populateText: true,
              text: orderBankPercentageLabel,
            });

            const bullet = am5.Bullet.new(root, {
              locationY: 1,
              sprite: label,
              themeTags: ['ftd-percentage-label'],
            });
            bullet.set('id', `label_${label.uid}`);
            return bullet;
          }
        );

        this.removeZeroValueBullets(events);

        this.setCurrentMonthBulletLocation(0, events);
      }

      if (events.target.get('name') === 'Retail Actuals') {
        this.setCurrentMonthBulletLocation(1, events);
      }
    });
  }

  protected setupTooltipRules() {
    this.rule('ColumnSeries').events.on('datavalidated', (event) => {
      event.target.columns.template.events.on('pointerover', (val) => {
        const dataContext = val.target.dataItem?.dataContext as GenericChartFields;
        let tooltipHeight = 18;
        let tooltipHTML;
        const isCalendarizedOrderBank = event.target.get('name') === 'Calendarized Order Bank';
        if (isCalendarizedOrderBank && dataContext.order_bank_percentage) {
          tooltipHeight = 48;
          const orderBankValue = `(${dataContext.order_bank_percentage}%)`;
          tooltipHTML = `<div style='font-family:BMWGroupTNCondensedPro-Regular; font-size: 16px; font-weight: 400;padding: 8px;background-color:#282f47;width:64px; height:${tooltipHeight}px; border-radius: 4px;'><div style='font-size:14px;'>{valueY}</div>`;
          tooltipHTML += `<div>${orderBankValue}</div>`;
        } else {
          tooltipHTML = `<div style='font-family:BMWGroupTNCondensedPro-Regular; font-size: 16px; font-weight: 400;padding: 8px;background-color:#282f47;width:64px; height:${tooltipHeight}px; border-radius: 4px;'><div style='font-size:14px;'>{valueY}</div>`;
        }
        tooltipHTML += '</div>';

        event.target.columns.template.setAll({
          layer: 1,
          tooltip: FtdAm5ChartsThemeHelper.getTooltip(this._root, 'horizontal', true),
          tooltipHTML: tooltipHTML,
          tooltipY: am5.p50,
        });
      });
    });
    this.rule('Tooltip').setAll({
      centerX: am5.p50,
      centerY: am5.p50,
    });
  }

  private removeZeroValueBullets(events: { type: 'datavalidated'; target: ColumnSeries }) {
    events.target.columns.template.onPrivate('height', (height, target) => {
      am5.array.each(target?.dataItem?.bullets as ArrayLike<Bullet>, (bullet) => {
        const orderBank = target?.dataItem?.dataContext as GenericChartFields;
        if (orderBank.order_bank_value === 0) {
          bullet.remove('sprite');
        }
      });
    });
  }

  private setCurrentMonthBulletLocation(location: number, events: { type: 'datavalidated'; target: ColumnSeries }) {
    const currentMonth = dayjs.monthsShort()[new Date().getMonth()];
    const dataItem = events.target.dataItems.find((value) => {
      const dataContext = value.dataContext as GenericChartFields;
      return dataContext.xAxisValue === currentMonth;
    });
    if (dataItem) {
      events.target.columns.template.onPrivate(
        'height',
        (height: number | undefined, target: am5.RoundedRectangle | undefined): void => {
          dataItem.bullets?.forEach((bullet: Bullet) => {
            if (height) {
              bullet.set('locationX', location);
              bullet.set('locationY', 1);
            }
          });
        }
      );
    }
  }

  private setBulletRule() {
    this.rule('Bullet', ['ftd-bullet-label']).adapters.add(
      'sprite',
      (sprite: am5.Sprite, target: am5.Bullet): am5.Sprite => {
        return this.setBulletPosition(target, sprite);
      }
    );
  }

  private setBulletPosition(target: am5.Bullet, sprite: am5.Sprite) {
    if (target.series?.get('name') === 'Calendarized Order Bank' && sprite) {
      sprite.setAll({ centerX: am5.p50, centerY: 42 });
    }
    return sprite;
  }

  private buildLabelRegardingValue(orderBankPercentage: GenericChartFields): string {
    return orderBankPercentage.order_bank_percentage !== undefined && orderBankPercentage.order_bank_percentage !== 0
      ? `(${orderBankPercentage.order_bank_percentage}%)`
      : '';
  }
}
