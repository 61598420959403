import { Component, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  providers: [
    {
      multi: true,
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CrosschecksChartFilterGroupComponent),
    },
  ],
  selector: 'app-crosschecks-chart-filter-group',
  styleUrls: ['./crosschecks-chart-filter-group.component.scss'],
  templateUrl: './crosschecks-chart-filter-group.component.html',
})
export class CrosschecksChartFilterGroupComponent implements ControlValueAccessor {
  value: string[] = [];
  onChange = (_value: string[]) => {};
  onTouch = () => {};

  writeValue(value: string[]): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string[]) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  toggleValue(selectedValue: string) {
    const index = this.value.indexOf(selectedValue);

    if (index > -1) {
      this.value = [...this.value.slice(0, index), ...this.value.slice(index + 1)];
    } else {
      this.value = [...this.value, selectedValue];
    }

    this.onChange(this.value);
    this.onTouch();
  }

  isSelected(valueToCheck: string): boolean {
    return this.value.includes(valueToCheck);
  }
}
