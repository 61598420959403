import { FtdSnackbarComponent } from '../../components/ftd-snackbar/ftd-snackbar.component';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { SnackBarType } from '../../models/ftd-notifier.model';

@Injectable({
  providedIn: 'root',
})
export class FtdNotifierService {
  constructor(private snackBar: MatSnackBar) {}

  showMessage(message: string, duration?: number, hasClose?: boolean): void {
    this._showSnackBar(message, SnackBarType.DEFAULT, duration, hasClose);
  }

  showInfo(message: string, duration?: number, hasClose?: boolean): void {
    this._showSnackBar(message, SnackBarType.INFO, duration, hasClose);
  }

  showError(message: string, duration?: number, hasClose?: boolean): void {
    this._showSnackBar(message, SnackBarType.ERROR, duration, hasClose);
  }

  showSuccess(message: string, duration?: number, hasClose?: boolean): void {
    this._showSnackBar(message, SnackBarType.SUCCESS, duration, hasClose);
  }

  showWarning(message: string, duration?: number, hasClose?: boolean): void {
    this._showSnackBar(message, SnackBarType.WARNING, duration, hasClose);
  }

  private _showSnackBar(
    message: string,
    type: SnackBarType,
    duration: number = 5,
    hasClose: boolean = true
  ): MatSnackBarRef<FtdSnackbarComponent> {
    return this.snackBar.openFromComponent(FtdSnackbarComponent, {
      data: {
        hasClose: hasClose,
        message: message,
        type: type,
      },
      duration: duration * 1000,
      horizontalPosition: 'center',
      panelClass: type,
      verticalPosition: 'top',
    });
  }
}
