import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICurrencyMaskConfig } from '../../directives/app-currency-mask/app-currency-mask';
import { InputStatus } from '../../enums/table-input-status.enum';
import { getCurrencySymbol } from '@angular/common';

export type TextAlign = 'left' | 'center' | 'right';
export type InputType = 'text' | 'currency' | 'percentage';
export type IconPosition = 'left' | 'right';

@Component({
  selector: 'app-ftd-table-input',
  styleUrls: ['./ftd-table-input.component.scss'],
  templateUrl: './ftd-table-input.component.html',
})
export class FtdTableInputComponent implements AfterViewInit {
  @Input() name!: string;
  @Input() id!: string;
  @Input() placeholder: string = 'Set a price';
  @Input() form!: FormGroup;
  @Input() controlName = '';
  @Input() rowIndex!: number;
  @Input() textAlign?: TextAlign = 'left';
  @Input() inputStatus?: InputStatus = 'default';
  @Input() inputType: InputType = 'currency';
  @Input() icon?: string;
  @Input() isMatrixView: boolean = true;
  @Input() iconPosition?: IconPosition = 'left';
  percentageCurrencyContext = { textAlign: 'right' };
  textContext = { textAlign: this.textAlign };
  @ViewChild('textField') textField!: ElementRef<HTMLInputElement>;
  @Output() KeyUp: EventEmitter<string> = new EventEmitter<string>();
  @Output() Paste: EventEmitter<string> = new EventEmitter<string>();

  @Input() currencyMaskOption: ICurrencyMaskConfig = {
    allowNegative: true,
    decimal: '.',
    precision: 1,
  };

  _currency: string = 'EUR';
  get currency(): string {
    return this._currency;
  }

  @Input() set currency(currency: string) {
    this._currency = currency;
    this.currencySymbol = getCurrencySymbol(this.currency, 'narrow');
  }

  currencySymbol: string = 'EUR';

  /**
   * @constructor
   * @param inputContainer
   */
  constructor(private inputContainer: ElementRef<HTMLDivElement>) {}

  /**
   * NgAfterViewInit
   */
  ngAfterViewInit() {
    if (this.inputType === 'currency') {
      this.inputContainer.nativeElement.children.item(0)?.setAttribute('data-currency', this.currencySymbol);
    } else if (this.inputType === 'text') {
      this.textContext = { textAlign: this.textAlign };
      if (this.icon) {
        this.textField.nativeElement.classList.add(`input-icon-${this.iconPosition}`);
      }
    }
    if (this.inputStatus === 'disabled') {
      this.textField.nativeElement.disabled = true;
    }
  }

  /**
   * OnKeyUpEvent
   * @param event
   */
  onKeyUpEvent(event: KeyboardEvent): void {
    // Disable event emitter for Tab key press as it would update the other input box and create issues with the percentage
    if (event.key !== 'Tab') {
      this.KeyUp.emit((event.target as HTMLInputElement).value);
    }
  }

  /**
   * OnPasteEvent
   * @param event
   */
  onPasteEvent(event: ClipboardEvent): void {
    this.Paste.emit((event.target as HTMLInputElement).value);
  }
}
