import { Base64Utils } from '../../../../common/utils/base64.utils';
import { ChartAxisType } from 'src/app/common/components/ftd-charts/enums/ftd-generic-chart.enum';
import { ChartLegendType } from 'src/app/common/components/ftd-charts/enums/chart-legend-type.enum';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CrosschecksChartContext } from '../../../enums/crosschecks-chart-context-messages.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import { DisplayNameColor } from 'src/app/common/components/ftd-charts/ftd-chart-info-tile/ftd-chart-info-tile.component';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  ICalendarizedOrderBankData,
  ICalendarizedOrderBankDataResponse,
  IGranularityDto,
  IOpenVolumeYe,
} from 'src/app/matrix-view/models/api.model';
import { IChartLegendConfig } from 'src/app/common/components/ftd-charts/models/ftd-chart-legend-config.model';
import { IFtdDropdownOption } from '../../../../common/models/ftd-dropdown-option.model';
import { IGenericChartHeadings } from 'src/app/common/components/ftd-charts/models/ftd-generic-chart.model';
import { ILineChartData } from '../../../../common/components/ftd-charts/models/ftd-line-chart.model';
import { Subscription } from 'rxjs';
import { TrafficLightsColor } from 'src/app/crosschecks/types/traffic-lights.type';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);

@Component({
  selector: 'app-calendarized-order-bank',
  styleUrls: ['./calendarized-order-bank.component.scss'],
  templateUrl: './calendarized-order-bank.component.html',
})
export class CalendarizedOrderBankComponent implements OnInit, OnDestroy {
  /**
   * Granularity for which chart data need to be fetched
   */
  filters!: IGranularityDto;
  private _granularity: IGranularityDto = <IGranularityDto>{};
  protected readonly ChartAxisType = ChartAxisType;
  /**
   * @input
   * @param granularityData
   */
  @Input() set granularity(granularityData: string) {
    this._granularity = Base64Utils.decodeAtobToJson(granularityData);
    this.filters = this._granularity;
    this.loadCalendarizedOrderBankData();
  }

  /**
   * Traffic light value coming from overview request on Sales Pipeline
   */
  @Input() crosscheckIndication?: TrafficLightsColor;
  @Input() crosscheckIndicationForecasted?: TrafficLightsColor;

  crosschecksChartContext: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  chartData!: ILineChartData;
  barChartKeys: string[] = [];
  totalOrderBankCY: IOpenVolumeYe = {
    displayName: 'TOTAL ORDER BANK CY',
    displayNameDetails: ' total order bank',
    ratioRetailOpenVolumeBudget: '',
    value: '',
  };

  orderBankReach = {
    color: DisplayNameColor.red,
    display_name: 'ORDER BANK REACH',
    display_name_details: ' &Delta; PY',
    value: ' Months',
  };

  initChart: boolean = false;
  chartAxisType: ChartAxisType = ChartAxisType.CATEGORY;
  isChartValuesVisible: boolean = false;
  dropdownList: string[] = ['Sales Channel'];
  dropdownOptions: IFtdDropdownOption<string>[][] = [
    [
      {
        id: 1,
        label: 'Actual Parameters',
        value: 'actualParameters',
      },
    ],
  ];

  chartLegendForm!: FormGroup;
  chartLegend: string[] = [];
  currentForecastName: string = '';
  chartLegendsConfigs: IChartLegendConfig[] = [];

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param formBuilder
   * @param crosscheckService
   */
  constructor(private formBuilder: FormBuilder, private crosscheckService: CrosschecksService) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe();
  }

  /**
   * NgUnsubscribe
   */
  ngUnsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * InitForm
   * @private
   */
  private initForm(): void {
    this.chartLegendForm = this.formBuilder.group({
      calendarizedOrderBankFields: new FormControl<string[]>([]),
    });

    const chartLegendFormSubscription: Subscription =
      this.chartLegendForm.controls.calendarizedOrderBankFields.valueChanges.subscribe(
        (selectedLegend: string[]): void => {
          this.chartLegend = selectedLegend;
        }
      );
    this.subscriptions.push(chartLegendFormSubscription);

    this.chartLegend = this.chartLegendForm.controls.calendarizedOrderBankFields.value;
  }

  /**
   * ShowValuesToggle
   * @param value
   */
  showValuesToggle(value: boolean): void {
    this.isChartValuesVisible = value;
  }

  /**
   * LoadCalendarizedOrderBankData
   * @private
   */
  private loadCalendarizedOrderBankData(): void {
    this.crosschecksChartContext = CrosschecksChartContext.LOADING;
    if (this._granularity.market) {
      const subscription: Subscription = this.crosscheckService
        .getCalendarizedOrderBankData(this._granularity)
        .subscribe({
          error: (): void => {
            this.crosschecksChartContext = CrosschecksChartContext.ERROR;
          },
          next: (response: ICalendarizedOrderBankDataResponse): void => {
            this.setCalendarizedOrderBankDataResponseOnNext(response);
          },
        });
      this.subscriptions.push(subscription);
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
    }
  }

  /**
   * SetCalendarizedOrderBankDataResponseOnNext
   * @private
   * @param response
   */
  private setCalendarizedOrderBankDataResponseOnNext(response: ICalendarizedOrderBankDataResponse): void {
    if (response.calendarized_order_bank.data.length > 0) {
      this.chartLegend = [];

      // Find first data value with forecast field
      const currentForecast = response.calendarized_order_bank.data.find((value: ICalendarizedOrderBankData) => {
        return Object.keys(value).find((key) => key.includes('forecast'));
      });

      // Get full name of current forecast
      this.currentForecastName = Object.keys(currentForecast!).find((key) => key.includes('forecast'))!;

      // Set legend config
      this.chartLegendsConfigs = [
        {
          fieldName: 'budget',
          imagePath: './assets/images/charts/legends/line-orange.svg',
          label: 'Retail budget',
          legendColor: '#F28F33',
          type: ChartLegendType.IMAGE,
        },
        { fieldName: 'retail', label: 'Retail actuals', legendColor: '#52A2EB', type: ChartLegendType.SQUARE_DIV },
        {
          fieldName: 'order_bank_value',
          label: 'Calendarized Order Bank',
          legendColor: '#79DDC0',
          type: ChartLegendType.SQUARE_DIV,
        },
        {
          fieldName: this.currentForecastName,
          imagePath: './assets/images/charts/legends/rectangle-dotted-blue.svg',
          label: `Retail Forecast (${this.currentForecastName.slice(-2)})`,
          legendColor: '#8CC4F9',
          type: ChartLegendType.IMAGE,
        },
      ];

      this.chartData = {
        fields: response.calendarized_order_bank.data.map((item: ICalendarizedOrderBankData) => {
          return {
            budget: item.budget,
            [this.currentForecastName]: item[this.currentForecastName as keyof ICalendarizedOrderBankData] as number,
            order_bank_percentage: item.order_bank?.percentage,
            order_bank_value: item.order_bank?.value,
            retail: item.retail,
            xAxisValue: item.period.toString(),
          };
        }),
        headings: [
          { description: 'Retail Budget', key: 'budget' },
          { description: 'Retail Forecast', key: this.currentForecastName },
          { description: 'Calendarized Order Bank', key: 'order_bank_value' },
          { description: 'Retail Actuals', key: 'retail' },
        ],
      };

      this.totalOrderBankCY.value = response.calendarized_order_bank.total_order_bank_cy.current.toString();
      this.totalOrderBankCY.displayNameDetails = `${response.calendarized_order_bank.total_order_bank_cy.forecasted} total order bank`;

      this.orderBankReach.value = `${response.calendarized_order_bank.order_bank_reach.months} Months`;
      this.orderBankReach.display_name_details = `${
        response.calendarized_order_bank.order_bank_reach.delta > 0 ? '+' : ''
      }${response.calendarized_order_bank.order_bank_reach.delta} &Delta; PY`;
      this.orderBankReach.color =
        response.calendarized_order_bank.order_bank_reach.delta > 0 ? DisplayNameColor.green : DisplayNameColor.red;

      this.chartData.headings.forEach((heading: IGenericChartHeadings): void => {
        this.chartLegend.push(heading.key);
      });
      this.chartLegendForm.get('calendarizedOrderBankFields')?.setValue(this.chartLegend);

      this.initChart = true;
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_RESULTS_FOUND;
    }
  }
}
