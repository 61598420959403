import { Component, Input, OnInit } from '@angular/core';
import { ISkeletonTheme, SkeletonThemeKey, SkeletonThemeTemplates } from './ftd-skeleton.theme';

@Component({
  selector: 'app-ftd-skeleton',
  styleUrls: ['./ftd-skeleton.component.scss'],
  templateUrl: './ftd-skeleton.component.html',
})
export class FtdSkeletonComponent implements OnInit {
  @Input() theme: SkeletonThemeKey = 'OrderIntake';
  themeTemplate: ISkeletonTheme = {
    chartNames: ['orderIntake'],
    filtersNumber: 2,
    hasChartTitle: false,
    hasFiltersToggle: true,
    hasHeaderRight: true,
    hasTitle: true,
    legendNumber: 7,
    tilesNumber: 1,
  };

  ngOnInit(): void {
    this.themeTemplate = new SkeletonThemeTemplates[this.theme]();
  }
}
