import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { GranularityReadyStatus } from '../../enums/granularity-ready-status.enum';
import { GranularityService } from '../../services/granularity/granularity.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-matrix-landing-view',
  styleUrls: ['./matrix-landing-view.component.scss'],
  templateUrl: './matrix-landing-view.component.html',
})
export class MatrixLandingViewComponent implements OnInit, OnDestroy {
  static readonly APPLY_FILTERS_SVG_PATH: string = 'assets/images/landingPageCards.svg';
  static readonly NO_RESULTS_SVG_PATH: string = 'assets/images/granularityEmptyPlaceholder.svg';
  static readonly APPLY_FILTERS_TEXT_PATH: string = 'Apply filters to see results';
  static readonly NO_RESULTS_TEXT_PATH: string = 'No results found.';
  static readonly DATA_LOADING_MESSAGE: string = 'Wait just a few seconds...';
  private subscriptions: Subscription[] = [];
  dataContext?: MatrixLandingViewComponentDataContext | keyof MatrixLandingViewComponentDataContext =
    MatrixLandingViewComponentDataContext.APPLY_FILTERS;

  constructor(private granularityService: GranularityService) {}

  message = MatrixLandingViewComponent.APPLY_FILTERS_TEXT_PATH;
  imagePlaceholderPathToAssets: string = MatrixLandingViewComponent.APPLY_FILTERS_SVG_PATH;

  private _isLoading = false;

  get isLoading() {
    return this._isLoading;
  }

  @Input() set isLoading(loading: boolean) {
    this._isLoading = loading;
    this.message = '';
  }

  @Input() set dataContextInput(dataContext: MatrixLandingViewComponentDataContext) {
    this.dataContext = dataContext;
    this.setDataContext();
  }

  ngOnInit() {
    this.setDataContext();

    const subscription = this.granularityService.isGranularityListReady().subscribe({
      error: (err: Error) => {},
      next: (isGranularityListReady: GranularityReadyStatus) => {
        if (isGranularityListReady === GranularityReadyStatus.LOADED) {
          if (this.granularityService.getGranularityList()?.length === 0) {
            this.dataContext = MatrixLandingViewComponentDataContext.NO_RESULTS;
          } else if (this.granularityService.getGranularityList()?.length > 0) {
            this.dataContext = MatrixLandingViewComponentDataContext.APPLY_FILTERS;
          }
        } else if (isGranularityListReady === GranularityReadyStatus.LOADING) {
          this.message = MatrixLandingViewComponent.DATA_LOADING_MESSAGE;
        }
      },
    });

    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub?.unsubscribe();
    });
  }

  setDataContext(): void {
    switch (this.dataContext) {
      case MatrixLandingViewComponentDataContext.APPLY_FILTERS: {
        this.imagePlaceholderPathToAssets = MatrixLandingViewComponent.APPLY_FILTERS_SVG_PATH;
        this.message = MatrixLandingViewComponent.APPLY_FILTERS_TEXT_PATH;
        this._isLoading = false;
        break;
      }
      case MatrixLandingViewComponentDataContext.NO_RESULTS: {
        this.imagePlaceholderPathToAssets = MatrixLandingViewComponent.NO_RESULTS_SVG_PATH;
        this.message = MatrixLandingViewComponent.NO_RESULTS_TEXT_PATH;
        this._isLoading = false;
        break;
      }
    }
  }
}

export enum MatrixLandingViewComponentDataContext {
  APPLY_FILTERS = 'APPLY_FILTERS',
  NO_RESULTS = 'NO_RESULTS',
  MATRIX_VIEW_DATA = 'MATRIX_VIEW_DATA',
}
