<div
  class="ftd-tab-label-traffic-light {{ trafficLightLegendClass }}"
  [class.traffic-light-pointer]="technicalName"
  [class.small]="isTrafficLightSmall"
  (click)="navigateToCrosschecks()"
  [ngClass]="[trafficLightsClassArrow + ' ' + trafficLightsClassColor, className]"
  *ngIf="trafficLightsClassColor !== 'grey' || !isTrafficLightSmall"
  [attr.data-test]="'matrix-view-traffic-light-' + technicalName"
>
  <mat-icon *ngIf="trafficLightsClassArrow !== 'na'">{{ this.iconToRender }}</mat-icon>
</div>
<span *ngIf="trafficLightsClassColor === 'grey' && isTrafficLightSmall">N/A</span>
