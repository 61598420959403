<div class="ftd-table-date-picker table-date-range-picker" *ngIf="pickRanges; else singlePick" [formGroup]="form">
  <mat-date-range-input [rangePicker]="picker">
    <input matStartDate formControlName="startDate" class="input-default" placeholder="Start date" />
    <input matEndDate formControlName="endDate" class="input-default" placeholder="End date" />
  </mat-date-range-input>
  <mat-datepicker-toggle matIconSuffix [for]="picker" class="button-default">
    <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
  </mat-datepicker-toggle>
  <mat-date-range-picker [calendarHeaderComponent]="ftdHeader" #picker></mat-date-range-picker>
</div>

<ng-template #singlePick>
  <mat-form-field [formGroup]="form" #singlePick appearance="outline">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
      class="ftd-table-date-picker"
      [placeholder]="placeholder"
      [id]="id"
      [name]="name"
      [min]="minDate"
      [formControlName]="controlName"
      matInput
      [matDatepicker]="picker"
    />
    <mat-hint *ngIf="customMessage">{{ customMessage }}</mat-hint>
    <mat-datepicker-toggle
      class="button-{{ inputStatus }}"
      (click)="emitDatepickerToggleClicked()"
      matSuffix
      [for]="picker"
    ></mat-datepicker-toggle>
    <mat-datepicker #picker [calendarHeaderComponent]="ftdHeader"></mat-datepicker>
  </mat-form-field>
</ng-template>
