import { ErrorPageComponent } from './error-page/error-page.component';
import { MainScenarioComponent } from './scenario/main-scenario/main-scenario.component';
import { NgModule } from '@angular/core';
import { NoPermissionsComponent } from './no-permissions/no-permissions.component';
import { RouterModule, Routes } from '@angular/router';
import { UserRolesComponent } from './scenario/user-roles/user-roles.component';
import { UserScenarioComponent } from './scenario/user-scenario/user-scenario.component';

const routes: Routes = [
  {
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/main-scenario' },
      { component: MainScenarioComponent, path: 'main-scenario' },
      { component: NoPermissionsComponent, path: 'no-permissions' },
      {
        component: UserScenarioComponent,
        data: { action: 'CREATE_USER_SCENARIO', breadcrumb: 'User Scenario' },
        path: 'user-scenario/:id',
      },
      {
        component: UserRolesComponent,
        data: { breadcrumb: 'Roles & Permissions' },
        path: 'user-roles',
      },
      { component: ErrorPageComponent, path: 'error' },
    ],
    path: '',
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [RouterModule.forRoot(routes)],
})
export class AppRoutingModule {}
