export interface ICommentSubmissionModel {
  filterId: string;
  text: string;
  userId: string;
  userName: string;
  pricePointId: string;
}

export interface ICommentsAndNotesModel {
  id: string;
  filterId: string;
  text: string;
  userId: string;
  userName: string;
  createdAt: string;
}

export enum CommentsAndNotesContext {
  COMMENTS = 'COMMENTS',
  NOTES = 'NOTES',
}
