import { Actions, KeyofActions } from './actions.model';
import { Brand } from '../../matrix-view/models/brand.model';
import { FunctionsUtils } from '../../common/utils/functions.utils';
import { IFtdDropdownOption } from '../../common/models/ftd-dropdown-option.model';
import { IMarket } from '../../matrix-view/models/market.model';
import { ROLE } from '../../matrix-view/models/roles.model';
import { ScenarioMarketParameter } from '../../matrix-view/enums/scenario-market-parameter.enum';
import uniqBy from 'lodash.uniqby';

export class Permissions {
  private _permissions: IPermission[] = [];
  private _selectedPermissions: IPermission[] = [];

  private _markets: IFtdDropdownOption<string>[] = [];
  private _selectedMarket: ScenarioMarketParameter | null = null;
  private _selectedBrands: Brand[] = [];

  private allowedRoles: ROLE[] = ['reader', 'editor', 'lead-editor', 'approver', 'admin'];

  constructor(permissions: IPermission[]) {
    this.setPermissions(permissions);
    this.setMarkets();
  }

  getMarkets(): IFtdDropdownOption<string>[] {
    return this._markets;
  }

  getPermissionsGroupedByBrand(): { BMW: IPermission[]; MINI: IPermission[] } {
    return FunctionsUtils.groupBy(this.getPermissions(), (permissions: IPermission) => permissions.brand) as {
      BMW: IPermission[];
      MINI: IPermission[];
    };
  }

  setMarkets(): void {
    const markets = this.getPermissions().map((permission: IPermission, index: number) => {
      return {
        id: index,
        label: permission.market.name,
        value: permission.market.code,
      };
    }) as IFtdDropdownOption<string>[];

    this._markets = uniqBy(markets, 'value');
  }

  getSelectedMarket(): ScenarioMarketParameter | null {
    return this._selectedMarket;
  }

  setSelectedMarket(market: ScenarioMarketParameter): void {
    this._selectedMarket = market;
  }

  getSelectedBrands(): Brand[] {
    return this._selectedBrands;
  }

  setSelectedBrands(brands: Brand[]): void {
    this._selectedBrands = brands;
  }

  getSelectedPermissions(): IPermission[] {
    return this._selectedPermissions;
  }

  setSelectedPermissions(market: ScenarioMarketParameter | null, brands: Brand[] = []): void {
    if (market) {
      this.setSelectedMarket(market);
      this.setSelectedBrands(brands);
      this._selectedPermissions = this.getPermissions().filter(
        (permission: IPermission) => permission.market.code === market && brands.includes(permission.brand)
      );
    } else {
      this._selectedMarket = null;
      this._selectedBrands = [];
      this._selectedPermissions = [];
    }
  }

  getPermissions(): IPermission[] {
    return this._permissions;
  }

  setPermissions(permissions: IPermission[]): void {
    this._permissions = permissions;
  }

  /**
   * Returns the permissions by market
   * @param marketCode to check the market permission
   * @returns IPermission
   */
  getPermissionsByMarket(marketCode: ScenarioMarketParameter | null): IPermission[] {
    return this.getPermissions().filter((permission: IPermission): boolean => permission.market.code === marketCode);
  }

  getPermissionsByBrandAndMarket(
    brand: Brand | string,
    market: ScenarioMarketParameter | null = this.getSelectedMarket(),
    isPermissionsBySelectedBrandAndMarket: boolean = true
  ): IPermission[] {
    const permissions: IPermission[] = isPermissionsBySelectedBrandAndMarket
      ? this.getSelectedPermissions()
      : this.getPermissions();

    return permissions.filter(
      (permission: IPermission) => permission.market.code === market && permission.brand === brand
    );
  }

  /**
   * Checks the brands the user has permission to release the prices
   * @param market to check which brand the user has access to in that selected market
   * @returns Brand
   */
  getBrandsFromApproveMainScenario(market: ScenarioMarketParameter | null = this.getSelectedMarket()): Brand[] {
    return this.getPermissionsByMarket(market)
      .filter((permission: IPermission) => permission.actions.APPROVE_MAIN_SCENARIO)
      .map((permission: IPermission) => permission.brand);
  }

  getBrandsFromReleaseMainScenario(market: ScenarioMarketParameter | null = this.getSelectedMarket()): Brand[] {
    return this.getPermissionsByMarket(market)
      .filter((permission: IPermission) => permission.actions.RELEASE_MAIN_SCENARIO)
      .map((permission: IPermission) => permission.brand);
  }

  /**
   * Fetch the brands that the user has for the selected market
   * @param market to check which brands he has
   * @returns permissions
   */
  getBrandsFromMarket(market: ScenarioMarketParameter | null = this.getSelectedMarket()): Brand[] {
    return this.getPermissionsByMarket(market).map((permissions: IPermission) => permissions.brand);
  }

  /**
   * Checks if the user has permission to approve the prices (renders the approve button)
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   */
  getHasPermissionToApproveMainScenario(brands: Brand[] | string[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'APPROVE_MAIN_SCENARIO');
  }

  /**
   * Checks if the user has permission to release the prices (renders the release button)
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   */
  getHasPermissionToReleaseMainScenario(brands: Brand[] | string[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'RELEASE_MAIN_SCENARIO');
  }

  /**
   * Checks if the user has permissions to submit the prices in the user scenario (renders the button)
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   */
  getHasPermissionToSubmitToMainScenario(brands: Brand[] | string[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'SUBMIT_TO_MAIN_SCENARIO');
  }

  /**
   * Checks if the user has the permissions to download the excel
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   */
  getHasPermissionToDownloadScenario(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'DOWNLOAD_SCENARIO');
  }

  /**
   * Checks if the user has the permission to save the user scenario to display the button
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   */
  getHasPermissionToSaveUserScenario(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'SAVE_USER_SCENARIO');
  }

  /**
   * Checks if the user has the permission to discount module to display the button
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   */
  getHasPermissionToDiscountModule(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'DISCOUNT_MODULE');
  }

  /**
   * Checks if the user has the permission to read discount to display the button
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   */
  getHasPermissionToReadDiscount(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'READ_DISCOUNT') && this.getHasPermissionToDiscountModule(brands);
  }

  /**
   * Checks if the user has the permission to create discount to display the button
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   */
  getHasPermissionToCreateDiscount(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'CREATE_DISCOUNT') && this.getHasPermissionToDiscountModule(brands);
  }

  /**
   * Checks if the user has the permission to withdraw discount to display the button
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   * @returns boolean
   */
  getHasPermissionToWithdrawDiscount(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'WITHDRAW_DISCOUNT') && this.getHasPermissionToDiscountModule(brands);
  }

  /**
   * Checks if the user has the permission to withdraw discount to display the button
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   * @returns boolean
   */
  getHasPermissionToRejectDiscount(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'REJECT_DISCOUNT') && this.getHasPermissionToDiscountModule(brands);
  }

  /**
   * Checks if the user has the permission to approve by 1 discount to display the button
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   * @returns boolean
   */
  getHasPermissionToApproveBy1Discount(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return (
      this.getHasPermissionToAction(brands, 'APPROVE_BY_1_DISCOUNT') && this.getHasPermissionToDiscountModule(brands)
    );
  }

  /**
   * Checks if the user has the permission to approve by 2 discount to display the button
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   * @returns boolean
   */
  getHasPermissionToApproveBy2Discount(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return (
      this.getHasPermissionToAction(brands, 'APPROVE_BY_2_DISCOUNT') && this.getHasPermissionToDiscountModule(brands)
    );
  }

  /**
   * Checks if the user has the permission to release discount to display the button
   * @param brands depending on the brand the user may or may not have permission
   * @returns boolean
   * @returns boolean
   */
  getHasPermissionToReleaseDiscount(brands: Brand[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'RELEASE_DISCOUNT') && this.getHasPermissionToDiscountModule(brands);
  }

  /**
   * This function checks depending on the action given if the user has permissions for that given action
   * @param action we send the action we want to check if the user has permission
   * @returns boolean
   */
  getHasPermissionToActionGeneric(action: string): boolean {
    return this.getMarkets().some((market) => {
      return this.getHasPermissionToAction(
        this.getBrandsFromMarket(market.value as ScenarioMarketParameter),
        action as KeyofActions,
        market.value as ScenarioMarketParameter,
        false
      );
    });
  }

  /**
   * Check if the user has permission to see the List and Create buttons
   * @param brands receives the brands because the user might have permissions in one brand and not in the other
   * @returns boolean
   */
  getHasPermissionToCreateUserScenario(brands: Brand[] | string[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'CREATE_USER_SCENARIO');
  }

  /**
   * Check if the user has permission to reject a price
   * @param brands receives the brands because the user might have permissions in one brand and not in the other
   * @returns boolean
   */
  getHasPermissionToRejectPrice(brands: Brand[] | string[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'REJECT_PRICE');
  }

  /**
   * Check if the user has permission to accept a price
   * @param brands receives the brands because the user might have permissions in one brand and not in the other
   * @returns boolean
   */
  getHasPermissionToAcceptPrice(brands: Brand[] | string[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'ACCEPT_PRICE');
  }

  /**
   * Check if the user has permission to withdraw a price
   * @param brands receives the brands because the user might have permissions in one brand and not in the other
   * @returns boolean
   */
  getHasPermissionToWithdrawOnMainScenario(brands: Brand[] | string[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'WITHDRAW_ON_MAIN_SCENARIO');
  }

  /**
   * Check if the user has permission to adjust the role of another user
   * @param brands receives the brands because the user might have permissions in one brand and not in the other
   * @returns boolean
   */
  getHasPermissionToAdjustRoles(brands: Brand[] | string[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'ADJUST_ROLES');
  }

  /**
   * Checks if the user has permissions to see the Main Scenario / My Pricing View
   * @param brands receives the brands because the user might have permissions in one brand and not in the other
   * @returns boolean
   */
  getHasPermissionToReadGovernanceBoard(brands: Brand[] | string[] = this.getBrandsFromMarket()): boolean {
    return this.getHasPermissionToAction(brands, 'READ_MAIN_SCENARIO');
  }

  getHasPermissionToAction(
    brands: Brand[] | string[],
    keyofActions: KeyofActions,
    market: ScenarioMarketParameter = this.getSelectedMarket()!,
    isPermissionsBySelectedBrandAndMarket: boolean = true
  ): boolean {
    let hasPermission: boolean = false;

    brands.forEach((brand: Brand | string): void => {
      const permissions: IPermission[] = this.getPermissionsByBrandAndMarket(
        brand,
        market,
        isPermissionsBySelectedBrandAndMarket
      );

      permissions.forEach((permission: IPermission): void => {
        const _hasPermission: boolean = permission?.actions[keyofActions];

        if (_hasPermission) {
          hasPermission = _hasPermission;
        }
      });
    });

    return hasPermission;
  }

  /**
   * Get Role according to the selected market and brand
   * @param brand depending on the brand the user can have different roles so we need to send it
   * @param market depending on the market the user can have different roles so we need to send it
   * @returns permissions list
   */
  getRole(brand: string, market: ScenarioMarketParameter = this.getSelectedMarket() as ScenarioMarketParameter): ROLE {
    return this.getPermissionsByMarket(market).find((permissions: IPermission): boolean => permissions.brand === brand)!
      .role;
  }

  getRoles(): ROLE[] {
    return uniqBy(this.getPermissions(), 'role').map((item: IPermission) => item.role);
  }

  /**
   * Check the role that has the highest permissions (e.g User can have more than one role depending on the market)
   * @returns Role
   */
  getHighestHierarchyRole(): ROLE {
    return this.getRolesHierarchy().find((role: { id: number; value: ROLE }) =>
      this.getRoles().some((innerRole: ROLE): boolean => innerRole === role.value)
    )?.value as ROLE;
  }

  private getRolesHierarchy(): { id: number; value: ROLE }[] {
    return [
      {
        id: 1,
        value: 'admin',
      },
      {
        id: 2,
        value: 'approver',
      },
      {
        id: 3,
        value: 'lead-editor',
      },
      {
        id: 4,
        value: 'editor',
      },
      {
        id: 5,
        value: 'reader',
      },
    ];
  }

  userHasAllowedPermission(): boolean {
    return this.getPermissions().some((permission: IPermission) => this.allowedRoles.includes(permission.role));
  }
}

export interface IPermission {
  actions: Actions;
  brand: Brand;
  market: IMarket;
  role: ROLE;
}
