import { Component, Inject, Optional } from '@angular/core';
import { IMatrixViewDataSourceItem } from '../../../matrix-view/models/matrix-view.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { StatusActionEnum } from '../../../matrix-view/services/governance/governance.service';

@Component({
  selector: 'app-override-status-modal',
  templateUrl: './override-status-modal.component.html',
})
/**
 * @class SubmitStatusModalComponent
 */
export class OverrideStatusModalComponent {
  title: string = 'Override Price Point';
  description: string =
    'You are about to overwrite the currently approved price and effective date. </br>' +
    'Are you sure that you want to proceed? This action will delete the currently approved price.';

  /**
   * @constructor
   * @param data
   * @param dialogRef
   */
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      matrixViewDataSourceItems: IMatrixViewDataSourceItem[];
      status: StatusActionEnum;
    },
    private dialogRef: MatDialogRef<OverrideStatusModalComponent>
  ) {}

  /**
   * Action
   * @areYouSure
   */
  action(areYouSure: boolean): void {
    this.dialogRef.close({
      areYouSure: areYouSure,
    });
  }
}
