<div fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="start center" class="main-content">
    <div fxLayout="row" fxLayoutAlign="start start">
      <div
        class="price-approval-container-rounded price-approval-container-rounded-step-{{ item.stepNumber }} {{
          statusCssClass
        }}"
      >
        <span>{{ item.stepNumber }}</span>
      </div>
      <div fxLayout="column">
        <strong class="title {{ statusCssClass }}">{{ item.title }}</strong>
        <span class="description {{ statusCssClass }}">
          {{ item.description }}
        </span>
      </div>
    </div>
  </div>
  <div class="price-approval-state-chip">
    <app-ftd-chip
      [chipText]="item.status"
      [enableRemoveButton]="false"
      [chipSize]="CHIP_SIZE.DEFAULT"
      [chipTextColor]="chipStyle.textColor"
      [chipColor]="chipStyle.backgroundColor"
    ></app-ftd-chip>
  </div>
</div>
<mat-divider *ngIf="item.showDivider"></mat-divider>
