<app-ftd-custom-modal>
  <app-ftd-custom-modal-header [title]="title"></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body>
    <div class="mb-3">
      <span> You are editing {{ totalModels }} models. </span>
    </div>

    <div class="mb-3">
      <div>Define the discount amount you want to apply to the selected model codes:</div>
    </div>

    <div id="edit-prices-form" class="mb-3">
      <form [formGroup]="form" novalidate>
        <div id="values-form-container">
          <div class="amount-container">
            <div class="mb-1">
              <span> Discount on {{ discountBenefitTypeContext }} list price </span>
            </div>

            <app-ftd-input
              [placeholder]="'Amount'"
              [form]="form"
              [controlName]="'amount'"
              [hideMaxlengthHint]="true"
              [enableCurrencyMask]="true"
              [currencyMaskOptions]="currencyMaskOption"
              [fillWidth]="true"
              [attr.data-test]="'edit-discounts-modal-amount-input'"
            ></app-ftd-input>
          </div>
        </div>
      </form>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer>
    <app-ftd-button
      [id]="'edit-discounts-btn'"
      (btnClick)="onSubmit()"
      [disabled]="!(form.valid && form.dirty)"
      [label]="'Edit'"
      ariaText="Edit"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="true"
      [attr.data-test]="'edit-discounts-modal-edit-btn'"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
