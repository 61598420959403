import { ScenarioContext } from '../../common/enums/scenario-context.enum';

interface IScenarioConfig {
  context: ScenarioContext;
  isMatrixViewDataHidden: boolean;
  isMatrixViewSideFiltersHidden: boolean;
  isReturnButtonHidden: boolean;
  title: string;
  isSubmitScenarioButtonHidden: boolean;
  isDescriptionHidden: boolean;
  isTitleHidden: boolean;
  isExportButtonHidden: boolean;
  isUnlockScenarioButtonHidden: boolean;
  isApproveScenarioButtonHidden: boolean;
  isSaveButtonHidden: boolean;
  description: string;
}

export const MAIN_SCENARIO_CONFIG: IScenarioConfig = {
  context: ScenarioContext.mainScenario,
  description: 'scenario',
  isApproveScenarioButtonHidden: false,
  isDescriptionHidden: true,
  isExportButtonHidden: false,
  isMatrixViewDataHidden: false,
  isMatrixViewSideFiltersHidden: false,
  isReturnButtonHidden: true,
  isSaveButtonHidden: true,
  isSubmitScenarioButtonHidden: true,
  isTitleHidden: true,
  isUnlockScenarioButtonHidden: true,
  title: 'Main Scenario',
};

export const USER_SCENARIO_CONFIG: IScenarioConfig = {
  context: ScenarioContext.userScenario,
  description: '',
  isApproveScenarioButtonHidden: true,
  isDescriptionHidden: false,
  isExportButtonHidden: true,
  isMatrixViewDataHidden: false,
  isMatrixViewSideFiltersHidden: false,
  isReturnButtonHidden: false,
  isSaveButtonHidden: false,
  isSubmitScenarioButtonHidden: false,
  isTitleHidden: false,
  isUnlockScenarioButtonHidden: true,
  title: 'User Scenario',
};
