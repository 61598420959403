<mat-toolbar class="matrix-view-subheader" fxLayout="row" fxLayoutAlign="space-between end">
  <div class="title-container-return-button-container">
    <div class="return-button-container" *ngIf="!isReturnButtonHidden">
      <app-ftd-breadcrumbs></app-ftd-breadcrumbs>
    </div>
    <div class="title-container">
      <h2>{{ title }}</h2>
    </div>
  </div>

  <div
    class="matrix-view-subheader-button-wrapper"
    fxLayout="row"
    *ngIf="currentUser?.permissions?.getHasPermissionToCreateUserScenario() && !isCreateScenarioHidden"
  >
    <app-ftd-button
      *ngIf="currentUser?.permissions?.getHasPermissionToReadDiscount()"
      [id]="'btn-go-to-discount-summary'"
      data-test="btn-go-to-discount-summary"
      [fillWidth]="true"
      label="Discounts"
      ariaText="Go to discount summary"
      icon="local_offer"
      hierarchy="secondary"
      size="S"
      (btnClick)="goToDiscountSummaryPage()"
      outlined
    ></app-ftd-button>
    <app-ftd-button
      data-test="btn-list-scenarios"
      class="ml-2"
      [fillWidth]="true"
      label="Scenario list"
      ariaText="Open User Scenario"
      icon="folder_open"
      hierarchy="secondary"
      size="S"
      (btnClick)="openScenarioListModal()"
    ></app-ftd-button>
    <app-ftd-button
      data-test="btn-new-scenario"
      class="ml-2"
      [fillWidth]="true"
      label="New scenario"
      ariaText="Create New Scenario"
      icon="add"
      hierarchy="primary"
      size="S"
      (btnClick)="openCreateScenarioModal()"
    ></app-ftd-button>
  </div>
</mat-toolbar>
