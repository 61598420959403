import { AuthService } from 'src/app/auth/services/auth.service';
import {
  CurrentCrossCheckTechnicalName,
  CurrentFinancialTechnicalName,
  CurrentPricingTechnicalName,
  ForecastedCrosscheckTechnicalName,
  ForecastedFinancialTechnicalName,
  PriceEditorTechnicalName,
  PriceEngineRecommendationTechnicalName,
} from '../../enums/matrix-column-technical-name.enum';
import { DiscountContext } from '../../services/context/context.service';
import { DiscountMatrixViewDataService } from '../matrix-view-data/matrix-view-data.service';
import { DiscountMatrixViewDataSource, IMatrixViewDataSourceItem } from '../../models/matrix-view.model';
import { GovernanceService } from '../governance/governance.service';
import { GranularityService } from '../granularity/granularity.service';
import { GranularityType } from '../../enums/granularity-type.enum';
import { ICrossCheckData, IGranularity, ISelectedFiltersFormConfirmed } from '../../models/app.model';
import {
  IExcelExportData,
  IExcelExportEseriesDataItem,
  IExcelExportModelDataItem,
} from '../../models/excel-export-data.model';
import { IFtdDropdownOption } from '../../../../../common/models/ftd-dropdown-option.model';
import { IScenarioDataWithPricePoint } from '../../../../../graphql/services/gql-api.service';
import { Injectable } from '@angular/core';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { ScenarioService } from '../scenario/scenario.service';
import { catchError, map } from 'rxjs/operators';
import cloneDeep from 'lodash.clonedeep';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class DiscountMatrixDataExcelExportService {
  private readonly ALL_VALUE_DISPLAY_TEXT: string = 'All';

  constructor(
    private granularityService: GranularityService,
    private scenarioService: ScenarioService,
    private matrixViewDataService: DiscountMatrixViewDataService,
    private authService: AuthService,
    private governanceService: GovernanceService
  ) {}

  buildExcelExportDataBasedOnFilters(
    context: DiscountContext,
    scenarioId: string,
    scenarioName: string,
    selectedFiltersValue: ISelectedFiltersFormConfirmed
  ): Observable<IExcelExportData> {
    const matrixViewDs: DiscountMatrixViewDataSource = cloneDeep(this.matrixViewDataService.getCurrentDataSource());
    const matrixViewFullData: IMatrixViewDataSourceItem[] = matrixViewDs
      .getData()
      .map((item: IMatrixViewDataSourceItem) => {
        return {
          ...item,
          scenarioOutput: undefined,
        };
      });

    let excelBuildCurrentPage: number = 0;
    const itemObservable: Observable<any>[] = [];
    while (
      excelBuildCurrentPage <= matrixViewDs.getLastPage() &&
      matrixViewFullData.filter((a: IMatrixViewDataSourceItem) => !a.scenarioOutput).length
    ) {
      const matrixViewDataSourceItemIds: string[] = matrixViewFullData
        .slice(20 * excelBuildCurrentPage, 20 * excelBuildCurrentPage + 20)
        .filter((a: IMatrixViewDataSourceItem) => !a.scenarioOutput)
        .map((a: IMatrixViewDataSourceItem) => a.id);
      if (matrixViewDataSourceItemIds.length) {
        itemObservable.push(
          this.scenarioService.downloadMatrixViewKpiData(
            scenarioId,
            matrixViewDataSourceItemIds,
            selectedFiltersValue.market!
          )
        );
      }
      excelBuildCurrentPage += 1;
    }

    if (!itemObservable.length) {
      return of(this.buildExcelExportData(scenarioName, selectedFiltersValue, matrixViewDs.getData()));
    }

    return forkJoin(itemObservable).pipe(
      catchError((res) => {
        return throwError(res);
      }),
      map((responseItems: any[]) => {
        responseItems.forEach((itemArray): void => {
          const nextDataSourceItemsWithScenarioData: IMatrixViewDataSourceItem[] = matrixViewFullData.map(
            (dataSourceItem: IMatrixViewDataSourceItem) => {
              return this.matrixViewDataService.mapAllScenarioSources(
                dataSourceItem as IMatrixViewDataSourceItem,
                itemArray as IScenarioDataWithPricePoint[],
                [] as unknown as ICrossCheckData
              );
            }
          );
          matrixViewDs.setData(matrixViewDs.getCurrentPage(), [...nextDataSourceItemsWithScenarioData], true);
        });
        return this.buildExcelExportData(scenarioName, selectedFiltersValue, matrixViewDs.getData());
      })
    );
  }

  buildExcelExportData(
    scenarioName: string,
    selectedFiltersValue: ISelectedFiltersFormConfirmed,
    dataSource: IMatrixViewDataSourceItem[]
  ): IExcelExportData {
    const marketOptions = this.authService.getLoggedInUser()?.permissions.getMarkets();
    const { market, segment, brand, series, eSeries, model, powertrain, priceEditorFilled } =
      selectedFiltersValue.originalSelectedValues!;
    return {
      filters: {
        brand: this.formatGranularityListAsString(brand),
        dateOfExport: dayjs().format('DD.MM.YY'),
        eSeries: this.formatGranularityListAsString(eSeries),
        market: marketOptions?.find((option: IFtdDropdownOption<string>) => option.value === market)?.label as string,
        model: this.formatGranularityListAsString(model),
        powertrain: this.formatGrunarityListAsStringByProperty(powertrain, 'powertrain'),
        priceEditorFilled: priceEditorFilled ? 'Yes' : 'No',
        scenarioName,
        segment: this.formatGrunarityListAsStringByProperty(segment, 'segment'),
        series: this.formatGranularityListAsString(series),
      },
      matrixDataItems: this.mapMatrixDataSourceItemsToExcelData(dataSource, powertrain),
    };
  }

  private mapMatrixDataSourceItemsToExcelData(
    dataSource: IMatrixViewDataSourceItem[],
    powertrain: IGranularity[]
  ): IExcelExportEseriesDataItem[] {
    return dataSource
      .filter((item) => item.granularity?.type === GranularityType.E_SERIES)
      .map((eSerieData) => {
        const granularities: IGranularity[] = dataSource.map((dataItem) => dataItem.granularity);
        const modelChildrenGranularities: IGranularity[] = this.granularityService.getGranularityChildrenInGivenList(
          eSerieData.granularity,
          granularities,
          powertrain
        );
        const children: IExcelExportModelDataItem[] = [];
        modelChildrenGranularities.forEach((model) => {
          const modelCodeGranularities: IGranularity[] = this.granularityService.getGranularityChildrenInGivenList(
            model,
            granularities,
            powertrain
          );
          const modelCodechildren = modelCodeGranularities.map((modelCodeItem) => {
            const modelCodeData = dataSource.find((item) => item.id === modelCodeItem.id);
            return {
              currentCrossCheck: this.getMatrixSectionData(
                CurrentCrossCheckTechnicalName,
                modelCodeData?.scenarioOutput
              ),
              currentFinancial: this.getMatrixSectionData(CurrentFinancialTechnicalName, modelCodeData?.scenarioOutput),
              currentPricing: this.getMatrixSectionData(CurrentPricingTechnicalName, modelCodeData?.scenarioOutput),
              forecastedCrosscheck: this.getMatrixSectionData(
                ForecastedCrosscheckTechnicalName,
                modelCodeData?.scenarioOutput
              ),
              forecastedFinancial: this.getMatrixSectionData(
                ForecastedFinancialTechnicalName,
                modelCodeData?.scenarioOutput
              ),
              priceEditor: {
                ...this.getMatrixSectionData(PriceEditorTechnicalName, modelCodeData?.priceEditorData),
                comments: modelCodeData?.priceEditorData?.comments?.length
                  ? JSON.stringify(modelCodeData?.priceEditorData?.comments.map((comment) => comment.text))
                  : null,
                delta_to_current_price_editor: this.governanceService.calculateDeltaToCurrentPriceEditor(
                  modelCodeData?.scenarioOutput
                ),
              },
              priceEngineRecommendation: this.getMatrixSectionData(
                PriceEngineRecommendationTechnicalName,
                modelCodeData?.scenarioOutput
              ),
              vehicleEseries: modelCodeItem.eSeries,
              vehicleModelCode: `${modelCodeItem.modelCode} (${modelCodeItem.brand} ${modelCodeItem.model})`,
            } as IExcelExportModelDataItem;
          });
          children.push(...modelCodechildren);
        });
        const eSerieScenarioData = {
          currentCrossCheck: this.getMatrixSectionData(CurrentCrossCheckTechnicalName, eSerieData?.scenarioOutput),
          currentFinancial: this.getMatrixSectionData(CurrentFinancialTechnicalName, eSerieData?.scenarioOutput),
          currentPricing: this.getMatrixSectionData(CurrentPricingTechnicalName, eSerieData?.scenarioOutput),
          forecastedCrosscheck: this.getMatrixSectionData(
            ForecastedCrosscheckTechnicalName,
            eSerieData?.scenarioOutput
          ),
          forecastedFinancial: this.getMatrixSectionData(ForecastedFinancialTechnicalName, eSerieData?.scenarioOutput),
          priceEditor: {
            ...this.getMatrixSectionData(PriceEditorTechnicalName, eSerieData?.priceEditorData),
            comments: eSerieData?.priceEditorData?.comments?.length
              ? JSON.stringify(eSerieData?.priceEditorData?.comments.map((comment) => comment.text))
              : null,
            delta_to_current_price_editor: this.governanceService.calculateDeltaToCurrentPriceEditor(
              eSerieData?.scenarioOutput
            ),
          },
          priceEngineRecommendation: this.getMatrixSectionData(
            PriceEngineRecommendationTechnicalName,
            eSerieData?.scenarioOutput
          ),
          vehicleEseries: eSerieData.granularity.eSeries,
          vehicleModelCode: eSerieData.granularity.eSeries,
        } as IExcelExportModelDataItem;
        return {
          children: children,
          vehicleEseries: eSerieScenarioData,
        };
      });
  }

  /**
   * Generate data for each matrix-view section based on {@link matrixColumEnumObject} & given {@link objectValueHolder}
   * @param matrixColumEnumObject
   * @param objectValueHolder
   * @returns
   */
  private getMatrixSectionData(matrixColumEnumObject: Object, objectValueHolder: any): any {
    const matrixSectionData: any = {};
    Object.values(matrixColumEnumObject).map((columnTechnicalName: string) => {
      matrixSectionData[columnTechnicalName] = objectValueHolder?.[columnTechnicalName];
    });
    return matrixSectionData;
  }

  private formatGranularityListAsString(granularities: IGranularity[]): string {
    if (granularities && granularities.length > 0) {
      return granularities.map((item) => item.displayName).join(', ');
    }
    return this.ALL_VALUE_DISPLAY_TEXT;
  }

  private formatGrunarityListAsStringByProperty(granularities: IGranularity[], property: keyof IGranularity): string {
    if (granularities && granularities.length > 0) {
      return granularities.map((item) => item[property]).join(', ');
    }
    return this.ALL_VALUE_DISPLAY_TEXT;
  }
}
