import { APP_FSM_RELATED_EXTERNAL_LINKS } from '../../../../../common/constants/app-fsm-related-external-links';
import { ApprovalModalType } from '../../enums/approval-status.enum';
import { ApproveScenarioModalComponent } from '../../../../../scenario/modals/approve-scenario-modal/approve-scenario-modal.component';
import { AuthService } from '../../../../../auth/services/auth.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ContextService, DiscountContext } from '../../services/context/context.service';
import { DiscountMatrixDataExcelExportService } from '../../services/matrix-data-excel-export/matrix-data-excel-export.service';
import { DiscountMatrixViewDataFormattingService } from '../../services/matrix-view-data-formatting/matrix-view-data-formatting.service';
import { DiscountMatrixViewDataService } from '../../services/matrix-view-data/matrix-view-data.service';
import { DiscountMatrixViewFormService } from '../../services/matrix-view-form/matrix-view-form.service';
import { ExportExcelService } from '../../../../../common/services/export-excel/export-excel.service';
import { FormGroup } from '@angular/forms';
import { FtdActionsToolbarComponent } from '../../../../../common/components/ftd-actions-toolbar/ftd-actions-toolbar.component';
import { FtdModalSize } from '../../../../../common/components/ftd-modal/ftd-modal-size.enum';
import {
  FtdProgressBarModalComponent,
  IProgressBarModalData,
} from '../../../../../common/components/ftd-progress-bar-modal/ftd-progress-bar-modal.component';
import { GovernanceService } from '../../services/governance/governance.service';
import { GranularityService } from '../../services/granularity/granularity.service';
import { IDiscount } from '../../../../../graphql/services/gql-api.service';
import { IExcelExportData } from '../../models/excel-export-data.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MessageService } from 'src/app/common/services/message/message.service';
import { Subscription } from 'rxjs';
import { User } from '../../../../../auth/models/user.model';
import { getCurrencySymbol } from '@angular/common';
import dayjs from 'dayjs';

export enum SUBMIT_SCENARIO_MESSAGES {
  INCLUDE_DATE = 'You need to include Effective Date to submit the scenario',
  INCLUDE_PRICE = 'You need to include Price to submit the scenario',
  INCLUDE_PRICE_AND_DATE = 'You need to include an Price and an Effective Date to submit the scenario',
}

export enum SAVE_SCENARIO_MESSAGES {
  INCLUDE_DATE = 'You need to include Effective Date to save the scenario',
  INCLUDE_PRICE = 'You need to include Price to save the scenario',
  INCLUDE_PRICE_AND_DATE = 'You need to include an Price and an Effective Date to save the scenario',
}

export enum SAVE_BUTTON_TOOLTIP {
  ENABLED = 'Pressing this button will save and simulate the prices',
  DISABLED = "You don't have any changes to save",
}

@Component({
  selector: 'app-discount-matrix-view-table-header',
  styleUrls: ['./matrix-view-table-header.component.scss'],
  templateUrl: './matrix-view-table-header.component.html',
})
/**
 * @class
 * MatrixViewTableHeaderComponent
 */
export class DiscountMatrixViewTableHeaderComponent implements OnInit, OnDestroy {
  @Input() title: string = 'Discount';
  @Input() description: string = '';
  @Input() isTitleHidden: boolean = false;
  @Input() isDescriptionHidden: boolean = false;
  @Input() isDividerHidden: boolean = false;
  @Output() toggleFullscreen: EventEmitter<any> = new EventEmitter<any>();
  @Input({ required: true }) discount!: IDiscount;

  private formValidRows: FormGroup[] = [];
  public currentUser?: User | null;
  private formValidRowsChecked: FormGroup[] = [];
  private formRowsChecked: FormGroup[] = [];
  public context: DiscountContext = DiscountContext.read;
  public isSaveDisabled: boolean = true;
  public loading: boolean = false;
  private matSnackBarRef: MatSnackBarRef<FtdActionsToolbarComponent> | null = null;
  releaseModal = {
    description: 'You are giving the final approve of the prices. After this operation, the prices will be released.',
    label: 'Release',
    title: 'Release Prices',
    type: ApprovalModalType.RELEASE,
  };

  approveModal = {
    description: 'You are approving the price points.',
    label: 'Approve',
    title: 'Approve Prices',
    type: ApprovalModalType.APPROVE,
  };

  APP_FSM_RELATED_EXTERNAL_LINKS = APP_FSM_RELATED_EXTERNAL_LINKS;

  get saveToolTip(): string {
    return this.isSaveDisabled ? SAVE_BUTTON_TOOLTIP.DISABLED : SAVE_BUTTON_TOOLTIP.ENABLED;
  }

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param dialog
   * @param matrixViewFormService
   * @param matrixViewDataFormattingService
   * @param exportExcelService
   * @param matrixDataExcelExportService
   * @param granularityService
   * @param matrixViewDataService
   * @param messageService
   * @param governanceService
   * @param authService
   * @param route
   * @param contextService
   * @param snackBar
   */
  constructor(
    public dialog: MatDialog,
    private matrixViewFormService: DiscountMatrixViewFormService,
    private matrixViewDataFormattingService: DiscountMatrixViewDataFormattingService,
    private exportExcelService: ExportExcelService,
    private matrixDataExcelExportService: DiscountMatrixDataExcelExportService,
    private granularityService: GranularityService,
    private matrixViewDataService: DiscountMatrixViewDataService,
    private messageService: MessageService,
    private governanceService: GovernanceService,
    private authService: AuthService,
    private contextService: ContextService,
    private snackBar: MatSnackBar
  ) {
    this.context = this.contextService.getCurrentDiscountContext();
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initUserListener();
    this.matrixViewFormService?.resetForm();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * InitUserListener
   */
  initUserListener(): void {
    const subscription: Subscription = this.authService
      .getLoggedInUserAsObservable()
      .subscribe((user: User | null) => (this.currentUser = user));
    this.subscriptions.push(subscription);
  }

  getMaskedDate(date: string): string {
    return dayjs(date).format('DD.MM.YY');
  }

  /**
   * OpenApproveScenarioModal
   */
  openApproveScenarioModal(modalText: any): void {
    const dialogRef: MatDialogRef<ApproveScenarioModalComponent> = this.dialog.open(ApproveScenarioModalComponent, {
      data: modalText,
      maxWidth: FtdModalSize.S,
    });

    const subscription: Subscription = dialogRef.afterClosed().subscribe((): void => {});
    this.subscriptions.push(subscription);
  }

  /**
   * OpenProgressBarModal
   * @param asyncCallback
   * @return MatDialogRef<FtdProgressBarModalComponent>
   */
  openProgressBarModal(asyncCallback?: () => Promise<void>): MatDialogRef<FtdProgressBarModalComponent> {
    return this.dialog.open(FtdProgressBarModalComponent, {
      data: {
        asyncCallback,
        colorLeftMatIcon: 'white',
        description: 'The export of this file will take a few seconds',
        title: 'Export Excel',
        titleLeftMatIcon: 'download',
      } as IProgressBarModalData,
      maxWidth: FtdModalSize.S,
      width: '90%',
    });
  }

  /**
   * OpenProgressBarModalAndDownloadExcelFileBasedOnSelectedFilters
   */
  openProgressBarModalAndDownloadExcelFileBasedOnSelectedFilters(): void {
    this.openProgressBarModal(() => this.downloadExcelFileBasedOnSelectedFilters());
  }

  /**
   * OnClickFullscreenBtn
   */
  onClickFullscreenBtn($event: MouseEvent): void {
    this.toggleFullscreen.emit($event);
  }

  /**
   * DownloadExcelFileBasedOnSelectedFilters
   * @private
   * @async
   */
  private async downloadExcelFileBasedOnSelectedFilters(): Promise<void> {
    try {
      const qxNumber = this.currentUser?.sub ? this.currentUser.sub : this.currentUser?.name;
      const excelExportData: IExcelExportData = await this.matrixDataExcelExportService
        .buildExcelExportDataBasedOnFilters(
          this.context,
          this.contextService.discountId,
          `My Pricing View ${qxNumber?.toUpperCase()}`,
          this.matrixViewDataService.getCurrentSelectedFilters()
        )
        .toPromise();

      await this.downloadExcelFile(excelExportData);
    } catch (e) {
      this.messageService.showError('An error occurred while preprocessing the data for the Excel download', 4);
    }
  }

  /**
   * DownloadExcelFile
   * @param excelExportData
   * @private
   * @return Promise<void>
   */
  private downloadExcelFile(excelExportData: IExcelExportData): Promise<void> {
    const date: Date = new Date();
    const marketCurrency: string = getCurrencySymbol(
      this.matrixViewDataFormattingService.getMatrixViewCurrency(),
      'narrow'
    );
    return this.exportExcelService
      .exportExcel({
        currency: marketCurrency,
        data: excelExportData,
        title:
          date.getFullYear().toString() +
          (date.getMonth() + 1).toString() +
          date.getUTCDate().toString() +
          '_FSM_Pricing_Excel_download',
      })
      .catch((error) => this.messageService.showError(error));
  }

  /**
   * ShowDivider
   * @return boolean
   */
  get showDivider(): boolean {
    return Boolean(
      this.context === DiscountContext.read &&
        this.currentUser?.permissions?.getHasPermissionToApproveMainScenario() &&
        this.currentUser?.permissions?.getHasPermissionToDownloadScenario()
    );
  }

  /**
   * GetShowHorizontalDivider
   * @return boolean
   */
  getShowHorizontalDivider(): boolean {
    if (
      (this.context === DiscountContext.read &&
        this.currentUser?.permissions.getHasPermissionToApproveMainScenario()) ||
      this.currentUser?.permissions.getHasPermissionToDownloadScenario()
    ) {
      return true;
    } else if (
      this.context === DiscountContext.create &&
      (this.currentUser?.permissions.getHasPermissionToSubmitToMainScenario() ||
        this.currentUser?.permissions.getHasPermissionToSaveUserScenario())
    ) {
      return true;
    }
    return false;
  }
}
