import { AuthService } from '../../../auth/services/auth.service';
import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { FetchScenarioListDataApiError } from '../../../core/error-handling/errors/error-functions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFtdDropdownOption } from '../../../common/models/ftd-dropdown-option.model';
import { IScenario } from 'src/app/graphql/services/gql-api.service';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable, Subject, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { ScenariosGQLAPIService } from 'src/app/graphql/services/scenarios-gql-api.service';

import { INewScenarioForm, NewScenarioForm } from './new-scenario.form';
import { MessageService } from 'src/app/common/services/message/message.service';
import { User } from 'src/app/auth/models/user.model';
import { catchError, map, shareReplay, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-new-scenario-modal',
  templateUrl: './new-scenario-modal.component.html',
})
export class NewScenarioModalComponent implements OnInit, OnDestroy {
  // Injects required Services
  private _router = inject(Router);
  private _formBuilder = inject(FormBuilder);
  private _authService = inject(AuthService);
  private messageService = inject(MessageService);
  private _scenarioGQLAPIService = inject(ScenariosGQLAPIService);
  public isSubmitButtonDisabled: boolean = false;
  public isRequestProcessing: boolean = false;

  constructor(private _dialogRef: MatDialogRef<NewScenarioModalComponent>) {}

  // Current logged in user's ID
  currentUserId!: User['sub'];
  currentUser!: User;
  // New scenario creation form objects
  newScenarioFormObj!: NewScenarioForm;
  newScenarioForm!: FormGroup<INewScenarioForm>;

  /**
   * List of User scenarios
   */
  userScenarios$!: Observable<IFtdDropdownOption<string>[]>;

  // Handles unsubscription for this component
  private _destroy$ = new Subject<unknown>();

  ngOnInit(): void {
    this.currentUser = this._authService.getLoggedInUser()!;

    // Get a list of scenarios created by current logged user
    this.userScenarios$ = this.getUserScenariosByUserId$();

    // Instantiate New scenario creation form
    this.newScenarioFormObj = new NewScenarioForm(this._formBuilder, this.currentUser?.sub, this.userScenarios$);
    this.newScenarioForm = this.newScenarioFormObj.form;
  }

  ngOnDestroy(): void {
    /*
     *Pass null to insert a value to _destroy$ will complete
     *the subscription as we have used takeUntil
     */
    this._destroy$.next(null);
    this._destroy$.complete();
  }

  /**
   * Fetch list of user scenarios for logged in scenario from GraphQL scenarios query
   * @returns Observable<Scenario[]>
   */
  getUserScenariosByUserId$ = (): Observable<IFtdDropdownOption<string>[]> => {
    return this._scenarioGQLAPIService.scenarioByUserId$().pipe(
      // Cache the results to not send multiple request on every validation in form
      shareReplay(),
      // Destroy the subscription after this._destroy$ completes
      takeUntil(this._destroy$),
      // Transform the list of scenario object to format, so it can be passed to dropdowns
      map((scenarioList: IScenario[]) =>
        this.convertScenarioListMetadataResponseToFilteredFtdDropdownOption(scenarioList)
      ),
      // Disable the checkbox to which enables to show list of user scenario
      tap(
        (userScenarios) =>
          !userScenarios?.length && this.newScenarioFormObj.control('isUseExistingUserScenarioChecked').disable()
      ),
      // Catch any error which might occur while fetching scenario list
      catchError((error) => {
        this.messageService.showError(FetchScenarioListDataApiError?.reason);
        return throwError(error);
      })
    );
  };

  private convertScenarioListMetadataResponseToFilteredFtdDropdownOption(
    scenarioList: IScenario[]
  ): IFtdDropdownOption<string>[] {
    return (
      scenarioList
        .slice()
        // Sort by creation date
        .sort((a, b) => b.creationDate.localeCompare(a.creationDate))
        .map((scenario: IScenario) => {
          return {
            id: scenario.id,
            label: scenario.scenarioName,
            value: scenario.id,
          };
        }) as IFtdDropdownOption<string>[]
    );
  }

  /**
   * Sends request to create a new scenario either to copy from existing user scenario or System scenario
   */
  createNewScenario(): void {
    const { scenarioName, userScenarioId } = this.newScenarioForm.getRawValue();
    this.isSubmitButtonDisabled = true;
    const userSelectedMarket = this.currentUser.permissions.getSelectedMarket()!.toString();
    this._scenarioGQLAPIService
      .createUserScenario$({ market: userSelectedMarket, scenarioName, userScenarioId })
      .pipe(
        takeUntil(this._destroy$),
        tap((scenario: IScenario) => {
          this.onCreateNewScenarioSuccess();
          this.redirectToUserScenarioById(scenario.id);
        })
      )
      .subscribe();
  }

  onCreateNewScenarioSuccess() {
    this.isSubmitButtonDisabled = false;
    this._dialogRef.close();
    this.messageService.showSuccess(`Scenario created`, 5);
  }

  redirectToUserScenarioById(id: string) {
    this._router.navigate(['user-scenario', id]);
  }
}
