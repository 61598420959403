export type ROLE = 'reader' | 'editor' | 'lead-editor' | 'approver' | 'admin' | 'invalid_role';

export enum RoleDefinition {
  'admin' = 'Admin',
  'approver' = 'Approver',
  'lead-editor' = 'Pricing Team Lead',
  'editor' = 'Pricing Manager',
  'reader' = 'Reader',
  'invalid_role' = 'Invalid Role',
}

export type KeyofRoleDefinition = keyof typeof RoleDefinition;
