<div id="table-header-container" class="w-100" *ngIf="!isDividerHidden && getShowHorizontalDivider()">
  <div class="ptb-16">
    <div id="table-header-actions-container" fxLayout="row" fxLayoutAlign="center center">
      <div>
        <b>{{ discount.discountType + ' ' }} </b> <span>{{ discount.name }}</span>
      </div>
      <div class="action-buttons">
        <div class="date-range-container">
          <div class="date-range">
            {{ getMaskedDate(discount.startDate!) }} - {{ getMaskedDate(discount.endDate!) }}
          </div>
        </div>
        <div>
          <button
            aria-label="Open more actions"
            mat-icon-button
            class="actions-button"
            [attr.data-test]="'more-actions-btn'"
          >
            <mat-icon class="action-icon"> more_vert </mat-icon>
          </button>
        </div>
        <div>
          <button aria-label="Open notes" mat-icon-button class="actions-button" [attr.data-test]="'open-notes-btn'">
            <mat-icon class="action-icon"> description </mat-icon>
          </button>
        </div>
        <div>
          <button aria-label="Open comments" mat-icon-button class="actions-button">
            <mat-icon class="action-icon"> chat </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!isDividerHidden && getShowHorizontalDivider()" class="divider">
    <mat-divider></mat-divider>
  </div>
</div>
