<ftd-tab-group>
  <ftd-tab>
    <ftd-tab-label
      tabLabel
      [title]="'Markets'"
      [percentage]="trafficLightMarket?.weight || ''"
      [trafficLights]="!!trafficLightMarket"
      [trafficLightColor]="trafficLightMarket?.color"
      [info]="true"
      [infoText]="tooltips?.markets"
    ></ftd-tab-label>
    <app-markets
      [granularity]="filters"
      [crosscheckIndication]="crosscheckIndication"
      [crosscheckIndicationForecasted]="crosscheckIndicationForecasted"
    ></app-markets>
  </ftd-tab>
</ftd-tab-group>
