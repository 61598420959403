<app-matrix-view-wrapper
  [title]="title"
  [isReturnButtonHidden]="isReturnButtonHidden"
  [isMatrixViewDataHidden]="isMatrixViewDataHidden"
  [isMatrixViewSideFiltersHidden]="isMatrixViewSideFiltersHidden"
  [isExportButtonHidden]="isExportButtonHidden"
  [isApproveScenarioButtonHidden]="isApproveScenarioButtonHidden"
  [isUnlockScenarioButtonHidden]="isUnlockScenarioButtonHidden"
  [isSubmitScenarioButtonHidden]="isSubmitScenarioButtonHidden"
>
</app-matrix-view-wrapper>
