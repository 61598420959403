<div id="columns-ordering-modal-container">
  <div id="columns-ordering-search">
    <form [formGroup]="form" novalidate>
      <app-ftd-input
        [form]="form"
        controlName="search"
        prefixIconName="search"
        [id]="'columns-ordering-search'"
        [placeholder]="'Search'"
        [fillWidth]="true"
      ></app-ftd-input>
    </form>
  </div>

  <div id="columns-ordering">
    <div cdkDropList class="columns-ordering-list" (cdkDropListDropped)="drop($event, matrixColumnConfig)">
      <ng-container *ngFor="let column of matrixColumnConfig">
        <div
          class="columns-ordering-item"
          [class.is-on-drag]="isOnDrag"
          cdkDrag
          *ngIf="!column.isHidden"
          (cdkDragStarted)="isOnDrag = true"
          (cdkDragEnded)="isOnDrag = false"
        >
          <span>
            <button
              aria-label="Expand/Collapse"
              mat-icon-button
              class="expand-collapse-button"
              (click)="column.isExpand = !column.isExpand"
            >
              <mat-icon *ngIf="!column.isExpand">chevron_right</mat-icon>
              <mat-icon *ngIf="column.isExpand">expand_more</mat-icon>
            </button>

            <mat-checkbox
              [checked]="column.isVisible"
              (change)="setChildVisibility(column, $event.checked)"
            ></mat-checkbox>

            <b>{{ column.displayName }}</b>
          </span>
          <mat-icon class="column-handle" cdkDragHandle>drag_indicator</mat-icon>
        </div>
        <div
          cdkDropList
          class="children-list columns-ordering-list inner-column"
          [class.expanded]="column.isExpand"
          [class.collapsed]="!column.isExpand"
          (cdkDropListDropped)="drop($event, column.children!)"
          (cdkDropListEntered)="setIsOnDrag(true)"
          (cdkDropListExited)="setIsOnDrag(false)"
        >
          <span>
            <ng-container *ngFor="let innerColumn of column.children">
              <div
                class="columns-ordering-item"
                cdkDrag
                *ngIf="!innerColumn.isHidden"
                [class.is-on-drag]="isOnDrag"
                (cdkDragStarted)="setIsOnDrag(true)"
                (cdkDragEnded)="setIsOnDrag(false)"
              >
                <span>
                  <div
                    [matTooltip]="'Pinned columns cannot be hidden'"
                    [matTooltipDisabled]="!innerColumn.isNotCollapsible || !column.isVisible"
                  >
                    <mat-checkbox
                      [disabled]="innerColumn.isNotCollapsible && column.isVisible"
                      [checked]="innerColumn.isVisible"
                      (change)="setParentVisibility(innerColumn, column, $event.checked)"
                    ></mat-checkbox>
                  </div>

                  {{ innerColumn.displayName }}
                </span>
                <div>
                  <mat-icon
                    (click)="
                      innerColumn.isNotCollapsible = checkIsNotCollapsible(column, !innerColumn.isNotCollapsible)
                        ? !innerColumn.isNotCollapsible
                        : innerColumn.isNotCollapsible
                    "
                    *ngIf="innerColumn.isNotCollapsible"
                    [ngClass]="{
                      'column-favorite-disabled': !checkIsNotCollapsible(column, !innerColumn.isNotCollapsible)
                    }"
                    class="column-favorite"
                    [matTooltip]="'There must be at least one pinned column'"
                    [matTooltipDisabled]="checkIsNotCollapsible(column, !innerColumn.isNotCollapsible)"
                    [matTooltipShowDelay]="500"
                    >star</mat-icon
                  >
                  <mat-icon
                    (click)="
                      innerColumn.isNotCollapsible =
                        checkIsNotCollapsible(column, !innerColumn.isNotCollapsible) && innerColumn.isVisible
                          ? !innerColumn.isNotCollapsible
                          : innerColumn.isNotCollapsible
                    "
                    *ngIf="!innerColumn.isNotCollapsible"
                    [ngClass]="{
                      'column-favorite-disabled':
                        !checkIsNotCollapsible(column, !innerColumn.isNotCollapsible) || !innerColumn.isVisible
                    }"
                    class="column-favorite not-selected"
                    [matTooltip]="'Only visible columns can be pinned, with a maximum of 3'"
                    [matTooltipDisabled]="
                      checkIsNotCollapsible(column, !innerColumn.isNotCollapsible) && innerColumn.isVisible
                    "
                    >star_border</mat-icon
                  >
                  <mat-icon class="column-handle" cdkDragHandle>drag_indicator</mat-icon>
                </div>
              </div>
            </ng-container>
          </span>
        </div>
      </ng-container>
    </div>
  </div>
</div>
