import { Component } from '@angular/core';

@Component({
  selector: 'app-discount-mv-disabled-display',
  styleUrls: ['./mv-disabled-display.component.scss'],
  template: ` <span class="disabled-row"> - </span> `,
})
/**
 * @class MvDisabledDisplayComponent
 */
export class MvDisabledDisplayComponent {}
