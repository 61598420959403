import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChartAxisType, GenericChartValueFields } from '../../enums/ftd-generic-chart.enum';
import { FtdAm5ChartsThemeHelper, FtdChartCustomThemes } from '../../themes/ftd-am5-charts.theme';
import { FtdBaseChart } from '../../ftd-base-chart.abstract';
import { IGenericChartHeadings } from '../../models/ftd-generic-chart.model';
import { ILineChartData } from '../../models/ftd-line-chart.model';
import { LineSeries } from '@amcharts/amcharts5/xy';
import { LinearGradient, Scrollbar } from '@amcharts/amcharts5';

let _rootId: number = 0;

@Component({
  selector: 'app-ftd-line-chart',
  templateUrl: './ftd-line-chart.component.html',
})
export class FtdLineChartComponent extends FtdBaseChart implements AfterViewInit, OnInit {
  @Input() rootId!: string;
  @Input() set chartLegend(currentLegend: string[]) {
    this.chart?.series?.each((serie) => {
      currentLegend.includes(serie.get('valueYField')!) ? serie.show() : serie.hide();
    });
  }

  @Input() set data(value: ILineChartData) {
    if (value) {
      this._data = value;
      if (this.chart?.series) {
        this.setXAxis();
        this.setYAxis();
        this.setScrollBar();
        this.chart.series.clear();
        // Set Series
        this._data.headings.forEach((lineChartHeadings: IGenericChartHeadings) => {
          this.setSeries(lineChartHeadings.description, lineChartHeadings.key);
        });
        if (this.isSysDateIndicatorVisible) {
          this.setSysDateIndicator();
        }
      }
    }
  }

  @Output() seriesOnClick: EventEmitter<any> = new EventEmitter();

  ngOnInit() {
    _rootId += 1;
    this.rootId = `ftd-line-chart-ref-${_rootId}`;
  }

  /**
   * NgAfterViewInit
   */

  ngAfterViewInit() {
    this.browserOnly(() => {
      this.initChart();
    });
  }

  /**
   * InitChart
   */
  initChart() {
    // Set Root (ftd-chart-ref-${id}})
    this.setRoot();

    // Set Theme
    this.setTheme();

    // Set Chart
    this.setChart();

    // Create Y-axis
    this.setYAxis();

    // Create X-Axis
    this.setXAxis();

    // Set Label Description
    this.setAxisLabelDescription();

    // Set Series
    this._data.headings.forEach((genericChartHeadings: IGenericChartHeadings) => {
      this.setSeries(genericChartHeadings.description, genericChartHeadings.key);
    });

    // Add Cursor tooltip if enabled
    if (this.isCursorVisible) {
      this.setCursor();
    }

    // Add SysDate Indicator if enabled
    if (this.isSysDateIndicatorVisible) {
      this.setSysDateIndicator();
    }

    if (this.isScrollBarVisible) {
      this.setScrollBar();
    }
  }

  /**
   * SetRoot
   * @private
   */
  private setRoot(): void {
    this.root = this.am5.Root.new(this.rootId);
  }

  /**
   * SetTheme
   * @private
   */
  private setTheme(): void {
    this.chartCustomTheme
      ? this.root.setThemes([
          this.am5themes_Ftd.new(this.root),
          FtdChartCustomThemes[this.chartCustomTheme].new(this.root),
        ])
      : this.root.setThemes([this.am5themes_Ftd.new(this.root)]);
  }

  /**
   * SetChart
   * @private
   */
  private setChart(): void {
    this.chart = this.root.container.children.push(
      this.am5xy.XYChart.new(this.root, {
        layout: this.root.verticalLayout,
        maxTooltipDistance: 0,
      })
    );
  }

  /**
   * SetYAxis
   * @protected
   */
  protected setYAxis(): void {
    super.setYAxis();
  }

  /**
   * SetXAxis
   * @protected
   */
  protected setXAxis(): void {
    super.setXAxis();
  }

  /**
   * SetAxisLabelDescription
   * @protected
   */

  protected setAxisLabelDescription() {
    super.setAxisLabelDescription();
  }

  /**
   * SetCursor
   * @private
   */
  private setCursor(): void {
    this.chart.set(
      'cursor',
      this.am5xy.XYCursor.new(this.root, {
        xAxis: this.xAxis,
      })
    );
  }

  /**
   * CreateSeries
   * @param name
   * @param valueYField
   * @private
   */

  private setSeries(name: string, valueYField: string): void {
    const serie: LineSeries = this.am5xy.LineSeries.new(this.root, {
      categoryXField: this.axisType === ChartAxisType.CATEGORY ? GenericChartValueFields.X : undefined,
      name: name,
      valueXField: GenericChartValueFields.X,
      valueYField: valueYField,
      xAxis: this.xAxis,
      yAxis: this.yAxis,
    });

    const series: LineSeries = this.chart.series.push(serie);

    // Add Bullet
    series.bullets.push(() => {
      return this.am5.Bullet.new(this.root, {
        sprite: this.am5.Circle.new(this.root, {
          fill: series.get('fill'),
          radius: 5,
          tooltip: FtdAm5ChartsThemeHelper.getTooltip(this.root, 'horizontal', this.isTooltipVisible),
          tooltipHTML: FtdAm5ChartsThemeHelper.buildChartTooltipHTML([{ key: '{name}', value: '{valueY}' }]),
        }),
      });
    });

    // Add Label
    series.bullets.push(() => {
      const label = this.am5.Bullet.new(this.root, {
        locationX: 0.5,
        locationY: 1,
        sprite: this.am5.Label.new(this.root, {
          centerX: 23,
          centerY: 30,
          dy: -20,
          populateText: true,
          text: '{valueY}',
          themeTags: ['ftd-bullet-label'],
          visible: this.isLabelValuesVisible,
        }),
      });
      label.set('id', `label_${label.uid}`);
      return label;
    });

    // Pass callback event-emitter to series in order to access them inside Theme class and bind to Elements like Bullets, Labels ...
    series?.events.on(
      'click',
      (event) => {
        this.seriesOnClick.emit(event.target.dataItem?.dataContext);
      },
      this
    );

    // Set Data
    series.data.setAll(this._data.fields);
  }

  /**
   * SetScrollBar
   * @private
   */

  private setScrollBar() {
    const scrollBarX = Scrollbar.new(this.root, { orientation: 'horizontal' });
    scrollBarX.get('background')?.set('forceHidden', true);
    scrollBarX.startGrip.set('forceHidden', true);
    scrollBarX.endGrip.set('forceHidden', true);
    scrollBarX.thumb.setAll({
      fillGradient: LinearGradient.new(this.root, {
        rotation: 0,
        stops: [
          {
            color: this.am5.color(0x565b76),
          },
          {
            color: this.am5.color(0x7b8ec0),
          },
        ],
      }),
      height: 8,
    });

    this.chart.set('scrollbarX', scrollBarX);
    this.chart.bottomAxesContainer.children.push(scrollBarX);
  }
}
