import { Apollo, TypedDocumentNode } from 'apollo-angular';
import { CreateScenarioDataError } from 'src/app/core/error-handling/errors/error-functions';
import {
  Exact,
  IDeleteScenarioResponse,
  IMutationAddScenarioArgs,
  IMutationDeleteScenarioArgs,
  IScenario,
  Scalars,
  ScenarioType,
} from './gql-api.service';
import { Injectable, inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { gql } from 'apollo-angular';

export type ScenariosQuery = {
  scenarios: Array<IScenario>;
};

export type ScenariosQueryVariables = Exact<{
  userId: Scalars['String'];
}>;

export type AddScenariosQuery = {
  addScenario: IScenario;
};

export type AddScenariosQueryVariables = Exact<{
  scenarioName: Scalars['String'];
  scenarioType?: ScenarioType;
  userScenarioId: Scalars['String'];
  market: Scalars['String'];
}>;

@Injectable({
  providedIn: 'root',
})
export class ScenariosGQLAPIService {
  private _apollo = inject(Apollo);

  private _GET_SCENARIOS = gql`
    query GetScenarios {
      scenarios {
        id
        scenarioType
        scenarioName
        userId
        creationDate
        lastUpdatedDate
        status
      }
    }
  `;

  /**
   * Get scenario-data-list for a user using GraphQL Query
   * @param userId
   * @returns
   */
  scenarioByUserId$(): Observable<ScenariosQuery['scenarios']> {
    return this._apollo
      .query<ScenariosQuery, ScenariosQueryVariables>({
        fetchPolicy: 'no-cache',
        query: this._GET_SCENARIOS,
      })
      .pipe(map((response) => response.data.scenarios));
  }

  private _COPY_FROM_SYSTEM_SCENARIO = gql`
    mutation CopyFromSystemScenario($input: AddScenariosInput!, $market: String!) {
      addScenario(input: $input, market: $market) {
        creationDate
        id
        kpiCalculatedDate
        lastUpdatedDate
        predictionComponentURI
        scenarioName
        scenarioType
        status
        userId
      }
    }
  `;

  private _COPY_FROM_USER_SCENARIO = gql`
    mutation CopyFromUserScenario($input: AddScenariosInput!, $market: String!) {
      addScenario(input: $input, market: $market) {
        creationDate
        id
        kpiCalculatedDate
        lastUpdatedDate
        predictionComponentURI
        scenarioName
        scenarioType
        status
        userId
      }
    }
  `;

  /**
   * Create a user scenario using GraphQL Query, by either copying
   * from System or User scenarios
   * @returns
   * @param queryVariable
   */
  createUserScenario$(queryVariable: AddScenariosQueryVariables): Observable<AddScenariosQuery['addScenario']> {
    let variables: IMutationAddScenarioArgs;
    let mutation!: TypedDocumentNode<unknown, unknown>;
    if (queryVariable.userScenarioId) {
      mutation = this._COPY_FROM_USER_SCENARIO;
      variables = {
        input: {
          scenarioName: queryVariable.scenarioName,
          scenarioType: ScenarioType.User,
          userScenarioId: queryVariable.userScenarioId,
        },
        market: queryVariable.market,
      };
    } else {
      mutation = this._COPY_FROM_SYSTEM_SCENARIO;
      variables = {
        input: { scenarioName: queryVariable.scenarioName, scenarioType: ScenarioType.User },
        market: queryVariable.market,
      };
    }

    return this._apollo
      .mutate<AddScenariosQuery, IMutationAddScenarioArgs>({
        mutation,
        variables,
      })
      .pipe(
        map((response) => response.data?.addScenario || ({} as AddScenariosQuery['addScenario'])),
        catchError((error) => throwError({ reason: error, type: CreateScenarioDataError.type }))
      );
  }

  private _DELETE_SCENARIO = gql`
    mutation DeleteScenarioByScenarioId($scenarioId: String!) {
      deleteScenario(scenarioId: $scenarioId) {
        response
      }
    }
  `;

  /**
   * Delete a user scenario using GraphQL Query
   * @param scenarioName
   * @param scenarioType
   * @returns
   */
  deleteUserScenario$(variables: IMutationDeleteScenarioArgs): Observable<IDeleteScenarioResponse['response']> {
    return this._apollo
      .mutate<IDeleteScenarioResponse, Partial<IMutationDeleteScenarioArgs>>({
        mutation: this._DELETE_SCENARIO,
        variables,
      })
      .pipe(
        map((response) => response.data?.response || ('' as IDeleteScenarioResponse['response'])),
        catchError((_) => {
          return throwError(CreateScenarioDataError.reason);
        })
      );
  }
}
