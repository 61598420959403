<div *ngIf="isUserScenario; else mainScenario">
  <input
    *ngIf="priceHolder && technicalName && priceHolder[technicalName]; else noPrice"
    matInput
    type="text"
    [value]="priceHolder[technicalName]"
    [disabled]="!userHasAccess()"
  />
  <ng-template #noPrice>{{ 'Not submitted' }}</ng-template>
</div>
<ng-template #mainScenario>
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <app-price-approval-status-chip
      *ngIf="approvalStatus && showApprovalStatusChip"
      [approvalStatus]="approvalStatus"
      class="mr-2"
    ></app-price-approval-status-chip>
    <span *ngIf="!(approvalStatus && showApprovalStatusChip)" class="mr-2"></span>
    <span *ngIf="priceHolder && technicalName">
      {{
        priceHolder[technicalName]
          ? (priceHolder[technicalName] | number: '1.2-2') + ' ' + currencySymbol
          : (priceHolder[technicalName] | nullLabel: 'notSubmitted')
      }}
    </span>
  </div>
</ng-template>
