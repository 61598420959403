import { Component, Input } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-discount-mv-changed-value',
  styleUrls: ['./mv-changed-value.component.scss'],
  templateUrl: './mv-changed-value.component.html',
})
export class MvChangedValueComponent {
  @Input() newValue: number = 0;

  @Input() oldValue: number = 0;

  @Input() technicalName: string = '';

  _currency: string = 'EUR';
  get currency(): string {
    return this._currency;
  }

  @Input() set currency(currency: string) {
    this._currency = currency;
    this.currencySymbol = getCurrencySymbol(this.currency, 'narrow');
  }

  currencySymbol: string = 'EUR';
  validTechnicalNames: string[] = ['delta_to_current_price_editor'];

  isValid() {
    return this.newValue && this.oldValue && this.validTechnicalNames.includes(this.technicalName);
  }

  getRelativeChange() {
    return this.newValue - this.oldValue;
  }
}
