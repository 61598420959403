export enum ScenarioApiError {
  FETCH_GRANULARITY_DATA_ERROR = 'FETCH_GRANULARITY_DATA_ERROR',
  MAP_GRANULARITY_DATA_ERROR = 'MAP_GRANULARITY_DATA_ERROR',

  FETCH_SCENARIO_DATA_ERROR = 'FETCH_SCENARIO_DATA_ERROR',
  MAP_SCENARIO_DATA_ERROR = 'MAP_SCENARIO_DATA_ERROR',

  CREATE_NEW_SCENARIO_DATA_ERROR = 'CREATE_NEW_SCENARIO_DATA_ERROR',
  FETCH_SCENARIO_LIST_DATA_ERROR = 'FETCH_SCENARIO_LIST_DATA_ERROR',

  DELETE_SCENARIO_DATA_ERROR = 'DELETE_SCENARIO_DATA_ERROR',
}
