import { AbandonScenarioModalComponent } from 'src/app/scenario/modals/abandon-scenario-modal/abandon-scenario-modal.component';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AuthService } from '../../../../../auth/services/auth.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextService, DiscountContext } from '../../services/context/context.service';
import { DiscountMatrixViewFormService } from '../../services/matrix-view-form/matrix-view-form.service';
import { FtdModalSize } from 'src/app/common/components/ftd-modal/ftd-modal-size.enum';
import { MatDialog } from '@angular/material/dialog';
import { NewScenarioModalComponent } from '../../../../../scenario/modals/new-scenario-modal/new-scenario-modal.component';
import { ScenarioListModalComponent } from '../../../../../scenario/modals/scenario-list-modal/scenario-list-modal.component';
import { Subscription } from 'rxjs';
import { User } from '../../../../../auth/models/user.model';

@Component({
  selector: 'app-discount-matrix-view-footer',
  styleUrls: ['./matrix-view-footer.component.scss'],
  templateUrl: './matrix-view-footer.component.html',
})
export class DiscountMatrixViewFooterComponent implements OnInit, OnDestroy {
  currentUser!: User | null;
  discountId!: string;
  discountContext!: DiscountContext;
  readDiscountContext = DiscountContext.read;
  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private matrixViewFormService: DiscountMatrixViewFormService,
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private contextService: ContextService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
    this.route.params.subscribe((params: Params | { id: string }): void => {
      this.discountId = params.id;
    });
  }

  ngOnInit(): void {
    this.discountContext = this.contextService.getCurrentDiscountContext();
    const subscription = this.authService
      .getLoggedInUserAsObservable()
      .subscribe((user: User | null) => (this.currentUser = user));
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  openCreateScenarioModal(): void {
    const dialogRef = this.dialog.open(NewScenarioModalComponent, {
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription = dialogRef.afterClosed().subscribe(() => {});
    this.subscriptions.push(subscription);
  }

  openScenarioListModal(): void {
    const dialogRef = this.dialog.open(ScenarioListModalComponent, {
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription = dialogRef.afterClosed().subscribe(() => {});
    this.subscriptions.push(subscription);
  }

  openAbandonScenarioModalComponent(): void {
    const dialogRef = this.dialog.open(AbandonScenarioModalComponent, {
      data: {
        scenarioName: '',
      },
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription = dialogRef.afterClosed().subscribe(async (isNavigateToMainScenario: boolean) => {
      if (isNavigateToMainScenario) {
        await this.router.navigate(['/main-scenario']);
      }
    });
    this.subscriptions.push(subscription);
  }

  goToDiscountSummaryPage(): void {
    this.router.navigate(['/discounts/summary']);
  }
}
