<app-ftd-expanded-tooltip
  [openMenuOnMouseClick]="true"
  [openMenuOnMouseOver]="false"
  (triggerInitialized)="initializedPopoverTrigger($event)"
>
  <app-ftd-dropdown
    [preventOptionsPanelToOpen]="true"
    tooltipTrigger
    [defaultSelected]="defaultValue"
    [isSearchVisible]="false"
    [label]="'Price range'"
    [form]="formGroup"
    [controlName]="'range'"
    [options]="singleOptionList"
    [fillWidth]="false"
  ></app-ftd-dropdown>

  <div class="price-range-select-slider-container" tooltipContent (click)="$event.stopPropagation()">
    <div [formGroup]="formGroup" fxLayout="column" fxLayoutAlign="space-between center">
      <mat-slider min="{{ minRangeValue }}" max="{{ maxRangeValue }}" color="primary" [step]="50">
        <input
          [value]="minPriceValue"
          matSliderStartThumb
          [formControlName]="'min'"
          (dragEnd)="handleMinPriceDragEnded($event)"
        />
        <input
          [value]="maxPriceValue"
          matSliderEndThumb
          [formControlName]="'max'"
          (dragEnd)="handleMaxPriceDragEnded($event)"
        />
      </mat-slider>

      <div class="price-range-inputs-container" fxLayout="row" fxLayoutAlign="space-between center">
        <app-ftd-input
          [id]="'price-range-min-value'"
          [form]="formGroup"
          [controlName]="'min'"
          [hideMaxlengthHint]="true"
          [maxlength]="7"
          [enableCurrencyMask]="true"
          [prefixValue]="currency"
          [currencyMaskOptions]="currencyMaskOption"
          (focusout)="validateMinPriceValuesOnChanges()"
        ></app-ftd-input>

        <div class="price-range-separator"></div>

        <app-ftd-input
          [id]="'price-range-max-value'"
          [form]="formGroup"
          [controlName]="'max'"
          [prefixValue]="currency"
          [hideMaxlengthHint]="true"
          [maxlength]="7"
          [enableCurrencyMask]="true"
          [currencyMaskOptions]="currencyMaskOption"
          (focusout)="validateMaxPriceValuesOnChanges()"
        ></app-ftd-input>
      </div>

      <div class="price-range-footer" fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="10">
        <button
          aria-label="Cancel selected price range"
          id="cancel-selected-price-range-btn"
          mat-button
          color="primary"
          (click)="closePopover()"
        >
          CANCEL
        </button>
        <app-ftd-button
          [id]="'apply-selected-price-range-btn'"
          [label]="'APPLY'"
          ariaText="Apply selected price range"
          [fillWidth]="false"
          (btnClick)="applySelectedPriceRange()"
          [disabled]="isApplyButtonDisabled"
        ></app-ftd-button>
      </div>
    </div>
  </div>
</app-ftd-expanded-tooltip>
