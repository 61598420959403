<ng-container *ngIf="isMatrixView; else tableView" [formGroup]="form">
  <ng-container formArrayName="rows">
    <div class="ftd-table-date-picker" [formGroupName]="rowIndex">
      <input
        matInput
        [placeholder]="placeHolder"
        class="input-{{ inputStatus }}"
        [min]="minDate"
        [formControlName]="controlName"
        [name]="name"
        [id]="id"
        [matDatepicker]="picker"
        #textField
      />
      <mat-datepicker-toggle
        (click)="emitDatepickerToggleClicked()"
        class="button-{{ inputStatus }}"
        matSuffix
        [for]="picker"
      >
        <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker [calendarHeaderComponent]="ftdHeader"></mat-datepicker>
    </div>
  </ng-container>
</ng-container>
<ng-template #tableView>
  <div class="ftd-table-date-picker" [formGroup]="form">
    <input
      matInput
      [placeholder]="placeHolder"
      class="input-{{ inputStatus }}"
      [min]="minDate"
      [formControlName]="controlName"
      [name]="name"
      [id]="id"
      [matDatepicker]="picker"
      #textField
    />
    <mat-datepicker-toggle
      (click)="emitDatepickerToggleClicked()"
      class="button-{{ inputStatus }}"
      matSuffix
      [for]="picker"
    >
      <mat-icon matDatepickerToggleIcon>calendar_month</mat-icon>
    </mat-datepicker-toggle>

    <mat-datepicker #picker [calendarHeaderComponent]="ftdHeader"></mat-datepicker>
  </div>
</ng-template>
