import { AfterViewChecked, Component, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../../../auth/services/auth.service';
import { ContextService, DiscountContext } from '../../services/context/context.service';
import { DiscountMatrixViewDataSource, IMatrixColumnConfig, IMatrixViewConfig } from '../../models/matrix-view.model';

import { DiscountMatrixViewDataService } from '../../services/matrix-view-data/matrix-view-data.service';
import { IDiscount } from '../../../../../graphql/services/gql-api.service';
import { MatTable, MatTableDataSource } from '@angular/material/table';
import { MatrixColumnType } from '../../enums/matrix-column-type.enum';
import { Subscription } from 'rxjs';
import { User } from '../../../../../auth/models/user.model';
import flatten from 'lodash.flatten';

/**
 * This component is only responsible for matrix-data visualization passed via {@link MatrixViewComponent.dataSource}
 * and header columns manipulation like collapse & expand
 */
@Component({
  selector: 'app-discount-matrix-view',
  styleUrls: ['./matrix-view.component.scss'],
  templateUrl: './matrix-view.component.html',
})
export class DiscountMatrixViewComponent implements OnInit, AfterViewChecked, OnChanges, OnDestroy {
  @ViewChild(MatTable) table!: MatTable<any>;

  @Input() set initSyncMatrixViewConfigWithScenarioMetadata(initSyncMatrixViewConfigWithScenarioMetadata: boolean) {
    if (initSyncMatrixViewConfigWithScenarioMetadata) {
      this.collapseAllColumnsAndSyncMatrixViewConfigWithScenarioMetadata();
    }
  }

  @Input() dataSource!: DiscountMatrixViewDataSource;

  @Input() matrixViewForm!: DiscountMatrixViewDataSource;

  @Input({ required: true }) discount!: IDiscount;

  matrixViewConfig: IMatrixViewConfig = { columns: [] };
  columnsExpanded: boolean = true;
  public currentUser?: User | null;
  public context: DiscountContext = DiscountContext.read;
  private subscriptions: Subscription[] = [];
  loadTable: boolean = false;
  showTableHeader: boolean = false;
  hoveredColumn?: IMatrixColumnConfig;

  constructor(
    private authService: AuthService,
    private matrixViewDataService: DiscountMatrixViewDataService,
    private contextService: ContextService
  ) {
    this.context = this.contextService.getCurrentDiscountContext();
    this.currentUser = this.authService.getLoggedInUser();
  }

  ngOnChanges(): void {
    this.showTableHeader = this.showElementByPermission();
  }

  ngOnInit(): void {
    this.dataSource = this.dataSource || new MatTableDataSource();
    const authServiceSubscription: Subscription = this.authService
      .getLoggedInUserAsObservable()
      .subscribe((user: User | null) => (this.currentUser = user));
    this.subscriptions.push(authServiceSubscription);

    const matrixColumnsConfigSubscription: Subscription = this.matrixViewDataService.matrixViewColumnsConfig.subscribe({
      next: (newColumnsConfig: IMatrixColumnConfig[]): void => {
        this.matrixViewConfig.columns = newColumnsConfig;
        this.loadTable = true;
        this.collapseAllColumnsAndSyncMatrixViewConfigWithScenarioMetadata();
      },
    });
    this.subscriptions.push(matrixColumnsConfigSubscription);
  }

  ngAfterViewChecked(): void {
    if (this.table) {
      this.table.updateStickyColumnStyles();
      this.table.updateStickyHeaderRowStyles();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  private collapseAllColumnsAndSyncMatrixViewConfigWithScenarioMetadata(): void {
    const allMatrixColumns: IMatrixColumnConfig[] = [...this.headerColumnsLevelOne, ...this.headerColumnsLevelTwo];
    this.collapseAllColumns(allMatrixColumns);
  }

  collapseAllColumns(allColumns: IMatrixColumnConfig[]): void {
    allColumns.forEach((matrixColumn: IMatrixColumnConfig): void => {
      matrixColumn.isExpandButtonForColumnsCollapsed = false;
      this.toggleAllColumnsBelongingToSameSection(matrixColumn);
      this.table?.updateStickyColumnStyles();
      this.table?.updateStickyHeaderRowStyles();
    });
  }

  getColSpanForColumnLevelOneByColumnId(columnId: string): number {
    const mainColumn = this.headerColumnsLevelOne.find((col) => col.id === columnId);
    if (mainColumn?.children) {
      return this.getChildrenColumnsInColumns(mainColumn.children).length;
    }
    return 1;
  }

  private getChildrenColumnsInColumns(
    columns: IMatrixColumnConfig[],
    filterNotHiddenColumns: boolean = true
  ): IMatrixColumnConfig[] {
    return (
      (columns &&
        flatten([...columns.map((col) => col.children as IMatrixColumnConfig[])]).filter(
          (subColumn: IMatrixColumnConfig) => (filterNotHiddenColumns ? !subColumn?.isHidden : true)
        )) ??
      []
    );
  }

  private getIdsOfColumns(columns: IMatrixColumnConfig[]): string[] {
    return columns?.map((col) => col.id || '');
  }

  get headerColumnsLevelOne(): IMatrixColumnConfig[] {
    return this.matrixViewConfig.columns;
  }

  get headerColumnsLevelTwo(): IMatrixColumnConfig[] {
    return this.getChildrenColumnsInColumns(this.headerColumnsLevelOne, true);
  }

  headerColumnsLevelThree(filterNotHiddenColumns: boolean = true): IMatrixColumnConfig[] {
    return this.getChildrenColumnsInColumns(this.headerColumnsLevelTwo, filterNotHiddenColumns);
  }

  get headerColumnsLevelOneIds(): string[] {
    return this.getIdsOfColumns(this.matrixViewConfig.columns);
  }

  get headerColumnsLevelTwoIds(): string[] {
    return this.getIdsOfColumns(this.headerColumnsLevelTwo);
  }

  get headerColumnsLevelThreeIds(): string[] {
    return this.getIdsOfColumns(this.headerColumnsLevelThree());
  }

  trackById = (_index: number, item: IMatrixColumnConfig): string => {
    return item?.id || '';
  };

  toggleAllColumnsBelongingToSameSection(selectedColumn: IMatrixColumnConfig): void {
    if (selectedColumn !== undefined && selectedColumn.children && selectedColumn.children.length) {
      // Mark column with expand/collapse button as expanded/collapsed
      selectedColumn.isExpandButtonForColumnsCollapsed = !selectedColumn.isExpandButtonForColumnsCollapsed;

      // Get all the orignal columns on level 2 without filtering hidden columns
      const levelTwoColumns = this.getChildrenColumnsInColumns(
        this.matrixViewConfig.columns,
        selectedColumn.isExpandButtonForColumnsCollapsed
      );

      // Collapse level 2 columns
      levelTwoColumns
        .filter(
          (parentColumn) =>
            parentColumn.parentId === selectedColumn.id &&
            !parentColumn.isNotCollapsible &&
            parentColumn.columnType !== MatrixColumnType.NAME &&
            parentColumn.columnType !== MatrixColumnType.ACTIONS
        )
        .forEach((parentColumn) => {
          parentColumn.isHidden = !parentColumn.isHidden;
        });

      /*
       * This.getChildrenColumnsInColumns(levelTwoColumns, selectedColumn.isExpandButtonForColumnsCollapsed)
       * .filter(
       *   (childColumn) =>
       *     ChildColumn.parentId === selectedColumn.id &&
       *     !childColumn.isNotCollapsible &&
       *     ChildColumn.columnType !== MatrixColumnType.NAME &&
       *     ChildColumn.columnType !== MatrixColumnType.ACTIONS
       * )
       * .forEach((childColumn) => {
       *   ChildColumn.isHidden = !childColumn.isHidden;
       * });
       */
    }
    this.table?.updateStickyColumnStyles();
    this.table?.updateStickyHeaderRowStyles();

    this.columnsExpanded =
      (selectedColumn.children && selectedColumn.children!.filter((col) => !col!.isHidden).length > 1) || false;
  }

  countTotalNotHiddenSubColumnsByParentColumn(selectedColumn: IMatrixColumnConfig): number {
    return selectedColumn.children?.filter((childColumn) => !childColumn.isHidden)?.length || 1;
  }

  showElementByPermission(): boolean {
    // TODO move this to a helper to not duplicate the code
    if (
      (this.context === DiscountContext.read &&
        this.currentUser?.permissions.getHasPermissionToApproveMainScenario()) ||
      this.currentUser?.permissions.getHasPermissionToDownloadScenario()
    ) {
      return true;
    } else if (
      this.context === DiscountContext.create &&
      (this.currentUser?.permissions.getHasPermissionToSubmitToMainScenario() ||
        this.currentUser?.permissions.getHasPermissionToSaveUserScenario())
    ) {
      return true;
    }
    return false;
  }

  /**
   * Only used in Unit Tests to reset (using deep clone/copy) the reference value of the {@link MatrixViewComponent.matrixViewConfig}
   * with {@link MATRIX_VIEW_CONFIG} . If not using it, its reference value will be shared across all tests & they will fail.
   */
  resetMatrixViewConfigState(): void {
    this.matrixViewConfig = JSON.parse(JSON.stringify({ ...this.matrixViewConfig }));
  }

  /**
   * ColumnHeaderHover
   * @param column
   */
  columnHeaderHover(column: IMatrixColumnConfig | undefined): void {
    this.hoveredColumn = column;
  }

  /**
   * ColumnHover
   * @param column
   */
  columnHover(column?: IMatrixColumnConfig | undefined): void {
    if (this.hoveredColumn !== column) {
      this.hoveredColumn = undefined;
    }
  }

  /**
   * DisableColumnHover
   */
  disableColumnHover(): void {
    this.hoveredColumn = undefined;
  }
}
