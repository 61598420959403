import { AuthService } from '../../../auth/services/auth.service';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CreateDiscountModalComponent } from '../create-discount-modal/create-discount-modal.component';
import { DiscountApprovalStatus } from '../../enums/discount-approval-status.enum';
import { DiscountDraftsModalComponent } from '../discount-drafts-modal/discount-drafts-modal.component';

import { DiscountSubmitModalComponent } from '../discount-submit-modal/discount-submit-modal.component';
import { DiscountsContext } from '../../enums/discounts-context.enum';
import { FtdModalSize } from '../../../common/components/ftd-modal/ftd-modal-size.enum';
import { IDiscount } from '../../../graphql/services/gql-api.service';
import { IDiscountStatusMap, discountStatusMap } from '../discounts-summary-table/config/discount-status-map.config';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';

@Component({
  selector: 'app-discounts-header',
  styleUrls: ['./discounts-header.component.scss'],
  templateUrl: './discounts-header.component.html',
})
export class DiscountsHeaderComponent implements OnInit, OnDestroy {
  @Input() public context!: DiscountsContext;
  @Input() public title: string = '';

  @Input() status?: DiscountApprovalStatus;

  @Input() public isApproveHidden: boolean = true;
  @Input() public isCreateDiscountHidden: boolean = true;
  @Input() public isStatusHidden: boolean = true;
  @Input() public isDraftsHidden: boolean = true;
  @Input() public isRejectHidden: boolean = true;
  @Input() public isReleaseHidden: boolean = true;
  @Input() public isSaveHidden: boolean = true;
  @Input() public isSaveDisabledTooltip: string = '';
  @Output() saveBtnClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() discountSubmitted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public isSubmitHidden: boolean = true;
  @Input() public isWithdrawHidden: boolean = true;
  @Input() discount!: IDiscount;

  currentUser!: User | null;
  private subscriptions: Subscription[] = [];
  private discountStatusMap: Map<DiscountApprovalStatus, IDiscountStatusMap> = discountStatusMap;

  @Input() isSaveDisabled: boolean = true;
  @Input() isSaveLoading: boolean = false;

  /**
   * @constructor
   * @param authService
   * @param dialog
   */
  constructor(private authService: AuthService, public dialog: MatDialog) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.listenToCurrentUserChanges();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * ListenToCurrentUserChanges
   * @private
   */
  private listenToCurrentUserChanges(): void {
    const subscription: Subscription = this.authService.getLoggedInUserAsObservable().subscribe({
      next: (user: User | null) => (this.currentUser = user),
    });
    this.subscriptions.push(subscription);
  }

  /**
   * OpenCreateDiscountModal
   */
  openCreateDiscountModal(): void {
    const dialogRef: MatDialogRef<CreateDiscountModalComponent> = this.dialog.open(CreateDiscountModalComponent, {
      maxWidth: FtdModalSize.S,
    });

    const subscription: Subscription = dialogRef.afterClosed().subscribe((): void => {});
    this.subscriptions.push(subscription);
  }

  /**
   * OpenDiscountDraftsModal
   */
  openDiscountDraftsModal(): void {
    const dialogRef: MatDialogRef<DiscountDraftsModalComponent> = this.dialog.open(DiscountDraftsModalComponent, {
      maxWidth: FtdModalSize.M,
    });

    const subscription: Subscription = dialogRef.afterClosed().subscribe((): void => {});
    this.subscriptions.push(subscription);
  }

  /**
   * OpenSubmitScenarioModal
   */
  openSubmitScenarioModal(): void {
    const dialogRef: MatDialogRef<DiscountSubmitModalComponent> = this.dialog.open(DiscountSubmitModalComponent, {
      data: {
        discount: this.discount,
      } as { discount: IDiscount },
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription: Subscription = dialogRef.afterClosed().subscribe((isSuccess: boolean): void => {
      if (isSuccess) {
        this.discountSubmitted.emit(true);
      }
    });
    this.subscriptions.push(subscription);
  }

  /**
   * SaveScenario
   */
  save(): void {
    this.saveBtnClicked.emit(true);
  }

  /**
   * ChipStyle
   */
  get chipStyle() {
    return this.discountStatusMap.get(this.status!);
  }
}
