<app-ftd-custom-modal>
  <app-ftd-custom-modal-header [title]="title"></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body [isRequestProcessing]="isRequestProcessing" [requestProcessingMessage]="''">
    <app-ftd-matrix-view-columns-ordering-component
      [matrixColumnConfig]="matrixColumnConfig"
      *ngIf="!isRequestProcessing"
    >
    </app-ftd-matrix-view-columns-ordering-component>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer>
    <ng-container start>
      <div (click)="resetMatrixColumnConfig()">
        <p class="reset-to-default-button">Reset to default</p>
      </div>
    </ng-container>

    <app-ftd-button
      [id]="'submit-btn'"
      (btnClick)="submit()"
      [disabled]="isSubmitButtonDisabled"
      [label]="'Apply'"
      ariaText="Submit"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="true"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
