import { Component, Input, ViewChild } from '@angular/core';
import { MatTab } from '@angular/material/tabs';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ftd-tab',
  styleUrls: ['./ftd-tab.component.scss'],
  templateUrl: './ftd-tab.component.html',
})
export class FtdTabComponent {
  @Input() tabLabel?: string;
  @Input() disabled: boolean = false;
  @ViewChild(MatTab) matTab!: MatTab;
}
