import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from 'src/app/matrix-view/services/context/context.service';
import { FtdWebVitalsService } from '../../common/services/ftd-web-vitals/ftd-web-vitals.service';
import { MatDialog } from '@angular/material/dialog';
import { ScenarioContext } from 'src/app/common/enums/scenario-context.enum';
import { ScenarioService } from '../../matrix-view/services/scenario/scenario.service';
import { Subscription } from 'rxjs';
import { USER_SCENARIO_CONFIG } from '../configs/scenario.config';

@Component({
  selector: 'app-user-scenario',
  templateUrl: './user-scenario.component.html',
})
export class UserScenarioComponent implements OnInit, OnDestroy {
  get context(): ScenarioContext {
    return this._context;
  }

  set context(value: ScenarioContext) {
    this._context = value;
  }

  get isMatrixViewSideFiltersHidden(): boolean {
    return this._isMatrixViewSideFiltersHidden;
  }

  set isMatrixViewSideFiltersHidden(value: boolean) {
    this._isMatrixViewSideFiltersHidden = value;
  }

  get isMatrixViewDataHidden(): boolean {
    return this._isMatrixViewDataHidden;
  }

  set isMatrixViewDataHidden(value: boolean) {
    this._isMatrixViewDataHidden = value;
  }

  get isReturnButtonHidden(): boolean {
    return this._isReturnButtonHidden;
  }

  set isReturnButtonHidden(value: boolean) {
    this._isReturnButtonHidden = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get isTitleHidden(): boolean {
    return this._isTitleHidden;
  }

  set isTitleHidden(isTitleHidden: boolean) {
    this._isTitleHidden = isTitleHidden;
  }

  get isDescriptionHidden(): boolean {
    return this._isDescriptionHidden;
  }

  set isDescriptionHidden(isDescriptionHidden: boolean) {
    this._isDescriptionHidden = isDescriptionHidden;
  }

  get isUnlockScenarioButtonHidden(): boolean {
    return this._isUnlockScenarioButtonHidden;
  }

  set isUnlockScenarioButtonHidden(value: boolean) {
    this._isUnlockScenarioButtonHidden = value;
  }

  get isApproveScenarioButtonHidden(): boolean {
    return this._isApproveScenarioButtonHidden;
  }

  set isApproveScenarioButtonHidden(value: boolean) {
    this._isApproveScenarioButtonHidden = value;
  }

  get isExportButtonHidden(): boolean {
    return this._isExportButtonHidden;
  }

  set isExportButtonHidden(value: boolean) {
    this._isExportButtonHidden = value;
  }

  get isSubmitScenarioButtonHidden(): boolean {
    return this._isSubmitScenarioButtonHidden;
  }

  set isSubmitScenarioButtonHidden(value: boolean) {
    this._isSubmitScenarioButtonHidden = value;
  }

  get isSaveButtonHidden(): boolean {
    return this._isSaveButtonHidden;
  }

  set isSaveButtonHidden(value: boolean) {
    this._isSaveButtonHidden = value;
  }

  private _title: string = USER_SCENARIO_CONFIG.title;
  private _isReturnButtonHidden: boolean = USER_SCENARIO_CONFIG.isReturnButtonHidden;
  private _isMatrixViewDataHidden: boolean = USER_SCENARIO_CONFIG.isMatrixViewDataHidden;
  private _isMatrixViewSideFiltersHidden: boolean = USER_SCENARIO_CONFIG.isMatrixViewSideFiltersHidden;
  private _isDescriptionHidden: boolean = USER_SCENARIO_CONFIG.isDescriptionHidden;
  private _isUnlockScenarioButtonHidden: boolean = USER_SCENARIO_CONFIG.isUnlockScenarioButtonHidden;
  private _isApproveScenarioButtonHidden: boolean = USER_SCENARIO_CONFIG.isApproveScenarioButtonHidden;
  private _isExportButtonHidden: boolean = USER_SCENARIO_CONFIG.isExportButtonHidden;
  private _isSubmitScenarioButtonHidden: boolean = USER_SCENARIO_CONFIG.isSubmitScenarioButtonHidden;
  private _isSaveButtonHidden: boolean = USER_SCENARIO_CONFIG.isSaveButtonHidden;
  private _isTitleHidden: boolean = USER_SCENARIO_CONFIG.isTitleHidden;
  private _description: string = USER_SCENARIO_CONFIG.description;
  private _context: ScenarioContext = USER_SCENARIO_CONFIG.context;
  private subscriptions: Subscription[] = [];

  constructor(
    private contextService: ContextService,
    private scenarioService: ScenarioService,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private ftdWebVitalsService: FtdWebVitalsService
  ) {}

  ngOnInit(): void {
    this.ftdWebVitalsService.logWebVitals();
    const subscription = this.scenarioService.scenarioNameBehaviorSubject$.subscribe(
      (_scenarioName: string) => (this.description = _scenarioName)
    );
    this.subscriptions.push(subscription);
    this.contextService.setCurrentScenarioContext(ScenarioContext.userScenario);
    this.contextService.scenarioId = this.activatedRoute.snapshot.params['id'];
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subs) => {
      subs?.unsubscribe();
    });
  }
}
