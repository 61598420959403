<mat-toolbar class="crosschecks-header">
  <div class="title-container-return-button-container">
    <div class="return-button-container">
      <app-ftd-breadcrumbs></app-ftd-breadcrumbs>
    </div>
    <div class="title-container">
      <h2 class="crosscheck-text">{{ title }}</h2>
      <div class="chip-location" *ngIf="isCrosschecksPage">
        <app-ftd-chip
          chipText="{{ currentLocation }}"
          chipColor="#0071C5"
          chipTextColor="#ffffff"
          [enableRemoveButton]="false"
        ></app-ftd-chip>
      </div>
      <div class="right-side-menu" *ngIf="isCrosschecksPage">
        <div [matMenuTriggerFor]="dashboardLinks" class="dashboard-links">
          Dashboard links
          <mat-icon>expand_more</mat-icon>
        </div>

        <mat-menu #dashboardLinks="matMenu" xPosition="before">
          <a
            *ngFor="let appLink of APP_FSM_RELATED_EXTERNAL_LINKS"
            class="item-text"
            mat-menu-item
            [href]="appLink.link"
            target="_blank"
          >
            <mat-icon>open_in_new</mat-icon>
            <p>{{ appLink.name }}</p>
          </a>
        </mat-menu>

        <app-ftd-toggle-filters
          *ngIf="isToggleFiltersVisible"
          class="filter-toggle"
          (toggleFiltersClickEvent)="showFiltersButtonVisible.emit()"
        ></app-ftd-toggle-filters>
      </div>
    </div>
  </div>
</mat-toolbar>
