import { Component, Input } from '@angular/core';
import { ICrosschecksSettings } from '../crosschecks-settings.component';

@Component({
  selector: 'app-crosschecks-settings-card',
  styleUrls: ['./crosschecks-settings-card.component.scss'],
  templateUrl: './crosschecks-settings-card.component.html',
})
export class CrosschecksSettingsCardComponent {
  @Input() crosscheck!: ICrosschecksSettings;
}
