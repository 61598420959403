import { Either } from './either';
import { Success } from './success';

export class Error<E, S> {
  readonly value: E;

  constructor(value: E) {
    this.value = value;
  }

  isError(): this is Error<E, S> {
    return true;
  }

  isSuccess(): this is Success<E, S> {
    return false;
  }

  applyOnSuccess<B>(_: (a: S) => B): Either<E, B> {
    return this as any;
  }
}

export const error = <E, S>(a: E): Either<E, S> => {
  return new Error<E, S>(a);
};
