import { AbstractService } from 'src/app/core/services/abstract.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  IDownloadScenarioDataByFilterIdsGQL,
  IDownloadScenarioDataByFilterIdsQuery,
  IScenarioDataByFilterIdsGQL,
  IScenarioDataByFilterIdsQuery,
  IScenarioDataWithPricePoint,
  IScenariosGQL,
  IScenariosQuery,
} from 'src/app/graphql/services/gql-api.service';
import { Injectable, Injector } from '@angular/core';

import { ApolloQueryResult } from '@apollo/client/core';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})

/**
 * @class ScenarioService
 * @extends AbstractService
 */
export class ScenarioService extends AbstractService {
  /**
   * @constructor
   * @param injector
   * @param scenarioDataByFilterIdsGQL
   * @param downloadScenarioDataByFilterIdsGQL
   * @param scenariosGQL
   */
  constructor(
    protected injector: Injector,
    private scenarioDataByFilterIdsGQL: IScenarioDataByFilterIdsGQL,
    private downloadScenarioDataByFilterIdsGQL: IDownloadScenarioDataByFilterIdsGQL,
    private scenariosGQL: IScenariosGQL
  ) {
    super(injector);
  }

  public scenarioNameBehaviorSubject$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  /**
   * GetMatrixViewKpiData
   * @param scenarioId
   * @param filterIds
   * @param market
   */
  getMatrixViewKpiData(
    scenarioId: string,
    filterIds: string[],
    market: string
  ): Observable<IScenarioDataWithPricePoint[]> {
    return this.scenarioDataByFilterIdsGQL
      .fetch({
        filterIds,
        market,
        scenarioId,
      })
      .pipe(
        map(
          (result: ApolloQueryResult<IScenarioDataByFilterIdsQuery>) =>
            result.data.scenarioDataByFilterIds as IScenarioDataWithPricePoint[]
        ),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * DownloadMatrixViewKpiData
   * @param scenarioId
   * @param filterIds
   */
  downloadMatrixViewKpiData(
    scenarioId: string,
    filterIds: string[],
    market: string
  ): Observable<IScenarioDataWithPricePoint[]> {
    return this.downloadScenarioDataByFilterIdsGQL
      .fetch({
        filterIds,
        market,
        scenarioId,
      })
      .pipe(
        map(
          (result: ApolloQueryResult<IDownloadScenarioDataByFilterIdsQuery>) =>
            result.data.downloadScenarioDataByFilterIds as IScenarioDataWithPricePoint[]
        ),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * GetScenarioData
   * @param userId
   * @param scenarioId
   */
  getScenarioData(userId: string, scenarioId: string): Observable<IScenariosQuery['scenarios']> {
    return this.scenariosGQL
      .fetch({
        scenarioId,
      })
      .pipe(
        map((result) => {
          this.scenarioNameBehaviorSubject$.next(
            result.data.scenarios.length ? result.data.scenarios[0].scenarioName : ''
          );

          return result.data.scenarios;
        }),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }
}
