<span *ngIf="crossCheckResultsItem && technicalName">
  <app-crosschecks-traffic-lights
    [granularity]="granularity"
    [status]="crossCheckResultsItem"
    [technicalName]="technicalName"
    [scenario]="scenario"
    [scenarioId]="scenarioId"
  ></app-crosschecks-traffic-lights>
</span>
<span *ngIf="!(crossCheckResultsItem && technicalName)">-</span>
