import { Component, Input } from '@angular/core';
import { MatrixColumnTechnicalName } from '../../../enums/matrix-column-technical-name.enum';

@Component({
  selector: 'app-discount-mv-unit-map',
  styleUrls: ['./mv-unit-map.component.scss'],
  templateUrl: './mv-unit-map.component.html',
})
export class MvUnitMapComponent {
  @Input() COLUMN_UNIT?: any;
  @Input() textHolder!: any;
  @Input() technicalName?: MatrixColumnTechnicalName;
}
