<div class="ftd-button-option-selector-container">
  <div
    class="percentage-container"
    [class.selected]="selectedOption === 'percentage'"
    (click)="emitCheckedChange('percentage')"
    [attr.data-test]="'btn-option-selector-percentage'"
  >
    {{ percentage }}
  </div>
  <div
    class="currency-container"
    [class.selected]="selectedOption === 'currency'"
    (click)="emitCheckedChange('currency')"
    [attr.data-test]="'btn-option-selector-currency'"
  >
    {{ currency }}
  </div>
</div>
