import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { FtdNotifierService } from '../../../common/services/ftd-notifier/ftd-notifier.service';
import { GovernanceService } from '../../../matrix-view/services/governance/governance.service';
import { ICreateUpdatePricePointMutationVariables } from '../../../graphql/services/gql-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatrixViewFormService } from '../../../matrix-view/services/matrix-view-form/matrix-view-form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-abandon-scenario-modal',
  templateUrl: './abandon-scenario-modal.component.html',
})
export class AbandonScenarioModalComponent implements OnDestroy {
  private subscriptions: Subscription[] = [];

  get scenarioName(): string {
    return this._scenarioName;
  }

  set scenarioName(value: string) {
    this._scenarioName = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  private _title: string = 'This User Scenario has unsaved changes';
  private _scenarioName: string = '';

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { scenarioName: string },
    private notifierService: FtdNotifierService,
    private matrixViewFormService: MatrixViewFormService,
    private governanceService: GovernanceService,
    private dialogRef: MatDialogRef<AbandonScenarioModalComponent>
  ) {
    this.scenarioName = this.data?.scenarioName;
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  continueNavigation(isSave: boolean = false): void {
    if (isSave) {
      this.saveScenario();
    }

    this.matrixViewFormService.resetForm();
    this.dialogRef.close(true);
  }

  saveScenario(): void {
    const priceSubmissionList: ICreateUpdatePricePointMutationVariables =
      this.matrixViewFormService.getPriceSubmissionList(true);
    const subscription: Subscription = this.governanceService.createUpdatePricePoint(priceSubmissionList).subscribe();
    this.subscriptions.push(subscription);
  }
}
