<app-ftd-chip
  *ngIf="chipData"
  [chipIcon]="'person'"
  [chipText]="chipData.text"
  [enableRemoveButton]="false"
  [chipSize]="CHIP_SIZE.EXTRA_SMALL"
  (click)="openPriceApprovalStatusChipModal()"
  [showCursorPointer]="true"
  [chipColor]="chipData.backgroundColor"
  [chipTextColor]="chipData.textColor"
></app-ftd-chip>
