<app-crosschecks-chart-wrapper
  [context]="crosschecksChartContext"
  [skeletonTheme]="'InternalPriceLadder'"
  [alternativeModelHeaderText]="'RELEVANT COMPARABLE VEHICLES'"
  [filters]="filters"
  [trafficLightStatus]="crosscheckIndication!"
  [trafficLightAdjStatus]="crosscheckIndicationForecasted!"
  [crosscheckIndicationVisible]="true"
>
  <div id="internal-price-ladder-header">
    <div *ngIf="filtersForm" id="internal-price-ladder-filters">
      <app-ftd-multi-dropdown
        [id]="'internal-price-ladder-filter-segment'"
        [label]="'Segment'"
        [form]="filtersForm"
        [controlName]="'segment'"
        [options]="segmentList"
        (optionsClosed)="updateSelectedSegmentsFilters()"
      ></app-ftd-multi-dropdown>
      <app-ftd-multi-dropdown-grouped
        [id]="'internal-price-ladder-filter-e_series'"
        [label]="'E-Series'"
        [form]="filtersForm"
        [controlName]="'eSeries'"
        [optionsGroupList]="eSeriesList"
        [showQuantityOf]="'eSeries'"
      ></app-ftd-multi-dropdown-grouped>
      <app-ftd-dropdown
        id="considered-price-type-select"
        [form]="filtersForm"
        [label]="'Considered Price'"
        [controlName]="'consideredPriceType'"
        [options]="consideredPriceTypeOptionList"
      ></app-ftd-dropdown>
      <app-ftd-price-range-select-slider
        *ngIf="minAndMaxPossiblePriceRange"
        (priceRangeApplied)="handlePriceRangeAppliedAndRefreshChartData($event)"
        [minRangeValue]="minAndMaxPossiblePriceRange.minPrice"
        [maxRangeValue]="minAndMaxPossiblePriceRange.maxPrice"
        [minValue]="minAndMaxFilter.minPrice"
        [maxValue]="minAndMaxFilter.maxPrice"
        [currency]="currency"
      ></app-ftd-price-range-select-slider>
      <app-ftd-dropdown
        [form]="filtersForm"
        [label]="'Vehicle specs'"
        [controlName]="'vehicleSpecsType'"
        [options]="vehicleSpecsTypeOptionList"
      ></app-ftd-dropdown>
    </div>
  </div>
  <div id="internal-price-ladder-sub-header">
    <div id="internal-price-ladder-chart-title">
      <span>{{ CHART_SUBTITLE_MAP.get(selectedVehicleType) }}</span>
    </div>
    <!-- Replace ngIf for the following: *ngIf="currentUser?.permissions?.getHighestHierarchyRole() === 'admin'" after Edit Rules btn is implemented -->
    <div id="internal-price-ladder-edit-rules-button" *ngIf="false">
      <app-ftd-button
        *ngIf="initChart"
        [label]="'EDIT RULES'"
        [icon]="'edit'"
        [size]="'S'"
        [disabled]="!isEditRulesButtonEnabled"
      >
      </app-ftd-button>
    </div>
  </div>
  <div id="internal-price-ladder-chart-container">
    <app-ftd-line-chart
      *ngIf="initChart"
      [data]="chartData"
      [isTooltipVisible]="true"
      [axisType]="ChartAxisType.CATEGORY"
      [isSysDateIndicatorVisible]="false"
      [rootId]="'ftd-line-chart-ref-order-intake'"
      [isLabelValuesVisible]="isChartValuesVisible"
      [chartCustomTheme]="'InternalPriceLadderCustomTheme'"
      [isScrollBarVisible]="true"
    >
    </app-ftd-line-chart>
  </div>
  <div class="internal-price-ladder-legend">
    <div *ngFor="let legend of chartLegendsConfigMap.get(selectedVehicleType)" class="filter-item">
      <img
        ngSrc="{{ legend.imagePath }}"
        [alt]="'Legend for ' + legend.label"
        [height]="legend.legendImageHeight"
        [width]="legend.legendImageWidth"
      />
      <div class="chart-filter-item">
        <div class="chart-filter-item-label">{{ legend.label }}</div>
      </div>
    </div>
  </div>
</app-crosschecks-chart-wrapper>
