import { APP_CONSTANTS } from 'src/app/common/constants/app.constant';
import { Base64Utils } from 'src/app/common/utils/base64.utils';
import { ChartAxisType } from 'src/app/common/components/ftd-charts/enums/ftd-generic-chart.enum';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ConsideredPriceType,
  DisplayedValuesType,
  SalesChannelType,
} from 'src/app/crosschecks/enums/crosschecks-chart-dropdowns-type.enum';
import { ContextService } from '../../../../matrix-view/services/context/context.service';
import { CrosschecksChartContext } from '../../../enums/crosschecks-chart-context-messages.enum';
import { CrosschecksContext } from 'src/app/crosschecks/enums/crosschecks-context.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  GenericChartFields,
  GenericChartVerticalSpacer,
} from 'src/app/common/components/ftd-charts/models/ftd-generic-chart.model';
import { IBarChartData } from 'src/app/common/components/ftd-charts/models/ftd-bar-chart.model';
import { IFtdDropdownOption } from '../../../../common/models/ftd-dropdown-option.model';
import {
  IGranularityDto,
  IMarketsCrosscheckChartData,
  IMarketsCrosscheckData,
  IMarketsCrosscheckDataResponse,
  MarketsCrosscheckDataDictionary,
} from 'src/app/matrix-view/models/api.model';
import { Subscription } from 'rxjs';
import { TrafficLightsColor } from '../../../types/traffic-lights.type';
import { marketsSeriesName } from 'src/app/common/components/ftd-charts/enums/crosschecks/markets/ftd-markets.enum';

/*
 * Dicitionaries to simplify to return of the key of the Markets object according to price context
 * Base price dicitionary
 */
const basePriceDictionary: MarketsCrosscheckDataDictionary = {
  [ConsideredPriceType.CURRENT_PRICE]: {
    [DisplayedValuesType.NET_PRICE]: 'baseNetPrice',
    [DisplayedValuesType.GROSS_PRICE]: 'baseGrossPrice',
  },
  [ConsideredPriceType.PROPOSED_PRICE]: {
    [DisplayedValuesType.NET_PRICE]: 'baseNetPriceAdj',
    [DisplayedValuesType.GROSS_PRICE]: 'baseGrossPriceAdj',
  },
};

// Base price dictionary
const basePriceVariationDictionary: MarketsCrosscheckDataDictionary = {
  [ConsideredPriceType.CURRENT_PRICE]: {
    [DisplayedValuesType.NET_PRICE]: 'marketNetPriceRatio',
    [DisplayedValuesType.GROSS_PRICE]: 'marketGrossPriceRatio',
  },
  [ConsideredPriceType.PROPOSED_PRICE]: {
    [DisplayedValuesType.NET_PRICE]: 'marketNetPriceRatioAdj',
    [DisplayedValuesType.GROSS_PRICE]: 'marketGrossPriceRatioAdj',
  },
};

// Typically equipped price dictionary
const typicallyEquippedPriceDictionary: MarketsCrosscheckDataDictionary = {
  [ConsideredPriceType.CURRENT_PRICE]: {
    [DisplayedValuesType.NET_PRICE]: 'typicallyEquippedNetPrice',
    [DisplayedValuesType.GROSS_PRICE]: 'typicallyEquippedGrossPrice',
  },
  [ConsideredPriceType.PROPOSED_PRICE]: {
    [DisplayedValuesType.NET_PRICE]: 'typicallyEquippedNetPriceAdj',
    [DisplayedValuesType.GROSS_PRICE]: 'typicallyEquippedGrossPriceAdj',
  },
};

const _consideredPrice: IFtdDropdownOption<string>[] = [
  {
    id: ConsideredPriceType.CURRENT_PRICE,
    label: 'Current price',
    value: ConsideredPriceType.CURRENT_PRICE,
  },
  {
    disabled: true,
    id: ConsideredPriceType.PROPOSED_PRICE,
    label: 'Scenario price',
    value: ConsideredPriceType.PROPOSED_PRICE,
  },
];

const _salesChannel: IFtdDropdownOption<string>[] = [
  {
    id: 0,
    label: 'Private',
    value: 'List price',
  },
];
const _basisOfComparison: IFtdDropdownOption<SalesChannelType>[] = [
  {
    id: SalesChannelType.LIST_PRICE,
    label: 'List price',
    value: SalesChannelType.LIST_PRICE,
  },
  {
    id: SalesChannelType.LEASE_RATE_PRICE,
    label: 'Lease/Loans Rates',
    value: SalesChannelType.LEASE_RATE_PRICE,
  },
];
const _displayedValues: IFtdDropdownOption<DisplayedValuesType>[] = [
  {
    id: DisplayedValuesType.NET_PRICE,
    label: 'Net values',
    value: DisplayedValuesType.NET_PRICE,
  },
  {
    disabled: false,
    id: DisplayedValuesType.GROSS_PRICE,
    label: 'Gross values',
    value: DisplayedValuesType.GROSS_PRICE,
  },
];

@Component({
  selector: 'app-markets',
  styleUrls: ['./markets.component.scss'],
  templateUrl: './markets.component.html',
})

/**
 * @class
 * MarketsComponent
 */
export class MarketsComponent implements OnInit, OnDestroy {
  private _granularity!: IGranularityDto;
  private subscriptions: Subscription[] = [];

  filters!: IGranularityDto;
  filtersForm!: FormGroup;
  crosschecksChartContext: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  chartLegend: string[] = [];
  chartData!: IBarChartData;
  chartAxisType: ChartAxisType = ChartAxisType.CATEGORY;
  consideredPrice: IFtdDropdownOption<string>[] = _consideredPrice;
  salesChannel: IFtdDropdownOption<string>[] = _salesChannel;
  basisOfComparison: IFtdDropdownOption<string>[] = _basisOfComparison;
  displayedValues: IFtdDropdownOption<string>[] = _displayedValues;
  marketData!: IMarketsCrosscheckDataResponse;
  context: CrosschecksContext = CrosschecksContext.CURRENT;

  /**
   * @constructor
   * @param crosscheckService
   * @param formBuilder
   * @param contextService
   */
  constructor(
    private crosscheckService: CrosschecksService,
    private formBuilder: FormBuilder,
    private contextService: ContextService
  ) {}

  /**
   * Traffic light value coming from overview request on Markets
   */
  @Input() crosscheckIndication?: TrafficLightsColor;
  @Input() crosscheckIndicationForecasted?: TrafficLightsColor;

  /**
   * Set Granularity
   * @param value
   */
  @Input() set granularity(value: string) {
    this._granularity = Base64Utils.decodeAtobToJson(value);
    this.filters = this._granularity;
    // Model code set to ALL so only model lvl is shown in x-check wrapper view
    this.filters.modelCode = 'ALL';
    this.loadMarketsData();
  }

  @Input() set crosscheckContext(value: CrosschecksContext) {
    this.context = value;
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * InitForm
   */
  private initForm(): void {
    this.filtersForm = this.formBuilder.group({
      basisOfComparison: new FormControl<SalesChannelType>({ disabled: false, value: SalesChannelType.LIST_PRICE }),
      consideredPrice: new FormControl<ConsideredPriceType>({
        disabled: false,
        value: ConsideredPriceType.CURRENT_PRICE,
      }),
      displayedValues: new FormControl<DisplayedValuesType>({ disabled: false, value: DisplayedValuesType.NET_PRICE }),
      salesChannel: new FormControl<SalesChannelType>({ disabled: true, value: SalesChannelType.LIST_PRICE }),
    });

    const basisOfComparisonSubscription = this.filtersForm.controls.basisOfComparison.valueChanges.subscribe(
      (value: SalesChannelType): void => {
        this.displayedValues.find((value) => value.id === DisplayedValuesType.GROSS_PRICE)!.disabled = false;
        if (value === SalesChannelType.LEASE_RATE_PRICE) {
          this.filtersForm.get('displayedValues')?.setValue(DisplayedValuesType.NET_PRICE);
          this.displayedValues.find((value) => value.id === DisplayedValuesType.GROSS_PRICE)!.disabled = true;
        }
        this.setMarketsChartData(this.marketData, value, this.getConsideredPriceType(), this.getDisplayedValueType());
      }
    );
    this.subscriptions.push(basisOfComparisonSubscription);

    const consideredPriceSubscription = this.filtersForm.controls.consideredPrice.valueChanges.subscribe(
      (value: ConsideredPriceType): void => {
        this.setMarketsChartData(this.marketData, this.getBasisOfComparisonType(), value, this.getDisplayedValueType());
      }
    );
    this.subscriptions.push(consideredPriceSubscription);

    const displayedValuesSubscription = this.filtersForm.controls.displayedValues.valueChanges.subscribe(
      (value: DisplayedValuesType) => {
        this.setMarketsChartData(
          this.marketData,
          this.getBasisOfComparisonType(),
          this.getConsideredPriceType(),
          value
        );
      }
    );
    this.subscriptions.push(displayedValuesSubscription);
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe();
  }

  /**
   * NgUnsubscribe
   */
  ngUnsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * GetBasisOfComparisonType
   */
  getBasisOfComparisonType(): SalesChannelType {
    return this.filtersForm.get('basisOfComparison')?.value;
  }

  /**
   * GetConsideredPriceType
   */
  getConsideredPriceType(): ConsideredPriceType {
    return this.filtersForm.get('consideredPrice')?.value;
  }

  /**
   * GetBasisOfComparisonTypeLabel
   */
  getBasisOfComparisonTypeLabel(): string {
    return this.getBasisOfComparisonType() === SalesChannelType.LEASE_RATE_PRICE
      ? 'LEASE RATE PRICE'
      : 'LIST PRICE CAR';
  }

  /**
   * GetDisplayedValueType
   */
  getDisplayedValueType(): DisplayedValuesType {
    return this.filtersForm.get('displayedValues')?.value;
  }

  /**
   * LoadMarketsData
   */
  loadMarketsData(): void {
    if (this._granularity.modelCode || this._granularity.model) {
      this.crosschecksChartContext = CrosschecksChartContext.LOADING;
      const subscription: Subscription = this.crosscheckService
        .getMarketData(this._granularity.id!, this._granularity.market, this.contextService.scenarioId)
        .subscribe({
          error: (): void => {
            this.crosschecksChartContext = CrosschecksChartContext.ERROR;
          },
          next: (response: IMarketsCrosscheckDataResponse): void => {
            this.onLoadMarketsNext(response);
          },
        });
      this.subscriptions.push(subscription);
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
    }
  }

  /**
   * OnLoadMarketsNext
   * @param response
   */
  onLoadMarketsNext(response: IMarketsCrosscheckDataResponse): void {
    if (
      (this.getBasisOfComparisonType() === SalesChannelType.LIST_PRICE && response.priceData.length > 0) ||
      (this.getBasisOfComparisonType() === SalesChannelType.LEASE_RATE_PRICE && response.leaseRateData.length > 0)
    ) {
      this.marketData = response;
      this.setMarketsChartData(
        this.marketData,
        this.getBasisOfComparisonType(),
        this.getConsideredPriceType(),
        this.getDisplayedValueType()
      );
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_RESULTS_FOUND;
    }
  }

  /**
   * UpdateMarketsPriceFilter
   * @private
   * @param marketData
   * @param displayedValueType
   */
  private updateMarketsPriceFilter(marketData: IMarketsCrosscheckData, displayedValueType: DisplayedValuesType): void {
    this.consideredPrice.find((option) => option.id === ConsideredPriceType.CURRENT_PRICE)!.label = `Current price | ${
      marketData[basePriceDictionary[ConsideredPriceType.CURRENT_PRICE]![displayedValueType]]
        ? marketData[basePriceDictionary[ConsideredPriceType.CURRENT_PRICE]![displayedValueType]]!.toLocaleString() +
          '€'
        : 'N/A'
    }`;
    const consideredPriceOption = this.consideredPrice.find(
      (option) => option.id === ConsideredPriceType.PROPOSED_PRICE
    );
    consideredPriceOption!.disabled = true;
    consideredPriceOption!.label = `Scenario price | N/A`;
    if (marketData.hasAdjustedPrice) {
      consideredPriceOption!.disabled = false;
      consideredPriceOption!.label = `Scenario price | ${marketData[
        basePriceDictionary[ConsideredPriceType.PROPOSED_PRICE]![displayedValueType]
      ]!.toLocaleString()}€`;
    }
  }

  /**
   * GetChartItemPriceValues
   * @private
   * @param item
   * @param consideredPriceContext
   * @param valueTypeContext
   */
  private getChartItemPriceValues(
    item: IMarketsCrosscheckData,
    consideredPriceContext: ConsideredPriceType,
    valueTypeContext: DisplayedValuesType
  ): Partial<IMarketsCrosscheckChartData> {
    // Get price values from dictionaries which return the object key for the specific value according to the context passed
    return {
      basePrice: item[basePriceDictionary[consideredPriceContext]![valueTypeContext]] as number,
      basePriceVariation: item[basePriceVariationDictionary[consideredPriceContext]![valueTypeContext]] as number,
      modelCode: item.hasAdjustedPrice ? item.modelCodeAdj : item.modelCode,
      typicallyEquippedPrice: item[typicallyEquippedPriceDictionary[consideredPriceContext]![valueTypeContext]]
        ? (item[typicallyEquippedPriceDictionary[consideredPriceContext]![valueTypeContext]] as number)
        : (item[basePriceDictionary[consideredPriceContext]![valueTypeContext]] as number),
    };
  }

  /**
   * SetMarketsChartData
   * @private
   * @param data
   * @param basisOfComparison
   * @param consideredPriceContext
   * @param displayValueType
   */
  private setMarketsChartData(
    data: IMarketsCrosscheckDataResponse,
    basisOfComparison: SalesChannelType,
    consideredPriceContext: ConsideredPriceType,
    displayValueType: DisplayedValuesType
  ): void {
    this.chartData = {
      fields: [],
      headings: [
        { description: marketsSeriesName.typicallyEquipped, key: marketsSeriesName.typicallyEquipped },
        { description: marketsSeriesName.baseCar, key: marketsSeriesName.baseCar },
      ],
    };

    const marketsData = basisOfComparison === SalesChannelType.LIST_PRICE ? data.priceData : data.leaseRateData;

    this.updateMarketsPriceFilter(
      marketsData.find((marketData) => marketData.market === this._granularity.market)!,
      this.getDisplayedValueType()
    );

    const defaultMarket = marketsData.find(
      (value: IMarketsCrosscheckData) => value.market === APP_CONSTANTS.defaultMarket
    );

    const defaultMarketChartData: GenericChartFields = defaultMarket
      ? {
          ...this.getChartItemPriceValues(defaultMarket, consideredPriceContext, displayValueType),
          section: marketsSeriesName.baseMarket,
          selected: this._granularity.market === APP_CONSTANTS.defaultMarket,
          xAxisImgSrc: `../../../../assets/images/flags/${defaultMarket.market}.svg`,
          xAxisValue: defaultMarket.market,
        }
      : {
          section: marketsSeriesName.baseMarket,
          selected: this._granularity.market === APP_CONSTANTS.defaultMarket,
          xAxisImgSrc: `../../../../assets/images/flags/${APP_CONSTANTS.defaultMarket}.svg`,
          xAxisValue: APP_CONSTANTS.defaultMarket,
        };

    const chartMarketsList: IMarketsCrosscheckChartData[] = marketsData
      .filter((marketData: IMarketsCrosscheckData) => {
        return (
          marketData.market !== APP_CONSTANTS.defaultMarket &&
          (displayValueType === DisplayedValuesType.NET_PRICE ? marketData.baseNetPrice : marketData.baseGrossPrice)
        );
      })
      .map((marketData: IMarketsCrosscheckData, index: number) => {
        return {
          ...this.getChartItemPriceValues(marketData, consideredPriceContext, displayValueType),
          section: index === 0 ? marketsSeriesName.relevanteMarkets : '',
          selected: this._granularity.market === marketData.market,
          xAxisImgSrc: `../../../../assets/images/flags/${marketData.market}.svg`,
          xAxisValue: marketData.market,
        };
      });

    this.crosschecksChartContext = CrosschecksChartContext.CHART;
    this.chartData.fields.push(defaultMarketChartData, new GenericChartVerticalSpacer(), ...chartMarketsList);
  }
}
