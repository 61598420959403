<button
  id="{{ id }}"
  mat-button
  [ngClass]="{ 'ftd-button-fill-width': fillWidth }"
  [disabled]="disabled"
  [attr.aria-label]="ariaText"
  class="ftd-button ftd-button-size-{{ size.toLowerCase() }} ftd-button-{{ hierarchy }} {{
    !position && icon ? 'ftd-button-only-icon' : ''
  }}"
  (click)="onClick($event)"
>
  <mat-icon class="ftd-button-icon-left" *ngIf="position === 'left'" [class.material-icons-outlined]="outlined"
    >{{ icon }}
  </mat-icon>
  <mat-icon *ngIf="!position && icon" [class.material-icons-outlined]="outlined">{{ icon }}</mat-icon>
  <span>{{ label }}</span>
  <mat-icon class="ftd-button-icon-right" *ngIf="position === 'right'" [class.material-icons-outlined]="outlined"
    >{{ icon }}
  </mat-icon>
  <app-loading *ngIf="isLoading" [diameter]="20" [color]="'white'" class="ml-1"></app-loading>
</button>
