import { CalendarizedOrderBankComponent } from './pages/sales-pipeline/calendarized-order-bank/calendarized-order-bank.component';
import { ChannelsPageComponent } from './pages/channels/channels-page/channels-page.component';
import { ChartFilterItemComponent } from './components/crosschecks-chart-filter-group/chart-filter-item/chart-filter-item.component';
import { ChartFiltersComponent } from './pages/sales-pipeline/chart-filters/chart-filters.component';
import { CommonModule } from '../common/common.module';
import { CompetitionPageComponent } from './pages/competition/competition-page/competition-page.component';
import { CrosschecksChartFilterGroupComponent } from './components/crosschecks-chart-filter-group/crosschecks-chart-filter-group.component';
import { CrosschecksChartWrapperComponent } from './components/crosschecks-chart-wrapper/crosschecks-chart-wrapper.component';
import { CrosschecksComponent } from './crosschecks.component';
import { CrosschecksHeaderComponent } from './components/crosschecks-header/crosschecks-header.component';
import { CrosschecksIndicationComponent } from './components/crosschecks-indication/crosschecks-indication.component';
import { CrosschecksMainFilterComponent } from './components/crosschecks-main-filter/crosschecks-main-filter.component';
import { CrosschecksRoutingModule } from './crosschecks-routing.module';
import { FtdTabsModule } from '../common/components/ftd-tabs/ftd-tabs.module';
import { HeaderModule } from 'src/app/header/header.module';
import { InternalPriceLadderComponent } from './pages/portfolio/internal-price-ladder/internal-price-ladder.component';
import { LeaseReturnsComponent } from './pages/sales-pipeline/lease-returns/lease-returns.component';
import { MacroeconomicsPageComponent } from './pages/macroeconomics/macroeconomics-page/macroeconomics-page.component';
import { MarketsComponent } from './pages/markets/markets/markets.component';
import { MarketsPageComponent } from './pages/markets/markets-page/markets-page.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MaterialModule } from '../common/material.module';
import { CommonModule as NgCommonModule, NgOptimizedImage } from '@angular/common';
import { NgModule } from '@angular/core';
import { OrderIntakeComponent } from './pages/sales-pipeline/order-intake/order-intake.component';
import { PortfolioPageComponent } from './pages/portfolio/portfolio-page/portfolio-page.component';
import { ProductionPipelinePageComponent } from './pages/production-pipeline/production-pipeline-page/production-pipeline-page.component';
import { RetailSalesComponent } from './pages/sales-pipeline/retail-sales/retail-sales.component';
import { SalesPipelinePageComponent } from './pages/sales-pipeline/sales-pipeline-page/sales-pipeline-page.component';
import { StockAgeComponent } from './pages/stock/stock-age/stock-age.component';
import { StockCoverageComponent } from './pages/stock/stock-coverage/stock-coverage.component';
import { StockMixComponent } from './pages/stock/stock-mix/stock-mix.component';
import { StockMixSideTableComponent } from './pages/stock/stock-mix/ftd-side-table/stock-mix-side-table/stock-mix-side-table.component';
import { StockPageComponent } from './pages/stock/stock-page/stock-page.component';
import { SubsidiesAndTaxationComponent } from './pages/macroeconomics/subsidies-and-taxation/subsidies-and-taxation.component';

@NgModule({
  declarations: [
    CrosschecksComponent,
    ChannelsPageComponent,
    CompetitionPageComponent,
    StockPageComponent,
    MacroeconomicsPageComponent,
    MarketsPageComponent,
    MarketsComponent,
    PortfolioPageComponent,
    ProductionPipelinePageComponent,
    SalesPipelinePageComponent,
    CalendarizedOrderBankComponent,
    OrderIntakeComponent,
    LeaseReturnsComponent,
    RetailSalesComponent,
    CrosschecksHeaderComponent,
    CrosschecksMainFilterComponent,
    CrosschecksChartWrapperComponent,
    ChartFiltersComponent,
    CrosschecksChartFilterGroupComponent,
    ChartFilterItemComponent,
    CrosschecksIndicationComponent,
    StockCoverageComponent,
    StockMixComponent,
    StockAgeComponent,
    StockAgeComponent,
    StockAgeComponent,
    InternalPriceLadderComponent,
    SubsidiesAndTaxationComponent,
    StockMixSideTableComponent,
  ],
  exports: [
    CrosschecksComponent,
    ChannelsPageComponent,
    CompetitionPageComponent,
    StockPageComponent,
    MacroeconomicsPageComponent,
    MarketsPageComponent,
    PortfolioPageComponent,
    ProductionPipelinePageComponent,
    SalesPipelinePageComponent,
    CalendarizedOrderBankComponent,
    OrderIntakeComponent,
    LeaseReturnsComponent,
    RetailSalesComponent,
    CrosschecksHeaderComponent,
  ],
  imports: [
    CrosschecksRoutingModule,
    NgCommonModule,
    CommonModule,
    HeaderModule,
    MaterialModule,
    MatSidenavModule,
    MatDividerModule,
    FtdTabsModule,
    NgOptimizedImage,
  ],
})
export class CrosschecksModule {}
