import { Brand } from '../../../matrix-view/models/brand.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FilterManagementService } from 'src/app/common/services/filter-management/filter-management.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { FtdDropdownOptionsUtils } from 'src/app/common/utils/ftd-dropdown-options.utils';
import { ICrosschecksFilters } from '../../crosschecks.component';
import { IFilters } from '../../../matrix-view/models/api.model';
import { IFtdDropdownOption } from '../../../common/models/ftd-dropdown-option.model';
import { IGranularity } from '../../../matrix-view/models/app.model';
import { Subscription } from 'rxjs';
import uniqBy from 'lodash.uniqby';

@Component({
  selector: 'app-crosschecks-main-filter',
  styleUrls: ['./crosschecks-main-filter.component.scss'],
  templateUrl: './crosschecks-main-filter.component.html',
})
export class CrosschecksMainFilterComponent implements OnInit, OnDestroy {
  private _crosschecksFilters!: IFilters;

  dropdownBrandList: IFtdDropdownOption<IGranularity>[] = [];
  dropdownSeriesList: IFtdDropdownOption<IGranularity>[] = [];
  dropdownESeriesList: IFtdDropdownOption<IGranularity>[] = [];
  dropdownModelList: IFtdDropdownOption<IGranularity>[] = [];
  dropdownModelCodeList: IFtdDropdownOption<IGranularity>[] = [];

  @Input() set crosschecksFilters(filters: IFilters) {
    this._crosschecksFilters = filters;
    this.handleInitFilters();
  }

  @Output() applyEvent = new EventEmitter<void>();

  subscriptions: Subscription[] = [];
  marketLabel = '';
  selectedBrand = '' as Brand;
  crosschecksForm!: FormGroup;
  isApplyBtnDisabled: boolean = false;

  constructor(private formBuilder: FormBuilder, private filterManagement: FilterManagementService) {}

  ngOnInit() {
    this.initForm();
    this.createFiltersSubscriptions();
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  private initForm() {
    this.crosschecksForm = this.formBuilder.group({
      brand: new FormControl<IGranularity[] | null>({ disabled: true, value: [] }),
      eSeries: new FormControl<IGranularity[] | null>({ disabled: true, value: [] }),
      model: new FormControl<IGranularity[] | null>({ disabled: true, value: [] }),
      modelCode: new FormControl<IGranularity[] | null>({ disabled: true, value: [] }),
      series: new FormControl<IGranularity[]>({ disabled: true, value: [] }),
    });
  }

  applyFilter() {
    if (this.crosschecksForm.get('modelCode')?.value.id) {
      this.filterManagement.filters = this.crosschecksForm.get('modelCode')?.value;
    } else if (this.crosschecksForm.get('model')?.value.id) {
      this.filterManagement.filters = this.crosschecksForm.get('model')?.value;
    } else if (this.crosschecksForm.get('eSeries')?.value.id) {
      this.filterManagement.filters = this.crosschecksForm.get('eSeries')?.value;
    }
    this.applyEvent.emit();
  }

  handleInitFilters(): void {
    // Url filters are acquired first, so they can be used to filter the next dropdown options
    const urlFilters = this.filterManagement.getUrlFilters();
    if (this._crosschecksFilters && this.crosschecksForm) {
      // Brand
      this.dropdownBrandList = this.transformIntoDropdownOption(this._crosschecksFilters.brand?.options, 'brand');
      const brandValue = this.dropdownBrandList.find((el) => el.value.brand === urlFilters.brand);
      this.crosschecksForm.controls.brand.patchValue(brandValue!.value);
      this.crosschecksForm.controls.brand.enable();
      this.selectedBrand = brandValue!.value.brand as Brand;
      this.marketLabel = brandValue!.value.market;

      // Series
      this.dropdownSeriesList = this.transformIntoDropdownOption(
        this._crosschecksFilters.series?.groupedOptions[this.selectedBrand],
        'series'
      );
      const seriesValue = this.dropdownSeriesList.find((el) => el.value.series === urlFilters.series);
      this.crosschecksForm.controls.series.patchValue(seriesValue!.value);
      this.crosschecksForm.controls.series.enable();

      // ESeries
      this.dropdownESeriesList = this.transformIntoDropdownOption(
        this._crosschecksFilters.eSeries?.groupedOptions[this.selectedBrand].filter((el: any) => {
          return seriesValue!.label === el.series;
        }),
        'eSeries'
      );
      const eSeriesValue = this.dropdownESeriesList.find((el) => el.value.eSeries?.includes(urlFilters.eSeries));
      this.crosschecksForm.controls.eSeries.patchValue(eSeriesValue!.value);

      // Model
      this.dropdownModelList = this.transformIntoDropdownOption(
        this._crosschecksFilters.model.groupedOptions[this.selectedBrand + ' | ' + eSeriesValue!.value.eSeries],
        'model'
      );
      const modelValue = this.dropdownModelList.find((el) => el.label === urlFilters.model);
      if (modelValue?.value) {
        this.crosschecksForm.controls.model.patchValue(modelValue.value);

        // ModelCode
        this.dropdownModelCodeList = this.transformIntoDropdownOption(
          this._crosschecksFilters.modelCode.groupedOptions[this.selectedBrand + ' | ' + modelValue.value.model].filter(
            (modelCodes) => modelCodes.eSeries === urlFilters.eSeries
          ),
          'modelCode'
        );

        const modelCodeValue = this.dropdownModelCodeList.find((el) => el.label === urlFilters.modelCode);
        if (modelCodeValue) {
          this.crosschecksForm.controls.modelCode.patchValue(modelCodeValue.value);
        }
      }
    }
  }

  private get fillCrosschecksFilters() {
    // This method is called with the subscription done on the form on changes
    return {
      brand: (selectedBrand: IGranularity) => {
        this.selectedBrand = selectedBrand.brand as Brand;
        this.isApplyBtnDisabled = true;
        this.crosschecksForm.controls.series?.setValue([]);
        this.crosschecksForm.controls.eSeries?.setValue([]);
        this.crosschecksForm.controls.model?.setValue([]);
        this.crosschecksForm.controls.modelCode?.setValue([]);
        if (selectedBrand.brand) {
          this.dropdownSeriesList = this.transformIntoDropdownOption(
            this._crosschecksFilters.series?.groupedOptions[selectedBrand.brand],
            'series'
          );
          this.isNextDropdownEnabled([], 'eSeries');
          this.isNextDropdownEnabled([], 'model');
          this.isNextDropdownEnabled([], 'modelCode');
        }
      },
      eSeries: (selectedESeries: IGranularity) => {
        if (selectedESeries.eSeries) {
          this.crosschecksForm.controls.model.setValue([]);
          this.crosschecksForm.controls.modelCode.setValue([]);
          this.dropdownModelList = this.transformIntoDropdownOption(
            this._crosschecksFilters.model?.groupedOptions[this.selectedBrand + ' | ' + selectedESeries.eSeries],
            'model'
          );
          this.isApplyBtnDisabled = Object.keys(this.crosschecksForm.get('eSeries')?.value).length <= 0;
          this.isNextDropdownEnabled(this.dropdownModelList, 'model');
          this.isNextDropdownEnabled([], 'modelCode');
        }
      },
      model: (selectedModel: IGranularity) => {
        if (selectedModel.model) {
          this.crosschecksForm.controls.modelCode.setValue([]);
          this.dropdownModelCodeList = this.transformIntoDropdownOption(
            this._crosschecksFilters.modelCode?.groupedOptions[this.selectedBrand + ' | ' + selectedModel.model].filter(
              (modelCodes) => modelCodes.eSeries === selectedModel.eSeries
            ),
            'modelCode'
          );
          this.isApplyBtnDisabled = Object.keys(this.crosschecksForm.get('model')?.value).length <= 0;
          this.isNextDropdownEnabled(this.dropdownModelCodeList, 'modelCode');
        }
      },
      modelCode: () => {},
      series: (selectedSeries: IGranularity) => {
        this.isApplyBtnDisabled = true;
        this.crosschecksForm.controls.eSeries?.setValue([]);
        this.crosschecksForm.controls.model?.setValue([]);
        this.crosschecksForm.controls.modelCode?.setValue([]);
        if (selectedSeries.series) {
          this._crosschecksFilters.eSeries?.groupedOptions[this.selectedBrand].forEach((eSeries: any) => {
            if (eSeries.series === selectedSeries.series) {
              this.dropdownESeriesList = this.transformIntoDropdownOption(
                this._crosschecksFilters.eSeries?.groupedOptions[this.selectedBrand].filter(
                  (el: ICrosschecksFilters) => el.series === selectedSeries.series
                ),
                'eSeries'
              );
              this.isNextDropdownEnabled(this.dropdownESeriesList, 'eSeries');
              this.isNextDropdownEnabled([], 'model');
              this.isNextDropdownEnabled([], 'modelCode');
            }
          });
        }
      },
    };
  }

  private isNextDropdownEnabled(list: IFtdDropdownOption<IGranularity>[], controlName: string): void {
    return list.length
      ? this.crosschecksForm.controls[controlName].enable()
      : this.crosschecksForm.controls[controlName].disable();
  }

  private createFiltersSubscriptions() {
    const brandSubscription = this.crosschecksForm.controls.brand.valueChanges.subscribe((brand) => {
      this.fillCrosschecksFilters.brand(brand);
    });
    this.subscriptions.push(brandSubscription);

    const seriesSubscription = this.crosschecksForm.controls.series.valueChanges.subscribe((series) => {
      this.fillCrosschecksFilters.series(series);
    });
    this.subscriptions.push(seriesSubscription);

    const eSeriesSubscription = this.crosschecksForm.controls.eSeries.valueChanges.subscribe((eSeries) => {
      this.fillCrosschecksFilters.eSeries(eSeries);
    });
    this.subscriptions.push(eSeriesSubscription);

    const modelSubscription = this.crosschecksForm.controls.model.valueChanges.subscribe((model) => {
      this.fillCrosschecksFilters.model(model);
    });
    this.subscriptions.push(modelSubscription);

    const modelCodeSubscription = this.crosschecksForm.controls.modelCode.valueChanges.subscribe(() => {
      this.fillCrosschecksFilters.modelCode();
    });
    this.subscriptions.push(modelCodeSubscription);

    if (this._crosschecksFilters) {
      this.handleInitFilters();
      this.applyFilter();
    }
  }

  private transformIntoDropdownOption(
    filters: ICrosschecksFilters[],
    controlName: string
  ): IFtdDropdownOption<IGranularity>[] {
    return FtdDropdownOptionsUtils.buildOptionsWithObjectValues(uniqBy(filters, controlName), controlName);
  }
}
