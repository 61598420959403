<div id="row-expand-collapse-button-on-{{ dataSourceItem.id }}" [style.margin-left.px]="dataSourceItem.level">
  <ng-container [formGroup]="form" *ngIf="isBulkSelection">
    <ng-container formArrayName="rows">
      <ng-container [formGroupName]="rowIndex">
        <mat-checkbox
          aria-label="Select/Unselect Model Code"
          [class.checked]="isChecked"
          class="checkbox-bulk"
          [formControlName]="controlName"
        ></mat-checkbox>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container>
    <button
      aria-label="Expand/Collapse rows"
      *ngIf="dataSourceItem.granularity.childrenType !== 'UNKNOWN'"
      mat-icon-button
      (click)="expandChildrenGranularities(dataSourceItem)"
    >
      <mat-icon>
        {{ dataSourceItem && dataSourceItem.isExpanded ? 'keyboard_arrow_down' : 'chevron_right' }}
      </mat-icon>
    </button>
  </ng-container>

  <span
    *ngIf="dataSourceItem && dataSourceItem.granularity"
    id="row-granularity-display-name-on-{{ dataSourceItem.id }}"
    class="vehicle-model"
  >
    <img
      class="brand-icon"
      *ngIf="dataSourceItem.granularity.type === 'BRAND' && dataSourceItem.granularity.brand === 'BMW'"
      src="assets/images/logoBmwMonoWhite.svg"
      alt="BMW logo in white"
    />
    <img
      class="brand-icon"
      *ngIf="dataSourceItem.granularity.type === 'BRAND' && dataSourceItem.granularity.brand === 'MINI'"
      src="assets/images/logoMiniMonoWhite.svg"
      alt="Mini logo in white"
    />
    {{ dataSourceItem.granularity.displayName }}
    <span *ngIf="dataSourceItem.granularity.type === 'SERIES'" class="info-label">
      | {{ dataSourceItem.granularity.segment }}
    </span>
    <span *ngIf="dataSourceItem.granularity.type === 'MODEL_CODE'" class="info-label">
      | {{ dataSourceItem.granularity.powertrain }}
    </span>
  </span>
</div>
