<div *ngIf="isUserScenario; else allIcons">
  <div>
    <button
      aria-label="Open notes"
      mat-icon-button
      class="actions-button"
      (click)="openPricePointComments(CommentsAndNotesContext.NOTES)"
      [disabled]="noteButtonDisabled"
      [attr.data-test]="'open-notes-btn'"
    >
      <mat-icon class="action-icon" [class.has-comments]="dataSourceItem.priceEditorData?.notes?.length">
        description
      </mat-icon>
    </button>
  </div>
  <div>
    <button
      aria-label="Open comments"
      mat-icon-button
      class="actions-button"
      (click)="openPricePointComments()"
      [disabled]="commentButtonDisabled"
    >
      <mat-icon class="action-icon" [class.has-comments]="dataSourceItem.priceEditorData?.comments?.length">
        chat
      </mat-icon>
    </button>
  </div>
</div>
<ng-template #allIcons>
  <div>
    <div>
      <div class="withdraw" *ngIf="currentUser?.permissions?.getHasPermissionToWithdrawOnMainScenario([selectedBrand])">
        <button
          aria-label="Widthdraw button"
          (click)="submitStatus('withdraw_status')"
          mat-icon-button
          [disabled]="isWithdrawStatusDisabled"
        >
          <mat-icon class="action-icon">block</mat-icon>
        </button>
      </div>
      <div class="cancel" *ngIf="currentUser?.permissions?.getHasPermissionToRejectPrice([selectedBrand])">
        <button
          (click)="submitStatus('demote_status')"
          aria-label="Demote price"
          mat-icon-button
          *ngIf="!isUserScenario"
          [disabled]="isDemoteStatusDisabled"
        >
          <mat-icon class="action-icon">
            {{ 'cancel' }}
          </mat-icon>
        </button>
      </div>
      <div class="check" *ngIf="currentUser?.permissions?.getHasPermissionToAcceptPrice([selectedBrand])">
        <button
          aria-label="Promote price"
          (click)="submitStatus('promote_status')"
          mat-icon-button
          [disabled]="isPromoteStatusDisabled"
        >
          <mat-icon class="action-icon">
            {{ 'check_circle' }}
          </mat-icon>
        </button>
      </div>
    </div>
    <div class="chat last-icon">
      <button
        aria-label="Open comments"
        mat-icon-button
        (click)="openPricePointComments()"
        [disabled]="commentButtonDisabled"
      >
        <mat-icon [class.has-comments]="dataSourceItem.priceEditorData?.comments?.length" class="action-icon">
          {{ 'chat' }}
        </mat-icon>
      </button>
    </div>
  </div>
</ng-template>
