import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
let _id: number = 0;

@Component({
  selector: 'app-ftd-textarea',
  styleUrls: ['./ftd-textarea.component.scss'],
  templateUrl: './ftd-textarea.component.html',
})
export class FtdTextareaComponent {
  @Input() form!: FormGroup;
  @Input() controlName: string = '';
  @Input() id!: string;
  @Input() name: string = '';
  @Input() label?: string;
  @Input() maxlength?: number;
  @Input() rows: number = 0;
  @Input() columns: number = 0;
  @Input() placeholder: string = '';
  @Input() customMessage?: string;

  /**
   * Makes input fitting & filling the available container's width
   */
  @Input() fillWidth: boolean = true;

  /**
   * Check if form has any error
   */
  get hasInputErrors() {
    return (
      this.form?.get(this.controlName)?.invalid &&
      (this.form?.get(this.controlName)?.dirty || this.form?.get(this.controlName)?.touched)
    );
  }

  constructor() {
    _id += 1;
    this.id = `ftd-textarea-${_id}`;
  }
}
