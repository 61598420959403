import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { MatMenuTrigger, MenuPositionX, MenuPositionY } from '@angular/material/menu';

/** Reason why the menu was closed. */
declare type MenuCloseReason = void | 'click' | 'keydown' | 'tab';

@Component({
  selector: 'app-ftd-expanded-tooltip',
  templateUrl: './ftd-expanded-tooltip.component.html',
})
export class FtdExpandedTooltipComponent implements AfterViewInit {
  /*
   * Event emitted when the menu is closed.
   */
  @Output() closed: EventEmitter<MenuCloseReason> = new EventEmitter<MenuCloseReason>();

  /**
   * Emit menu-trigger-ref once menu is visible to allow parent components to programmatically open, close, ... it
   */
  @Output() triggerInitialized: EventEmitter<MatMenuTrigger> = new EventEmitter();

  /**
   * Emit menu close reason.
   * @param menuCloseReason
   */
  emitClosed(menuCloseReason: MenuCloseReason) {
    this.closed.emit(menuCloseReason);
  }

  /*
   * Position of the menu in the Y axis.
   * Default: below.
   */
  @Input() yPosition: MenuPositionY = 'below';

  /*
   * Position of the menu in the Y axis.
   * Default: after.
   */
  @Input() xPosition: MenuPositionX = 'after';

  /*
   * Whether the menu has a backdrop.
   * Default: true.
   */
  @Input() hasBackdrop: boolean = true;

  /*
   * Whether the menu should overlap its trigger.
   * Default: false.
   */
  @Input() overlapTrigger: boolean = false;

  /*
   * Open menu on mouse over.
   * Default: true.
   */
  @Input() openMenuOnMouseOver: boolean = true;

  /*
   * Open menu on mouse over.
   * Default: true.
   */
  @Input() closeMenuOnMouseLeave: boolean = false;

  /*
   * Open menu on mouse click.
   * Default: false.
   */
  @Input() openMenuOnMouseClick: boolean = false;

  /*
   * Open menu on mouse click.
   * Default: false.
   */
  @Input() hasTooltip: boolean = true;

  /*
   * ViewChild trigger selector.
   * Default: trigger as MatMenuTrigger.
   */
  @ViewChild('trigger') trigger?: MatMenuTrigger;
  @ViewChild('contentRef') contentRef?: ElementRef | null;

  constructor(private ngZone: NgZone, private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    // Bind outside ngZone to avoid change detection.
    this.ngZone.runOutsideAngular(() => {
      // Only bind mouseevents if there is any tooltip content.
      if (this.contentRef?.nativeElement.innerText) {
        this.bindMouseEvents();
      }
    });
    this.triggerInitialized.emit(this.trigger);
  }

  private bindMouseEvents() {
    this.renderer.listen(
      (this.trigger as any)!._element.nativeElement,
      'mouseover',
      this.triggerOpenMenuOnMouseOver.bind(this)
    );
    this.renderer.listen(
      (this.trigger as any)!._element.nativeElement,
      'mouseleave',
      this.triggerCloseMenuOnMouseLeave.bind(this)
    );
  }

  /*
   * Trigger Open Menu On Mouse Over if is enabled.
   */
  triggerOpenMenuOnMouseOver(): void {
    if (this.openMenuOnMouseOver) {
      this.trigger?.openMenu();
    }
  }

  /*
   * Trigger Close Menu On Mouse Out if is enabled.
   */
  triggerCloseMenuOnMouseLeave(): void {
    if (this.closeMenuOnMouseLeave) {
      this.trigger?.closeMenu();
    }
  }

  /*
   * Trigger Open Menu On Mouse click if is enabled.
   */
  triggerOpenMenuOnMouseClick(): void {
    if (this.openMenuOnMouseClick) {
      this.trigger?.openMenu();
    }
  }
}
