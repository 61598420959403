import { CdkTable, CdkTableDataSourceInput } from '@angular/cdk/table';
import { Component, HostListener, Input, NgZone, OnInit, ViewChild } from '@angular/core';
import { DiscountApprovalStatus } from '../../enums/discount-approval-status.enum';
import { DiscountManagementService } from '../../services/discount-management.service';
import { IDiscount, Maybe } from '../../../graphql/services/gql-api.service';
import { IDiscountStatusMap, discountStatusMap } from './config/discount-status-map.config';
import {
  ISummaryTableColumnCategoryModel,
  ISummaryTableColumnModel,
  SummaryTableColumnCategoryName,
  SummaryTableColumns,
} from '../../models/discounts-summary-table.model';
import { Router } from '@angular/router';
import { delay, first, tap } from 'rxjs/operators';
import { summaryTableColumnCategories, summaryTableColumns } from './config/summary-table.config';

@Component({
  selector: 'app-discounts-summary-table',
  styleUrls: ['./discounts-summary-table.component.scss'],
  templateUrl: './discounts-summary-table.component.html',
})

/**
 * @class DiscountsSummaryTableComponent
 */
export class DiscountsSummaryTableComponent implements OnInit {
  @Input() discountSummaryData: CdkTableDataSourceInput<IDiscount> = [];
  columns: ISummaryTableColumnModel[] = summaryTableColumns;
  columnCategory: ISummaryTableColumnCategoryModel[] = summaryTableColumnCategories;
  columnsToDisplay: SummaryTableColumns[] = [];
  columnCategories: SummaryTableColumnCategoryName[] = [];
  lastChildOfCategory: SummaryTableColumns[] = [];
  protected readonly discountStatusMap: Map<Maybe<DiscountApprovalStatus>, IDiscountStatusMap> = discountStatusMap;

  @ViewChild(CdkTable) discountsSummaryTable!: CdkTable<IDiscount>;

  /**
   * @constructor
   * @param discountManagementService
   * @param zone
   * @param router
   */
  constructor(
    private readonly discountManagementService: DiscountManagementService,
    private zone: NgZone,
    private router: Router
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.columnsToDisplay = this.discountManagementService.getDisplayedColumns();
    this.columnCategories = this.discountManagementService.getColumnCategories();
    this.lastChildOfCategory = this.discountManagementService.getLastChildOfCategory();
    this.zone.onMicrotaskEmpty
      .pipe(
        delay(200),
        tap(() => this.discountsSummaryTable.updateStickyColumnStyles()),
        first()
      )
      .subscribe();
  }

  /**
   * GetDiscountStatus
   * @return string
   */
  @HostListener('window:resize')
  tableStickyReset(): void {
    this.discountsSummaryTable.updateStickyColumnStyles();
  }

  /**
   * GetDiscountStatus
   * @return string
   * @param id
   */
  openDiscountMatrix(id: string): void {
    this.router.navigate(['discounts/discount/', id]);
  }
}
