import { Component, Input, OnInit } from '@angular/core';
import { DiscountMatrixViewDataService } from '../../../services/matrix-view-data/matrix-view-data.service';
import { DiscountMatrixViewFormService } from '../../../services/matrix-view-form/matrix-view-form.service';
import { FormArray, FormGroup } from '@angular/forms';
import { GranularityUtils } from 'src/app/matrix-view/utils/granularity/granularity.utils';
import { IGranularity } from 'src/app/matrix-view/models/app.model';
import { IMatrixViewDataSourceItem } from 'src/app/matrix-view/models/matrix-view.model';

@Component({
  selector: 'app-discount-mv-row-name',
  styleUrls: ['./mv-row-name.component.scss'],
  templateUrl: './mv-row-name.component.html',
})
export class MvRowNameComponent implements OnInit {
  @Input() form!: FormGroup;
  @Input() controlName: string = '';
  @Input() rowIndex!: number;
  @Input() isBulkSelection: boolean = false;
  @Input() dataSourceItem!: IMatrixViewDataSourceItem;

  private granularityLevels: Map<string, number>;
  constructor(
    private matrixViewDataService: DiscountMatrixViewDataService,
    private matrixViewFormService: DiscountMatrixViewFormService
  ) {
    this.granularityLevels = GranularityUtils.getGranularityLevels();
  }

  ngOnInit() {
    this.dataSourceItem.level = this.getSpaceGranularity(this.dataSourceItem.granularity);
  }

  expandChildrenGranularities(dataSourceItem: IMatrixViewDataSourceItem): void {
    // Send the granularity info to the <app-discount-matri-view-data/> using RXJS to skip all components hierachy
    this.matrixViewDataService.sendGranularityToExpandOrCollapse(dataSourceItem.granularity);
  }

  getSpaceGranularity(granularityItem: IGranularity) {
    // Incrementing margin by 40 for each level
    const levelMargin = this.granularityLevels.has(granularityItem.type)
      ? 40 * (this.granularityLevels.get(granularityItem.type) as number)
      : 0;
    return levelMargin;
  }

  get matrixViewFormArray(): FormArray<FormGroup> {
    return this.matrixViewFormService.getFormArray();
  }

  get isChecked(): boolean {
    return this.matrixViewFormArray.at(this.rowIndex)?.get(this.controlName)?.value;
  }
}
