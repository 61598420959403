import { Component, Input } from '@angular/core';
import { IMatrixColumnConfig } from '../../../models/matrix-view.model';
import { MatrixColumnType } from 'src/app/matrix-view/enums/matrix-column-type.enum';

@Component({
  selector: 'app-discount-mv-expanded-tooltip-column-header',
  styleUrls: ['./mv-expanded-tooltip-column-header.component.scss'],
  templateUrl: './mv-expanded-tooltip-column-header.component.html',
})
export class MvExpandedTooltipColumnHeaderComponent {
  protected readonly MatrixColumnType = MatrixColumnType;

  @Input() columnData: IMatrixColumnConfig = {
    columnType: '',
    displayName: 'No data available',
    id: '0',
    secondaryDisplayName: '',
  };

  get isTooltipVisible(): boolean {
    return this.columnData.columnType !== MatrixColumnType.DISABLED;
  }

  get lastSourceCall(): string {
    return this.columnData.metadata?.lastSourceCall && this.columnData.metadata?.lastSourceCall !== ''
      ? this.columnData.metadata?.lastSourceCall
      : 'Last source call';
  }
}
