<app-discounts-header
  [context]="SUMMARY_DISCOUNTS_CONFIG.context"
  [title]="SUMMARY_DISCOUNTS_CONFIG.title"
  [isDraftsHidden]="SUMMARY_DISCOUNTS_CONFIG.isDraftsHidden"
  [isCreateDiscountHidden]="SUMMARY_DISCOUNTS_CONFIG.isCreateDiscountHidden"
  [isSaveHidden]="SUMMARY_DISCOUNTS_CONFIG.isSaveHidden"
  [isSubmitHidden]="SUMMARY_DISCOUNTS_CONFIG.isSubmitHidden"
  [isWithdrawHidden]="SUMMARY_DISCOUNTS_CONFIG.isWithdrawHidden"
  [isRejectHidden]="SUMMARY_DISCOUNTS_CONFIG.isRejectHidden"
  [isApproveHidden]="SUMMARY_DISCOUNTS_CONFIG.isApproveHidden"
  [isReleaseHidden]="SUMMARY_DISCOUNTS_CONFIG.isReleaseHidden"
>
</app-discounts-header>

<mat-card id="discounts-mat-card">
  <mat-card-content id="discounts-mat-card-content">
    <div class="container">
      <app-discounts-summary-filters
        (emitMarketFiltersChanged)="emitMarketFiltersChanged($event)"
        (emitApplyFiltersChanged)="onClickApplyFilters($event)"
        [isLoading]="isRequestProcessing"
      ></app-discounts-summary-filters>
      <div class="discounts">
        <app-discounts-summary-table
          *ngIf="discountSummaryData.length && !isRequestProcessing"
          [discountSummaryData]="discountSummaryData"
        ></app-discounts-summary-table>

        <app-discounts-summary-empty-state
          *ngIf="!discountSummaryData.length"
          [isFilterApplied]="isFilterApplied"
          [hasError]="hasError"
          [isLoading]="isRequestProcessing"
        ></app-discounts-summary-empty-state>
      </div>
    </div>
  </mat-card-content>
</mat-card>
