import { BreadcrumbsService } from '../../services/breadcrumbs/breadcrumbs.service';
import { Component } from '@angular/core';
import { IBreadcrumb } from '../../models/breadcrumb.model';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-ftd-breadcrumbs',
  styleUrls: ['./ftd-breadcrumbs.component.scss'],
  templateUrl: './ftd-breadcrumbs.component.html',
})
export class FtdBreadcrumbsComponent {
  breadcrumbs$: Observable<IBreadcrumb[]>;

  constructor(private readonly breadcrumbService: BreadcrumbsService) {
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
  }
}
