<div id="mv-filter-container" fxLayout="column" fxLayoutAlign="space-between start">
  <mat-card>
    <div class="mv-filter-card-header-container" fxFill fxLayout="row" fxLayoutAlign="space-between center">
      <span id="mv-filter-title">FILTERS</span>
      <button
        aria-label="Reset filters"
        id="mv-reset-filter-btn"
        [attr.data-test]="'mv-reset-filter-btn'"
        mat-button
        color="primary"
        [class.hidden]="!isMarketValueSet"
        (click)="resetSelectFilters()"
      >
        Reset filters
      </button>
    </div>
    <div class="size" fxLayout="column" fxLayoutAlign="flex-start">
      <form [formGroup]="filtersForm" novalidate class="filter-form" *ngIf="currentUser">
        <div class="filters">
          <div *ngFor="let filter of filtersList | keyvalue: preserveOriginalKeyValueOrder; trackBy: optionTrackBy">
            <div [ngSwitch]="filter.value.type">
              <ng-container *ngSwitchCase="'multi-grouped'">
                <app-ftd-multi-dropdown-grouped
                  id="{{ 'mv-filter-' + filter.key }}"
                  attr.data-test="{{ 'mv-filter-' + filter.key }}"
                  [isSearchVisible]="true"
                  [label]="filter.value.label"
                  [form]="filtersForm"
                  [controlName]="filter.key"
                  [optionsGroupList]="filter.value.groupedOptions!"
                  [showQuantityOf]="'displayName'"
                ></app-ftd-multi-dropdown-grouped>
              </ng-container>
              <ng-container *ngSwitchCase="'multi'">
                <app-ftd-multi-dropdown
                  id="{{ 'mv-filter-' + filter.key }}"
                  attr.data-test="{{ 'mv-filter-' + filter.key }}"
                  [isSearchVisible]="true"
                  [label]="filter.value.label"
                  [form]="filtersForm"
                  [controlName]="filter.key"
                  [options]="filter.value.options!"
                  [showBrandIcon]="filter.key === 'brand'"
                ></app-ftd-multi-dropdown>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <app-ftd-dropdown
                  id="{{ 'mv-filter-' + filter.key }}"
                  attr.data-test="{{ 'mv-filter-' + filter.key }}"
                  [isSearchVisible]="true"
                  [label]="filter.value.label"
                  [form]="filtersForm"
                  [isFlagsVisible]="filter.key === 'market'"
                  [controlName]="filter.key"
                  [options]="filter.value.options!"
                ></app-ftd-dropdown>
              </ng-container>
            </div>
          </div>
        </div>
      </form>
    </div>
    <ngx-skeleton-loader
      count="1"
      appearance="line"
      animation="pulse"
      [theme]="buttonSkeletonTheme"
      *ngIf="!isUserMarketLoaded"
    >
    </ngx-skeleton-loader>
    <app-ftd-button
      *ngIf="isUserMarketLoaded"
      [id]="'mv-filter-confirm-btn'"
      [attr.data-test]="'mv-filter-confirm-btn'"
      [label]="'APPLY'"
      ariaText="Apply Filter"
      size="L"
      [fillWidth]="true"
      [disabled]="confirmButtonDisabled"
      [matTooltip]="'Please select a market'"
      [matTooltipPosition]="'above'"
      [matTooltipDisabled]="!confirmButtonDisabled"
      (btnClick)="confirmSelectedFilters()"
    ></app-ftd-button>
  </mat-card>
</div>
