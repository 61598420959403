import { BehaviorSubject, Observable, throwError } from 'rxjs';
import {
  BenefitType,
  DiscountCategory,
  DiscountType,
  IAddDiscountsGQL,
  IAddDiscountsMutation,
  IAddDiscountsMutationVariables,
  IDiscount,
  IDiscountDraftsGQL,
  IDiscountDraftsQuery,
  IDiscountDraftsQueryVariables,
  IDiscountGQL,
  IDiscountPointsByDiscountIdTotalGQL,
  IDiscountPointsByDiscountIdTotalQuery,
  IDiscountPointsByDiscountIdTotalQueryVariables,
  IDiscountPointsByDiscountIdTotalResponse,
  IDiscountQuery,
  IDiscountQueryVariables,
  IDiscountsGQL,
  IDiscountsQuery,
  IDiscountsQueryVariables,
  ISubmitDiscountGQL,
  ISubmitDiscountMutation,
  ISubmitDiscountMutationVariables,
  SalesChannels,
} from '../../graphql/services/gql-api.service';
import { BenefitTypeKey, BenefitTypeMap, BenefitTypeValue } from '../enums/benefit-type.enum';
import { DiscountCategoryKey, DiscountCategoryMap, DiscountCategoryValue } from '../enums/discount-category.enum';
import { DiscountTypeKey, DiscountTypeMap, DiscountTypeValue } from '../enums/discount-type.enum';
import { FetchResult } from '@apollo/client/core';
import {
  ISummaryTableColumnCategoryModel,
  ISummaryTableColumnModel,
  SummaryTableColumnCategoryName,
  SummaryTableColumns,
} from '../models/discounts-summary-table.model';
import { Injectable } from '@angular/core';
import { SalesChannelsKey, SalesChannelsMap, SalesChannelsValue } from '../enums/sales-channels.enum';
import { catchError, map } from 'rxjs/operators';
import {
  summaryTableColumnCategories,
  summaryTableColumns,
} from '../components/discounts-summary-table/config/summary-table.config';

@Injectable({
  providedIn: 'root',
})

/**
 * @class DiscountManagementService
 */
export class DiscountManagementService {
  protected readonly discountCategoryMap: Map<DiscountCategoryKey, DiscountCategoryValue> = DiscountCategoryMap;
  protected readonly discountTypeMap: Map<DiscountTypeKey, DiscountTypeValue> = DiscountTypeMap;
  protected readonly salesChannelsMap: Map<SalesChannelsKey, SalesChannelsValue> = SalesChannelsMap;
  protected readonly benefitTypeMap: Map<BenefitTypeKey, BenefitTypeValue> = BenefitTypeMap;
  private reloadGetDiscountsSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private reloadGetDiscountSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  /**
   * @constructor
   * @param submitDiscountGQL
   * @param addDiscountsGQL
   * @param discountsGQL
   * @param discountDraftsGQL
   * @param discountGQL
   * @param discountPointsByDiscountIdTotalGQL
   */
  constructor(
    private submitDiscountGQL: ISubmitDiscountGQL,
    private addDiscountsGQL: IAddDiscountsGQL,
    private discountsGQL: IDiscountsGQL,
    private discountDraftsGQL: IDiscountDraftsGQL,
    private discountGQL: IDiscountGQL,
    private discountPointsByDiscountIdTotalGQL: IDiscountPointsByDiscountIdTotalGQL
  ) {}

  /**
   * TriggerReloadGetDiscountsReady
   * @param reloadGetDiscounts
   */
  triggerReloadGetDiscounts(reloadGetDiscounts: boolean): void {
    this.reloadGetDiscountsSubject.next(reloadGetDiscounts);
  }

  /**
   * ReloadGetDiscountsReady
   * @return Observable<boolean>
   */
  reloadGetDiscounts(): Observable<boolean> {
    return this.reloadGetDiscountsSubject.asObservable();
  }

  /**
   * TriggerReloadGetDiscountReady
   * @param reloadGetDiscount
   */
  triggerReloadGetDiscount(reloadGetDiscount: boolean): void {
    this.reloadGetDiscountSubject.next(reloadGetDiscount);
  }

  /**
   * ReloadGetDiscountsReady
   * @return Observable<boolean>
   */
  reloadGetDiscount(): Observable<boolean> {
    return this.reloadGetDiscountSubject.asObservable();
  }

  /**
   * AddDiscounts
   * @param addDiscountsMutationVariables
   * @return Observable<IDiscount>
   */
  addDiscounts(addDiscountsMutationVariables: IAddDiscountsMutationVariables): Observable<IDiscount> {
    return this.addDiscountsGQL.mutate(addDiscountsMutationVariables).pipe(
      map((response: FetchResult<IAddDiscountsMutation>) => {
        return response.data!.addDiscount as IDiscount;
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * GetDiscounts
   * @param discountsQueryVariables
   * @return Observable<IDiscount[]>
   */
  getDiscounts(discountsQueryVariables: IDiscountsQueryVariables): Observable<IDiscount[]> {
    return this.discountsGQL.fetch(discountsQueryVariables).pipe(
      map((response: FetchResult<IDiscountsQuery>) => {
        return response.data!.discounts.items.map((discount: IDiscount) => {
          return {
            ...discount,
            benefitType: this.benefitTypeMap.get(discount.benefitType) as BenefitType,
            discountCategory: this.discountCategoryMap.get(discount.discountCategory) as DiscountCategory,
            discountType: this.discountTypeMap.get(discount.discountType) as DiscountType,
            salesChannels: discount.salesChannels.map((salesChannel: SalesChannels) =>
              this.salesChannelsMap.get(salesChannel)
            ) as unknown as SalesChannels[],
          };
        }) as IDiscount[];
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * GetDiscountDrafts
   * @param discountDraftsQueryVariables
   * @return Observable<IDiscount[]>
   */
  getDiscountDrafts(discountDraftsQueryVariables: IDiscountDraftsQueryVariables): Observable<IDiscount[]> {
    return this.discountDraftsGQL.fetch(discountDraftsQueryVariables).pipe(
      map((response: FetchResult<IDiscountDraftsQuery>) => {
        return response.data!.discountDrafts.items.map((discount: IDiscount) => {
          return {
            ...discount,
            benefitType: this.benefitTypeMap.get(discount.benefitType) as BenefitType,
            discountCategory: this.discountCategoryMap.get(discount.discountCategory) as DiscountCategory,
            discountType: this.discountTypeMap.get(discount.discountType) as DiscountType,
            salesChannels: discount.salesChannels.map((salesChannel: SalesChannels) =>
              this.salesChannelsMap.get(salesChannel)
            ) as unknown as SalesChannels[],
          };
        }) as IDiscount[];
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * GetDiscount
   * @return Observable<IDiscount[]>
   * @param discountQueryVariables
   */
  getDiscount(discountQueryVariables: IDiscountQueryVariables): Observable<IDiscount> {
    return this.discountGQL.fetch(discountQueryVariables).pipe(
      map((response: FetchResult<IDiscountQuery>) => {
        const discount: IDiscount = response.data?.discount as IDiscount;

        return {
          ...discount,
          benefitType: this.benefitTypeMap.get(discount.benefitType) as BenefitType,
          discountCategory: this.discountCategoryMap.get(discount.discountCategory) as DiscountCategory,
          discountType: this.discountTypeMap.get(discount.discountType) as DiscountType,
          salesChannels: discount.salesChannels.map((salesChannel: SalesChannels) =>
            this.salesChannelsMap.get(salesChannel)
          ) as unknown as SalesChannels[],
        } as IDiscount;
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * GetDiscountPointsByDiscountIdTotal
   * @return Observable<IDiscountPointsByDiscountIdTotalResponse>
   * @param discountPointsByDiscountIdTotalQueryVariables
   */
  getDiscountPointsByDiscountIdTotal(
    discountPointsByDiscountIdTotalQueryVariables: IDiscountPointsByDiscountIdTotalQueryVariables
  ): Observable<IDiscountPointsByDiscountIdTotalResponse> {
    return this.discountPointsByDiscountIdTotalGQL.fetch(discountPointsByDiscountIdTotalQueryVariables).pipe(
      map((response: FetchResult<IDiscountPointsByDiscountIdTotalQuery>) => {
        return response.data?.discountPointsByDiscountIdTotal as IDiscountPointsByDiscountIdTotalResponse;
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * SubmitDiscount
   * @param submitDiscountMutationVariables
   * @return Observable<IDiscount>
   */
  submitDiscount(submitDiscountMutationVariables: ISubmitDiscountMutationVariables): Observable<IDiscount> {
    return this.submitDiscountGQL.mutate(submitDiscountMutationVariables).pipe(
      map((response: FetchResult<ISubmitDiscountMutation>) => {
        return response.data!.submitDiscount as IDiscount;
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * GetDisplayedColumns
   * @return SummaryTableColumns[]
   */
  getDisplayedColumns(): SummaryTableColumns[] {
    return summaryTableColumns
      .sort((a: ISummaryTableColumnModel, b: ISummaryTableColumnModel) => a.position - b.position)
      .map((column: ISummaryTableColumnModel) => column.name);
  }

  /**
   * GetColumnCategories
   * @return SummaryTableColumnCategoryName[]
   */
  getColumnCategories(): SummaryTableColumnCategoryName[] {
    return summaryTableColumnCategories.map((column: ISummaryTableColumnCategoryModel) => column.name);
  }

  /**
   * GetLastChildOfCategory
   * @return SummaryTableColumns[]
   */
  getLastChildOfCategory(): SummaryTableColumns[] {
    return summaryTableColumnCategories
      .filter((category) => category.name !== 'ACTIONS')
      .map((column: ISummaryTableColumnCategoryModel) => {
        return summaryTableColumns
          .filter((summaryTableColumn: ISummaryTableColumnModel) => summaryTableColumn.category === column.name)
          .sort((a: ISummaryTableColumnModel, b: ISummaryTableColumnModel) => a.position - b.position)
          .map((summaryTableColumn: ISummaryTableColumnModel) => summaryTableColumn.name)
          .pop() as SummaryTableColumns;
      });
  }
}
