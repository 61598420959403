<app-ftd-custom-modal>
  <app-ftd-custom-modal-header
    [titleLeftMatIcon]="'warning_amber'"
    [colorLeftMatIcon]="'warning'"
    [title]="title"
  ></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body
    [requestProcessingMessage]="requestProcessingMessage"
    [isRequestProcessing]="isRequestProcessing"
  >
    <div>
      <span [innerHTML]="description"></span>
    </div>

    <div class="reason-container" *ngIf="isDemoteStatus">
      <div class="mb-2">
        <span> Please type a reason </span>
      </div>

      <app-ftd-input
        id="ftd-modal-input"
        [placeholder]="'Write a reason for decline...'"
        [form]="form"
        [controlName]="'reason'"
        [fillWidth]="true"
      ></app-ftd-input>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer [cancelButtonText]="'Cancel'">
    <app-ftd-button
      [id]="'submit-status-btn'"
      (btnClick)="submit()"
      [disabled]="isSubmitButtonDisabled"
      [label]="buttonText"
      [ariaText]="buttonText"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="true"
      [attr.data-test]="'submit-status-btn'"
      [hierarchy]="buttonHierarchy"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
