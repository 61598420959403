<div>
  <app-loading *ngIf="isLoading" color="white" [diameter]="32"></app-loading>
  <div *ngIf="!isLoading">
    <div *ngIf="isFilterApplied && !hasError" class="empty-state-container">
      <img src="../assets/images/emptyStateDiscountsSummary.svg" alt="Create discount" class="empty-state-image" />
      <div class="label-wrapper">
        <span class="label">No results found</span>
        <span class="mt-3">Please try to use another filter</span>
      </div>
    </div>
    <div *ngIf="!isFilterApplied && !hasError" class="empty-state-container">
      <img src="../assets/images/applyFiltersDiscountsSummary.svg" alt="Create discount" class="empty-state-image" />
      <div class="label-wrapper">
        <span class="label">Apply filters to see results</span>
        <span class="mt-3">Your discounts will be shown here</span>
      </div>
    </div>
    <div *ngIf="hasError" class="empty-state-container">
      <img src="../assets/images/errorState.svg" alt="Create discount" class="empty-state-image" />
      <div class="label-wrapper">
        <span class="label">Something went wrong</span>
        <span class="mt-3">Please try again later</span>
      </div>
    </div>
  </div>
</div>
