import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IFtdDropdownOption, IFtdDropdownOptionsGroup } from '../../models/ftd-dropdown-option.model';
import { IFtdDropdownWithQuantity } from './ftd-dropdown.model';
import { Subscription } from 'rxjs';
import flatten from 'lodash.flatten';

/**
 * Will display the {@link FtdMultiDropdownGroupedComponent.optionsGroupList} as grouped options by their group-label.
 */
@Component({
  selector: 'app-ftd-multi-dropdown-grouped',
  styleUrls: ['./ftd-multi-dropdown-grouped.component.scss'],
  templateUrl: './ftd-multi-dropdown-grouped.component.html',
})
export class FtdMultiDropdownGroupedComponent<T> implements OnInit, OnDestroy {
  @Input() id!: string;
  @Input() isSearchVisible: boolean = false;

  private _defaultOptionsGroupList: IFtdDropdownOptionsGroup<T>[] = [];
  private _optionsGroupList: IFtdDropdownOptionsGroup<T>[] = [];
  get optionsGroupList(): IFtdDropdownOptionsGroup<T>[] {
    return this._optionsGroupList;
  }

  @Input() set optionsGroupList(options: IFtdDropdownOptionsGroup<T>[]) {
    this._defaultOptionsGroupList = options;
    this._optionsGroupList = options;
  }

  @Input() label: string = 'Select';
  @Input() form!: FormGroup;
  @Input() controlName: string = '';
  @Input() defaultSelected?: IFtdDropdownOption<T>[];
  @Input() showQuantityOf!: keyof T;

  @Output() selectedOptions: EventEmitter<any> = new EventEmitter();

  selectedOptionsToDisplay: IFtdDropdownWithQuantity[] = [];

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param changeDetector
   */
  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.isSearchVisible) {
      this.setSearchForm();
    }

    const subscription = this.form?.get(this.controlName)?.valueChanges.subscribe((option) => {
      this.emitSelectedOptions(option);
    });

    if (this.form?.get(this.controlName)?.value?.length) {
      this.formatModelList(this.form?.get(this.controlName)?.value);
    }

    if (this.defaultSelected) {
      const defaultValues: IFtdDropdownOption<T>[] = flatten(
        this.optionsGroupList.map((group) => group.options)
      ).filter((option) => this.defaultSelected?.find((defaultOption) => defaultOption.id === option.id));

      this.form?.patchValue({
        [this.controlName]: defaultValues,
      });

      if (subscription) {
        this.subscriptions.push(subscription);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }

  /**
   * Distinct Value Model By Id
   * @param ftdDropdownOptionA
   * @param ftdDropdownOptionB
   */
  distinctById(ftdDropdownOptionA: IFtdDropdownOption, ftdDropdownOptionB: IFtdDropdownOption): boolean {
    if (ftdDropdownOptionA && ftdDropdownOptionB) {
      return ftdDropdownOptionA.id === ftdDropdownOptionB.id;
    }
    return false;
  }

  emitSelectedOptions(options: T[]): void {
    this.selectedOptions.emit(options);
    if (options && options.length > 0) {
      this.formatModelList(options);
    }
  }

  groupTrackBy(index: number, group: IFtdDropdownOptionsGroup<T>): number | string {
    return group.id;
  }

  optionTrackBy(index: number, option: IFtdDropdownOption<T>): number | string {
    return option.id;
  }

  formatModelList(options: T[]) {
    this.selectedOptionsToDisplay = [];
    options.forEach((option: T) => {
      const modelInListIndex = this.selectedOptionsToDisplay.findIndex(
        (model: IFtdDropdownWithQuantity) => model.name === option[this.showQuantityOf]
      );
      if (modelInListIndex >= 0) {
        const foundModel = this.selectedOptionsToDisplay[modelInListIndex];
        this.selectedOptionsToDisplay[modelInListIndex] = {
          ...foundModel,
          quantity: foundModel.quantity + 1,
        };
      } else {
        this.selectedOptionsToDisplay.push({ name: option[this.showQuantityOf] as string, quantity: 1 });
      }
    });
  }

  hasToShowComma(index: number): boolean {
    return this.selectedOptionsToDisplay.length > 1 && index < this.selectedOptionsToDisplay.length - 1;
  }

  /**
   * SetSearchForm
   * @private
   */
  private setSearchForm(): void {
    this.form?.addControl(`${this.controlName}Search`, new FormControl(''));
    const subscriptionSearch: Subscription | undefined = this.form
      ?.get(`${this.controlName}Search`)
      ?.valueChanges.subscribe((search): void => {
        this.filterSearch(search);
      });

    if (subscriptionSearch) {
      this.subscriptions.push(subscriptionSearch);
    }
  }

  /**
   * FilterSearch
   * @private
   * @param search
   */
  private filterSearch(search: string): void {
    if (search?.length) {
      this._optionsGroupList = this._defaultOptionsGroupList
        .map((element: IFtdDropdownOptionsGroup<T>): IFtdDropdownOptionsGroup<T> => {
          return {
            ...element,
            options: element.options.filter(
              (option: IFtdDropdownOption<T>): boolean =>
                String(option.label).toLowerCase().indexOf(String(search).toLowerCase()) > -1
            ),
          };
        })
        .filter((option: IFtdDropdownOptionsGroup<T>): boolean => option.options.length > 0);

      this.changeDetector.detectChanges();
    } else if (search?.length === 0 || this._optionsGroupList.length === 0) {
      this._optionsGroupList = this._defaultOptionsGroupList;
    }
  }
}
