import { AwsRum } from 'aws-rum-web';
import { FtdNotifierService } from '../../../common/services/ftd-notifier/ftd-notifier.service';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { IApiErrorResponse } from '../../error-handling/errors/models/api-error.model';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
declare let window: any;

@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  constructor(private notifierService: FtdNotifierService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        const error_user_msg = (httpErrorResponse.error as IApiErrorResponse)?.error?.error_user_msg;

        if (error_user_msg) {
          this.notifierService.showError(error_user_msg, 5);
        }
        if (environment?.environment === 'dev' && window.awsRum) {
          (window.awsRum as AwsRum).recordError(error_user_msg);
        }
        return throwError(httpErrorResponse);
      })
    );
  }
}
