import { AbstractService } from 'src/app/core/services/abstract.service';
import { AuthError } from '../../core/error-handling/errors/enums/auth-error.enum';
import { BehaviorSubject, Observable, Subscription, throwError } from 'rxjs';
import { FetchUserProfileApiError, LogoutUserApiError } from 'src/app/core/error-handling/errors/error-functions';
import { HttpErrorResponse } from '@angular/common/http';
import { IFailure } from '../../core/error-handling/errors/models/failure';

import { IUserResponse, User } from '../models/user.model';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';

import { catchError, map, shareReplay, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService extends AbstractService implements OnDestroy {
  private readonly AUTH_BASE_PATH: string = `${environment.basePath}/auth`;
  private readonly API_PATHS = {
    login: `${this.AUTH_BASE_PATH}/login?post_login_url=${environment.postLoginUrl}`,
    logout: `${this.AUTH_BASE_PATH}/logout`,
    profile: `${this.AUTH_BASE_PATH}/profile`,
    profileWithPermissions: `${this.AUTH_BASE_PATH}/profile?permissions=true`,
  };

  private userProfileSubject: BehaviorSubject<User | null> = new BehaviorSubject<User | null>(null);
  private isAuthenticatedSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private subscriptions: Subscription[] = [];
  public isAuthenticated$: Observable<boolean> = this.isAuthenticatedSubject.asObservable();

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  public hasFailed: boolean = false;

  constructor(protected injector: Injector, private router: Router) {
    super(injector);
  }

  getUserProfile(): Observable<User> {
    return this.httpClient.get<IUserResponse>(this.API_PATHS.profileWithPermissions).pipe(
      map((user: IUserResponse) => new User(user)),
      tap((user: User) => {
        this.userProfileSubject.next(user);
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 500 || error.status === 503) {
          this.hasFailed = true;
          this.router.navigate(['/error', { errorMessage: `${error.status}: ${error.statusText}` }]);
        }
        return throwError(FetchUserProfileApiError);
      }),
      shareReplay()
    );
  }

  logoutUser(): Observable<any> {
    return this.httpClient.get<any>(this.API_PATHS.logout).pipe(
      catchError(() => {
        return throwError(LogoutUserApiError);
      })
    );
  }

  getLoggedInUser(): User | null {
    return this.userProfileSubject.getValue();
  }

  isSameUserSub(userSub: string): boolean {
    return this.userProfileSubject.getValue()?.sub === userSub;
  }

  getLoggedInUserAsObservable(): Observable<User | null> {
    return this.userProfileSubject;
  }

  updateLoggedInUserAndNotifyObservers(user: User): void {
    return this.userProfileSubject.next(user);
  }

  login(): void {
    const subscription = this.getUserProfile().subscribe(
      () => {
        if (!this.getLoggedInUser()?.permissions.userHasAllowedPermission()) {
          this.hasFailed = true;
          this.router.navigate(['/no-permissions']);
        }
        this.isAuthenticatedSubject.next(true);
      },
      (errorData: IFailure<AuthError.FETCH_USER_PROFILE_FAILED>) => {
        this.isAuthenticatedSubject.next(false);
        this.messageService.showError(errorData.reason);
      }
    );
    this.subscriptions.push(subscription);
  }

  resetUser() {
    this.userProfileSubject.next(null);
  }

  getLoginUrl(): string {
    return `${environment.apiUrl}/${this.API_PATHS.login}`;
  }
}
