import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ftd-chart-info-tile',
  styleUrls: ['./ftd-chart-info-tile.component.scss'],
  templateUrl: './ftd-chart-info-tile.component.html',
})
export class FtdChartInfoTileComponent {
  /**
   * Text value on top of the Tile
   */
  @Input() displayName!: string;

  /**
   * Value display in the middle of the Tile
   */
  @Input() displayValue!: string | number;

  /**
   * Value display next to displayValue
   */
  @Input() displaySubValue?: string | number;

  /**
   * Text value display at the bottom of the Tile
   */
  @Input() displayNameDetailsValue!: string | number;
  @Input() displayNameDetails!: string;

  /**
   * Display name detail's color
   */
  @Input() displayNameDetailsColor?: DisplayNameColor | keyof typeof DisplayNameColor;

  /**
   * Display selected series in the tiles
   */

  @Input() selectedSeries?: string;

  /**
   * Display in extended mode
   */
  @Input() isExtended?: boolean;

  /**
   * Text to display absolute value side on value
   */
  @Input() displayAbsValue?: number;

  /**
   * Text to display percentage value side on ratio value
   */
  @Input() displayRatioValue?: number;

  addPlusSign(value: number) {
    return value > 0 ? `+${value}` : value;
  }
}

export enum DisplayNameColor {
  white = 'white',
  red = 'red',
  green = 'green',
}
