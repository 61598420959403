import * as fs from 'file-saver';
import { ApprovalStatus, KeyofApprovalStatus } from '../../../matrix-view/enums/approval-status.enum';
import { BorderStyle, Workbook } from 'exceljs';
import { IExcelExportData, IExcelExportDataFilter } from '../../../matrix-view/models/excel-export-data.model';
import { Injectable } from '@angular/core';
import {
  bmwLogoImageBase64,
  columnTypesArray,
  filterTableParameters,
  generalColumnTypesArray,
} from './export-excel.helper';
import dayjs from 'dayjs';

@Injectable({
  providedIn: 'root',
})
export class ExportExcelService {
  workbook = new Workbook();
  worksheet = this.workbook.addWorksheet('FSM Excel Report');

  async exportExcel(excelData: { title: string; data: IExcelExportData; currency: string }): Promise<void> {
    this.workbook = new Workbook();
    this.worksheet = this.workbook.addWorksheet('FSM Excel Report');
    this.worksheet.getColumn(3).width = 30;

    this.createFiltersTable(excelData.data);
    this.createInfoTable(excelData.data, excelData.currency);
    this.addHeader();
    this.generateExcel(excelData.title);
  }

  addHeader(): void {
    const firstColumn = this.worksheet.getColumn(1);
    firstColumn.fill = {
      fgColor: { argb: 'FFFFFF' },
      pattern: 'solid',
      type: 'pattern',
    };
    firstColumn.width = 2;

    const firstRow = this.worksheet.getRow(1);
    firstRow.fill = {
      fgColor: { argb: '002060' },
      pattern: 'solid',
      type: 'pattern',
    };
    firstRow.height = 47;
    this.worksheet.getRow(2).fill = {
      fgColor: { argb: 'FFFFFF' },
      pattern: 'solid',
      type: 'pattern',
    };

    const myLogoImage = this.workbook.addImage({
      base64: bmwLogoImageBase64,
      extension: 'png',
    });
    this.worksheet.getCell(0, 1).alignment = { vertical: 'top' };
    this.worksheet.addImage(myLogoImage, {
      ext: { height: 32, width: 202 },
      tl: { col: 1.5, row: 0.3 },
    });
    this.worksheet.getColumn(2).width = 20;
  }

  addSecondHeaderTitle(title: string, cell: string, width: number): void {
    const headerCell = this.worksheet.getCell(cell);
    headerCell.value = title;
    headerCell.font = { bold: true, color: { argb: 'FFFFFF' }, size: 11 };
    this.worksheet.getColumn(cell.charAt(0)).width = width;
  }

  createOuterBorder(startRow: number, startCol: number, endRow: number, endCol: number, style: BorderStyle): void {
    for (let i = startRow; i <= endRow; i++) {
      const leftBorderCell = this.worksheet.getCell(i, startCol);
      const rightBorderCell = this.worksheet.getCell(i, endCol);
      leftBorderCell.border = {
        ...leftBorderCell.border,
        left: { color: { argb: '000000' }, style: style },
      };
      rightBorderCell.border = {
        ...rightBorderCell.border,
        right: { color: { argb: '000000' }, style: style },
      };
    }

    for (let i = startCol; i <= endCol; i++) {
      const topBorderCell = this.worksheet.getCell(startRow, i);
      const bottomBorderCell = this.worksheet.getCell(endRow, i);
      topBorderCell.border = {
        ...topBorderCell.border,
        top: { color: { argb: '000000' }, style: style },
      };
      bottomBorderCell.border = {
        ...bottomBorderCell.border,
        bottom: { color: { argb: '000000' }, style: style },
      };
    }
  }

  createFiltersTable(data: IExcelExportData): void {
    this.createOuterBorder(3, 2, 13, 3, 'thick');
    this.worksheet.getRow(3).height = 20;
    const filtersCell = this.worksheet.getCell('B3');
    filtersCell.value = 'Filters';
    filtersCell.font = { bold: true, color: { argb: 'FFFFFF' }, name: 'BMWGroupTNCondensedPro-Regular', size: 11 };
    filtersCell.alignment = { horizontal: 'center', vertical: 'middle' };
    this.fillCell('002060', 'B3');
    this.fillCell('002060', 'C3');
    let aux = 0;
    filterTableParameters.forEach((parameter) => {
      const cell = this.worksheet.getCell('B' + (aux + 4));
      cell.font = { bold: true, name: 'BMWGroupTNCondensedPro-Regular' };
      cell.value = parameter;
      cell.alignment = { indent: 1, vertical: 'middle' };
      aux = aux + 1;
    });
    this.populateFilters(data.filters);
  }

  createInfoTable(data: IExcelExportData, currency: string): void {
    const startRowOffset = 16;
    const startColOffset = 3;

    const row = this.worksheet.getRow(16);
    row.height = 20;
    row.alignment = { indent: 1, vertical: 'middle' };
    row.font = { name: 'BMWGroupTNCondensedPro-Regular' };

    this.worksheet.mergeCells('B16', 'C16');
    const vehicleModelsCell = this.worksheet.getCell('B16');
    vehicleModelsCell.value = 'Vehicle Models';
    vehicleModelsCell.font = { bold: true, color: { argb: 'FFFFFF' }, size: 11 };
    vehicleModelsCell.alignment = { horizontal: 'center', vertical: 'middle' };

    for (let i = 1; i <= columnTypesArray.length; i++) {
      this.fillCell('000000', '15', i + startColOffset);
      this.fillCell('002060', '16', i + startColOffset);
    }
    this.fillCell('002060', 'B16');
    this.fillCell('002060', 'C16');

    this.worksheet.getRow(17).height = 75;

    generalColumnTypesArray.forEach((type) => {
      this.addSecondHeaderTitle(type.title, type.cell, 15);
    });

    for (let i = 0; i < columnTypesArray.length; i++) {
      this.worksheet.mergeCells(17, i + 4, 18, i + 4);
      const cell = this.worksheet.getCell(17, i + 4);
      cell.value = columnTypesArray[i].header?.toString() || '';
      const column = this.worksheet.getColumn(i + 4);
      column.width = columnTypesArray[i].width;
      column.outlineLevel = columnTypesArray[i].outlineLevel!;
      cell.alignment = { horizontal: 'center', vertical: 'middle', wrapText: true };
    }

    const carModels = this.getNumberOfModels(data);
    for (let i = startRowOffset + 1; i < startRowOffset + carModels + 3; i++) {
      this.worksheet.getRow(i).font = { name: 'BMWGroupTNCondensedPro-Regular' };
      this.worksheet.mergeCells('B' + (i + 2), 'C' + (i + 2));
    }

    this.worksheet.getRow(startRowOffset - 1).font = {
      color: { argb: 'FFFFFF' },
      name: 'BMWGroupTNCondensedPro-Regular',
    };
    this.worksheet.getRow(startRowOffset).font = { color: { argb: 'FFFFFF' }, name: 'BMWGroupTNCondensedPro-Regular' };
    for (let i = startRowOffset + 1; i < startRowOffset + carModels + 3; i++) {
      this.worksheet.getRow(i).font = { name: 'BMWGroupTNCondensedPro-Regular' };
    }

    // Borders on the info table
    this.createOuterBorder(startRowOffset, 4, startRowOffset + 2 + carModels, 10, 'thick');
    this.createOuterBorder(startRowOffset, 11, startRowOffset + 2 + carModels, 21, 'thick');
    this.createOuterBorder(startRowOffset, 22, startRowOffset + 2 + carModels, 29, 'thick');
    this.createOuterBorder(startRowOffset, 30, startRowOffset + 2 + carModels, 35, 'thick');
    this.createOuterBorder(startRowOffset, 36, startRowOffset + 2 + carModels, 49, 'thick');
    this.createOuterBorder(startRowOffset, 50, startRowOffset + 2 + carModels, 59, 'thick');

    this.createOuterBorder(
      startRowOffset,
      2,
      startRowOffset + carModels + 2,
      startColOffset + columnTypesArray.length,
      'thick'
    );
    this.createOuterBorder(startRowOffset, 2, startRowOffset + 2, startColOffset + columnTypesArray.length, 'thick');

    this.populateInfoTable(data, currency);
  }

  fillCell(color: string, row: string, column?: number): void {
    let cell;
    if (!column) {
      cell = this.worksheet.getCell(row);
    } else {
      cell = this.worksheet.getCell(row, column);
    }
    cell.fill = {
      fgColor: {
        argb: color,
      },
      pattern: 'solid',
      type: 'pattern',
    };
  }

  generateExcel(title: string): void {
    this.workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx', { autoBom: false });
    });
  }

  getNumberOfModels(excelData: IExcelExportData): number {
    let aux = 0;
    excelData.matrixDataItems.forEach((eSeries) => {
      aux = aux + 1;
      aux = aux + eSeries.children.length;
    });
    return aux;
  }

  populateFilters(excelData: IExcelExportDataFilter): void {
    this.worksheet.getCell('C4').value = excelData.scenarioName;
    this.worksheet.getCell('C5').value = excelData.dateOfExport;
    this.worksheet.getCell('C6').value = excelData.market;
    const segmentCell = this.worksheet.getCell('C7');
    segmentCell.value = excelData.segment;
    segmentCell.alignment = { vertical: 'middle', wrapText: true };
    this.worksheet.getCell('C8').value = excelData.brand;
    const seriesCell = this.worksheet.getCell('C9');
    seriesCell.value = excelData.series;
    seriesCell.alignment = { vertical: 'middle', wrapText: true };
    const eSeriesCell = this.worksheet.getCell('C10');
    eSeriesCell.value = excelData.eSeries;
    eSeriesCell.alignment = { vertical: 'middle', wrapText: true };
    const modelCell = this.worksheet.getCell('C11');
    modelCell.value = excelData.model;
    modelCell.alignment = { vertical: 'middle', wrapText: true };
    const powertrainCell = this.worksheet.getCell('C12');
    powertrainCell.value = excelData.powertrain;
    powertrainCell.alignment = { vertical: 'middle', wrapText: true };
    this.worksheet.getCell('C13').value = excelData.priceEditorFilled;
  }

  formatCrosscheckTrafficLight(trafficLightString: string): string {
    if (trafficLightString) {
      const trafficLightJSON = JSON.parse(trafficLightString);
      if (trafficLightJSON.color) {
        if (trafficLightJSON.color === 'red') {
          return 'Deep dive recommended';
        } else if (trafficLightJSON.color === 'green') {
          return 'Crosscheck within threshold';
        } else {
          return '-';
        }
      }
    }
    return '-';
  }

  formatCrosscheckTrafficLightWithArrow(trafficLightString: string): string {
    if (trafficLightString) {
      const trafficLightJSON = JSON.parse(trafficLightString);
      if (trafficLightJSON.arrow) {
        switch (trafficLightJSON.arrow) {
          case 'up':
            return 'Increase price';
          case 'down':
            return 'Decrease price';
          case 'keep':
            return 'No Price change';
          default:
            return '-';
        }
      }
    }
    return '-';
  }

  formatDate(date: string): string {
    if (date) {
      return dayjs(date).format('YYYY MMM DD');
    }
    return '-';
  }

  formatCurrency(cell: string, price: string, currency: string): void {
    if (price) {
      this.worksheet.getCell(cell).value = parseFloat(price);
      if (currency) {
        this.worksheet.getCell(cell).numFmt = `#,0.00"${currency}"`;
      } else {
        this.worksheet.getCell(cell).numFmt = '#,0.00';
      }
    } else {
      this.worksheet.getCell(cell).value = '-';
    }
  }

  formatInteger(cell: string, price: string, currency: string = ''): void {
    if (price) {
      this.worksheet.getCell(cell).numFmt = `#,0"${currency}"`;
      this.worksheet.getCell(cell).value = parseFloat(price);
    } else {
      this.worksheet.getCell(cell).value = '-';
    }
  }

  formatPercentage(cell: string, price: string): void {
    if (price) {
      this.worksheet.getCell(cell).numFmt = '0 %';
      this.worksheet.getCell(cell).value = parseFloat(price) / 100;
    } else {
      this.worksheet.getCell(cell).value = '-';
    }
  }

  populateInfoTable(excelData: IExcelExportData, currency: string): void {
    let aux = 0;
    const startRowOffset = 19;
    excelData.matrixDataItems.forEach((eSeries) => {
      const seriesCell = this.worksheet.getCell('C' + (startRowOffset + aux));
      seriesCell.value = eSeries.vehicleEseries.vehicleModelCode;
      seriesCell.font = { bold: true, name: 'BMWGroupTNCondensedPro-Regular' };

      // Current Price
      this.formatCurrency(
        'D' + (startRowOffset + aux),
        eSeries.vehicleEseries.currentPricing.listPriceInclTax,
        currency
      );
      this.formatInteger(
        'E' + (startRowOffset + aux),
        eSeries.vehicleEseries.currentPricing.indicativeLeaseRate,
        currency
      );
      this.worksheet.getCell('F' + (startRowOffset + aux)).value = this.formatDate(
        eSeries.vehicleEseries.currentPricing.effectiveSince
      );
      this.formatInteger('G' + (startRowOffset + aux), eSeries.vehicleEseries.currentPricing.priceChangeCount);
      this.formatCurrency(
        'H' + (startRowOffset + aux),
        eSeries.vehicleEseries.currentPricing.typicallyEquippedPrice,
        currency
      );
      this.formatCurrency(
        'I' + (startRowOffset + aux),
        eSeries.vehicleEseries.currentPricing.typicallyEquippedLeaseRate,
        currency
      );
      this.formatCurrency(
        'J' + (startRowOffset + aux),
        eSeries.vehicleEseries.currentPricing.listPriceExclTax,
        currency
      );

      // Current Financials
      this.formatInteger('K' + (startRowOffset + aux), eSeries.vehicleEseries.currentFinancial.budgetVolumeRetail);
      this.formatInteger('L' + (startRowOffset + aux), eSeries.vehicleEseries.currentFinancial.forecastVolumeRetail);
      this.formatInteger('M' + (startRowOffset + aux), eSeries.vehicleEseries.currentFinancial.expectedVolumeRetail);
      this.formatInteger('N' + (startRowOffset + aux), eSeries.vehicleEseries.currentFinancial.retailVolumeActualAbs);
      this.formatPercentage(
        'O' + (startRowOffset + aux),
        eSeries.vehicleEseries.currentFinancial.retailVolumeAchievementPct
      );
      this.formatCurrency('P' + (startRowOffset + aux), eSeries.vehicleEseries.currentFinancial.budgetCmPerUnit, '€');
      this.formatCurrency(
        'Q' + (startRowOffset + aux),
        eSeries.vehicleEseries.currentFinancial.forecast1CmPerUnit,
        '€'
      );
      this.formatCurrency(
        'R' + (startRowOffset + aux),
        eSeries.vehicleEseries.currentFinancial.forecast2CmPerUnit,
        '€'
      );
      this.formatCurrency('S' + (startRowOffset + aux), eSeries.vehicleEseries.currentFinancial.currentCmPerUnit, '€');
      this.formatCurrency(
        'T' + (startRowOffset + aux),
        eSeries.vehicleEseries.currentFinancial.typicallyEquippedCurrentCmPerUnit,
        '€'
      );
      this.formatInteger('U' + (startRowOffset + aux), eSeries.vehicleEseries.currentFinancial.co2BaseCar);

      // Current Crosschecks
      this.worksheet.getCell('V' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.currentCrossCheck.salesPipelineTrafficLight
      );
      this.worksheet.getCell('W' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.currentCrossCheck.productionPipelineTrafficLight
      );
      this.worksheet.getCell('X' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.currentCrossCheck.stockTrafficLight
      );
      this.worksheet.getCell('Y' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.currentCrossCheck.competitionTrafficLight
      );
      this.worksheet.getCell('Z' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.currentCrossCheck.marketsTrafficLight
      );
      this.worksheet.getCell('AA' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.currentCrossCheck.portfolioTrafficLight
      );
      this.worksheet.getCell('AB' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.currentCrossCheck.channelsTrafficLight
      );
      this.worksheet.getCell('AC' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.currentCrossCheck.microMacroTrafficLight
      );
      // Price Engine Recommendation
      this.worksheet.getCell('AD' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLightWithArrow(
        eSeries.vehicleEseries.currentCrossCheck.summaryTrafficLight
      );
      this.formatInteger(
        'AE' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEngineRecommendation.recommendedPriceEngine,
        currency
      );
      this.formatCurrency(
        'AF' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEngineRecommendation.indicativeLeaseRateEngine,
        currency
      );
      this.formatInteger(
        'AG' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEngineRecommendation.typicallyEquippedPriceEngine,
        currency
      );
      this.formatCurrency(
        'AH' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEngineRecommendation.typicallyEquippedLeaseRateEngine,
        currency
      );

      this.formatCurrency(
        'AI' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEngineRecommendation.deltaCurrentPriceEngineAbs,
        currency
      );

      // Price Editor
      this.formatCurrency('AJ' + (startRowOffset + aux), eSeries.vehicleEseries.priceEditor.pricePointPrice, currency);
      // This is where percentage goes. ATM it's just showing '-'. this.formatPercentage('AK' + (startRowOffset + aux), series.priceEditor., currency);
      this.worksheet.getCell('AK' + (startRowOffset + aux)).value = '-';
      this.worksheet.getCell('AL' + (startRowOffset + aux)).value = this.formatDate(
        eSeries.vehicleEseries.priceEditor.effectiveDate
      );
      this.worksheet.getCell('AM' + (startRowOffset + aux)).value =
        eSeries.vehicleEseries.priceEditor.adjustedListPriceReasonCode || '-';
      this.formatInteger(
        'AN' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEditor.recommendedRetailPrice,
        currency
      );
      this.formatInteger(
        'AO' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEditor.recommendedRetailPriceValidFromDate,
        currency
      );
      this.worksheet.getCell('AP' + (startRowOffset + aux)).value =
        eSeries.vehicleEseries.priceEditor.recommendedRetailPriceReasonCode || '-';
      this.formatCurrency(
        'AQ' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEditor.indicativeLeaseRateAdj,
        currency
      );
      this.formatCurrency(
        'AR' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEditor.typicallyEquippedPriceAdj,
        currency
      );
      this.worksheet.getCell('AS' + (startRowOffset + aux)).value =
        ApprovalStatus[eSeries.vehicleEseries.priceEditor.typicallyEquippedLeaseRateAdj as KeyofApprovalStatus] || '-';
      this.formatPercentage('AT' + (startRowOffset + aux), eSeries.vehicleEseries.priceEditor.deltaCurrentPriceEditor);
      this.formatInteger(
        'AU' + (startRowOffset + aux),
        eSeries.vehicleEseries.priceEditor.deltaCurrentIndicativeLeaseRate,
        currency
      );
      this.formatInteger('AV' + (startRowOffset + aux), eSeries.vehicleEseries.priceEditor.listPriceExclTaxAdj, '');
      this.worksheet.getCell('AW' + (startRowOffset + aux)).value =
        ApprovalStatus[eSeries.vehicleEseries.priceEditor.status as KeyofApprovalStatus] || '-';

      // Forecasted Financials
      this.formatInteger(
        'AX' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.expectedVolumeRetailAdj
      );
      this.formatInteger(
        'AY' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.deltaExpectedVolumeRetailCurrentAdj
      );
      this.formatInteger(
        'AZ' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.forecastVolumeRetail
      );
      this.formatInteger(
        'BA' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.budgetVolumeRetailNextYear
      );
      this.formatInteger(
        'BB' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.forecastLatestCmPerUnit
      );
      this.formatInteger(
        'BC' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.budgetNextYearCmPerUnit
      );
      this.formatCurrency(
        'BD' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.currentCmPerUnit,
        '€'
      );
      this.formatCurrency(
        'BE' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.adjustedCmPerUnit,
        '€'
      );
      this.formatCurrency(
        'BF' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.deltaCmPerUnit,
        '€'
      );
      this.formatCurrency(
        'BG' + (startRowOffset + aux),
        eSeries.vehicleEseries.forecastedFinancial.deltaTotalCmAbs,
        '€'
      );

      // Forecasted Crosschecks
      this.worksheet.getCell('BH' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.forecastedCrosscheck.salesPipelineTrafficLightAdj
      );
      this.worksheet.getCell('BI' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.forecastedCrosscheck.productionPipelineTrafficLightAdj
      );
      this.worksheet.getCell('BJ' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.forecastedCrosscheck.stockTrafficLightAdj
      );
      this.worksheet.getCell('BK' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.forecastedCrosscheck.competitionTrafficLightAdj
      );
      this.worksheet.getCell('BL' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.forecastedCrosscheck.marketsTrafficLightAdj
      );
      this.worksheet.getCell('BM' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.forecastedCrosscheck.portfolioTrafficLightAdj
      );
      this.worksheet.getCell('BN' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.forecastedCrosscheck.channelsTrafficLightAdj
      );
      this.worksheet.getCell('BO' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
        eSeries.vehicleEseries.forecastedCrosscheck.microMacroTrafficLightAdj
      );
      aux = aux + 1;

      eSeries.children.forEach((series) => {
        const seriesCell = this.worksheet.getCell('C' + (startRowOffset + aux));
        seriesCell.alignment = { indent: 1 };
        seriesCell.value = series.vehicleModelCode;
        this.worksheet.getRow(aux + startRowOffset).outlineLevel = 1;

        // Current Price
        this.formatCurrency('D' + (startRowOffset + aux), series.currentPricing.listPriceInclTax, currency);
        this.formatInteger('E' + (startRowOffset + aux), series.currentPricing.indicativeLeaseRate, currency);
        this.worksheet.getCell('F' + (startRowOffset + aux)).value = this.formatDate(
          series.currentPricing.effectiveSince
        );
        this.formatInteger('G' + (startRowOffset + aux), series.currentPricing.priceChangeCount);
        this.formatCurrency('H' + (startRowOffset + aux), series.currentPricing.typicallyEquippedPrice, currency);
        this.formatCurrency('I' + (startRowOffset + aux), series.currentPricing.typicallyEquippedLeaseRate, currency);
        this.formatCurrency('J' + (startRowOffset + aux), series.currentPricing.listPriceExclTax, currency);

        // Current Financials
        this.formatInteger('K' + (startRowOffset + aux), series.currentFinancial.budgetVolumeRetail);
        this.formatInteger('L' + (startRowOffset + aux), series.currentFinancial.forecastVolumeRetail);
        this.formatInteger('M' + (startRowOffset + aux), series.currentFinancial.expectedVolumeRetail);
        this.formatInteger('N' + (startRowOffset + aux), series.currentFinancial.retailVolumeActualAbs);
        this.formatPercentage('O' + (startRowOffset + aux), series.currentFinancial.retailVolumeAchievementPct);
        this.formatCurrency('P' + (startRowOffset + aux), series.currentFinancial.budgetCmPerUnit, '€');
        this.formatCurrency('Q' + (startRowOffset + aux), series.currentFinancial.forecast1CmPerUnit, '€');
        this.formatCurrency('R' + (startRowOffset + aux), series.currentFinancial.forecast2CmPerUnit, '€');
        this.formatCurrency('S' + (startRowOffset + aux), series.currentFinancial.currentCmPerUnit, '€');
        this.formatCurrency(
          'T' + (startRowOffset + aux),
          series.currentFinancial.typicallyEquippedCurrentCmPerUnit,
          '€'
        );
        this.formatInteger('U' + (startRowOffset + aux), series.currentFinancial.co2BaseCar);

        // Current Crosschecks
        this.worksheet.getCell('V' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.currentCrossCheck.salesPipelineTrafficLight
        );
        this.worksheet.getCell('W' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.currentCrossCheck.productionPipelineTrafficLight
        );
        this.worksheet.getCell('X' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.currentCrossCheck.stockTrafficLight
        );
        this.worksheet.getCell('Y' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.currentCrossCheck.competitionTrafficLight
        );
        this.worksheet.getCell('Z' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.currentCrossCheck.marketsTrafficLight
        );
        this.worksheet.getCell('AA' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.currentCrossCheck.portfolioTrafficLight
        );
        this.worksheet.getCell('AB' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.currentCrossCheck.channelsTrafficLight
        );
        this.worksheet.getCell('AC' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.currentCrossCheck.microMacroTrafficLight
        );

        // Price Engine Recommendation
        this.worksheet.getCell('AD' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLightWithArrow(
          series.currentCrossCheck.summaryTrafficLight
        );
        this.formatInteger(
          'AE' + (startRowOffset + aux),
          series.priceEngineRecommendation.recommendedPriceEngine,
          currency
        );
        this.formatCurrency(
          'AF' + (startRowOffset + aux),
          series.priceEngineRecommendation.indicativeLeaseRateEngine,
          currency
        );
        this.formatInteger(
          'AG' + (startRowOffset + aux),
          series.priceEngineRecommendation.typicallyEquippedPriceEngine,
          currency
        );
        this.formatCurrency(
          'AH' + (startRowOffset + aux),
          series.priceEngineRecommendation.typicallyEquippedLeaseRateEngine,
          currency
        );

        this.formatCurrency(
          'AI' + (startRowOffset + aux),
          series.priceEngineRecommendation.deltaCurrentPriceEngineAbs,
          currency
        );

        // Price Editor
        this.formatCurrency('AJ' + (startRowOffset + aux), series.priceEditor.pricePointPrice, currency);
        // This is where percentage goes. ATM it's just showing '-'. this.formatPercentage('AK' + (startRowOffset + aux), series.priceEditor., currency);
        this.worksheet.getCell('AK' + (startRowOffset + aux)).value = '-';
        this.worksheet.getCell('AL' + (startRowOffset + aux)).value = this.formatDate(series.priceEditor.effectiveDate);
        this.worksheet.getCell('AM' + (startRowOffset + aux)).value =
          series.priceEditor.adjustedListPriceReasonCode || '-';
        this.formatInteger('AN' + (startRowOffset + aux), series.priceEditor.recommendedRetailPrice, currency);
        this.formatInteger(
          'AO' + (startRowOffset + aux),
          series.priceEditor.recommendedRetailPriceValidFromDate,
          currency
        );
        this.worksheet.getCell('AP' + (startRowOffset + aux)).value =
          series.priceEditor.recommendedRetailPriceReasonCode || '-';
        this.formatCurrency('AQ' + (startRowOffset + aux), series.priceEditor.indicativeLeaseRateAdj, currency);
        this.formatCurrency('AR' + (startRowOffset + aux), series.priceEditor.typicallyEquippedPriceAdj, currency);
        this.worksheet.getCell('AS' + (startRowOffset + aux)).value =
          ApprovalStatus[series.priceEditor.typicallyEquippedLeaseRateAdj as KeyofApprovalStatus] || '-';
        this.formatPercentage('AT' + (startRowOffset + aux), series.priceEditor.deltaCurrentPriceEditor);
        this.formatInteger('AU' + (startRowOffset + aux), series.priceEditor.deltaCurrentIndicativeLeaseRate, currency);
        this.formatInteger('AV' + (startRowOffset + aux), series.priceEditor.listPriceExclTaxAdj, '');
        this.worksheet.getCell('AW' + (startRowOffset + aux)).value =
          ApprovalStatus[series.priceEditor.status as KeyofApprovalStatus] || '-';

        // Forecasted Financials
        this.formatInteger('AX' + (startRowOffset + aux), series.forecastedFinancial.expectedVolumeRetailAdj);
        this.formatInteger(
          'AY' + (startRowOffset + aux),
          series.forecastedFinancial.deltaExpectedVolumeRetailCurrentAdj
        );
        this.formatInteger('AZ' + (startRowOffset + aux), series.forecastedFinancial.forecastVolumeRetail);
        this.formatInteger('BA' + (startRowOffset + aux), series.forecastedFinancial.budgetVolumeRetailNextYear);
        this.formatInteger('BB' + (startRowOffset + aux), series.forecastedFinancial.forecastLatestCmPerUnit);
        this.formatInteger('BC' + (startRowOffset + aux), series.forecastedFinancial.budgetNextYearCmPerUnit);
        this.formatCurrency('BD' + (startRowOffset + aux), series.forecastedFinancial.currentCmPerUnit, '€');
        this.formatCurrency('BE' + (startRowOffset + aux), series.forecastedFinancial.adjustedCmPerUnit, '€');
        this.formatCurrency('BF' + (startRowOffset + aux), series.forecastedFinancial.deltaCmPerUnit, '€');
        this.formatCurrency('BG' + (startRowOffset + aux), series.forecastedFinancial.deltaTotalCmAbs, '€');

        // Forecasted Crosschecks
        this.worksheet.getCell('BH' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.forecastedCrosscheck.salesPipelineTrafficLightAdj
        );
        this.worksheet.getCell('BI' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.forecastedCrosscheck.productionPipelineTrafficLightAdj
        );
        this.worksheet.getCell('BJ' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.forecastedCrosscheck.stockTrafficLightAdj
        );
        this.worksheet.getCell('BK' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.forecastedCrosscheck.competitionTrafficLightAdj
        );
        this.worksheet.getCell('BL' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.forecastedCrosscheck.marketsTrafficLightAdj
        );
        this.worksheet.getCell('BM' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.forecastedCrosscheck.portfolioTrafficLightAdj
        );
        this.worksheet.getCell('BN' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.forecastedCrosscheck.channelsTrafficLightAdj
        );
        this.worksheet.getCell('BO' + (startRowOffset + aux)).value = this.formatCrosscheckTrafficLight(
          series.forecastedCrosscheck.microMacroTrafficLightAdj
        );
        aux = aux + 1;
      });
    });
  }
}
