<mat-dialog-content id="custom-modal-body">
  <ng-content></ng-content>
  <br />
  <div class="mt-3 fade-in" *ngIf="isRequestProcessing">
    <div fxLayout="column">
      <b>
        {{ requestProcessingMessage }}
      </b>
      <br />
      <div fxLayout="row" fxLayoutAlign="center center">
        <app-loading [diameter]="20" [color]="'white'" class="mr-2"></app-loading>
        <b> Wait just a few seconds... </b>
      </div>
    </div>
  </div>
</mat-dialog-content>
