import { AuthService } from 'src/app/auth/services/auth.service';
import { Base64Utils } from 'src/app/common/utils/base64.utils';
import { Brand } from 'src/app/matrix-view/models/brand.model';
import { ChartAxisType } from '../../../../common/components/ftd-charts/enums/ftd-generic-chart.enum';
import { ChartLegendType } from '../../../../common/components/ftd-charts/enums/chart-legend-type.enum';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConsideredPriceType } from '../../../enums/crosschecks-chart-dropdowns-type.enum';
import { ContextService } from '../../../../matrix-view/services/context/context.service';
import { CrosschecksChartContext } from 'src/app/crosschecks/enums/crosschecks-chart-context-messages.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { GenericChartFields } from '../../../../common/components/ftd-charts/models/ftd-generic-chart.model';
import { GranularityService } from '../../../../matrix-view/services/granularity/granularity.service';
import { GranularityType } from 'src/app/matrix-view/enums/granularity-type.enum';
import { IChartLegendConfig } from '../../../../common/components/ftd-charts/models/ftd-chart-legend-config.model';
import { IFtdDropdownOption, IFtdDropdownOptionsGroup } from 'src/app/common/models/ftd-dropdown-option.model';
import { IGranularity } from 'src/app/matrix-view/models/app.model';
import {
  IGranularityDto,
  IInternalPriceLadderChartData,
  IInternalPriceLadderResponse,
  IInternalPriceLadderResponseData,
} from 'src/app/matrix-view/models/api.model';
import { ILineChartData } from '../../../../common/components/ftd-charts/models/ftd-line-chart.model';
import { IPriceRange } from '../../../../common/models/price-range.model';
import { LineChartPricePointColor } from '../../../../common/components/ftd-charts/enums/line-chart-price-point-color.enum';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { PortfolioChartSubtitleMap } from '../../../helpers/portfolio/portfolio-chart-subtitle-map.helper';
import { PortfolioInternalPriceVehicleSpecsType } from '../../../enums/portfolio-internal-price-vehicle-specs-type.enum';
import { SideFiltersService } from '../../../../common/services/side-filter/side-filter.service';
import { TrafficLightColor } from 'src/app/crosschecks/enums/traffic-light-color.enum';
import { TrafficLightsColor } from 'src/app/crosschecks/types/traffic-lights.type';
import { User } from 'src/app/auth/models/user.model';
import { currencyMapHelper } from 'src/app/crosschecks/helpers/currency.helper';
import cloneDeep from 'lodash.clonedeep';
import flatten from 'lodash.flatten';
import uniqWith from 'lodash.uniqwith';

@Component({
  selector: 'app-internal-price-ladder',
  styleUrls: ['./internal-price-ladder.component.scss'],
  templateUrl: './internal-price-ladder.component.html',
})

/**
 * @class InternalPriceLadderComponent
 */
export class InternalPriceLadderComponent implements OnInit, OnDestroy {
  /**
   * Granularity for which graph data need to be fetched
   */
  filters!: IGranularityDto;
  private _granularity: IGranularityDto = <IGranularityDto>{};
  @Input() set granularity(granularityData: string) {
    this._granularity = Base64Utils.decodeAtobToJson(granularityData);
    this.filters = this._granularity;
    this.currency = currencyMapHelper.get(this._granularity.market) as string;
    this.loadInternalPriceLadder();
  }

  /**
   * Traffic light value coming from overview request on Portfolio
   */
  @Input() crosscheckIndication?: TrafficLightsColor;
  @Input() crosscheckIndicationForecasted?: TrafficLightsColor;

  protected readonly ChartAxisType = ChartAxisType;
  crosschecksChartContext: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  initChart: boolean = false;
  chartData!: ILineChartData;
  unfilteredChartFields: IInternalPriceLadderChartData[] = [];
  isChartValuesVisible: boolean = false;
  loadedData: { segment: string; data: IInternalPriceLadderResponseData[] }[] = [];

  isEditRulesButtonEnabled: boolean = false;

  public currentUser!: User | null;
  private subscriptions: Subscription[] = [];
  filtersForm!: FormGroup;
  segmentList: IFtdDropdownOption<IGranularity>[] = [];
  currentSelectedSegmentFilter: string[] = [];
  eSeriesList: IFtdDropdownOptionsGroup<IGranularity>[] = [];
  minAndMaxPossiblePriceRange!: IPriceRange;
  minAndMaxFilter!: IPriceRange;
  currency!: string;

  // Considered price filter
  get selectedConsideredPriceType(): ConsideredPriceType {
    return this.filtersForm?.get('consideredPriceType')?.value;
  }

  consideredPriceTypeOptionList: IFtdDropdownOption<ConsideredPriceType>[] = [
    {
      id: ConsideredPriceType.CURRENT_PRICE,
      label: 'Current prices | All models',
      value: ConsideredPriceType.CURRENT_PRICE,
    },
    {
      id: ConsideredPriceType.PROPOSED_PRICE,
      label: 'Scenario prices | All models',
      value: ConsideredPriceType.PROPOSED_PRICE,
    },
    {
      id: ConsideredPriceType.CURRENT_AND_PROPOSED_PRICE,
      label: 'Current & Scenario prices | All models',
      value: ConsideredPriceType.CURRENT_AND_PROPOSED_PRICE,
    },
  ];

  // Vehicle Type filter
  vehicleSpecsTypeOptionList: IFtdDropdownOption<PortfolioInternalPriceVehicleSpecsType>[] = [
    {
      id: PortfolioInternalPriceVehicleSpecsType.BASE_CAR,
      label: 'Base car',
      value: PortfolioInternalPriceVehicleSpecsType.BASE_CAR,
    },
    {
      id: PortfolioInternalPriceVehicleSpecsType.TYPICALLY_EQUIPPED,
      label: 'Typically equipped\n',
      value: PortfolioInternalPriceVehicleSpecsType.TYPICALLY_EQUIPPED,
    },
  ];

  get selectedVehicleType(): PortfolioInternalPriceVehicleSpecsType {
    return this.filtersForm?.get('vehicleSpecsType')?.value;
  }

  CHART_SUBTITLE_MAP = PortfolioChartSubtitleMap;

  chartLegendsConfigMap = new Map<PortfolioInternalPriceVehicleSpecsType, IChartLegendConfig[]>([
    [
      PortfolioInternalPriceVehicleSpecsType.BASE_CAR,
      [
        {
          fieldName: 'correct_price_point',
          imagePath: './assets/images/charts/bullets/price-point-green.svg',
          label: 'Correct Price Point',
          legendColor: '',
          legendImageHeight: 18,
          legendImageWidth: 18,
          type: ChartLegendType.IMAGE,
        },
        {
          fieldName: 'incorrect_price_point',
          imagePath: './assets/images/charts/bullets/price-point-red.svg',
          label: 'Incorrect Price Point',
          legendColor: '',
          legendImageHeight: 18,
          legendImageWidth: 18,
          type: ChartLegendType.IMAGE,
        },
      ],
    ],
    [
      PortfolioInternalPriceVehicleSpecsType.TYPICALLY_EQUIPPED,
      [
        {
          fieldName: 'typically_equipped_price_point',
          imagePath: './assets/images/charts/bullets/price-point-light_gray.svg',
          label: 'Price Point for typically equipped vehicle',
          legendColor: '',
          legendImageHeight: 18,
          legendImageWidth: 18,
          type: ChartLegendType.IMAGE,
        },
      ],
    ],
  ]);

  /**
   * @constructor
   * @param authService
   * @param formBuilder
   * @param crosscheckService
   * @param contextService
   * @param granularityService
   * @param sideFiltersService
   */
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private crosscheckService: CrosschecksService,
    private contextService: ContextService,
    private granularityService: GranularityService,
    private sideFiltersService: SideFiltersService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    if (this.granularityService.getGranularityList()?.length) {
      this.initSegmentAndESeriesFiltersOptions();
      this.initForm();
    } else {
      const subscription = this.sideFiltersService
        .getFiltersForMarket(this.filters.market, [Brand.BMW, Brand.MINI])
        .subscribe(() => {
          this.initSegmentAndESeriesFiltersOptions();
          this.initForm();
        });
      this.subscriptions.push(subscription);
    }
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * InitForm
   * @param value
   */
  private initForm(): void {
    this.filtersForm = this.formBuilder.group({
      consideredPriceType: new FormControl<ConsideredPriceType>({
        disabled: false,
        value: ConsideredPriceType.CURRENT_PRICE,
      }),
      eSeries: new FormControl<IGranularity[] | null>({
        disabled: false,
        value: flatten(this.eSeriesList.map((item: IFtdDropdownOptionsGroup<IGranularity>) => item.options)).flatMap(
          (item: IFtdDropdownOption<IGranularity>) => item.value
        ),
      }),
      segment: new FormControl<IGranularity[] | null>({
        disabled: false,
        value: [this.segmentList.find((item) => item.value?.segment === this.filters.segment)?.value as any],
      }),
      vehicleSpecsType: new FormControl<PortfolioInternalPriceVehicleSpecsType>({
        disabled: false,
        value: PortfolioInternalPriceVehicleSpecsType.BASE_CAR,
      }),
    });

    this.currentSelectedSegmentFilter = this.getSelectedSegments();

    const consideredPriceSpecsValueChangeSubscription =
      this.filtersForm.controls.consideredPriceType.valueChanges.subscribe((value) => {
        this.setChartData(this.getSelectedSegments(), this.getSelectedESeries());
      });
    this.subscriptions.push(consideredPriceSpecsValueChangeSubscription);

    const vehicleSpecsValueChangeSubscription = this.filtersForm.controls.vehicleSpecsType.valueChanges.subscribe(
      (value) => {
        this.setChartData(this.getSelectedSegments(), this.getSelectedESeries());
      }
    );
    this.subscriptions.push(vehicleSpecsValueChangeSubscription);

    const eSeriesValueChangeSubscription = this.filtersForm.controls.eSeries.valueChanges.subscribe((value) => {
      this.setChartData(this.getSelectedSegments(), this.getSelectedESeries());
    });
    this.subscriptions.push(eSeriesValueChangeSubscription);
  }

  /**
   * InitSegmentAndESeriesFiltersOptions
   * @private
   */
  private initSegmentAndESeriesFiltersOptions(): void {
    this.segmentList = uniqWith(
      this.granularityService
        .getGranularityList()
        .filter((item) => item.type === GranularityType.SEGMENT)
        .map((item) => {
          return {
            id: item.id,
            label: item.segment,
            value: item,
          };
        }),
      (a, b) => a.label === b.label
    );

    const bmwESeriesList: IFtdDropdownOption<IGranularity>[] = this.granularityService
      .getGranularityList()
      .filter(
        (item) =>
          item.type === GranularityType.E_SERIES && item.brand === Brand.BMW && item.segment === this.filters.segment
      )
      .map((item) => {
        return {
          id: item.id,
          label: item.eSeries,
          value: item,
        };
      });

    const miniESeriesList: IFtdDropdownOption<IGranularity>[] = this.granularityService
      .getGranularityList()
      .filter(
        (item) =>
          item.type === GranularityType.E_SERIES && item.brand === Brand.MINI && item.segment === this.filters.segment
      )
      .map((item) => {
        return {
          id: item.id,
          label: item.eSeries,
          value: item,
        };
      });

    if (bmwESeriesList.length) {
      this.eSeriesList.push({
        id: Brand.BMW,
        label: Brand.BMW,
        options: bmwESeriesList,
      });
    }

    if (miniESeriesList.length) {
      this.eSeriesList.push({
        id: Brand.MINI,
        label: Brand.MINI,
        options: miniESeriesList,
      });
    }
  }

  /**
   * UpdateSelectedSegmentsFilters
   */
  updateSelectedSegmentsFilters(): void {
    const addedSegments: string[] = [];
    const selectedSegments = this.getSelectedSegments();

    // Find added segments to current filter
    selectedSegments.forEach((segment) => {
      if (!this.currentSelectedSegmentFilter.includes(segment)) {
        addedSegments.push(segment);
      }
    });

    // Find from the added segments which ones are new and required data fetch
    const newSegments: IGranularity[] = [];

    addedSegments.forEach((segment) => {
      if (!this.loadedData.find((data) => data.segment === segment)) {
        newSegments.push(
          this.filtersForm.controls.segment.value.find((filter: IGranularity) => filter.segment === segment)
        );
      }
    });

    // Update current segments
    this.currentSelectedSegmentFilter = selectedSegments;

    if (newSegments.length) {
      this.updateAndFetchInternalPriceLadderNewData(newSegments, addedSegments);
    } else {
      this.updateESeriesFiltersOptions(addedSegments);
    }
  }

  /**
   * UpdateESeriesFiltersOptions
   * @private
   * @param segments
   */
  private updateESeriesFiltersOptions(segments: string[]): void {
    const bmwESeriesList: IFtdDropdownOption<IGranularity>[] = this.granularityService
      .getGranularityList()
      .filter(
        (item) => item.type === GranularityType.E_SERIES && item.brand === Brand.BMW && segments.includes(item.segment)
      )
      .map((item) => {
        return {
          id: item.id,
          label: item.eSeries,
          value: item,
        };
      });

    const miniESeriesList: IFtdDropdownOption<IGranularity>[] = this.granularityService
      .getGranularityList()
      .filter(
        (item) => item.type === GranularityType.E_SERIES && item.brand === Brand.MINI && segments.includes(item.segment)
      )
      .map((item) => {
        return {
          id: item.id,
          label: item.eSeries,
          value: item,
        };
      });

    // Filter out removed segments
    this.eSeriesList.forEach((optionGroup: IFtdDropdownOptionsGroup<IGranularity>) => {
      optionGroup.options = optionGroup.options.filter((option: IFtdDropdownOption<IGranularity>) =>
        this.currentSelectedSegmentFilter.includes(option.value.segment)
      );
    });
    // For loop to remove brand if no options availabel for selected segments
    for (let index = this.eSeriesList.length - 1; index >= 0; index -= 1) {
      if (this.eSeriesList[index].options.length === 0) {
        this.eSeriesList.splice(index, 1);
      }
    }
    const newSelectedESeries: IGranularity[] = this.filtersForm.controls.eSeries.value.filter((eSeries: IGranularity) =>
      this.currentSelectedSegmentFilter.includes(eSeries.segment)
    );

    if (bmwESeriesList.length) {
      newSelectedESeries.push(...bmwESeriesList.map((eSeriesOption) => eSeriesOption.value));
      const bmwOptionGroup = this.eSeriesList.find(
        (optionGroup: IFtdDropdownOptionsGroup<IGranularity>) => optionGroup.id === Brand.BMW
      );
      if (!bmwOptionGroup) {
        this.eSeriesList.push({
          id: Brand.BMW,
          label: Brand.BMW,
          options: bmwESeriesList,
        });
      } else {
        bmwOptionGroup.options.push(...bmwESeriesList);
      }
    }

    if (miniESeriesList.length) {
      newSelectedESeries.push(...miniESeriesList.map((eSeriesOption) => eSeriesOption.value));
      const miniOptionGroup = this.eSeriesList.find(
        (optionGroup: IFtdDropdownOptionsGroup<IGranularity>) => optionGroup.id === Brand.MINI
      );
      if (!miniOptionGroup) {
        this.eSeriesList.push({
          id: Brand.MINI,
          label: Brand.MINI,
          options: miniESeriesList,
        });
      } else {
        miniOptionGroup.options.push(...miniESeriesList);
      }
    }
    this.filtersForm.controls.eSeries.patchValue(newSelectedESeries);
  }

  /**
   * HandlePriceRangeAppliedAndRefreshChartData
   * @param selectedPriceRange
   */
  handlePriceRangeAppliedAndRefreshChartData(selectedPriceRange: IPriceRange): void {
    this.minAndMaxFilter = {
      maxPrice: selectedPriceRange.maxPrice,
      minPrice: selectedPriceRange.minPrice,
    };
    this.chartData = {
      ...this.chartData,
      fields: cloneDeep(this.unfilteredChartFields).filter((field) => {
        const value = field['value'] as number;
        return value >= selectedPriceRange.minPrice && value <= selectedPriceRange.maxPrice;
      }),
    };
  }

  /**
   * ApplyDefaultPriceRange
   * @private
   * @param fields
   */
  private applyDefaultPriceRange(fields: GenericChartFields[]): void {
    const fieldsValues: number[] = fields.map((field) => field['value'] as number);
    this.minAndMaxPossiblePriceRange = {
      maxPrice: Math.max(...fieldsValues),
      minPrice: Math.min(...fieldsValues) < 0 ? 0 : Math.min(...fieldsValues),
    };

    this.minAndMaxFilter = {
      maxPrice: this.minAndMaxPossiblePriceRange.maxPrice,
      minPrice: this.minAndMaxPossiblePriceRange.minPrice,
    };
  }

  /**
   * GetSelectedESeries
   * @private
   * @returns string[]
   */
  private getSelectedESeries(): string[] {
    return this.filtersForm.controls.eSeries.value.map((value: IGranularity) => value.eSeries);
  }

  /**
   * GetSelectedESeries
   * @private
   * @returns string[]
   */
  private getSelectedSegments(): string[] {
    return this.filtersForm.controls.segment.value.map((value: IGranularity) => value.segment);
  }

  /**
   * LoadOrderIntakeData
   * @private
   */
  private loadInternalPriceLadder(): void {
    if (this._granularity.market) {
      this.crosschecksChartContext = CrosschecksChartContext.LOADING;
      const subscription: Subscription = this.crosscheckService
        .getPortfolioPriceLadderData(this._granularity.id!, this._granularity.market, this.contextService.scenarioId)
        .subscribe({
          error: (): void => {
            this.crosschecksChartContext = CrosschecksChartContext.ERROR;
          },
          next: (response: IInternalPriceLadderResponse[]): void => {
            this.transformData(response);
            this.setChartData(this.getSelectedSegments(), this.getSelectedESeries());
          },
        });
      this.subscriptions.push(subscription);
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
    }
  }

  /**
   * UpdateAndFetchInternalPriceLadderNewData
   * @private
   * @param addedSegments
   * @param segments
   */
  private updateAndFetchInternalPriceLadderNewData(segments: IGranularity[], addedSegments: string[]): void {
    const observableArray: Observable<IInternalPriceLadderResponse[]>[] = [];
    segments.forEach((segment) => {
      observableArray.push(
        this.crosscheckService.getPortfolioPriceLadderData(
          segment.id,
          this._granularity.market,
          this.contextService.scenarioId
        )
      );
    });
    this.crosschecksChartContext = CrosschecksChartContext.LOADING;
    const subscription = forkJoin(observableArray).subscribe({
      error: (): void => {
        this.crosschecksChartContext = CrosschecksChartContext.ERROR;
      },
      next: (response: IInternalPriceLadderResponse[][]): void => {
        response.forEach((data: IInternalPriceLadderResponse[]) => {
          this.transformData(data);
        });
        this.updateESeriesFiltersOptions(addedSegments);
      },
    });

    this.subscriptions.push(subscription);
  }

  /**
   * GetPricePointColor
   * @private
   * @param isCorrectPricePoint
   * @returns LineChartPricePointColor
   */
  private getPricePointColor(isCorrectPricePoint: boolean): LineChartPricePointColor {
    switch (this.selectedVehicleType) {
      case PortfolioInternalPriceVehicleSpecsType.BASE_CAR:
        return isCorrectPricePoint ? LineChartPricePointColor.GREEN : LineChartPricePointColor.RED;
      case PortfolioInternalPriceVehicleSpecsType.TYPICALLY_EQUIPPED:
        return LineChartPricePointColor.LIGHT_GRAY;
      default:
        return LineChartPricePointColor.RED;
    }
  }

  /**
   * GetPricePointDisplayValue
   * @private
   * @param item
   * @param consideredPrice
   * @returns number | undefined
   */
  private getPricePointDisplayValue(
    item: IInternalPriceLadderResponseData,
    consideredPrice: ConsideredPriceType
  ): number | undefined {
    switch (this.selectedVehicleType) {
      case PortfolioInternalPriceVehicleSpecsType.BASE_CAR: {
        switch (consideredPrice) {
          case ConsideredPriceType.CURRENT_PRICE:
          case ConsideredPriceType.PROPOSED_PRICE:
            return item.listPriceInclTaxAdj;
          default:
            return item.listPriceInclTax;
        }
      }
      case PortfolioInternalPriceVehicleSpecsType.TYPICALLY_EQUIPPED: {
        switch (consideredPrice) {
          case ConsideredPriceType.CURRENT_PRICE:
          case ConsideredPriceType.PROPOSED_PRICE:
            return item.typicallyEquippedPriceAdj;
          default:
            return item.typicallyEquippedPrice;
        }
      }
    }
  }

  /**
   * SetChartData
   * @private
   * @param segments
   * @param eSeries
   */
  private setChartData(segments: string[], eSeries: string[]): void {
    this.unfilteredChartFields = [];
    const currentData: IInternalPriceLadderResponseData[] = [];
    segments?.forEach((segment) => {
      const data = this.loadedData.find(
        (data: { segment: string; data: IInternalPriceLadderResponseData[] }) => data.segment === segment
      )?.data;
      if (data) {
        currentData.push(...data);
      }
    });
    currentData
      ?.filter((item: IInternalPriceLadderResponseData) => eSeries.includes(item.eSeries!))
      .map((item: IInternalPriceLadderResponseData) => {
        if (
          this.selectedConsideredPriceType === ConsideredPriceType.CURRENT_PRICE ||
          this.selectedConsideredPriceType === ConsideredPriceType.CURRENT_AND_PROPOSED_PRICE ||
          (this.selectedConsideredPriceType === ConsideredPriceType.PROPOSED_PRICE && item.hasAdjustedPrice)
        ) {
          this.unfilteredChartFields.push({
            currency: this.currency,
            eRange: item.eRange,
            isAdjustedPrice: false,
            kw: item.enginePower,
            model: item.model,
            pricePointColor: this.getPricePointColor(item.priceLadderFulfilled === TrafficLightColor.green),
            pricingGuideline: '',
            series: item.series!,
            value: this.getPricePointDisplayValue(item, ConsideredPriceType.CURRENT_PRICE),
            volMix: item.volumeMix,
            xAxisValue: `${item.series} ${item.eSeries}`,
          });
        }
        if (
          (this.selectedConsideredPriceType === ConsideredPriceType.PROPOSED_PRICE ||
            this.selectedConsideredPriceType === ConsideredPriceType.CURRENT_AND_PROPOSED_PRICE) &&
          item.hasAdjustedPrice
        ) {
          this.unfilteredChartFields.push({
            currency: this.currency,
            eRange: item.eRange,
            isAdjustedPrice: true,
            kw: item.enginePower,
            model: item.model,
            pricePointColor: this.getPricePointColor(item.priceLadderFulfilled === TrafficLightColor.green),
            pricingGuideline: '',
            series: item.series!,
            value: this.getPricePointDisplayValue(item, ConsideredPriceType.PROPOSED_PRICE),
            volMix: item.volumeMix,
            xAxisValue: `${item.series} ${item.eSeries}`,
          });
        }
      });

    this.unfilteredChartFields = this.unfilteredChartFields.filter((item) => !!item.value);
    this.applyDefaultPriceRange(this.unfilteredChartFields);

    this.chartData = {
      fields: cloneDeep(this.unfilteredChartFields).filter(
        (item) =>
          (item.value as number) >= this.minAndMaxFilter.minPrice &&
          (item.value as number) <= this.minAndMaxFilter.maxPrice
      ),
      headings: [
        {
          description: 'List price car (base car)',
          key: 'value',
        },
      ],
    };
    this.initChart = true;
    this.crosschecksChartContext = CrosschecksChartContext.CHART;
  }

  /**
   * TransformData
   * @private
   * @param data
   */
  private transformData(data: IInternalPriceLadderResponse[]): void {
    if (data.length > 0) {
      const newData: { segment: string; data: IInternalPriceLadderResponseData[] } = {
        data: [],
        segment: '',
      };

      newData.data = data.flatMap((parentItem: IInternalPriceLadderResponse) => {
        newData.segment = parentItem.segment;
        return parentItem.data.flatMap((item: IInternalPriceLadderResponseData) => {
          return {
            eSeries: parentItem.eSeries,
            series: parentItem.series,
            ...item,
          };
        });
      });

      this.loadedData.push(newData);
      this.crosschecksChartContext = CrosschecksChartContext.CHART;
    } else {
      this.crosschecksChartContext = CrosschecksChartContext.NO_RESULTS_FOUND;
    }
  }
}
