import { ActivatedRoute, Data } from '@angular/router';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { CrosschecksContext } from '../../../enums/crosschecks-context.enum';
import { CrosschecksService } from 'src/app/crosschecks/services/crosschecks/crosschecks.service';
import { IMacroeconomicsTooltips, ITooltipDataResponse } from 'src/app/matrix-view/models/api.model';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-macroeconomics-page',
  templateUrl: './macroeconomics-page.component.html',
})
export class MacroeconomicsPageComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  context: CrosschecksContext = CrosschecksContext.CURRENT;
  tooltips?: IMacroeconomicsTooltips;

  /**
   * Constructor
   * @param crosscheckService
   * @param route
   */
  constructor(private crosscheckService: CrosschecksService, private readonly route: ActivatedRoute) {
    const routeSubscription: Subscription = this.route.data.subscribe((data: Data): void => {
      this.context = data.context;
    });

    this.subscriptions.push(routeSubscription);
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.loadTooltips();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * LoadTooltips
   * @private
   */
  private loadTooltips(): void {
    const subscription: Subscription = this.crosscheckService.getTooltipData('DE', 'macroeconomics').subscribe({
      next: (response: ITooltipDataResponse): void => {
        this.tooltips = response as IMacroeconomicsTooltips;
      },
    });
    this.subscriptions.push(subscription);
  }
}
