<div class="header">
  <app-ftd-header
    userID="{{ currentUser?.name }}"
    userName="{{ currentUser?.given_name }} {{ currentUser?.family_name }}"
    userTitle="{{ currentUser?.departmentnumber }}"
    [isUserHidden]="authService.hasFailed"
    [userHighestHierarchyRole]="currentUser?.permissions?.getHighestHierarchyRole()"
    [userRoles]="currentUser?.permissions?.getRoles()"
    (logOutClick)="logout()"
  ></app-ftd-header>
</div>
