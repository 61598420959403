export enum ScenarioMarketParameter {
  BE = 'BE',
  NL = 'NL',
  LU = 'LU',
  DE = 'DE',
  CH = 'CH',
  IT = 'IT',
  FR = 'FR',
  ES = 'ES',
  PT = 'PT',
  AT = 'AT',
  PL = 'PL',
  GR = 'GR',
  HU = 'HU',
  CZ = 'CZ',
  RO = 'RO',
  SK = 'SK',
  BG = 'BG',
  SI = 'SI',
  SE = 'SE',
  FI = 'FI',
  NO = 'NO',
  DK = 'DK',
  GB = 'GB',
  IE = 'IE',
}
