import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { IFtdDropdownOption, IFtdDropdownOptionsGroup } from '../../models/ftd-dropdown-option.model';
import { IGranularity } from 'src/app/matrix-view/models/app.model';
import { MatSelect } from '@angular/material/select';
import { Subscription } from 'rxjs';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-ftd-dropdown',
  styleUrls: ['./ftd-dropdown.component.scss'],
  templateUrl: './ftd-dropdown.component.html',
})
export class FtdDropdownComponent implements OnInit, OnDestroy {
  @Input() id!: string;

  private _defaultOptions: IFtdDropdownOption[] = [];
  private _options: IFtdDropdownOption[] = [];
  get options(): IFtdDropdownOption[] {
    return this._options;
  }

  @Input() set options(options: IFtdDropdownOption[]) {
    this._options = options;
    this._defaultOptions = options;
  }

  @Input() models: IFtdDropdownOptionsGroup<IGranularity>[] = [];
  @Input() label: string = 'Select';
  @Input() form!: FormGroup;
  @Input() controlName: string = '';
  @Input() defaultSelected?: IFtdDropdownOption;
  @Input() disabled: boolean = false;
  @Output() selectedOption: EventEmitter<any> = new EventEmitter();
  @Input() customMessage?: string;
  @Input() isSearchVisible: boolean = false;
  @Input() isLabelVisible: boolean = true;
  @Input() isRadioButtonVisible: boolean = false;
  @Input() isFlagsVisible: boolean = false;
  @Input() isGroupedDropdown: boolean = false;
  @Input() errorMessage?: string;
  /**
   * Makes input fitting & filling the available container's width
   */
  @Input() fillWidth: boolean = false;

  private subscriptions: Subscription[] = [];

  @Input() preventOptionsPanelToOpen?: boolean = false;
  @Output() selectFormClick = new EventEmitter<any>();
  @ViewChild('matSelectRef') matSelectRef!: MatSelect;

  /**
   * @constructor
   * @param changeDetector
   */
  constructor(private changeDetector: ChangeDetectorRef) {}

  ngOnInit(): void {
    if (this.isSearchVisible) {
      this.setSearchForm();
    }

    const subscription: Subscription | undefined = this.form
      ?.get(this.controlName)
      ?.valueChanges.subscribe((option): void => {
        this.emitSelectedOption(option);
      });

    if (this.defaultSelected) {
      const defaultValue = this.options.find((option1) => this.defaultSelected?.id === option1.id);

      this.form?.get(this.controlName)?.setValue(defaultValue?.value);

      if (subscription) {
        this.subscriptions.push(subscription);
      }
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  emitSelectedOption(description: IFtdDropdownOption | undefined): void {
    this.selectedOption.emit(description);
  }

  optionTrackBy(index: number, option: IFtdDropdownOption): number | string {
    return option.id;
  }

  handleSelectFormClick(): void {
    if (this.preventOptionsPanelToOpen && this.matSelectRef.panelOpen) {
      this.matSelectRef.close();
    }
    this.selectFormClick.emit();
  }

  /**
   * SetSearchForm
   * @private
   */
  private setSearchForm(): void {
    this.form?.addControl(`${this.controlName}Search`, new FormControl(''));

    const subscriptionSearch: Subscription | undefined = this.form
      ?.get(`${this.controlName}Search`)
      ?.valueChanges.subscribe((search): void => {
        this.filterSearch(search);
      });

    if (subscriptionSearch) {
      this.subscriptions.push(subscriptionSearch);
    }
  }

  /**
   * FilterSearch
   * @private
   * @param search
   */
  private filterSearch(search: string): void {
    if (search?.length) {
      this._options = this._defaultOptions.filter(
        (option: IFtdDropdownOption<{}>): boolean =>
          String(option.label).toLowerCase().indexOf(String(search).toLowerCase()) > -1
      );

      this.changeDetector.detectChanges();
    } else if (search?.length === 0 || this._options?.length === 0) {
      this._options = this._defaultOptions;
    }
  }
}
