export enum DiscountType {
  BEV_CAMPAIGN = 'BEV campaign',
  RUN_RATE_SUPPORT = 'Run rate support',
  LOYALTY_CAMPAIGN = 'Loyalty campaign',
  CONQUEST_CAMPAIGN = 'Conquest campaign',
  EVENT_BASED_CAMPAIGN = 'Event based campaign',
  VIP_A = 'VIP A',
  VIP_B = 'VIP B',
  VIP_C = 'VIP C',
  DISABLED_PERSON = 'Disabled person',
  JOURNALIST = 'Journalist',
}

export const DiscountTypeMap: Map<DiscountTypeKey, DiscountTypeValue> = new Map<DiscountTypeKey, DiscountTypeValue>([
  ['BEV_CAMPAIGN', 'BEV campaign'],
  ['RUN_RATE_SUPPORT', 'Run rate support'],
  ['LOYALTY_CAMPAIGN', 'Loyalty campaign'],
  ['CONQUEST_CAMPAIGN', 'Conquest campaign'],
  ['EVENT_BASED_CAMPAIGN', 'Event based campaign'],
  ['VIP_A', 'VIP A'],
  ['VIP_B', 'VIP C'],
  ['VIP_C', 'VIP C'],
  ['DISABLED_PERSON', 'Disabled person'],
  ['JOURNALIST', 'Journalist'],
]);

export type DiscountTypeKey =
  | 'BEV_CAMPAIGN'
  | 'RUN_RATE_SUPPORT'
  | 'LOYALTY_CAMPAIGN'
  | 'CONQUEST_CAMPAIGN'
  | 'EVENT_BASED_CAMPAIGN'
  | 'VIP_A'
  | 'VIP_B'
  | 'VIP_C'
  | 'VIP_C'
  | 'DISABLED_PERSON'
  | 'JOURNALIST';

export type DiscountTypeValue =
  | 'BEV campaign'
  | 'Run rate support'
  | 'Loyalty campaign'
  | 'Conquest campaign'
  | 'Event based campaign'
  | 'VIP A'
  | 'VIP B'
  | 'VIP C'
  | 'Disabled person'
  | 'Journalist';
