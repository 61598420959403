import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { HttpLoaderService } from 'src/app/common/services/http-loader/http-loader.service';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-ftd-custom-modal-body',
  styleUrls: ['./ftd-custom-modal-body.component.scss'],
  templateUrl: './ftd-custom-modal-body.component.html',
})
export class FtdCustomModalBodyComponent implements OnInit, OnDestroy {
  @Input() enableGlobalLoader: boolean = false;
  @Input() isRequestProcessing: boolean = false;
  @Input() requestProcessingMessage: string = "Don't close this window, please wait a few moments.";

  private _httpLoaderService = inject(HttpLoaderService);

  private _destroy$ = new Subject<unknown>();

  ngOnInit(): void {
    this.enableGlobalLoader &&
      this._httpLoaderService.waitingForResponse$
        .pipe(
          takeUntil(this._destroy$),
          tap((waitingForResponse: boolean) => (this.isRequestProcessing = waitingForResponse))
        )
        .subscribe();
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
    this._destroy$.complete();
  }
}
