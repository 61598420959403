<app-crosschecks-chart-wrapper
  [context]="crosschecksChartContext"
  [skeletonTheme]="'Markets'"
  [filters]="filters"
  [crosscheckIndicationVisible]="!!crosscheckIndication"
  [trafficLightAdjStatus]="crosscheckIndicationForecasted!"
  [trafficLightStatus]="crosscheckIndication!"
>
  <div id="market-container">
    <div id="market-filters-container">
      <app-ftd-dropdown
        [id]="'considered-price'"
        [label]="'Considered Price'"
        [form]="filtersForm"
        [controlName]="'consideredPrice'"
        [options]="consideredPrice"
      ></app-ftd-dropdown>

      <app-ftd-dropdown
        [id]="'sales-channel'"
        [label]="'Sales Channel'"
        [form]="filtersForm"
        [controlName]="'salesChannel'"
        [options]="salesChannel"
      ></app-ftd-dropdown>

      <app-ftd-dropdown
        [id]="'basis-of-comparison'"
        [label]="'Basis of comparison'"
        [form]="filtersForm"
        [controlName]="'basisOfComparison'"
        [options]="basisOfComparison"
      ></app-ftd-dropdown>

      <app-ftd-dropdown
        [id]="'displayed-values'"
        [label]="'Displayed Values'"
        [form]="filtersForm"
        [controlName]="'displayedValues'"
        [options]="displayedValues"
      ></app-ftd-dropdown>
    </div>
    <div id="market-filters-headers" *ngIf="false">
      <app-crosschecks-indication [trafficLightStatusColor]="'green'"> </app-crosschecks-indication>
    </div>
  </div>
  <div class="container">
    <span class="chart-title">
      C1 PRICE DISTANCE
      <ul>
        <li>
          {{ getBasisOfComparisonTypeLabel() }}
          €
        </li>
      </ul>
    </span>
    <div class="chart-container">
      <app-ftd-bar-chart
        *ngIf="chartData"
        [data]="chartData"
        [rootId]="'ftd-bar-chart-markets'"
        [isLabelValuesVisible]="true"
        [stacked]="false"
        [isSysDateIndicatorVisible]="false"
        [axisType]="chartAxisType"
        [chartCustomTheme]="'MarketsCustomTheme'"
        [renderXAxisTop]="true"
        [isTooltipVisible]="true"
        [clustered]="false"
        [chartLegend]="chartLegend"
        [isScrollBarVisible]="true"
      ></app-ftd-bar-chart>
    </div>

    <div class="markets-legend">
      <div class="filter-item">
        <img
          ngSrc="./assets/images/charts/legends/rectangle-dotted-white.svg"
          alt="White dashed rectangle from chart legend"
          height="18"
          width="18"
        />
        <div class="chart-filter-item">
          <div class="chart-filter-item-label">Your market</div>
        </div>
      </div>

      <div class="filter-item">
        <img
          ngSrc="./assets/images/charts/legends/rectangle-blue.svg"
          alt="Blue rectangle from chart legend"
          height="18"
          width="18"
        />
        <div class="chart-filter-item">
          <div class="chart-filter-item-label">Base car price</div>
        </div>
      </div>

      <div class="filter-item">
        <img
          ngSrc="./assets/images/charts/legends/rectangle-green.svg"
          alt="Green rectangle from chart legend"
          height="18"
          width="18"
        />
        <div class="chart-filter-item">
          <div class="chart-filter-item-label">Typically equipped car markup</div>
        </div>
      </div>
    </div>
  </div>
</app-crosschecks-chart-wrapper>
