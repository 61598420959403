import { AbandonScenarioModalComponent } from 'src/app/scenario/modals/abandon-scenario-modal/abandon-scenario-modal.component';
import { AuthService } from '../../../auth/services/auth.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FtdModalSize } from 'src/app/common/components/ftd-modal/ftd-modal-size.enum';
import { MatDialog } from '@angular/material/dialog';
import { MatrixViewFormService } from '../../services/matrix-view-form/matrix-view-form.service';
import { NewScenarioModalComponent } from '../../../scenario/modals/new-scenario-modal/new-scenario-modal.component';
import { Router } from '@angular/router';
import { ScenarioListModalComponent } from '../../../scenario/modals/scenario-list-modal/scenario-list-modal.component';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';

@Component({
  selector: 'app-matrix-view-subheader',
  styleUrls: ['./matrix-view-subheader.component.scss'],
  templateUrl: './matrix-view-subheader.component.html',
})
export class MatrixViewSubheaderComponent implements OnInit, OnDestroy {
  @Input() public title: string = '';
  @Input() public description: string = '';
  @Input() public isReturnButtonHidden: boolean = true;
  @Input() public isCreateScenarioHidden: boolean = false;
  currentUser!: User | null;

  private subscriptions: Subscription[] = [];

  constructor(
    public dialog: MatDialog,
    private matrixViewFormService: MatrixViewFormService,
    private router: Router,
    private authService: AuthService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  ngOnInit(): void {
    const subscription = this.authService
      .getLoggedInUserAsObservable()
      .subscribe((user: User | null) => (this.currentUser = user));
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  openCreateScenarioModal(): void {
    const dialogRef = this.dialog.open(NewScenarioModalComponent, {
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription = dialogRef.afterClosed().subscribe(() => {});
    this.subscriptions.push(subscription);
  }

  openScenarioListModal(): void {
    const dialogRef = this.dialog.open(ScenarioListModalComponent, {
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription = dialogRef.afterClosed().subscribe(() => {});
    this.subscriptions.push(subscription);
  }

  openAbandonScenarioModalComponent(): void {
    const dialogRef = this.dialog.open(AbandonScenarioModalComponent, {
      data: {
        scenarioName: this.description,
      },
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription = dialogRef.afterClosed().subscribe(async (isNavigateToMainScenario: boolean) => {
      if (isNavigateToMainScenario) {
        await this.router.navigate(['/main-scenario']);
      }
    });
    this.subscriptions.push(subscription);
  }

  goToDiscountSummaryPage(): void {
    this.router.navigate(['/discounts/summary']);
  }
}
