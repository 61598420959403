export type TrafficLightsColor =
  | 'green'
  | 'grey'
  | 'red'
  | 'trafficLightNotAvailable'
  | 'notAvailable'
  | ''
  | null
  | undefined;
export type TrafficLightsArrow =
  | 'up'
  | 'down'
  | 'upDown'
  | 'keep'
  | 'neutral'
  | 'trafficLightNotAvailable'
  | 'na'
  | 'notAvailable'
  | null;
export type trafficLightsDescription =
  | 'Crosscheck within threshold'
  | 'No Price change'
  | 'Deep dive recommended'
  | 'Increase price'
  | 'Increase/decrease price'
  | 'Decrease price'
  | 'View deep dive'
  | 'Only detailed info';

export const trafficLightsDescriptionMap: Map<TrafficLightsArrow, trafficLightsDescription> = new Map<
  TrafficLightsArrow,
  trafficLightsDescription
>([
  ['keep', 'No Price change'],
  ['up', 'Increase price'],
  ['down', 'Decrease price'],
  ['upDown', 'Increase/decrease price'],
  ['trafficLightNotAvailable', 'Only detailed info'],
]);

export const trafficLightsColorDescriptionMap: Map<TrafficLightsColor, trafficLightsDescription> = new Map([
  ['green', 'Crosscheck within threshold'],
  ['grey', 'View deep dive'],
  ['red', 'Deep dive recommended'],
]);
