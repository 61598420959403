import { Component, ViewChild } from '@angular/core';
import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-ftd-accordion',
  templateUrl: './ftd-accordion.component.html',
})
export class FtdAccordionComponent {
  @ViewChild(MatAccordion) accordion?: MatAccordion;
}
