import { APP_FSM_USER_MANUAL_LINK } from '../../constants/app-fsm-related-external-links';
import { Component, ElementRef, EventEmitter, Input, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { FtdButtonComponent } from '../ftd-button/ftd-button.component';
import { HeaderLogos } from '../../models/ftd-header-logos.model';
import { ROLE, RoleDefinition } from '../../../matrix-view/models/roles.model';

@Component({
  encapsulation: ViewEncapsulation.None,
  selector: 'app-ftd-header',
  styleUrls: ['./ftd-header.component.scss'],
  templateUrl: './ftd-header.component.html',
})
export class FtdHeaderComponent extends HeaderLogos {
  public FtdButtonComponent!: FtdButtonComponent;
  @ViewChild('nav') nav!: ElementRef;
  @Input() public userRoles?: ROLE[] = [];
  @Input() public userName: string = '';
  @Input() public userTitle: string = '';
  @Input() public userID: string = '';
  @Input() public isUserHidden: boolean = true;
  @Output() logOutClick: EventEmitter<any> = new EventEmitter<any>();

  @Input() public userHighestHierarchyRole?: ROLE = 'reader';
  protected readonly APP_FSM_USER_MANUAL_LINK = APP_FSM_USER_MANUAL_LINK;

  constructor() {
    super();
  }

  get userHighestHierarchyRoleDefinition() {
    return RoleDefinition[this.userHighestHierarchyRole!];
  }

  menuOpen(): void {
    this.nav.nativeElement.classList.add('ftd-header-menu-open');
  }

  menuClose(): void {
    this.nav.nativeElement.classList.remove('ftd-header-menu-open');
  }

  onLogOut($event: MouseEvent): void {
    this.logOutClick.emit($event);
  }
}
