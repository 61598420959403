import { IMutation, IQuery } from 'src/app/graphql/services/gql-api.service';

/*
 * Types of errors which GraphQL/AppSync would throw
 */
export enum GraphQLErrorType {
  DUPLICATE_ITEM = 'duplicateItem',
  MISSING_ARGUMENT = 'missingArgument',
  MISSING_ARGUMENT_VALUE = 'missingArgumentValue',
  DELETION_FAILED = 'deletionFailed',
  LAMBDA_UNHANDLED = 'Lambda:Unhandled',
  APPROVE_BRAND_PRICE_POINT_IN_TWO_DIFFERENT_STATUS = 'pricePointsInTwoDiffrentStatus',
  APPROVE_BRAND_NO_PRICE_POINTS_TO_APPROVE = 'noPricePointsToApprove',
  BRAND_APPROVAL_MISSING_VALUE = 'emptySearchResult',
  FILTERS_MISSING_ARGUMENTS = 'missingArgumentValue',
}

/*
 * Response format of all the AppSync APIs
 */
export interface IGraphQLResponse<T> {
  data: T;
  errors: IGraphQLErrorResponse[];
}

/*
 * Error format in case an error occurs
 */
export interface IGraphQLErrorResponse {
  data: null;
  errorInfo: null;
  errorType: GraphQLErrorType;
  message: string;
  path: (keyof (IMutation & IQuery))[];
}
