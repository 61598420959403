import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FtdAm5ChartsThemeHelper, FtdChartCustomThemes } from '../../themes/ftd-am5-charts.theme';
import { FtdBaseChart } from '../../ftd-base-chart.abstract';
import { GenericChartValueFields } from '../../enums/ftd-generic-chart.enum';
import { IGenericChartHeadings } from '../../models/ftd-generic-chart.model';
let _rootId = 0;

@Component({
  selector: 'app-ftd-line-and-bar-chart',
  templateUrl: './ftd-line-and-bar-chart.component.html',
})
export class FtdLineAndBarChartComponent extends FtdBaseChart implements AfterViewInit, OnInit {
  @Input() rootId!: string;
  @Input() isSysDateIndicatorVisible: boolean = true;
  @Input() isCursorVisible: boolean = true;
  /**
   * Input to make series hidable through legend toggles
   */
  @Input() set chartLegend(currentLegend: string[]) {
    this.configLegend(currentLegend);
  }

  /**
   * If bars on bar chart should be clustered or side by side
   */
  @Input() clustered: boolean = true;
  /**
   * The unit to display on each grid value of the left Y axis
   */
  @Input() leftYaxisUnit: string = 'Units';
  /**
   * The unit to display on each grid value of the right Y axis
   */
  @Input() rightYaxisUnit: string = '€';
  /**
   * The keys to extract data for valueYField on the given data set of the Line-Charts
   */
  @Input() linesChartKeys: string[] = [];
  /**
   * The keys to extract data for valueYField on the given data set of the Bar-Charts
   */
  @Input() barChartKeys: string[] = [];

  ngOnInit() {
    _rootId += 1;
    this.rootId = `ftd-line-and-bart-chart-ref-${_rootId}`;
  }

  /**
   * NgAfterViewInit
   */
  ngAfterViewInit() {
    this.browserOnly(() => {
      this.initChart();
    });
  }

  /**
   * InitChart
   */
  initChart() {
    // Set Root (ftd-chart-ref-${id}})
    this.setRoot();

    // Set Theme
    this.setTheme();

    // Set Chart
    this.setChart();

    // Create Y-axis
    this.setYAxis();

    // Create X-Axis
    this.setXAxis();

    // Set Label Description
    this.setAxisLabelDescription();

    // Set Line Series
    this._data.headings
      .filter((genericChartHeadings: IGenericChartHeadings) => this.linesChartKeys.includes(genericChartHeadings.key))
      .forEach((genericChartHeadings: IGenericChartHeadings) => {
        this.setLineSeries(genericChartHeadings.description, genericChartHeadings.key);
      });
    // Set Bar Series
    this._data.headings
      .filter((genericChartHeadings: IGenericChartHeadings) => this.barChartKeys.includes(genericChartHeadings.key))
      .forEach((genericChartHeadings: IGenericChartHeadings) => {
        this.setBarSeries(genericChartHeadings.description, genericChartHeadings.key);
      });

    // Add Cursor tooltip if enabled
    if (this.isCursorVisible) {
      this.setCursor();
    }

    // Add SysDate Indicator if enabled
    if (this.isSysDateIndicatorVisible) {
      this.setSysDateIndicator();
    }
  }

  /**
   * SetRoot
   * @private
   */
  private setRoot(): void {
    this.root = this.am5.Root.new(this.rootId);
  }

  /**
   * SetTheme
   * @private
   */
  private setTheme(): void {
    this.chartCustomTheme
      ? this.root.setThemes([
          this.am5themes_Ftd.new(this.root),
          FtdChartCustomThemes[this.chartCustomTheme].new(this.root),
        ])
      : this.root.setThemes([this.am5themes_Ftd.new(this.root)]);
  }

  /**
   * SetChart
   * @private
   */
  private setChart(): void {
    this.chart = this.root.container.children.push(
      this.am5xy.XYChart.new(this.root, {
        layout: this.root.verticalLayout,
        maxTooltipDistance: 0,
      })
    );
  }

  /**
   * SetYAxis
   * @protected
   */
  protected setYAxis(): void {
    super.setYAxis();

    if (this.renderYAxisRight) {
      // Right Y Axis
      this.yAxisRight.setAll({
        syncWithAxis: this.yAxis,
      });
    }
  }

  /**
   * SetXAxis
   * @protected
   */
  protected setXAxis(): void {
    super.setXAxis();
  }

  /**
   * SetAxisLabelDescription
   * @protected
   */

  protected setAxisLabelDescription() {
    super.setAxisLabelDescription();
  }

  /**
   * SetCursor
   * @private
   */
  private setCursor(): void {
    this.chart.set(
      'cursor',
      this.am5xy.XYCursor.new(this.root, {
        xAxis: this.xAxis,
      })
    );
  }

  /**
   * Create Line Series
   * @param name
   * @param valueYField
   * @private
   */
  private setLineSeries(name: string, valueYField: string): void {
    const series = this.chart.series.push(
      this.am5xy.LineSeries.new(this.root, {
        categoryXField: GenericChartValueFields.X,
        layer: 2,
        name: name,
        valueXField: GenericChartValueFields.X,
        valueYField: valueYField,
        xAxis: this.xAxis,
        yAxis: this.renderYAxisRight ? this.yAxisRight : this.yAxis,
      })
    );

    // Add Bullet
    series.bullets.push(() => {
      return this.am5.Bullet.new(this.root, {
        sprite: this.am5.Circle.new(this.root, {
          fill: series.get('fill'),
          radius: 5,
          tooltip: FtdAm5ChartsThemeHelper.getTooltip(this.root, 'horizontal', this.isTooltipVisible),
          tooltipHTML: FtdAm5ChartsThemeHelper.buildChartTooltipHTML([{ key: '{name}', value: '{valueY}' }]),
        }),
      });
    });

    // Add Label
    series.bullets.push(() => {
      const label = this.am5.Bullet.new(this.root, {
        sprite: this.am5.Label.new(this.root, {
          populateText: true,
          text: '{valueY}',
          visible: this.isLabelValuesVisible,
        }),
        themeTags: ['ftd-bullet-label'],
      });
      label.set('id', `label_${label.uid}`);
      return label;
    });

    // Set Data
    series.data.setAll(this._data.fields);
  }

  /**
   * Create Bar Series
   * @param name
   * @param valueYField
   * @param valueXField
   * @private
   */

  private setBarSeries(name: string, valueYField: string): void {
    const series = this.chart.series.push(
      this.am5xy.ColumnSeries.new(this.root, {
        categoryXField: GenericChartValueFields.X,
        clustered: this.clustered,
        name: name,
        valueXField: GenericChartValueFields.X,
        valueYField: valueYField,
        xAxis: this.xAxis,
        yAxis: this.yAxis,
      })
    );

    series.columns.template.setAll({
      tooltip: FtdAm5ChartsThemeHelper.getTooltip(this.root, 'vertical', this.isTooltipVisible),
      tooltipHTML: FtdAm5ChartsThemeHelper.buildChartTooltipHTML([{ key: '{name}', value: '{valueY}' }]),
      tooltipY: 0,
      width: this.am5.percent(100),
    });

    // Add Label
    series.bullets.push(() => {
      const label = this.am5.Bullet.new(this.root, {
        locationX: 0.5,
        locationY: 1,
        sprite: this.am5.Label.new(this.root, {
          centerX: 23,
          centerY: 30,
          populateText: true,
          text: '{valueY}',
          textAlign: 'center',
          textBaseline: 'bottom',
          visible: this.isLabelValuesVisible,
        }),
        themeTags: ['ftd-bullet-label'],
      });
      label.set('id', `label_${label.uid}`);
      return label;
    });

    // Set Data
    series.data.setAll(this._data.fields);
  }

  /**
   * ConfigLegend
   * @private
   */
  private configLegend(currentLegend: string[]) {
    this.chart?.series?.each((serie) => {
      currentLegend.includes(serie.get('valueYField')!) ? serie.show() : serie.hide();
    });
  }
}
