import { ActivatedRoute, Data } from '@angular/router';
import { Base64Utils } from '../../../../common/utils/base64.utils';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../../../../matrix-view/services/context/context.service';
import { CrossCheckContextType, SalesPipelineCrossCheckType } from '../../../../graphql/services/gql-api.service';
import { CrossCheckContextTypeMap, CrosschecksContext } from '../../../enums/crosschecks-context.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import {
  CrosschecksTrafficLightUIProps,
  IGranularityDto,
  ISalesPipelineTooltips,
  ITooltipDataResponse,
} from 'src/app/matrix-view/models/api.model';
import { Location } from '@angular/common';
import { Subscription } from 'rxjs';
import { TrafficLightsColor } from '../../../types/traffic-lights.type';

@Component({
  selector: 'app-sales-pipeline-page',
  templateUrl: './sales-pipeline-page.component.html',
})
export class SalesPipelinePageComponent implements OnInit, OnDestroy {
  selectedTab: number = 0;
  context: CrosschecksContext = CrosschecksContext.CURRENT;
  tooltips?: ISalesPipelineTooltips;
  private subscriptions: Subscription[] = [];
  private salesPipelineTabs: string[] = ['lease-returns', 'order-intake', 'calendarized-order-bank', 'retail-sales'];

  crosscheckIndication?: TrafficLightsColor;
  crosscheckIndicationForecasted?: TrafficLightsColor;

  trafficLightRetailOverview?: CrosschecksTrafficLightUIProps;
  trafficLightOrderIntakeOverview?: CrosschecksTrafficLightUIProps;

  _filters: string = '';
  @Input() set filters(value: string) {
    this._filters = this.getParamMapId;
    this.loadTrafficLights();
    this.loadTooltips();
    this.loadTrafficLightsOverview();
  }

  get filters(): string {
    return this._filters;
  }

  /**
   * Constructor
   * @param route
   * @param location
   * @param crosscheckService
   * @param contextService
   */
  constructor(
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private crosscheckService: CrosschecksService,
    private contextService: ContextService
  ) {
    const routeSubscription: Subscription = this.route.data.subscribe((data: Data): void => {
      this.selectedTab = data.index;
      this.context = data.context;
    });

    this.subscriptions.push(routeSubscription);
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.route.url.subscribe(() => {
      this.filters = this.getParamMapId;
      this.loadTrafficLights();
    });
    this.loadTooltips();
    this.loadTrafficLightsOverview();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * ChangeTab
   * @param changeEvent
   */
  changeTab(changeEvent: number): void {
    const changedTabURL: string = `/crosschecks/${this.context}/sales-pipeline/${this.salesPipelineTabs[changeEvent]}/${this.filters}`;
    this.selectedTab = changeEvent;
    this.location.go(changedTabURL);
  }

  /**
   * IsSelectedTabLeaseReturns
   * @return boolean
   */
  get isSelectedTabLeaseReturns(): boolean {
    return this.selectedTab === 0;
  }

  /**
   * IsSelectedTabOrderIntake
   * @return boolean
   */
  get isSelectedTabOrderIntake(): boolean {
    return this.selectedTab === 1;
  }

  /**
   * IsSelectedTabCalendarizedOrderBank
   * @return boolean
   */
  get isSelectedTabCalendarizedOrderBank(): boolean {
    return this.selectedTab === 2;
  }

  /**
   * IsSelectedTabRetailSales
   * @return boolean
   */
  get isSelectedTabRetailSales(): boolean {
    return this.selectedTab === 3;
  }

  /**
   * GetParamMapId
   * @private
   * @return string
   */
  private get getParamMapId(): string {
    return this.route.snapshot.paramMap.get('id') ?? '';
  }

  /**
   * LoadTooltips
   * @private
   */
  private loadTooltips(): void {
    const subscription: Subscription = this.crosscheckService.getTooltipData('DE', 'salesPipeline').subscribe({
      next: (response: ITooltipDataResponse): void => {
        this.tooltips = response as ISalesPipelineTooltips;
      },
    });
    this.subscriptions.push(subscription);
  }

  /**
   * LoadTrafficLights
   * @private
   */
  private loadTrafficLights(): void {
    const granularity: IGranularityDto = Base64Utils.decodeAtobToJson(this.filters);
    if (granularity.id) {
      const trafficLightsSubscription: Subscription = this.crosscheckService
        .getSalesPipelineTrafficLights(this.contextService.scenarioId, granularity.id.toString(), granularity.market)
        .subscribe((trafficLights) => {
          this.crosscheckIndication = trafficLights.trafficLight.color;
          this.crosscheckIndicationForecasted = trafficLights.trafficLightAdj.color;
        });
      this.subscriptions.push(trafficLightsSubscription);
    }
  }

  /**
   * LoadTrafficLights
   * @private
   */
  private loadTrafficLightsOverview(): void {
    const granularity: IGranularityDto = Base64Utils.decodeAtobToJson(this.filters);
    if (granularity.id) {
      const request = (type: SalesPipelineCrossCheckType) =>
        this.crosscheckService.getSalesPipelineTrafficLightsOverview(
          this.contextService.scenarioId,
          granularity.id?.toString() as string,
          granularity.market,
          type,
          CrossCheckContextTypeMap.get(this.context) as CrossCheckContextType
        );

      const retailTrafficLightsSubscription: Subscription = request(SalesPipelineCrossCheckType.Retail).subscribe(
        (response) => {
          this.trafficLightRetailOverview = response;
        }
      );
      this.subscriptions.push(retailTrafficLightsSubscription);

      const orderIntakeTrafficLightsSubscription: Subscription = request(
        SalesPipelineCrossCheckType.OrderIntake
      ).subscribe((response) => {
        this.trafficLightOrderIntakeOverview = response;
      });
      this.subscriptions.push(orderIntakeTrafficLightsSubscription);
    }
  }
}
