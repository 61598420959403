import { AxisLabel, AxisRendererX, ColumnSeries, ICategoryAxisDataItem, XYChart } from '@amcharts/amcharts5/xy';
import { ChartDimension, FtdAm5ChartsThemeHelper } from '../../ftd-am5-charts.theme';
import { Color, DataItem, RoundedRectangle, Theme } from '@amcharts/amcharts5';
import { GenericChartFields } from '../../../models/ftd-generic-chart.model';
import { StockAgeHelper, isIndex } from '../../../../../../crosschecks/helpers/stock/stock-age.helper';
import { StockAgePropsEnum } from '../../../../../../crosschecks/enums/stock-age-props.enum';
import { am5 } from '../../../ftd-base-chart.abstract';
import dayjs from 'dayjs';

export class FtdStockAgeHorizontalCustomTheme extends Theme {
  setupDefaultRules() {
    super.setupDefaultRules();
    this.setupLabelRules();
    this.setupGridRules();
    this.setupColumnRules();
  }

  protected setupGridRules() {
    this.rule('XYChart').setAll({
      paddingRight: 0,
      paddingTop: 0,
      wheelX: 'panX',
    });

    this.rule('ValueAxis').setAll({
      maxDeviation: 0,
    });

    this.rule('AxisRendererY').setAll({
      layer: 2,
      minGridDistance: 35,
      stroke: Color.fromHex(0xffffff),
    });

    this.rule('ValueAxis').setAll({
      extraMax: 0.1,
    });

    this.rule('ValueAxis').events.once('datavalidated', (ev) => {
      ev.target.get('renderer').grid.template.set('visible', false);
    });

    this.rule('Button').setAll({
      forceHidden: true,
    });

    this.rule('CategoryAxis').setAll({
      maxDeviation: 0,
    });

    // Set background highlight when clicked
    this.rule('CategoryAxis').events.once('datavalidated', (event) => {
      // Create series range to work as background highlight color
      event.target.dataItems.forEach((element: DataItem<ICategoryAxisDataItem>) => {
        const seriesRangeDataItem = event.target.makeDataItem({
          category: (element.dataContext as GenericChartFields).value as string,
        });
        event.target.createAxisRange(seriesRangeDataItem);

        // Set every background with opacity 0 to enable onclick outside bars
        seriesRangeDataItem.get('axisFill')?.setAll({
          fill: Color.fromHex(0x282f47),
          fillOpacity: 0,
          visible: true,
        });

        // Set label to have same color has background when selected
        seriesRangeDataItem.get('label')?.setAll({
          background: am5.RoundedRectangle.new(event.target.root, {
            cornerRadiusBL: 10,
            cornerRadiusBR: 0,
            cornerRadiusTL: 10,
            cornerRadiusTR: 0,
            fill: Color.fromHex(0x282f47),
            fillOpacity: 1,
            height: am5.p100,
          }),
          forceHidden: true,
          maskContent: false,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingTop: 0,
          text: ' ',
          themeTags: ['ftd-axisRange-label'],
        });

        // Set selected model or serie
        if ((element.dataContext as GenericChartFields).selected) {
          seriesRangeDataItem.get('axisFill')?.set('fillOpacity', 1);
          seriesRangeDataItem.get('axisFill')?.set('layer', -1);
          seriesRangeDataItem.get('label')?.set('forceHidden', false);
        }
      });
    });
  }

  protected setupLabelRules() {
    this.rule('Label').setup = (target: am5.Label): void => {
      if (target.dataItem?.bullets) {
        target.setAll({
          centerX: am5.p50,
          centerY: am5.p50,
        });
      }
    };

    this.rule('AxisLabel', ['x']).setAll({
      maxPosition: 0.9,
    });

    this.rule('AxisLabel', ['y']).setAll({
      paddingLeft: 20,
    });

    this.rule('XYChart').adapters.add('width', (width: ChartDimension, chart: XYChart): ChartDimension => {
      chart.yAxes.getIndex(0)?.axisRanges.each((axisRange) => {
        axisRange.get('label')?.set('paddingLeft', chart.leftAxesContainer.width() - 20);
        const columnHeight = chart.yAxesAndPlotContainer.height() / chart.yAxes.getIndex(0)?.axisRanges.length!;
        axisRange.get('label')?.set('paddingTop', columnHeight - 20);
      });
      return width;
    });
  }

  protected setupColumnRules() {
    this.rule('ColumnSeries').events.once('datavalidated', (events) => {
      const columnSize = 20;
      events.target.columns.template.onPrivate(
        'width',
        (size: number | undefined, target: RoundedRectangle | undefined): void => {
          am5.array.each(target?.dataItem?.bullets as ArrayLike<am5.Bullet>, (bullet: am5.Bullet) => {
            this.isBulletVisible(bullet, columnSize, size) ? bullet.get('sprite').show() : bullet.get('sprite').hide();
          });
        }
      );
    });

    this.rule('ColumnSeries').adapters.add(
      'fill',
      (fill: Color | undefined, target: ColumnSeries): Color | undefined => {
        return isIndex(<string>target.get('valueXField')) >= 0
          ? am5.color(StockAgeHelper.getColor(+isIndex(<string>target.get('valueXField')), 'fill'))
          : fill;
      }
    );
  }

  private isBulletVisible(bullet: am5.Bullet, columnSize: number, size: number | undefined) {
    return size! > columnSize || bullet.get('sprite').get('themeTags')?.includes('ftd-horizontal-total');
  }
}

export class FtdStockAgePopupCustomTheme extends Theme {
  tooltipHTML: string = '';
  timeout: any;

  setupDefaultRules() {
    super.setupDefaultRules();
    this.setupLabelRules();
    this.setupGridRules();
    this.setupColumnRules();
    this.setupTooltipRules();
  }

  protected setupGridRules() {
    this.rule('XYChart').setAll({
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 14,
    });

    this.rule('AxisRendererY').setAll({
      minGridDistance: 35,
    });

    this.rule('CategoryAxis').events.once('datavalidated', (ev) => {
      const lastIndex = ev.target.endIndex();
      ev.target.zoomToIndexes(lastIndex - 6, lastIndex);
      ev.target.get('renderer').grid.template.set('visible', false);
    });

    this.rule('Button').setAll({
      forceHidden: true,
    });
  }

  protected setupLabelRules() {
    this.rule('ValueAxis').setAll({
      calculateTotals: true,
      extraMax: 0.1,
      min: 0,
    });

    this.rule('AxisRendererX', ['ftd-xAxis-top']).setup = (target: AxisRendererX): void => {
      target.setAll({
        cellEndLocation: 0.8,
        cellStartLocation: 0.2,
        minGridDistance: 20,
        opacity: 1,
        opposite: true,
      });
    };

    this.rule('AxisLabel', ['axis', 'x', 'ftd-xAxis-top']).adapters.add(
      'text',
      (value: string | undefined, target: AxisLabel): string | undefined => {
        const dataItem: GenericChartFields | undefined = (
          target.dataItem?.component.get('userData') as GenericChartFields[]
        )?.find(
          (data: GenericChartFields) =>
            data.xAxisValue === (target.dataItem?.dataContext as GenericChartFields)?.xAxisValue
        );
        if (dataItem && dataItem.xAxisValue !== dayjs(new Date()).format("MMM 'YY")) {
          target.setAll({
            centerX: am5.p50,
            dy: -14,
            fontFamily: 'BMW Group TN Condensed Pro',
            fontSize: 14,
            lineHeight: 1.4,
            populateText: false,
            visible: true,
          });
          return `[#D6D6D6]Median Age[/]\n[fontWeight: 700]${dataItem.monthlyMedianAge} days[/]`;
        }
        return value;
      }
    );

    this.rule('ColumnSeries').events.once('datavalidated', (event) => {
      const valueY = event.target.get('valueYField');
      const isLastIndex = valueY === 'lastIndex';
      const textColor = am5.color(
        isIndex(<string>valueY) >= 0 ? StockAgeHelper.getColor(+isIndex(<string>valueY), 'text') : '#ffffff'
      );

      event.target.bullets.push(() => {
        return am5.Bullet.new(this._root, {
          locationX: 0.5,
          locationY: 0.5,
          sprite: am5.Label.new(this._root, {
            centerX: am5.p50,
            centerY: am5.p50,
            dy: isLastIndex ? -16 : 0,
            fill: textColor,
            layer: 1,
            populateText: true,
            text: !isLastIndex ? '{valueY}' : '{valueYTotal}',
            textAlign: 'center',
            textBaseline: 'bottom',
          }),
        });
      });
    });
  }

  protected setupColumnRules() {
    this.rule('ColumnSeries').adapters.add('fill', (stroke, target) => {
      return isIndex(<string>target.get('valueYField')) >= 0
        ? am5.color(StockAgeHelper.getColor(Number(isIndex(<string>target.get('valueYField'))), 'fill'))
        : stroke;
    });
  }

  protected setupTooltipRules() {
    this.rule('ColumnSeries').events.on('datavalidated', (event) => {
      event.target.columns.template.events.on('pointerover', (val) => {
        const valueY = event.target.get('valueYField');

        if (
          valueY === StockAgePropsEnum.countPerBucket0_10Days ||
          valueY === StockAgePropsEnum.countPerBucket365Days ||
          valueY === 'lastIndex'
        ) {
          this.tooltipHTML = '';
        } else {
          this.tooltipHTML =
            "<div style='font-size: 14px; padding: 8px; background-color: #282f47; width: 17.875rem; min-height:10.5rem; max-height: 12.5rem; border-radius: 5px;'>Age distribution in cluster<div style='margin-top: 10px;'><div style='display:flex; height: 66px; justify-content:space-evenly; align-items:end; width:100%; border-bottom: 1px solid white;'>";

          let legendHTML =
            "<div style='display:grid; grid-gap:8px; grid-template-columns:repeat(3, 1fr); margin-top:18px;'>";
          const ctx = val.target.dataItem?.dataContext as GenericChartFields;

          if (ctx && valueY) {
            StockAgeHelper.buildTooltipColumns(ctx, valueY)?.forEach((element: any, index) => {
              const proportion =
                typeof element.value === 'number'
                  ? Math.round(50 * (Number(element.value) / Number(ctx[valueY]))) + 16
                  : 0;
              this.tooltipHTML += proportion
                ? `<div style='height: ${proportion}px; width:24px; background-color: ${StockAgeHelper.getColor(
                    index,
                    'fill'
                  )}; font-size: 0.685rem; color: ${StockAgeHelper.getColor(
                    index,
                    'text'
                  )}; display: flex; align-items: center; justify-content: center'>${element.value}</div>`
                : '';
              legendHTML += `<div style='display:flex; align-items:center; flex-direction:row; justify-content: flex-start; gap:8px; opacity: ${
                proportion ? 1 : 0.5
              }'><div style='height:14px; width:14px; background:${StockAgeHelper.getColor(
                index,
                'fill'
              )};'></div> <div style='font-size: 12px;'>${element.description}</div></div>`;
            });
            this.tooltipHTML += `</div>${legendHTML}</div>`;
          }
        }

        event.target.columns.template.setAll({
          layer: 1,
          tooltip: FtdAm5ChartsThemeHelper.getTooltip(this._root, 'horizontal', true),
          tooltipHTML: this.tooltipHTML,
          tooltipY: am5.p50,
        });
      });
    });
    this.rule('Tooltip').setAll({
      centerX: am5.p50,
      centerY: am5.p50,
    });
  }
}
