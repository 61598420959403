import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ftd-stepper',
  styleUrls: ['./ftd-stepper.component.scss'],
  templateUrl: './ftd-stepper.component.html',
})
export class FtdStepperComponent {
  @Input() currentStep: number = 1;
  @Input() stepperData: string[] = [];
  @Input() maxStep = 0;
  @Output() goToStepEvent: EventEmitter<number> = new EventEmitter<number>();

  goToStep(step: number): void {
    if (this.maxStep >= step && step > 0) {
      this.currentStep = step;
      this.goToStepEvent.emit(step);
    }
  }

  getShowStepLabel(index: number): boolean {
    return this.maxStep <= index + 1;
  }

  getShowStepIcon(index: number): boolean {
    return this.maxStep > index + 1;
  }
}
