import { Component, EventEmitter, Input, Output } from '@angular/core';

let _id: number = 0;

export type OptionSelectorContext = 'percentage' | 'currency';

@Component({
  selector: 'app-ftd-button-option-selector',
  styleUrls: ['./ftd-button-option-selector.component.scss'],
  templateUrl: './ftd-button-option-selector.component.html',
})
export class FtdButtonOptionSelectorComponent {
  // Event emitted when the toggle button is clicked.
  @Output() optionSelectorChange: EventEmitter<OptionSelectorContext> = new EventEmitter<OptionSelectorContext>();

  @Input() disabled: boolean = false;
  @Input() id?: string;
  @Input() label?: string = '';
  @Input() percentage: string = '%';
  @Input() currency: string = '€';
  selectedOption: OptionSelectorContext = 'percentage';

  constructor() {
    _id += 1;
    this.id = `ftd-button-option-selector-${_id}`;
  }

  /**
   * EmitCheckedChange $event emitter
   * @param $event
   */
  emitCheckedChange($event: OptionSelectorContext): void {
    this.selectedOption = $event;
    this.optionSelectorChange.emit($event);
  }
}
