import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ftd-expansion-panel',
  styleUrls: ['./ftd-expansion-panel.component.scss'],
  templateUrl: './ftd-expansion-panel.component.html',
})
export class FtdExpansionPanelComponent {
  @Input() set expanded(value: boolean) {
    this._expanded = value;
    this.emitExpanded.emit(value);
  }

  get expanded(): boolean {
    return this._expanded;
  }

  private _expanded: boolean = false;

  @Input() title: string = '';
  @Input() titleIcon: string | string[] = '';
  @Input() iconPath = '';
  @Input() description: string = '';
  @Input() descriptionIcon: string = '';
  @Input() disabled: boolean = false;
  @Input() hideRightToggle: boolean = false;
  @Input() hideLeftToggle: boolean = true;
  @Input() panelHeaderFxLayout: string = '';
  @Input() panelHeaderFxLayoutAlign: string = '';
  @Input() fillWidth: boolean = false;
  @Output() emitExpanded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() isCustomDescription: boolean = false;
}
