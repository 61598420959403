import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ftdCurrency',
})
export class FtdCurrencyPipe implements PipeTransform {
  transform(
    value: number | string,
    precision: number = 2,
    decimalSymbol: string = '.',
    thousandSymbol: string = ',',
    prefix: string = '',
    suffix: string = '€'
  ): string {
    // Convert the value to a number if it is a string
    const numericValue: number = typeof value === 'string' ? parseFloat(value) : value;

    // Check if the value is not an invalid number
    if (isNaN(numericValue)) {
      return '';
    }

    // Apply the precision to the value
    const formattedValue: string = numericValue.toFixed(precision);

    // Split the value integer and decimal parts
    const parts: string[] = formattedValue.split('.');
    const integerPart: string = parts[0];
    const decimalPart: string = parts[1] || '';

    // Add the thousand separator to the integer part
    const integerWithSeparators: string = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, thousandSymbol);

    // Construct the final formatted value
    return `${prefix}${integerWithSeparators}${decimalSymbol}${decimalPart}${suffix}`;
  }
}
