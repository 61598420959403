<div class="ftd-actions-toolbar-content">
  <span [attr.data-test]="'actions-toolbar-selected-price-points'">{{ data.message }}</span>
  <div class="ftd-actions-toolbar-text"></div>
  <div class="ftd-actions-toolbar-actions">
    <div
      (click)="onWithdrawClicked()"
      class="actions-withdraw"
      [class.disabled]="data.actions.isWithdrawDisabled"
      *ngIf="data.actions.isWithdraw"
      [attr.data-test]="'actions-toolbar-withdraw-price-points'"
    >
      <mat-icon>block</mat-icon>
      <span>WITHDRAW</span>
    </div>

    <div
      (click)="onRejectClicked()"
      class="actions-reject"
      [class.disabled]="data.actions.isRejectDisabled"
      *ngIf="data.actions.isReject"
      [attr.data-test]="'actions-toolbar-reject-price-points'"
    >
      <mat-icon>close</mat-icon>
      <span>REJECT</span>
    </div>

    <div
      (click)="onApproveClicked()"
      class="actions-approve"
      [class.disabled]="data.actions.isApproveDisabled"
      *ngIf="data.actions.isApprove"
      [attr.data-test]="'actions-toolbar-approve-price-points'"
    >
      <mat-icon>task_alt</mat-icon>
      <span>APPROVE</span>
    </div>

    <div
      (click)="onEditPricesClicked()"
      class="actions-edit-prices"
      [class.disabled]="data.actions.isEditPricesDisabled"
      *ngIf="data.actions.isEditPrices"
      [attr.data-test]="'actions-toolbar-edit-price-points'"
    >
      <mat-icon>edit</mat-icon>
      <span>EDIT PRICES</span>
    </div>

    <div
      (click)="onOverridePricesClicked()"
      class="actions-override-prices"
      [class.disabled]="data.actions.isOverridePricesDisabled"
      *ngIf="data.actions.isOverridePrices"
      [attr.data-test]="'actions-toolbar-override-price-points'"
      [matTooltip]="DISABLE_OVERRIDE_TOOLTIP_TEXT"
    >
      <mat-icon>refresh</mat-icon>
      <span>OVERRIDE</span>
    </div>

    <div
      (click)="onSubmitClicked()"
      class="actions-submit"
      [class.disabled]="data.actions.isSubmitPricesDisabled"
      *ngIf="data.actions.isSubmitPrices"
      [matTooltip]="data.actions.isSubmitPricesDisabledMessage"
      [attr.data-test]="'actions-toolbar-submit-price-points'"
    >
      <mat-icon>check_circle</mat-icon>
      <span>SUBMIT</span>
    </div>
  </div>
</div>
