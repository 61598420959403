<div>
  <app-crosschecks-traffic-lights
    [enableRedirect]="false"
    [isCrosscheckLegend]="true"
    [status]="{ color: 'green', arrow: null }"
  ></app-crosschecks-traffic-lights>
  <p>{{ trafficLightsColorDescriptionMap.get('green') }}</p>
</div>
<div>
  <app-crosschecks-traffic-lights
    [enableRedirect]="false"
    [isCrosscheckLegend]="true"
    [status]="{ color: 'red', arrow: null }"
  ></app-crosschecks-traffic-lights>
  <p>{{ trafficLightsColorDescriptionMap.get('red') }}</p>
</div>
<div>
  <app-crosschecks-traffic-lights
    [enableRedirect]="false"
    [isCrosscheckLegend]="true"
    [status]="{ color: 'grey', arrow: null }"
  ></app-crosschecks-traffic-lights>
  <p>{{ trafficLightsColorDescriptionMap.get('grey') }}</p>
</div>
<div id="table-header-actions-divider"></div>
<div>
  <app-crosschecks-traffic-lights
    [enableRedirect]="false"
    [isCrosscheckLegend]="true"
    [status]="{ color: 'green', arrow: 'keep' }"
  ></app-crosschecks-traffic-lights>
  <p>{{ trafficLightsMap.get('keep') }}</p>
</div>
<div>
  <app-crosschecks-traffic-lights
    [enableRedirect]="false"
    [isCrosscheckLegend]="true"
    [status]="{ color: 'red', arrow: 'up' }"
  ></app-crosschecks-traffic-lights>
  <app-crosschecks-traffic-lights
    [enableRedirect]="false"
    [isCrosscheckLegend]="true"
    [status]="{ color: 'red', arrow: 'down' }"
  ></app-crosschecks-traffic-lights>
  <p>{{ trafficLightsMap.get('upDown') }}</p>
</div>
