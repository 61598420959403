import { Component, OnDestroy, OnInit } from '@angular/core';
import { IAppMessage } from '../../models/app-message.model';
import { MessageService } from '../../services/message/message.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-messages',
  styleUrls: ['./messages.component.scss'],
  templateUrl: './messages.component.html',
})
export class MessagesComponent implements OnInit, OnDestroy {
  messagesList: IAppMessage[] = [];

  private subscriptions: Subscription[] = [];

  constructor(private messageService: MessageService) {}

  ngOnInit(): void {
    const subscription = this.messageService.getNextMessage().subscribe({
      next: (message) => {
        this.messagesList.push(message);
      },
    });
    this.subscriptions.push(subscription);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }

  closeClicked(messageIndex: number): void {
    this.messagesList.splice(messageIndex, 1);
  }
}
