<mat-card
  class="crosscheck-settings-wrapper"
  [ngClass]="crosscheck.threshold === 'Within target range' ? 'crosscheck-settings-wrapper--big-card' : ''"
>
  <div class="crosscheck-settings-title-wrapper">
    <span class="crosscheck-settings-title">{{ crosscheck.name }}</span>
  </div>

  <mat-divider></mat-divider>

  <div class="crosscheck-settings-body-wrapper">
    <div class="settings-column-wrapper">
      <span class="settings-column-title">Weighting</span>
      <span class="settings-column-value">{{ crosscheck.weighting }}</span>
    </div>
    <div class="settings-column-wrapper">
      <span class="settings-column-title">Threshold</span>
      <span class="settings-column-value">{{ crosscheck.threshold }}</span>
    </div>
  </div>
</mat-card>
