<app-ftd-custom-modal>
  <app-ftd-custom-modal-header
    [titleLeftMatIcon]="titleLeftMatIcon"
    [colorLeftMatIcon]="colorLeftMatIcon"
    [title]="title"
  ></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body>
    <div class="mb-4">
      <span> {{ description }} </span>
    </div>

    <app-ftd-progress-bar
      [value]="progressBarValue"
      [mode]="isAsyncCallbackDefined ? 'indeterminate' : 'determinate'"
    ></app-ftd-progress-bar>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer (cancelButtonClicked)="cancelButtonClicked()"> </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
