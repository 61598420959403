import { ActionToolbarSubmitStatusType } from '../../models/app-common.type';
import { AuthService } from '../../../auth/services/auth.service';
import { Component, Inject, OnDestroy } from '@angular/core';
import { EditScenarioModalComponent } from '../../../scenario/modals/edit-scenario-modal/edit-scenario-modal.component';
import { FtdModalSize } from '../ftd-modal/ftd-modal-size.enum';
import { GovernanceService } from '../../../matrix-view/services/governance/governance.service';
import { IMatrixViewDataSourceItem } from '../../../matrix-view/models/matrix-view.model';
import { ISnackbarActionsData } from '../../models/ftd-actions-toolbar.model';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatrixViewDataService } from '../../../matrix-view/services/matrix-view-data/matrix-view-data.service';
import { MatrixViewFormService } from '../../../matrix-view/services/matrix-view-form/matrix-view-form.service';
import { OverrideStatusModalComponent } from '../../../scenario/modals/override-status-modal/override-status-modal.component';
import { SubmitScenarioModalComponent } from '../../../scenario/modals/submit-scenario-modal/submit-scenario-modal.component';
import { SubmitStatusModalComponent } from '../../../scenario/modals/submit-status-modal/submit-status-modal.component';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';

@Component({
  selector: 'app-ftd-actions-toolbar',
  styleUrls: ['./ftd-actions-toolbar.component.scss'],
  templateUrl: './ftd-actions-toolbar.component.html',
})
/**
 * @class
 * FtdActionsToolbarComponent
 */
export class FtdActionsToolbarComponent implements OnDestroy {
  public currentUser!: User | null;
  public DISABLE_OVERRIDE_TOOLTIP_TEXT: string = '';
  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param data
   * @param snackBarRef
   * @param dialog
   * @param matrixViewDataService
   * @param authService
   * @param matrixViewFormService
   * @param governanceService
   */
  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: ISnackbarActionsData,
    private snackBarRef: MatSnackBarRef<FtdActionsToolbarComponent>,
    public dialog: MatDialog,
    private matrixViewDataService: MatrixViewDataService,
    private authService: AuthService,
    private matrixViewFormService: MatrixViewFormService,
    public governanceService: GovernanceService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
    this.isActionsPossible();

    this.DISABLE_OVERRIDE_TOOLTIP_TEXT = data.actions.isOverridePricesDisabled
      ? 'You can only override prices in exported status'
      : '';
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * IsActionsPossible
   * Disabled action if one records in the same status doesn't match the requirements
   */
  isActionsPossible(): void {
    this.resetActionsDisabled();

    this.data?.matrixViewDataSourceItems?.forEach((item: IMatrixViewDataSourceItem): void => {
      if (!this.governanceService.isWithdrawStatusPricePointPossible(item, this.currentUser)) {
        this.data.actions.isWithdrawDisabled = true;
      }

      if (!this.governanceService.isPromoteStatusPricePointPossible(item, this.currentUser)) {
        this.data.actions.isApproveDisabled = true;
      }

      if (!this.governanceService.isDemoteStatusPricePointPossible(item, this.currentUser)) {
        this.data.actions.isRejectDisabled = true;
      }
    });
  }

  /**
   * ResetActionsDisabled
   */
  private resetActionsDisabled(): void {
    this.data.actions.isWithdrawDisabled = false;
    this.data.actions.isRejectDisabled = false;
    this.data.actions.isApproveDisabled = false;
  }

  /**
   * OnClose
   */
  onClose(): void {
    this.snackBarRef.dismiss();
  }

  /**
   * OnWithdrawClicked
   */
  onWithdrawClicked(): void {
    this.submitStatus('withdraw_status');
  }

  /**
   * OnRejectClicked
   */
  onRejectClicked(): void {
    this.submitStatus('demote_status');
  }

  /**
   * OnApproveClicked
   */
  onApproveClicked(): void {
    this.submitStatus('promote_status');
  }

  /**
   * OnEditPricesClicked
   */
  onEditPricesClicked(): void {
    this.openEditScenarioModal();
  }

  /**
   * OnEditPricesClicked
   */
  onOverridePricesClicked(): void {
    if (this.data.actions.isOverridePrices && !this.data.actions.isOverridePricesDisabled) {
      const dialogRef: MatDialogRef<OverrideStatusModalComponent> = this.dialog.open(OverrideStatusModalComponent, {
        maxWidth: FtdModalSize.SM,
        width: FtdModalSize.SM,
      });

      const subscription: Subscription = dialogRef.afterClosed().subscribe((data: { areYouSure: boolean }): void => {
        if (data && data.areYouSure) {
          this.openSubmitScenarioModal('override');
        }
      });
      this.subscriptions.push(subscription);
    }
  }

  /**
   * OnSubmitClicked
   */
  onSubmitClicked(): void {
    if (this.data.actions.isSubmitPrices && !this.data.actions.isSubmitPricesDisabled) {
      this.openSubmitScenarioModal();
    }
  }

  /**
   * IsSubmitStatusPossible
   * @param status
   */
  isSubmitStatusPossible(status: ActionToolbarSubmitStatusType): boolean {
    let isPossible: boolean = true;

    if (status === 'promote_status' && this.data.actions.isApproveDisabled) {
      isPossible = false;
    }

    if (status === 'demote_status' && this.data.actions.isRejectDisabled) {
      isPossible = false;
    }

    if (status === 'withdraw_status' && this.data.actions.isWithdrawDisabled) {
      isPossible = false;
    }

    return isPossible;
  }

  /**
   * SubmitStatus
   * @param status
   */
  submitStatus(status: ActionToolbarSubmitStatusType): void {
    if (!this.isSubmitStatusPossible(status)) {
      return;
    }

    const dialogRef: MatDialogRef<SubmitStatusModalComponent> = this.dialog.open(SubmitStatusModalComponent, {
      data: { matrixViewDataSourceItems: this.data.matrixViewDataSourceItems, status: status },
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription: Subscription = dialogRef
      .afterClosed()
      .subscribe(
        (data: {
          requestStatus: string;
          status: ActionToolbarSubmitStatusType;
          matrixViewDataSourceItems: IMatrixViewDataSourceItem[];
        }): void => {
          if (data && data.requestStatus === 'success') {
            this.matrixViewDataService.refreshMatrixViewDataSubject$.next({
              granularitiesToRefreshData: data.matrixViewDataSourceItems.map(
                (item: IMatrixViewDataSourceItem) => item.granularity
              ),
            });

            this.onClose();
          }
        }
      );
    this.subscriptions.push(subscription);
  }

  /**
   * OpenSubmitScenarioModal
   * @context
   */
  openSubmitScenarioModal(context: 'override' | 'submit' = 'submit'): void {
    const dialogRef: MatDialogRef<SubmitScenarioModalComponent> = this.dialog.open(SubmitScenarioModalComponent, {
      data: { context: context, data: this.matrixViewFormService.getPriceSubmissionsSubmitModal() },
      maxWidth: FtdModalSize.L,
    });

    const subscription: Subscription = dialogRef.afterClosed().subscribe((): void => {});
    this.subscriptions.push(subscription);
  }

  /**
   * OpenEditScenarioModal
   */
  openEditScenarioModal(): void {
    const dialogRef: MatDialogRef<EditScenarioModalComponent> = this.dialog.open(EditScenarioModalComponent, {
      width: FtdModalSize.SM,
    });

    const subscription: Subscription = dialogRef.afterClosed().subscribe((): void => {});
    this.subscriptions.push(subscription);
  }
}
