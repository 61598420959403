import { AwsRum } from 'aws-rum-web';
import { FtdNotifierService } from '../../../common/services/ftd-notifier/ftd-notifier.service';
import { GRAPHQL_ERRORS } from '../../error-handling/graphql/error-handler';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { IGraphQLErrorResponse, IGraphQLResponse } from '../../error-handling/graphql/models/graphql-error.model';
import { Injectable } from '@angular/core';
import { MessageService } from 'src/app/common/services/message/message.service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap } from 'rxjs/operators';
declare let window: any;
@Injectable()
export class GraphQLGlobalErrorInterceptor implements HttpInterceptor {
  constructor(private notifierService: FtdNotifierService, private messageService: MessageService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      tap((response) => {
        // Check if any erros are presen tin response body
        const errors: IGraphQLErrorResponse[] = (response as HttpResponse<IGraphQLResponse<unknown>>).body?.errors!;
        if (errors) {
          // For every error display error notification
          errors.forEach((error: IGraphQLErrorResponse) => {
            const customErrorMessage = GRAPHQL_ERRORS[error.path[0]];
            /*
             * If errorType is already know and we have custom message display
             * else as a fallback logic display error coming from graphQL
             */
            const errorMessage =
              customErrorMessage && customErrorMessage[error.errorType]
                ? customErrorMessage[error.errorType]
                : error.message;
            // Display error notification
            this.messageService.showError(errorMessage);
            if (environment?.environment === 'dev' && window.awsRum) {
              (window.awsRum as AwsRum).recordError(errorMessage);
            }
          });
        }
      })
    );
  }
}
