<mat-form-field
  [formGroup]="form"
  [floatLabel]="'auto'"
  appearance="outline"
  id="ftd-multi-dropdown-{{ id }}"
  *ngIf="!isFormArray"
>
  <mat-label *ngIf="isLabelVisible">{{ label }}</mat-label>
  <mat-select
    [id]="id"
    [formControlName]="controlName"
    [multiple]="true"
    [placeholder]="label"
    [compareWith]="distinctById"
    [disableOptionCentering]="true"
    (closed)="emitOptionsClosed()"
  >
    <mat-option *ngIf="isSearchVisible" class="map-option-search-input">
      <ngx-mat-select-search
        placeholderLabel="Search"
        [formControlName]="controlName + 'Search'"
        noEntriesFoundLabel="No matching found"
      >
      </ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let option of options; trackBy: optionTrackBy" [value]="option.value">
      <div class="option-wrapper">
        <div *ngIf="showBrandIcon" class="brand">
          <img src="./assets/images/brands/{{ option.label }}.svg" alt="{{ option.label }}" />
        </div>
        {{ option.label }}
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
<mat-form-field
  [formGroup]="form"
  [floatLabel]="'auto'"
  appearance="outline"
  id="ftd-multi-dropdown-{{ id }}"
  *ngIf="isFormArray"
>
  <mat-label *ngIf="isLabelVisible">{{ label }}</mat-label>
  <mat-select
    [id]="id"
    [formArrayName]="controlName"
    [multiple]="true"
    [compareWith]="distinctById"
    [disableOptionCentering]="true"
    [placeholder]="label"
  >
    <mat-option *ngIf="isSearchVisible">
      <ngx-mat-select-search
        placeholderLabel="Search"
        [formControlName]="controlName + '_search'"
      ></ngx-mat-select-search>
    </mat-option>

    <mat-option *ngFor="let option of options; trackBy: optionTrackBy" [value]="option.value">
      <div class="option-wrapper">
        <div *ngIf="showBrandIcon" class="brand">
          <img src="./assets/images/brands/{{ option.label }}.svg" alt="{{ option.label }}" />
        </div>
        {{ option.label }}
      </div>
    </mat-option>
  </mat-select>
</mat-form-field>
