import { AuthService } from '../../../auth/services/auth.service';
import { Component, HostListener, Input, OnDestroy, OnInit } from '@angular/core';
import { ContextService } from '../../services/context/context.service';
import { FormGroup } from '@angular/forms';
import { FtdActionsToolbarComponent } from '../../../common/components/ftd-actions-toolbar/ftd-actions-toolbar.component';
import { FtdFullscreenService } from 'src/app/common/services/ftd-fullscreen/ftd-fullscreen.service';
import { FtdNotifierService } from 'src/app/common/services/ftd-notifier/ftd-notifier.service';
import { ISelectedFiltersFormConfirmed } from '../../models/app.model';
import { MatSnackBar, MatSnackBarRef } from '@angular/material/snack-bar';
import { MatrixViewFormService } from '../../services/matrix-view-form/matrix-view-form.service';
import { SUBMIT_SCENARIO_MESSAGES } from '../matrix-view-table-header/matrix-view-table-header.component';
import { ScenarioContext } from '../../../common/enums/scenario-context.enum';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';

@Component({
  selector: 'app-matrix-view-wrapper',
  styleUrls: ['./matrix-view-wrapper.component.scss'],
  templateUrl: './matrix-view-wrapper.component.html',
})
export class MatrixViewWrapperComponent implements OnInit, OnDestroy {
  @Input() title: string = 'Main Scenario';
  @Input() isReturnButtonHidden: boolean = true;
  @Input() isMatrixViewSideFiltersHidden: boolean = false;
  @Input() isMatrixViewDataHidden: boolean = false;
  @Input() isDescriptionHidden: boolean = true;
  @Input() isExportButtonHidden: boolean = false;
  @Input() isApproveScenarioButtonHidden: boolean = true;
  @Input() isUnlockScenarioButtonHidden: boolean = true;
  @Input() isSaveButtonHidden: boolean = true;
  @Input() isSubmitScenarioButtonHidden: boolean = false;
  @Input() isTitleHidden: boolean = true;
  @Input() description: string = '';
  private isSubmitScenarioButtonDisabled: boolean = true;

  private matSnackBarRef: MatSnackBarRef<FtdActionsToolbarComponent> | null = null;

  private formValidRowsChecked: FormGroup[] = [];
  formValidRows: FormGroup[] = [];
  private formRowsChecked: FormGroup[] = [];
  public currentUser?: User | null;
  public context: ScenarioContext = ScenarioContext.mainScenario;

  private subscriptions: Subscription[] = [];

  isSaveDisabled: boolean = true;
  selectedFiltersValue!: ISelectedFiltersFormConfirmed;
  granularityDataLoaded: boolean = false;
  granularityHasData: boolean = false;
  isFullScreenDisabled = true;

  /**
   * @constructor
   * @param fullscreenService
   * @param toastService
   * @param matrixViewFormService
   * @param authService
   * @param contextService
   * @param snackBar
   */
  constructor(
    private fullscreenService: FtdFullscreenService,
    private toastService: FtdNotifierService,
    private matrixViewFormService: MatrixViewFormService,
    private authService: AuthService,
    private contextService: ContextService,
    private snackBar: MatSnackBar
  ) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  get isFilterSelected(): boolean {
    return this.selectedFiltersValue.selectedFilterList.length > 0;
  }

  /**
   * EnableFullscreen
   */

  ngOnInit() {
    this.context = this.contextService.getCurrentScenarioContext();
    this.initMatrixViewFormValueChanged();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  enableFullscreen() {
    this.fullscreenService.isFullscreenDisable = !this.fullscreenService.isFullscreenDisable;
    this.isFullScreenDisabled = this.fullscreenService.isFullscreenDisable;
    if (!this.isFullScreenDisabled) {
      const duration: number = 0;
      const showCloseButton = true;
      if (!this.matSnackBarRef) {
        this.toastService.showInfo('Press [Alt + Enter] to exit full screen', duration, showCloseButton);
      }
    }
  }

  /**
   * HandleSelectFiltersValue
   * @param selectedValue
   */
  handleSelectFiltersValue(selectedValue: ISelectedFiltersFormConfirmed): void {
    this.selectedFiltersValue = selectedValue;
  }

  /**
   * HandleGranularityDataLoaded
   * @param granularityDataLoaded
   */
  handleGranularityDataLoaded(granularityDataLoaded: boolean): void {
    this.granularityDataLoaded = granularityDataLoaded;
  }

  /**
   * HandleGranularityHasData
   * @param granularityHasData
   */
  handleGranularityHasData(granularityHasData: boolean): void {
    this.granularityHasData = granularityHasData;
  }

  private initMatrixViewFormValueChanged(): void {
    const subscription: Subscription = this.matrixViewFormService.formValueChangedSubject$.subscribe((): void => {
      this.formValidRowsChecked = this.matrixViewFormService.getFormValidRowsChecked();
      this.formValidRows = this.matrixViewFormService.getFormValidRows();
      this.triggerActionsToolbarHandler();
    });
    this.subscriptions.push(subscription);
  }

  /**
   * @host
   * @listens keydown: alt + enter
   */
  @HostListener('document:keydown.alt.enter', ['$event'])
  onKeyDown() {
    this.enableFullscreen();
  }

  private triggerActionsToolbarHandler(): void {
    if (
      (this.matrixViewFormService.getFormRowsChecked().length &&
        this.matrixViewFormService.getFormRowsChecked().length !== this.formRowsChecked.length) ||
      (this.matrixViewFormService.getFormRowsChecked().length &&
        this.isSubmitScenarioButtonDisabled !==
          !(this.formValidRowsChecked.length && this.matrixViewFormService.getForm().valid))
    ) {
      this.isSubmitScenarioButtonDisabled = !(
        this.formValidRowsChecked.length && this.matrixViewFormService.getForm().valid
      );
      this.actionsToolbarHandler();
    } else if (!this.matrixViewFormService.getFormRowsChecked().length) {
      this.snackBar?.dismiss();
      this.matSnackBarRef = null;
    }
    this.isSaveDisabled = !this.formValidRows.length;
    this.formRowsChecked = this.matrixViewFormService.getFormRowsChecked();
  }

  private actionsToolbarHandler(): void {
    if (!this.matSnackBarRef) {
      this.matSnackBarRef = this.snackBar.openFromComponent(FtdActionsToolbarComponent, {
        data: {
          actions: {
            isApprove: this.context === ScenarioContext.mainScenario,
            isApproveDisabled: this.context === ScenarioContext.mainScenario ? false : false,
            isEditPrices: this.context === ScenarioContext.userScenario,
            isOverridePrices:
              this.context === ScenarioContext.userScenario &&
              !this.isSubmitScenarioButtonHidden &&
              this.currentUser?.permissions?.getHasPermissionToSubmitToMainScenario(),
            isOverridePricesDisabled: ScenarioContext.userScenario
              ? this.isSubmitScenarioButtonDisabled ||
                Boolean(
                  !this.matrixViewFormService.getFormRowsCheckedAndExported().length ||
                    this.matrixViewFormService.getFormRowsChecked().length !==
                      this.matrixViewFormService.getFormRowsCheckedAndExported().length
                )
              : true,
            isReject: this.context === ScenarioContext.mainScenario,
            isRejectDisabled: this.context === ScenarioContext.mainScenario ? false : false,
            isSubmitPrices:
              this.context === ScenarioContext.userScenario &&
              !this.isSubmitScenarioButtonHidden &&
              this.currentUser?.permissions?.getHasPermissionToSubmitToMainScenario(),
            isSubmitPricesDisabled: ScenarioContext.userScenario ? this.isSubmitScenarioButtonDisabled : true,
            isSubmitPricesDisabledMessage: ScenarioContext.userScenario ? this.submitScenarioDisableStateMessage() : '',
            isWithdraw: this.context === ScenarioContext.mainScenario,
            isWithdrawDisabled: this.context === ScenarioContext.mainScenario ? false : false,
          },
          matrixViewDataSourceItems: this.matrixViewFormService
            .getFormRowsChecked()
            .map((item: FormGroup) => item.value.scenarioData),
          message: `${this.matrixViewFormService.getFormRowsChecked().length} Model(s) selected`,
        },
        horizontalPosition: 'center',
        panelClass: 'ftd-actions-toolbar',
      });
    } else {
      if (ScenarioContext.userScenario) {
        this.matSnackBarRef.instance.data.actions.isSubmitPricesDisabled = this.isSubmitScenarioButtonDisabled;
        this.matSnackBarRef.instance.data.actions.isOverridePricesDisabled =
          this.isSubmitScenarioButtonDisabled ||
          Boolean(
            !this.matrixViewFormService.getFormRowsCheckedAndExported().length ||
              this.matrixViewFormService.getFormRowsChecked().length !==
                this.matrixViewFormService.getFormRowsCheckedAndExported().length
          );
        this.matSnackBarRef.instance.data.actions.isSubmitPricesDisabledMessage =
          this.submitScenarioDisableStateMessage();
      }

      this.matSnackBarRef.instance.data.matrixViewDataSourceItems = this.matrixViewFormService
        .getFormRowsChecked()
        .map((item: FormGroup) => item.value.scenarioData);
      this.matSnackBarRef.instance.data.message = `${
        this.matrixViewFormService.getFormRowsChecked().length
      } Model(s) selected`;
      this.matSnackBarRef.instance.isActionsPossible();
    }
  }

  /**
   * SubmitScenarioDisableStateMessage
   * @return string
   */
  submitScenarioDisableStateMessage(): string {
    switch (true) {
      case !(this.matrixViewFormService.getForm().touched || this.matrixViewFormService.getForm().dirty):
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_PRICE_AND_DATE;
      case this.matrixViewFormService.getForm().invalid &&
        this.matrixViewFormService.isFormRowsValidPriceMissingEffectiveDate():
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_DATE;
      case this.matrixViewFormService.getForm().invalid &&
        this.matrixViewFormService.isFormRowsValidEffectiveDateMissingPrice():
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_PRICE;
      case this.isSubmitScenarioButtonDisabled:
        return SUBMIT_SCENARIO_MESSAGES.INCLUDE_PRICE_AND_DATE;
      default:
        return '';
    }
  }
}
