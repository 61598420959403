import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Base64Utils } from '../../../../common/utils/base64.utils';
import { ChartLegendType } from '../../../../common/components/ftd-charts/enums/chart-legend-type.enum';
import { CrosschecksChartContext } from '../../../enums/crosschecks-chart-context-messages.enum';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { IChartLegendConfig } from '../../../../common/components/ftd-charts/models/ftd-chart-legend-config.model';
import { IFtdDropdownOption } from '../../../../common/models/ftd-dropdown-option.model';
import {
  IGenericChartData,
  IGenericChartHeadings,
} from '../../../../common/components/ftd-charts/models/ftd-generic-chart.model';
import { IGranularityDto } from '../../../../matrix-view/models/api.model';
import { LEASE_RETURNS_CHART_DATA_MOCK } from '../../../../common/components/ftd-charts/mocks/chart-data-mock';
import { Subscription } from 'rxjs';
import { TrafficLightsColor } from 'src/app/crosschecks/types/traffic-lights.type';

@Component({
  selector: 'app-lease-returns',
  styleUrls: ['./lease-returns.component.scss'],
  templateUrl: './lease-returns.component.html',
})

/**
 * @class
 * LeaseReturnsComponent
 */
export class LeaseReturnsComponent implements OnInit, OnDestroy, AfterViewInit {
  /**
   * Granularity for which chart data need to be fetched
   */

  filters!: IGranularityDto;
  private _granularity: IGranularityDto = <IGranularityDto>{};
  @Input() set granularity(granularityData: string) {
    this._granularity = Base64Utils.decodeAtobToJson(granularityData);
    this.filters = this._granularity;
  }

  /**
   * Traffic light value coming from overview request on Sales Pipeline
   */
  @Input() crosscheckIndication?: TrafficLightsColor;
  @Input() crosscheckIndicationForecasted?: TrafficLightsColor;

  crosschecksChartContext: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  LEASE_RETURNS_CHART_DATA_MOCK: IGenericChartData = LEASE_RETURNS_CHART_DATA_MOCK;
  initChart: boolean = false;
  isChartValuesVisible: boolean = false;

  dropdownDisabled: boolean[] = [true, true];
  dropdownList: string[] = ['Leasing Parameters', 'Sales Channel'];
  dropdownOptions: IFtdDropdownOption<string>[][] = [
    [
      {
        id: 1,
        label: 'Actual Parameters',
        value: 'actualParameters',
      },
    ],
    [
      {
        id: 1,
        label: 'Private (inc. employee sales)',
        value: 'private',
      },
    ],
  ];

  chartLegend: string[] = [];
  chartLegendForm!: FormGroup;
  chartLegendsConfigs: IChartLegendConfig[] = [
    {
      fieldName: 'averageLeaseRateReturn',
      imagePath: './assets/images/charts/legends/line-blue.svg',
      label: 'Avg. lease rate returning customers',
      legendImageHeight: 4,
      legendImageWidth: 31,
      type: ChartLegendType.IMAGE,
    },
    {
      fieldName: 'totalLeaseReturns',
      label: 'Total lease returns',
      legendColor: '#79DDC0',
      type: ChartLegendType.SQUARE_DIV,
    },
    {
      fieldName: 'currentLeaseRate',
      imagePath: './assets/images/charts/legends/line-orange.svg',
      label: 'Current indic. lease rate',
      legendImageHeight: 4,
      legendImageWidth: 30,
      type: ChartLegendType.IMAGE,
    },
    {
      fieldName: 'forecastAverageLeaseRateReturn',
      imagePath: './assets/images/charts/legends/line-dotted-blue.svg',
      label: 'Expected avg. lease rate returning customers',
      legendImageHeight: 4,
      legendImageWidth: 31,
      type: ChartLegendType.IMAGE,
    },
    {
      fieldName: 'expectedLeaseRate',
      imagePath: './assets/images/charts/legends/line-dotted-orange.svg',
      label: 'Expected indic. lease rate',
      legendImageHeight: 4,
      legendImageWidth: 31,
      type: ChartLegendType.IMAGE,
    },
    {
      fieldName: 'expectedTotalLeaseReturns',
      label: 'Expected total lease returns',
      legendColor: '#D6D6D6',
      type: ChartLegendType.SQUARE_DIV,
    },
    {
      fieldName: 'returningCustomersCompared',
      imagePath: './assets/images/charts/legends/percentage-blue.svg',
      label: 'Returning customers compared to retail',
      legendImageHeight: 20,
      legendImageWidth: 30,
      type: ChartLegendType.IMAGE,
    },
  ];

  private subscriptions: Subscription[] = [];

  constructor(private formBuilder: FormBuilder) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initForm();
    this.loadLeaseReturnsData();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe();
  }

  /**
   * NgAfterViewInit
   */
  ngAfterViewInit(): void {
    this.initChart = true;
  }

  ngUnsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * ShowValuesToggle
   * @param value
   */
  showValuesToggle(value: boolean): void {
    this.isChartValuesVisible = value;
  }

  /**
   * InitForm
   * @private
   */
  private initForm(): void {
    this.chartLegendForm = this.formBuilder.group({
      leaseReturnsFields: new FormControl<string[]>([]),
    });

    const chartLegendFormSubscription: Subscription =
      this.chartLegendForm.controls.leaseReturnsFields.valueChanges.subscribe((selectedLegend: string[]): void => {
        this.chartLegend = selectedLegend;
      });
    this.subscriptions.push(chartLegendFormSubscription);

    this.chartLegend = this.chartLegendForm.controls.leaseReturnsFields.value;
  }

  private loadLeaseReturnsData(): void {
    this.initSelectedChartLegends();
  }

  private initSelectedChartLegends(): void {
    this.LEASE_RETURNS_CHART_DATA_MOCK.headings.forEach((heading: IGenericChartHeadings): void => {
      this.chartLegend.push(heading.key);
    });
    this.chartLegendForm.get('leaseReturnsFields')?.setValue(this.chartLegend);
  }
}
