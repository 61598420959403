export enum DiscountCategory {
  SPECIAL_B2C_DISCOUNT = 'Special B2C',
  CAMPAIGN = 'Campaign',
}

export const DiscountCategoryMap: Map<DiscountCategoryKey, DiscountCategoryValue> = new Map<
  DiscountCategoryKey,
  DiscountCategoryValue
>([
  ['SPECIAL_B2C_DISCOUNT', 'Special B2C'],
  ['CAMPAIGN', 'Campaign'],
]);

export type DiscountCategoryKey = 'SPECIAL_B2C_DISCOUNT' | 'CAMPAIGN';

export type DiscountCategoryValue = 'Special B2C' | 'Campaign';
