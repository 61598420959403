import { AbstractService } from '../../../core/services/abstract.service';
import { ApprovalModalType } from '../../enums/approval-status.enum';
import {
  ApprovalStatus,
  EditPricePointTask,
  IApprovalStatusBySeriesGQL,
  IApprovalStatusBySeriesResponse,
  IApproveSeriesPricePointsGQL,
  IApproveSeriesPricePointsMutation,
  IBulkEditPricePointsGQL,
  IBulkEditPricePointsMutation,
  IBulkEditPricePointsMutationVariables,
  ICreateUpdatePricePointGQL,
  ICreateUpdatePricePointMutation,
  ICreateUpdatePricePointMutationVariables,
  IPricePoint,
  IPricePointEditInput,
  IPromotePricePointGQL,
  IPromotePricePointMutation,
  IPromotePricePointMutationVariables,
  IRejectPricePointGQL,
  IRejectPricePointMutation,
  IRejectPricePointMutationVariables,
  IReleaseSeriesPricePointsGQL,
  IScenarioDataWithPricePoint,
  ISubmitPricePointsGQL,
  ISubmitPricePointsMutation,
  ISubmitPricePointsMutationVariables,
  ISubmitPricePointsResponse,
  IWithdrawPricePointGQL,
  IWithdrawPricePointMutation,
  IWithdrawPricePointMutationVariables,
  Maybe,
  PromotePricePointStatus,
} from '../../../graphql/services/gql-api.service';
import { AwsRum } from 'aws-rum-web';
import { CalculationsUtils } from 'src/app/common/utils/calculations.utils';
import { FetchResult } from '@apollo/client/core';
import { IBulkPricePointPayload } from '../../models/price-point.model';
import { IMatrixViewDataSourceItem } from '../../models/matrix-view.model';
import { IPriceEditorData, IScenarioMetaDataBuilder } from '../../models/app.model';
import { Injectable, Injector } from '@angular/core';
import {
  KeyofPricePointApprovalStatusRequest,
  PricePointApprovalStatus,
  PricePointApprovalStatusRequest,
} from '../../enums/price-point-approval-status.enum';
import { Observable, throwError } from 'rxjs';
import { ScenarioDefaultIds } from '../../../common/enums/scenario-default-ids.enum';
import { User } from '../../../auth/models/user.model';
import { catchError, map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare let window: any;
@Injectable({
  providedIn: 'root',
})

/**
 * @class GovernanceService
 * @extends AbstractService
 */
export class GovernanceService extends AbstractService {
  /**
   * @constructor
   * @param injector
   * @param submitPricePointsGQL
   * @param createUpdatePricePointGQL
   * @param promotePricePointGQL
   * @param rejectPricePointGQL
   * @param withdrawPricePointGQL
   * @param bulkEditPricePointsGQL
   * @param approvalStatusBySeriesGQL
   * @param approveSeriesPricePointsGQL
   * @param approvalStatusBySeriesGQL
   * @param approveSeriesPricePointsGQL
   * @param releaseSeriesPricePointsGQL
   */
  constructor(
    protected injector: Injector,
    private submitPricePointsGQL: ISubmitPricePointsGQL,
    private createUpdatePricePointGQL: ICreateUpdatePricePointGQL,
    private promotePricePointGQL: IPromotePricePointGQL,
    private rejectPricePointGQL: IRejectPricePointGQL,
    private withdrawPricePointGQL: IWithdrawPricePointGQL,
    private bulkEditPricePointsGQL: IBulkEditPricePointsGQL,
    private approvalStatusBySeriesGQL: IApprovalStatusBySeriesGQL,
    private approveSeriesPricePointsGQL: IApproveSeriesPricePointsGQL,
    private releaseSeriesPricePointsGQL: IReleaseSeriesPricePointsGQL
  ) {
    super(injector);
  }

  /**
   * Set PriceEditorData
   *
   * PriceEditorDataBuilder
   * @param scenarioData
   * @return PriceEditorData
   */
  priceEditorDataBuilder(scenarioData: IScenarioDataWithPricePoint): IPriceEditorData {
    return {
      adjustedListPriceReasonCode: scenarioData?.adjustedListPriceReasonCode,
      author: scenarioData?.pricePoint?.author,
      comments: scenarioData?.comments,
      currentPrice: this.calculateCurrentPrice(scenarioData),
      deltaToCurrentPriceEditor: this.calculateDeltaToCurrentPriceEditor(scenarioData),
      effectiveDate: this.isProposalWithdrawn(scenarioData?.pricePoint?.approvalStatus)
        ? undefined
        : (scenarioData?.pricePoint?.effectiveDate as string),
      governancePricePointStatus: PricePointApprovalStatusRequest[
        scenarioData?.governancePricePointStatus as KeyofPricePointApprovalStatusRequest
      ] as PricePointApprovalStatus,
      id: scenarioData?.pricePoint?.id,
      listPriceExclTax: scenarioData?.listPriceExclTax,
      listPriceInclTax: scenarioData?.listPriceInclTax ? scenarioData?.listPriceInclTax : undefined,
      netPrice: scenarioData?.pricePoint?.netPrice,
      netPricePointPercentage: this.calculatePricePointPercentage(
        scenarioData?.pricePoint?.netPrice,
        scenarioData?.listPriceExclTax,
        scenarioData?.pricePoint?.approvalStatus
      ),
      notes: scenarioData?.notes,
      pricePointPercentage: this.calculatePricePointPercentage(
        scenarioData?.pricePoint?.price,
        scenarioData?.listPriceInclTax,
        scenarioData?.pricePoint?.approvalStatus
      ),
      pricePointPrice: this.getPricePointPrice(scenarioData),
      recommendedRetailPriceReasonCode: scenarioData?.recommendedRetailPriceReasonCode,
      relativeChange:
        scenarioData?.listPriceInclTax && scenarioData?.pricePointPrice
          ? scenarioData?.listPriceInclTax - scenarioData?.pricePointPrice
          : undefined,
      status: PricePointApprovalStatusRequest[
        scenarioData?.pricePoint?.approvalStatus as KeyofPricePointApprovalStatusRequest
      ] as PricePointApprovalStatus,
    };
  }

  getPricePointPrice(scenarioData: IScenarioDataWithPricePoint): number | undefined | null {
    if ((scenarioData?.pricePoint?.price as number) === 0) {
      return undefined;
    } else {
      if (this.isProposalWithdrawn(scenarioData?.pricePoint?.approvalStatus)) {
        return undefined;
      } else {
        return scenarioData?.pricePoint?.price as number;
      }
    }
  }

  /**
   * Set ScenarioMetaDataBuilder
   *
   * ScenarioMetaDataBuilder
   * @param scenarioData
   * @return IScenarioMetaDataBuilder
   */
  scenarioMetaDataBuilder(scenarioData: IScenarioDataWithPricePoint): IScenarioMetaDataBuilder {
    return {
      indicativeLeaseRate: JSON.parse(scenarioData?.indicativeLeaseRateMetaData || 'null'),
      listPriceInclTax: JSON.parse(scenarioData?.listPriceInclTaxMetaData || 'null'),
      summaryTrafficLight: JSON.parse(scenarioData?.recommendedPriceMetaData || 'null'),
    };
  }

  /**
   * CalculateDeltaToCurrentPriceEditor
   * @param scenarioData
   * @private
   */
  calculateDeltaToCurrentPriceEditor(scenarioData: IScenarioDataWithPricePoint | undefined): number | undefined {
    return scenarioData?.listPriceInclTax && scenarioData?.pricePointPrice
      ? scenarioData.pricePointPrice - scenarioData.listPriceInclTax
      : undefined;
  }

  /**
   * CalculateCurrentPrice
   * @param scenarioData
   * @private
   */
  calculateCurrentPrice(scenarioData: IScenarioDataWithPricePoint | undefined): number | undefined {
    return scenarioData?.listPriceInclTax && scenarioData?.pricePointPrice
      ? Math.round((scenarioData?.listPriceInclTax / scenarioData?.pricePointPrice) * 100)
      : undefined;
  }

  /**
   * CalculatePricePointPercentage
   * @param price
   * @param consideredPrice
   * @param approvalStatus
   * @private
   */
  calculatePricePointPercentage(
    price: number | null | undefined,
    consideredPrice: number | null | undefined,
    approvalStatus: ApprovalStatus | null | undefined
  ): number | undefined {
    const pricePoint: Maybe<number> = this.isProposalWithdrawn(approvalStatus) ? undefined : (price as number);
    return consideredPrice && pricePoint
      ? Number(CalculationsUtils.calculatePercentageVariation(consideredPrice, pricePoint).toFixed(1))
      : undefined;
  }

  /**
   * IsProposalWithdrawn
   * @param approvalStatus
   * @private
   */
  isProposalWithdrawn(approvalStatus: ApprovalStatus | undefined | null): boolean {
    return approvalStatus === ApprovalStatus.ProposalWithdrawn;
  }

  /**
   * IsPromoteStatusPricePointPossible
   * @param matrixViewDataSourceItem
   * @param user
   * @return boolean
   */
  isPromoteStatusPricePointPossible(matrixViewDataSourceItem: IMatrixViewDataSourceItem, user: User | null): boolean {
    return (
      !(
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.exported ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.saved ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.withdraw ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.released ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.proposed_to_governance_board ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.comments_only ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.no_status ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.approved_by_1 ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.approved_by_2 ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.rejected ||
        !matrixViewDataSourceItem.priceEditorData?.effectiveDate
      ) && Boolean(user?.permissions.getHasPermissionToAcceptPrice())
    );
  }

  /**
   * IsDemoteStatusPricePointPossible
   * @param matrixViewDataSourceItem
   * @param user
   * @return boolean
   */
  isDemoteStatusPricePointPossible(matrixViewDataSourceItem: IMatrixViewDataSourceItem, user: User | null): boolean {
    return (
      !(
        !matrixViewDataSourceItem.priceEditorData?.effectiveDate ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.exported ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.withdraw ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.rejected ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.approved_by_2 ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.saved
      ) && Boolean(user?.permissions.getHasPermissionToRejectPrice())
    );
  }

  /**
   * IsWithdrawStatusPricePointPossible
   * @param matrixViewDataSourceItem
   * @param user
   * @return boolean
   */
  isWithdrawStatusPricePointPossible(matrixViewDataSourceItem: IMatrixViewDataSourceItem, user: User | null): boolean {
    return (
      !(
        !matrixViewDataSourceItem.priceEditorData?.effectiveDate ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.exported ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.withdraw ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.rejected ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.proposed_to_governance_board ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.approved_by_1 ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.approved_by_2 ||
        matrixViewDataSourceItem.priceEditorData?.status === PricePointApprovalStatus.saved
      ) &&
      user?.sub! === matrixViewDataSourceItem.priceEditorData?.author &&
      Boolean(user?.permissions.getHasPermissionToWithdrawOnMainScenario())
    );
  }

  /**
   * IsPricePointSubmissionPossible
   * @param status
   * @return boolean
   */
  isPricePointSubmissionPossible(status: PricePointApprovalStatus): boolean {
    return !(
      status === PricePointApprovalStatus.proposed_to_governance_board ||
      status === PricePointApprovalStatus.proposed_to_pricing_lead ||
      status === PricePointApprovalStatus.approved_by_1 ||
      status === PricePointApprovalStatus.approved_by_2 ||
      status === PricePointApprovalStatus.exported
    );
  }

  /**
   * GetModelFromMatrixViewDataSourceItem
   * @param matrixViewDataSourceItem
   * @return string
   */
  getModelFromMatrixViewDataSourceItem(matrixViewDataSourceItem: IMatrixViewDataSourceItem): string {
    return `${matrixViewDataSourceItem.granularity.modelCode} | ${matrixViewDataSourceItem.granularity.model} | ${matrixViewDataSourceItem.granularity.powertrain}`;
  }

  /**
   * GetBrandFromMatrixViewDataSourceItem
   * @param matrixViewDataSourceItem
   * @return string
   */
  getBrandFromMatrixViewDataSourceItem(matrixViewDataSourceItem: IMatrixViewDataSourceItem): string {
    return matrixViewDataSourceItem.granularity.brand;
  }

  /**
   * SubmitPricePoints
   * @param submitPricePointsMutationVariables
   */
  submitPricePoints(
    submitPricePointsMutationVariables: ISubmitPricePointsMutationVariables
  ): Observable<ISubmitPricePointsResponse> {
    return this.submitPricePointsGQL.mutate(submitPricePointsMutationVariables).pipe(
      map((response: FetchResult<ISubmitPricePointsMutation>) => {
        return response.data!.submitPricePoints;
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * CreateUpdatePricePoint function
   * @param createUpdatePricePointMutationVariables
   */
  createUpdatePricePoint(
    createUpdatePricePointMutationVariables: ICreateUpdatePricePointMutationVariables
  ): Observable<IPricePoint[]> {
    return this.createUpdatePricePointGQL.mutate(createUpdatePricePointMutationVariables).pipe(
      map((response: FetchResult<ICreateUpdatePricePointMutation>) => {
        if (environment?.environment === 'dev' && window.awsRum) {
          if (Array.isArray(createUpdatePricePointMutationVariables.pricePoints)) {
            createUpdatePricePointMutationVariables.pricePoints.forEach((pp) => {
              if (pp.id) {
                (window.awsRum as AwsRum).recordEvent('PRICEPOINT-UPDATE', { filterId: pp.filterId });
              } else {
                (window.awsRum as AwsRum).recordEvent('PRICEPOINT-ADD', { filterId: pp.filterId });
              }
            });
          } else {
            if (createUpdatePricePointMutationVariables.pricePoints.id) {
              (window.awsRum as AwsRum).recordEvent('PRICEPOINT-UPDATE', {
                filterId: createUpdatePricePointMutationVariables.pricePoints.filterId,
              });
            } else {
              (window.awsRum as AwsRum).recordEvent('PRICEPOINT-ADD', {
                filterId: createUpdatePricePointMutationVariables.pricePoints.filterId,
              });
            }
          }
        }

        return response.data!.createUpdatePricePoint as IPricePoint[];
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * GetPricePointPayloadPromoteStatus
   * @param matrixViewDataSourceItem
   * @return PromotePricePointMutationVariables
   */
  getPricePointPayloadPromoteStatus(
    matrixViewDataSourceItem: IMatrixViewDataSourceItem
  ): IPromotePricePointMutationVariables {
    return {
      expectedCurrentStatus: Object.keys(PricePointApprovalStatusRequest).find(
        (key: string): boolean =>
          PricePointApprovalStatusRequest[key as KeyofPricePointApprovalStatusRequest] ===
          matrixViewDataSourceItem?.priceEditorData?.status
      ) as PromotePricePointStatus,
      filterId: matrixViewDataSourceItem?.id,
      pricePointId: matrixViewDataSourceItem?.priceEditorData?.id,
      scenarioId: matrixViewDataSourceItem?.scenarioOutput?.scenarioId!,
    } as IPromotePricePointMutationVariables;
  }

  /**
   * GetPricePointPayloadDemoteStatus
   * @param matrixViewDataSourceItem
   * @param reason
   * @return RejectPricePointMutationVariables
   */
  getPricePointPayloadDemoteStatus(
    matrixViewDataSourceItem: IMatrixViewDataSourceItem,
    reason: string = ''
  ): IRejectPricePointMutationVariables {
    return {
      expectedCurrentStatus: Object.keys(PricePointApprovalStatusRequest).find(
        (key: string): boolean =>
          PricePointApprovalStatusRequest[key as KeyofPricePointApprovalStatusRequest] ===
          matrixViewDataSourceItem?.priceEditorData?.status
      ) as ApprovalStatus,
      filterId: matrixViewDataSourceItem?.id,
      pricePointId: matrixViewDataSourceItem?.priceEditorData?.id,
      reason: reason,
    } as IRejectPricePointMutationVariables;
  }

  /**
   * GetPricePointPayloadWithDrawStatus
   * @param matrixViewDataSourceItem
   * @return WithdrawPricePointMutationVariables
   */
  getPricePointPayloadWithDrawStatus(
    matrixViewDataSourceItem: IMatrixViewDataSourceItem
  ): IWithdrawPricePointMutationVariables {
    return {
      expectedCurrentStatus: Object.keys(PricePointApprovalStatusRequest).find(
        (key: string): boolean =>
          PricePointApprovalStatusRequest[key as KeyofPricePointApprovalStatusRequest] ===
          matrixViewDataSourceItem?.priceEditorData?.status
      ) as PromotePricePointStatus,
      filterId: matrixViewDataSourceItem?.id,
      pricePointId: matrixViewDataSourceItem?.priceEditorData?.id as string,
    } as IWithdrawPricePointMutationVariables;
  }

  /**
   * GetPricePointActionPayload
   * @param matrixViewDataSourceItem
   * @param action
   * @param reason
   * @return PromotePricePointMutationVariables | RejectPricePointMutationVariables | WithdrawPricePointMutationVariables
   */
  getPricePointActionPayload(
    matrixViewDataSourceItem: IMatrixViewDataSourceItem,
    action: StatusActionEnum,
    reason: string
  ): IPromotePricePointMutationVariables | IRejectPricePointMutationVariables | IWithdrawPricePointMutationVariables {
    switch (action) {
      case StatusActionEnum.promote_status:
        return this.getPricePointPayloadPromoteStatus(matrixViewDataSourceItem);
      case StatusActionEnum.demote_status:
        return this.getPricePointPayloadDemoteStatus(matrixViewDataSourceItem, reason);
      case StatusActionEnum.withdraw_status:
        return this.getPricePointPayloadWithDrawStatus(matrixViewDataSourceItem);
    }
  }

  transformPricePointActionSingleToBulkPayload(
    pricePoints:
      | IPromotePricePointMutationVariables[]
      | IRejectPricePointMutationVariables[]
      | IWithdrawPricePointMutationVariables[],
    action: StatusActionEnum
  ): IBulkEditPricePointsMutationVariables {
    return {
      pricePoints: pricePoints.map((item: IBulkPricePointPayload) => {
        return {
          expectedCurrentStatus: item.expectedCurrentStatus,
          pricePointId: item.pricePointId,
        };
      }) as IPricePointEditInput[],
      scenarioId: ScenarioDefaultIds.governance,
      task: this.getTaskAction(action),
    };
  }

  getTaskAction(action: StatusActionEnum) {
    switch (action) {
      case StatusActionEnum.promote_status:
        return EditPricePointTask.Promote;
      case StatusActionEnum.demote_status:
        return EditPricePointTask.Reject;
      default:
        return EditPricePointTask.Withdraw;
    }
  }

  /**
   * SubmitPricePointAction
   * @param matrixViewDataSourceItems
   * @param action
   * @param reason string
   * @return @Observable<FetchResult<PricePoint>>
   */
  submitPricePointAction(
    matrixViewDataSourceItems: IMatrixViewDataSourceItem[],
    action: StatusActionEnum,
    reason: string = ''
  ): Observable<FetchResult<IPromotePricePointMutation | IRejectPricePointMutation | IWithdrawPricePointMutation>> {
    let mutation: any;

    switch (action) {
      case StatusActionEnum.promote_status:
        mutation = this.promotePricePointGQL;
        break;
      case StatusActionEnum.demote_status:
        mutation = this.rejectPricePointGQL;
        break;
      case StatusActionEnum.withdraw_status:
        mutation = this.withdrawPricePointGQL;
        break;
      default:
        break;
    }

    const payload:
      | IBulkEditPricePointsMutationVariables
      | IPromotePricePointMutationVariables[]
      | IRejectPricePointMutationVariables[]
      | IWithdrawPricePointMutationVariables[] = matrixViewDataSourceItems.map(
      (matrixViewDataSourceItem: IMatrixViewDataSourceItem) =>
        this.getPricePointActionPayload(matrixViewDataSourceItem, action, reason)
    );

    if (payload.length > 1) {
      mutation = this.bulkEditPricePointsGQL;
      const payloadBulk: IBulkEditPricePointsMutationVariables = this.transformPricePointActionSingleToBulkPayload(
        payload,
        action
      );

      return mutation.mutate(payloadBulk).pipe(
        map((response: FetchResult<IBulkEditPricePointsMutation>) => {
          return response;
        }),
        catchError((error): Observable<never> => {
          return throwError(error);
        })
      );
    } else {
      return mutation.mutate(payload[0]).pipe(
        map(
          (
            response:
              | FetchResult<IPromotePricePointMutation>
              | FetchResult<IRejectPricePointMutation>
              | FetchResult<IWithdrawPricePointMutation>
          ) => {
            return response;
          }
        ),
        catchError((error): Observable<never> => {
          return throwError(error);
        })
      );
    }
  }

  /**
   * GetApprovalStatusBySeries
   * @param scenarioId
   * @param phIds string[]
   * @param isRelease boolean
   * @return @Observable<IApprovalStatusBySeriesResponse[]>
   */
  getApprovalStatusBySeries(
    scenarioId: string,
    phIds: string[],
    isRelease: boolean
  ): Observable<IApprovalStatusBySeriesResponse[]> {
    return this.approvalStatusBySeriesGQL.fetch({ pathId: phIds, scenarioId: scenarioId }).pipe(
      map((response) => {
        return response.data.approvalStatusBySeries.map((approvalStatus) => {
          return {
            ...approvalStatus,
            canApprove: isRelease ? approvalStatus!.nscApproved!.length > 0 : approvalStatus!.lvl1Approved!.length > 0,
          };
        });
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }

  /**
   * ApproveSeriesPricePoints
   * @param scenarioId
   * @param phIds string[]
   * @param action
   */
  approveSeriesPricePoints(scenarioId: string, phIds: string[], action: ApprovalModalType) {
    const approveReleaseSeriesPricePointsGQL: IApproveSeriesPricePointsGQL | IReleaseSeriesPricePointsGQL | any =
      action === ApprovalModalType.RELEASE ? this.releaseSeriesPricePointsGQL : this.approveSeriesPricePointsGQL;

    return approveReleaseSeriesPricePointsGQL.mutate({ pathId: phIds, scenarioId: scenarioId }).pipe(
      map((response: FetchResult<IApproveSeriesPricePointsMutation>) => {
        return response.data;
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }
}

export enum StatusActionEnum {
  promote_status = 'promote_status',
  demote_status = 'demote_status',
  withdraw_status = 'withdraw_status',
}
