<div id="user-roles-container">
  <app-matrix-view-subheader
    [title]="title"
    [description]="description"
    [isReturnButtonHidden]="isReturnButtonHidden"
    [isCreateScenarioHidden]="true"
  ></app-matrix-view-subheader>
  <mat-card id="roles-card">
    <mat-card-content>
      <div id="table-header-container">
        <div id="table-header-title">
          <span>{{ currentUser?.given_name }} {{ currentUser?.family_name }}</span>
        </div>
        <div id="table-header-description" class="ml-2 uppercase">
          <span>{{ currentUser?.sub }}</span>
        </div>
      </div>

      <div class="divider">
        <mat-divider></mat-divider>
      </div>

      <div id="table-container" class="mt-2">
        <ng-container *ngFor="let key of getObjectKeys()">
          <div [id]="key.toLocaleLowerCase()" class="brand-container">
            <div class="brand-title-container" fxLayoutAlign="start center">
              <h4 class="brand-title">
                {{ key }}
              </h4>
            </div>

            <table aria-describedby="User Roles" class="w-100 text-left">
              <tr class="table-header">
                <th class="w-25">
                  <h4>Market</h4>
                </th>
                <th class="w-25">
                  <h4>Role</h4>
                </th>
                <th>
                  <h4>Permissions</h4>
                </th>
              </tr>

              <tr class="table-body" *ngFor="let item of getPermissionsGroupedByBrandKey(key)">
                <td class="market">
                  <p>
                    {{ item.market.name }}
                  </p>
                </td>
                <td class="role">
                  <div class="badge {{ item.role }}">
                    <span>
                      {{ getRoleDefinition(item.role) }}
                    </span>
                  </div>
                </td>
                <td class="description">
                  <p [innerHTML]="getActionsDescription(item.actions)"></p>
                </td>
              </tr>
            </table>
          </div>
        </ng-container>
      </div>
    </mat-card-content>
  </mat-card>
</div>
