import { AppMessageType, IAppMessage, IAppMessageConfig } from 'src/app/common/models/app-message.model';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-message-item',
  styleUrls: ['./message-item.component.scss'],
  templateUrl: './message-item.component.html',
})
export class MessageItemComponent implements OnDestroy, OnChanges {
  @Input() index!: number;

  @Input() message!: IAppMessage;

  @Input() isOnTop!: boolean;

  @Output() closeClicked = new EventEmitter<number>();

  progressValue: number = 0;

  isCounterPaused: boolean = false;

  private subscriptions: Subscription[] = [];

  private readonly DEFAULT_MESSAGE_DURATION: number = 5;

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => {
      subscription?.unsubscribe();
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.isOnTop?.currentValue) {
      this.startCounterTillClose();
      this.resumeCounter();
    } else {
      this.pauseCounter();
    }
  }

  pauseCounter(): void {
    this.isCounterPaused = true;
  }

  resumeCounter(): void {
    this.isCounterPaused = false;
  }

  closeMessage(): void {
    this.closeClicked.emit(this.index);
  }

  private startCounterTillClose(): void {
    if (this.isOnTop) {
      const subscription = interval(((this.message.duration || this.DEFAULT_MESSAGE_DURATION) * 1000) / 100).subscribe({
        next: () => {
          if (!this.isCounterPaused) {
            this.progressValue = this.progressValue + 1;
            if (this.progressValue > 100) {
              this.closeMessage();
            }
          }
        },
      });
      this.subscriptions.push(subscription);
    }
  }

  get messageConfig(): IAppMessageConfig {
    switch (this.message.type) {
      case AppMessageType.ERROR:
        return {
          class: 'message-error',
          iconName: 'error',
          title: 'Error',
        };
      case AppMessageType.SUCCESS:
        return {
          class: 'message-success',
          iconName: 'done',
          title: 'Success',
        };
      default:
        return {
          class: 'message-info',
          iconName: 'info',
          title: 'Info',
        };
    }
  }
}
