import { ApolloQueryResult } from '@apollo/client/core';
import { AuthService } from '../../../auth/services/auth.service';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { FetchFilterDataApiError } from '../../../core/error-handling/errors/error-functions';
import { GranularityPropName } from '../../enums/granularity-prop-name.enum';
import { GranularityReadyStatus } from '../../enums/granularity-ready-status.enum';
import { GranularityType } from '../../enums/granularity-type.enum';
import { GranularityUtils, Level } from '../../utils/granularity/granularity.utils';
import { IGranularity } from '../../models/app.model';
import { IProductDataGQL, IProductDataQuery, IProductHierarchy } from '../../../graphql/services/gql-api.service';
import { Injectable } from '@angular/core';
import { ScenarioMarketParameter } from '../../enums/scenario-market-parameter.enum';
import { SideFiltersService } from '../../../common/services/side-filter/side-filter.service';
import { User } from '../../../auth/models/user.model';
import { catchError, map } from 'rxjs/operators';
import sortBy from 'lodash.sortby';
import uniq from 'lodash.uniq';

@Injectable({
  providedIn: 'root',
})
export class GranularityService {
  private granularityList: IGranularity[] = [];
  private currentUser!: User | null;
  private granularityListReadySubject: BehaviorSubject<GranularityReadyStatus> =
    new BehaviorSubject<GranularityReadyStatus>(GranularityReadyStatus.UNINITIALIZED);

  public productHierarchyGroupedBySeries: { BMW: IProductHierarchy[]; MINI: IProductHierarchy[] } = {
    BMW: [],
    MINI: [],
  };

  constructor(
    private sideFilterService: SideFiltersService,
    private authService: AuthService,
    private productDataGQL: IProductDataGQL
  ) {
    this.currentUser = this.authService.getLoggedInUser();

    this.sideFilterService.granularityList().subscribe((granularities: IGranularity[]): void => {
      if (granularities.length > 0) {
        this.granularityList = granularities;
        this.granularityListReadySubject.next(GranularityReadyStatus.LOADED);
      }
    });
  }

  getProductHierarchy(): Observable<{ BMW: IProductHierarchy[]; MINI: IProductHierarchy[] }> {
    const market: ScenarioMarketParameter | null =
      this.currentUser!.permissions.getSelectedMarket() as ScenarioMarketParameter;
    return this.productDataGQL.fetch({ market }).pipe(
      map((productData: ApolloQueryResult<IProductDataQuery>) => {
        this.setProductHierarchyGroupedBySeries(productData.data.productData as unknown as IProductHierarchy[]);
        return this.productHierarchyGroupedBySeries;
      }),
      catchError((error): Observable<never> => {
        if (error.graphqlErrors || error.networkError) {
          return throwError(FetchFilterDataApiError);
        } else {
          return throwError(error.value);
        }
      })
    );
  }

  /**
   * Group Series By Brand
   * @param productData
   */
  private setProductHierarchyGroupedBySeries(productData: IProductHierarchy[]): void {
    productData.forEach((itemMarketLevel: IProductHierarchy): void => {
      if (itemMarketLevel.children?.length && itemMarketLevel.pathLevel === Level.MARKET) {
        itemMarketLevel.children.forEach((itemBrandLevel: IProductHierarchy): void => {
          if (itemBrandLevel.children && itemBrandLevel.pathLevel === Level.BRAND) {
            this.productHierarchyGroupedBySeries[
              itemBrandLevel.brand as keyof { BMW: IProductHierarchy[]; MINI: IProductHierarchy[] }
            ] = sortBy(
              itemBrandLevel.children.flatMap(
                (itemBrandLevelChildren: IProductHierarchy) => itemBrandLevelChildren.children as IProductHierarchy[]
              ),
              ['series'],
              ['asc']
            );
          }
        });
      }
    });
  }

  isGranularityListReady(): Observable<GranularityReadyStatus> {
    return this.granularityListReadySubject.asObservable();
  }

  /**
   * Returns synchronously the already fetched and mapped {@link IGranularity} list
   */
  getGranularityList(): IGranularity[] {
    return [...this.granularityList];
  }

  /**
   * Returns children that belongs to a given {@link granularity}
   * @param granularity
   * @param powertrain
   */
  getGranularityChildren(granularity: IGranularity, powertrain: []): IGranularity[] {
    return this.getGranularityChildrenInGivenList(granularity, this.granularityList, powertrain);
  }

  setGranularityList(list: IGranularity[]): void {
    this.granularityList = list;
  }

  /**
   * Gets the model code children and adds it to the Granularity list.
   * @param granularity
   * @param granularityList
   * @param powertrain
   */
  getGranularityChildrenInGivenList(
    granularity: IGranularity,
    granularityList: IGranularity[],
    powertrain: IGranularity[]
  ): IGranularity[] {
    if (granularity.childrenType && granularity.childrenType !== GranularityType.UNKNOWN) {
      const key = GranularityUtils.getGranularityPropertyName(granularity.type) as keyof IGranularity;

      granularityList = sortBy(granularityList, ['posInPath', 'asc']);

      if (key === 'model') {
        const modelCodeGranularities = granularityList.filter(
          (item: IGranularity) =>
            item.type === granularity.childrenType &&
            item.model === granularity.model &&
            item.eSeries === granularity.eSeries &&
            item.series === granularity.series
        );
        /**
         * Checks whether the powertrain parameter has values and if those values are from the same brand as the
         * current set and filters the model codes according to the brand of the selected powertrain only.
         */
        if (
          powertrain.length &&
          modelCodeGranularities
            .map((item) => item.brand)
            .includes(uniq(powertrain.map((item) => item.brand)).toString())
        ) {
          return modelCodeGranularities.filter((item) =>
            powertrain.map((item) => item.powertrain).includes(item.powertrain)
          );
        }
        return modelCodeGranularities;
      } else if (key === 'eSeries') {
        return granularityList.filter(
          (item: IGranularity) =>
            item.type === granularity.childrenType &&
            item[key] === granularity[key] &&
            item.series === granularity.series
        );
      } else if (key === 'series') {
        return granularityList.filter(
          (item: IGranularity) =>
            item.type === granularity.childrenType && item[key] === granularity[key] && item.brand === granularity.brand
        );
      }

      return granularityList.filter(
        (item: IGranularity) => item.type === granularity.childrenType && item[key] === granularity[key]
      );
    }
    return [];
  }

  getGranularityParents(granularity: IGranularity): IGranularity | undefined {
    if (granularity.type && granularity.type !== GranularityType.BRAND) {
      let nameComparisonKey: keyof IGranularity;
      switch (granularity.type) {
        case GranularityType.E_SERIES:
          nameComparisonKey = GranularityPropName.SERIES;
          break;
        case GranularityType.MODEL:
          nameComparisonKey = GranularityPropName.E_SERIES;
          break;
        case GranularityType.MODEL_CODE:
          nameComparisonKey = GranularityPropName.MODEL;
          break;
        default:
          nameComparisonKey = GranularityPropName.BRAND;
      }

      if (granularity.type === GranularityType.MODEL_CODE) {
        return this.granularityList.find(
          (item: IGranularity) =>
            item.childrenType === granularity.type &&
            item.model === granularity.model &&
            item.eSeries === granularity.eSeries &&
            item.series === granularity.series &&
            item.brand === granularity.brand &&
            item[nameComparisonKey] === granularity[nameComparisonKey]
        );
      } else {
        return this.granularityList.find(
          (item: IGranularity) =>
            item.childrenType === granularity.type && item[nameComparisonKey] === granularity[nameComparisonKey]
        );
      }
    }
    return undefined;
  }

  /**
   * Method removes children in given granularities
   */
  removeAllChildrenOnGivenGranularities(granularityList: IGranularity[]): void {
    granularityList
      .filter((granularity: IGranularity) => granularity?.children?.length)
      .forEach((granularity: IGranularity) => (granularity.children = []));
  }

  /**
   * Method removes children from granularities having children at the moment
   */
  removeAllChildrenOnGranularityList(): void {
    this.removeAllChildrenOnGivenGranularities(this.granularityList);
  }
}
