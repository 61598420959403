import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FtdNotifierService } from '../../../common/services/ftd-notifier/ftd-notifier.service';
import { ICurrencyMaskConfig } from '../../../common/directives/app-currency-mask/lib/currency-mask.config';
import { IEditPricesForm } from './edit-scenario-modal.model';
import { MatDialogRef } from '@angular/material/dialog';
import { MatrixViewDataFormattingService } from '../../../matrix-view/services/matrix-view-data-formatting/matrix-view-data-formatting.service';
import { MatrixViewFormService } from '../../../matrix-view/services/matrix-view-form/matrix-view-form.service';
import { OptionSelectorContext } from '../../../common/components/ftd-button-option-selector/ftd-button-option-selector.component';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-edit-scenario-modal',
  templateUrl: './edit-scenario-modal.component.html',
})
/**
 * @class EditScenarioModalComponent
 */
export class EditScenarioModalComponent {
  public title: string = 'Edit Prices';
  public form: FormGroup = new FormGroup<IEditPricesForm>({
    amount: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
    comment: new FormControl<string>('', {
      nonNullable: false,
      validators: [],
    }),
    effectiveDate: new FormControl<string>('', {
      nonNullable: true,
      validators: [Validators.required],
    }),
  });

  public currencyMaskOption: ICurrencyMaskConfig = {
    allowNegative: true,
    decimal: '.',
    precision: 1,
    suffix: '%',
  };

  public currencySymbol: string = getCurrencySymbol(
    this.matrixViewDataFormattingService.getMatrixViewCurrency(),
    'narrow'
  );

  private currency: string = '%';

  /**
   * @constructor
   * @param notifierService
   * @param dialogRef
   * @param matrixViewFormService
   * @param matrixViewDataFormattingService
   */
  constructor(
    private notifierService: FtdNotifierService,
    private dialogRef: MatDialogRef<EditScenarioModalComponent>,
    private matrixViewFormService: MatrixViewFormService,
    private matrixViewDataFormattingService: MatrixViewDataFormattingService
  ) {}

  /**
   * SetCurrencyContext
   * @param optionSelectorContext
   */
  setCurrencyContext(optionSelectorContext: OptionSelectorContext): void {
    const isPercentageSelected = optionSelectorContext === 'percentage';

    this.currency = isPercentageSelected ? '%' : this.currencySymbol;

    this.currencyMaskOption.precision = isPercentageSelected ? 1 : 4;
    this.currencyMaskOption.allowNegative = isPercentageSelected;
    this.currencyMaskOption.suffix = this.currency;
  }

  /**
   * TotalModels
   */
  get totalModels(): number {
    return this.matrixViewFormService.getFormTotalRowsCarModels();
  }

  /**
   * OnSubmit
   */
  onSubmit(): void {
    if (this.form.valid) {
      this.submitEditScenario();
    }
  }

  /**
   * SubmitEditScenario
   */
  submitEditScenario(): void {
    this.matrixViewFormService.updateFormRowCheckedValues(
      this.currency === '%' ? 'percentage' : 'currency',
      this.form.get('amount')?.value,
      this.form.get('effectiveDate')?.value,
      this.form.get('comment')?.value
    );
    this.dialogRef.close();
  }
}
