export enum ConsideredPriceType {
  CURRENT_PRICE = 'CURRENT_PRICE',
  PROPOSED_PRICE = 'PROPOSED_PRICE',
  CURRENT_AND_PROPOSED_PRICE = 'CURRENT_AND_PROPOSED_PRICE',
}

export enum SalesChannelType {
  LIST_PRICE = 'LIST_PRICE',
  LEASE_RATE_PRICE = 'LEASE_RATE_PRICE',
}

export enum DisplayedValuesType {
  NET_PRICE = 'NET_PRICE',
  GROSS_PRICE = 'GROSS_PRICE',
}
