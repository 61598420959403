import { Component, Inject } from '@angular/core';
import { IFtdModalData } from './ftd-modal-data.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-ftd-modal',
  styleUrls: ['./ftd-modal.component.scss'],
  templateUrl: './ftd-modal.component.html',
})
export class FtdModalComponent {
  buttonsAreaActivated = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: IFtdModalData, public dialogRef: MatDialogRef<FtdModalComponent>) {}
}
