<ng-container [ngSwitch]="COLUMN_UNIT">
  <ng-container *ngSwitchCase="'date'">
    <app-discount-mv-date-display
      [dateHolder]="textHolder"
      [technicalName]="technicalName"
    ></app-discount-mv-date-display>
  </ng-container>

  <ng-container *ngSwitchCase="'%'">
    <app-discount-mv-pp-display [textHolder]="textHolder" [technicalName]="technicalName"></app-discount-mv-pp-display>
  </ng-container>

  <ng-container *ngSwitchDefault>
    <app-discount-mv-price-display
      [currency]="COLUMN_UNIT || 'EUR'"
      [priceHolder]="textHolder"
      [technicalName]="technicalName"
    ></app-discount-mv-price-display>
  </ng-container>
</ng-container>
