import { APP_REGEX } from 'src/app/common/constants/app-regex';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseUrlInterceptor implements HttpInterceptor {
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let apiUrl: string = '';
    const requestUrl: string = request.url;
    if (requestUrl.includes('v1/models')) {
      apiUrl = environment.dataApiUrl;
    } else if (requestUrl.includes('v1/auth') || requestUrl.includes('v1/app')) {
      apiUrl = environment.apiUrl;
    }

    if (APP_REGEX.API_VERSION.test(requestUrl)) {
      return next.handle(request.clone({ url: `${apiUrl}/${requestUrl}` }));
    } else {
      return next.handle(request);
    }
  }
}
