<app-ftd-custom-modal *ngIf="userScenarios$ | async as userScenarios">
  <app-ftd-custom-modal-header [title]="'New Scenario'"></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body [enableGlobalLoader]="true">
    <form [formGroup]="newScenarioForm" novalidate fxLayout="column" fxLayoutGap="20">
      <ng-container *ngIf="userScenarios.length > 0; else noUserScenarioFound">
        <mat-checkbox
          ngDefaultControl
          id="usePreExistScenario"
          [attr.data-test]="'checkbox-use-pre-existing-scenario'"
          formControlName="isUseExistingUserScenarioChecked"
        >
          <p class="m-0">Use a pre-existing scenario</p>
        </mat-checkbox>
      </ng-container>

      <ng-template #noUserScenarioFound>
        <p class="m-0">No existing user scenario to copy from</p>
      </ng-template>

      <ng-container>
        <span> Name the new User Scenario. </span>
        <app-ftd-input
          [id]="'new-scenario-name'"
          [attr.data-test]="'input-new-scenario-name'"
          [placeholder]="'I.E.: Forecast for 2040'"
          [form]="newScenarioForm"
          [controlName]="'scenarioName'"
          [maxlength]="100"
          [errorMessage]="newScenarioFormObj.scenarioNameErrorMessage"
          [fillWidth]="true"
        ></app-ftd-input>
      </ng-container>

      <ng-container *ngIf="newScenarioFormObj.control('isUseExistingUserScenarioChecked').value">
        <span> Choose a Pre-existing Scenario </span>
        <app-ftd-dropdown
          [isLabelVisible]="false"
          [fillWidth]="true"
          [label]="'Choose a Pre-existing Scenario'"
          [id]="'scenario-name'"
          [form]="newScenarioForm"
          [controlName]="'userScenarioId'"
          [customMessage]="'You are using a scenario that has a base for a new one.'"
          [errorMessage]="newScenarioFormObj.userScenarioIdErrorMessage"
          [options]="userScenarios"
        ></app-ftd-dropdown>
      </ng-container>
    </form>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer>
    <app-ftd-button
      [id]="'create-new-scenario-btn'"
      [attr.data-test]="'create-new-scenario-btn'"
      (btnClick)="createNewScenario()"
      [disabled]="!newScenarioForm.valid || isSubmitButtonDisabled"
      [label]="'Create'"
      ariaText="Create new user scenario"
      [matTooltip]="newScenarioFormObj.formInvalidMessage"
      matTooltipPosition="above"
      [matTooltipDisabled]="newScenarioForm.valid"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
