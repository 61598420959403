/*
 *Import { APP_REGEX } from '../constants/app-regex';
 *import { Injectable } from '@angular/core';
 *
 *@Injectable({
 *  providedIn: 'root',
 *})
 *export class FunctionsUtils {
 *  constructor() {}
 *
 *  /!**
 * Validates recursively, the pattern "YYYYMMDD_HHMMSS" for all checkFields
 * @param testData Any object
 * @param checkFields A list of properties to check
 *!/
 *  validateAllDatesFormat(testData: {}, checkFields: string[]) {
 *    const getPropertiesValues = (object: {}, key: string) => {
 *      function iteration(this: Record<string, any>, i: string) {
 *        const item = this ? this[i] : i;
 *        if (this && i === key) {
 *          return result.push(item);
 *        }
 *        if (Array.isArray(item)) {
 *          return item.forEach(iteration);
 *        }
 *        if (item !== null && typeof item === 'object') {
 *          return Object.keys(item).forEach(iteration, item);
 *        }
 *      }
 *      const result: string[] = [];
 *      Object.keys(object).forEach(iteration, object);
 *      return result;
 *    };
 *    let listAllDates: string[] = [];
 *
 *    checkFields.forEach((field) => {
 *      const listValues: string[] = getPropertiesValues(testData, field);
 *      listAllDates = listAllDates.concat(listValues);
 *    });
 *    return listAllDates.every((eachDate) => APP_REGEX.DATE_TIME.test(eachDate));
 *  }
 *}
 */

export class FunctionsUtils {
  static groupBy = <T>(array: T[], predicate: (value: T, index: number, array: T[]) => string) =>
    array.reduce((acc, value, index, array) => {
      (acc[predicate(value, index, array)] ||= []).push(value);
      return acc;
    }, {} as { [key: string]: T[] });
}
