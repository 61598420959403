import { InjectionToken } from '@angular/core';

export interface ICurrencyMaskConfig {
  align?: string;
  allowNegative?: boolean;
  decimal?: string;
  precision?: number;
  prefix?: string;
  suffix?: string;
  thousands?: string;
}

export const CURRENCY_MASK_CONFIG = new InjectionToken<ICurrencyMaskConfig>('currency.mask.config');
