import { ApprovalStatus, KeyofApprovalStatus } from 'src/app/matrix-view/enums/approval-status.enum';
import { Component, Input, OnInit } from '@angular/core';
import { MatrixColumnTechnicalName } from 'src/app/matrix-view/enums/matrix-column-technical-name.enum';

@Component({
  selector: 'app-discount-mv-approval-status-display',
  styleUrls: ['./mv-approval-status-display.component.scss'],
  templateUrl: './mv-approval-status-display.component.html',
})
export class MvApprovalStatusDisplayComponent implements OnInit {
  @Input() textHolder!: any;

  @Input() technicalName?: MatrixColumnTechnicalName;

  approvalStatusDisplay: string = '';

  ngOnInit(): void {
    if (this.textHolder && this.technicalName) {
      this.approvalStatusDisplay = ApprovalStatus[this.textHolder[this.technicalName] as KeyofApprovalStatus];
    }
  }
}
