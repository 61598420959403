<mat-card
  class="mat-elevation-z8 message-item {{ messageConfig.class }}"
  (mouseenter)="pauseCounter()"
  (mouseleave)="resumeCounter()"
>
  <mat-progress-bar
    mode="determinate"
    class="message-progress-bar"
    [mode]="'determinate'"
    [value]="progressValue"
  ></mat-progress-bar>

  <div fxLayout="row" class="header-container">
    <span fxLayout="row" fxLayoutAlign="space-between center" class="message-title-wrapper">
      <mat-icon class="message-icon">{{ messageConfig.iconName }}</mat-icon> {{ messageConfig.title }}
    </span>
    <button mat-icon-button class="message-close-btn" [attr.data-test]="'message-close-btn'" (click)="closeMessage()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <span class="message-card-content">
    {{ message.text }}
  </span>
</mat-card>
