import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';

export class FormValidation<
  IForm extends {
    [K in keyof IForm]: IForm[K] extends FormControl
      ? FormControl<IModel[K]>
      : IForm[K] extends FormGroup
      ? FormGroup<any>
      : IForm[K] extends FormArray<FormGroup>
      ? IForm[K]
      : AbstractControl<IModel[K]>;
  },
  IModel extends Record<keyof IForm, IModel[keyof IForm]>
> {
  public form!: FormGroup<IForm>;

  public control = <ControlName extends keyof IForm>(controlName: ControlName): IForm[ControlName] => {
    return (this.form.controls as IForm)[controlName];
  };

  public invalid = <ControlName extends keyof IForm>(controlName: ControlName): boolean => {
    const control = this.control(controlName);
    return control.invalid;
    // Return control.invalid && control.touched && control.dirty;
  };

  public valid = <ControlName extends keyof IForm>(controlName: ControlName): boolean => {
    const control = this.control(controlName);
    return control.hasValidator(Validators.required)
      ? control.valid && (control.touched || control.dirty)
      : control.valid;
  };

  public class = (controlName: keyof IForm): string => {
    const isInvalid = this.invalid(controlName) ? 'is-invalid' : '';
    return this.valid(controlName) ? 'is-valid' : isInvalid;
  };

  get buttonClass(): string {
    return this.form.invalid ? 'form-invalid' : 'form-valid';
  }

  errors = <ControlName extends keyof IForm>(controlName: ControlName): ValidationErrors | null => {
    return this.control(controlName).errors;
  };

  hasRequiredError = <ControlName extends keyof IForm>(controlName: ControlName): boolean => {
    const control = this.control(controlName);
    return control.touched && control.errors?.required;
  };
}
