import { Actions, ActionsDescription, KeyofActions } from '../../auth/models/actions.model';
import { AuthService } from '../../auth/services/auth.service';
import { Brand } from '../../matrix-view/models/brand.model';
import { Component } from '@angular/core';
import { IPermission } from '../../auth/models/permissions.model';
import { KeyofRoleDefinition, RoleDefinition } from '../../matrix-view/models/roles.model';
import { User } from '../../auth/models/user.model';

@Component({
  selector: 'app-user-roles',
  styleUrls: ['./user-roles.component.scss'],
  templateUrl: './user-roles.component.html',
})
export class UserRolesComponent {
  isReturnButtonHidden: boolean = false;
  title: string = 'Roles & Permissions';
  description: string = '';
  currentUser?: User | null;

  constructor(private authService: AuthService) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  getObjectKeys(
    object: {
      BMW?: IPermission[];
      MINI?: IPermission[];
    } = this.currentUser!.permissions.getPermissionsGroupedByBrand()
  ): Brand[] {
    return Object.keys(object) as Brand[];
  }

  getPermissionsGroupedByBrandKey(key: Brand): IPermission[] {
    return this.currentUser!.permissions.getPermissionsGroupedByBrand()[key];
  }

  getRoleDefinition(role: KeyofRoleDefinition): RoleDefinition {
    return RoleDefinition[role];
  }

  getActionsDescription(actions: Actions): string {
    let description: string = 'This role allows you to';
    let _index: number = 0;

    Object.keys(ActionsDescription).forEach((action: string) => {
      if (actions[action as KeyofActions]) {
        _index += 1;
      }
    });

    Object.keys(ActionsDescription).forEach((action: string, index: number) => {
      if (actions[action as KeyofActions]) {
        description += ` <b>${ActionsDescription[action as keyof typeof ActionsDescription]}</b>`;

        if (index >= 0 && index < _index - 2) {
          description += ',';
        } else if (index === _index - 2) {
          description += ' and';
        } else {
          description += '.';
        }
      }
    });

    return description;
  }
}
