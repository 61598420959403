import { AppButtonLinkModel } from '../models/app-button-link.model';

export const APP_FSM_RELATED_EXTERNAL_LINKS: AppButtonLinkModel[] = [
  {
    link: 'https://sales-steering-reporting.aws.bmw.cloud',
    name: 'Reporting Layer',
  },
  {
    link: 'https://onegpm.bmw.cloud/dosm',
    name: 'Stock Data Asset',
  },
  {
    link: 'https://eu-west-1.quicksight.aws.amazon.com/sn/accounts/376714490571/dashboards/7aec14df-022f-44dc-9929-9b457d9e779a?directory_alias=fsm-qs-dashboard-dev',
    name: 'Competition',
  },
  {
    link: 'https://tab06.bmwgroup.net/#/site/E2ELeadManagementDashboard/views/TheNewMarketingSalesFunnelDashboard/Overview',
    name: 'Sales Funnel',
  },
];

export const APP_FSM_USER_MANUAL_LINK: string = 'https://atc.bmwgroup.net/confluence/display/FSMEMP/NCPE+User+Manual';
