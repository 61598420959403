import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ftd-slider',
  styleUrls: ['./ftd-slider.component.scss'],
  templateUrl: './ftd-slider.component.html',
})
export class FtdSliderComponent {
  @Input() maxValue: number = 100;
  @Input() minValue: number = 0;
  @Input() value: number = 50;
}
