import { ChartLegendType } from '../../../../common/components/ftd-charts/enums/chart-legend-type.enum';
import { Component, Host, Input } from '@angular/core';
import { CrosschecksChartFilterGroupComponent } from '../crosschecks-chart-filter-group.component';

@Component({
  selector: 'app-chart-filter-item',
  styleUrls: ['./chart-filter-item.component.scss'],
  templateUrl: './chart-filter-item.component.html',
})
export class ChartFilterItemComponent {
  @Input() value!: string;
  @Input() label!: string;
  @Input() legendType?: ChartLegendType;
  @Input() legendColor?: string;
  @Input() legendImagePath?: string;
  @Input() legendImageHeight?: number = 30;
  @Input() legendImageWidth?: number = 30;
  @Input() legendImageAlt?: string = 'Legend';

  CHART_LEGEND_TYPE = ChartLegendType;

  constructor(@Host() public crosschecksChartFilterGroup: CrosschecksChartFilterGroupComponent) {}
}
