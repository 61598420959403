<div class="filter-item">
  <mat-checkbox
    [checked]="crosschecksChartFilterGroup.isSelected(value)"
    (change)="crosschecksChartFilterGroup.toggleValue(value)"
    class="chart-filter-item"
  >
    <div class="chart-filter-item-label" [ngSwitch]="legendType">
      <ng-container *ngSwitchCase="CHART_LEGEND_TYPE.SQUARE_DIV">
        <div class="chart-label-square" [ngStyle]="{ background: legendColor }"></div>
      </ng-container>

      <ng-container *ngSwitchCase="CHART_LEGEND_TYPE.IMAGE">
        <img
          *ngIf="legendImagePath"
          [src]="legendImagePath"
          alt="{{ legendImageAlt }}"
          [height]="legendImageHeight"
          [width]="legendImageWidth"
        />
      </ng-container>

      <ng-container *ngSwitchDefault>
        <ng-content></ng-content>
      </ng-container>
      {{ label }}
    </div>
  </mat-checkbox>
</div>
