<app-ftd-toggle-filters
  *ngIf="showFiltersToggle"
  class="filter-toggle"
  [hidden]="getDataContext !== dataContextMatrixViewData"
  (toggleFiltersClickEvent)="toggleFiltersClickEvent.emit($event)"
></app-ftd-toggle-filters>
<app-discount-matrix-view
  [initSyncMatrixViewConfigWithScenarioMetadata]="isFiltersApplied"
  [hidden]="getDataContext !== dataContextMatrixViewData"
  [formGroup]="matrixViewForm"
  [discount]="discount"
  [dataSource]="dataSource"
></app-discount-matrix-view>
<app-discount-matrix-landing-view
  [dataContextInput]="getDataContext"
  [hidden]="granularityDataLoaded"
  [isLoading]="isLoadingScenarioData"
  fxFill
></app-discount-matrix-landing-view>
