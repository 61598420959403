/**
 * @Base64Utils
 */
export class Base64Utils {
  /**
   * DecodeAtob
   * @param encodedData
   * @return string
   */
  static decodeAtob(encodedData: string): string {
    return atob(encodedData);
  }

  /**
   * DecodeAtob
   * @param encodedData
   * @return string
   */
  static decodeAtobToJson<T>(encodedData: string): T {
    let decodeAtobToJson: T;

    try {
      decodeAtobToJson = JSON.parse(this.decodeAtob(encodedData));
    } catch (e) {
      decodeAtobToJson = <T>{};
    }

    return decodeAtobToJson;
  }

  /**
   * EncodeBtoa
   * @param decodedData
   * @return string
   */
  static encodeBtoa(decodedData: string): string {
    return btoa(decodedData);
  }

  /**
   * EncodeBtoaToString
   * @param encodedData
   * @return string
   */
  static encodeBtoaToString<T>(encodedData: T): string {
    let encodeBtoaToString: string;

    try {
      encodeBtoaToString = this.encodeBtoa(JSON.stringify(encodedData));
    } catch (e) {
      encodeBtoaToString = '';
    }

    return encodeBtoaToString;
  }
}
