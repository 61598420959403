<mat-chip-set>
  <mat-chip
    (removed)="remove(chipText)"
    [ngClass]="{ 'cursor-pointer': showCursorPointer }"
    [ngStyle]="{ 'background-color': chipColor, color: chipTextColor }"
    [removable]="enableRemoveButton"
    class="chips {{ chipSize }}"
    disableRipple="true"
  >
    <mat-icon *ngIf="chipIcon" [ngClass]="chipSize" [ngStyle]="{ color: chipTextColor }" class="chip-icon">
      {{ chipIcon }}
    </mat-icon>
    <span [ngClass]="chipSize" class="chip-label" [ngStyle]="{ 'font-weight': textFontWeight }">
      {{ chipText }}
    </span>
    <mat-icon
      *ngIf="enableRemoveButton"
      [ngClass]="chipSize"
      [ngStyle]="{ color: chipTextColor }"
      class="chip-remove-icon"
      matChipRemove
      >add_circle
    </mat-icon>
  </mat-chip>
</mat-chip-set>
