export interface IAppMessage {
  type: AppMessageType;
  text: string;
  duration?: number;
}

export interface IAppMessageConfig {
  title: string;
  iconName: string;
  class: string;
}

export enum AppMessageType {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  ERROR = 'ERROR',
}
