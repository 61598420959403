import { APP_SYMBOLS } from '../../constants/app-symbols';
import { Pipe, PipeTransform } from '@angular/core';

/*
 * Show 'N/A' if the value is null or undefined
 * Usage:
 *   value | nullLabel
 * Example:
 *   {{ undefined | nullLabel }}
 *   formats to: 'N/A'
 * This pipe accepts an extra param optionally
 * Example:
 *   {{ undefined | nullLabel: 'notSubmitted'  }}
 *   formats to: 'Not Submitted'
 */
@Pipe({ name: 'nullLabel' })
export class NullLabelPipe implements PipeTransform {
  transform(value: any, type?: string): string {
    return value ? value : this.formatNullText(type);
  }

  formatNullText(type?: string): string {
    switch (type) {
      case APP_SYMBOLS.NOT_SUBMITTED:
        return APP_SYMBOLS.NOT_SUBMITTED_DISPLAY;
      case APP_SYMBOLS.NA:
      default:
        return APP_SYMBOLS.NA;
    }
  }
}
