export enum PlanningHorizonValue {
  NEXT_MONTH = 'Next Month',
  NEXT_YEAR = 'Next Year',
  MTD = 'MTD',
  YTD = 'YTD',
  LAST_YEAR = 'Last Year',
  LAST_MONTH = 'Last Month',
  FULL_MONTH = 'Full Month',
  FULL_YEAR = 'Full Year',
  YE = 'YE',
}
