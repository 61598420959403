import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProgressAnimationEnd } from '@angular/material/progress-bar';
type ProgressBarMode = 'determinate' | 'indeterminate' | 'buffer' | 'query';
let _id: number = 0;

@Component({
  selector: 'app-ftd-progress-bar',
  templateUrl: './ftd-progress-bar.component.html',
})
export class FtdProgressBarComponent {
  // Ftd-progress-bar-${id}
  @Input()
  id?: string;

  // Buffer value of the progress bar. Defaults to zero.
  @Input()
  bufferValue: number = 0;

  /*
   * Mode of the progress bar.
   * Input must be one of these values: determinate, indeterminate, buffer, query, defaults to 'determinate'. Mirrored to mode attribute.
   */
  @Input()
  mode: ProgressBarMode = 'determinate';

  // Value of the progress bar. Defaults to zero. Mirrored to aria-valuenow.
  @Input()
  value: number = 0;

  /*
   * Event emitted when animation of the primary progress bar completes.
   * This event will not be emitted when animations are disabled, nor will it be emitted for modes with continuous animations (indeterminate and query).
   */
  @Output()
  animationEnd: EventEmitter<ProgressAnimationEnd> = new EventEmitter<ProgressAnimationEnd>();

  // HandleAnimationEnd $event emitter
  handleAnimationEnd($event: ProgressAnimationEnd): void {
    this.animationEnd.emit($event);
  }

  constructor() {
    _id += 1;
    this.id = `ftd-progress-bar-${_id}`;
  }
}
