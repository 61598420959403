<ng-container *ngIf="column.columnType" [ngSwitch]="column.columnType">
  <div *ngSwitchCase="ColumnType.TEXT" class="column-type {{ column.name.toLowerCase() }}">
    {{ dataItem[column.technicalName] || 'N/A' }}
  </div>

  <div *ngSwitchCase="ColumnType.DATE" class="column-type-date {{ column.name.toLowerCase() }}">
    {{ dataItem[column.technicalName]?.toString() | date: 'dd.MM.yyyy' }}
  </div>

  <div *ngSwitchCase="ColumnType.PERCENTAGE" class="column-type-percentage {{ column.name.toLowerCase() }}">
    {{ (dataItem[column.technicalName] || '0') + '%' }}
  </div>

  <div *ngSwitchCase="ColumnType.CURRENCY" class="column-type-currency {{ column.name.toLowerCase() }}">
    {{ dataItem[column.technicalName] || '0 - 0 €' }}
  </div>

  <div
    *ngSwitchCase="ColumnType.STATUS"
    class="column-type-status {{ column.name.toLowerCase() }}"
    [matTooltip]="discountStatusMap.get(getStatus(dataItem))?.tooltip!"
    matTooltipPosition="above"
  >
    <div
      class="discount-status-{{ discountStatusMap.get(getStatus(dataItem))?.colorScheme }} {{
        getStatus(dataItem)!.toLowerCase().replace('_', '-')
      }}"
    ></div>
    {{ discountStatusMap.get(getStatus(dataItem))?.text }}
  </div>

  <div *ngSwitchCase="ColumnType.ACTIONS" class="column-type-actions {{ column.name.toLowerCase() }}">
    <button
      *ngFor="let action of actions"
      mat-icon-button
      (click)="action.onClick(dataItem.id)"
      aria-label="button"
      [matTooltip]="action.tooltip"
      matTooltipPosition="above"
    >
      <mat-icon>{{ action.icon }}</mat-icon>
    </button>
  </div>
</ng-container>
