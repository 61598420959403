import { IBarChartData } from './ftd-bar-chart.model';
import { am5 } from '../ftd-base-chart.abstract';

export interface IGenericChartData {
  headings: IGenericChartHeadings[];
  fields: GenericChartFields[];
}

export type GenericChartFieldsType =
  | number
  | string
  | Date
  | boolean
  | null
  | undefined
  | number[]
  | am5.Color
  | IBarChartData;

export class GenericChartFields {
  [key: string]: GenericChartFieldsType;
  xAxisImgSrc?: string;
  xAxisValue?: GenericChartFieldsType;
}

export interface IGenericChartHeadings {
  key: string;
  description: string;
}

export class GenericChartVerticalSpacer extends GenericChartFields {
  verticalSpacer: string = 'VERTICAL_SPACER';
  stroke: am5.Color = am5.color(0xffffff);
  strokeDasharray: number[] = [2, 2];
  strokeOpacity: number = 1;
  location: number = 0.5;
  visible: boolean = true;

  constructor(visible?: boolean, stroke?: am5.Color, location?: number, strokeOpacity?: number) {
    super();
    this.visible = visible ?? true;
    this.location = location ?? 0.5;
    this.strokeOpacity = strokeOpacity ?? 1;
    this.stroke = stroke ?? am5.color(0xffffff);
  }
}
