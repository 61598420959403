<div class="container">
  <div class="main-title">Crosscheck Indication</div>
  <div class="description">
    <span class="color-text-description">
      Current
      <app-crosschecks-traffic-lights
        [isTrafficLightSmall]="true"
        [status]="{ color: trafficLightStatusColor, arrow: 'na' }"
      ></app-crosschecks-traffic-lights>
    </span>
    <span class="color-text-description">
      Estimated
      <app-crosschecks-traffic-lights
        [isTrafficLightSmall]="true"
        [status]="{ color: trafficLightAdjStatusColor, arrow: 'na' }"
      ></app-crosschecks-traffic-lights>
    </span>
  </div>
</div>
