import { AuthError } from '../core/error-handling/errors/enums/auth-error.enum';
import { AuthService } from '../auth/services/auth.service';
import { Component, OnDestroy } from '@angular/core';
import { IFailure } from '../core/error-handling/errors/models/failure';
import { MessageService } from '../common/services/message/message.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { User } from '../auth/models/user.model';

@Component({
  selector: 'app-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnDestroy {
  currentUser!: User | null;
  loading: boolean = false;
  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param authService
   * @param messageService
   */
  constructor(public authService: AuthService, private messageService: MessageService) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * Logout
   */
  logout(): void {
    this.loading = true;
    const subscription = this.authService.logoutUser().subscribe({
      error: (errorData: IFailure<AuthError.LOGOUT_FAILED>): void => {
        this.messageService.showError(errorData.reason);
      },
      next: (): void => {
        this.messageService.showSuccess(`You have been successfully disconnected`);
        this.redirectToLoginPage();
      },
    });
    this.subscriptions.push(subscription);
  }

  // Added method to prevent unit testing error because of page redirect
  redirectToLoginPage() {
    window.location.href = this.authService.getLoginUrl();
  }
}
