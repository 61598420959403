import { ApproveScenarioModalComponent } from './modals/approve-scenario-modal/approve-scenario-modal.component';
import { CommonModule } from '../common/common.module';
import { CrosschecksModule } from '../crosschecks/crosschecks.module';
import { CrosschecksSettingsCardComponent } from '../crosschecks/pages/crosschecks-settings/crosschecks-settings-card/crosschecks-settings-card.component';
import { CrosschecksSettingsComponent } from '../crosschecks/pages/crosschecks-settings/crosschecks-settings.component';
import { HeaderModule } from 'src/app/header/header.module';
import { MainScenarioComponent } from './main-scenario/main-scenario.component';
import { MatDividerModule } from '@angular/material/divider';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MaterialModule } from '../common/material.module';
import { MatrixViewModule } from '../matrix-view/matrix-view.module';
import { CommonModule as NgCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { OverrideStatusModalComponent } from './modals/override-status-modal/override-status-modal.component';
import { SubmitStatusModalComponent } from './modals/submit-status-modal/submit-status-modal.component';
import { UserRolesComponent } from './user-roles/user-roles.component';
import { UserScenarioComponent } from './user-scenario/user-scenario.component';

@NgModule({
  declarations: [
    MainScenarioComponent,
    UserScenarioComponent,
    SubmitStatusModalComponent,
    OverrideStatusModalComponent,
    ApproveScenarioModalComponent,
    UserRolesComponent,
    CrosschecksSettingsComponent,
    CrosschecksSettingsCardComponent,
  ],
  exports: [MainScenarioComponent, UserScenarioComponent],
  imports: [
    NgCommonModule,
    CommonModule,
    HeaderModule,
    MatrixViewModule,
    MatTooltipModule,
    MaterialModule,
    MatSidenavModule,
    MatDividerModule,
    CrosschecksModule,
  ],
})
export class ScenarioModule {}
