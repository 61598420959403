import { ActivatedRoute } from '@angular/router';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-page',
  styleUrls: ['./error-page.component.scss'],
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent implements OnInit {
  @Input() errorMessage: string = 'Error';
  readonly imagePlaceholderPathToAssets: string = 'assets/images/errorServer.svg';

  constructor(private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.errorMessage = this.route.snapshot.paramMap.get('errorMessage')?.toString() || '';
  }
}
