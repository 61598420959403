import { AuthService } from '../../../auth/services/auth.service';
import { CacheFiltersService } from '../../../common/services/cache-filters/cache-filters.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  DiscountFiltersForm,
  DiscountsCachedFilters,
  FiltersList,
  IGranularity,
} from '../../../matrix-view/models/app.model';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IFtdDropdownOption } from '../../../common/models/ftd-dropdown-option.model';
import { MatDialog } from '@angular/material/dialog';

import { MessageService } from '../../../common/services/message/message.service';
import { ScenarioMarketParameter } from '../../../matrix-view/enums/scenario-market-parameter.enum';
import { SideFiltersService } from '../../../common/services/side-filter/side-filter.service';
import { User } from '../../../auth/models/user.model';

@Component({
  selector: 'app-discounts-summary-filters',
  styleUrls: ['./discounts-summary-filters.component.scss'],
  templateUrl: './discounts-summary-filters.component.html',
})

/**
 * @class DiscountsSummaryFiltersComponent
 */
export class DiscountsSummaryFiltersComponent implements OnInit {
  @Input() isLoading: boolean = true;
  currentUser!: User | null;
  marketsList?: FiltersList;
  brandsList?: FiltersList;
  filtersForm!: FormGroup<DiscountFiltersForm>;
  isApplyBtnDisabled: boolean = true;

  private cachedDiscountsFilters?: DiscountsCachedFilters;

  @Output() emitMarketFiltersChanged: EventEmitter<{
    brand: IGranularity[];
    market: string;
  }> = new EventEmitter();

  @Output() emitApplyFiltersChanged: EventEmitter<{
    brand: IGranularity[];
    market: string;
  }> = new EventEmitter();

  /**
   * @constructor
   * @param formBuilder
   * @param filtersService
   * @param cacheFiltersService
   * @param messageService
   * @param authService
   * @param dialog
   */
  constructor(
    private formBuilder: FormBuilder,
    private filtersService: SideFiltersService,
    private cacheFiltersService: CacheFiltersService,
    private messageService: MessageService,
    private authService: AuthService,
    public dialog: MatDialog
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.cachedDiscountsFilters = this.cacheFiltersService.getDiscountsFilters();
    this.listenToCurrentUserChanges();
    this.initFormAndListenValueChanges();
  }

  /**
   * ListenToCurrentUserChanges
   * @private
   */
  private listenToCurrentUserChanges(): void {
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * InitFormAndListenValueChanges
   * @private
   */
  private initFormAndListenValueChanges(): void {
    const filtersList = this.filtersService.transformOptionsForView(
      this.filtersService.getDefaultFilters(),
      this.getUserMarkets()
    );
    this.marketsList = filtersList.get('market') as any;
    this.brandsList = filtersList.get('brand') as any;

    const dropdownDefaultProps = { disabled: true, value: [] };
    this.filtersForm = this.formBuilder.group<DiscountFiltersForm>({
      brand: new FormControl<IGranularity[]>({ ...dropdownDefaultProps }),
      market: new FormControl<string | null>('', Validators.required),
    });
    this.initMarketValueChanges(this.filtersForm.get('market')?.value!);
    this.filtersForm
      .get('market')
      ?.valueChanges?.subscribe((selectedMarket) => this.handleMarketValueChanges(selectedMarket));
    this.filtersForm.get('brand')?.valueChanges?.subscribe(() => this.handleBrandValueChanges());

    if (this.cachedDiscountsFilters?.brand && this.cachedDiscountsFilters?.market) {
      this.initMarketValueChanges(this.cachedDiscountsFilters.market);
    }
  }

  handleMarketValueChanges(selectedMarket: string | null): void {
    this.isApplyBtnDisabled = true;
    this.isLoading = true;
    this.filtersForm.get('brand')?.disable();
    this.filtersForm.patchValue({
      brand: [],
    });
    this.emitMarketFiltersChanged.emit({
      brand: [],
      market: this.filtersForm.controls.market.value || '',
    });

    this.filtersService.getFiltersForMarket(selectedMarket!, this.getUserBrands()).subscribe({
      error: (error) => {
        this.messageService.showError(error.reason);
      },
      next: (data) => {
        this.marketsList = this.filtersService
          .transformOptionsForView(data, this.getUserMarkets())
          .get('market') as FiltersList;
        this.brandsList = this.filtersService
          .transformOptionsForView(data, this.getUserMarkets())
          .get('brand') as FiltersList;
        this.filtersForm.get('brand')?.enable();
        this.handleBrandValueChanges();
        this.isLoading = false;
      },
    });
  }

  handleBrandValueChanges(): void {
    this.isApplyBtnDisabled = false;
  }

  /**
   * OnClickApplyFilters
   */
  onClickApplyFilters(): void {
    if (!this.isApplyBtnDisabled) {
      if (this.filtersForm.controls.brand.value?.length === 0) {
        const brands: IGranularity[] = this.brandsList?.options?.map(
          (brand: IFtdDropdownOption<string>) => brand.value
        ) as unknown as IGranularity[];
        this.filtersForm.controls.brand.patchValue(brands);
      }
      this.cacheFiltersService.setDiscountsFilters({
        brand: this.filtersForm.controls.brand.value,
        market: this.filtersForm.controls.market.value,
      });
      this.emitApplyFiltersChanged.emit({
        brand: this.filtersForm.controls.brand.value as IGranularity[],
        market: this.filtersForm.controls.market.value as string,
      });
      this.isApplyBtnDisabled = true;
    }
  }

  /**
   * InitMarketValueChanges
   * @param selectedMarket
   * @private
   */
  private initMarketValueChanges(selectedMarket: string | null): void {
    if (selectedMarket) {
      this.filtersForm.reset({
        brand: [],
        market: selectedMarket,
      });

      this.filtersService.getFiltersForMarket(selectedMarket, this.getUserBrands()).subscribe({
        error: (error) => {
          this.messageService.showError(error.reason);
        },
        next: (data) => {
          const filtersList: Map<string, FiltersList> = this.filtersService.transformOptionsForView(
            data,
            this.getUserMarkets()
          );
          this.marketsList = filtersList.get('market');
          this.brandsList = filtersList.get('brand');

          Object.keys(this.filtersForm.controls).forEach((controlKey: string) => {
            if (controlKey !== 'market' && this.cachedDiscountsFilters) {
              this.filtersForm
                .get(controlKey)
                ?.patchValue(this.cachedDiscountsFilters[controlKey as keyof DiscountsCachedFilters]);
            }
          });
          this.filtersForm.get('brand')?.enable();
          this.onClickApplyFilters();
        },
      });
    }
  }

  /**
   * Get markets user has permissions to.
   * @returns List of markets.
   */
  private getUserMarkets(): IFtdDropdownOption<string>[] {
    return this.currentUser?.permissions.getMarkets() as IFtdDropdownOption<string>[];
  }

  /**
   * GetUserBrands
   * @private
   */
  private getUserBrands(): string[] {
    return this.currentUser?.permissions.getBrandsFromMarket(this.getMarketFromFilter()) as string[];
  }

  /**
   * GetMarketFromFilter
   * @private
   */
  private getMarketFromFilter(): ScenarioMarketParameter {
    const market = this.filtersForm?.get('market')?.value;
    return <ScenarioMarketParameter>market || '';
  }

  get isButtonDisabled() {
    return this.isApplyBtnDisabled || this.isLoading || !this.brandsList?.options;
  }
}
