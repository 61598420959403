<div class="mv-column-wrapper">
  <ng-container [ngSwitch]="columnConfig.columnType">
    <app-ftd-expanded-tooltip [closeMenuOnMouseLeave]="true" [hasBackdrop]="false">
      <div class="mv-column" tooltipTrigger>
        <ng-container *ngSwitchCase="COLUMN_TYPE.DISABLED">
          <app-discount-mv-disabled-display></app-discount-mv-disabled-display>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.NAME">
          {{ columnConfig.unit }}
          <app-discount-mv-row-name
            [form]="matrixViewFormGroup"
            [controlName]="'checkboxStatus'"
            [dataSourceItem]="dataSourceItem"
            [rowIndex]="index"
            [isBulkSelection]="isBulkSelection"
          >
          </app-discount-mv-row-name>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.UNIT_MAP">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT) &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="
              dataSourceItem.scenarioOutput &&
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig.sectionKey === COLUMN_SECTION.CURRENT_PRICING || COLUMN_SECTION.PRICING_WITH_DISCOUNT)
            "
          >
            <app-discount-mv-unit-map
              *ngIf="columnConfig.unit"
              [COLUMN_UNIT]="columnConfig.unit"
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            >
            </app-discount-mv-unit-map>

            <app-discount-mv-text-display
              *ngIf="!columnConfig.unit"
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-discount-mv-text-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.PRICE_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type !== GRANULARITY_TYPE.BRAND &&
              columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type !== GRANULARITY_TYPE.BRAND &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING || COLUMN_SECTION.PRICING_WITH_DISCOUNT) &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-discount-mv-unit-map
              [COLUMN_UNIT]="columnConfig.unit"
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            >
            </app-discount-mv-unit-map>
          </ng-container>

          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              COLUMN_SECTION.PRICING_WITH_DISCOUNT &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              COLUMN_SECTION.PRICING_WITH_DISCOUNT &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-discount-mv-price-display
              [currency]="columnConfig.unit || 'EUR'"
              [priceHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-discount-mv-price-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.NUMBER_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING || COLUMN_SECTION.PRICING_WITH_DISCOUNT) &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING || COLUMN_SECTION.PRICING_WITH_DISCOUNT) &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-discount-mv-text-display
              [textHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-discount-mv-text-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.INTEGER_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT) &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-discount-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
            ></app-discount-mv-number-percent>

            <ng-template #regular>
              <app-discount-mv-number-display
                [textHolder]="dataSourceItem.scenarioOutput"
                [technicalName]="columnConfig.technicalName"
              ></app-discount-mv-number-display>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.INTEGER_PRICE_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT) &&
              !dataSourceItem?.scenarioOutput
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING &&
              dataSourceItem?.scenarioOutput
            "
          >
            <app-discount-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
            ></app-discount-mv-number-percent>

            <ng-template #regular>
              <app-discount-mv-number-display
                [textHolder]="dataSourceItem.scenarioOutput"
                [technicalName]="columnConfig.technicalName"
                [price]="true"
              ></app-discount-mv-number-display>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.DATE_DISPLAY">
          <div
            *ngIf="dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE && !dataSourceItem?.scenarioOutput"
            class="skeleton-matrix-view-values-overlay"
          ></div>

          <ng-container
            *ngIf="dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE && dataSourceItem?.scenarioOutput"
          >
            <app-discount-mv-date-display
              [dateHolder]="dataSourceItem.scenarioOutput"
              [technicalName]="columnConfig.technicalName"
            ></app-discount-mv-date-display>
          </ng-container>
        </ng-container>

        <ng-container
          *ngIf="
            columnConfig?.columnType === COLUMN_TYPE.NUMBER_DISPLAY ||
            columnConfig?.columnType === COLUMN_TYPE.PRICE_DISPLAY
          "
        >
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.DISCOUNT_INPUT_EDITOR">
          <div
            *ngIf="
              columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT && !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT && dataSourceItem?.priceEditorData"
          >
            <app-ftd-table-input
              [id]="dataSourceItem.id + '-price-input-editor'"
              *ngIf="
                dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                discountContext === DISCOUNT_CONTEXT.create &&
                isBenefitTypeAbsolute
              "
              [rowIndex]="index"
              [currencyMaskOption]="{ allowNegative: true, decimal: '.', precision: 2 }"
              [form]="matrixViewFormGroup"
              [inputStatus]="getPriceEditorInputStatus(index)"
              [controlName]="'price'"
              [placeholder]="'Set a discount'"
              [inputType]="'currency'"
              [currency]="columnConfig.unit || 'EUR'"
              [textAlign]="'right'"
              (click)="priceInputHandler(index)"
              (KeyUp)="onPriceInputChange($event, index)"
              (Paste)="onPriceInputChange($event, index)"
            ></app-ftd-table-input>
            <ng-container
              *ngIf="
                (dataSourceItem.scenarioOutput &&
                  discountContext === DISCOUNT_CONTEXT.read &&
                  dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                  (columnConfig.sectionKey === COLUMN_SECTION.CURRENT_PRICING ||
                    COLUMN_SECTION.PRICING_WITH_DISCOUNT)) ||
                (dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                  discountContext === DISCOUNT_CONTEXT.create &&
                  isBenefitTypeRelative)
              "
            >
              <app-discount-mv-unit-map
                *ngIf="columnConfig.unit"
                [COLUMN_UNIT]="columnConfig.unit"
                [textHolder]="dataSourceItem.scenarioOutput"
                [technicalName]="columnConfig.technicalName"
              >
              </app-discount-mv-unit-map>

              <app-discount-mv-text-display
                *ngIf="!columnConfig.unit"
                [textHolder]="dataSourceItem.scenarioOutput"
                [technicalName]="columnConfig.technicalName"
              ></app-discount-mv-text-display>
            </ng-container>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.DISCOUNT_INPUT_PERCENTAGE_EDITOR">
          <div
            *ngIf="
              columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT && !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT && dataSourceItem?.priceEditorData"
          >
            <app-ftd-table-input
              [id]="dataSourceItem.id + '-price-input-percentage-editor'"
              *ngIf="
                dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                discountContext === DISCOUNT_CONTEXT.create &&
                isBenefitTypeRelative
              "
              [placeholder]="'Set a percentage'"
              [currencyMaskOption]="{ allowNegative: true, decimal: '.', precision: 1 }"
              [inputType]="'percentage'"
              [rowIndex]="index"
              [form]="matrixViewFormGroup"
              [controlName]="'pricePointPercentage'"
              [textAlign]="'right'"
              (KeyUp)="onPricePercentageInputChange($event, index)"
              (Paste)="onPricePercentageInputChange($event, index)"
            ></app-ftd-table-input>
          </ng-container>
          <app-discount-mv-unit-map
            *ngIf="
              (dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                discountContext === DISCOUNT_CONTEXT.read) ||
              (dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
                discountContext === DISCOUNT_CONTEXT.create &&
                isBenefitTypeAbsolute)
            "
            [COLUMN_UNIT]="'%'"
            [textHolder]="dataSourceItem.priceEditorData"
            [technicalName]="columnConfig.technicalName"
          >
          </app-discount-mv-unit-map>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.PRICE_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT &&
              !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT &&
              dataSourceItem?.priceEditorData
            "
          >
            <app-discount-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
              [currency]="columnConfig.unit || 'EUR'"
            ></app-discount-mv-number-percent>

            <ng-template #regular>
              <app-discount-mv-changed-value
                *ngIf="
                  discountContext === DISCOUNT_CONTEXT.create &&
                    !(
                      columnConfig.technicalName === TE_LEASE_RATE_ADJ ||
                      columnConfig.technicalName === DELTA_LEASE_RATE_ADJ
                    );
                  else mainScenarioPrice
                "
                [currency]="columnConfig.unit || 'EUR'"
                [technicalName]="columnConfig.technicalName!"
                [newValue]="matrixViewFormArray.at(index).get('price')?.value"
                [oldValue]="+dataSourceItem.priceEditorData?.listPriceInclTax!"
              ></app-discount-mv-changed-value>
              <ng-template #mainScenarioPrice>
                <app-discount-mv-price-display
                  [currency]="columnConfig.unit || 'EUR'"
                  [priceHolder]="
                    columnConfig.technicalName === TE_LEASE_RATE_ADJ ||
                    columnConfig.technicalName === DELTA_LEASE_RATE_ADJ
                      ? dataSourceItem.scenarioOutput
                      : dataSourceItem.priceEditorData
                  "
                  [technicalName]="columnConfig.technicalName"
                ></app-discount-mv-price-display>
              </ng-template>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.NUMBER_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT &&
              !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT &&
              dataSourceItem?.priceEditorData
            "
          >
            <app-discount-mv-number-percent
              *ngIf="isColumnWithPercent(columnConfig.technicalName); else regular"
              [values]="getPercentageByColumnName(columnConfig.technicalName)"
            ></app-discount-mv-number-percent>

            <ng-template #regular>
              <app-discount-mv-text-display
                [textHolder]="dataSourceItem.priceEditorData"
                [technicalName]="columnConfig.technicalName"
              ></app-discount-mv-text-display>
            </ng-template>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.TEXT_DISPLAY">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT &&
              !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              (columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT ||
                columnConfig?.sectionKey === COLUMN_SECTION.CURRENT_PRICING) &&
              dataSourceItem?.priceEditorData
            "
          >
            <app-discount-mv-text-display
              [textHolder]="dataSourceItem.priceEditorData"
              [technicalName]="columnConfig.technicalName"
            ></app-discount-mv-text-display>
          </ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="COLUMN_TYPE.APPROVAL_STATUS">
          <div
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT &&
              !dataSourceItem?.priceEditorData
            "
            class="skeleton-matrix-view-values-overlay"
          ></div>
          <ng-container
            *ngIf="
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              columnConfig?.sectionKey === COLUMN_SECTION.PRICING_WITH_DISCOUNT &&
              dataSourceItem?.priceEditorData
            "
          >
            <app-discount-mv-approval-status-display
              [textHolder]="dataSourceItem.priceEditorData"
              [technicalName]="columnConfig.technicalName"
            ></app-discount-mv-approval-status-display>
          </ng-container>
        </ng-container>
        <ng-container *ngSwitchCase="COLUMN_TYPE.ACTIONS">
          <div
            class="skeleton-matrix-view-values-overlay"
            *ngIf="
              columnConfig?.sectionKey === COLUMN_SECTION.ACTIONS &&
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE &&
              !dataSourceItem?.priceEditorData
            "
          ></div>

          <ng-container
            *ngIf="
              columnConfig?.sectionKey === COLUMN_SECTION.ACTIONS &&
              dataSourceItem?.priceEditorData &&
              dataSourceItem.granularity.type === GRANULARITY_TYPE.MODEL_CODE
            "
          >
            <app-discount-mv-column-action
              [dataSourceItem]="dataSourceItem"
              [isUserScenario]="discountContext === DISCOUNT_CONTEXT.create"
            ></app-discount-mv-column-action>
          </ng-container>
        </ng-container>
      </div>

      <div class="mv-column-metadata" tooltipContent>
        <app-discount-mv-column-metadata
          [currency]="columnConfig.unit || 'EUR'"
          [scenarioMetaData]="dataSourceItem.scenarioMetaData"
          [technicalName]="columnConfig.technicalName"
        >
        </app-discount-mv-column-metadata>
      </div>
    </app-ftd-expanded-tooltip>
  </ng-container>
</div>
