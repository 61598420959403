import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-ftd-alert',
  styleUrls: ['./ftd-alert.component.scss'],
  templateUrl: './ftd-alert.component.html',
})
export class FtdAlertComponent {
  @Input() iconTypeName?: string;
  @Input() message!: string;
  @Input() borderLight: boolean = false;
}
