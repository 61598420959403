<app-crosschecks-chart-wrapper
  [context]="crosschecksChartContext"
  [skeletonTheme]="'StockAge'"
  [filters]="filters"
  [crosscheckIndicationVisible]="true"
  [trafficLightStatus]="crosscheckIndication"
  [trafficLightAdjStatus]="crosscheckIndicationForecasted"
>
  <div class="header-container">
    <div id="stock-age-filters-container">
      <app-ftd-dropdown
        [id]="'accessible'"
        [label]="'Stock Category'"
        [form]="filtersForm"
        [controlName]="'accessible'"
        [options]="dropdownAccessible"
      ></app-ftd-dropdown>

      <app-ftd-dropdown
        [id]="'stock-price-level'"
        [label]="'Price Level'"
        [form]="filtersForm"
        [controlName]="'stockPriceLevel'"
        [options]="dropdownStockPriceLevel"
      ></app-ftd-dropdown>
    </div>
  </div>
  <div class="container">
    <div class="horizontal-chart-container">
      <div class="horizontal-chart-title-container">
        <span> E-series stock age structure </span>
        <app-ftd-chip
          [enableRemoveButton]="false"
          [chipSize]="chipSize"
          [chipText]="chipText"
          class="date-chip"
          [chipColor]="chipColor"
        ></app-ftd-chip>
      </div>
      <div class="horizontal-chart-wrapper">
        <app-ftd-bar-chart
          *ngIf="horizontalChartData"
          [data]="horizontalChartData"
          [categoryField]="'value'"
          [rootId]="'ftd-bar-chart-ref-stock-age-horizontal'"
          [primaryUnitLabel]="'Units'"
          [chartCustomTheme]="'StockAgeHorizontalCustomTheme'"
          [isLabelValuesVisible]="isChartValuesVisible"
          [isTooltipVisible]="false"
          [chartLegend]="chartLegendFieldNames"
          [horizontalLayout]="true"
          [axisType]="horizontalChartAxisType"
          [isScrollBarVisible]="false"
        ></app-ftd-bar-chart>
      </div>
    </div>
    <div
      class="popup-chart-container"
      *ngIf="filters.type === GranularityType.MODEL_CODE || filters.type === GranularityType.MODEL"
    >
      <div class="popup-chart-title-container">
        <span>historic stock age</span>
        <div class="divider"></div>
        <span class="reference-text"> {{ reference }}</span>
      </div>
      <div class="popup-chart-wrapper">
        <app-ftd-bar-chart
          *ngIf="popupChartData; else noContent"
          [data]="popupChartData"
          [chartCustomTheme]="'StockAgePopupCustomTheme'"
          [categoryField]="'xAxisValue'"
          [axisType]="popupChartAxisType"
          [renderXAxisTop]="true"
          [rootId]="'ftd-bar-chart-ref-stock-age'"
          [isLabelValuesVisible]="isChartValuesVisible"
          [chartLegend]="chartLegendFieldNames"
          [isSysDateIndicatorVisible]="true"
          [isScrollBarVisible]="true"
        ></app-ftd-bar-chart>
        <ng-template #noContent>
          <div class="no-content-available">
            <img ngSrc="./assets/images/granularityEmptyPlaceholder.svg" alt="" height="178" width="220" />
            <p class="no-data-title">We don’t have any data</p>
            <p class="no-data-subtitle">Please try to use other filter</p>
          </div>
        </ng-template>
      </div>
    </div>
    <div
      class="tile-container"
      *ngIf="filters.type === GranularityType.MODEL_CODE || filters.type === GranularityType.MODEL"
    >
      <app-ftd-chart-info-tile
        displayName="MEDIAN STOCK AGE"
        [displayValue]="(tileData?.medianAgeCurrentMonth ?? '0') + ' Days'"
        [displayNameDetailsValue]="tileData?.medianAgeDiff ?? '0'"
        [displayNameDetails]="' days vs prev. month'"
        [displayNameDetailsColor]="setColorString(tileData?.medianAgeDiff ?? 0)"
      ></app-ftd-chart-info-tile>
      <app-ftd-chart-info-tile
        displayName="% STOCK > 180 DAYS"
        [displayValue]="(tileData?.percentageCurrentMonth ?? '0') + '%'"
        [displayNameDetailsValue]="tileData?.over180PercentageDiff?.toString() ?? '0'"
        [displayNameDetails]="' pp vs prev. month'"
        [displayNameDetailsColor]="setColorString(tileData?.over180PercentageDiff ?? 0)"
      ></app-ftd-chart-info-tile>
    </div>
  </div>

  <form class="mt-50" [formGroup]="chartLegendForm">
    <app-crosschecks-chart-filter-group formControlName="stockAgeFields">
      <app-chart-filter-item
        *ngFor="let legendConfig of chartLegendsConfigs"
        [value]="legendConfig.fieldName"
        [label]="legendConfig.label"
        [legendType]="legendConfig.type"
        [legendColor]="legendConfig.legendColor"
      ></app-chart-filter-item>
    </app-crosschecks-chart-filter-group>
  </form>
</app-crosschecks-chart-wrapper>
