<div class="chart-filters-container">
  <form [formGroup]="filtersForm" novalidate class="sales-filters-form">
    <div class="chart-filters">
      <ng-container formArrayName="items">
        <div *ngFor="let dropdown of items.controls; let i = index">
          <ng-container [formGroupName]="i">
            <app-ftd-dropdown
              [label]="dropdownList[i]"
              [controlName]="dropdownList[i]"
              [options]="dropdownOptions[i]"
              [form]="convertToFormGroup(dropdown)"
            ></app-ftd-dropdown>
          </ng-container>
        </div>
      </ng-container>
      <div class="show-values-toggle-container" *ngIf="isShowValuesVisible">
        <span class="show-values-toggle-label">Show Values</span>
        <mat-slide-toggle
          ngDefaultControl
          [formControlName]="'showValuesToggle'"
          matTooltipPosition="above"
        ></mat-slide-toggle>
      </div>
    </div>
  </form>
</div>
