import { AuthService } from '../services/auth.service';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MessageService } from 'src/app/common/services/message/message.service';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class UnauthenticatedInterceptor implements HttpInterceptor {
  constructor(private injector: Injector, private messageService: MessageService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((res) => {
        if (res.status === 401 && request.url.includes('/profile')) {
          const path = this.resetUserAndReturnRedirectUrl();
          this.redirect(path);
        } else if (res.status === 401) {
          const authService = this.injector.get(AuthService);
          authService.getUserProfile().subscribe(() => {
            this.messageService.showError(
              'Insufficent permissions: You do not have permissions to perform this action'
            );
          });
        }
        return throwError(res);
      })
    );
  }

  resetUserAndReturnRedirectUrl(): string {
    const authService = this.injector.get(AuthService);
    authService.resetUser();
    return authService.getLoginUrl();
  }

  redirect(path: string) {
    window.location.href = path;
  }
}
