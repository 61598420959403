/* eslint-disable sort-keys-fix/sort-keys-fix */
import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { AuthService } from '../../../auth/services/auth.service';
import { DiscountManagementService } from '../../services/discount-management.service';
import { FtdNotifierService } from 'src/app/common/services/ftd-notifier/ftd-notifier.service';
import { IDiscount } from 'src/app/graphql/services/gql-api.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialogRef as Mat_dialogRef } from '@angular/material/dialog';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { User } from '../../../auth/models/user.model';
import { takeUntil, tap } from 'rxjs/operators';

/**
 * Attributes for Discount Drafts List table
 */
interface IDiscountDraftsListHeader {
  updatedAt: string;
  discountType: string;
  name: string;
}

@Component({
  selector: 'app-discount-drafts-modal',
  styleUrls: ['./discount-drafts-modal.component.scss'],
  templateUrl: './discount-drafts-modal.component.html',
})
/**
 * @class DiscountDraftsModalComponent
 * @implements OnInit, AfterViewInit, OnDestroy
 */
export class DiscountDraftsModalComponent implements OnInit, AfterViewInit, OnDestroy {
  // Dialog Ref
  private _dialogRef: Mat_dialogRef<DiscountDraftsModalComponent, IDiscount> = inject(Mat_dialogRef);

  // Current User
  private currentUser!: User | null;

  /**
   * Mapping for Column header names
   */
  readonly COLUMNS: IDiscountDraftsListHeader = {
    discountType: 'discountType',
    name: 'name',
    updatedAt: 'updatedAt',
  };

  /**
   * List of columns to be displayed
   */
  readonly displayedColumns: IDiscountDraftsListHeader[keyof IDiscountDraftsListHeader][] = Object.keys(this.COLUMNS);

  /**
   * List of User Discount Drafts
   */
  discountDrafts!: Observable<IDiscount[]>;

  /**
   * Currently selected discount
   */
  selectedDiscount!: IDiscount;

  /**
   * Data source for Mat table for List scenarios
   */
  discountDraftsListDataSource: MatTableDataSource<IDiscount> = new MatTableDataSource<IDiscount>([]);

  /**
   * Subject to unsubscribed subscriptions as soon as component is destroyed
   */
  private _destroy$: Subject<unknown> = new Subject<unknown>();

  /**
   * @constructor
   * @param router
   * @param notifierService
   * @param discountManagementService
   * @param authService
   */
  constructor(
    private router: Router,
    private notifierService: FtdNotifierService,
    private discountManagementService: DiscountManagementService,
    private authService: AuthService
  ) {
    // Current User
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * ViewChild
   * @param sort
   */
  @ViewChild(MatSort) set matSort(sort: MatSort) {
    this.discountDraftsListDataSource.sort = sort;
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    // Fetch a list of discount drafts for logged-in user from GraphQL discountDrafts query
    this.discountDrafts = this.getDiscountDrafts();
  }

  /**
   * NgAfterViewInit
   */
  ngAfterViewInit(): void {
    // Sort discount drafts datasource
    this.discountDraftsListDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    /*
     *Pass null to insert a value to _destroy$ will complete
     *the subscription as we have used takeUntil
     */
    this._destroy$.next(null);
  }

  /**
   * Fetch a list of discount drafts for logged-in user from GraphQL discountDrafts query
   * @returns Observable<IDiscount[]>
   */
  getDiscountDrafts = (): Observable<IDiscount[]> => {
    return this.discountManagementService
      .getDiscountDrafts({
        userId: this.currentUser!.sub,
      })
      .pipe(
        takeUntil(this._destroy$),
        tap((discounts: IDiscount[]): void => {
          this.discountDraftsListDataSource.data = discounts;
        })
      );
  };

  /**
   * SetSelectedDiscount
   * @param selectedDiscount
   */
  setSelectedDiscount(selectedDiscount: IDiscount): void {
    this.selectedDiscount = selectedDiscount;
  }

  /**
   * OpenDiscount
   */
  openDiscount(): void {
    if (this.selectedDiscount?.id) {
      this._dialogRef.close(this.selectedDiscount);
      this.notifierService.showInfo('Opening selected discount ...');
      this.redirectToUserScenarioById(this.selectedDiscount?.id);
    }
  }

  /**
   * RedirectToUserScenarioById
   * @param id
   */
  redirectToUserScenarioById(id: string): void {
    this.router.navigate(['discounts/discount/', id]);
  }

  /**
   * SortingDataAccessor
   * @param discount
   * @param sortHeader
   * @return string
   */
  private sortingDataAccessor = (discount: IDiscount, sortHeader: string): string => {
    if (sortHeader === this.COLUMNS.name) {
      return discount.name!.toLowerCase();
    } else if (sortHeader === this.COLUMNS.updatedAt) {
      return discount.updatedAt!;
    } else if (sortHeader === this.COLUMNS.discountType) {
      return discount.discountType;
    }
    return '';
  };
}
