import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { MessageService } from '../../common/services/message/message.service';

@Injectable({
  providedIn: 'root',
})
export abstract class AbstractService {
  protected httpClient: HttpClient;
  protected messageService: MessageService;

  constructor(protected injector: Injector) {
    this.httpClient = this.injector.get(HttpClient);
    this.messageService = this.injector.get(MessageService);
  }
}
