<div class="main-container">
  <mat-drawer-container>
    <mat-drawer #filtersDrawer [mode]="'side'" [disableClose]="true" [opened]="isFullScreenDisabled">
      <app-discount-matrix-view-side-filters
        *ngIf="!isMatrixViewSideFiltersHidden && isFullScreenDisabled"
        (confirmButtonClicked)="handleSelectFiltersValue($event)"
      ></app-discount-matrix-view-side-filters>
    </mat-drawer>
    <mat-drawer-content>
      <mat-card id="main-container-content">
        <mat-card-content>
          <div [ngClass]="isFullScreenDisabledClass">
            <app-discount-matrix-view-table-header
              [discount]="discount"
              *ngIf="
                selectedFiltersValue &&
                !selectedFiltersValue.shouldResetMatrixViewToDefaultState &&
                isFilterSelected &&
                isFullScreenDisabled
              "
            ></app-discount-matrix-view-table-header>
            <app-discount-matrix-view-data
              [showFiltersToggle]="isFullScreenDisabled"
              [hidden]="isMatrixViewDataHidden"
              [mode]="mode"
              [discount]="discount"
              fxFill
              [selectedFiltersValue]="selectedFiltersValue"
              [granularityDataLoaded]="granularityDataLoaded"
              [granularityHasData]="granularityHasData"
              (toggleFiltersClickEvent)="filtersDrawer.toggle()"
            ></app-discount-matrix-view-data>
          </div>
          <app-discount-matrix-view-footer></app-discount-matrix-view-footer>
        </mat-card-content>
      </mat-card>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
