import { AppModule } from './app/app.module';
import { AwsRum, AwsRumConfig } from 'aws-rum-web';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

if (environment.production) {
  enableProdMode();
}

awsRumInit();

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));

function awsRumInit() {
  try {
    const config: AwsRumConfig = {
      allowCookies: true,
      enableXRay: true,
      endpoint: 'https://dataplane.rum.eu-west-1.amazonaws.com',
      guestRoleArn: 'arn:aws:iam::376714490571:role/RUM-Monitor-eu-west-1-376714490571-3057859559071-Unauth',
      identityPoolId: 'eu-west-1:6f0ccb6e-85d2-45d5-8191-3ddb0b9d34ee',
      sessionSampleRate: 1,
      telemetries: ['performance', 'errors', 'http'],
    };

    const APPLICATION_ID: string = '0e269700-9051-4f1c-8874-931f5dc71905';
    const APPLICATION_VERSION: string = '1.0.0';
    const APPLICATION_REGION: string = 'eu-west-1';

    const awsRum: AwsRum = new AwsRum(APPLICATION_ID, APPLICATION_VERSION, APPLICATION_REGION, config);
    (window as any).awsRum = awsRum;
  } catch (error) {
    // Ignore errors thrown during CloudWatch RUM web client initialization
  }
}
