import { CachedFilters, DiscountsCachedFilters } from '../../../matrix-view/models/app.model';
import { Injectable } from '@angular/core';

enum CacheFiltersStorageKeys {
  MATRIX_VIEW_SIDE_FILTERS = 'cachedFilters',
  DISCOUNTS_FILTERS = 'CACHED_DISCOUNTS_FILTERS',
}

@Injectable({
  providedIn: 'root',
})
export class CacheFiltersService {
  private cachedMatrixViewSideFilters?: CachedFilters;
  private cachedDiscountsFilters?: DiscountsCachedFilters;

  constructor() {
    this.initMatrixViewSideFilters();
    this.initDiscountsFilters();
  }

  initMatrixViewSideFilters(): void {
    const data = localStorage.getItem(btoa(CacheFiltersStorageKeys.MATRIX_VIEW_SIDE_FILTERS));
    this.cachedMatrixViewSideFilters = data ? JSON.parse(atob(data)) : undefined;
  }

  initDiscountsFilters(): void {
    const data = localStorage.getItem(btoa(CacheFiltersStorageKeys.DISCOUNTS_FILTERS));
    this.cachedDiscountsFilters = data ? JSON.parse(atob(data)) : undefined;
  }

  clearMatrixViewSideFilters(): void {
    localStorage.removeItem(btoa(CacheFiltersStorageKeys.MATRIX_VIEW_SIDE_FILTERS));
    this.cachedMatrixViewSideFilters = undefined;
  }

  clearDiscountsFilters(): void {
    localStorage.removeItem(btoa(CacheFiltersStorageKeys.DISCOUNTS_FILTERS));
    this.cachedDiscountsFilters = undefined;
  }

  getMatrixViewSideFilters(): CachedFilters | undefined {
    return this.cachedMatrixViewSideFilters;
  }

  getDiscountsFilters(): DiscountsCachedFilters | undefined {
    return this.cachedDiscountsFilters;
  }

  setMatrixViewSideFilters(filters: CachedFilters): void {
    this.cachedMatrixViewSideFilters = {
      brand: filters?.brand,
      eSeries: filters.eSeries,
      market: filters.market,
      model: filters.model,
      planningHorizon: filters.planningHorizon,
      powertrain: filters.powertrain,
      priceEditorFilled: filters.priceEditorFilled,
      segment: filters.segment,
      series: filters.series,
    };
    localStorage.setItem(
      btoa(CacheFiltersStorageKeys.MATRIX_VIEW_SIDE_FILTERS),
      btoa(JSON.stringify(this.cachedMatrixViewSideFilters))
    );
  }

  setDiscountsFilters(filters: DiscountsCachedFilters): void {
    this.cachedDiscountsFilters = {
      brand: filters?.brand,
      market: filters.market,
    };
    localStorage.setItem(
      btoa(CacheFiltersStorageKeys.DISCOUNTS_FILTERS),
      btoa(JSON.stringify(this.cachedDiscountsFilters))
    );
  }
}
