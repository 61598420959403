import { GenericChartFields } from '../../../common/components/ftd-charts/models/ftd-generic-chart.model';
import { StockAgePropsEnum } from '../../enums/stock-age-props.enum';

/**
 * Stock Age Helper Class
 */
export class StockAgeHelper {
  /**
   * Stock Age chart columns and legends color map: <Fill, Text>
   * @private
   */
  private static readonly stockAgeColorMap: string[][] = [
    ['#7D98C1', '#FFFFFF'],
    ['#2A7ECC', '#FFFFFF'],
    ['#52A2EB', '#FFFFFF'],
    ['#79DDC0', '#000000'],
    ['#25779D', '#FFFFFF'],
    ['#BAB476', '#000000'],
    ['#F8C237', '#000000'],
    ['#F28F33', '#FFFFFF'],
    ['#8CC4F9', '#000000'],
  ];

  /**
   * Fetches color from {@link stockAgeColorMap} color map.
   * @param index color index.
   * @param value either 'fill' or 'text'.
   */
  public static getColor = (index: number, value: 'fill' | 'text') =>
    this.stockAgeColorMap[index][['fill', 'text'].indexOf(value)];

  /**
   * Builds the Granular Bucket tooltip object for current selection.
   * @param ctx Chart data items.
   * @param columnSeriesBucket Current series level on {@link StockAgePropsEnum}.
   * @returns Tooltips array.
   * @see StockAgePropsEnum
   */
  public static buildTooltipColumns(ctx: GenericChartFields, columnSeriesBucket: string) {
    const bucket = this.getInterval(columnSeriesBucket);
    if (bucket.length !== 2) {
      return;
    }

    const [rangeLower, rangeHigher] = bucket;
    const tooltips = [];

    /**
     * Adds an entry object for each key in context that is between selected columnSeriesBucket.
     */
    for (const key in ctx) {
      if (key.includes('Granular')) {
        const bucketBetween = this.getInterval(key);
        if (bucketBetween.length === 2) {
          const [bktLower, bktHigher] = bucketBetween;

          if (Number(bktLower) >= Number(rangeLower) && Number(bktHigher) <= Number(rangeHigher)) {
            tooltips.push({
              description: bucketBetween.join('-') + ' days',
              value: ctx[key] ? ctx[key] : '',
            });
          }
        }
      }
    }

    return tooltips;
  }

  /**
   * Returns current bucket days interval.
   * @param bucket
   */
  private static readonly getInterval = (bucket: string) => bucket.match(/[_\d]/g)!.join('').split('_');
}

/**
 * Checks if current value is a prop of {@link StockAgePropsEnum} and returns either index number or false.
 * to be used alongside with {@link getColor}.
 * @param value property to check.
 * @see StockAgePropsEnum
 */
export const isIndex = (value: string): number | boolean => {
  const valueIndex = Object.keys(StockAgePropsEnum).indexOf(value);
  return valueIndex > -1 && valueIndex;
};
