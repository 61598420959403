import { CdkDrag, CdkDragHandle, CdkDragPlaceholder, CdkDropList } from '@angular/cdk/drag-drop';
import { CrosschecksTrafficLightsComponent } from './components/crosschecks-traffic-lights/crosschecks-traffic-lights.component';
import { DecimalPipe, CommonModule as NgCommonModule, NgOptimizedImage } from '@angular/common';
import { ErrorPageComponent } from '../error-page/error-page.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FtdAccordionComponent } from './components/ftd-accordion/ftd-accordion.component';
import { FtdActionsToolbarComponent } from './components/ftd-actions-toolbar/ftd-actions-toolbar.component';
import { FtdAlertComponent } from './components/ftd-alert/ftd-alert.component';
import { FtdBarChartComponent } from './components/ftd-charts/charts/ftd-bar-chart/ftd-bar-chart.component';
import { FtdBreadcrumbsComponent } from './components/ftd-breadcrumbs/ftd-breadcrumbs.component';
import { FtdButtonComponent } from './components/ftd-button/ftd-button.component';
import { FtdButtonOptionSelectorComponent } from './components/ftd-button-option-selector/ftd-button-option-selector.component';
import { FtdChartInfoTileComponent } from './components/ftd-charts/ftd-chart-info-tile/ftd-chart-info-tile.component';
import { FtdChipComponent } from './components/ftd-chip/ftd-chip.component';
import { FtdCloseTooltipComponent } from './components/ftd-tooltip/ftd-close-tooltip/ftd-close-tooltip.component';
import { FtdCommentComponent } from './components/ftd-comment/ftd-comment.component';
import { FtdCurrencyMaskModule } from './directives/app-currency-mask/app-currency-mask';
import { FtdCurrencyPipe } from './pipes/ftd-currency/ftd-currency.pipe';
import { FtdCustomModalBodyComponent } from './components/modals/ftd-custom-modal-body/ftd-custom-modal-body.component';
import { FtdCustomModalComponent } from './components/modals/ftd-custom-modal/ftd-custom-modal.component';
import { FtdCustomModalFooterComponent } from './components/modals/ftd-custom-modal-footer/ftd-custom-modal-footer.component';
import { FtdCustomModalHeaderComponent } from './components/modals/ftd-custom-modal-header/ftd-custom-modal-header.component';
import { FtdDatepickerComponent, FtdDatepickerHeader } from './components/ftd-date-picker/ftd-date-picker.component';
import { FtdDropdownComponent } from './components/ftd-dropdown/ftd-dropdown.component';
import { FtdExpandedTooltipComponent } from './components/ftd-expanded-tooltip/ftd-expanded-tooltip.component';
import { FtdExpansionPanelComponent } from './components/ftd-expansion-panel/ftd-expansion-panel.component';
import { FtdHeaderComponent } from './components/ftd-header/ftd-header.component';
import { FtdInputComponent } from './components/ftd-input/ftd-input.component';
import { FtdLineAndBarChartComponent } from './components/ftd-charts/charts/ftd-line-and-bar-chart/ftd-line-and-bar-chart.component';
import { FtdLineChartComponent } from './components/ftd-charts/charts/ftd-line-chart/ftd-line-chart.component';
import { FtdMatrixViewColumnsOrderingComponentComponent } from './components/ftd-matrix-view-columns-ordering-component/ftd-matrix-view-columns-ordering-component.component';
import { FtdModalComponent } from './components/ftd-modal/ftd-modal.component';
import { FtdMultiDropdownComponent } from './components/ftd-multi-dropdown/ftd-multi-dropdown.component';
import { FtdMultiDropdownGroupedComponent } from './components/ftd-multi-dropdown-grouped/ftd-multi-dropdown-grouped.component';
import { FtdPriceRangeSelectSliderComponent } from './components/ftd-price-range-select-slider/ftd-price-range-select-slider.component';
import { FtdProgressBarComponent } from './components/ftd-progress-bar/ftd-progress-bar.component';
import { FtdProgressBarModalComponent } from './components/ftd-progress-bar-modal/ftd-progress-bar-modal.component';
import { FtdScrollToBottomDirective } from './directives/ftd-scroll-to-bottom/ftd-scroll-to-bottom.directive';
import { FtdSkeletonComponent } from './components/ftd-skeleton/ftd-skeleton.component';
import { FtdSliderComponent } from './components/ftd-slider/ftd-slider.component';
import { FtdSnackbarComponent } from './components/ftd-snackbar/ftd-snackbar.component';
import { FtdTableDatePickerComponent } from './components/ftd-table-date-picker/ftd-table-date-picker.component';
import { FtdTableInputComponent } from './components/ftd-table-input/ftd-table-input.component';
import { FtdTextareaComponent } from './components/ftd-textarea/ftd-textarea.component';
import { FtdToggleButtonComponent } from './components/ftd-toggle-button/ftd-toggle-button.component';
import { FtdToggleFiltersComponent } from './components/ftd-toggle-filters/ftd-toggle-filters.component';
import { LoadingComponent } from './loading/loading.component';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDateFnsModule } from '@angular/material-date-fns-adapter';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MaterialModule } from './material.module';
import { MessageItemComponent } from './components/messages/message-item/message-item.component';
import { MessagesComponent } from './components/messages/messages.component';
import { NgModule } from '@angular/core';
import { NullLabelPipe } from './pipes/null-label/null-label.pipe';
import { RouterLink } from '@angular/router';
import { TextAndNumberDisplayPipe } from './pipes/text-and-number-display/text-and-number-display.pipe';
import { enGB } from 'date-fns/locale';

@NgModule({
  declarations: [
    LoadingComponent,
    FtdProgressBarComponent,
    FtdAlertComponent,
    FtdToggleButtonComponent,
    FtdButtonComponent,
    FtdButtonOptionSelectorComponent,
    FtdSliderComponent,
    FtdDropdownComponent,
    FtdMultiDropdownComponent,
    FtdMultiDropdownGroupedComponent,
    FtdTableInputComponent,
    MessagesComponent,
    MessageItemComponent,
    FtdHeaderComponent,
    FtdAccordionComponent,
    FtdExpansionPanelComponent,
    FtdExpandedTooltipComponent,
    FtdLineChartComponent,
    FtdBarChartComponent,
    FtdModalComponent,
    FtdCloseTooltipComponent,
    FtdInputComponent,
    FtdTextareaComponent,
    FtdSnackbarComponent,
    FtdActionsToolbarComponent,
    FtdCustomModalComponent,
    FtdCustomModalHeaderComponent,
    FtdCustomModalFooterComponent,
    FtdCustomModalBodyComponent,
    FtdScrollToBottomDirective,
    FtdDatepickerComponent,
    FtdDatepickerHeader,
    FtdProgressBarModalComponent,
    FtdChipComponent,
    NullLabelPipe,
    FtdProgressBarModalComponent,
    FtdCommentComponent,
    FtdToggleFiltersComponent,
    FtdTableDatePickerComponent,
    TextAndNumberDisplayPipe,
    ErrorPageComponent,
    FtdLineAndBarChartComponent,
    CrosschecksTrafficLightsComponent,
    FtdChartInfoTileComponent,
    FtdSkeletonComponent,
    FtdPriceRangeSelectSliderComponent,
    FtdCurrencyPipe,
    FtdBreadcrumbsComponent,
    FtdMatrixViewColumnsOrderingComponentComponent,
  ],
  exports: [
    FtdCloseTooltipComponent,
    NgCommonModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    FtdDropdownComponent,
    FtdMultiDropdownComponent,
    FtdMultiDropdownGroupedComponent,
    FtdProgressBarComponent,
    LoadingComponent,
    FtdAlertComponent,
    FtdToggleButtonComponent,
    FtdButtonComponent,
    FtdButtonOptionSelectorComponent,
    FtdProgressBarModalComponent,
    FtdSliderComponent,
    FtdTableDatePickerComponent,
    MessagesComponent,
    MessageItemComponent,
    FtdLineChartComponent,
    FtdLineAndBarChartComponent,
    FtdBarChartComponent,
    FtdHeaderComponent,
    FtdAccordionComponent,
    FtdExpansionPanelComponent,
    FtdExpandedTooltipComponent,
    FtdTableInputComponent,
    FtdModalComponent,
    FtdInputComponent,
    FtdScrollToBottomDirective,
    FtdTextareaComponent,
    FtdSnackbarComponent,
    FtdActionsToolbarComponent,
    FtdCustomModalComponent,
    FtdCustomModalHeaderComponent,
    FtdCustomModalFooterComponent,
    FtdCustomModalBodyComponent,
    FtdDatepickerComponent,
    FtdChipComponent,
    FtdProgressBarModalComponent,
    FtdCommentComponent,
    FtdToggleFiltersComponent,
    NullLabelPipe,
    TextAndNumberDisplayPipe,
    FtdChartInfoTileComponent,
    CrosschecksTrafficLightsComponent,
    FtdSkeletonComponent,
    FtdPriceRangeSelectSliderComponent,
    FtdBreadcrumbsComponent,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkDragPlaceholder,
    FtdMatrixViewColumnsOrderingComponentComponent,
  ],
  imports: [
    NgCommonModule,
    MatMenuModule,
    MaterialModule,
    MatIconModule,
    MatDatepickerModule,
    MatDateFnsModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    FtdCurrencyMaskModule,
    MatDividerModule,
    MatExpansionModule,
    RouterLink,
    MatRadioModule,
    CdkDropList,
    CdkDrag,
    CdkDragHandle,
    CdkDragPlaceholder,
    NgOptimizedImage,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: enGB }, DecimalPipe, FtdCurrencyPipe],
})
export class CommonModule {}
