import { DiscountApprovalStatus } from '../enums/discount-approval-status.enum';
import { DiscountsContext } from '../enums/discounts-context.enum';

export interface IDiscountsConfig {
  context: DiscountsContext;
  status?: DiscountApprovalStatus;
  title: string;
  isDraftsHidden: boolean;
  isCreateDiscountHidden: boolean;
  isStatusHidden: boolean;
  isSaveHidden: boolean;
  isSaveDisabledTooltip: string;
  isSubmitHidden: boolean;
  isWithdrawHidden: boolean;
  isRejectHidden: boolean;
  isApproveHidden: boolean;
  isReleaseHidden: boolean;
}

export const SUMMARY_DISCOUNTS_CONFIG: IDiscountsConfig = {
  context: DiscountsContext.SUMMARY,
  isApproveHidden: true,
  isCreateDiscountHidden: false,
  isDraftsHidden: false,
  isRejectHidden: true,
  isReleaseHidden: true,
  isSaveDisabledTooltip: 'You don’t have any changes to save',
  isSaveHidden: true,
  isStatusHidden: true,
  isSubmitHidden: true,
  isWithdrawHidden: true,
  title: 'DISCOUNT SUMMARY',
};

export const CREATE_DISCOUNTS_CONFIG: IDiscountsConfig = {
  context: DiscountsContext.SUMMARY,
  isApproveHidden: true,
  isCreateDiscountHidden: true,
  isDraftsHidden: true,
  isRejectHidden: true,
  isReleaseHidden: true,
  isSaveDisabledTooltip: 'You don’t have any changes to save',
  isSaveHidden: false,
  isStatusHidden: true,
  isSubmitHidden: false,
  isWithdrawHidden: true,
  title: 'CREATE DISCOUNT',
};

export const REVIEW_DISCOUNTS_CONFIG: IDiscountsConfig = {
  context: DiscountsContext.SUMMARY,
  isApproveHidden: false,
  isCreateDiscountHidden: true,
  isDraftsHidden: true,
  isRejectHidden: false,
  isReleaseHidden: false,
  isSaveDisabledTooltip: 'You don’t have any changes to save',
  isSaveHidden: true,
  isStatusHidden: false,
  isSubmitHidden: true,
  isWithdrawHidden: false,
  title: 'REVIEW DISCOUNT',
};

/**
 * GetDiscountsConfig
 * Return Discounts Config depending on status
 *
 * @param status
 * @return IDiscountsConfig
 */
export const getDiscountsConfig = (status: DiscountApprovalStatus): IDiscountsConfig => {
  const config: IDiscountsConfig =
    status === DiscountApprovalStatus.NEW ? CREATE_DISCOUNTS_CONFIG : REVIEW_DISCOUNTS_CONFIG;

  return {
    ...config,
    status,
  };
};
