<app-ftd-custom-modal>
  <app-ftd-custom-modal-header
    [title]="'Scenario List'"
    [titleLeftMatIcon]="'folder_open'"
  ></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body>
    <div id="scenario-list-container">
      <ng-template #loading>
        <div class="spinner-wrapper">
          <app-loading [color]="'white'" [diameter]="25"></app-loading>
        </div>
      </ng-template>
      <div id="scenario-list-table-responsive" fxLayout="column" *ngIf="userScenarios$ | async; else loading">
        <table
          aria-describedby="Scenario List"
          id="scenario-list-table"
          [attr.data-test]="'scenario-list-table'"
          mat-table
          [dataSource]="scenarioListDataSource"
          matSort
          [matSortActive]="COLUMNS.creationDate"
          matSortDirection="desc"
          matSortDisableClear
        >
          <ng-container [matColumnDef]="COLUMNS.scenarioName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by scenarioName">Name</th>
            <td mat-cell *matCellDef="let scenario">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5">
                <mat-icon>content_paste</mat-icon>
                <span>{{ scenario.scenarioName }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="COLUMNS.creationDate">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by data/hour">
              Saved in
            </th>
            <td mat-cell *matCellDef="let scenario">{{ scenario.creationDate | date: 'dd LLL yyyy, HH:mm' }}</td>
          </ng-container>

          <ng-container [matColumnDef]="COLUMNS.delete">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let scenario" (click)="deleteScenario(scenario)">
              <div class="scenario-delete">
                <mat-icon [matTooltipPosition]="'above'" [matTooltip]="'Delete'">delete</mat-icon>
              </div>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let scenarioItem; columns: displayedColumns"
            (click)="setSelectedScenario(scenarioItem)"
            [ngClass]="{ 'scenario-selected': selectedScenario && scenarioItem.id === selectedScenario.id }"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell">No scenario data found</td>
          </tr>
        </table>
      </div>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer>
    <app-ftd-button
      id="open-scenario-btn"
      [attr.data-test]="'open-scenario-btn'"
      (btnClick)="openScenario()"
      [disabled]="!selectedScenario"
      label="Open"
      ariaText="Select a User Scenario"
      matTooltip="Select a User Scenario"
      matTooltipPosition="above"
      [matTooltipDisabled]="!!selectedScenario"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
