import { AuthService } from '../../../auth/services/auth.service';
import { Brand } from '../../../matrix-view/models/brand.model';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DiscountManagementService } from '../../services/discount-management.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IAddDiscountsMutationVariables, IDiscount } from '../../../graphql/services/gql-api.service';
import { IFtdDropdownOption } from '../../../common/models/ftd-dropdown-option.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from '../../../common/services/message/message.service';
import { Router } from '@angular/router';
import { ScenarioMarketParameter } from '../../../matrix-view/enums/scenario-market-parameter.enum';
import { Subscription } from 'rxjs';
import { User } from '../../../auth/models/user.model';
import { distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'app-create-discount-modal',
  styleUrls: ['./create-discount-modal.component.scss'],
  templateUrl: './create-discount-modal.component.html',
})
export class CreateDiscountModalComponent implements OnInit, OnDestroy {
  private currentUser!: User | null;
  private subscriptions: Subscription[] = [];
  public isRequestProcessing: boolean = false;
  currentStep: number = 1;
  maxStep: number = 1;
  stepperData: string[] = ['Step 1', 'Step 2'];
  benefitTypeTooltipMessage: string =
    'Defines whether the discount as a percentage or absolute amount on the list price';

  applicationLevelTooltipMessage: string = 'Defines whether the discount is applied on gross or net list price';

  brandsOptions: IFtdDropdownOption<Brand>[] = [];
  marketsOptions: IFtdDropdownOption[] = [];

  benefitTypeOptions: IFtdDropdownOption[] = [
    { id: 'ABSOLUTE', label: 'Absolute', value: 'ABSOLUTE' },
    { id: 'RELATIVE', label: 'Relative', value: 'RELATIVE' },
  ];

  discountTypeOptions: IFtdDropdownOption[] = [];
  appLevelOptions: IFtdDropdownOption[] = [
    { id: 'GROSS_LIST_PRICE', label: 'Gross list price', value: 'GROSS_LIST_PRICE' },
    { id: 'NET_LIST_PRICE', label: 'Net list price', value: 'NET_LIST_PRICE' },
  ];

  private discountTypeCampaignOptions: IFtdDropdownOption[] = [
    { id: 'BEV_CAMPAIGN', label: 'BEV campaign', value: 'BEV_CAMPAIGN' },
    { id: 'RUN_RATE_SUPPORT', label: 'Run rate support', value: 'RUN_RATE_SUPPORT' },
    { id: 'LOYALTY_CAMPAIGN', label: 'Loyalty campaign', value: 'LOYALTY_CAMPAIGN' },
    { id: 'CONQUEST_CAMPAIGN', label: 'Conquest campaign', value: 'CONQUEST_CAMPAIGN' },
    { id: 'EVENT_BASED_CAMPAIGN', label: 'Event-based campaign', value: 'EVENT_BASED_CAMPAIGN' },
  ];

  private discountTypeSpecialB2cDiscountOptions: IFtdDropdownOption[] = [
    { id: 'VIP_A', label: 'VIP A', value: 'VIP_A' },
    { id: 'VIP_B', label: 'VIP B', value: 'VIP_B' },
    { id: 'VIP_C', label: 'VIP C', value: 'VIP_C' },
    { id: 'DISABLED_PERSON', label: 'Disabled person', value: 'DISABLED_PERSON' },
    { id: 'JOURNALIST', label: 'Journalist', value: 'JOURNALIST' },
  ];

  categoryOptions: IFtdDropdownOption[] = [
    { id: 'SPECIAL_B2C_DISCOUNT', label: 'Special B2C Discount', value: 'SPECIAL_B2C_DISCOUNT' },
    { id: 'CAMPAIGN', label: 'Campaign', value: 'CAMPAIGN' },
  ];

  salesChannelOptions: IFtdDropdownOption[] = [
    { id: 'NC', label: 'NC Private (New car)', value: 'NC' },
    { id: 'YUC', label: 'YUC Private (Young Used Car)', value: 'YUC' },
  ];

  minEndDate: Date = new Date();

  discountTypeForm!: FormGroup;
  discountSettingsForm!: FormGroup;

  /**
   * @constructor
   * @param data
   * @param dialogRef
   * @param formBuilder
   * @param router
   * @param authService
   * @param messageService
   * @param discountManagementService
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateDiscountModalComponent>,
    private formBuilder: FormBuilder,
    private router: Router,
    private authService: AuthService,
    private messageService: MessageService,
    private readonly discountManagementService: DiscountManagementService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initForm();
    this.getUserMarkets();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * GetPayload
   * @return IAddDiscountsMutationVariables
   */
  getPayload(): IAddDiscountsMutationVariables {
    return {
      applicationLevel: this.discountSettingsForm.get('applicationLevel')?.value,
      benefitType: this.discountSettingsForm.get('benefitType')?.value,
      brand: this.discountTypeForm.get('brand')?.value,
      discountCategory: this.discountTypeForm.get('discountCategory')?.value,
      discountType: this.discountTypeForm.get('discountType')?.value,
      endDate: this.discountSettingsForm.get('endDate')?.value,
      market: this.discountTypeForm.get('market')?.value,
      name: this.discountTypeForm.get('name')?.value,
      salesChannels: this.discountTypeForm.get('salesChannels')?.value,
      startDate: this.discountSettingsForm.get('startDate')?.value,
      userId: this.currentUser!.sub,
    };
  }

  /**
   * CreateDiscount
   */
  createDiscount(): void {
    this.isRequestProcessing = true;

    const payload: IAddDiscountsMutationVariables = this.getPayload();

    this.discountManagementService.addDiscounts(payload).subscribe((discount: IDiscount): void => {
      this.messageService.showSuccess(`Discount created`, 5);
      this.isRequestProcessing = false;
      this.dialogRef.close();

      this.router.navigate(['discounts/discount/', discount.id]);
    });
  }

  /**
   * NextStep
   */
  nextStep(): void {
    this.currentStep = this.currentStep + 1;
    if (this.currentStep > this.maxStep) {
      this.maxStep = this.currentStep;
    }
  }

  /**
   * PreviousStep
   */
  previousStep(): void {
    this.currentStep = this.currentStep - 1;
  }

  /**
   * OnClickCancel
   */
  onClickCancel(): void {
    this.dialogRef.close();
  }

  /**
   * GoToStep
   * @param step
   */
  goToStep(step: number): void {
    this.currentStep = step;
  }

  /**
   * SelectedCategory
   * @param category
   */
  selectedCategory(category: string): void {
    this.discountTypeOptions =
      category === 'CAMPAIGN' ? this.discountTypeCampaignOptions : this.discountTypeSpecialB2cDiscountOptions;

    this.discountTypeForm.controls.discountType.enable();
  }

  /**
   * Get markets user has permissions to.
   * @returns List of markets.
   */
  getUserMarkets(): void {
    this.marketsOptions = this.currentUser?.permissions.getMarkets() as IFtdDropdownOption<string>[];
  }

  /**
   * GetUserBrands
   * @param market
   */
  getUserBrands(market: ScenarioMarketParameter): void {
    const temp: Brand[] = this.currentUser?.permissions.getBrandsFromMarket(market) as Brand[];
    this.brandsOptions = temp.map((brand: Brand) => {
      return { id: brand, label: brand, value: brand };
    });
    this.discountTypeForm.controls.brand.enable();
  }

  /**
   * InitForm
   * @private
   */
  private initForm(): void {
    this.discountTypeForm = this.formBuilder.group({
      brand: new FormControl<string[]>({ disabled: true, value: [] }, Validators.required),
      discountCategory: new FormControl<string[]>([], Validators.required),
      discountType: new FormControl<string[]>({ disabled: true, value: [] }, Validators.required),
      market: new FormControl<string[]>([], Validators.required),
      name: new FormControl<string>('', Validators.required),
      salesChannels: new FormControl<string[]>([], Validators.required),
    });
    this.discountSettingsForm = this.formBuilder.group({
      applicationLevel: new FormControl<string[]>([], Validators.required),
      benefitType: new FormControl<string[]>([], Validators.required),
      endDate: new FormControl<Date | undefined>(undefined, Validators.required),
      startDate: new FormControl<Date | undefined>(undefined, Validators.required),
    });
    const subscription: Subscription = this.discountTypeForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((): void => {
        if (this.maxStep === this.currentStep && this.discountTypeForm.valid) {
          this.maxStep = this.currentStep + 1;
        }
      });
    this.subscriptions.push(subscription);

    const dateSubscription: Subscription = this.discountSettingsForm.controls.startDate.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe((startDate: Date): void => {
        if (startDate) {
          this.minEndDate = startDate;
        } else {
          this.minEndDate = new Date();
        }
      });
    this.subscriptions.push(dateSubscription);
  }
}
