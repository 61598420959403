import { AuthService } from '../../../auth/services/auth.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { Component, Inject, OnDestroy, OnInit, inject } from '@angular/core';
import { IDeleteScenarioDataRequest } from '../../../matrix-view/models/api.model';
import { IMutationDeleteScenarioArgs, IScenario } from 'src/app/graphql/services/gql-api.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MessageService } from 'src/app/common/services/message/message.service';
import { Router } from '@angular/router';
import { ScenariosGQLAPIService } from 'src/app/graphql/services/scenarios-gql-api.service';
import { User } from '../../../auth/models/user.model';
import { finalize, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-scenario-delete-modal',
  templateUrl: './scenario-delete-modal.component.html',
})
export class ScenarioDeleteModalComponent implements OnInit, OnDestroy {
  currentUserId?: User['sub'];
  title: string = 'Delete scenario';
  description: string = `You are deleting this scenario. </br> Are you sure you want to proceed? `;

  requestProcessingMessage: string = "Don't close this window, please wait a few moments";

  private _destroy$ = new Subject<unknown>();
  public disableButton$ = new BehaviorSubject<boolean>(false);

  private _authService = inject(AuthService);
  private messageService = inject(MessageService);
  private _scenariosGQLAPIService = inject(ScenariosGQLAPIService);

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: IScenario,
    private dialogRef: MatDialogRef<IDeleteScenarioDataRequest>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.currentUserId = this._authService.getLoggedInUser()!.sub;
  }

  ngOnDestroy(): void {
    this._destroy$.next(null);
  }

  delete(): void {
    this.disableButton$.next(true);

    const payload: IMutationDeleteScenarioArgs = {
      scenarioId: this.data.id,
    };

    this._scenariosGQLAPIService
      .deleteUserScenario$(payload)
      .pipe(
        takeUntil(this._destroy$),
        tap(() => {
          this.onDeleteScenarioSuccess();
          this.dialogRef.close();
        }),
        finalize(() => this.disableButton$.next(false))
      )
      .subscribe();
  }

  onDeleteScenarioSuccess() {
    this.messageService.showSuccess('Scenario deleted');
    this.router.navigate(['/main-scenario']);
  }
}
