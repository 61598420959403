import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { IFtdDropdownOption } from 'src/app/common/models/ftd-dropdown-option.model';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-chart-filters',
  styleUrls: ['./chart-filters.component.scss'],
  templateUrl: './chart-filters.component.html',
})

/**
 * @class
 * ChartFiltersComponent
 */
export class ChartFiltersComponent implements OnInit, OnDestroy {
  @Input() dropdownDisabled: boolean[] = [];
  @Input() dropdownList: string[] = [];
  @Input() dropdownOptions: IFtdDropdownOption<{}>[][] = [];
  @Input() isShowValuesVisible: boolean = true;
  @Output() showValuesToggleEvent: EventEmitter<boolean> = new EventEmitter<boolean>();
  filtersForm!: FormGroup;
  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param formBuilder
   */
  constructor(private formBuilder: FormBuilder) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.initForm();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * InitForm
   * @private
   */
  private initForm(): void {
    this.filtersForm = this.formBuilder.group({
      items: new FormArray<FormGroup>([]),
      showValuesToggle: new FormControl<boolean>({ disabled: false, value: true }),
    });
    // Emit "true" value, to show label's values immediately
    this.showValuesToggleEvent.emit(true);

    const showValuesToggleSubscription: Subscription =
      this.filtersForm.controls.showValuesToggle.valueChanges.subscribe((value: boolean): void => {
        this.showValuesToggleEvent.emit(value);
      });
    this.subscriptions.push(showValuesToggleSubscription);

    this.dropdownList.forEach((item: string): void => {
      this.addItem(item);
    });
  }

  /**
   * Items
   * @return FormGroup
   */
  get items(): FormArray {
    return this.filtersForm.get('items') as FormArray<FormGroup>;
  }

  convertToFormGroup(itemControl: AbstractControl): FormGroup {
    return itemControl as FormGroup;
  }

  /**
   * CreateItem
   * @return FormGroup
   */
  createItem(item: string): FormGroup {
    return this.formBuilder.group({
      [item]: new FormControl({
        disabled: this.dropdownDisabled[this.items.length],
        value: this.dropdownOptions[this.items.length],
      }),
    });
  }

  /**
   * AddItem
   */
  addItem(item: string): void {
    this.items.push(this.createItem(item));
  }
}
