import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ICurrencyMaskConfig } from '../../directives/app-currency-mask/lib/currency-mask.config';

@Component({
  selector: 'app-ftd-input',
  templateUrl: './ftd-input.component.html',
})
export class FtdInputComponent {
  @Input() id!: string;
  @Input() label?: string;
  @Input() maxlength?: number;
  @Input() hideMaxlengthHint: boolean = false;
  @Input() placeholder: string = '';
  @Input() customMessage?: string;
  @Input() form!: FormGroup;
  @Input() controlName = '';
  @Input() errorMessage?: string;
  @Input() prefixValue?: string | number;
  @Input() prefixIconName?: string;

  /**
   * Allow to get advantages of Directive {@link CurrencyMaskDirective} to display input-value in desired price + currency format
   */
  @Input() enableCurrencyMask: boolean = false;
  @Input() currencyMaskOptions: ICurrencyMaskConfig = {};
  /**
   * Makes input fitting & filling the available container's width
   */
  @Input() fillWidth: boolean = false;
  /**
   * Shows input-errors directly after initialization.
   *
   * Or wait for User changed the input-value before showing possible errors
   */
  @Input() checkErrorsOnInit: boolean = false;

  get hasInputErrors() {
    return (
      (this.checkErrorsOnInit ? this.form?.pristine : this.form?.dirty) && this.form?.get(this.controlName)?.invalid
    );
  }

  get inputValue(): string {
    return `${this.form.get(this.controlName)?.value}`;
  }
}
