<app-ftd-custom-modal>
  <app-ftd-custom-modal-header
    [titleLeftMatIcon]="'warning_amber'"
    [colorLeftMatIcon]="'warning'"
    [title]="title"
  ></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body
    [requestProcessingMessage]="requestProcessingMessage"
    [isRequestProcessing]="(disableButton$ | async) === true"
  >
    <div class="mb-3">
      <span [innerHTML]="description"></span>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer [cancelButtonText]="'CANCEL'">
    <app-ftd-button
      hierarchy="red"
      [id]="'delete-scenario-btn'"
      [attr.data-test]="'delete-scenario-btn'"
      (btnClick)="delete()"
      [disabled]="(disableButton$ | async) === true"
      [label]="'Delete'"
      ariaText="Delete User Scenario"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="true"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
