<app-ftd-custom-modal>
  <app-ftd-custom-modal-header
    [titleLeftMatIcon]="titleLeftMatIcon"
    [colorLeftMatIcon]="colorLeftMatIcon"
    [title]="'Submit Discount'"
  ></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body [isRequestProcessing]="isRequestProcessing">
    <ng-container *ngIf="!isRequestProcessing || isLoading">
      <ng-container *ngIf="hasDiscountPoints">
        <div>
          <span>
            You are submitting the “{{ data.discount.name }} ” discount, valid as of
            <b>{{ getMaskedDate(data.discount.startDate!) }}</b> until <b>{{ getMaskedDate(data.discount.endDate!) }}</b
            >.
          </span>
        </div>
        <div class="mb-3">
          The discount is applied to <b> {{ totalDiscounts }} model code(s).</b>
        </div>
        <div>
          <span> All discounts saved will be submitted. </span>
        </div>
        <div>
          <span> Are you sure you want to proceed? </span>
        </div>
      </ng-container>
      <ng-container *ngIf="!hasDiscountPoints">
        <div>
          <span> You are submitting “{{ data.discount.name }}” discount with no discounts set. </span>
        </div>
        <div>Please enter the discount points.</div>
      </ng-container>
    </ng-container>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer [hideCancelButton]="true" *ngIf="!isRequestProcessing || isLoading">
    <app-ftd-button
      data-test="btn-cancel"
      class="mr-2 no-border"
      [fillWidth]="true"
      [label]="cancelButtonText"
      [ariaText]="cancelButtonText"
      [hierarchy]="cancelButtonColor"
      (click)="cancel()"
    ></app-ftd-button>

    <app-ftd-button
      *ngIf="hasDiscountPoints"
      id="open-discount-btn"
      (btnClick)="submitDiscount()"
      [disabled]="isSubmitButtonDisabled"
      hierarchy="green"
      [label]="'Submit'"
      ariaText="Submit"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="isSubmitButtonDisabled"
      [attr.data-test]="'submit-discount-btn'"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
