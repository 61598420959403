import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-stock-mix-side-table',
  styleUrls: ['./stock-mix-side-table.component.scss'],
  templateUrl: './stock-mix-side-table.component.html',
})
export class StockMixSideTableComponent {
  @Input() dataSource!: IStockMixSideTable[];
}

export interface IStockMixSideTable {
  nextMonthExpected: string;
  totalStock: string;
  deltaStockCoverage?: DeltaStockCoverageEnum;
}

export enum DeltaStockCoverageEnum {
  increase = 'increase',
  stable = 'stable',
  decrease = 'decrease',
}
