<div id="settings-page-container">
  <app-crosschecks-header [title]="title" [isToggleFiltersVisible]="false"></app-crosschecks-header>

  <div class="crosscheck-wrapper">
    <div *ngFor="let crosscheck of crosschecksNames; index as i" class="card-divider">
      <span class="crosscheck-name"
        >{{ crosscheck }}
        <div class="circle-divider"></div>
        Σ 100%</span
      >
      <div class="settings-card-container">
        <app-crosschecks-settings-card
          *ngFor="let crosschecksSubname of crosschecksSettingsData[i]"
          [crosscheck]="crosschecksSubname"
        ></app-crosschecks-settings-card>
      </div>
    </div>
  </div>
</div>
