import { IFtdDropdownOption } from '../models/ftd-dropdown-option.model';

export class FtdDropdownOptionsUtils {
  static buildOptionsWithStringValues(options: string[]): IFtdDropdownOption<string>[] {
    return options.map<IFtdDropdownOption<string>>((item, index) => ({
      id: index,
      label: item,
      value: item,
    }));
  }

  static buildOptionsWithObjectValues<T>(options: (T | any)[], labelKey: string): IFtdDropdownOption<T>[] {
    return options.map<IFtdDropdownOption<T>>((item) => ({
      id: item[labelKey],
      label: item[labelKey],
      value: item as T,
    }));
  }
}
