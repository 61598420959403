export enum SalesChannels {
  NC = 'NC private',
  YUC = 'YUC private',
}

export const SalesChannelsMap: Map<SalesChannelsKey, SalesChannelsValue> = new Map<
  SalesChannelsKey,
  SalesChannelsValue
>([
  ['NC', ' NC Private'],
  ['YUC', ' YUC Private'],
]);

export type SalesChannelsKey = 'NC' | 'YUC';
export type SalesChannelsValue = ' NC Private' | ' YUC Private';
