import { Component, Inject, OnDestroy, OnInit, Optional, inject } from '@angular/core';
import { DiscountManagementService } from '../../services/discount-management.service';
import { FtdNotifierService } from 'src/app/common/services/ftd-notifier/ftd-notifier.service';
import {
  IDiscount,
  IDiscountPointsByDiscountIdTotalResponse,
  ISubmitDiscountMutationVariables,
} from 'src/app/graphql/services/gql-api.service';

import { BtnHierarchy } from '../../../common/components/ftd-button/ftd-button.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogRef as Mat_dialogRef } from '@angular/material/dialog';
import { MessageService } from '../../../common/services/message/message.service';
import { Subscription } from 'rxjs';
import dayjs from 'dayjs';

@Component({
  selector: 'app-discount-submit-modal',
  styleUrls: ['./discount-submit-modal.component.scss'],
  templateUrl: './discount-submit-modal.component.html',
})
/**
 * @class DiscountSubmitModalComponent
 * @implements OnInit, AfterViewInit, OnDestroy
 */
export class DiscountSubmitModalComponent implements OnInit, OnDestroy {
  // Dialog Ref
  private _dialogRef: Mat_dialogRef<DiscountSubmitModalComponent, IDiscount> = inject(Mat_dialogRef);
  private discountManagementService: DiscountManagementService = inject(DiscountManagementService);
  private notifierService: FtdNotifierService = inject(FtdNotifierService);
  private messageService: MessageService = inject(MessageService);
  totalDiscounts: number = -1;
  private subscriptions: Subscription[] = [];
  isRequestProcessing: boolean = true;
  isLoading: boolean = false;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: { discount: IDiscount },
    private dialogRef: MatDialogRef<DiscountSubmitModalComponent>
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    // Fetch total number of discounts
    this.getDiscountPointsByDiscountIdTotal();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * TitleLeftMatIcon
   * @return string
   */
  get titleLeftMatIcon(): string {
    return this.totalDiscounts === 0 ? 'warning_amber' : '';
  }

  /**
   * ColorLeftMatIcon
   * @return string
   */
  get colorLeftMatIcon(): string {
    return this.totalDiscounts === 0 ? 'warning' : '';
  }

  /**
   * CancelButtonText
   * @return string
   */
  get cancelButtonText(): string {
    return this.totalDiscounts > 0 ? 'CANCEL' : 'GO BACK';
  }

  /**
   * CancelButtonColor
   * @return string
   */
  get cancelButtonColor(): BtnHierarchy {
    return this.totalDiscounts > 0 ? 'base' : 'primary';
  }

  /**
   * HasDiscountPoints
   * @return boolean
   */
  get hasDiscountPoints(): boolean {
    return this.totalDiscounts > 0;
  }

  /**
   * GetMaskedDate
   * @param date
   */
  getMaskedDate(date: string): string {
    return dayjs(date).format('DD.MM.YY');
  }

  /**
   * IsSubmitButtonDisabled
   * @return boolean
   */
  get isSubmitButtonDisabled(): boolean {
    return this.isRequestProcessing || this.totalDiscounts === 0 || this.isLoading;
  }

  /**
   * Fetch total number of discounts
   */
  getDiscountPointsByDiscountIdTotal(): void {
    const subscription: Subscription = this.discountManagementService
      .getDiscountPointsByDiscountIdTotal({
        discountId: this.data.discount.id,
      })
      .subscribe((response: IDiscountPointsByDiscountIdTotalResponse): void => {
        this.totalDiscounts = response.total;
        this.isRequestProcessing = false;
      });

    this.subscriptions.push(subscription);
  }

  /**
   * GetSubmitDiscountPayload
   * @return ISubmitDiscountMutationVariables
   */
  getSubmitDiscountPayload(): ISubmitDiscountMutationVariables {
    return {
      input: {
        id: this.data.discount.id,
      },
    };
  }

  /**
   * SubmitDiscount
   */
  submitDiscount(): void {
    this.isLoading = true;
    this.isRequestProcessing = true;

    // Payload
    const payload: ISubmitDiscountMutationVariables = this.getSubmitDiscountPayload();

    this.discountManagementService.submitDiscount(payload).subscribe(
      (discount: IDiscount): void => {
        this.isLoading = false;
        this.onDiscountSubmitSuccess();
        this.dialogRef.close(true);
      },
      (error): void => {
        this.onDiscountSubmitError();
      }
    );
  }

  /**
   * OnDiscountSaveSuccess
   */
  private onDiscountSubmitSuccess(): void {
    this.messageService.showSuccess(`Discount submitted`);
  }

  /**
   * OnDiscountSaveError
   */
  private onDiscountSubmitError(): void {
    this.messageService.showError(`Error in discount submission`);
  }

  cancel(): void {
    this.dialogRef.close();
  }
}
