export type keyofOrderIntakeSeriesName = keyof OrderIntakeSeriesName;

export enum OrderIntakeSeriesName {
  actualsThisYear = 'actualsThisYear',
  actualsPreviousYear = 'actualsPreviousYear',
  orderIntakeBudget = 'orderIntakeBudget',
  enginePredictedOrderIntakeVolume = 'enginePredictedOrderIntakeVolume',
  enginePredictedOrderIntakeVolumeAdj = 'enginePredictedOrderIntakeVolumeAdj',
  orderIntakeLatestForecast = 'orderIntakeLatestForecast',
  orderIntakeSecondLatestForecast = 'orderIntakeSecondLatestForecast',
}

export enum OrderIntakeSeriesDisplayName {
  actualsThisYear = 'Actuals this year',
  actualsPreviousYear = 'Actuals previous year',
  orderIntakeBudget = 'Order intake budget',
  enginePredictedOrderIntakeVolume = 'Order intake NCPE-Estimation (current price)',
  enginePredictedOrderIntakeVolumeAdj = 'Order intake NCPE-Estimation (scenario price)',
  orderIntakeLatestForecast = 'Order intake forecast',
  orderIntakeSecondLatestForecast = 'Order intake forecast',
}
