<div class="custom-modal-header" fxLayout="row" fxLayoutAlign="space-between center">
  <div fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="8">
    <mat-icon class="ftd-modal-header-icon-color" [ngClass]="colorLeftMatIcon" *ngIf="titleLeftMatIcon">{{
      titleLeftMatIcon
    }}</mat-icon>
    <h3 *ngIf="title; else ChildContentTemplate">
      {{ title }}
    </h3>
  </div>
  <button
    class="custom-model-header-button-close"
    (click)="handleCloseButtonClick($event)"
    mat-icon-button
    mat-dialog-close=""
    [attr.data-test]="'close-model-btn'"
  >
    <mat-icon id="custom-modal-header-close">close</mat-icon>
  </button>
</div>

<ng-template #ChildContentTemplate>
  <div>
    <ng-content></ng-content>
  </div>
</ng-template>
