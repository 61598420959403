<app-crosschecks-chart-wrapper
  [context]="crosschecksChartContext"
  [skeletonTheme]="'StockCoverage'"
  [filters]="filters"
  [trafficLightAdjStatus]="crosscheckIndicationForecasted!"
  [trafficLightStatus]="crosscheckIndication!"
  [crosscheckIndicationVisible]="true"
>
  <div class="header-container">
    <div id="stock-coverage-filters-container">
      <app-ftd-dropdown
        [id]="'considered-price'"
        [label]="'Considered Price'"
        [form]="filtersForm"
        [controlName]="'consideredPrice'"
        [options]="consideredPrice"
      ></app-ftd-dropdown>

      <app-ftd-dropdown
        [id]="'accessible'"
        [label]="'Stock Category'"
        [form]="filtersForm"
        [controlName]="'accessible'"
        [options]="dropdownAccessible"
      ></app-ftd-dropdown>

      <app-ftd-dropdown
        [id]="'stock-price-level'"
        [label]="'Price Level'"
        [form]="filtersForm"
        [controlName]="'stockPriceLevel'"
        [options]="dropdownStockPriceLevel"
      ></app-ftd-dropdown>

      <div [formGroup]="filtersForm" class="show-values-toggle-container">
        <span class="show-values-toggle-label">Show Values</span>
        <mat-slide-toggle
          [formControlName]="'showValuesToggle'"
          matTooltipPosition="above"
          ngDefaultControl
        ></mat-slide-toggle>
      </div>
    </div>
  </div>
  <div class="subheader-container">
    <span class="subheader-text"> Stock units </span
    ><mat-icon alt="Information" [matTooltip]="stockUnitsTooltipText">info</mat-icon>
  </div>

  <div class="container">
    <div class="chart-container">
      <app-ftd-bar-chart
        *ngIf="initLineChart && initBarChart"
        [data]="barChartData"
        [isSysDateIndicatorVisible]="true"
        [clustered]="false"
        [stacked]="false"
        [chartCustomTheme]="'StockCoverageBarCustomTheme'"
        [rootId]="'ftd-bar-chart-ref-stock-coverage'"
        [isLabelValuesVisible]="isChartValuesVisible"
        [axisType]="chartAxisType"
        [chartLegend]="barChartLegend"
        [primaryUnitLabel]="'Units'"
        [renderYAxisRight]="true"
        [isScrollBarVisible]="true"
      ></app-ftd-bar-chart>
    </div>
    <div class="tile-container" fxLayout="column">
      <app-ftd-chart-info-tile
        *ngIf="totalAccessibleStock"
        [displayName]="totalAccessibleStock.stockUnitsDisplayName"
        [displayValue]="totalAccessibleStock.currentActualStockUnits"
        [displayNameDetailsColor]="setColorString(parseFloat(totalAccessibleStock.deltaCurrentToSoY) || 0)"
        [displayNameDetails]="' vs SOY'"
        [displayNameDetailsValue]="totalAccessibleStock.deltaCurrentToSoY"
      ></app-ftd-chart-info-tile>
    </div>
  </div>

  <form [formGroup]="barChartLegendForm">
    <app-crosschecks-chart-filter-group formControlName="stockCoverageBarChartFields">
      <app-chart-filter-item
        *ngFor="let legendConfig of barChartLegendsConfigs"
        [value]="legendConfig.fieldName"
        [label]="legendConfig.label"
        [legendColor]="legendConfig.legendColor"
        [legendImageHeight]="legendConfig.legendImageHeight"
        [legendImageWidth]="legendConfig.legendImageWidth"
        [legendImagePath]="legendConfig.imagePath"
        [legendType]="legendConfig.type"
      >
      </app-chart-filter-item>
    </app-crosschecks-chart-filter-group>
  </form>

  <div class="subheader-container">
    <span class="subheader-text"> Stock coverage </span>
    <mat-icon alt="Information" [matTooltip]="stockCoverageTooltipText">info</mat-icon>
  </div>

  <div class="container">
    <div class="chart-container">
      <app-ftd-line-chart
        *ngIf="initLineChart && initBarChart"
        [data]="lineChartData"
        [isSysDateIndicatorVisible]="true"
        [rootId]="'ftd-line-chart-ref-stock-coverage'"
        [isLabelValuesVisible]="isChartValuesVisible"
        [axisType]="chartAxisType"
        [chartLegend]="lineChartLegend"
        [primaryUnitLabel]="'Stock coverage in months'"
        [chartCustomTheme]="'StockCoverageLineCustomTheme'"
        [isScrollBarVisible]="true"
      ></app-ftd-line-chart>
    </div>
    <div class="tile-container" fxLayout="column">
      <app-ftd-chart-info-tile
        *ngIf="totalAccessibleStock"
        [displayName]="totalAccessibleStock.stockCoverageDisplayName"
        [displayValue]="totalAccessibleStock.currentStockCoverage"
        [displayNameDetails]="' vs Total ISP'"
        [displayNameDetailsValue]="totalAccessibleStock.deltaCurrentStockCoverage"
        [displayNameDetailsColor]="setColorString(parseFloat(totalAccessibleStock.deltaCurrentStockCoverage))"
      ></app-ftd-chart-info-tile>
    </div>
  </div>

  <form [formGroup]="lineChartLegendForm">
    <app-crosschecks-chart-filter-group formControlName="stockCoverageLineChartFields">
      <app-chart-filter-item
        *ngFor="let legendConfig of lineChartLegendsConfigs"
        [value]="legendConfig.fieldName"
        [label]="legendConfig.label"
        [legendColor]="legendConfig.legendColor"
        [legendType]="legendConfig.type"
        [legendImagePath]="legendConfig.imagePath"
        [legendImageHeight]="legendConfig.legendImageHeight"
        [legendImageWidth]="legendConfig.legendImageWidth"
      >
      </app-chart-filter-item>
    </app-crosschecks-chart-filter-group>
  </form>
</app-crosschecks-chart-wrapper>
