import { ISkeletonTheme } from '../models/skeloton-theme.model';

export const DROPDOWN_SKELETON_THEME: ISkeletonTheme = {
  'background-color': '#414b78',
  height: '40px',
};

export const BUTTON_SKELETON_THEME: ISkeletonTheme = {
  'background-color': '#414b78',
  height: '48px',
};
