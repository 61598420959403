<ng-container>
  <div #trigger="matMenuTrigger" [matMenuTriggerFor]="menu">
    <ng-content select="[tooltipTrigger]"></ng-content>
  </div>
</ng-container>

<mat-menu
  #menu="matMenu"
  class="ftd-mat-menu"
  [yPosition]="yPosition"
  [xPosition]="xPosition"
  [hasBackdrop]="hasBackdrop"
  [overlapTrigger]="overlapTrigger"
  (closed)="emitClosed($event)"
>
  <div #contentRef *ngIf="hasTooltip"><ng-content select="[tooltipContent]"></ng-content></div>
</mat-menu>
