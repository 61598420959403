import { ActivatedRoute, Params } from '@angular/router';
import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { AuthService } from '../../../../../auth/services/auth.service';
import { ContextService, DiscountContext } from '../../services/context/context.service';
import { CrosschecksService } from '../../../../../crosschecks/services/crosschecks/crosschecks.service';
import { DiscountMatrixViewDataService } from '../../services/matrix-view-data/matrix-view-data.service';
import {
  DiscountMatrixViewDataSource,
  IMatrixViewCustomConfig,
  IMatrixViewDataSourceItem,
} from '../../models/matrix-view.model';
import { DiscountMatrixViewFormService } from '../../services/matrix-view-form/matrix-view-form.service';
import { FormArray, FormGroup } from '@angular/forms';
import { GranularityService } from '../../services/granularity/granularity.service';
import { GranularityType } from '../../enums/granularity-type.enum';
import {
  ICrossCheckData,
  IGranularity,
  ISelectedFiltersFormConfirmed,
  MatrixViewDataToRefresh,
} from '../../models/app.model';
import { IDiscount, IScenarioDataWithPricePoint } from '../../../../../graphql/services/gql-api.service';
import { IFilterIdsWithPriceFilled } from '../../models/api.model';
import { MatrixColumnTechnicalName } from '../../enums/matrix-column-technical-name.enum';
import { MatrixLandingViewComponentDataContext } from '../matrix-landing-view/matrix-landing-view.component';
import { Observable, Subscription, forkJoin, of, throwError } from 'rxjs';
import { ScenarioService } from '../../services/scenario/scenario.service';
import { User } from '../../../../../auth/models/user.model';
import { catchError, map } from 'rxjs/operators';
import discountConfig from '../../../../discount-matrix-config.json';
import isEqual from 'lodash.isequal';
import sortBy from 'lodash.sortby';
@Component({
  selector: 'app-discount-matrix-view-data',
  styleUrls: ['./matrix-view-data.component.scss'],
  templateUrl: './matrix-view-data.component.html',
})
export class DiscountMatrixViewDataComponent implements OnInit, OnDestroy, AfterContentChecked, OnChanges {
  @Input() selectedFiltersValue!: ISelectedFiltersFormConfirmed;
  @Input() granularityDataLoaded: boolean = false;
  @Input() granularityHasData: boolean = false;
  @Input() showFiltersToggle: boolean = true;
  @Input() mode!: DiscountContext;
  @Input({ required: true }) discount!: IDiscount;
  @Output() toggleFiltersClickEvent: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();
  dataContextMatrixViewData: MatrixLandingViewComponentDataContext =
    MatrixLandingViewComponentDataContext.MATRIX_VIEW_DATA;

  private currentUser?: User | null;

  // Number on the current page
  public currentPage: number = 1;

  // Number of items per page
  public pageSize: number = 20;

  public lastPage: number = 20;

  // State management for scenario data fetch
  public isLoadingScenarioData: boolean = false;
  public dataSource: DiscountMatrixViewDataSource = new DiscountMatrixViewDataSource([], this.pageSize);
  private selectedGranularitiesFromSideFilters: IGranularity[] = [];
  private filterIdsWithPriceFilled: IFilterIdsWithPriceFilled[] = [];
  private subscriptions: Subscription[] = [];
  private context!: DiscountContext;

  /**
   * @constructor
   * @param granularityService
   * @param scenarioService
   * @param matrixViewDataService
   * @param crosscheckService
   * @param route
   * @param matrixViewFormService
   * @param authService
   * @param contextService
   * @param changeDetector
   */
  constructor(
    private granularityService: GranularityService,
    private scenarioService: ScenarioService,
    private matrixViewDataService: DiscountMatrixViewDataService,
    private crosscheckService: CrosschecksService,
    private route: ActivatedRoute,
    private matrixViewFormService: DiscountMatrixViewFormService,
    private authService: AuthService,
    private contextService: ContextService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  ngOnInit(): void {
    this.context = this.contextService.getCurrentDiscountContext();
    this.dataSource.setItemsFormArray(this.matrixViewForm.get('rows') as FormArray);
    this.listenForExpandOrCollapseGranularity();
    this.matrixViewDataService.setCurrentDataSource(this.dataSource);
    this.matrixViewDataService.setCurrentSelectedFilters(this.selectedFiltersValue as ISelectedFiltersFormConfirmed);
    this.matrixViewFormService?.resetForm();
    this.contextService.setCurrentDiscountContext(this.mode);
    this.listenForScenarioIdChanges();
    this.listenForMatrixViewDataToRefresh();
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * ScrollListener
   * Fetch more data if user scrolls
   */
  async scrollListener(): Promise<void> {
    const element: HTMLElement | null = document.getElementById('matrix-view-table-responsive');
    let lastScrollTop: number = 0;

    if (element) {
      element.onscroll = async (): Promise<void> => {
        if (element.scrollTop < lastScrollTop) {
          return;
        }
        lastScrollTop = element!.scrollTop <= 0 ? 0 : element!.scrollTop;

        if (await this.hasReachedTableScrollableLimit()) {
          await this.fetchAndPopulateMatrixViewData().toPromise();
        }
      };
    }
  }

  /**
   * Checks whether the scrollbar is visible.
   * @returns true or false based on scrollbar visibility
   */
  private async isScrollVisible(): Promise<Boolean> {
    return new Promise((resolve) => {
      const element: HTMLElement | null = document.getElementById('matrix-view-table-responsive');
      if (element) {
        // Change after change detection is complete.
        resolve(element!.scrollHeight > element!.clientHeight);
      } else {
        resolve(true);
      }
    });
  }

  /**
   * Checks if the scrollbar has reached 80% of the scroll height or is hidden.
   * @returns true or false based on scrollbar position.
   */
  private async hasReachedTableScrollableLimit(): Promise<boolean> {
    return new Promise((resolve): void => {
      const element: HTMLElement | null = document.getElementById('matrix-view-table-responsive');
      if (element) {
        // Change after change detection is complete.
        (async (): Promise<void> => {
          resolve(
            this.hasMoreDataToLoad !== 0 &&
              (!(await this.isScrollVisible()) ||
                element.scrollTop + element.offsetHeight >= element!.scrollHeight * 0.8)
          );
        })();
      } else {
        resolve(false);
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.granularityDataLoaded && changes.granularityHasData) {
      /**
       * Watch {@link MatrixViewDataComponent.granularityDataLoaded} changes & fetch next matrix-view-data based on granularityDataLoaded
       * Watch {@link MatrixViewDataComponent.granularityHasData} changes & fetch next matrix-view-data based on granularityHasData
       */
      this.granularityDataLoaded = changes.granularityDataLoaded.currentValue as boolean;
      this.granularityHasData = changes.granularityHasData.currentValue as boolean;
    }

    if (changes.selectedFiltersValue) {
      /**
       * Watch {@link MatrixViewDataComponent.selectedFiltersValue} changes & fetch next matrix-view-data based on selected granularities
       */
      const selectedFiltersCurrentValue: ISelectedFiltersFormConfirmed = changes.selectedFiltersValue
        .currentValue as ISelectedFiltersFormConfirmed;
      const selectedFiltersPreviousValue: ISelectedFiltersFormConfirmed = changes.selectedFiltersValue
        .previousValue as ISelectedFiltersFormConfirmed;

      // Reset matrix-view to default view with only brand level data
      if (selectedFiltersCurrentValue?.shouldResetMatrixViewToDefaultState) {
        this.selectedGranularitiesFromSideFilters = [];
        this.filterIdsWithPriceFilled = [];
        this.granularityService.removeAllChildrenOnGranularityList();
        this.dataSource = new DiscountMatrixViewDataSource([], this.pageSize);
        this.dataSource.setItemsFormArray(this.matrixViewForm.get('rows') as FormArray);
        this.clearAndRePopulateMatrixViewData();
      } else {
        this.filterIdsWithPriceFilled = selectedFiltersCurrentValue?.filterIdsWithPriceFilled || [];
        this.selectedGranularitiesFromSideFilters = selectedFiltersCurrentValue?.selectedFilterList || [];

        if (selectedFiltersCurrentValue?.priceEditorFilled) {
          this.mergeSelectedGranularitiesFromFiltersWithThoseWithPricePointSet();
        }

        // Apply selected filters when changed
        if (
          !isEqual(selectedFiltersCurrentValue, selectedFiltersPreviousValue) &&
          selectedFiltersCurrentValue?.selectedFilterList?.length > 0
        ) {
          if (this.selectedFiltersValue?.market) {
            const matrixColumnsSubscription = this.matrixViewDataService
              .getMatrixViewColumnsOrdered(this.selectedFiltersValue.market, this.contextService.discountId)
              .subscribe((matrixColumns: IMatrixViewCustomConfig) => {
                // This.matrixViewDataService.updateMatrixViewColumns(matrixColumns.matrixColumnsCustomOrder);
                this.matrixViewDataService.updateMatrixViewColumns(structuredClone(discountConfig) as any);
              });
            this.subscriptions.push(matrixColumnsSubscription);
          }
          this.orderSelectedGranularitiesFromSideFilters();
        }
      }

      this.matrixViewDataService.setCurrentDataSource(this.dataSource);
      this.matrixViewDataService.setCurrentSelectedFilters(this.selectedFiltersValue as ISelectedFiltersFormConfirmed);
    }
  }

  private listenForScenarioIdChanges(): void {
    const scenarioSubscription = this.route.params.subscribe((params: Params | { id: string }): void => {
      this.contextService.discountId = params.id;
      if (this.selectedFiltersValue?.market) {
        const matrixColumnsSubscription = this.matrixViewDataService
          .getMatrixViewColumnsOrdered(this.selectedFiltersValue.market, this.contextService.discountId)
          .subscribe((matrixColumns: IMatrixViewCustomConfig) => {
            // This.matrixViewDataService.updateMatrixViewColumns(matrixColumns.matrixColumnsCustomOrder);
            this.matrixViewDataService.updateMatrixViewColumns(discountConfig as any);
          });
        this.subscriptions.push(matrixColumnsSubscription);
      }
      this.clearAndRePopulateMatrixViewData();
    });
    this.subscriptions.push(scenarioSubscription);
  }

  private listenForMatrixViewDataToRefresh(): void {
    const subscription: Subscription = this.matrixViewDataService.refreshMatrixViewDataSubject$.subscribe({
      next: (matrixViewDataToRefresh: MatrixViewDataToRefresh): void => {
        let dataSourceItemsToRefresh: IMatrixViewDataSourceItem[];
        if (matrixViewDataToRefresh.granularitiesToRefreshData?.length > 0) {
          dataSourceItemsToRefresh = this.dataSource.getData().filter((dataSourceItem) => {
            return matrixViewDataToRefresh.granularitiesToRefreshData
              .map((item) => item.id)
              .includes(dataSourceItem.granularity?.id);
          });
        } else {
          // If now specific filters provided, then refresh only currently displayed rows based on current-page and size
          dataSourceItemsToRefresh = this.dataSource.getData().slice(0, this.currentPage * this.pageSize);
        }

        const granularitiesToRefreshDataIds: string[] = dataSourceItemsToRefresh.map(
          (dataSourceItem: IMatrixViewDataSourceItem) => dataSourceItem?.granularity?.id
        );

        const batchSize: number = 50;
        for (let i = 0; i < granularitiesToRefreshDataIds.length; i += batchSize) {
          const batch = granularitiesToRefreshDataIds.slice(i, i + batchSize);
          this.refreshScenarioDataOfMatrixViewRowsByFilterIds(batch);
        }
      },
    });
    this.subscriptions.push(subscription);
  }

  private listenForExpandOrCollapseGranularity(): void {
    const subscription = this.matrixViewDataService.listenForGranularityToExpandOrCollapse().subscribe({
      next: async (granularityToExpandOrCollapse) => {
        const indexOfExistingDataSourceItem = this.dataSource
          .getData()
          .findIndex((dataItem) => dataItem.id === granularityToExpandOrCollapse?.id);

        let items: IMatrixViewDataSourceItem[] = [];
        if (indexOfExistingDataSourceItem !== -1) {
          const existingDataSourceItem = this.dataSource.getData()[indexOfExistingDataSourceItem];

          if (existingDataSourceItem.isExpanded) {
            items = this.expandCollapseChildren(existingDataSourceItem, false);
            this.dataSource.setData(this.currentPage, [...items], true);
          } else {
            // Expand row
            items = this.expandCollapseChildren(existingDataSourceItem, true);
            this.currentPage = Math.ceil((indexOfExistingDataSourceItem + 1) / this.pageSize);
            this.dataSource.setData(this.currentPage, [...items], true);
          }

          await this.loadOnExpandCollapse(indexOfExistingDataSourceItem, !existingDataSourceItem.isExpanded);
        }
      },
    });
    this.subscriptions.push(subscription);
  }

  /**
   * Load next / remaining data items if table rows are collapsed.
   * @param indexOfItem Index of the item to be collapsed or expanded.
   * @param visible Show hide or show rows.
   */
  private async loadOnExpandCollapse(indexOfItem: number, visible: boolean = false) {
    const collapsedRowCount = this.dataSource
      .getData()
      .slice(indexOfItem + 1, this.dataSource.getData().length)
      .filter((item) => item.isVisible === false).length;

    if (visible) {
      this.currentPage = Math.ceil((indexOfItem + this.pageSize) / this.pageSize);
      this.dataSource.setData(this.currentPage, this.dataSource.getData());
    } else {
      const pageBasedOnExpandCollapse = Math.ceil((indexOfItem + collapsedRowCount) / this.pageSize);

      // Load all pages as the next rows can be distributed across multiple pages.
      const targetPageBasedOnExpandCollapse = Math.ceil(
        (indexOfItem + collapsedRowCount + this.pageSize) / this.pageSize
      );

      this.currentPage = pageBasedOnExpandCollapse;
      await this.fetchAndPopulateMatrixViewData().toPromise();
      const hasMoreData = await this.shouldLoadMoreData(targetPageBasedOnExpandCollapse);

      // Load next rows if rows are spread across multiple pages and if the scrollbar is not visible.
      while (await this.shouldLoadMoreData(targetPageBasedOnExpandCollapse)) {
        // Set loading to true if more data is being loaded for that row/granularity
        this.setLoadingStatusForRowInMatrixView(true, indexOfItem + 1 + collapsedRowCount);
        await this.fetchAndPopulateMatrixViewData().toPromise();
      }

      if (hasMoreData) {
        this.setLoadingStatusForRowInMatrixView(false, indexOfItem + 1 + collapsedRowCount);
      }
    }
  }

  setLoadingStatusForRowInMatrixView(loading: boolean, itemIndex: number) {
    const tableItems = [...this.dataSource.getData()];
    tableItems[itemIndex].isLoading = loading;
    this.dataSource.setData(this.currentPage, [...tableItems]);
  }

  private async shouldLoadMoreData(targetPage: number) {
    const isScrollVisible = await this.isScrollVisible();
    return this.currentPage <= targetPage && this.currentPage < this.lastPage && !isScrollVisible;
  }

  /**
   * Clear the current displayed KPI-data (Scenario, Price-editor, Crosscheck, ...) in the {@link MatrixViewDataComponent.dataSource},
   * then re-fetch & re-populate them.
   * Helps to prevent browser-refresh when trying to reload only the displayed data
   * @private
   */
  private async clearAndRePopulateMatrixViewData(): Promise<void> {
    this.matrixViewFormService.resetForm(true);
    // Only fetches new scenario data if context === userScenario
    if (this.context === DiscountContext.create) {
      this.scenarioService.getScenarioData(this.currentUser!.sub, this.contextService.discountId).subscribe();
    }
    this.currentPage = 1;
    const dataSourceItemsWithEmptyKpiData: IMatrixViewDataSourceItem[] = this.dataSource.getData().map((item) => {
      return {
        ...this.matrixViewDataService.buildSingleDataSourceItemWithLoaderIndicator(item.granularity, true),
        children: item.children,
        isExpanded: item.isExpanded,
        isVisible: item.isVisible,
        scenarioOutput: undefined,
      };
    });
    this.dataSource.setData(this.currentPage, [...dataSourceItemsWithEmptyKpiData], true);
    await this.fetchAndPopulateMatrixViewData().toPromise();
  }

  /**
   * Recursively build parent child data structure.
   */
  expandResultRowToLowestGranularity() {
    const collapsedRows = this.dataSource
      .getData()
      .filter((row) => row.isExpanded === false && row.granularity.childrenType !== GranularityType.UNKNOWN);
    collapsedRows.forEach((row) => {
      const indexOfExistingDataSourceItem = this.dataSource
        .getData()
        .findIndex((dataItem) => dataItem.id === row.granularity?.id);
      this.expandChildrenOfGivenGranularity(row.granularity, row, indexOfExistingDataSourceItem, false);
    });
    if (
      this.dataSource
        .getData()
        .filter((row) => row.isExpanded === false && row.granularity.childrenType !== GranularityType.UNKNOWN).length
    ) {
      this.expandResultRowToLowestGranularity();
    }
  }

  private expandChildrenOfGivenGranularity(
    granularityToExpand: IGranularity,
    existingDataSourceItemToExpand: IMatrixViewDataSourceItem,
    indexOfExistingDataSourceItemToExpand: number,
    loadScenarioData: boolean = true
  ): void {
    const childrenToExpand: IGranularity[] = this.getChildrenOfGranularityToExpand(granularityToExpand);

    const nextDataSourceItems: IMatrixViewDataSourceItem[] = [...this.dataSource.getData()];

    // Append new data source items from given index
    nextDataSourceItems.splice(
      indexOfExistingDataSourceItemToExpand + 1,
      0,
      ...childrenToExpand.map((child) =>
        this.matrixViewDataService.buildSingleDataSourceItemWithLoaderIndicator(child, true)
      )
    );

    nextDataSourceItems[indexOfExistingDataSourceItemToExpand].children = childrenToExpand.map((child) =>
      this.matrixViewDataService.buildSingleDataSourceItemWithLoaderIndicator(child, true)
    );

    nextDataSourceItems[indexOfExistingDataSourceItemToExpand] =
      this.matrixViewDataService.setSingleDataSourceItemAsExpandedOrCollapsed(existingDataSourceItemToExpand, true);
    this.dataSource.setData(this.currentPage, [...nextDataSourceItems]);
  }

  private expandCollapseChildren(
    granularityToCollapse: IMatrixViewDataSourceItem,
    hide: boolean
  ): IMatrixViewDataSourceItem[] {
    const dataSourceItems = [...this.dataSource.getData()];
    const indexOfExistingDataSourceItem = this.dataSource
      .getData()
      .findIndex((dataItem) => dataItem.id === granularityToCollapse?.id);

    dataSourceItems[indexOfExistingDataSourceItem] =
      this.matrixViewDataService.setSingleDataSourceItemAsExpandedOrCollapsed(granularityToCollapse, hide);

    granularityToCollapse.children?.forEach((el) => {
      const indexOfExistingDataSourceItem = this.dataSource.getData().findIndex((dataItem) => dataItem.id === el?.id);

      const item = dataSourceItems[indexOfExistingDataSourceItem];
      item.isVisible = hide;

      // Only update children if row is expanded
      if (item.children?.length && item.isExpanded) {
        return this.expandCollapseChildren(item, hide);
      } else {
        return dataSourceItems;
      }
    });
    return dataSourceItems;
  }

  /**
   * Orders the selected {@link IGranularity} from the side-filters & arrange them into a visual relationship parent-child (expand/collapse)
   */
  private async orderSelectedGranularitiesFromSideFilters(): Promise<void> {
    const copyOfDataSource: IMatrixViewDataSourceItem[] = this.dataSource.getData();

    this.dataSource.setData(
      this.currentPage,
      sortBy(
        this.selectedGranularitiesFromSideFilters
          .filter((granularity: IGranularity) => granularity.type === GranularityType.BRAND)
          .map((granularity) =>
            this.matrixViewDataService.buildSingleDataSourceItemWithLoaderIndicator(granularity, true)
          ),
        ['granularity.posInPath', 'asc']
      )
    );

    this.selectedGranularitiesFromSideFilters.forEach((granularity: IGranularity) => {
      let indexOfExistingDataSourceItem = this.dataSource
        .getData()
        .findIndex((dataItem) => dataItem.id === granularity.id);
      indexOfExistingDataSourceItem = indexOfExistingDataSourceItem !== -1 ? indexOfExistingDataSourceItem : 0;

      const childrenToExpand = this.granularityService.getGranularityChildrenInGivenList(
        granularity,
        this.selectedGranularitiesFromSideFilters,
        this.selectedFiltersValue.originalSelectedValues!.powertrain
      );
      const nextDataSourceItemsWithScenarioData: IMatrixViewDataSourceItem[] = [...this.dataSource.getData()];
      nextDataSourceItemsWithScenarioData.splice(
        indexOfExistingDataSourceItem + 1,
        0,
        ...childrenToExpand.map((child) =>
          this.matrixViewDataService.buildSingleDataSourceItemWithLoaderIndicator(child, false)
        )
      );

      let existingDataSourceItem = copyOfDataSource.find((dataItem) => dataItem.id === granularity.id);
      existingDataSourceItem =
        existingDataSourceItem ||
        this.matrixViewDataService.buildSingleDataSourceItemWithLoaderIndicator(granularity, true);

      nextDataSourceItemsWithScenarioData[indexOfExistingDataSourceItem] =
        this.matrixViewDataService.setSingleDataSourceItemAsExpandedOrCollapsed(
          existingDataSourceItem,
          childrenToExpand.length > 0
        );
      nextDataSourceItemsWithScenarioData[indexOfExistingDataSourceItem].children = childrenToExpand.map((child) =>
        this.matrixViewDataService.buildSingleDataSourceItemWithLoaderIndicator(child, true)
      );
      this.dataSource.setData(this.currentPage, [...nextDataSourceItemsWithScenarioData]);
    });

    this.restartPagination();
    this.expandResultRowToLowestGranularity();

    this.lastPage = Math.ceil(this.dataSource.getData().length / this.pageSize);

    await this.fetchAndPopulateMatrixViewData().toPromise();
  }

  get hasMoreDataToLoad() {
    return this.dataSource.getData().filter((item: IMatrixViewDataSourceItem) => !item.scenarioOutput).length;
  }

  /**
   * Fetch all necessary data (scenario, crosschecks, pricePoint, comments, approvalStatus, ...) and
   * populate ONLY granularities-rows without those data (specified in parentheses) right now.
   *
   * An optional list of specifics KPI {@link MatrixColumnTechnicalName} can be passed :
   *  - if the {@param kpiListToUpdateValues} is not provided, then data of all available KPIs on the server will be fetched
   *  - else, the API will only retrieve data for given {@param kpiListToUpdateValues}
   * @param kpiListToUpdateValues
   * @param loadScenarioData
   * @param fetchFirstPage
   * @param reload
   * @private
   */
  fetchAndPopulateMatrixViewData(
    kpiListToUpdateValues?: MatrixColumnTechnicalName[],
    loadScenarioData: boolean = true,
    fetchFirstPage: boolean = false,
    reload: boolean = false
  ): Observable<any> {
    if (this.isLoadingScenarioData || (fetchFirstPage && this.currentPage > 1)) {
      return of();
    }
    // 1) Show immediately the granularities & indicates that scenario & crosschecks-data are loading
    const nextDataSourceItems: IMatrixViewDataSourceItem[] = this.displayedGranularityList.map(
      (granularity: IGranularity): IMatrixViewDataSourceItem => {
        const alreadyDisplayedDataSourceItem = this.dataSource
          .getData()
          .find((item: IMatrixViewDataSourceItem): boolean => item.id === granularity.id);
        return alreadyDisplayedDataSourceItem
          ? alreadyDisplayedDataSourceItem
          : this.matrixViewDataService.buildSingleDataSourceItemWithLoaderIndicator(granularity, true);
      }
    );

    this.dataSource.setData(this.currentPage, [...nextDataSourceItems]);

    // 2) Filter displayed granularity-rows without scenario data
    if ((this.getDataSourceNextPage().length && loadScenarioData) || (reload && loadScenarioData)) {
      /**
       * 3) Fetch asynchronously needed scenario-data
       * 4) Check if scenario-id should be set within the request-payload depending on the {@link ScenarioContext}
       */

      const granularityIds: string[] = reload
        ? this.dataSource
            .getData()
            .map((matrixViewDataSourceItem: IMatrixViewDataSourceItem) => matrixViewDataSourceItem.granularity.id)
        : this.getDataSourceNextPage().map(
            (matrixViewDataSourceItem: IMatrixViewDataSourceItem) => matrixViewDataSourceItem.granularity.id
          );

      const sources: (Observable<IScenarioDataWithPricePoint[]> | Observable<ICrossCheckData>)[] = [
        this.scenarioService.getMatrixViewKpiData(
          this.contextService.discountId,
          granularityIds,
          this.selectedFiltersValue?.market!
        ),
        this.crosscheckService.processCrossChecks(),
      ];

      this.isLoadingScenarioData = true;

      return forkJoin(sources).pipe(
        map(async ([scenarioMatrixValueDataList, crossChecksData = []]) => {
          // 4) Now, set fetched scenario-data to datasource-items & stop each loader indicator
          const nextDataSourceItemsWithScenarioData: IMatrixViewDataSourceItem[] = this.dataSource
            .getData()
            .map((dataSourceItem: IMatrixViewDataSourceItem) => {
              return this.matrixViewDataService.mapAllScenarioSources(
                dataSourceItem as IMatrixViewDataSourceItem,
                scenarioMatrixValueDataList as IScenarioDataWithPricePoint[],
                crossChecksData as ICrossCheckData
              );
            });
          this.dataSource.setData(this.currentPage, [...nextDataSourceItemsWithScenarioData], true);
          this.isLoadingScenarioData = false;
          this.currentPage += 1;
          this.scrollListener();

          /*
           * Check if the scrollbar has reached 80% of the scroll height or is hidden.
           * If the user scrolls fast and reaches 80% of the height
           * or if the scroll is hidden because of large screen
           * then the next page is loaded recursively.
           */
          if (await this.hasReachedTableScrollableLimit()) {
            await this.fetchAndPopulateMatrixViewData().toPromise();
          }

          return of();
        }),
        catchError((res) => {
          this.isLoadingScenarioData = false;
          this.currentPage += 1;
          this.scrollListener();
          return throwError(res);
        })
      );
    } else {
      return of();
    }
  }

  private refreshScenarioDataOfMatrixViewRowsByFilterIds(filterIdsToBeRefreshed: string[]): void {
    // 1) Show loader
    const nextDataSourceItems: IMatrixViewDataSourceItem[] = this.dataSource
      .getData()
      .map((item: IMatrixViewDataSourceItem): IMatrixViewDataSourceItem => {
        const isExpanded: boolean = !!item.isExpanded;
        if (filterIdsToBeRefreshed.includes(item.id)) {
          return {
            ...item,
            isExpanded,
            isLoading: true,
          };
        }
        return { ...item, isExpanded };
      });
    this.dataSource.setData(this.currentPage, [...nextDataSourceItems]);

    // 2) Refresh rows
    this.scenarioService
      .getMatrixViewKpiData(this.contextService.discountId, filterIdsToBeRefreshed, this.selectedFiltersValue.market!)
      .subscribe({
        error: (error) => {
          // TODO: Handle this error better
        },
        next: (scenarioMatrixValueDataList) => {
          const nextDataSourceItemsWithScenarioData: IMatrixViewDataSourceItem[] = this.dataSource
            .getData()
            .map((item: IMatrixViewDataSourceItem) => {
              const isExpanded: boolean = !!item.isExpanded;
              if (filterIdsToBeRefreshed.includes(item.id)) {
                return {
                  ...this.matrixViewDataService.mapAllScenarioSources(
                    item,
                    scenarioMatrixValueDataList as unknown as IScenarioDataWithPricePoint[]
                  ),
                  isExpanded,
                } as IMatrixViewDataSourceItem;
              }
              return { ...item, isExpanded };
            });
          this.dataSource.setData(this.currentPage, [...nextDataSourceItemsWithScenarioData], true);
        },
      });
  }

  /**
   * GetDataSourceNextPage
   * @private
   */
  private getDataSourceNextPage(): IMatrixViewDataSourceItem[] {
    if (this.currentPage > this.lastPage) {
      return [];
    }

    return this.dataSource.getData().slice((this.currentPage - 1) * this.pageSize, this.currentPage * this.pageSize);
  }

  private getChildrenOfGranularityToExpand(granularityToExpand: IGranularity): IGranularity[] {
    const childrenToExpand: IGranularity[] = this.granularityService.getGranularityChildrenInGivenList(
      granularityToExpand,
      this.granularitiesSourceToFindChildren,
      this.selectedFiltersValue.originalSelectedValues!.powertrain
    );

    /**
     * When no filtering applied on the children of {@link granularityToExpand}, matrix view expand all children of the parent
     */
    if (
      childrenToExpand.length === 0 &&
      [GranularityType.BRAND, GranularityType.SERIES, GranularityType.E_SERIES, GranularityType.MODEL].includes(
        granularityToExpand.type
      )
    ) {
      // TODO: Not sure if this code snippet can be reached out. Seems to be the same call like on the top, since the powertrain.map(...) don't map anything ?!
      const granularityChildrenInGivenList = this.granularityService.getGranularityChildrenInGivenList(
        granularityToExpand,
        this.granularitiesSourceToFindChildren,
        this.selectedFiltersValue.originalSelectedValues!.powertrain.map((item) => item)
      );

      return granularityChildrenInGivenList;
    }
    /**
     * Else, when filtering applied on the children of {@link granularityToExpand}, matrix view expand only filtered children of the parent
     */
    return childrenToExpand;
  }

  /**
   * Current displayed granularities on matrix-view within {@link MatrixViewDataComponent.dataSource}
   */
  private get displayedGranularityList(): IGranularity[] {
    return this.dataSource.getData().map((dataItem) => dataItem.granularity) || [];
  }

  /**
   * Restart Pagination
   * @private
   */
  private restartPagination(): void {
    this.currentPage = 1;
  }

  private mergeSelectedGranularitiesFromFiltersWithThoseWithPricePointSet(): void {
    const granularitiesWithPriceSetList = this.granularityService
      .getGranularityList()
      .filter((item) => this.filterIdsWithPriceFilled.map((price) => price.filterId).includes(item.id));

    this.selectedGranularitiesFromSideFilters = this.selectedGranularitiesFromSideFilters.filter(
      (selectedGranularity) => {
        switch (selectedGranularity.type) {
          case GranularityType.BRAND:
            return !!granularitiesWithPriceSetList.find(
              (itemWithPricePointSet) => itemWithPricePointSet.brand === selectedGranularity.brand
            );
          case GranularityType.SEGMENT:
            return !!granularitiesWithPriceSetList.find(
              (itemWithPricePointSet) => itemWithPricePointSet.segment === selectedGranularity.segment
            );
          case GranularityType.SERIES:
            return !!granularitiesWithPriceSetList.find(
              (itemWithPricePointSet) =>
                itemWithPricePointSet.series === selectedGranularity.series &&
                itemWithPricePointSet.brand === selectedGranularity.brand
            );
          case GranularityType.E_SERIES:
            return !!granularitiesWithPriceSetList.find(
              (itemWithPricePointSet) =>
                itemWithPricePointSet.eSeries === selectedGranularity.eSeries &&
                itemWithPricePointSet.series === selectedGranularity.series
            );
          case GranularityType.MODEL:
            return !!granularitiesWithPriceSetList.find(
              (itemWithPricePointSet) =>
                itemWithPricePointSet.model === selectedGranularity.model &&
                itemWithPricePointSet.eSeries === selectedGranularity.eSeries
            );
          case GranularityType.MODEL_CODE:
            return !!granularitiesWithPriceSetList.find(
              (itemWithPricePointSet) => itemWithPricePointSet.modelCode === selectedGranularity.modelCode
            );
          default:
            return false;
        }
      }
    );

    const selectedModelCodeWithPrice: IGranularity[] = [];

    this.selectedGranularitiesFromSideFilters.forEach((item) => {
      if (item.type === GranularityType.MODEL) {
        granularitiesWithPriceSetList
          .filter(
            (itemWithPrice) =>
              itemWithPrice.model === item.model &&
              itemWithPrice.brand === item.brand &&
              itemWithPrice.series === item.series &&
              itemWithPrice.type === GranularityType.MODEL_CODE
          )
          .map((granularityWithPricePoint) => selectedModelCodeWithPrice.push(granularityWithPricePoint));
      }
    });

    this.selectedGranularitiesFromSideFilters = [
      ...this.selectedGranularitiesFromSideFilters,
      ...selectedModelCodeWithPrice,
    ];
  }

  /**
   * Returns the source (list) of {@link IGranularity} with which the expand/collapse feature are working.
   */
  private get granularitiesSourceToFindChildren(): IGranularity[] {
    if (this.filterIdsWithPriceFilled?.length > 0) {
      return this.selectedGranularitiesFromSideFilters;
    }
    return this.granularityService.getGranularityList();
  }

  get matrixViewForm(): FormGroup {
    return this.matrixViewFormService.getForm();
  }

  get isFiltersApplied(): boolean {
    return (
      (this.selectedFiltersValue as ISelectedFiltersFormConfirmed)?.selectedFilterList &&
      Boolean((this.selectedFiltersValue as ISelectedFiltersFormConfirmed).selectedFilterList.length)
    );
  }

  get getDataContext(): MatrixLandingViewComponentDataContext {
    return this.selectedFiltersValue
      ? this.selectedFiltersValue.dataContext
      : MatrixLandingViewComponentDataContext.APPLY_FILTERS;
  }
}
