import { CommonModule } from '@angular/common';
import { CurrencyMaskDirective } from './currency-mask.directive';
import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';

@NgModule({
  declarations: [CurrencyMaskDirective],
  exports: [CurrencyMaskDirective],
  imports: [CommonModule, FormsModule],
})
export class FtdCurrencyMaskModule {}
