<span *ngIf="textHolder && technicalName">
  {{
    textHolder[technicalName]
      ? technicalName !== 'pricePointPercentage'
        ? (textHolder[technicalName] | number: '1.0-0') + '%'
        : (textHolder[technicalName] | number: '1.0-1') + '%'
      : technicalName !== 'pricePointPercentage'
      ? (textHolder[technicalName] | nullLabel)
      : 'Not submitted'
  }}
</span>
