export enum CrosschecksChartContext {
  NO_FILTER_APPLIED = 'NO_FILTER_APPLIED',
  NO_RESULTS_FOUND = 'NO_RESULTS_FOUND',
  ERROR = 'ERROR',
  LOADING = 'LOADING',
  CHART = 'CHART',
}

export enum CrosschecksChartContextImagePaths {
  APPLY_FILTERS_SVG_PATH = 'assets/images/applyFiltersCrosschecks.svg',
  NO_RESULTS_FOUND_SVG_PATH = 'assets/images/granularityEmptyPlaceholder.svg',
  ERROR_SVG_PATH = 'assets/images/errorState.svg',
}

export enum CrosschecksChartContextMessages {
  APPLY_FILTERS_SVG_PATH_MESSAGE = 'Apply filters to see results',
  APPLY_FILTERS_SVG_PATH_SUB_MESSAGE = 'Please try to use another filter',
  NO_RESULTS_FOUND_SVG_PATH_MESSAGE = "We don't have any data",
  NO_RESULTS_FOUND_SVG_PATH_SUB_MESSAGE = 'Please try to use another filter',
  ERROR_SVG_PATH_MESSAGE = 'Something went wrong',
  ERROR_SVG_PATH_SUB_MESSAGE = 'Please try again later',
}
