import { Component, Input } from '@angular/core';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-discount-mv-number-percent',
  styleUrls: ['./mv-number-percent.component.scss'],
  templateUrl: './mv-number-percent.component.html',
})
export class MvNumberPercentComponent {
  @Input({ required: true }) values: NumberWithPercentInput = {};
  @Input() digitsInfo: string = '';
  protected readonly Number = Number;
  protected readonly isNaN = isNaN;

  _currency: string = '';
  get currency(): string {
    return this._currency;
  }

  @Input() set currency(currency: string) {
    this._currency = currency;
    this.currencySymbol = getCurrencySymbol(this.currency, 'narrow');
  }

  currencySymbol: string = '';
}

export type NumberWithPercentInput = {
  number?: number;
  percentage?: number;
};
