import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { DataSource } from '@angular/cdk/collections';
import { ICrossCheckResults, IGranularity, IPriceEditorData, IScenarioMetaDataBuilder } from './app.model';
import { IScenarioDataWithPricePoint } from '../../../../graphql/services/gql-api.service';
import { MatrixColumnLevel } from '../enums/matrix-column-level.enum';
import { MatrixColumnType } from '../enums/matrix-column-type.enum';
import { Observable, ReplaySubject } from 'rxjs';
import { ScenarioMarketParameter } from '../enums/scenario-market-parameter.enum';
import uniqBy from 'lodash.uniqby';

/**
 * Defines the main table's configuration
 */
export interface IMatrixViewConfig {
  columns: IMatrixColumnConfig[];
}

export interface IMatrixViewCustomConfig {
  matrixColumnsCustomOrder: IMatrixColumnConfig[];
}

export interface IMatrixViewColumnMetadata {
  config: {
    unit: string;
    referenceField: string;
  };
  dataIngestionDate?: string;
  description?: string;
  source?: string;
  lastSourceCall?: string;
}

/**
 * Defines each Main-Column or Sub-Column in the KPI-Table's header.
 * Contains needed info to display, collapse / expand or show tooltip on a header-column
 */
export interface IMatrixColumnConfig {
  /**
   * ID of the header's column
   */
  id: string;

  parentId?: any;
  /**
   * Used for data-mapping between backend & frontend
   */
  technicalName?: string | any;
  /**
   * Indicates if the column is on 1st, 2nd & 3rd header's row
   */
  level?: MatrixColumnLevel;
  /**
   * Name displayed in the column
   */
  displayName: string;
  /**
   * Sub name displayed in the column
   */
  secondaryDisplayName?: string | null;
  /**
   * Key referencing to which section the column belongs.
   * Used for grouping them and allow collapse & expand features in the header.
   */
  sectionKey?: string | undefined | null;
  /**
   * Indicates which @Component type should be rendered whithin the column
   */
  type?: MatrixColumnType;
  columnType?: MatrixColumnType | string;

  /**
   * Indicates order of the columns displayed.
   */
  position?: number;
  /**
   * Indicates if the columns on the row-data-level should have the expand/collapse button
   */
  hasExpandButtonForRows?: boolean;
  /**
   * Column should remain sticky on horizontal-scroll
   */
  isSticky?: boolean | undefined | null;
  /**
   * Tooltip text that the column should show on mouse-hover
   */
  infoTooltipText?: string;
  /**
   * Optional unit that the row-data-level value in column should show
   */
  unit?: string;

  /**
   * Optional description tooltip
   */
  description?: string | undefined | null;
  /**
   * Flag to indicate if column should be hidden or showed within column-group when expanding or collapsing main-column
   */
  isHidden?: boolean;
  /**
   * Indicates if the columns on the header-level should have the expand/collapse button to hide/show columns for the same section
   */
  hasExpandButtonForColumns?: boolean;
  /**
   * Indicates if the column cannot be collapsed AT ALL and is always visible
   */
  isNotCollapsible?: boolean | undefined | null;
  /**
   * Indicates if a header column with (+ / -) button is collapsed or expanded
   */
  isExpandButtonForColumnsCollapsed?: boolean;
  /**
   * If set, then main-column has sub-columns
   */
  children?: IMatrixColumnConfig[] | undefined;

  /**
   * If set, then column is visible
   */
  isVisible?: boolean | undefined | null;

  /**
   * If set, then column is expanded
   */
  isExpand?: boolean | undefined;

  /**
   * Metadata containing description, config and dataIngestionDate
   */
  metadata?: IMatrixViewColumnMetadata;
}

export interface IMatrixViewColumnFormat {
  description: string | undefined | null;
  columnName: string | undefined | null;
  config:
    | {
        unit: string | undefined | null;
        referenceField: string | undefined | null;
      }
    | any;
  market: ScenarioMarketParameter;
}

/**
 * Class for managing matrix-view rows-items items using observable-based Datasource
 */
export class DiscountMatrixViewDataSource extends DataSource<IMatrixViewDataSourceItem> {
  private fb: FormBuilder = new FormBuilder();
  private _dataStream: ReplaySubject<IMatrixViewDataSourceItem[]> = new ReplaySubject<IMatrixViewDataSourceItem[]>();
  private initialData: IMatrixViewDataSourceItem[] = [];
  private itemsFormArray: FormArray = this.fb.array([]);
  private readonly pageSize: number = 20;
  private currentPage: number = 1;

  /**
   * @constructor
   * @param initialData
   * @param pageSize
   */
  constructor(initialData: IMatrixViewDataSourceItem[], pageSize: number = 20) {
    super();
    this.initialData = initialData;
    this.pageSize = pageSize;
    this.setData(1, initialData);
  }

  /**
   * Connect
   * @return Observable<MatrixViewDataSourceItem[]>
   */
  connect(): Observable<IMatrixViewDataSourceItem[]> {
    return this._dataStream;
  }

  /**
   * Disconnect
   */
  disconnect(): void {}

  /**
   * SetData
   * @param currentPage
   * @param data
   * @param updateFormArray
   */
  setData(currentPage: number, data: IMatrixViewDataSourceItem[], updateFormArray: boolean = false): void {
    this.currentPage = currentPage;

    if (updateFormArray) {
      this.buildNextItemsForm(data);
    }

    this.initialData = data;
    this._dataStream.next(uniqBy(this.getLazyLoadItems(data, currentPage), 'id'));
  }

  /**
   * GetData
   * @return MatrixViewDataSourceItem[]
   */
  getData(): IMatrixViewDataSourceItem[] {
    return this.initialData;
  }

  getCurrentPage(): number {
    return this.currentPage;
  }

  getLastPage(): number {
    return Math.ceil(this.initialData.length / this.pageSize);
  }

  /**
   * SetItemsFormArray
   * @param formArray
   */
  setItemsFormArray(formArray: FormArray): void {
    this.itemsFormArray = formArray;
  }

  /**
   * SetLazyLoadItem
   * @param data
   * @private
   */
  private getLazyLoadItems(data: IMatrixViewDataSourceItem[], currentPage: number): IMatrixViewDataSourceItem[] {
    const lazyData = data.slice(0, currentPage * this.pageSize);
    return lazyData.length ? lazyData : this.initialData.slice(0, this.pageSize);
  }

  /**
   * BuildNextItemsForm
   * @param nextData
   * @private
   */
  private buildNextItemsForm(nextData: IMatrixViewDataSourceItem[]): void {
    const previousItemsFormArrayValue: {
      id: string;
      checkboxStatus: boolean;
      price: number;
      pricePointPercentage: number;
      comment: string;
    }[] = [...this.itemsFormArray.value];

    nextData.forEach((item: IMatrixViewDataSourceItem, index: number): void => {
      this.fillFormArray(item, index, previousItemsFormArrayValue);
    });
    this.clearFormArrayItems(nextData, previousItemsFormArrayValue);
  }

  /**
   * FillFormArray
   * @param item
   * @param index
   * @param previousItemsFormArrayValue
   * @private
   */
  private fillFormArray(
    item: IMatrixViewDataSourceItem,
    index: number,
    previousItemsFormArrayValue: {
      id: string;
      checkboxStatus: boolean;
      price: number;
      pricePointPercentage: number;
      comment: string;
    }[]
  ): void {
    const previousValue:
      | { id: string; checkboxStatus: boolean; price: number; pricePointPercentage: number }
      | undefined = previousItemsFormArrayValue.find(
      (value: {
        id: string;
        checkboxStatus: boolean;
        price: number;
        pricePointPercentage: number;
        comment: string;
      }): boolean => value?.id === item.id
    );

    const existingIndex: number = this.itemsFormArray.controls.findIndex(
      (control: AbstractControl<any, any>): boolean => item.id === control.value?.id
    );
    if (existingIndex === -1) {
      this.itemsFormArray.setControl(index, this.buildSingleItemForm(item, previousValue));
    } else {
      const dataRowAtIndex = this.itemsFormArray.at(existingIndex);
      dataRowAtIndex.patchValue({
        comment: dataRowAtIndex.value.comment || '',
        listPriceExclTax: dataRowAtIndex.value.listPriceExclTax || item.priceEditorData?.listPriceExclTax,
        netPricePointPercentage: dataRowAtIndex.value.netPrice || item.priceEditorData?.netPricePointPercentage,
        price: dataRowAtIndex.value.price || item.priceEditorData?.pricePointPrice,
        pricePointPercentage: dataRowAtIndex.value.pricePointPercentage || item.priceEditorData?.pricePointPercentage,
        scenarioData: item,
      });
    }
  }

  /**
   * ClearFormArrayItems
   * @param nextData
   * @param previousItemsFormArrayValue
   * @private
   */
  private clearFormArrayItems(nextData: IMatrixViewDataSourceItem[], previousItemsFormArrayValue: any): void {
    const itemsToRemoves = previousItemsFormArrayValue.filter(
      (itemToRmv: any) => !nextData.map((datasourceItem: any) => datasourceItem.id).includes(itemToRmv.id)
    );
    itemsToRemoves.forEach((itemsToRemove: any) => {
      const indexToRemove = this.itemsFormArray.controls.findIndex((control) => control.value?.id === itemsToRemove.id);
      this.itemsFormArray.removeAt(indexToRemove);
    });
  }

  /**
   * BuildSingleItemForm
   * @param item
   * @param previousValue
   * @private
   */
  private buildSingleItemForm(item: IMatrixViewDataSourceItem, previousValue?: any): FormGroup | void {
    if (item.id) {
      return new FormGroup({
        checkboxStatus: new FormControl(previousValue?.checkboxStatus),
        comment: new FormControl(''),
        id: new FormControl(item.id),
        listPriceExclTax: new FormControl(item.priceEditorData?.listPriceExclTax),
        netPricePointPercentage: new FormControl(item.priceEditorData?.netPricePointPercentage),
        price: new FormControl(item.priceEditorData?.pricePointPrice),
        pricePointPercentage: new FormControl(item.priceEditorData?.pricePointPercentage),
        scenarioData: new FormControl(item),
      });
    }
  }
}

/**
 * Interface representing data-object of each row for the KPI-Table
 */
export interface IMatrixViewDataSourceItem {
  /**
   * Id of each row in the Matrix-View
   */
  id: string;
  /**
   * Holds info like display name for single row (Brand, Series, E-Serie, Model, ...), type, children.
   */
  granularity: IGranularity;
  /**
   * Holds needed data for filling current prices & financials columns
   */
  scenarioOutput?: IScenarioDataWithPricePoint;
  /**
   * Holds needed data for showing current crosschecks
   */
  currentCrossCheckResults?: ICrossCheckResults;
  /**
   * Holds needed data for showing current editor data
   */
  priceEditorData?: IPriceEditorData;

  /**
   * Holds scenario metadata for showing tooltips and other descriptions
   */
  scenarioMetaData?: IScenarioMetaDataBuilder;

  /**
   * Should show spinner or not
   */
  isLoading?: boolean;

  /**
   * Flag telling if the row-item is expanded or not (collapsed)
   */
  isExpanded?: boolean;
  isVisible?: boolean;
  children?: IMatrixViewDataSourceItem[];
  unit?: string;
  level?: number;
}

export interface IMatrixViewRowItemFormValue {
  id: string;
  price: number;
  comment: string;
  checkboxStatus: boolean;
}

export enum ColumnsWithPercent {
  EXPECTED_VOLUME_RETAIL = 'expectedVolumeRetailAdj',
  DELTA_CURRENT_PRICE_EDITOR = 'deltaCurrentPriceEditor',
  DELTA_CM_PER_UNIT = 'deltaCmPerUnit',
  DELTA_EXPECTED_VOLUME_RETAIL_CURRENT_ADJ = 'deltaExpectedVolumeRetailCurrentAdj',
  DELTA_LEASE_RATE = 'deltaCurrentIndicativeLeaseRate',
}
