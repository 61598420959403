/**
 * Different column's types that can be showed in the {@link MatrixViewColumnComponent}
 */
export enum MatrixColumnType {
  NAME = 'NAME',
  UNIT_MAP = 'UNIT_MAP',
  PRICE_DISPLAY = 'PRICE_DISPLAY',
  NUMBER_DISPLAY = 'NUMBER_DISPLAY',
  INTEGER_DISPLAY = 'INTEGER_DISPLAY',
  INTEGER_PRICE_DISPLAY = 'INTEGER_PRICE_DISPLAY',
  TEXT_DISPLAY = 'TEXT_DISPLAY',
  DATE_DISPLAY = 'DATE_DISPLAY',
  CROSSCHECK = 'CROSSCHECK',
  CROSSCHECK_SUMMARY = 'CROSSCHECK_SUMMARY',
  PRICE_INPUT_EDITOR = 'PRICE_INPUT_EDITOR',
  PRICE_INPUT_PERCENTAGE_EDITOR = 'PRICE_INPUT_PERCENTAGE_EDITOR',
  ALL_PRICE_INPUT_EDITOR = 'ALL_PRICE_INPUT_EDITOR',
  CONFIDENCE_INDICATOR = 'CONFIDENCE_INDICATOR',
  EFFECTIVE_DATE_INPUT = 'EFFECTIVE_DATE_INPUT',
  PREDICTION_EVALUATION_INDICATOR = 'PREDICTION_EVALUATION_INDICATOR',
  APPROVAL_STATUS = 'APPROVAL_STATUS',
  GO_TO_LEASE_LOAN_SIMULATOR_BUTTON = 'GO_TO_LEASE_LOAN_SIMULATOR_BUTTON',
  ALL_EFFECTIVE_DATE_INPUT_EDITOR = 'ALL_EFFECTIVE_DATE_INPUT_EDITOR',
  ACTIONS = 'ACTIONS',
  EMPTY = 'EMPTY',
  DISABLED = 'DISABLED',
}
