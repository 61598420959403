import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { Axis, AxisRenderer, CategoryAxis, ValueAxis } from '@amcharts/amcharts5/xy';
import { ChartAxisLayout, ChartAxisType, GenericChartValueFields } from './enums/ftd-generic-chart.enum';
import { Color, Series } from '@amcharts/amcharts5';
import { Directive, Inject, Input, NgZone, OnDestroy, PLATFORM_ID } from '@angular/core';
import { FtdAm5ChartsTheme, FtdChartCustomThemeKeys } from './themes/ftd-am5-charts.theme';
import { GenericChartFields, IGenericChartData } from './models/ftd-generic-chart.model';
import { IComponentDataItem } from '@amcharts/amcharts5/.internal/core/render/Component';
import { XYChart } from '@amcharts/amcharts5/.internal/charts/xy/XYChart';
import { environment } from 'src/environments/environment';
import { isPlatformBrowser } from '@angular/common';
import dayjs from 'dayjs';

export { am5 };
export { am5xy };

@Directive()
export abstract class FtdBaseChart implements OnDestroy {
  protected am5 = am5;
  protected am5xy = am5xy;
  protected am5themes_Ftd = FtdAm5ChartsTheme;
  protected isPlatformBrowser = isPlatformBrowser;
  protected root!: am5.Root;
  protected chart!: XYChart;
  protected xAxis!: Axis<AxisRenderer> | any;
  protected xAxisTop: Axis<AxisRenderer> | any;
  protected yAxis!: Axis<AxisRenderer> | any;
  protected yAxisRight: Axis<AxisRenderer> | any;
  protected legend!: am5.Legend;

  protected _isLabelValuesVisible: boolean = true;
  get isLabelValuesVisible(): boolean {
    return this._isLabelValuesVisible;
  }

  protected _data!: IGenericChartData;
  @Input() set data(value: IGenericChartData) {
    if (value) {
      this._data = value;
      this.chart?.series?.each((val) => {
        val.data.clear();
        val.data.setAll(value.fields);
      });
    }
  }

  @Input() chartCustomTheme?: FtdChartCustomThemeKeys;

  @Input() horizontalLayout: boolean = false;

  @Input() categoryField!: string;

  @Input() isScrollBarVisible: boolean = false;

  @Input() set isLabelValuesVisible(value: boolean) {
    this._isLabelValuesVisible = value;
    this.setBulletsLabelVisibility(this._isLabelValuesVisible);
  }

  protected _isTooltipVisible: boolean = true;
  get isTooltipVisible(): boolean {
    return this._isTooltipVisible;
  }

  @Input() set isTooltipVisible(value: boolean) {
    this._isTooltipVisible = value;
    this.setTooltipVisibility(this._isTooltipVisible);
  }

  @Input() axisType: keyof typeof ChartAxisType | ChartAxisType = ChartAxisType.DATE;
  @Input() dateLabelFormat: string = `MMM [']YY`;
  @Input() axisLayout: ChartAxisLayout = ChartAxisLayout.VERTICAL;
  @Input() renderXAxisTop: boolean = false;
  @Input() renderYAxisRight: boolean = false;

  @Input() isSysDateIndicatorVisible: boolean = false;
  @Input() isCursorVisible: boolean = true;
  @Input() primaryUnitLabel: string = '';
  @Input() secondaryUnitLabel: string = '';

  /**
   * @constructor
   * @param platformId
   * @param zone
   */
  constructor(@Inject(PLATFORM_ID) protected platformId: Object, protected zone: NgZone) {
    am5.addLicense(environment.chartsLicence);
  }

  /**
   * @ngOnDestroy
   * Clean up chart when the component is removed
   */
  ngOnDestroy() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }

  /**
   * Run the function only in the browser
   * @protected
   * @param callbackFunction
   */
  protected browserOnly(callbackFunction: () => void) {
    if (this.isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        callbackFunction();
      });
    }
  }

  /**
   * SetBulletsLabelVisibility
   * @param isLabelValuesVisible
   * @protected
   */
  protected setBulletsLabelVisibility(isLabelValuesVisible: boolean) {
    this.chart?.series?.each((series: Series) => {
      series.dataItems.forEach((dataItem) => {
        dataItem.bullets?.forEach((bullet) => {
          if (bullet.get('id')?.includes('label_')) {
            bullet.get('sprite')?.set('visible', isLabelValuesVisible);
          }
        });
      });
    });
  }

  /**
   * SetTooltipVisibility
   * @param isTooltipVisible
   * @protected
   */

  protected setAxisLabelDescription() {
    this.horizontalLayout
      ? this.chart.children.push(
          am5.Label.new(this.root, {
            centerX: am5.percent(45),
            centerY: 58,
            fontSize: 12,
            fontWeight: '400',
            position: 'relative',
            text: this.primaryUnitLabel,
            themeTags: ['ftd-chart-label'],
            x: am5.percent(96),
            y: am5.p100,
          })
        )
      : this.yAxis.children.unshift(
          am5.Label.new(this.root, {
            fontSize: '12px',
            fontWeight: '400',
            position: 'absolute',
            text: this.primaryUnitLabel,
            themeTags: ['ftd-chart-label'],
            x: -5,
            y: -40,
          })
        );

    if (this.renderYAxisRight) {
      this.yAxisRight.children.unshift(
        am5.Label.new(this.root, {
          centerX: am5.p50,
          fontSize: '12px',
          fontWeight: '400',
          position: 'absolute',
          text: this.secondaryUnitLabel,
          themeTags: ['ftd-chart-label'],
          y: -40,
        })
      );
    }
  }

  protected setTooltipVisibility(isTooltipVisible: boolean) {
    this.chart?.series?.each((serie: Series) => {
      const columnSeries = serie as am5xy.ColumnSeries;
      columnSeries.columns?.each((column: am5.RoundedRectangle) => {
        column.getTooltip()?.set('forceHidden', !isTooltipVisible);
      });
      serie.bulletsContainer.children.each((bullet: am5.Sprite) => {
        bullet.getTooltip()?.set('forceHidden', !isTooltipVisible);
      });
      serie.getTooltip()?.set('forceHidden', !isTooltipVisible);
    });
  }

  /**
   * SetTypeOfYAxis
   * @private
   */
  private setTypeOfYAxis(): ValueAxis<AxisRenderer> | CategoryAxis<AxisRenderer> {
    return this.horizontalLayout
      ? this.am5xy.CategoryAxis.new(this.root, {
          categoryField: this.categoryField,
          renderer: this.am5xy.AxisRendererY.new(this.root, {}),
        })
      : this.am5xy.ValueAxis.new(this.root, {
          extraTooltipPrecision: 1,
          renderer: this.am5xy.AxisRendererY.new(this.root, {}),
        });
  }

  /**
   * SetYAxis
   * @protected
   */
  protected setYAxis(): void {
    this.chart.yAxes.clear();
    this.yAxis = this.chart.yAxes.push(this.setTypeOfYAxis());

    if (this.renderYAxisRight) {
      this.yAxisRight = this.chart.yAxes.push(
        this.am5xy.ValueAxis.new(this.root, {
          renderer: this.am5xy.AxisRendererY.new(this.root, {
            opposite: true,
            themeTags: ['ftd-yAxis-right'],
          }),
          themeTags: ['ftd-yAxis-right'],
        })
      );
    }
    if (this.horizontalLayout) {
      const yRenderer: am5xy.AxisRendererY = this.yAxis.get('renderer');
      yRenderer.grid.template.setAll({
        location: 1,
      });
      this.yAxis.data.setAll(this._data.fields);
    }
  }

  /**
   * SetXAxis
   * @protected
   */
  protected setXAxis(): void {
    this.chart.xAxes.clear();
    const renderer = this.am5xy.AxisRendererX.new(this.root, {
      minGridDistance: this.horizontalLayout ? 150 : 20,
    });

    const axisBullet = (root: am5.Root, axis: am5xy.Axis<any>, dataItem: am5.DataItem<IComponentDataItem>) => {
      const data = axis.get('userData').find((item: GenericChartFields): boolean => {
        const dataContext: GenericChartFields = dataItem.dataContext as GenericChartFields;
        return item.xAxisValue === dataContext?.xAxisValue;
      });

      return this.am5xy.AxisBullet.new(this.root, {
        location: 0.5,
        sprite: this.am5.Picture.new(this.root, {
          centerX: this.am5.p50,
          centerY: this.am5.p50,
          height: 22,
          src: data?.xAxisImgSrc as string,
          width: 22,
        }),
      });
    };
    switch (this.axisType) {
      case ChartAxisType.DATE:
        this.xAxis = this.chart.xAxes.push(
          this.am5xy.DateAxis.new(this.root, {
            baseInterval: { count: 1, timeUnit: 'month' },
            bullet: axisBullet,
            renderer: renderer,
          })
        );

        if (this.renderXAxisTop) {
          this.xAxisTop = this.chart.xAxes.push(
            this.am5xy.DateAxis.new(this.root, {
              renderer: this.am5xy.AxisRendererX.new(this.root, {
                opposite: true,
                themeTags: ['ftd-xAxis-top'],
                visible: false,
              }),
              themeTags: ['ftd-xAxis-top'],
            })
          );
        }
        break;

      case ChartAxisType.CATEGORY:
        this.xAxis = this.chart.xAxes.push(
          this.am5xy.CategoryAxis.new(this.root, {
            bullet: axisBullet,
            categoryField: GenericChartValueFields.X,
            renderer: renderer,
          })
        );

        if (this.renderXAxisTop) {
          this.xAxisTop = this.chart.xAxes.push(
            this.am5xy.CategoryAxis.new(this.root, {
              renderer: this.am5xy.AxisRendererX.new(this.root, {
                opposite: true,
                themeTags: ['ftd-xAxis-top'],
              }),
              themeTags: ['ftd-xAxis-top'],
            })
          );
        }
        break;
      case ChartAxisType.VALUE:
        this.xAxis = this.chart.xAxes.push(
          this.am5xy.ValueAxis.new(this.root, {
            calculateTotals: true,
            min: 0,
            renderer: renderer,
          })
        );
        break;
    }

    // Set data to all X axis
    this.chart.xAxes.values.forEach((axis: am5xy.Axis<any>) => {
      axis.data.setAll(
        [...new Set(this._data.fields.map((dataItem: GenericChartFields) => dataItem.xAxisValue))].map((item) => {
          return { xAxisValue: item };
        })
      );
      axis.set('userData', this._data.fields);
    });
  }

  /**
   * SetSysDateIndicator
   * @protected
   */
  protected setSysDateIndicator(): void {
    // Date calculation to center line at middle of the month by subtrating the number of days between the current and next month and dividing by 2
    const day =
      Math.round(
        dayjs(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`)
          .add(1, 'month')
          .diff(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-01`, 'days') / 2
      ) + 1;
    const date = dayjs(`${new Date().getFullYear()}-${new Date().getMonth() + 1}-${day}`)
      .toDate()
      .getTime();

    // Add series range
    const seriesRangeDataItem = this.xAxis.makeDataItem({
      category: this.axisType === ChartAxisType.DATE ? date : dayjs(new Date()).format(this.dateLabelFormat),
      value: this.axisType === ChartAxisType.DATE ? date : dayjs.monthsShort()[new Date().getMonth()],
    });

    this.xAxis.createAxisRange(seriesRangeDataItem);
    seriesRangeDataItem.get('grid')?.setAll({
      layer: 10,
      location: 0.5,
      stroke: Color.fromHex(0x51dddd),
      strokeDasharray: [2, 2],
      strokeOpacity: 1,
      strokeWidth: 1,
      themeTag: ['ftd-label-today'],
      visible: true,
    });

    seriesRangeDataItem.get('label').setAll({
      background: this.am5.Triangle.new(this.root, {
        centerX: 21.5,
        dy: 46,
        fill: Color.fromHex(0x74ebf2),
        fillOpacity: 1,
        maxHeight: 22,
        maxWidth: 21,
        rotation: 180,
      }),
      fill: Color.fromHex(0x74ebf2),
      fontFamily: 'BMWGroupTNCondensedPro-Regular',
      fontSize: '14px',
      fontWeight: '400',
      inside: true,
      text: 'Today',
      textAlign: 'center',
    });

    this.root.events.on('frameended', () => {
      seriesRangeDataItem.get('label').set('dy', -1 * this.chart.plotContainer.height() - 58);
    });
  }
}
