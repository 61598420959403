import { APP_FSM_RELATED_EXTERNAL_LINKS } from '../../../common/constants/app-fsm-related-external-links';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-crosschecks-header',
  styleUrls: ['./crosschecks-header.component.scss'],
  templateUrl: './crosschecks-header.component.html',
})
export class CrosschecksHeaderComponent implements OnInit {
  @Input() public title: string = '';
  @Input() public isToggleFiltersVisible: boolean = true;
  @Output() showFiltersButtonVisible: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentLocation = '';
  isCrosschecksPage = true;

  APP_FSM_RELATED_EXTERNAL_LINKS = APP_FSM_RELATED_EXTERNAL_LINKS;

  constructor(public router: Router) {}

  ngOnInit() {
    const routerUrl = this.router.url;
    const currentString = 'current';

    if (routerUrl.includes('crosschecks/settings')) {
      this.isCrosschecksPage = false;
    }

    if (routerUrl.includes(currentString)) {
      this.currentLocation = 'Current';
    } else {
      this.currentLocation = 'Estimated';
    }
  }

  emitShowFiltersButtonVisible() {
    this.showFiltersButtonVisible.emit(!this.showFiltersButtonVisible);
  }
}
