import { ApiErrorInterceptor } from './core/interceptors/api-error/api-error.interceptor';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './auth/guards/auth.guard';
import { AuthInterceptor } from './auth/interceptors/auth.interceptor';
import { BaseUrlInterceptor } from './core/interceptors/base-url.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from './common/common.module';
import { CrosschecksModule } from './crosschecks/crosschecks.module';
import { DiscountManagementModule } from './discount-management/discount-management.module';
import { FtdTabsModule } from './common/components/ftd-tabs/ftd-tabs.module';
import { GraphQLGlobalErrorInterceptor } from './core/interceptors/graphql-error/graphql-error.interceptor';
import { GraphQLModule } from './graphql.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { HeaderModule } from './header/header.module';
import { HttpLoaderInterceptor } from './core/interceptors/http-loader.interceptor';
import { MaterialModule } from './common/material.module';
import { MatrixViewModule } from './matrix-view/matrix-view.module';
import { NgModule } from '@angular/core';
import { ScenarioModule } from './scenario/scenario.module';
import { UnauthenticatedInterceptor } from './auth/interceptors/unauthenticated.interceptor';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CommonModule,
    HttpClientModule,
    HeaderModule,
    MaterialModule,
    MatrixViewModule,
    ScenarioModule,
    CrosschecksModule,
    DiscountManagementModule,
    FtdTabsModule,
    GraphQLModule,
  ],
  providers: [
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: HttpLoaderInterceptor,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthenticatedInterceptor,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: BaseUrlInterceptor,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
    },
    {
      multi: true,
      provide: HTTP_INTERCEPTORS,
      useClass: GraphQLGlobalErrorInterceptor,
    },
    AuthGuard,
  ],
})
export class AppModule {}
