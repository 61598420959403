<div class="stepper-container">
  <div class="step-container" *ngFor="let step of stepperData; index as i">
    <div class="step-separator" *ngIf="i !== 0"></div>
    <div class="step-wrapper" [ngClass]="maxStep >= i + 1 ? '' : 'disabled'" (click)="goToStep(i + 1)">
      <div class="step-point" [ngClass]="maxStep >= i + 1 ? '' : 'disabled'">
        <span *ngIf="getShowStepLabel(i)">{{ i + 1 }}</span>
        <span *ngIf="getShowStepIcon(i)"><mat-icon>done</mat-icon></span>
      </div>
      <span [ngClass]="currentStep === i + 1 ? 'current-step' : ''">{{ step }}</span>
    </div>
  </div>
</div>
