<div [ngClass]="isFullScreenDisabled ? 'main-container' : 'main-container-fullscreen'">
  <app-matrix-view-subheader
    *ngIf="isFullScreenDisabled"
    class="matrix-view-subheader"
    [title]="title"
    [description]="description"
    [isReturnButtonHidden]="isReturnButtonHidden"
  ></app-matrix-view-subheader>
  <mat-drawer-container>
    <mat-drawer #filtersDrawer [mode]="'side'" [disableClose]="true" [opened]="isFullScreenDisabled">
      <app-matrix-view-side-filters
        *ngIf="!isMatrixViewSideFiltersHidden"
        (confirmButtonClicked)="handleSelectFiltersValue($event)"
      ></app-matrix-view-side-filters>
    </mat-drawer>
    <mat-drawer-content>
      <mat-card id="main-container-content">
        <mat-card-content>
          <div
            [ngClass]="isFullScreenDisabled ? 'matrix-view-data-container' : 'matrix-view-data-container-fullscreen'"
          >
            <div [ngClass]="isFullScreenDisabled ? 'fullscreen-disabled' : 'fullscreen-enabled'">
              <app-matrix-view-table-header
                *ngIf="
                  selectedFiltersValue && !selectedFiltersValue.shouldResetMatrixViewToDefaultState && isFilterSelected
                "
                [isDescriptionHidden]="isDescriptionHidden"
                [isTitleHidden]="isTitleHidden"
                [description]="description"
                [isExportButtonHidden]="isExportButtonHidden"
                [isApproveScenarioButtonHidden]="isApproveScenarioButtonHidden"
                [isUnlockScenarioButtonHidden]="isUnlockScenarioButtonHidden"
                [isSaveButtonHidden]="isSaveButtonHidden"
                [isSaveDisabled]="isSaveDisabled"
                [isSubmitScenarioButtonHidden]="isSubmitScenarioButtonHidden"
                (toggleFullscreen)="enableFullscreen()"
              ></app-matrix-view-table-header>
            </div>

            <app-matrix-view-data
              [showFiltersToggle]="isFullScreenDisabled"
              [hidden]="isMatrixViewDataHidden"
              fxFill
              [selectedFiltersValue]="selectedFiltersValue"
              [granularityDataLoaded]="granularityDataLoaded"
              [granularityHasData]="granularityHasData"
              (toggleFiltersClickEvent)="filtersDrawer.toggle()"
            ></app-matrix-view-data>
          </div>
          <app-matrix-view-traffic-lights-legends
            *ngIf="selectedFiltersValue?.dataContext === 'MATRIX_VIEW_DATA'"
          ></app-matrix-view-traffic-lights-legends>
        </mat-card-content>
      </mat-card>
    </mat-drawer-content>
  </mat-drawer-container>
</div>
