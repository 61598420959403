<app-ftd-custom-modal>
  <app-ftd-custom-modal-header [title]="'Drafts'" [titleLeftMatIcon]="'folder_open'"></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body>
    <div id="discount-drafts-list-container">
      <ng-template #loading>
        <div class="spinner-wrapper">
          <app-loading [color]="'white'" [diameter]="25"></app-loading>
        </div>
      </ng-template>
      <div id="discount-drafts-list-table-responsive" fxLayout="column" *ngIf="discountDrafts | async; else loading">
        <table
          aria-describedby="Drafts List"
          id="discount-drafts-list-table"
          [attr.data-test]="'discount-drafts-list-table'"
          mat-table
          [dataSource]="discountDraftsListDataSource"
          matSort
          [matSortActive]="COLUMNS.updatedAt"
          matSortDirection="desc"
          matSortDisableClear
        >
          <ng-container [matColumnDef]="COLUMNS.discountType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by type">Type</th>
            <td mat-cell *matCellDef="let discount">{{ discount.discountType }}</td>
          </ng-container>

          <ng-container [matColumnDef]="COLUMNS.name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by name">Name</th>
            <td mat-cell *matCellDef="let discount">
              <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5">
                <mat-icon>content_paste</mat-icon>
                <span>{{ discount.name }}</span>
              </div>
            </td>
          </ng-container>

          <ng-container [matColumnDef]="COLUMNS.updatedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by data/hour">
              Saved On
            </th>
            <td mat-cell *matCellDef="let discount">{{ discount.updatedAt | date: 'dd LLL yyyy, HH:mm' }}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr
            mat-row
            *matRowDef="let discountItem; columns: displayedColumns"
            (click)="setSelectedDiscount(discountItem)"
            [ngClass]="{ 'discount-selected': selectedDiscount && discountItem.id === selectedDiscount.id }"
          ></tr>
          <tr class="mat-row" *matNoDataRow>
            <td class="mat-cell">No discount drafts data found</td>
          </tr>
        </table>
      </div>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer>
    <app-ftd-button
      id="open-discount-btn"
      [attr.data-test]="'open-discount-btn'"
      (btnClick)="openDiscount()"
      [disabled]="!selectedDiscount"
      label="Open"
      ariaText="Select a Discount Draft"
      matTooltip="Select a Discount Draft"
      matTooltipPosition="above"
      [matTooltipDisabled]="!!selectedDiscount"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
