<app-ftd-custom-modal>
  <app-ftd-custom-modal-header [title]="title"></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body>
    <div class="mb-3">
      <span> You are editing {{ totalModels }} models. </span>
    </div>

    <div class="mb-3">
      <div>Set the amount you want to apply to increase or decrease the selected prices:</div>
    </div>

    <div id="edit-prices-form" class="mb-3">
      <form [formGroup]="form" novalidate>
        <div id="values-form-container">
          <div class="effective-date-container">
            <div class="mb-1">
              <span> Effective Date </span>
            </div>

            <app-ftd-date-picker
              class="w-100"
              [form]="form"
              [id]="'effectiveDate'"
              [placeholder]="'Date'"
              [daysAhead]="0"
              [name]="'effectiveDate'"
              [enableMinDate]="true"
              [controlName]="'effectiveDate'"
              [attr.data-test]="'edit-scenario-modal-effective-date-input'"
            ></app-ftd-date-picker>
          </div>
          <div class="amount-container">
            <div class="mb-1">
              <span> Amount </span>
            </div>

            <app-ftd-input
              [placeholder]="'Amount'"
              [form]="form"
              [controlName]="'amount'"
              [hideMaxlengthHint]="true"
              [enableCurrencyMask]="true"
              [currencyMaskOptions]="currencyMaskOption"
              [fillWidth]="true"
              [attr.data-test]="'edit-scenario-modal-amount-input'"
            ></app-ftd-input>
          </div>

          <div class="currency-container">
            <div class="mb-1"></div>

            <app-ftd-button-option-selector
              [currency]="currencySymbol"
              (optionSelectorChange)="setCurrencyContext($event)"
              [attr.data-test]="'edit-scenario-modal-option-selector'"
            >
            </app-ftd-button-option-selector>
          </div>
        </div>

        <div class="comment-container mt-3">
          <div class="mb-1">
            <span> Leave a comment: </span>
          </div>

          <app-ftd-textarea
            class="w-100"
            [form]="form"
            [controlName]="'comment'"
            [placeholder]="'Add a comment'"
            [rows]="5"
            [attr.data-test]="'edit-scenario-modal-comment-text-area'"
          ></app-ftd-textarea>
        </div>
      </form>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer>
    <app-ftd-button
      [id]="'edit-scenario-btn'"
      (btnClick)="onSubmit()"
      [disabled]="!(form.valid && form.touched)"
      [label]="'Edit'"
      ariaText="Edit"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="true"
      [attr.data-test]="'edit-scenario-modal-edit-btn'"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
