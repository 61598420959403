<app-ftd-custom-modal>
  <app-ftd-custom-modal-header
    [titleLeftMatIcon]="'info_outline'"
    [colorLeftMatIcon]="'info'"
    [title]="title"
  ></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body [isRequestProcessing]="isRequestProcessing">
    <div class="mb-3" *ngIf="!(data.data.bmw.length || data.data.mini.length)">
      <span> Please edit some values for submission </span>
    </div>

    <div class="mb-3" *ngIf="data.data.bmw.length || data.data.mini.length">
      <div class="modal-header-text-container">
        By submitting this User Scenario, your price adjustment(s) will be visible and waiting for validation in the
        Main Scenario.
      </div>
      <div>Are you sure you want to submit the price adjustment(s) in this User Scenario for:</div>
      <div class="summary-report-text-container">Your Summary Report</div>
    </div>

    <div *ngIf="data.data.bmw.length || data.data.mini.length">
      <div class="car-models-accordion-container">
        <app-ftd-accordion id="modal-accordion">
          <app-ftd-expansion-panel
            *ngIf="data.data.bmw.length"
            [iconPath]="'./assets/images/brands/BMW'"
            [expanded]="true"
            [title]="'BMW'"
            [description]="data.data.bmw.length + ' Model Code(s)'"
            [panelHeaderFxLayout]="'row'"
            [panelHeaderFxLayoutAlign]="'space-between'"
            [fillWidth]="true"
            [hideRightToggle]="true"
            [hideLeftToggle]="false"
          >
            <table aria-describedby="BMW Table" mat-table [dataSource]="data.data.bmw">
              <ng-container matColumnDef="model">
                <th mat-header-cell *matHeaderCellDef>Model</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="car-models-text-container"
                  [class.is-car-models-row-disabled]="!isPricePointSubmissionPossible(element.status)"
                >
                  <span>
                    <b>{{ element.modelCode }}</b>
                  </span>
                  <span>{{ ' ' + element.model }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="currentPrice">
                <th mat-header-cell *matHeaderCellDef>Current Price</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="current-price-text-container no-padding"
                  [class.is-car-models-row-disabled]="!isPricePointSubmissionPossible(element.status)"
                >
                  <div>
                    <span class="ml-2">
                      <b> Gross </b>
                    </span>

                    <span class="mr-2">
                      <span class="w-100 warning-container" *ngIf="!isPricePointSubmissionPossible(element.status)">
                        <mat-icon class="warning">warning_amber</mat-icon>
                      </span>

                      {{ formatPrice(element.listPriceInclTax, element.currency) | nullLabel }}
                    </span>
                  </div>

                  <div>
                    <span class="ml-2">
                      <b> Net </b>
                    </span>

                    <span class="mr-2">
                      <span class="w-100 warning-container" *ngIf="!isPricePointSubmissionPossible(element.status)">
                        <mat-icon class="warning">warning_amber</mat-icon>
                      </span>

                      {{ formatPrice(element.listPriceExclTax, element.currency) }}
                    </span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="newPriceAndDate">
                <th mat-header-cell *matHeaderCellDef>New price & Effective date</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="new-price-and-date-text-container no-padding"
                  [class.is-car-models-row-disabled]="!isPricePointSubmissionPossible(element.status)"
                >
                  <div class="container-new-price-and-date">
                    <div>
                      <div class="table-cell-container-new-price-and-date">
                        <div class="table-cell-container-new-price-and-date-text yellow-badge">
                          <b class="mr-2">{{ formatPrice(element.price, element.currency) }}</b>
                          {{ element.effectiveDate }}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="table-cell-container-new-price-and-date">
                        <div
                          class="table-cell-container-new-price-and-date-text"
                          [class.yellow-badge]="formatPrice(element.netPrice, element.currency) !== 'N/A'"
                        >
                          <b class="mr-2">{{ formatPrice(element.netPrice, element.currency) }}</b>
                          <ng-container *ngIf="formatPrice(element.netPrice, element.currency) !== 'N/A'; else na">
                            {{ element.effectiveDate }}
                          </ng-container>
                          <ng-template #na> N/A </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="priceDifference">
                <th mat-header-cell *matHeaderCellDef>Price Difference</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="no-padding price-difference-column"
                  [class.is-car-models-row-disabled]="!isPricePointSubmissionPossible(element.status)"
                >
                  <div class="container-price-difference">
                    <div class="table-cell-container-price-difference">
                      <span class="price-difference-percentage-text" [ngClass]="element.priceDifferenceStatus">
                        <mat-icon class="price-difference-icon" [ngClass]="element.priceDifferenceStatus"
                          >arrow_downward</mat-icon
                        >
                        {{ element.pricePercentage + '%' | nullLabel }}
                      </span>
                      <span class="price-difference-percentage-text ml-2" [ngClass]="element.priceDifferenceStatus">{{
                        formatPrice(element.price - element.listPriceInclTax, element.currency, true) | nullLabel
                      }}</span>
                    </div>

                    <div class="table-cell-container-price-difference">
                      <span
                        class="price-difference-percentage-text"
                        [ngClass]="
                          element.netPriceDifferenceStatus && formatPrice(element.netPrice, element.currency) !== 'N/A'
                            ? element.netPriceDifferenceStatus
                            : ''
                        "
                      >
                        <mat-icon
                          *ngIf="formatPrice(element.netPrice, element.currency) !== 'N/A'"
                          class="price-difference-icon"
                          [ngClass]="element.netPriceDifferenceStatus ? element.netPriceDifferenceStatus : ''"
                          >arrow_downward</mat-icon
                        >
                        {{
                          (element.netPricePointPercentage | nullLabel) + (element.netPricePointPercentage ? '%' : '')
                        }}
                      </span>
                      <span
                        class="price-difference-percentage-text ml-2"
                        [ngClass]="
                          element.netPriceDifferenceStatus && formatPrice(element.netPrice, element.currency) !== 'N/A'
                            ? element.netPriceDifferenceStatus
                            : ''
                        "
                        >{{
                          formatPrice(element.netPrice - element.listPriceExclTax, element.currency, true) | nullLabel
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="table-overlay" *ngIf="!isPricePointSubmissionPossible(element.status)"></div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </app-ftd-expansion-panel>

          <app-ftd-expansion-panel
            *ngIf="data.data.mini.length"
            [iconPath]="'./assets/images/brands/MINI'"
            [expanded]="true"
            [title]="'MINI'"
            [description]="data.data.mini.length + ' Model Code(s)'"
            [panelHeaderFxLayout]="'row'"
            [panelHeaderFxLayoutAlign]="'space-between'"
            [fillWidth]="true"
            [hideRightToggle]="true"
            [hideLeftToggle]="false"
          >
            <table aria-describedby="Mini Table" mat-table [dataSource]="data.data.mini">
              <ng-container matColumnDef="model">
                <th mat-header-cell *matHeaderCellDef>Model</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="car-models-text-container"
                  [class.is-car-models-row-disabled]="!isPricePointSubmissionPossible(element.status)"
                >
                  <span>
                    <b>{{ element.modelCode }}</b>
                  </span>
                  <span>{{ ' ' + element.model }}</span>
                </td>
              </ng-container>

              <ng-container matColumnDef="currentPrice">
                <th mat-header-cell *matHeaderCellDef>Current Price</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="current-price-text-container no-padding"
                  [class.is-car-models-row-disabled]="!isPricePointSubmissionPossible(element.status)"
                >
                  <div>
                    <span class="ml-2">
                      <b> Gross </b>
                    </span>

                    <span class="mr-2">
                      <span class="w-100 warning-container" *ngIf="!isPricePointSubmissionPossible(element.status)">
                        <mat-icon class="warning">warning_amber</mat-icon>
                      </span>

                      {{ formatPrice(element.listPriceInclTax, element.currency) | nullLabel }}
                    </span>
                  </div>

                  <div>
                    <span class="ml-2">
                      <b> Net </b>
                    </span>

                    <span class="mr-2">
                      <span class="w-100 warning-container" *ngIf="!isPricePointSubmissionPossible(element.status)">
                        <mat-icon class="warning">warning_amber</mat-icon>
                      </span>

                      {{ formatPrice(element.listPriceExclTax, element.currency) }}
                    </span>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="newPriceAndDate">
                <th mat-header-cell *matHeaderCellDef>New price & Effective date</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="new-price-and-date-text-container no-padding"
                  [class.is-car-models-row-disabled]="!isPricePointSubmissionPossible(element.status)"
                >
                  <div class="container-new-price-and-date">
                    <div>
                      <div class="table-cell-container-new-price-and-date">
                        <div class="table-cell-container-new-price-and-date-text yellow-badge">
                          <b class="mr-2">{{ formatPrice(element.price, element.currency) }}</b>
                          {{ element.effectiveDate }}
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="table-cell-container-new-price-and-date">
                        <div
                          class="table-cell-container-new-price-and-date-text"
                          [class.yellow-badge]="formatPrice(element.netPrice, element.currency) !== 'N/A'"
                        >
                          <b class="mr-2">{{ formatPrice(element.netPrice, element.currency) }}</b>
                          <ng-container *ngIf="formatPrice(element.netPrice, element.currency) !== 'N/A'; else na">
                            {{ element.effectiveDate }}
                          </ng-container>
                          <ng-template #na> N/A </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="priceDifference">
                <th mat-header-cell *matHeaderCellDef>Price Difference</th>
                <td
                  mat-cell
                  *matCellDef="let element"
                  class="no-padding price-difference-column"
                  [class.is-car-models-row-disabled]="!isPricePointSubmissionPossible(element.status)"
                >
                  <div class="container-price-difference">
                    <div class="table-cell-container-price-difference">
                      <span class="price-difference-percentage-text" [ngClass]="element.priceDifferenceStatus">
                        <mat-icon class="price-difference-icon" [ngClass]="element.priceDifferenceStatus"
                          >arrow_downward</mat-icon
                        >
                        {{ formatPercentage(element.pricePercentage) | nullLabel }}
                      </span>
                      <span class="price-difference-percentage-text ml-2" [ngClass]="element.priceDifferenceStatus">{{
                        formatPrice(element.price - element.listPriceInclTax, element.currency, true) | nullLabel
                      }}</span>
                    </div>

                    <div class="table-cell-container-price-difference">
                      <span
                        class="price-difference-percentage-text"
                        [ngClass]="
                          element.netPriceDifferenceStatus && formatPrice(element.netPrice, element.currency) !== 'N/A'
                            ? element.netPriceDifferenceStatus
                            : ''
                        "
                      >
                        <mat-icon
                          *ngIf="formatPrice(element.netPrice, element.currency) !== 'N/A'"
                          class="price-difference-icon"
                          [ngClass]="element.netPriceDifferenceStatus ? element.netPriceDifferenceStatus : ''"
                          >arrow_downward</mat-icon
                        >
                        {{
                          (element.netPricePointPercentage | nullLabel) + (element.netPricePointPercentage ? '%' : '')
                        }}
                      </span>
                      <span
                        class="price-difference-percentage-text ml-2"
                        [ngClass]="
                          element.netPriceDifferenceStatus && formatPrice(element.netPrice, element.currency) !== 'N/A'
                            ? element.netPriceDifferenceStatus
                            : ''
                        "
                        >{{
                          formatPrice(element.netPrice - element.listPriceExclTax, element.currency, true) | nullLabel
                        }}</span
                      >
                    </div>
                  </div>
                  <div class="table-overlay" *ngIf="!isPricePointSubmissionPossible(element.status)"></div>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </app-ftd-expansion-panel>
        </app-ftd-accordion>
      </div>

      <div *ngIf="!isAllPricePointsSubmissionPossible" class="w-100 all-price-points-submission-not-Possible-container">
        <mat-icon class="warning">warning_amber</mat-icon>

        Some models already have price points and will not be submitted
      </div>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer>
    <app-ftd-button
      *ngIf="data.data.bmw.length || data.data.mini.length"
      [id]="'submit-scenario-btn'"
      (btnClick)="submitNewScenario()"
      [disabled]="isSubmitButtonDisabled"
      [label]="'Submit'"
      ariaText="Submit"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="true"
      [attr.data-test]="'submit-scenario-btn'"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
