import { GraphQLErrorType } from './models/graphql-error.model';
import { IMutation, IQuery } from 'src/app/graphql/services/gql-api.service';

/*
 * Custom defined user readable error messages for all Queries and Mutations
 */
export const GRAPHQL_ERRORS: Partial<Record<keyof (IMutation & IQuery), Record<string, string>>> = {
  /*
   * Creates a new scenario and it's scenario data
   */
  addScenario: {
    [GraphQLErrorType.DUPLICATE_ITEM]: 'Scenario with same name already exists. Please try another name.',
    [GraphQLErrorType.MISSING_ARGUMENT_VALUE]: 'You are missing scenario name.',
    [GraphQLErrorType.LAMBDA_UNHANDLED]:
      "Couldn't generate KPI matrix values. Error processing request while connecting to KPI Microservice, please try again.",
  },

  /*
   * Check for approval for a market and brand
   */
  approveBrandPricePoints: {
    [GraphQLErrorType.APPROVE_BRAND_PRICE_POINT_IN_TWO_DIFFERENT_STATUS]:
      "Price points in both level-1 approved and NSC approved can't be approved",
    [GraphQLErrorType.APPROVE_BRAND_NO_PRICE_POINTS_TO_APPROVE]: 'No pricePoints to approve',
  },

  /*
   * Check for approval for a market and brand
   */
  brandApprovalStatus: {
    [GraphQLErrorType.BRAND_APPROVAL_MISSING_VALUE]: 'No pricepoints for the given input',
  },

  /*
   * Deletes an existing scenario and its related scenario data
   */
  deleteScenario: {
    [GraphQLErrorType.DELETION_FAILED]: 'Failed to delete scenario',
  },

  /*
   * Fetch filers based on market and brands
   */
  filters: {
    [GraphQLErrorType.FILTERS_MISSING_ARGUMENTS]: 'Please select only one market and atleast one brand',
  },

  /*
   * Fetches list of scenarios
   */
  scenarios: {
    fetchError: 'Unable to fetch your scenarios',
  },
};
