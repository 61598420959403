import { APP_SYMBOLS } from '../../constants/app-symbols';
import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'textAndNumberDisplay',
})
export class TextAndNumberDisplayPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(value: any, format: string = '1.2-2'): string {
    return this.formatValue(value, format);
  }

  private formatValue(value: any, format: string): string {
    switch (typeof value) {
      case 'number':
        return Number.isInteger(value) ? value.toString() : (this.decimalPipe.transform(value, format) as string);
      case 'string':
        return value;
      default:
        return APP_SYMBOLS.NA;
    }
  }
}
