import { DiscountApprovalStatus } from '../enums/discount-approval-status.enum';
import { DiscountCategory } from '../enums/discount-category.enum';
import { DiscountType } from '../enums/discount-type.enum';
import { IDiscount } from '../../graphql/services/gql-api.service';
import { SalesChannels } from '../enums/sales-channels.enum';

export interface ISummaryTableDataModel {
  discountCategory: DiscountCategory;
  discountType: DiscountType;
  name: string;
  absoluteDiscountNetRange: string;
  absoluteDiscountInclTaxRange: string;
  relativeDiscountNetRange: string;
  validAsOf: Date;
  validUntil: Date;
  salesChannels: SalesChannels;
  id: string;
  approvalStatus: DiscountApprovalStatus;
}

export interface ISummaryTableColumnModel {
  name: SummaryTableColumns;
  columnType: SummaryTableColumnType;
  category: SummaryTableColumnCategoryName;
  displayName: string;
  secondDisplayName?: string;
  position: number;
  isSticky: boolean;
  isVisible: boolean;
  metadata: {
    description: string;
  };
  technicalName: keyof IDiscount;
}

export type SummaryTableColumns =
  | 'discountCategory'
  | 'discountType'
  | 'name'
  | 'absoluteDiscountNetRange'
  | 'absoluteDiscountInclTaxRange'
  | 'relativeDiscountNetRange'
  | 'relativeDiscountInclTaxRange'
  | 'startDate'
  | 'endDate'
  | 'salesChannels'
  | 'benefitType'
  | 'id'
  | 'approvalStatus'
  | 'actions';

export enum SummaryTableColumnType {
  TEXT = 'TEXT',
  ACTIONS = 'ACTIONS',
  STATUS = 'STATUS',
  DATE = 'DATE',
  CURRENCY = 'CURRENCY',
  PERCENTAGE = 'PERCENTAGE',
}

export interface ISummaryTableColumnCategoryModel {
  name: SummaryTableColumnCategoryName;
  displayName: string;
  numOfColumns: number;
}

export type SummaryTableColumnCategoryName =
  | 'DISCOUNT_CLASSIFICATION'
  | 'DISCOUNT_AMOUNT'
  | 'DISCOUNT_VALIDITY'
  | 'IDENTIFICATION'
  | 'ACTIONS';
