import { AfterViewInit, Component, Input, OnDestroy } from '@angular/core';
import { Base64Utils } from '../../../../common/utils/base64.utils';
import { ChartAxisType } from '../../../../common/components/ftd-charts/enums/ftd-generic-chart.enum';
import { CrosschecksChartContext } from '../../../enums/crosschecks-chart-context-messages.enum';
import { CrosschecksService } from '../../../services/crosschecks/crosschecks.service';
import {
  DeltaStockCoverageEnum,
  IStockMixSideTable,
} from './ftd-side-table/stock-mix-side-table/stock-mix-side-table.component';
import { IFtdDropdownOption } from '../../../../common/models/ftd-dropdown-option.model';
import { IGranularityDto, IStockMixTileChart } from '../../../../matrix-view/models/api.model';
import { Subscription } from 'rxjs';
import { TrafficLightsColor } from '../../../types/traffic-lights.type';

@Component({
  selector: 'app-stock-mix',
  styleUrls: ['./stock-mix.component.scss'],
  templateUrl: './stock-mix.component.html',
})
export class StockMixComponent implements AfterViewInit, OnDestroy {
  @Input() set granularity(granularityData: string) {
    this._granularity = Base64Utils.decodeAtobToJson(granularityData);
    this.filters = this._granularity;
  }

  chartData?: any;
  chartAxisType = ChartAxisType.VALUE;
  averageStockAge?: IStockMixTileChart;
  ispStockCoverage?: IStockMixTileChart;
  idealStockParameter?: number;

  filters!: IGranularityDto;
  isLoading: boolean = true;
  dataLoaded: boolean = false;
  errorFlag: boolean = false;
  crosschecksChartContext: CrosschecksChartContext = CrosschecksChartContext.NO_FILTER_APPLIED;
  dropdownList: string[] = ['Stock Category', 'Price Level'];
  isChartValuesVisible: boolean = false;
  dropdownOptions: IFtdDropdownOption<string>[][] = [];
  sideTableDataSource: IStockMixSideTable[] = [];

  @Input() crosscheckIndication?: TrafficLightsColor;
  @Input() crosscheckIndicationForecasted?: TrafficLightsColor;

  private _granularity: IGranularityDto = <IGranularityDto>{};
  private subscriptions: Subscription[] = [];

  constructor(private crosscheckService: CrosschecksService) {}

  ngAfterViewInit(): void {
    this.loadStockMixData();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.ngUnsubscribe();
  }

  /**
   * NgUnsubscribe
   */
  ngUnsubscribe(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  /**
   * LoadStockAgeData
   * @private
   */

  private loadStockMixData(): void {
    this.dataLoaded = false;
    this.isLoading = true;
    if (this._granularity.market) {
      const subscription: Subscription = this.crosscheckService.getStockMixData().subscribe({
        error: (): void => {
          this.crosschecksChartContext = CrosschecksChartContext.ERROR;
          this.isLoading = false;
          this.errorFlag = true;
          this.dataLoaded = false;
        },
        next: (response: any): void => {
          this.mapStockAgeDataResponse(response);
          this.errorFlag = false;
          this.dataLoaded = true;
          this.isLoading = false;
        },
      });
      this.subscriptions.push(subscription);
    } else {
      this.isLoading = false;
    }
  }

  /**
   * MapStockAgeDataResponse
   * @private
   */
  private mapStockAgeDataResponse(response: any) {
    if (response) {
      this.averageStockAge = response.stock_mix?.stock_mix_avg_coverage;
      this.ispStockCoverage = response.stock_mix?.stock_mix_isp_coverage;
      this.idealStockParameter = response.stock_mix?.ideal_stock_parameter;
    }

    this.chartData = {
      fields: response.stock_mix.data.map((element: any) => {
        this.sideTableDataSource.push({
          deltaStockCoverage: this.getDeltaStockAge(element.next_month_expected),
          nextMonthExpected: element.next_month_expected,
          totalStock: element.total_stock,
        });
        return { averageStockCoverage: element.value, model: element.model };
      }),
      headings: [
        {
          description: 'AverageStockCoverage',
          key: 'averageStockCoverage',
        },
      ],
    };

    return this.chartData;
  }

  getDeltaStockAge(nextMonthExpected: string): DeltaStockCoverageEnum {
    if (nextMonthExpected.charAt(0) !== '+' && nextMonthExpected.charAt(0) !== '-') {
      return DeltaStockCoverageEnum.stable;
    }
    return nextMonthExpected.charAt(0) === '+' ? DeltaStockCoverageEnum.increase : DeltaStockCoverageEnum.decrease;
  }
}
