<div id="filters-container">
  <div class="filter-wrapper">
    <app-ftd-dropdown
      id="{{ 'mv-filter-' + 'market' }}"
      [attr.data-test]="'mv-filter-' + 'market'"
      [isSearchVisible]="true"
      [label]="marketsList?.label!"
      [form]="filtersForm"
      [isFlagsVisible]="true"
      [controlName]="'market'"
      [options]="marketsList?.options!"
    ></app-ftd-dropdown>
    <app-ftd-multi-dropdown
      id="{{ 'mv-filter-' + 'brand' }}"
      [attr.data-test]="'mv-filter-' + 'brand'"
      [isSearchVisible]="false"
      [label]="brandsList?.label!"
      [form]="filtersForm"
      [controlName]="'brand'"
      [options]="brandsList?.options!"
      [showBrandIcon]="true"
    ></app-ftd-multi-dropdown>
  </div>
  <app-ftd-button
    [label]="!isLoading ? 'APPLY' : ''"
    (click)="onClickApplyFilters()"
    [disabled]="isButtonDisabled"
    [isLoading]="isLoading || !brandsList?.options!"
  ></app-ftd-button>
</div>
