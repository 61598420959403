export enum FilterDisplayName {
  brand = 'Brand',
  series = 'Series',
  eSeries = 'E-Series',
  model = 'Model',
  modelCode = 'Model code',
  segment = 'Segment',
  powertrain = 'Powertrain',
  market = 'Market',
}
