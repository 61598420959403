<mat-form-field [formGroup]="form" appearance="outline" id="ftd-multi-dropdown-{{ id }}">
  <mat-label>{{ label }}</mat-label>
  <mat-select
    [id]="id"
    [formControlName]="controlName"
    multiple
    [compareWith]="distinctById"
    disableOptionCentering="true"
  >
    <mat-select-trigger *ngIf="showQuantityOf">
      <span *ngFor="let selectedOptionToDisplay of selectedOptionsToDisplay; let index = index">
        {{ selectedOptionToDisplay.name }}
        <span *ngIf="selectedOptionToDisplay.quantity > 1" class="selected-quantity">
          ({{ selectedOptionToDisplay.quantity }})
        </span>
        {{ hasToShowComma(index) ? ', ' : '' }}
      </span>
    </mat-select-trigger>

    <mat-option *ngIf="isSearchVisible" class="map-option-search-input">
      <ngx-mat-select-search
        placeholderLabel="Search"
        [formControlName]="controlName + 'Search'"
        noEntriesFoundLabel="No matching found"
      >
      </ngx-mat-select-search>
    </mat-option>

    <mat-optgroup
      *ngFor="let group of optionsGroupList; trackBy: groupTrackBy"
      [label]="group.label"
      class="brands-group"
    >
      <mat-option *ngFor="let option of group.options; trackBy: optionTrackBy" [value]="option.value">
        {{ option.label }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
