import { AfterViewInit, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';

import { FtdDatepickerHeader } from '../ftd-date-picker/ftd-date-picker.component';
import { InputStatus } from '../../enums/table-input-status.enum';

@Component({
  selector: 'app-ftd-table-date-picker',
  styleUrls: ['./ftd-table-date-picker.component.scss'],
  templateUrl: './ftd-table-date-picker.component.html',
})
export class FtdTableDatePickerComponent implements AfterViewInit {
  @Output() datepickerToggleClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() name!: string;
  @Input() isMatrixView: boolean = true;
  @Input() id!: string;
  @Input() form!: FormGroup;
  @Input() controlName = '';
  @Input() placeHolder: string = 'DD/MM/YYYY';
  minDate?: Date | null = new Date();
  @Input() rowIndex!: number;
  @Input() daysAhead: number = 0;
  @Input() inputStatus?: InputStatus = 'default';
  @ViewChild('textField') textField!: ElementRef<HTMLInputElement>;
  ftdHeader = FtdDatepickerHeader;

  _enableMinDate: boolean = false;
  get enableMinDate(): boolean {
    return this._enableMinDate;
  }

  @Input() set enableMinDate(value: boolean) {
    this._enableMinDate = value;
    if (value) {
      this.minDate = new Date();
      this.minDate?.setDate(this.minDate.getDate() + this.daysAhead);
    } else {
      this.minDate = null;
    }
  }

  ngAfterViewInit() {
    if (this.inputStatus === 'disabled') {
      this.textField.nativeElement.disabled = true;
    }
  }

  emitDatepickerToggleClicked() {
    this.datepickerToggleClicked.emit(true);
  }
}
