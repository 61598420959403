<mat-expansion-panel
  class="ftd-expansion-panel"
  [hideToggle]="hideRightToggle"
  [expanded]="expanded"
  (opened)="expanded = true"
  (closed)="expanded = false"
  [disabled]="disabled"
>
  <mat-expansion-panel-header>
    <div
      class="mat-expansion-panel-header-container"
      [fxLayout]="panelHeaderFxLayout"
      [fxLayoutAlign]="panelHeaderFxLayoutAlign"
      [class.w-100]="fillWidth"
    >
      <div>
        <div fxLayout="row" fxLayoutAlign="space-between">
          <mat-icon *ngIf="titleIcon">{{ titleIcon }}</mat-icon>
          <mat-icon *ngIf="!hideLeftToggle && expanded">expand_more</mat-icon>
          <mat-icon class="expand-less-icon" *ngIf="!hideLeftToggle && !expanded">expand_less</mat-icon>
          <img class="icon" *ngIf="iconPath.length" src="{{ iconPath }}.svg" alt="{{ iconPath }}" />
          <mat-panel-title class="title-label" *ngIf="title || titleIcon">
            {{ title }}
          </mat-panel-title>
        </div>
      </div>
      <div>
        <mat-panel-description *ngIf="description || descriptionIcon">
          {{ description }}
          <mat-icon *ngIf="descriptionIcon">{{ descriptionIcon }}</mat-icon>
        </mat-panel-description>
      </div>
    </div>
  </mat-expansion-panel-header>

  <div class="ftd-expansion-panel-body">
    <ng-content></ng-content>
  </div>
</mat-expansion-panel>
