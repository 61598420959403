<div id="table-header-container" class="w-100" *ngIf="!isDividerHidden && getShowHorizontalDivider()">
  <div class="ptb-16">
    <div id="table-header-title-description-container" [attr.data-test]="'table-header'">
      <div id="table-header-title" *ngIf="!isTitleHidden">
        <h3>{{ title }}:</h3>
      </div>
      <div id="table-header-description" class="ml-2" *ngIf="!isDescriptionHidden">
        <h3 class="header-description">{{ description }}</h3>
      </div>
    </div>
    <div id="table-header-actions-container" fxLayout="row" fxLayoutAlign="center center">
      <app-ftd-button
        [matMenuTriggerFor]="menu"
        icon="more_vert"
        hierarchy="secondary"
        ariaText="Table Menu"
        size="S"
        class="mr-2"
      ></app-ftd-button>

      <div
        *ngIf="!isSaveButtonHidden && currentUser?.permissions?.getHasPermissionToSaveUserScenario()"
        id="save-scenario-container"
        class="m-0 p-0"
      >
        <app-ftd-button
          class="mr-2"
          [disabled]="isSaveDisabled"
          [matTooltip]="saveToolTip"
          icon="save"
          [label]="'Save'"
          ariaText="Save User Scenario"
          hierarchy="primary"
          size="S"
          [isLoading]="loading"
          (btnClick)="saveScenario()"
          [attr.data-test]="'save-scenario-btn'"
        >
        </app-ftd-button>
      </div>

      <mat-menu #menu="matMenu" [xPosition]="'before'">
        <button
          aria-label="Custom Columns"
          class="item-text"
          mat-menu-item
          (click)="openMatrixViewColumnsOrderingModal()"
        >
          <mat-icon>view_column</mat-icon>
          <p>Custom Columns</p>
        </button>
        <button
          aria-label="Full Screen (Alt + Enter)"
          class="item-text"
          mat-menu-item
          (click)="onClickFullscreenBtn($event)"
        >
          <mat-icon>open_in_full</mat-icon>
          <p>Full Screen (Alt + Enter)</p>
        </button>
        <a
          *ngFor="let appLink of APP_FSM_RELATED_EXTERNAL_LINKS"
          class="item-text"
          mat-menu-item
          [href]="appLink.link"
          target="_blank"
        >
          <mat-icon>open_in_new</mat-icon>
          <p>{{ appLink.name }}</p>
        </a>
      </mat-menu>

      <app-ftd-button
        *ngIf="!isExportButtonHidden && currentUser?.permissions?.getHasPermissionToDownloadScenario()"
        [id]="'download-btn'"
        icon="download"
        label="Download"
        ariaText="Download Scenario"
        hierarchy="secondary"
        size="S"
        (btnClick)="openProgressBarModalAndDownloadExcelFileBasedOnSelectedFilters()"
        [attr.data-test]="'matrix-view-excel-download-btn'"
      ></app-ftd-button>

      <div *ngIf="showDivider && !isActionButtonsDividerHidden" id="table-header-actions-divider"></div>
      <div
        id="approve-scenario-container"
        *ngIf="!isApproveScenarioButtonHidden && currentUser?.permissions?.getHasPermissionToApproveMainScenario()"
      >
        <app-ftd-button
          label="Approve"
          icon="task_alt"
          hierarchy="green"
          size="S"
          (btnClick)="openApproveScenarioModal(approveModal)"
          [attr.data-test]="'approve-scenario-btn'"
        ></app-ftd-button>
      </div>
      <div
        id="release-scenario-container"
        *ngIf="!isApproveScenarioButtonHidden && currentUser?.permissions?.getHasPermissionToReleaseMainScenario()"
      >
        <app-ftd-button
          label="Release"
          icon="open_in_new"
          hierarchy="primary"
          size="S"
          (btnClick)="openApproveScenarioModal(releaseModal)"
          [attr.data-test]="'release-scenario-btn'"
        ></app-ftd-button>
      </div>
      <div id="unlock-main-scenario-container" *ngIf="!isUnlockScenarioButtonHidden">
        <app-ftd-button
          [disabled]="isUnlockScenarioButtonDisabled"
          label="Unlock main scenario"
          ariaText="Unlock Main Scenario"
          icon="lock"
          hierarchy="primary"
          size="S"
        ></app-ftd-button>
      </div>
    </div>
  </div>

  <div *ngIf="!isDividerHidden && getShowHorizontalDivider()" class="divider">
    <mat-divider></mat-divider>
  </div>
</div>
