import { Component, Inject, OnDestroy, Optional } from '@angular/core';
import { FtdNotifierService } from '../../../common/services/ftd-notifier/ftd-notifier.service';
import { GovernanceService, StatusActionEnum } from '../../../matrix-view/services/governance/governance.service';
import { IMatrixViewDataSourceItem } from '../../../matrix-view/models/matrix-view.model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BtnHierarchy } from '../../../common/components/ftd-button/ftd-button.component';
import { FormControl, FormGroup } from '@angular/forms';
import { GovernanceUpdateError } from '../../../core/error-handling/errors/error-functions';
import { ISubmitStatusForm } from './submit-status-modal.model.component';
import { MatrixViewFormService } from '../../../matrix-view/services/matrix-view-form/matrix-view-form.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-submit-status-modal',
  styleUrls: ['./submit-status-modal.component.scss'],
  templateUrl: './submit-status-modal.component.html',
})
/**
 * @class SubmitStatusModalComponent
 * @extends OnDestroy
 */
export class SubmitStatusModalComponent implements OnDestroy {
  private _isSubmitButtonDisabled: boolean = false;
  private _isRequestProcessing: boolean = false;
  private _title: string = '';
  private _buttonHierarchy: BtnHierarchy = 'primary';
  private _buttonText: string = 'Confirm';
  private _description: string = '';
  private _successMessage: string = '';
  private _requestProcessingMessage: string = "Don't close this window, please wait a few moments";

  get isRequestProcessing(): boolean {
    return this._isRequestProcessing;
  }

  set isRequestProcessing(value: boolean) {
    this._isRequestProcessing = value;
  }

  get requestProcessingMessage(): string {
    return this._requestProcessingMessage;
  }

  set requestProcessingMessage(value: string) {
    this._requestProcessingMessage = value;
  }

  get successMessage(): string {
    return this._successMessage;
  }

  set successMessage(value: string) {
    switch (value) {
      case StatusActionEnum.promote_status:
        this._successMessage = 'Price Point accepted';
        break;
      case StatusActionEnum.withdraw_status:
        this._successMessage = 'Price Point withdrawn';
        break;
      default:
        this._successMessage = 'Price Point declined';
        break;
    }
  }

  get isSubmitButtonDisabled(): boolean {
    return this._isSubmitButtonDisabled;
  }

  set isSubmitButtonDisabled(value: boolean) {
    this._isSubmitButtonDisabled = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    switch (value) {
      case StatusActionEnum.promote_status:
        this._title = 'Confirm Price Point';
        break;
      case StatusActionEnum.withdraw_status:
        this._title = 'Withdraw Price Point';
        break;
      default:
        this._title = 'Decline Price Point';
        break;
    }
  }

  get buttonText(): string {
    return this._buttonText;
  }

  get buttonHierarchy(): BtnHierarchy {
    return this._buttonHierarchy;
  }

  set buttonHierarchy(value: string) {
    switch (value) {
      case StatusActionEnum.promote_status:
        this._buttonHierarchy = 'primary';
        this._buttonText = 'Confirm';
        break;
      case StatusActionEnum.withdraw_status:
        this._buttonHierarchy = 'primary';
        this._buttonText = 'Withdraw';
        break;
      default:
        this._buttonHierarchy = 'red';
        this._buttonText = 'Decline';
        break;
    }
  }

  get description(): string {
    return this._description;
  }

  set description(value: string) {
    switch (value) {
      case StatusActionEnum.promote_status:
        this._description = 'You are accepting this price point. </br> Are you sure you want to proceed?';
        break;
      case StatusActionEnum.withdraw_status:
        this._description = 'You are withdrawing this price point. </br> Are you sure you want to proceed?';
        break;
      default:
        this._description = 'You are declining this price point. </br> Are you sure you want to proceed?';
        break;
    }
  }

  private subscriptions: Subscription[] = [];

  public form: FormGroup = new FormGroup<ISubmitStatusForm>({
    reason: new FormControl<string>(''),
  });

  /**
   * @constructor
   * @param data
   * @param notifierService
   * @param governanceService
   * @param matrixViewFormService
   * @param dialogRef
   */
  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: {
      matrixViewDataSourceItems: IMatrixViewDataSourceItem[];
      status: StatusActionEnum;
    },
    private notifierService: FtdNotifierService,
    private governanceService: GovernanceService,
    private matrixViewFormService: MatrixViewFormService,
    private dialogRef: MatDialogRef<SubmitStatusModalComponent>
  ) {
    this.description = this.data.status;
    this.title = this.data.status;
    this.buttonHierarchy = this.data.status;
    this.successMessage = this.data.status;
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * IsDemoteStatus
   * @return boolean
   */
  get isDemoteStatus(): boolean {
    return this.data.status === StatusActionEnum.demote_status;
  }

  /**
   * Submit
   */
  submit(): void {
    this.isSubmitButtonDisabled = true;
    this.isRequestProcessing = true;

    const subscription: Subscription = this.governanceService
      .submitPricePointAction(this.data.matrixViewDataSourceItems, this.data.status, this.form.get('reason')?.value)
      .subscribe({
        error: (): void => {
          this.isSubmitButtonDisabled = false;
          this.isRequestProcessing = false;
          this.notifierService.showError(GovernanceUpdateError.reason);
        },
        next: (): void => {
          this.matrixViewFormService.resetForm();
          this.dialogRef.close({
            matrixViewDataSourceItems: this.data.matrixViewDataSourceItems,
            requestStatus: 'success',
            status: this.data.status,
          });
          this.isSubmitButtonDisabled = false;
          this.isRequestProcessing = false;
          this.notifierService.showSuccess(this.successMessage);
        },
      });
    this.subscriptions.push(subscription);
  }
}
