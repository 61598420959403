import { Base64Utils } from '../../utils/base64.utils';
import { Component, Input } from '@angular/core';
import { ICrosschecksTrafficLights } from '../../../matrix-view/models/app.model';
import { IGranularityDto } from 'src/app/matrix-view/models/api.model';
import { Router } from '@angular/router';
import { ScenarioContext } from '../../enums/scenario-context.enum';
import { TrafficLightsArrow, TrafficLightsColor } from '../../../crosschecks/types/traffic-lights.type';

export type KeyofCrosschecksTechnicalNameRouteMapping = keyof typeof CrosschecksTechnicalNameRouteMapping;
enum CrosschecksTechnicalNameRouteMapping {
  salesPipelineTrafficLight = 'current/sales-pipeline/order-intake',
  salesPipelineTrafficLightAdj = 'forecasted/sales-pipeline/order-intake',
  stockTrafficLight = 'current/stock',
  stockTrafficLightAdj = 'forecasted/stock',
  marketsTrafficLight = 'current/markets',
  marketsTrafficLightAdj = 'forecasted/markets',
  portfolioTrafficLight = 'current/portfolio',
  portfolioTrafficLightAdj = 'forecasted/portfolio',
  microMacroTrafficLight = 'current/macroeconomics',
  microMacroTrafficLightAdj = 'forecasted/macroeconomics',
}

@Component({
  selector: 'app-crosschecks-traffic-lights',
  styleUrls: ['./crosschecks-traffic-lights.component.scss'],
  templateUrl: './crosschecks-traffic-lights.component.html',
})
export class CrosschecksTrafficLightsComponent {
  @Input() granularity!: IGranularityDto;
  @Input() technicalName?: string;
  @Input() isCrosscheckLegend?: boolean;
  @Input() scenario?: ScenarioContext;
  @Input() scenarioId?: unknown;
  @Input() enableRedirect: boolean = true;
  @Input() isTrafficLightSmall: boolean = false;
  @Input() className?: string = '';
  trafficLightsClassArrow?: TrafficLightsArrow = 'trafficLightNotAvailable';
  trafficLightsClassColor?: TrafficLightsColor = 'trafficLightNotAvailable';

  trafficLightLegendClass?: string = '';
  iconToRender?: string = 'link';

  @Input() _status!: ICrosschecksTrafficLights;
  @Input() set status(status: ICrosschecksTrafficLights) {
    this._status = status;
    this.initTrafficLights();
  }

  get status(): ICrosschecksTrafficLights {
    return this._status;
  }

  /**
   * @constructor
   * @param router
   */
  constructor(private router: Router) {}

  /**
   * InitTrafficLights
   */
  initTrafficLights(): void {
    this.trafficLightsClassArrow = this.status.arrow ? this.status.arrow : 'na';
    this.trafficLightsClassColor =
      this.status.color === 'red' || this.status.color === 'green' ? this.status.color : 'grey';

    if (this.isCrosscheckLegend) {
      this.trafficLightLegendClass = 'ftd-tab-label-traffic-light--legend';
    }

    this.iconToRender = this.trafficLightsClassArrow === 'trafficLightNotAvailable' ? 'link' : 'straight';
  }

  /**
   * NavigateToCrosschecks
   */
  navigateToCrosschecks(): void {
    // TODO: remove "this.technicalName !== 'summaryTrafficLight'" when overview crosschecks are implemented
    if (this.technicalName && this.technicalName !== 'summaryTrafficLight') {
      const id: string = Base64Utils.encodeBtoaToString(this.granularity);

      const route: CrosschecksTechnicalNameRouteMapping =
        CrosschecksTechnicalNameRouteMapping[this.technicalName as KeyofCrosschecksTechnicalNameRouteMapping];

      this.router.navigate([`/crosschecks/${route}/`, id], {
        queryParams: { scenario: this.scenario, scenarioId: this.scenarioId },
      });
    }
  }
}
