import { AppThemeColors } from 'src/app/common/constants/app-theme-colors';
import {
  AxisLabel,
  AxisRenderer,
  AxisRendererX,
  AxisRendererY,
  CategoryAxis,
  ColumnSeries,
} from '@amcharts/amcharts5/xy';
import { GenericChartFields } from '../../../models/ftd-generic-chart.model';
import { Label, RoundedRectangle, Theme } from '@amcharts/amcharts5';
import { am5 } from '../../../ftd-base-chart.abstract';
import { marketsSeriesName } from '../../../enums/crosschecks/markets/ftd-markets.enum';

export class FtdMarketsCustomTheme extends Theme {
  setupDefaultRules() {
    super.setupDefaultRules();
    this.setupColumnRules();
    this.setupLabelRules();
    this.setupAxisRules();
    this.setupGridRules();
  }

  protected setupAxisRules() {
    this.rule('ValueAxis').setAll({
      extraMax: 0.3,
      maxDeviation: 0,
      min: 0,
      strictMinMax: true,
      visible: true,
      zoomX: false,
      zoomY: false,
    });

    this.rule('CategoryAxis').setAll({
      maxDeviation: 0,
      panX: true,
      zoomX: false,
      zoomY: false,
    });
    this.rule('CategoryAxis').events.once(
      'datavalidated',
      (event: { type: string; target: CategoryAxis<AxisRenderer> }): void => {
        event.target.zoomToIndexes(0, 15);
        event.target.get('renderer').grid.template.set('visible', false);
      }
    );

    this.rule('CategoryAxis', ['ftd-xAxis-top']).setup = (target: CategoryAxis<AxisRenderer>): void => {
      target.set('categoryField', 'section');
    };

    this.rule('CategoryAxis', ['ftd-xAxis-top']).events.once(
      'datavalidated',
      (event: { type: string; target: CategoryAxis<AxisRenderer> }): void => {
        event.target
          .get('userData')
          .filter((dataItem: GenericChartFields) => dataItem.section)
          .forEach((dataItem: GenericChartFields) => {
            event.target.data.setIndex(
              event.target.data.values.findIndex(
                (element) => (element as GenericChartFields).xAxisValue === dataItem.xAxisValue
              ),
              { section: dataItem.section, xAxisValue: dataItem.xAxisValue }
            );
          });
        event.target.data.setAll(event.target.data.values);
      }
    );

    this.rule('AxisLabel', ['ftd-xAxis-top']).setAll({
      dx: 10,
      dy: -50,
      fontSize: 18,
      populateText: true,
      text: '{section}',
    });

    this.rule('AxisRendererX', ['ftd-xAxis-top']).setup = (target: AxisRendererX): void => {
      target.setAll({
        cellEndLocation: 0.9,
        cellStartLocation: 0.1,
        inside: true,
        minGridDistance: 20,
        opacity: 0,
        opposite: true,
      });
    };

    this.rule('AxisLabel', ['axis', 'y']).adapters.add(
      'text',
      (value: string | undefined, target: AxisLabel): string | undefined => {
        return value + '€';
      }
    );

    this.rule('AxisLabel', ['axis', 'x']).setAll({
      paddingTop: 18,
    });

    this.rule('XYChart').setAll({
      panX: true,
      panY: false,
      wheelY: 'panX',
    });
  }

  protected setupGridRules() {
    this.rule('AxisRendererY').setup = (target: AxisRendererY): void => {
      target.setAll({
        minGridDistance: 40,
        opacity: 0,
      });
    };

    this.rule('Button').setAll({
      forceHidden: true,
    });
  }

  protected setupColumnRules() {
    this.rule('ColumnSeries').adapters.add(
      'fill',
      (fill: am5.Color | undefined, target: ColumnSeries): am5.Color | undefined => {
        switch (target.get('name')) {
          case marketsSeriesName.baseCar:
            return am5.color(0x52a2eb);
          case marketsSeriesName.typicallyEquipped:
            return am5.color(0x79ddc0);
        }
        return fill;
      }
    );

    this.rule('ColumnSeries').events.once('datavalidated', (event: { type: string; target: ColumnSeries }): void => {
      this.rule('RoundedRectangle').adapters.add(
        'stroke',
        (stroke: am5.Color | undefined, target: RoundedRectangle): am5.Color | undefined => {
          if ((target.dataItem?.dataContext as GenericChartFields)?.selected) {
            target.setAll({
              strokeDasharray: [5, 5],
              strokeWidth: 2.5,
            });
            stroke = am5.color(0xffffff);
          }
          return stroke;
        }
      );
    });

    this.rule('RoundedRectangle').adapters.add(
      'tooltipHTML',
      (value: string | undefined, target: RoundedRectangle): string | undefined => {
        target.get('tooltip')?.setAll({
          dy: 5,
          pointerOrientation: 'horizontal',
        });
        return this.buildTooltipHTML(value, target);
      }
    );
  }

  private buildTooltipHTML(value: string | undefined, target: RoundedRectangle): string | undefined {
    if (value) {
      if ((target.dataItem?.component as ColumnSeries)?._settings?.name! === marketsSeriesName.baseCar) {
        return `<div style='z-index:10;font-family:BMWGroupTNCondensedPro-Regular; font-size: 16px; font-weight: 400;padding: 8px;background-color:#282f47; border-radius: 5px;'>
                    <div style='display:flex;justify-content:space-between;flex-direction:column'>
                        <div style='margin-bottom:10px'>
                            <span style='font-family:BMWGroupTNCondensedPro-Bold;font-weight: 700;margin-right:4px'>Model Code</span> ${
                              (target.dataItem!.dataContext as GenericChartFields).modelCode
                            }
                        </div>
                            <span>${(
                              target.dataItem!.dataContext as GenericChartFields
                            ).basePrice?.toLocaleString()}€</span>
                    </div>
                </div>`;
      } else {
        return `<div style='z-index:10;font-family:BMWGroupTNCondensedPro-Regular; font-size: 16px; font-weight: 400;padding: 8px;background-color:#282f47; border-radius: 5px;'>
                    <div style='display:flex;justify-content:space-between;flex-direction:column'>
                        <div style='margin-bottom:10px'>
                          <span style='font-family:BMWGroupTNCondensedPro-Bold;font-weight: 700;margin-right:4px'>Model Code</span> ${
                            (target.dataItem!.dataContext as GenericChartFields).modelCode
                          }
                        </div>
                          <span>${(
                            target.dataItem!.dataContext as GenericChartFields
                          ).typicallyEquippedPrice?.toLocaleString()}€</span>
                    </div>
                </div>`;
      }
    }
    return value;
  }

  protected setupLabelRules() {
    this.rule('Bullet').setAll({
      locationX: 0.33,
      locationY: 1,
    });

    this.rule('Label').setup = (target: am5.Label): void => {
      if (target.dataItem?.bullets) {
        target.setAll({
          centerX: -5,
          centerY: 15,
          forceHidden: (target.dataItem?.component as ColumnSeries)?._settings?.name! === marketsSeriesName.baseCar,
          layer: 0,
          rotation: -90,
          text: this.getMarketsCustomLabel(target.dataItem?.dataContext as GenericChartFields),
        });
      }
    };

    this.rule('Label').events.on('positionchanged', (event: { type: string; target: Label }): void => {
      // Set labels to not be clickable
      if (event.target?._privateSettings.htmlElement?.style.pointerEvents !== undefined) {
        event.target._privateSettings.htmlElement.style.pointerEvents = 'none';
      }
    });
  }

  private getMarketsCustomLabel(data: GenericChartFields): string {
    if (data.section === marketsSeriesName.baseMarket) {
      return `[bold fontSize: 24px fontFamily: BMWGroupTNCondensedPro-Bold]${
        data.basePrice ? data.basePrice.toLocaleString() + '€' : ''
      }[/]`;
    }

    const { positive: labelColorPositive, negative: labelColorNegative } = AppThemeColors['ftd-chart'];
    const netPriceColor: string = (data.basePriceVariation as number) > 0 ? labelColorPositive : labelColorNegative;
    const netPriceVariation: string =
      (data.basePriceVariation as number) > 0
        ? `+${Math.trunc((data.basePriceVariation as number) * 100)}%`
        : `${Math.trunc((data.basePriceVariation as number) * 100)}%`;
    return `[ fontSize: 15px fontFamily: BMWGroupTNCondensedPro-Regular]${data.basePrice?.toLocaleString()}€[/] [#${netPriceColor}]${netPriceVariation}[/]`;
  }
}
