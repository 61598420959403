import { Component, Inject, OnInit } from '@angular/core';
import { IPriceApprovalStatusChipModalItem } from './price-approval-status-chip-modal-item/price-approval-status-chip-modal-item.component';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { PricePointApprovalStatus } from '../../../enums/price-point-approval-status.enum';

@Component({
  selector: 'app-price-approval-status-chip-modal',
  styleUrls: ['./price-approval-status-chip-modal.component.scss'],
  templateUrl: './price-approval-status-chip-modal.component.html',
})
export class PriceApprovalStatusChipModalComponent implements OnInit {
  approvalStatus!: PricePointApprovalStatus;

  approvalStatusChipMap = {
    [PricePointApprovalStatus.proposed_to_pricing_lead]: {
      description: 'Pricing Manager has proposed price points. Transfer to main scenario.',
      showDivider: true,
      status: PriceApprovalChipInModalStatus.pending,
      stepNumber: 1,
      title: 'Submitted by Pricing Manager',
    } as IPriceApprovalStatusChipModalItem,
    [PricePointApprovalStatus.proposed_to_governance_board]: {
      description: 'Pricing Team Lead has approved price points.',
      showDivider: true,
      status: PriceApprovalChipInModalStatus.not_available,
      stepNumber: 2,
      title: 'Approved by Pricing Lead',
    } as IPriceApprovalStatusChipModalItem,
    [PricePointApprovalStatus.approved_by_1]: {
      description: 'Controlling has approved price points as second governance instance.',
      showDivider: true,
      status: PriceApprovalChipInModalStatus.not_available,
      stepNumber: 3,
      title: 'Approved by Controlling',
    } as IPriceApprovalStatusChipModalItem,
    [PricePointApprovalStatus.approved_by_2]: {
      description:
        'Prices have been released to SPRINT (and approved by C1 if necessary). Governance process is completed.',
      showDivider: false,
      status: PriceApprovalChipInModalStatus.not_available,
      stepNumber: 4,
      title: 'Released to SPRINT/C1 approved',
    } as IPriceApprovalStatusChipModalItem,
  };

  constructor(@Inject(MAT_DIALOG_DATA) public data: IPriceApprovalStatusChipModalData) {
    this.approvalStatus = data.approvalStatus;
  }

  ngOnInit(): void {
    this.setApprovalStatusChips();
  }

  private setApprovalStatusChips(): void {
    switch (this.approvalStatus) {
      case PricePointApprovalStatus.proposed_to_pricing_lead:
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_pricing_lead].status =
          PriceApprovalChipInModalStatus.done;
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_governance_board].status =
          PriceApprovalChipInModalStatus.pending;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_1].status =
          PriceApprovalChipInModalStatus.not_available;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_2].status =
          PriceApprovalChipInModalStatus.not_available;
        break;
      case PricePointApprovalStatus.proposed_to_governance_board:
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_pricing_lead].status =
          PriceApprovalChipInModalStatus.done;
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_governance_board].status =
          PriceApprovalChipInModalStatus.approved;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_1].status =
          PriceApprovalChipInModalStatus.pending;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_2].status =
          PriceApprovalChipInModalStatus.not_available;
        break;
      case PricePointApprovalStatus.approved_by_1:
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_pricing_lead].status =
          PriceApprovalChipInModalStatus.done;
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_governance_board].status =
          PriceApprovalChipInModalStatus.approved;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_1].status =
          PriceApprovalChipInModalStatus.approved;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_2].status =
          PriceApprovalChipInModalStatus.pending;
        break;
      case PricePointApprovalStatus.approved_by_2:
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_pricing_lead].status =
          PriceApprovalChipInModalStatus.done;
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_governance_board].status =
          PriceApprovalChipInModalStatus.approved;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_1].status =
          PriceApprovalChipInModalStatus.approved;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_2].status =
          PriceApprovalChipInModalStatus.approved;
        break;
      case PricePointApprovalStatus.comments_only:
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_pricing_lead].status =
          PriceApprovalChipInModalStatus.refused;
        this.approvalStatusChipMap[PricePointApprovalStatus.proposed_to_governance_board].status =
          PriceApprovalChipInModalStatus.not_available;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_1].status =
          PriceApprovalChipInModalStatus.not_available;
        this.approvalStatusChipMap[PricePointApprovalStatus.approved_by_2].status =
          PriceApprovalChipInModalStatus.not_available;
        break;
    }
  }

  get stepList(): IPriceApprovalStatusChipModalItem[] {
    return Object.values(this.approvalStatusChipMap);
  }
}

export interface IPriceApprovalStatusChipModalData {
  approvalStatus: PricePointApprovalStatus;
}

export enum PriceApprovalChipInModalStatus {
  pending = 'Pending',
  done = 'Done',
  approved = 'Approved',
  refused = 'Refused',
  not_available = 'Not available',
}
