export enum GranularityPropName {
  BRAND = 'brand',
  SEGMENT = 'segment',
  SERIES = 'series',
  E_SERIES = 'eSeries',
  ESERIES = 'eSeries',
  MODEL = 'model',
  POWERTRAIN = 'powertrain',
  MODEL_CODE = 'modelCode',
}
