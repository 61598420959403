<div id="matrix-view-container" *ngIf="loadTable" [ngClass]="!showTableHeader ? 'matrix-container-hidden-header' : ''">
  <div id="matrix-view-table-responsive" [ngClass]="!showTableHeader ? 'matrix-hidden-header' : ''">
    <table aria-describedby="Matrix View" id="matrix-view-table" mat-table [dataSource]="dataSource">
      <!-- Level 1 Header Rows -->
      <ng-container
        *ngFor="let mainColumn of headerColumnsLevelOne; let idx = index; trackBy: trackById"
        [matColumnDef]="mainColumn.id"
        sticky="{{ mainColumn.isSticky }}"
      >
        <th
          class="min-px-100"
          [attr.col-identifier]="mainColumn.technicalName"
          mat-header-cell
          *matHeaderCellDef
          [attr.colspan]="getColSpanForColumnLevelOneByColumnId(mainColumn.id)"
          attr.data-test="{{ 'matrix-view-l1-header-' + mainColumn.displayName.toLowerCase().replace(' ', '-') }}"
        >
          <span class="header-text capitalize">{{ mainColumn.displayName }}</span>
        </th>
      </ng-container>

      <tr class="header-row" mat-header-row *matHeaderRowDef="headerColumnsLevelOneIds; sticky: false"></tr>
      <!-- Level 2 Header Rows -->
      <ng-container
        *ngFor="let subColumn of headerColumnsLevelTwo; let idx = index; trackBy: trackById"
        [matColumnDef]="subColumn.id"
        sticky="{{ subColumn.isSticky }}"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [class]="subColumn.columnType!"
          [attr.col-identifier]="subColumn.technicalName"
          [attr.colspan]="countTotalNotHiddenSubColumnsByParentColumn(subColumn)"
          attr.data-test="{{ 'matrix-view-l2-header-' + subColumn.displayName.toLowerCase().replace(' ', '-') }}"
        >
          <div
            (mouseover)="disableColumnHover()"
            fxLayout="row"
            fxLayoutAlign="space-between center"
            class="header-content"
          >
            <span class="header-text capitalize">
              {{ subColumn.displayName }}
            </span>
            <button
              aria-label="Toggle Expand/Collapse Column"
              *ngIf="subColumn.displayName && subColumn.children && subColumn.children.length > 0"
              mat-icon-button
              color="primary"
              (click)="toggleAllColumnsBelongingToSameSection(subColumn)"
            >
              <mat-icon>
                {{ subColumn.isExpandButtonForColumnsCollapsed ? 'add_circle_outline' : 'remove_circle_outline' }}
              </mat-icon>
            </button>
          </div>
        </th>
      </ng-container>

      <tr class="header-row" mat-header-row *matHeaderRowDef="headerColumnsLevelTwoIds; sticky: true"></tr>

      <!-- Level 3 Header Rows -->
      <ng-container
        *ngFor="let subSubColumn of headerColumnsLevelThree(); let idx = index; trackBy: trackById"
        [matColumnDef]="subSubColumn.id"
        sticky="{{ subSubColumn.isSticky }}"
      >
        <th
          mat-header-cell
          *matHeaderCellDef
          [attr.col-identifier]="subSubColumn.technicalName"
          (mouseover)="columnHeaderHover(subSubColumn)"
          [class]="subSubColumn.columnType!"
          [ngClass]="{ 'default-visible': subSubColumn.position === 0 }"
          [class.highlight-column]="subSubColumn.displayName && hoveredColumn === subSubColumn"
          [class.header-cell]="subSubColumn.displayName"
          attr.data-test="{{ 'matrix-view-l3-header-' + subSubColumn.technicalName }}"
        >
          <app-mv-expanded-tooltip-column-header [columnData]="subSubColumn"></app-mv-expanded-tooltip-column-header>
        </th>
        <td
          #hoverColumn
          mat-cell
          *matCellDef="let element; let idx = index"
          [class]="subSubColumn.columnType"
          (mouseover)="columnHover(subSubColumn)"
          [class.highlight-column]="subSubColumn.displayName && hoveredColumn === subSubColumn"
        >
          <!-- HERE COMES THE MAIN MATRIX VIEW COLUMN COMPONENT -->
          <app-mv-column-wrapper
            [index]="idx"
            [columnConfig]="subSubColumn"
            [dataSourceItem]="element"
            attr.data-test="{{ 'matrix-view-column-' + subSubColumn.technicalName }}"
          >
          </app-mv-column-wrapper>
        </td>
      </ng-container>

      <tr
        class="header-row"
        [ngClass]="{ expanded: columnsExpanded }"
        mat-header-row
        *matHeaderRowDef="headerColumnsLevelThreeIds; sticky: true"
      ></tr>

      <!--DATA ROW DEF-->
      <tr
        mat-row
        *matRowDef="let dataEntry; columns: headerColumnsLevelThreeIds"
        [class.hidden-row]="dataEntry.isVisible === false"
        id="{{ dataEntry?.config?.id }}"
        class="data-row"
        attr.data-test="{{
          'matrix-view-row-' +
            dataEntry.granularity.type +
            '-' +
            dataEntry.granularity.displayName.toLowerCase().replace(' ', '-')
        }}"
      ></tr>
    </table>
  </div>
</div>
<div class="no-highlight-column-trigger" (mouseover)="disableColumnHover()"></div>
