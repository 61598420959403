/* eslint-disable sort-keys-fix/sort-keys-fix */
import { ApolloQueryResult } from '@apollo/client/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Brand } from '../../../matrix-view/models/brand.model';
import {
  EmptyFilterDataError,
  FetchFilterDataApiError,
  MapGranularityDataError,
} from 'src/app/core/error-handling/errors/error-functions';
import { FilterDisplayName } from '../../../matrix-view/enums/filter-enum';
import {
  FilterKey,
  IFilterIdsWithPriceFilled,
  IFilterValueGroupedOptions,
  IFilterValueOptions,
  IFilters,
  IGranularityDto,
} from '../../../matrix-view/models/api.model';
import { FiltersList, IGranularity, ISelectedFiltersForm } from '../../../matrix-view/models/app.model';
import { FtdDropdownOptionsUtils } from '../../utils/ftd-dropdown-options.utils';
import { GranularityType } from '../../../matrix-view/enums/granularity-type.enum';
import { GranularityUtils } from '../../../matrix-view/utils/granularity/granularity.utils';
import {
  IFilter,
  IFilterScenarioDataByPriceEditorGQL,
  IFilterScenarioDataByPriceEditorQuery,
  IFiltersGQL,
  IFiltersQuery,
} from 'src/app/graphql/services/gql-api.service';
import { IFtdDropdownOption } from '../../models/ftd-dropdown-option.model';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { error } from 'src/app/core/error-handling/functional-error-handling/error';
import groupBy from 'lodash.groupby';
import sortBy from 'lodash.sortby';
import uniq from 'lodash.uniq';
import uniqBy from 'lodash.uniqby';

export type FilterItemsResponse = IFiltersQuery['filters'];
type FilterItemsResponseKey = string &
  keyof Omit<IFilter, 'hash' | 'id' | 'level' | 'resultingPath' | 'posInPath' | 'isVisible'>;

@Injectable({
  providedIn: 'root',
})
export class SideFiltersService {
  private _baseFilterData!: FilterItemsResponse;
  private _granularityList: BehaviorSubject<IGranularity[]> = new BehaviorSubject<IGranularity[]>([]);

  /**
   * Transform data structure for UI for dropdown rendering.
   */
  transformOptionsForView(
    filterData: IFilters,
    marketsOptions: IFtdDropdownOption<string>[]
  ): Map<string, FiltersList> {
    const filtersList: Map<string, FiltersList> = new Map();
    const defaultDisplayType = 'multi-grouped';

    // Add default market filter to list of filters.
    filtersList.set('market', {
      isGrouped: false,
      label: FilterDisplayName.market,
      options: marketsOptions,
      type: 'default',
    });

    for (const filter in filterData) {
      const filterList = filterData[filter as FilterKey];
      const filterGroupedOptionsList = filterList as IFilterValueGroupedOptions;
      const filterOptionsList = (filterList as IFilterValueOptions).options;

      if (filterList.isGrouped) {
        filtersList.set(filter, {
          groupedBy: filterGroupedOptionsList.groupedBy,
          groupedOptions: Object.keys(filterGroupedOptionsList.groupedOptions).map(
            (groupLabel: string, index: number) => ({
              id: index,
              label: groupLabel,
              options: FtdDropdownOptionsUtils.buildOptionsWithObjectValues(
                filterGroupedOptionsList.groupedOptions[groupLabel] || [],
                'displayName'
              ),
            })
          ),
          isGrouped: true,
          label: this.getFilterDisplayName(filter),
          type: defaultDisplayType,
        });
      } else {
        filtersList.set(filter, {
          id: filter,
          isGrouped: false,
          label: this.getFilterDisplayName(filter),
          options: FtdDropdownOptionsUtils.buildOptionsWithObjectValues(filterOptionsList || [], 'displayName'),
          type: 'multi',
        });
      }
    }

    return filtersList;
  }

  /**
   * Get filter display names from enum.
   * @param key Enum key.
   * @returns Enum value.
   */
  private getFilterDisplayName(key: string): FilterDisplayName {
    return FilterDisplayName[key as keyof typeof FilterDisplayName];
  }

  /**
   * GranularityList
   */
  granularityList(): Observable<IGranularity[]> {
    return this._granularityList.asObservable();
  }

  /**
   * @constructor
   * @param getFiltersGQL
   * @param filterScenarioDataByPriceEditorGQL
   */
  constructor(
    private getFiltersGQL: IFiltersGQL,
    private filterScenarioDataByPriceEditorGQL: IFilterScenarioDataByPriceEditorGQL
  ) {}

  /**
   * Get filter values from filter graphql api.
   * @param market Market value.
   * @param brands
   * @returns  List of filter values.
   */
  getFiltersForMarket(market: string, brands: string[]): Observable<IFilters> {
    return this.getFiltersGQL
      .fetch({
        brands,
        market,
      })
      .pipe(
        map((res) => this.transformToFiltersDomainObject(res.data.filters)),
        catchError((error) => {
          if (error.graphqlErrors || error.networkError) {
            return throwError(FetchFilterDataApiError);
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * Get filter values from filter graphql api.
   * @param market Market value.
   * @param brands
   * @returns  List of filter values.
   */
  getFiltersForMarketCrosschecks(market: string, brands: string[]): Observable<IFilters> {
    return this.getFiltersGQL
      .fetch({
        brands,
        market,
      })
      .pipe(
        map((res) => this.transformToFiltersDomainObject(res.data.filters, true)),
        catchError((error) => {
          if (error.graphqlErrors || error.networkError) {
            return throwError(FetchFilterDataApiError);
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * GetFiltersWithPriceEditorFilled
   * @param market
   * @param scenarioId
   * @param brands
   */
  getFiltersWithPriceEditorFilled(
    market: string,
    scenarioId: string,
    brands: string[]
  ): Observable<IFilterIdsWithPriceFilled[]> {
    return this.filterScenarioDataByPriceEditorGQL
      .fetch({
        market,
        scenarioId,
        brands,
      })
      .pipe(
        map((response: ApolloQueryResult<IFilterScenarioDataByPriceEditorQuery>) => {
          return response.data.priceFilledFilterIds.map((item) => ({ filterId: item?.filterId! }));
        })
      );
  }

  /**
   * Get filter values for initial render.
   * @returns Filter object of type {@link IFilters}
   */
  getDefaultFilters(): IFilters {
    return {
      brand: this.transformFilterDataToObject([], 'brand'),
      segment: this.transformFilterDataToGroupedObject('segment', 'brand'),
      series: this.transformFilterDataToGroupedObject('series', 'brand'),
      eSeries: this.transformFilterDataToGroupedObject('eSeries', 'brand'),
      powertrain: this.transformFilterDataToGroupedObject('powertrain', 'brand'),
      model: this.transformFilterDataToGroupedObject('model', 'eSeries'),
    } as IFilters;
  }

  get baseFilterData(): FilterItemsResponse {
    return this._baseFilterData;
  }

  /**
   * Transform & map data to Filter interface.
   * @param filtersData Data returned from the API.
   * @param includeModelCode
   * @returns Filter object.
   */
  private transformToFiltersDomainObject(
    filtersData: FilterItemsResponse,
    includeModelCode: boolean = false
  ): IFilters {
    if (!filtersData) {
      throw error(EmptyFilterDataError);
    }
    filtersData = sortBy(
      filtersData,
      ['brand', 'series', 'eSeries', 'segment', 'model'],
      ['asc', 'asc', 'asc', 'asc', 'asc']
    );

    const hierarchicalGranularityData: any = filtersData.map((fl) => {
      const type = GranularityUtils.getGranularityType(fl);
      const typeKey: string = GranularityUtils.getGranularityPropertyName(type);

      return {
        ...fl,
        childrenType: GranularityUtils.getChildrenGranularityType({ type } as IGranularity),
        displayName: fl[typeKey as FilterItemsResponseKey],
        type,
      };
    });

    this._baseFilterData = [...hierarchicalGranularityData];

    const granularities = hierarchicalGranularityData.filter(
      (item: any): boolean => item.type !== GranularityType.ESERIES
    );

    this._granularityList.next(granularities);

    // Group data initially to avoid grouping multiple times.
    const groupByBrand = this.groupByColumn(hierarchicalGranularityData, 'brand');
    const groupByESeries = this.groupByColumn(hierarchicalGranularityData, 'eSeries');
    const groupByModel = this.groupByColumn(hierarchicalGranularityData, 'model');
    const groupByModelCode = this.groupByColumn(hierarchicalGranularityData, 'modelCode');

    if (!groupByBrand || !groupByESeries || !groupByModel || !groupByModelCode) {
      throw error(MapGranularityDataError);
    }
    return includeModelCode
      ? ({
          brand: this.transformFilterDataToObject(hierarchicalGranularityData, 'brand'),
          segment: this.transformFilterDataToGroupedObject('segment', 'brand', groupByBrand, {}),
          series: this.transformFilterDataToGroupedObject('series', 'brand', groupByBrand, {}),
          eSeries: this.transformFilterDataToGroupedObject('eSeries', 'brand', groupByBrand, {}),
          model: this.transformFilterDataToGroupedObject('model', 'eSeries', groupByESeries, groupByBrand),
          powertrain: this.transformFilterDataToGroupedObject('powertrain', 'brand', groupByBrand, {}),
          modelCode: this.transformFilterDataToGroupedObject('modelCode', 'model', groupByModel, groupByBrand),
        } as IFilters)
      : ({
          brand: this.transformFilterDataToObject(hierarchicalGranularityData, 'brand'),
          segment: this.transformFilterDataToGroupedObject('segment', 'brand', groupByBrand, {}),
          series: this.transformFilterDataToGroupedObject('series', 'brand', groupByBrand, {}),
          eSeries: this.transformFilterDataToGroupedObject('eSeries', 'brand', groupByBrand, {}),
          powertrain: this.transformFilterDataToGroupedObject('powertrain', 'brand', groupByBrand, {}),
          model: this.transformFilterDataToGroupedObject('model', 'eSeries', groupByESeries, groupByBrand),
        } as IFilters);
  }

  /**
   * Transform filters of type grouped to {@link IFilterValueGroupedOptions} data structure.
   * @param filterKey Filter property name
   * @param groupByColumn Group by property name
   * @param groupedItems Grouped items dictionary.
   * @param groupedBrandItems Items grouped by brand.
   * @returns Filter object of type {@link IFilterValueGroupedOptions}
   */
  private transformFilterDataToGroupedObject(
    filterKey: string & FilterItemsResponseKey,
    groupByColumn: string,
    groupedItems: _.Dictionary<FilterItemsResponse> = {},
    groupedBrandItems: _.Dictionary<FilterItemsResponse> = {}
  ): IFilterValueGroupedOptions {
    const groupedOptions: _.Dictionary<IGranularityDto[]> = {};

    try {
      const groupedBrandItemsKeys = Object.keys(groupedBrandItems);
      const groupedItemKeys = Object.keys(groupedItems);

      // When brand names needs to be added as part of the group label.
      if (groupedBrandItemsKeys.length) {
        groupedBrandItemsKeys.forEach((brand) => {
          groupedItemKeys.forEach((groupColumnKey) => {
            const filteredData = this.filterItemsBasedOnGranularity(groupedItems[groupColumnKey], filterKey);
            const uniqItemsByFilterKey = uniqBy(filteredData, filterKey);
            const filterByBrand = uniqItemsByFilterKey?.filter((groupedItem) => groupedItem?.brand === brand);
            if (filterByBrand?.length) {
              groupedOptions[`${brand} | ${groupColumnKey}`] = this.transformFilterValueProps(
                filterByBrand,
                filterKey,
                groupByColumn
              );
            }
          }, this);
        }, this);
      } else {
        groupedItemKeys.forEach((value) => {
          const filteredData = this.filterItemsBasedOnGranularity(groupedItems[value], filterKey);
          groupedOptions[value] = this.transformFilterValueProps(
            uniqBy(filteredData, filterKey),
            filterKey,
            groupByColumn
          );
        });
      }

      return {
        displayName: filterKey,
        groupedBy: groupByColumn,
        groupedOptions,
        id: filterKey,
        isGrouped: true,
      };
    } catch (e) {
      throw error(MapGranularityDataError);
    }
  }

  /**
   * Transform filters to {@link IFilterValueOptions} data structure.
   * @param items Filters items data from the API.
   * @param filterKey Filter property / key name. e.g brand, series.
   * @returns Filter object of type {@link IFilterValueOptions}
   */
  private transformFilterDataToObject(
    items: FilterItemsResponse,
    filterKey: string & FilterItemsResponseKey
  ): IFilterValueOptions {
    const filterType = GranularityUtils.getGranularityTypeFromPropertyName(filterKey);
    try {
      return {
        displayName: filterKey,
        id: filterKey,
        isGrouped: false,
        options: uniqBy(this.filterItemsBasedOnGranularity(items, filterKey), filterKey)?.map((filter) => ({
          ...filter,
          childrenType: GranularityUtils.getChildrenGranularityType({ type: filterType } as any),
          displayName: filter ? filter[filterKey] : '',
          type: filterType,
        })),
        type: filterType,
      } as IFilterValueOptions;
    } catch (e) {
      throw error(MapGranularityDataError);
    }
  }

  /**
   * Build Filter value dropdown options list.
   * @param filters Filter data from the API.
   * @param filterKey Filter property / key name. e.g brand, series.
   * @param groupColumn Group by property name
   * @returns List of dropdown options.
   */
  private transformFilterValueProps(
    filters: FilterItemsResponse,
    filterKey: FilterItemsResponseKey,
    groupColumn: string
  ): IGranularityDto[] {
    try {
      const filterType = GranularityUtils.getGranularityTypeFromPropertyName(filterKey);
      return (
        filters?.map((filter) => ({
          ...(filter ? filter : ({} as IGranularityDto)),
          childrenType: GranularityUtils.getChildrenGranularityType({ type: filterType } as any),
          displayName: filter[filterKey] || '',
          group: groupColumn,
          type: filterType,
        })) || ([] as IGranularityDto[])
      );
    } catch (e) {
      throw error(MapGranularityDataError);
    }
  }

  /**
   * Group data in an Array based on the key.
   * @param filtersList Filter items array.
   * @param groupByColumn GroupBy column key / property name.
   * @returns Dictionary of grouped items.
   */
  private groupByColumn(filtersList: FilterItemsResponse, groupByColumn: string): _.Dictionary<FilterItemsResponse> {
    return groupBy(filtersList, groupByColumn);
  }

  private filterItemsBasedOnGranularity(items: FilterItemsResponse, key: string): FilterItemsResponse {
    switch (key) {
      case 'brand':
        return items.filter((fl: IFilter) => GranularityUtils.isBrand(fl));
      case 'segment':
        return items.filter((fl: IFilter) => GranularityUtils.isSegment(fl));
      case 'series':
        return items.filter((fl: IFilter) => GranularityUtils.isSeries(fl));
      case 'eSeries':
        return items.filter((fl: IFilter) => GranularityUtils.isEseries(fl));
      case 'model':
        return items.filter((fl: IFilter) => GranularityUtils.isModel(fl));
      case 'modelCode':
        return items.filter((fl: IFilter) => GranularityUtils.isModelCode(fl));
      case 'powertrain':
        return items.filter((fl: IFilter) => fl.powertrain.toLowerCase().length);
      default:
        return items;
    }
  }

  /**
   * Checks whether the selected filter options match to granularities data and returns the selected data to build the
   * dataset or sends a plain array and notifies user that search has matched no data.
   * @param filtersFormValue
   * @param priceEditorFilledIds
   * @returns SelectedFiltersForm
   */
  public ifFiltersConditions(
    filtersFormValue: ISelectedFiltersForm,
    priceEditorFilledIds: IFilterIdsWithPriceFilled[]
  ): ISelectedFiltersForm {
    const formValue: ISelectedFiltersForm = filtersFormValue,
      BRAND: string[] = [],
      SEGMENT: string[][] = [],
      SERIES: string[][] = [],
      E_SERIES: string[][] = [],
      POWERTRAIN: string[][] = [],
      MODEL: string[][] = [],
      BMW_DATASET: IGranularity[] = [],
      MINI_DATASET: IGranularity[] = [];

    for (const key in formValue) {
      if (key.toUpperCase() in GranularityType) {
        const selectedFilters: IGranularity[] = formValue[key as keyof ISelectedFiltersForm] as Array<IGranularity>;
        if (selectedFilters.length) {
          selectedFilters.forEach((granularity: IGranularity) => {
            switch (granularity.type) {
              case GranularityType.BRAND:
                BRAND.push(granularity.brand);
                break;
              case GranularityType.SEGMENT:
                SEGMENT.push([granularity.brand, granularity.segment]);
                break;
              case GranularityType.SERIES:
                SERIES.push([granularity.brand, granularity.series]);
                break;
              case GranularityType.ESERIES:
                E_SERIES.push([granularity.brand, granularity.eSeries]);
                break;
              case GranularityType.E_SERIES:
                E_SERIES.push([granularity.brand, granularity.eSeries]);
                break;
              case GranularityType.POWERTRAIN:
                POWERTRAIN.push([granularity.brand, granularity.powertrain]);
                break;
              case GranularityType.MODEL:
                MODEL.push([granularity.brand, granularity.model, granularity.eSeries]);
                break;
            }
          });
        }
      }
    }

    this._granularityList.value.forEach((granularity) => {
      if (BRAND.includes(granularity.brand)) {
        granularity.brand === Brand.BMW ? BMW_DATASET.push(granularity) : MINI_DATASET.push(granularity);
      }
    });

    const runFiltersByDataset = (filters: IGranularity[], selectedBrand: Brand) => {
      if (SEGMENT.length && SEGMENT.filter(([brand]) => brand === selectedBrand).length) {
        const selectedSegments: IGranularity[] = [];
        SEGMENT.filter(([brand]) => brand === selectedBrand).forEach(([brand, segment]) => {
          filters
            .filter((granularity) => granularity.segment === segment && granularity.brand === brand)
            .forEach((item) => selectedSegments.push(item));
        });
        filters = filters.filter((granularity) => selectedSegments.includes(granularity));
      }

      if (SERIES.length && SERIES.filter(([brand]) => brand === selectedBrand).length) {
        const selectedSeries: IGranularity[] = [];
        SERIES.filter(([brand]) => brand === selectedBrand).forEach(([brand, series]) => {
          filters
            .filter((granularity) => granularity.series === series && granularity.brand === brand)
            .forEach((item) => selectedSeries.push(item));
        });
        filters = filters.filter((granularity) => selectedSeries.includes(granularity));
      }

      if (E_SERIES.length && E_SERIES.filter(([brand]) => brand === selectedBrand).length) {
        const selectedESeries: IGranularity[] = [];
        E_SERIES.filter(([brand]) => brand === selectedBrand).forEach(([brand, eSeries]) => {
          filters
            .filter((granularity) => granularity.eSeries === eSeries && granularity.brand === brand)
            .forEach((item) => selectedESeries.push(item));
        });
        filters = filters.filter((granularity) => selectedESeries.includes(granularity));
      }

      if (POWERTRAIN.length && POWERTRAIN.filter(([brand]) => brand === selectedBrand).length) {
        const selectedPowertrain: IGranularity[] = [];
        const modelsByPowertrain: IGranularity[] = [];
        POWERTRAIN.filter(([brand]) => brand === selectedBrand).forEach(([brand, powertrain]) => {
          filters
            .filter((granularity) => granularity.powertrain === powertrain && granularity.brand === brand)
            .forEach((item) => {
              selectedPowertrain.push(item);
              filters
                .filter(
                  (modelGranularity) =>
                    modelGranularity.model === item.model &&
                    modelGranularity.eSeries === item.eSeries &&
                    modelGranularity.type === GranularityType.MODEL
                )
                .forEach((model) => !modelsByPowertrain.includes(model) && modelsByPowertrain.push(model));
            });
        });
        filters = filters.filter(
          (granularity) => selectedPowertrain.includes(granularity) || modelsByPowertrain.includes(granularity)
        );
      }

      if (MODEL.length && MODEL.filter(([brand]) => brand === selectedBrand).length) {
        const selectedModel: IGranularity[] = [];
        MODEL.filter(([brand]) => brand === selectedBrand).forEach(([brand, model, eSeries]) => {
          filters
            .filter(
              (granularity) =>
                granularity.model === model && granularity.brand === brand && granularity.eSeries === eSeries
            )
            .forEach((item) => selectedModel.push(item));
        });
        filters = filters.filter((granularity) => selectedModel.includes(granularity));
      }

      if (filtersFormValue.priceEditorFilled) {
        const editorFilledIds = priceEditorFilledIds.map((item) => item.filterId);
        const editorFilled = filters.filter(
          (item) => item.type === GranularityType.MODEL_CODE && editorFilledIds.includes(item.id)
        );
        if (!editorFilled.length) {
          filters = [];
        }
      }

      return filters;
    };

    const filters: IGranularity[] = [];

    BMW_DATASET.length && filters.push(...runFiltersByDataset(BMW_DATASET, Brand.BMW));
    MINI_DATASET.length && filters.push(...runFiltersByDataset(MINI_DATASET, Brand.MINI));

    return {
      brand: uniq(filters.filter((granularity) => granularity.type === GranularityType.BRAND)),
      eSeries: uniq(filters.filter((granularity) => granularity.type === GranularityType.E_SERIES)),
      market: filtersFormValue.market,
      model: uniq(filters.filter((granularity) => granularity.type === GranularityType.MODEL)),
      originalSelectedValues: formValue,
      planningHorizon: filtersFormValue.planningHorizon,
      powertrain: uniq(filters.filter((granularity) => granularity.type === GranularityType.POWERTRAIN)),
      priceEditorFilled: filtersFormValue.priceEditorFilled,
      segment: uniq(filters.filter((granularity) => granularity.type === GranularityType.SEGMENT)),
      series: uniq(filters.filter((granularity) => granularity.type === GranularityType.SERIES)),
    };
  }
}
