import { AuthService } from './auth/services/auth.service';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { FtdFullscreenService } from './common/services/ftd-fullscreen/ftd-fullscreen.service';

@Component({
  selector: 'app-root',
  styleUrls: ['./app.component.scss'],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(
    public authService: AuthService,
    @Inject(DOCUMENT) private document: Document,
    public fullscreenService: FtdFullscreenService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.renderer.addClass(this.document.body, 'ftd-theme');
    this.authService.login();
  }
}
