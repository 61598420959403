<ftd-tab-group>
  <ftd-tab>
    <ftd-tab-label
      tabLabel
      [title]="'Subsidies & Taxation'"
      [info]="true"
      [infoText]="tooltips?.subsidiesTaxation"
    ></ftd-tab-label>
    <app-subsidies-and-taxation></app-subsidies-and-taxation>
  </ftd-tab>
</ftd-tab-group>
