export const currencyMapHelper: Map<string, string> = new Map<string, string>([
  ['BE', '€'],
  ['NL', '€'],
  ['LU', '€'],
  ['DE', '€'],
  ['CH', 'Fr'],
  ['IT', '€'],
  ['FR', '€'],
  ['ES', '€'],
  ['PT', '€'],
  ['AT', '€'],
  ['PL', 'zł'],
  ['GR', '€'],
  ['HU', 'Ft -tól'],
  ['CZ', 'Kč'],
  ['RO', '€'],
  ['SK', '€'],
  ['BG', 'лв'],
  ['SI', '€'],
  ['SE', 'kr'],
  ['FI', '€'],
  ['NO', 'kr'],
  ['DK', 'kr'],
  ['UK', '£'],
  ['GB', '£'],
  ['IE', '€'],
]);
