import { Base64Utils } from '../../utils/base64.utils';
import { IGranularityDto } from 'src/app/matrix-view/models/api.model';
import { Injectable } from '@angular/core';
import { Router, UrlSegment, UrlTree } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class FilterManagementService {
  filters: IGranularityDto = <IGranularityDto>{};

  /**
   * @constructor
   * @param router
   */
  constructor(private router: Router) {}

  /**
   * GetFilters
   * @return GranularityDto
   */
  getUrlFilters(): IGranularityDto {
    const parsedURL: UrlTree = this.router.parseUrl(this.router.url);
    const segments: UrlSegment[] = parsedURL.root.children.primary.segments;
    const idSegment: UrlSegment = segments.pop()!;
    const path: string = idSegment?.path;

    return Base64Utils.decodeAtobToJson(path);
  }

  /**
   * GetHashedFilter
   * @return string
   */
  getHashedFilter(): string {
    const parsedURL: UrlTree = this.router.parseUrl(this.router.url);
    const segments: UrlSegment[] = parsedURL.root.children.primary.segments;
    const idSegment: UrlSegment = segments.pop()!;
    return idSegment?.path;
  }
}
