<ng-container *ngIf="!isRequestProcessing">
  <app-discounts-header
    [discount]="discount"
    [context]="DISCOUNTS_CONFIG.context"
    [title]="DISCOUNTS_CONFIG.title"
    [isDraftsHidden]="DISCOUNTS_CONFIG.isDraftsHidden"
    [status]="DISCOUNTS_CONFIG.status"
    [isStatusHidden]="DISCOUNTS_CONFIG.isStatusHidden"
    [isCreateDiscountHidden]="DISCOUNTS_CONFIG.isCreateDiscountHidden"
    [isSaveHidden]="DISCOUNTS_CONFIG.isSaveHidden"
    [isSubmitHidden]="DISCOUNTS_CONFIG.isSubmitHidden"
    [isWithdrawHidden]="DISCOUNTS_CONFIG.isWithdrawHidden"
    [isRejectHidden]="DISCOUNTS_CONFIG.isRejectHidden"
    [isApproveHidden]="DISCOUNTS_CONFIG.isApproveHidden"
    [isReleaseHidden]="DISCOUNTS_CONFIG.isReleaseHidden"
    [isSaveDisabledTooltip]="DISCOUNTS_CONFIG.isSaveDisabledTooltip"
    [isSaveDisabled]="isSaveDisabled || isSaveLoading"
    (saveBtnClicked)="saveDiscount()"
    (discountSubmitted)="submitDiscount()"
    [isSaveLoading]="isSaveLoading"
  >
  </app-discounts-header>
  <mat-card id="discounts-mat-card">
    <mat-card-content id="discounts-mat-card-content">
      <div class="container">
        <app-discount-matrix-view-wrapper
          [discount]="discount"
          [title]="title"
          [mode]="mode"
        ></app-discount-matrix-view-wrapper>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
