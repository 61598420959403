import { Brand } from '../models/brand.model';
import { ICrosscheckDataResponse } from '../models/api.model';

export const CROSSCHECK_DATA_RESPONSE_MOCK: ICrosscheckDataResponse = {
  cross_check_results: {
    channelsTrafficLight: [0.123, 0.566],
    competitionTrafficLight: [0.3, 0.347],
    marketsTrafficLight: [0.3, 0.23],
    microMacroTrafficLight: [0.3, 0.75],
    portfolioTrafficLight: [0.9, 0.245],
    productionPipelineTrafficLight: [0.2, 0.6745],
    salesPipelineTrafficLight: [0.3, 0.127],
    stockTrafficLight: [0.7, 0.745],
  },
  granularities: [
    {
      brand: Brand.BMW,
      eSeries: 'ALL',
      id: '1',
      market: 'DE',
      model: 'ALL',
      modelCode: 'ALL',
      powertrain: 'ALL',
      segment: 'ALL',
      series: 'ALL',
    },
    {
      brand: Brand.MINI,
      eSeries: 'ALL',
      id: '2',
      market: 'DE',
      model: 'ALL',
      modelCode: 'ALL',
      powertrain: 'ALL',
      segment: 'ALL',
      series: 'ALL',
    },
  ],
  metadata: {
    // Number of months ahead
    creation_date: '20220101_222233',

    id: 'asdasdajlkn283741927341asd',

    // User ID, can be specified to "all".
    market: 'DE',

    // Creation of the scenario
    modification_time: '20220101_222233',

    planning_horizon: '12M',
    // Id of the requested scenario
    user_id: 'Q123123', //  Latest modification of it
  },
  settings: {},
};
