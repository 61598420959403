import { Component, Input } from '@angular/core';
import { MatrixColumnTechnicalName } from '../../../enums/matrix-column-technical-name.enum';

@Component({
  selector: 'app-mv-number-display',
  styleUrls: ['./mv-number-display.component.scss'],
  templateUrl: './mv-number-display.component.html',
})
export class MvNumberDisplayComponent {
  @Input() textHolder!: any;
  @Input() price?: boolean;
  @Input() technicalName?: MatrixColumnTechnicalName;
  @Input() digitsInfo: string = '';
}
