import { CLSMetric, FIDMetric, LCPMetric, onCLS, onFID, onLCP } from 'web-vitals';
import { IWebVitalsGQL, IWebVitalsMutationVariables, WebVitalsType } from '../../../graphql/services/gql-api.service';
import { Injectable } from '@angular/core';
import { MutationResult } from 'apollo-angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

/**
 * @Injectable
 */
@Injectable({
  providedIn: 'root',
})
/**
 * @class FtdWebVitalsService
 */
export class FtdWebVitalsService {
  /**
   * @constructor
   * @param webVitalsGQL
   */
  constructor(private webVitalsGQL: IWebVitalsGQL) {}

  /**
   * @public
   * logWebVitals
   */
  public logWebVitals(): void {
    this.webVitalsHandler();
  }

  /**
   * @private
   * WebVitalsHandler
   */
  private webVitalsHandler(): void {
    if (!this.cachedWebVitalsHelper().get()) {
      const self = this;

      const logWebVitals = (webVitalsArgs: CLSMetric | FIDMetric | LCPMetric): void => {
        const webVitals: IWebVitalsMutationVariables = {
          webVitals: {
            type: webVitalsArgs.name as WebVitalsType,
            value: webVitalsArgs.value,
          },
        };

        self.saveWebVitals(webVitals).subscribe((): void => {
          self.cachedWebVitalsHelper().set(true);
        });
      };

      onCLS(logWebVitals);
      onFID(logWebVitals);
      onLCP(logWebVitals);
    }
  }

  /**
   * CachedWebVitalsHelper
   * @get
   * @set
   * @private
   */
  private cachedWebVitalsHelper() {
    return {
      get: (): boolean => {
        const cachedFilters = localStorage.getItem(btoa('cachedFilters'))
          ? JSON.parse(atob(localStorage.getItem(btoa('cachedFilters'))!))
          : false;

        return localStorage.getItem(btoa('webVitals')) ? Boolean(cachedFilters) : false;
      },

      set: (value: boolean): void => {
        localStorage.setItem(btoa('webVitals'), btoa(JSON.stringify(value)));
      },
    };
  }

  /**
   * WebVitals function
   * @private
   * @param webVitalsMutationVariables
   */
  private saveWebVitals(webVitalsMutationVariables: IWebVitalsMutationVariables): Observable<string> {
    return this.webVitalsGQL.mutate(webVitalsMutationVariables).pipe(
      map((response: MutationResult) => {
        return response.data?.response as string;
      }),
      catchError((error): Observable<never> => {
        return throwError(error);
      })
    );
  }
}
