import { Component, Input } from '@angular/core';
import { MatrixColumnTechnicalName } from 'src/app/matrix-view/enums/matrix-column-technical-name.enum';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-discount-mv-price-display',
  styleUrls: ['./mv-price-display.component.scss'],
  templateUrl: './mv-price-display.component.html',
})
export class MvPriceDisplayComponent {
  @Input() priceHolder!: any;
  @Input() technicalName?: MatrixColumnTechnicalName;

  _currency: string = 'EUR';
  get currency(): string {
    return this._currency;
  }

  @Input() set currency(currency: string) {
    this._currency = currency;
    this.currencySymbol = getCurrencySymbol(this.currency, 'narrow');
  }

  currencySymbol: string = 'EUR';
}
