<app-crosschecks-chart-wrapper
  [context]="crosschecksChartContext"
  [skeletonTheme]="'RetailSales'"
  [filters]="filters"
  [crosscheckIndicationVisible]="true"
  [trafficLightStatus]="crosscheckIndication!"
  [trafficLightAdjStatus]="crosscheckIndicationForecasted!"
>
  <app-chart-filters
    (showValuesToggleEvent)="showValuesToggle($event)"
    [dropdownDisabled]="dropdownDisabled"
    [dropdownList]="dropdownList"
    [dropdownOptions]="dropdownOptions"
  ></app-chart-filters>

  <div class="container">
    <div class="chart-container">
      <app-ftd-line-chart
        *ngIf="chartData"
        [data]="chartData"
        [rootId]="'sales-pipeline-retail-sales'"
        [chartLegend]="chartLegend"
        [primaryUnitLabel]="'Units'"
        [isLabelValuesVisible]="isChartValuesVisible"
        [chartCustomTheme]="'RetailSalesCustomTheme'"
        [isSysDateIndicatorVisible]="true"
        [axisType]="ChartAxisType.CATEGORY"
        [isScrollBarVisible]="true"
      ></app-ftd-line-chart>
    </div>
    <ng-container *ngIf="scalarRetailSalesData">
      <div class="tile-container">
        <div class="grouped-tile-header">
          <app-ftd-chart-info-tile
            class="chart-tiles"
            *ngIf="scalarRetailSalesData.budgetVolumeRetail"
            [displayName]="'RETAIL BUDGET FY'"
            [displayValue]="scalarRetailSalesData.budgetVolumeRetail"
            [isExtended]="true"
          ></app-ftd-chart-info-tile>
        </div>
        <div class="grouped-tile-container">
          <app-ftd-chart-info-tile
            class="chart-tiles"
            *ngIf="scalarRetailSalesData.openVolumeYe"
            [displayName]="scalarRetailSalesData.openVolumeYe.displayName"
            [displayValue]="scalarRetailSalesData.openVolumeYe.value"
            [displayNameDetails]="scalarRetailSalesData.openVolumeYe.displayNameDetails"
            displaySubValue="{{ scalarRetailSalesData.openVolumeYe.ratioRetailOpenVolumeBudget }} %"
          ></app-ftd-chart-info-tile>

          <app-ftd-chart-info-tile
            class="chart-tiles"
            *ngIf="scalarRetailSalesData.expectedVolumeRetail"
            [displayName]="'RETAIL NCPE-ESTIMATION FY'"
            [displayValue]="scalarRetailSalesData.expectedVolumeRetail"
            [displayAbsValue]="scalarRetailSalesData.absDiffExpectedVolumeBudget"
            [displayRatioValue]="scalarRetailSalesData.ratioExpectedVolumeBudget"
            [displayNameDetailsColor]="setColorString(scalarRetailSalesData.absDiffExpectedVolumeBudget)"
          ></app-ftd-chart-info-tile>
        </div>
        <div class="grouped-tile-container">
          <app-ftd-chart-info-tile
            class="chart-tiles"
            *ngIf="scalarRetailSalesData.expectedVolumeAchievement"
            [displayName]="'ESTIMATED YTD TOTAL VOLUME'"
            [displayValue]="scalarRetailSalesData.expectedVolumeAchievement"
            displayNameDetails="{{ scalarRetailSalesData.ratioExpectedRetailBudget }}%"
          ></app-ftd-chart-info-tile>

          <app-ftd-chart-info-tile
            class="chart-tiles"
            *ngIf="scalarRetailSalesData.retailCount"
            [displayName]="'CUMULATED YTD TOTAL VOLUME'"
            [displayValue]="scalarRetailSalesData.retailCount"
            [displayAbsValue]="scalarRetailSalesData.absDiffCumulatedBudget"
            [displayRatioValue]="scalarRetailSalesData.ratioCumulatedBudget"
            [displayNameDetailsColor]="setColorString(scalarRetailSalesData.absDiffCumulatedBudget)"
          ></app-ftd-chart-info-tile>
        </div>
      </div>
    </ng-container>
  </div>

  <form [formGroup]="chartLegendForm">
    <app-crosschecks-chart-filter-group formControlName="retailSalesFields">
      <app-chart-filter-item
        *ngFor="let legendConfig of chartLegendConfigs"
        [value]="legendConfig.fieldName"
        [label]="legendConfig.label"
        [legendType]="legendConfig.type"
        [legendImagePath]="legendConfig.imagePath"
        [legendImageAlt]="legendConfig.imageAlt"
        [legendImageHeight]="legendConfig.legendImageHeight"
        [legendImageWidth]="legendConfig.legendImageWidth"
      >
      </app-chart-filter-item>
    </app-crosschecks-chart-filter-group>
  </form>
</app-crosschecks-chart-wrapper>
