import { AuthService } from '../../../../../../auth/services/auth.service';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DiscountMatrixViewDataService } from '../../../services/matrix-view-data/matrix-view-data.service';
import { FtdCommentComponent } from '../../../../../../common/components/ftd-comment/ftd-comment.component';
import { FtdModalSize } from '../../../../../../common/components/ftd-modal/ftd-modal-size.enum';
import { GovernanceService } from '../../../services/governance/governance.service';
import { IMatrixViewDataSourceItem } from '../../../models/matrix-view.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { CommentsAndNotesContext } from '../../../models/comments-and-notes.model';
import { ContextService } from '../../../services/context/context.service';
import { SubmitStatusModalComponent } from '../../../../../../scenario/modals/submit-status-modal/submit-status-modal.component';
import { Subscription } from 'rxjs';
import { User } from '../../../../../../auth/models/user.model';

@Component({
  selector: 'app-discount-mv-column-action',
  styleUrls: ['./mv-column-action.component.scss'],
  templateUrl: './mv-column-action.component.html',
})
export class MvColumnActionComponent implements OnInit, OnDestroy {
  @Input() isUserScenario!: boolean;
  @Input() dataSourceItem!: IMatrixViewDataSourceItem;
  public currentUser!: User | null;
  private subscriptions: Subscription[] = [];
  protected readonly CommentsAndNotesContext = CommentsAndNotesContext;

  constructor(
    private authService: AuthService,
    public dialog: MatDialog,
    public governanceService: GovernanceService,
    private matrixViewDataService: DiscountMatrixViewDataService,
    public contextService: ContextService
  ) {
    this.currentUser = this.authService.getLoggedInUser();
  }

  ngOnInit(): void {
    this.initUserListener();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription?.unsubscribe();
    });
  }

  private initUserListener(): void {
    const subscription: Subscription = this.authService
      .getLoggedInUserAsObservable()
      .subscribe((user: User | null) => (this.currentUser = user));
    this.subscriptions.push(subscription);
  }

  get selectedBrand() {
    return this.governanceService.getBrandFromMatrixViewDataSourceItem(this.dataSourceItem);
  }

  get isWithdrawStatusDisabled(): boolean {
    return !this.governanceService.isWithdrawStatusPricePointPossible(this.dataSourceItem, this.currentUser);
  }

  get isPromoteStatusDisabled(): boolean {
    return !this.governanceService.isPromoteStatusPricePointPossible(this.dataSourceItem, this.currentUser);
  }

  get isDemoteStatusDisabled(): boolean {
    return !this.governanceService.isDemoteStatusPricePointPossible(this.dataSourceItem, this.currentUser);
  }

  /**
   * SubmitStatus
   * @param status
   */
  submitStatus(status: 'promote_status' | 'demote_status' | 'withdraw_status'): void {
    const dialogRef: MatDialogRef<SubmitStatusModalComponent> = this.dialog.open(SubmitStatusModalComponent, {
      data: { matrixViewDataSourceItems: [this.dataSourceItem], status: status },
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription: Subscription = dialogRef!
      .afterClosed()
      .subscribe(
        (data: { requestStatus: string; status: 'promote_status' | 'demote_status' | 'withdraw_status' }): void => {
          if (data && data.requestStatus === 'success') {
            this.matrixViewDataService.refreshMatrixViewDataSubject$.next({
              granularitiesToRefreshData: [],
            });
          }
        }
      );
    this.subscriptions.push(subscription);
  }

  /**
   * OpenPricePointComments
   * @param context
   */
  openPricePointComments(context: CommentsAndNotesContext = CommentsAndNotesContext.COMMENTS): void {
    const dialogRef: MatDialogRef<FtdCommentComponent> = this.dialog?.open(FtdCommentComponent, {
      data: {
        comments:
          context === CommentsAndNotesContext.COMMENTS
            ? this.dataSourceItem.priceEditorData?.comments
            : this.dataSourceItem.priceEditorData?.notes,
        context: context,
        filterId: this.dataSourceItem.id,
        governancePricePointStatus: this.dataSourceItem.priceEditorData?.governancePricePointStatus,
        model: this.governanceService.getModelFromMatrixViewDataSourceItem(this.dataSourceItem),
        pricePointId: CommentsAndNotesContext.COMMENTS
          ? this.dataSourceItem.scenarioOutput?.governancePricePointId
          : this.dataSourceItem.scenarioOutput?.pricePoint?.id,
        scenarioId: this.contextService.discountId,
      },
      maxWidth: FtdModalSize.S,
      width: '90%',
    });

    const subscription: Subscription = dialogRef!.afterClosed().subscribe((): void => {
      this.matrixViewDataService.refreshMatrixViewDataSubject$.next({
        granularitiesToRefreshData: [this.dataSourceItem.granularity],
      });
    });
    this.subscriptions.push(subscription);
  }

  get commentButtonDisabled(): boolean {
    return [undefined, null, ''].includes(this.dataSourceItem?.scenarioOutput?.governancePricePointStatus);
  }

  get noteButtonDisabled(): boolean {
    return !this.dataSourceItem?.scenarioOutput?.pricePoint?.id;
  }
}
