import { AbstractService } from '../../../core/services/abstract.service';
import {
  Accessible,
  CrossCheckContextType,
  IMarketsCcTrafficLightsOverviewGQL,
  IMarketsCrossChecksGQL,
  IMarketsCrossChecksQuery,
  IMarketsTrafficLightsGQL,
  IPortfolioInternalPriceCcTrafficLightsOverviewGQL,
  IPortfolioInternalPriceGQL,
  IPortfolioInternalPriceQuery,
  IPortfolioTrafficLightsGQL,
  ISalesPipelineCcTrafficLightsOverviewGQL,
  ISalesPipelineCrossChecksGQL,
  ISalesPipelineDateRange,
  ISalesPipelineTrafficLightsGQL,
  ISalesPipelineTrafficLightsQuery,
  IStockAgeCcTrafficLightsOverviewGQL,
  IStockAgeCrosscheckData,
  IStockAgeGQL,
  IStockCoverageCcTrafficLightsOverviewGQL,
  IStockCoverageCrosscheckData,
  IStockCoverageGQL,
  IStockTrafficLightsGQL,
  ITrafficLight,
  SalesPipelineCrossCheckType,
  StockPriceLevel,
} from 'src/app/graphql/services/gql-api.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { CROSSCHECK_DATA_RESPONSE_MOCK } from '../../../matrix-view/mocks/crosschecks-data-response.mock';
import { CROSSCHECK_TOOLTIP_RESPONSE_MOCK } from '../../mocks/crosschecks-response.mock';
import {
  CrosschecksTrafficLightUIProps,
  ICalendarizedOrderBankDataResponse,
  ICrossCheckResultsDto,
  ICrosscheckDataResponse,
  IGranularityDto,
  IInternalPriceLadderResponse,
  IMarketsCrosscheckDataResponse,
  IOrderIntakeApiResponse,
  IRetailSalesData,
  ITooltipDataResponse,
  TrafficLightsResponse,
} from '../../../matrix-view/models/api.model';
import {
  FetchCrosscheckDataApiError,
  MapCrosscheckDataError,
} from '../../../core/error-handling/errors/error-functions';
import { ICrossCheckData, ICrossCheckResults } from '../../../matrix-view/models/app.model';
import { Injectable, Injector } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { STOCK_MIX_CHART_DATA_MOCK_RESPONSE } from '../../../common/components/ftd-charts/mocks/chart-data-mock';
import { TrafficLightsColor } from '../../types/traffic-lights.type';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { error } from '../../../core/error-handling/functional-error-handling/error';
@Injectable({
  providedIn: 'root',
})
export class CrosschecksService extends AbstractService {
  constructor(
    private crossChecksGql: ISalesPipelineCrossChecksGQL,
    private marketsCrossChecksGQL: IMarketsCrossChecksGQL,
    private portfolioInternalPriceGQL: IPortfolioInternalPriceGQL,
    private salesPipelineTrafficLightsGQL: ISalesPipelineTrafficLightsGQL,
    private salesPipelineCcTrafficLightsOverviewGQL: ISalesPipelineCcTrafficLightsOverviewGQL,
    private stockTrafficLightsGQL: IStockTrafficLightsGQL,
    private marketTrafficLightsGQL: IMarketsTrafficLightsGQL,
    private marketsCcTrafficLightsOverviewGQL: IMarketsCcTrafficLightsOverviewGQL,
    private portfolioTrafficLightsGQL: IPortfolioTrafficLightsGQL,
    private portfolioInternalPriceCcTrafficLightsOverviewGQL: IPortfolioInternalPriceCcTrafficLightsOverviewGQL,
    private stockAgeGQL: IStockAgeGQL,
    private stockAgeCcTrafficLightsOverviewGQL: IStockAgeCcTrafficLightsOverviewGQL,
    private stockCoverageGQL: IStockCoverageGQL,
    private stockCoverageCcTrafficLightsOverviewGQL: IStockCoverageCcTrafficLightsOverviewGQL,
    protected injector: Injector
  ) {
    super(injector);
  }

  private readonly CROSSCHECKS_BASE_PATH: string = `${environment.basePath}/models/scenarios/cross-checks`;
  readonly API_PATHS = {
    salesPipeline: {
      calendarizedOrderBank: `${this.CROSSCHECKS_BASE_PATH}/static/sales-pipeline/order-bank`,
    },
  };

  /**
   * GetTooltipData
   * @param market desired market for translation
   * @param key key for section desired to fetch tooltips for
   */
  getTooltipData(market: string, key: string): Observable<ITooltipDataResponse> {
    return of(CROSSCHECK_TOOLTIP_RESPONSE_MOCK[key as keyof typeof CROSSCHECK_TOOLTIP_RESPONSE_MOCK]);
  }

  getSalesPipelineOrderIntakeData(
    granularity: IGranularityDto,
    scenarioId: string,
    crossCheckType: SalesPipelineCrossCheckType,
    dateRange?: ISalesPipelineDateRange
  ): Observable<IOrderIntakeApiResponse> {
    return this.crossChecksGql
      .fetch({
        crossCheckType,
        dateRange,
        filterId: granularity.id!,
        market: granularity.market,
        scenarioId,
      })
      .pipe(
        map((res) => {
          const response = res.data.salesPipelineCrossChecks.data;
          return response as unknown as IOrderIntakeApiResponse;
        }),
        catchError((error) => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('Unable to fetch retail crosschecks');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * GetSalesPipelineTrafficLights
   * @param scenarioId
   * @param filterIds
   * @param market
   */
  getSalesPipelineTrafficLights(
    scenarioId: string,
    filterIds: string,
    market: string
  ): Observable<TrafficLightsResponse> {
    return this.salesPipelineTrafficLightsGQL
      .fetch({
        filterIds: [filterIds],
        market,
        scenarioId,
      })
      .pipe(
        map((result: ApolloQueryResult<ISalesPipelineTrafficLightsQuery>) => {
          if (result.data.scenarioDataByFilterIds.length > 0) {
            return {
              trafficLight:
                result.data.scenarioDataByFilterIds[0].salesPipelineTrafficLight !== null
                  ? JSON.parse(<string>result.data.scenarioDataByFilterIds[0].salesPipelineTrafficLight)
                  : {
                      arrow: null,
                      color: 'notAvailable',
                    },
              trafficLightAdj:
                result.data.scenarioDataByFilterIds[0].salesPipelineTrafficLightAdj !== null
                  ? JSON.parse(<string>result.data.scenarioDataByFilterIds[0].salesPipelineTrafficLightAdj)
                  : {
                      arrow: null,
                      color: 'notAvailable',
                    },
            };
          } else {
            return {
              trafficLight: {
                arrow: null,
                color: 'notAvailable',
              },
              trafficLightAdj: {
                arrow: null,
                color: 'notAvailable',
              },
            };
          }
        }),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * GetSalesPipelineTrafficLightsOverview
   * @param scenarioId
   * @param filterId
   * @param market
   * @param crossCheckType
   * @param context
   * @param dateRange
   */
  getSalesPipelineTrafficLightsOverview(
    scenarioId: string,
    filterId: string,
    market: string,
    crossCheckType: SalesPipelineCrossCheckType,
    context: CrossCheckContextType,
    dateRange?: ISalesPipelineDateRange
  ): Observable<CrosschecksTrafficLightUIProps | undefined> {
    return this.salesPipelineCcTrafficLightsOverviewGQL
      .fetch({
        context,
        crossCheckType,
        dateRange,
        filterId,
        market,
        scenarioId,
      })
      .pipe(
        map((response) =>
          this.mapTrafficLightResponse(response.data.salesPipelineCcTrafficLightsOverview.data?.trafficLight)
        ),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * GetStockTrafficLights
   * @param scenarioId
   * @param filterIds
   * @param market
   */
  getStockTrafficLights(scenarioId: string, filterIds: string, market: string): Observable<TrafficLightsResponse> {
    return this.stockTrafficLightsGQL
      .fetch({
        filterIds: [filterIds],
        market,
        scenarioId,
      })
      .pipe(
        map((result: ApolloQueryResult<any>) => {
          if (result.data.scenarioDataByFilterIds.length > 0) {
            return {
              trafficLight:
                result.data.scenarioDataByFilterIds[0].stockTrafficLight !== null
                  ? JSON.parse(result.data.scenarioDataByFilterIds[0].stockTrafficLight)
                  : {
                      arrow: null,
                      color: 'notAvailable',
                    },
              trafficLightAdj:
                result.data.scenarioDataByFilterIds[0].stockTrafficLightAdj !== null
                  ? JSON.parse(result.data.scenarioDataByFilterIds[0].stockTrafficLightAdj)
                  : {
                      arrow: null,
                      color: 'notAvailable',
                    },
            };
          } else {
            return {
              trafficLight: {
                arrow: null,
                color: 'notAvailable',
              },
              trafficLightAdj: {
                arrow: null,
                color: 'notAvailable',
              },
            };
          }
        }),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * GetMarketsTrafficLights
   * @param scenarioId
   * @param filterIds
   * @param market
   */
  getMarketsTrafficLights(scenarioId: string, filterIds: string, market: string): Observable<TrafficLightsResponse> {
    return this.marketTrafficLightsGQL
      .fetch({
        filterIds: [filterIds],
        market,
        scenarioId,
      })
      .pipe(
        map((result: ApolloQueryResult<any>) => {
          if (result.data.scenarioDataByFilterIds.length > 0) {
            return {
              trafficLight:
                result.data.scenarioDataByFilterIds[0].marketsTrafficLight !== null
                  ? JSON.parse(result.data.scenarioDataByFilterIds[0].marketsTrafficLight)
                  : {
                      arrow: null,
                      color: 'notAvailable',
                    },
              trafficLightAdj:
                result.data.scenarioDataByFilterIds[0].marketsTrafficLightAdj !== null
                  ? JSON.parse(result.data.scenarioDataByFilterIds[0].marketsTrafficLightAdj)
                  : {
                      arrow: null,
                      color: 'notAvailable',
                    },
            };
          } else {
            return {
              trafficLight: {
                arrow: null,
                color: 'notAvailable',
              },
              trafficLightAdj: {
                arrow: null,
                color: 'notAvailable',
              },
            };
          }
        }),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * GetMarketsTrafficLightsOverview
   * @param scenarioId
   * @param filterIds
   * @param market
   * @param context
   */
  getMarketsTrafficLightsOverview(
    scenarioId: string,
    filterIds: string,
    market: string,
    context: CrossCheckContextType
  ): Observable<CrosschecksTrafficLightUIProps | undefined> {
    return this.marketsCcTrafficLightsOverviewGQL
      .fetch({
        context,
        filterId: filterIds,
        market,
        scenarioId,
      })
      .pipe(
        map((response) =>
          this.mapTrafficLightResponse(response.data.marketsCcTrafficLightsOverview?.data?.trafficLight)
        ),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * GetPortfolioTrafficLights
   * @param scenarioId
   * @param filterIds
   * @param market
   */
  getPortfolioTrafficLights(scenarioId: string, filterIds: string, market: string): Observable<TrafficLightsResponse> {
    return this.portfolioTrafficLightsGQL
      .fetch({
        filterIds: [filterIds],
        market,
        scenarioId,
      })
      .pipe(
        map((result: ApolloQueryResult<any>) => {
          if (result.data.scenarioDataByFilterIds.length > 0) {
            return {
              trafficLight:
                result.data.scenarioDataByFilterIds[0].portfolioTrafficLight !== null
                  ? JSON.parse(result.data.scenarioDataByFilterIds[0].portfolioTrafficLight)
                  : {
                      arrow: null,
                      color: 'notAvailable',
                    },
              trafficLightAdj:
                result.data.scenarioDataByFilterIds[0].portfolioTrafficLightAdj !== null
                  ? JSON.parse(result.data.scenarioDataByFilterIds[0].portfolioTrafficLightAdj)
                  : {
                      arrow: null,
                      color: 'notAvailable',
                    },
            };
          } else {
            return {
              trafficLight: {
                arrow: null,
                color: 'notAvailable',
              },
              trafficLightAdj: {
                arrow: null,
                color: 'notAvailable',
              },
            };
          }
        }),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * GetPortfolioTrafficLightsOverview
   * @param scenarioId
   * @param filterIds
   * @param market
   * @param context
   */
  getPortfolioTrafficLightsOverview(
    scenarioId: string,
    filterIds: string,
    market: string,
    context: CrossCheckContextType
  ): Observable<CrosschecksTrafficLightUIProps | undefined> {
    return this.portfolioInternalPriceCcTrafficLightsOverviewGQL
      .fetch({
        context,
        filterId: filterIds,
        market,
        scenarioId,
      })
      .pipe(
        map((response) =>
          this.mapTrafficLightResponse(response.data.portfolioInternalPriceCcTrafficLightsOverview?.data?.trafficLight)
        ),
        catchError((error): Observable<never> => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  getCalendarizedOrderBankData(granularity: IGranularityDto): Observable<ICalendarizedOrderBankDataResponse> {
    return this.httpClient.post<ICalendarizedOrderBankDataResponse>(
      this.API_PATHS.salesPipeline.calendarizedOrderBank,
      {
        ...granularity,
        e_series: granularity.eSeries,
        model_code: granularity.modelCode,
      }
    );
  }

  getSalesPipelineRetailsSalesData(
    granularity: IGranularityDto,
    scenarioId: string,
    crossCheckType: SalesPipelineCrossCheckType,
    dateRange?: ISalesPipelineDateRange
  ): Observable<IRetailSalesData> {
    return this.crossChecksGql
      .fetch({
        crossCheckType,
        dateRange,
        filterId: granularity.id!,
        market: granularity.market,
        scenarioId,
      })
      .pipe(
        map((res: any) => {
          const response = res?.data?.salesPipelineCrossChecks?.data;

          const retailData = {
            frequency: response?.frequency,
            scalar: response?.scalar,
            timeSeries: response?.timeSeries,
            timeSeriesProperties: response?.timeSeriesProperties,
          };
          return {
            headings: retailData.timeSeriesProperties?.map((prop: any) => {
              return {
                description: granularity.series,
                key: prop,
              };
            }),
            scalar: retailData.scalar,
            timeSeriesProperties: retailData?.timeSeriesProperties,
            xaxis: retailData?.frequency,
            yaxis: retailData?.timeSeries,
          } as IRetailSalesData;
        }),
        catchError((error) => {
          if (error.graphqlErrors || error.networkError) {
            return throwError('Unable to fetch retail crosschecks');
          } else {
            return throwError(error.value);
          }
        })
      );
  }

  /**
   * Stock
   */

  /**
   * Market Crosschecks data
   */
  getMarketData(filterId: string, market: string, scenarioId: string): Observable<IMarketsCrosscheckDataResponse> {
    return this.marketsCrossChecksGQL
      .fetch({
        filterId: filterId,
        market: market,
        scenarioId: scenarioId,
      })
      .pipe(
        map((response: ApolloQueryResult<IMarketsCrossChecksQuery>) => {
          const marketsResponse: IMarketsCrosscheckDataResponse = {
            leaseRateData: [],
            priceData: [],
          };
          response.data.marketsCrossChecks?.map((data) => {
            marketsResponse.priceData.push({
              baseGrossPrice: data.price.baseGrossPrice!,
              baseGrossPriceAdj: data.price.baseGrossPriceAdj!,
              baseNetPrice: data.price.baseNetPrice!,
              baseNetPriceAdj: data.price.baseNetPriceAdj!,
              hasAdjustedPrice: data.price.hasAdjustedPrice!,
              market: data.market,
              marketGrossPriceRatio: data.price.marketGrossPriceRatio!,
              marketGrossPriceRatioAdj: data.price.marketGrossPriceRatioAdj!,
              marketNetPriceRatio: data.price.marketNetPriceRatio!,
              marketNetPriceRatioAdj: data.price.marketNetPriceRatioAdj!,
              modelCode: data.modelCode!,
              modelCodeAdj: data.modelCodeAdj!,
              typicallyEquippedGrossPrice: data.price.typicallyEquippedGrossPrice!,
              typicallyEquippedGrossPriceAdj: data.price.typicallyEquippedNetPriceAdj!,
              typicallyEquippedNetPrice: data.price.typicallyEquippedNetPrice!,
              typicallyEquippedNetPriceAdj: data.price.typicallyEquippedNetPriceAdj!,
            });
            marketsResponse.leaseRateData.push({
              baseGrossPrice: data.leaseRate.baseGrossPrice!,
              baseGrossPriceAdj: data.leaseRate.baseGrossPriceAdj!,
              baseNetPrice: data.leaseRate.baseNetPrice!,
              baseNetPriceAdj: data.leaseRate.baseNetPriceAdj!,
              hasAdjustedPrice: data.leaseRate.hasAdjustedPrice!,
              market: data.market,
              marketGrossPriceRatio: data.leaseRate.marketGrossPriceRatio!,
              marketGrossPriceRatioAdj: data.leaseRate.marketGrossPriceRatioAdj!,
              marketNetPriceRatio: data.leaseRate.marketNetPriceRatio!,
              marketNetPriceRatioAdj: data.leaseRate.marketNetPriceRatioAdj!,
              modelCode: data.modelCode!,
              modelCodeAdj: data.modelCodeAdj!,
              typicallyEquippedGrossPrice: data.leaseRate.typicallyEquippedGrossPrice!,
              typicallyEquippedGrossPriceAdj: data.leaseRate.typicallyEquippedNetPriceAdj!,
              typicallyEquippedNetPrice: data.leaseRate.typicallyEquippedNetPrice!,
              typicallyEquippedNetPriceAdj: data.leaseRate.typicallyEquippedNetPriceAdj!,
            });
          });
          return marketsResponse;
        })
      );
  }

  /**
   * Portfolio
   */
  getPortfolioPriceLadderData(
    filterId: string,
    market: string,
    scenarioId: string
  ): Observable<IInternalPriceLadderResponse[]> {
    return this.portfolioInternalPriceGQL
      .fetch({
        filterId: filterId,
        market: market,
        scenarioId: scenarioId,
      })
      .pipe(
        map((response: ApolloQueryResult<IPortfolioInternalPriceQuery>) => {
          const internalPriceLadderData = response.data?.portfolioInternalPrice?.internalPriceLadderData;
          return internalPriceLadderData as IInternalPriceLadderResponse[];
        })
      );
  }

  /**
   * Get Stock Age GQL query.
   * @param filterId
   * @param market
   * @param scenarioId
   * @param accessible
   * @param stockPriceLevel
   */
  getStockAgeData(
    filterId: string,
    market: string,
    scenarioId: string,
    accessible: Accessible,
    stockPriceLevel: StockPriceLevel
  ): Observable<IStockAgeCrosscheckData> {
    return this.stockAgeGQL
      .fetch({
        accessible,
        filterId,
        market,
        scenarioId,
        stockPriceLevel,
      })
      .pipe(
        map((response) => response.data.stockAge),
        catchError(() => {
          return throwError(FetchCrosscheckDataApiError);
        })
      );
  }

  getStockAgeTrafficLightsOverview(
    filterId: string,
    market: string,
    scenarioId: string,
    accessible: Accessible,
    stockPriceLevel: StockPriceLevel,
    context: CrossCheckContextType
  ): Observable<CrosschecksTrafficLightUIProps | undefined> {
    return this.stockAgeCcTrafficLightsOverviewGQL
      .fetch({
        accessible,
        context,
        filterId,
        market,
        scenarioId,
        stockPriceLevel,
      })
      .pipe(
        map((response) =>
          this.mapTrafficLightResponse(response.data.stockAgeCcTrafficLightsOverview?.data?.trafficLight)
        ),
        catchError(() => {
          return throwError(FetchCrosscheckDataApiError);
        })
      );
  }

  getStockCoverageData(
    filterId: string,
    market: string,
    scenarioId: string,
    accessible: Accessible,
    stockPriceLevel: StockPriceLevel
  ): Observable<IStockCoverageCrosscheckData> {
    return this.stockCoverageGQL
      .fetch({
        accessible,
        filterId,
        market,
        scenarioId,
        stockPriceLevel,
      })
      .pipe(
        map((response) => response.data.stockCoverage),
        catchError(() => {
          return throwError(FetchCrosscheckDataApiError);
        })
      );
  }

  getStockCoverageTrafficLightsOverview(
    filterId: string,
    market: string,
    scenarioId: string,
    accessible: Accessible,
    stockPriceLevel: StockPriceLevel,
    context: CrossCheckContextType
  ): Observable<CrosschecksTrafficLightUIProps | undefined> {
    return this.stockCoverageCcTrafficLightsOverviewGQL
      .fetch({
        accessible,
        context,
        filterId,
        market,
        scenarioId,
        stockPriceLevel,
      })
      .pipe(
        map((response) =>
          this.mapTrafficLightResponse(response.data.stockCoverageCcTrafficLightsOverview?.data?.trafficLight)
        ),
        catchError(() => {
          return throwError(FetchCrosscheckDataApiError);
        })
      );
  }

  getStockMixData(): Observable<any> {
    return of(STOCK_MIX_CHART_DATA_MOCK_RESPONSE);
  }

  processCrossChecks(): Observable<ICrossCheckData> {
    return of<ICrosscheckDataResponse>(CROSSCHECK_DATA_RESPONSE_MOCK).pipe(
      map((response) => this.mapCrosscheckDataResponse(response)),
      catchError(() => {
        return throwError(FetchCrosscheckDataApiError);
      })
    );
  }

  mapCrosscheckDataResponse(response: ICrosscheckDataResponse): ICrossCheckData {
    return {
      currentCrossCheckResults: this.mapCrosscheckResults(response.granularities.length, response.cross_check_results),
      granularities: response.granularities?.map<IGranularityDto>((item) => ({
        ...item,
        id: item.id,
      })),
      metadata: response.metadata,
      settings: response.settings,
    };
  }

  private mapCrosscheckResults(
    totalGranularities: number,
    crossCheckResults: ICrossCheckResultsDto
  ): ICrossCheckResults[] {
    try {
      return [...Array(totalGranularities)].map((_, index) => {
        const mappedCrosscheck: ICrossCheckResults | any = {};

        Object.keys(crossCheckResults).forEach((rootKey) => {
          mappedCrosscheck[rootKey] = (crossCheckResults as any)[rootKey][index];
        });

        return mappedCrosscheck;
      });
    } catch (e) {
      throw error(MapCrosscheckDataError);
    }
  }

  private mapTrafficLightResponse(
    trafficLight?: { arrow?: number | null; color?: string | null; weight?: number | null } | ITrafficLight | null
  ): CrosschecksTrafficLightUIProps | undefined {
    return trafficLight
      ? {
          arrow: trafficLight?.arrow as number,
          color: trafficLight?.color as TrafficLightsColor,
          weight: trafficLight?.weight ? `${trafficLight.weight * 100}` : '',
        }
      : undefined;
  }
}
