import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-ftd-custom-modal-header',
  styleUrls: ['./ftd-custom-modal-header.component.scss'],
  templateUrl: './ftd-custom-modal-header.component.html',
})
export class FtdCustomModalHeaderComponent {
  /**
   * Main Title of the modal located on his top-left
   */
  @Input() title!: string;

  /**
   * Left Title Icon of the modal located on his top-left
   */
  @Input() titleLeftMatIcon!: string;

  /**
   * Left Color Icon of the modal located on his top-left
   */
  @Input() colorLeftMatIcon!: string;

  /**
   * Callback event to fire after clicking on "close (X)" button
   */
  @Output() closeButtonClicked: EventEmitter<any> = new EventEmitter();

  handleCloseButtonClick($event: MouseEvent): void {
    this.closeButtonClicked.emit($event);
  }
}
