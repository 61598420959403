import { Component, OnDestroy, OnInit } from '@angular/core';
import { DiscountMatrixViewDataService } from '../../../services/matrix-view-data/matrix-view-data.service';
import { FtdNotifierService } from '../../../../../../common/services/ftd-notifier/ftd-notifier.service';
import { IMatrixColumnConfig, IMatrixViewConfig, IMatrixViewCustomConfig } from '../../../models/matrix-view.model';
import {
  IMatrixColumnOrderInput,
  IMatrixViewColumnsQuery,
  IUpdateMatrixColumnsOrderMutationVariables,
} from '../../../../../../graphql/services/gql-api.service';
import { MATRIX_VIEW_COLUMNS_TREE_TRANSFORMATION_CONFIG } from '../../../constants/matrix-view-columns.config';
import { MatDialogRef } from '@angular/material/dialog';
import { MatrixColumnType } from '../../../enums/matrix-column-type.enum';
import { Subscription } from 'rxjs';
import cloneDeep from 'lodash.clonedeep';

@Component({
  selector: 'app-discount-matrix-view-columns-ordering-modal',
  styleUrls: ['./matrix-view-columns-ordering-modal.component.scss'],
  templateUrl: './matrix-view-columns-ordering-modal.component.html',
})

/**
 * @class DiscountMatrixViewColumnsOrderingModalComponent
 */
export class DiscountMatrixViewColumnsOrderingModalComponent implements OnInit, OnDestroy {
  public title: string = 'Show/Hide columns';
  public isSubmitButtonDisabled: boolean = false;
  public isRequestProcessing: boolean = false;
  public matrixColumnConfig: any[] = [];
  private staticColumns: IMatrixColumnConfig[] = [];

  private subscriptions: Subscription[] = [];

  /**
   * @constructor
   * @param notifierService
   * @param dialogRef
   * @param matrixViewDataService
   */
  constructor(
    private notifierService: FtdNotifierService,
    private dialogRef: MatDialogRef<DiscountMatrixViewColumnsOrderingModalComponent>,
    private matrixViewDataService: DiscountMatrixViewDataService
  ) {}

  /**
   * NgOnInit
   */
  ngOnInit(): void {
    this.getMatrixViewColumns();
  }

  /**
   * NgOnDestroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription): void => {
      subscription.unsubscribe();
    });
  }

  /**
   * GetMatrixViewColumns
   * @private
   */
  private getMatrixViewColumns(): void {
    this.isRequestProcessing = true;
    const subscription: Subscription = this.matrixViewDataService
      .getMatrixViewColumnsOrdered('DE', 'bd2b59108e70f7b3e47af1efd1ed5fd312')
      .subscribe(
        (matrixViewCustomConfig: IMatrixViewCustomConfig) => {
          // Use this to store static columns that are not available to customize (Model Names, Actions)
          this.staticColumns = this.extractStaticColumns(matrixViewCustomConfig.matrixColumnsCustomOrder);
          const { columns } = {
            columns: this.matrixViewDataService.transformDataToTreeView(
              matrixViewCustomConfig.matrixColumnsCustomOrder,
              MATRIX_VIEW_COLUMNS_TREE_TRANSFORMATION_CONFIG
            ),
          };
          this.matrixColumnConfig = this.matrixViewDataService.transformMatrixViewColumnsLevel2Flat(columns);
          this.getMatrixColumnConfigPayload();
          this.isRequestProcessing = false;
        },
        (): void => {}
      );

    this.subscriptions.push(subscription);
  }

  /**
   * Reset columns to Default State
   * resetToDefault
   */
  resetMatrixColumnConfig(): void {
    this.isRequestProcessing = true;
    const subscription: Subscription = this.matrixViewDataService.getMatrixViewColumns().subscribe(
      (matrixViewConfig: IMatrixViewConfig): void => {
        this.matrixColumnConfig = this.matrixViewDataService.transformMatrixViewColumnsLevel2Flat(
          matrixViewConfig.columns
        );
        this.isRequestProcessing = false;
      },
      (): void => {}
    );
    this.subscriptions.push(subscription);
  }

  /**
   * GetMatrixColumnConfigPayload
   * @return UpdateMatrixColumnsOrderMutationVariables
   */
  getMatrixColumnConfigPayload(): IUpdateMatrixColumnsOrderMutationVariables {
    return {
      matrixColumnsOrder: [
        ...this.matrixColumnConfig.map(
          (matrixColumnConfig: IMatrixColumnConfig, position: number): IMatrixColumnOrderInput =>
            this.mapMatrixColumnConfigToMatrixColumnOrderInput(matrixColumnConfig, position)
        ),
        ...this.matrixColumnConfig.flatMap((matrixColumnConfig: IMatrixColumnConfig) =>
          matrixColumnConfig?.children?.map(
            (matrixColumnConfig: IMatrixColumnConfig, position: number): IMatrixColumnOrderInput =>
              this.mapMatrixColumnConfigToMatrixColumnOrderInput(matrixColumnConfig, position)
          )
        ),
        ...this.staticColumns.map((matrixColumnConfig: IMatrixColumnConfig): IMatrixColumnOrderInput => {
          if (matrixColumnConfig.displayName !== '' && matrixColumnConfig.columnType === MatrixColumnType.NAME) {
            matrixColumnConfig.isVisible = [
              ...this.matrixColumnConfig,
              ...this.matrixColumnConfig.flatMap(
                (matrixColumnConfig: IMatrixColumnConfig) => matrixColumnConfig?.children
              ),
            ]
              .filter((col): boolean => col?.parentId === matrixColumnConfig.id)
              .some((col): boolean => Boolean(col?.isVisible));
          }

          return this.mapMatrixColumnConfigToMatrixColumnOrderInput(matrixColumnConfig, matrixColumnConfig.position!);
        }),
      ] as IMatrixColumnOrderInput[],
    };
  }

  /**
   * ExtractStaticColumns
   * @param columns
   * @return IMatrixColumnConfig[]
   */
  extractStaticColumns(columns: IMatrixColumnConfig[]): IMatrixColumnConfig[] {
    // Extract first header columns
    const headerRow = columns.filter((column: IMatrixColumnConfig) => {
      return column.parentId === '';
    });
    // Extract first level from Actions and Models Names columns
    const firstRow = columns.filter((column: IMatrixColumnConfig) => {
      return headerRow
        .filter((col) => col.columnType !== MatrixColumnType.NAME)
        .some((col) => col.id === column.parentId);
    });

    // Extract second level of Actions and Models Names columns
    const secondRow = columns.filter((column: IMatrixColumnConfig) => {
      return firstRow.some((col) => col.id === column.parentId);
    });
    return cloneDeep([...headerRow, ...firstRow, ...secondRow]);
  }

  /**
   * MapMatrixColumnConfigToMatrixColumnOrderInput
   * @param matrixColumnConfig
   * @param position
   * @return MatrixColumnOrderInput
   */
  mapMatrixColumnConfigToMatrixColumnOrderInput(
    matrixColumnConfig: IMatrixColumnConfig,
    position: number
  ): IMatrixColumnOrderInput {
    return {
      columnId: matrixColumnConfig.id,
      isNotCollapsible: Boolean(matrixColumnConfig.isNotCollapsible),
      isSticky: Boolean(matrixColumnConfig.isSticky),
      isVisible: Boolean(matrixColumnConfig.isVisible),
      position: position,
      sectionKey: matrixColumnConfig.sectionKey,
    };
  }

  /**
   * Submit
   * @param payload
   */
  submit(payload: IUpdateMatrixColumnsOrderMutationVariables = this.getMatrixColumnConfigPayload()): void {
    this.isSubmitButtonDisabled = true;
    this.isRequestProcessing = true;

    const subscription: Subscription = this.matrixViewDataService.setMatrixViewColumns(payload).subscribe({
      error: (): void => {
        this.isSubmitButtonDisabled = false;
        this.isRequestProcessing = false;
        this.notifierService.showError(`Error in Columns Ordering Submission`);
      },
      next: (data: IMatrixViewColumnsQuery | any): void => {
        this.isSubmitButtonDisabled = false;
        this.isRequestProcessing = false;

        if (data.errors?.length) {
          this.onSubmitError(data.errors);
        } else {
          this.onSubmitSuccess(data.updateMatrixColumnsOrder as IMatrixColumnConfig[]);
        }
      },
    });
    this.subscriptions.push(subscription);
  }

  /**
   * OnSubmitSuccess
   * @param data
   */
  onSubmitSuccess(data: IMatrixColumnConfig[]): void {
    this.matrixViewDataService.updateMatrixViewColumns(data);
    this.dialogRef.close();
    this.notifierService.showSuccess(`Columns Ordering Submitted`);
  }

  /**
   * OnSubmitError
   * @param errors
   */
  onSubmitError(errors: Array<any>): void {
    errors.forEach((error: any): void => {
      this.notifierService.showError(error.message as string);
    });
  }
}
