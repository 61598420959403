import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { interval } from 'rxjs';

export interface IProgressBarModalData {
  title: string;
  description: string;
  titleLeftMatIcon: string;
  colorLeftMatIcon: string;
  asyncCallback?: () => Promise<void>;
}

@Component({
  selector: 'app-ftd-progress-bar-modal',
  templateUrl: './ftd-progress-bar-modal.component.html',
})
export class FtdProgressBarModalComponent implements OnInit {
  get description(): string {
    return this._description;
  }

  set description(value: string) {
    this._description = value;
  }

  get colorLeftMatIcon(): string {
    return this._colorLeftMatIcon;
  }

  set colorLeftMatIcon(value: string) {
    this._colorLeftMatIcon = value;
  }

  get titleLeftMatIcon(): string {
    return this._titleLeftMatIcon;
  }

  set titleLeftMatIcon(value: string) {
    this._titleLeftMatIcon = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get isAsyncCallbackDefined(): boolean {
    return typeof this.data?.asyncCallback === 'function';
  }

  /**
   * Title of the modal located on his center
   */
  private _title: string = '';

  /**
   * Description of the modal located on his body
   */
  private _description: string = '';

  /**
   * Left Title Icon of the modal located on his top-left
   */
  private _titleLeftMatIcon: string = '';

  /**
   * Left Color Icon of the modal located on his top-left
   */
  private _colorLeftMatIcon: string = '';

  /**
   * Timer Interval increment
   */
  private timerInterval: number = 0.5;

  /**
   * Progress Bar Value
   */
  public progressBarValue: number = 0;

  constructor(
    @Optional()
    @Inject(MAT_DIALOG_DATA)
    public data: IProgressBarModalData,
    private dialogRef: MatDialogRef<FtdProgressBarModalComponent>
  ) {
    this.title = this.data.title;
    this.description = this.data.description;
    this.titleLeftMatIcon = this.data.titleLeftMatIcon;
    this.colorLeftMatIcon = this.data.colorLeftMatIcon;
  }

  ngOnInit(): void {
    this.handleProgressBarProcess();
  }

  private async handleProgressBarProcess(): Promise<void> {
    if (this.isAsyncCallbackDefined) {
      this.data.asyncCallback && (await this.data.asyncCallback());
      this.dialogRef.close(true);
    } else {
      this.startTimer();
    }
  }

  cancelButtonClicked(): void {
    this.dialogRef.close();
  }

  startTimer(interVal: number = this.timerInterval) {
    const timer$ = interval(10);

    const subscription = timer$.subscribe((sec) => {
      this.progressBarValue = sec * interVal;

      if (this.progressBarValue >= 100) {
        this.dialogRef.close(true);
        subscription.unsubscribe();
      }
    });
  }
}
