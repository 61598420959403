import { Component } from '@angular/core';
import {
  TrafficLightsArrow,
  TrafficLightsColor,
  trafficLightsColorDescriptionMap,
  trafficLightsDescription,
  trafficLightsDescriptionMap,
} from 'src/app/crosschecks/types/traffic-lights.type';

@Component({
  selector: 'app-matrix-view-traffic-lights-legends',
  styleUrls: ['./matrix-view-traffic-lights-legends.component.scss'],
  templateUrl: './matrix-view-traffic-lights-legends.component.html',
})
export class MatrixViewTrafficLightsLegendsComponent {
  trafficLightsMap: Map<TrafficLightsArrow, trafficLightsDescription> = trafficLightsDescriptionMap;
  trafficLightsColorDescriptionMap: Map<TrafficLightsColor, trafficLightsDescription> =
    trafficLightsColorDescriptionMap;
}
