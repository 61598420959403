export enum stockCoverageSeriesName {
  accessible = 'Accessible',
  totalStock = 'Total stock',
  stockEstimateCurrentPrice = 'Stock NCPE-Estimate (current price)',
  stockEstimateScenarioPrice = 'Stock NCPE-Estimate (scenario price)',
  totalISPStockCoverage = 'Total ISP Stock Coverage',
  totalISPStockCoverageForecast = 'Forecast Total ISP Stock Coverage',
  totalStockCoverage = 'Total Stock Coverage',
  stockCoverageEstimateCurrentPrice = 'Stock coverage NCPE-Estimation (current price)',
  stockCoverageEstimateScenarioPrice = 'Stock coverage NCPE-Estimation (scenario price)',
  totalStockCoverageForecast = 'Forecast Total Stock Coverage',
  stockCoverageEnginePredicted = 'Engine Predicted Stock Coverage',
}
