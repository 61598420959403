import { Component, Input } from '@angular/core';
import { TrafficLightsArrow, TrafficLightsColor } from '../../../../crosschecks/types/traffic-lights.type';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'ftd-tab-label',
  templateUrl: './ftd-tab-label.component.html',
})
export class FtdTabLabelComponent {
  @Input() title!: string;
  @Input() info?: boolean;
  @Input() infoText?: string;
  @Input() status: TrafficLightsArrow = 'keep';
  @Input() set percentage(value: string | undefined | null) {
    this._percentage = value;
    this.isDividerVisible();
    this.setTrafficLightColorBasedOnPercentage();
  }

  @Input() set trafficLights(value: boolean) {
    this._trafficLights = value;
    this.isDividerVisible();
  }

  @Input() set trafficLightColor(color: TrafficLightsColor) {
    this.setTrafficLightColorBasedOnGivenColorProp(color);
  }

  showDivider?: boolean;
  _trafficLights?: boolean;
  _percentage?: string | null;

  _trafficLightColor: TrafficLightsColor = 'green';

  isDividerVisible() {
    if (this._percentage && this._trafficLights) {
      this.showDivider = true;
    }
  }

  private setTrafficLightColorBasedOnPercentage(): void {
    if (['', undefined, null].includes(this._percentage?.trim())) {
      this._trafficLightColor = 'notAvailable';
    }
  }

  private setTrafficLightColorBasedOnGivenColorProp(color: TrafficLightsColor): void {
    const colorsNotToShowNAValues: TrafficLightsColor[] = ['green', 'red'];
    if (colorsNotToShowNAValues.includes(color)) {
      this._trafficLightColor = color;
    } else {
      this._trafficLightColor = 'notAvailable';
    }
  }
}
