import { DeltaStockCoverageEnum } from '../../../../crosschecks/pages/stock/stock-mix/ftd-side-table/stock-mix-side-table/stock-mix-side-table.component';
import { IBarChartData } from '../models/ftd-bar-chart.model';
import { IChartTooltipData } from '../models/ftd-chart-tooltip-data.model';
import { IGenericChartData } from '../models/ftd-generic-chart.model';
import { ILineChartData } from '../models/ftd-line-chart.model';
import { IOpenVolumeYe, IStockAgeResponseChartData } from 'src/app/matrix-view/models/api.model';
import { LineChartPricePointColor } from '../enums/line-chart-price-point-color.enum';
import { leaseReturnsSeriesName } from '../enums/crosschecks/sales-pipeline/ftd-lease-returns.enum';

export const LINE_CHART_DATA_RESPONSE_MOCK: ILineChartData = {
  fields: [
    {
      value1: 300,
      value2: 1500,
      value3: 2500,
      xAxisValue: new Date(2023, 0).getTime(),
    },
    {
      value1: 3000,
      value2: 2000,
      xAxisValue: new Date(2023, 1).getTime(),
    },
    {
      value1: 500,
      value2: 3000,
      value3: 4000,
      xAxisValue: new Date(2023, 2).getTime(),
    },
    {
      value1: 600,
      value2: 1000,
      xAxisValue: new Date(2023, 3).getTime(),
    },
    {
      value1: 350,
      value2: 500,
      value3: 1000,
      xAxisValue: new Date(2023, 4).getTime(),
    },
  ],
  headings: [
    {
      description: 'Series 1',
      key: 'value1',
    },
    {
      description: 'Series 2',
      key: 'value2',
    },
    {
      description: 'Series 3',
      key: 'value3',
    },
  ],
};

export const BAR_CHART_DATA_RESPONSE_MOCK: IBarChartData = {
  fields: [
    {
      value1: 300,
      value2: 1500,
      value3: 2500,
      xAxisValue: new Date(2023, 0).getTime(),
    },
    {
      value1: 3000,
      value2: 2000,
      xAxisValue: new Date(2023, 1).getTime(),
    },
    {
      value1: 500,
      value2: 3000,
      value3: 4000,
      xAxisValue: new Date(2023, 2).getTime(),
    },
    {
      value1: 600,
      value2: 1000,
      xAxisValue: new Date(2023, 3).getTime(),
    },
    {
      value1: 350,
      value2: 500,
      value3: 1000,
      xAxisValue: new Date(2023, 4).getTime(),
    },
    {
      value1: 350,
      value2: 500,
      value3: 1000,
      xAxisValue: new Date(2023, 5).getTime(),
    },
    {
      value1: 350,
      value2: 500,
      value3: 1000,
      xAxisValue: new Date(2023, 6).getTime(),
    },
    {
      value1: 350,
      value2: 500,
      value3: 1000,
      xAxisValue: new Date(2023, 7).getTime(),
    },
    {
      value1: 350,
      value2: 500,
      value3: 1000,
      xAxisValue: new Date(2023, 8).getTime(),
    },
    {
      value1: 350,
      value2: 500,
      value3: 1000,
      xAxisValue: new Date(2023, 9).getTime(),
    },
    {
      value1: 150,
      value2: 500,
      value3: 1200,
      xAxisValue: new Date(2023, 10).getTime(),
    },
    {
      value1: 250,
      value2: 300,
      value3: 700,
      xAxisValue: new Date(2023, 11).getTime(),
    },
    {
      value1: 350,
      value2: 500,
      value3: 1000,
      xAxisValue: new Date(2023, 12).getTime(),
    },
    {
      value1: 350,
      value2: 500,
      value3: 1000,
      xAxisValue: new Date(2023, 13).getTime(),
    },
  ],
  headings: [
    {
      description: 'Series 1',
      key: 'value1',
    },
    {
      description: 'Series 2',
      key: 'value2',
    },
    {
      description: '',
      key: 'value3',
    },
  ],
};

export const HORIZONTAL_BAR_CHART_MOCK_STOCK_AGE_NEW: IStockAgeResponseChartData = {
  calculationDate: '2023-11-26',
  historic: [
    {
      countPerBucket0_10Days: 17,
      countPerBucket11_30Days: 20,
      countPerBucket31_90Days: 103,
      countPerBucket91_180Days: 41,
      countPerBucket181_270Days: 38,
      countPerBucket271_365Days: 31,
      countPerBucket365Days: 20,
      date: new Date('2023-01-01'),
      historicSumGranularBucket0_10Days: 17,
      historicSumGranularBucket11_20Days: 16,
      historicSumGranularBucket21_30Days: 4,
      historicSumGranularBucket31_40Days: 4,
      historicSumGranularBucket41_50Days: 15,
      historicSumGranularBucket51_60Days: 19,
      historicSumGranularBucket61_70Days: 34,
      historicSumGranularBucket71_80Days: 12,
      historicSumGranularBucket81_90Days: 19,
      historicSumGranularBucket91_100Days: 3,
      historicSumGranularBucket101_110Days: 7,
      historicSumGranularBucket111_120Days: 12,
      historicSumGranularBucket121_130Days: 2,
      historicSumGranularBucket131_140Days: 2,
      historicSumGranularBucket141_150Days: 1,
      historicSumGranularBucket151_160Days: 4,
      historicSumGranularBucket161_170Days: 2,
      historicSumGranularBucket171_180Days: 8,
      historicSumGranularBucket181_195Days: 1,
      historicSumGranularBucket196_210Days: 6,
      historicSumGranularBucket211_225Days: 17,
      historicSumGranularBucket226_240Days: 8,
      historicSumGranularBucket241_255Days: 5,
      historicSumGranularBucket256_270Days: 1,
      historicSumGranularBucket271_285Days: 3,
      historicSumGranularBucket286_300Days: 7,
      historicSumGranularBucket301_315Days: 6,
      historicSumGranularBucket316_330Days: 7,
      historicSumGranularBucket331_345Days: 3,
      historicSumGranularBucket346_365Days: 5,
      historicSumGranularBucket365Days: 20,
      monthlyCount: 270,
      monthlyMedianAge: 145.9443092203361,
    },
    {
      countPerBucket0_10Days: 13,
      countPerBucket11_30Days: 18,
      countPerBucket31_90Days: 62,
      countPerBucket91_180Days: 80,
      countPerBucket181_270Days: 45,
      countPerBucket271_365Days: 24,
      countPerBucket365Days: 18,
      date: new Date('2023-02-01'),
      historicSumGranularBucket0_10Days: 13,
      historicSumGranularBucket11_20Days: 13,
      historicSumGranularBucket21_30Days: 5,
      historicSumGranularBucket31_40Days: 6,
      historicSumGranularBucket41_50Days: 9,
      historicSumGranularBucket51_60Days: 1,
      historicSumGranularBucket61_70Days: 10,
      historicSumGranularBucket71_80Days: 7,
      historicSumGranularBucket81_90Days: 29,
      historicSumGranularBucket91_100Days: 24,
      historicSumGranularBucket101_110Days: 15,
      historicSumGranularBucket111_120Days: 15,
      historicSumGranularBucket121_130Days: 5,
      historicSumGranularBucket131_140Days: 9,
      historicSumGranularBucket141_150Days: 7,
      historicSumGranularBucket151_160Days: 2,
      historicSumGranularBucket161_170Days: 2,
      historicSumGranularBucket171_180Days: 1,
      historicSumGranularBucket181_195Days: 5,
      historicSumGranularBucket196_210Days: 9,
      historicSumGranularBucket211_225Days: 1,
      historicSumGranularBucket226_240Days: 7,
      historicSumGranularBucket241_255Days: 15,
      historicSumGranularBucket256_270Days: 8,
      historicSumGranularBucket271_285Days: 4,
      historicSumGranularBucket286_300Days: 1,
      historicSumGranularBucket301_315Days: 2,
      historicSumGranularBucket316_330Days: 6,
      historicSumGranularBucket331_345Days: 3,
      historicSumGranularBucket346_365Days: 8,
      historicSumGranularBucket365Days: 18,
      monthlyCount: 260,
      monthlyMedianAge: 153.61783664872684,
    },
    {
      countPerBucket0_10Days: 7,
      countPerBucket11_30Days: 3,
      countPerBucket31_90Days: 30,
      countPerBucket91_180Days: 120,
      countPerBucket181_270Days: 26,
      countPerBucket271_365Days: 34,
      countPerBucket365Days: 22,
      date: new Date('2023-03-01'),
      historicSumGranularBucket0_10Days: 7,
      historicSumGranularBucket11_20Days: 2,
      historicSumGranularBucket21_30Days: 1,
      historicSumGranularBucket31_40Days: 2,
      historicSumGranularBucket41_50Days: 8,
      historicSumGranularBucket51_60Days: 4,
      historicSumGranularBucket61_70Days: 5,
      historicSumGranularBucket71_80Days: 9,
      historicSumGranularBucket81_90Days: 2,
      historicSumGranularBucket91_100Days: 3,
      historicSumGranularBucket101_110Days: 13,
      historicSumGranularBucket111_120Days: 25,
      historicSumGranularBucket121_130Days: 28,
      historicSumGranularBucket131_140Days: 13,
      historicSumGranularBucket141_150Days: 17,
      historicSumGranularBucket151_160Days: 3,
      historicSumGranularBucket161_170Days: 10,
      historicSumGranularBucket171_180Days: 8,
      historicSumGranularBucket181_195Days: 2,
      historicSumGranularBucket196_210Days: 3,
      historicSumGranularBucket211_225Days: 5,
      historicSumGranularBucket226_240Days: 9,
      historicSumGranularBucket241_255Days: 1,
      historicSumGranularBucket256_270Days: 7,
      historicSumGranularBucket271_285Days: 15,
      historicSumGranularBucket286_300Days: 7,
      historicSumGranularBucket301_315Days: 4,
      historicSumGranularBucket316_330Days: 1,
      historicSumGranularBucket331_345Days: 2,
      historicSumGranularBucket346_365Days: 5,
      historicSumGranularBucket365Days: 22,
      monthlyCount: 242,
      monthlyMedianAge: 179.85507469563055,
    },
    {
      countPerBucket0_10Days: 18,
      countPerBucket11_30Days: 6,
      countPerBucket31_90Days: 17,
      countPerBucket91_180Days: 115,
      countPerBucket181_270Days: 40,
      countPerBucket271_365Days: 31,
      countPerBucket365Days: 23,
      date: new Date('2023-04-01'),
      historicSumGranularBucket0_10Days: 18,
      historicSumGranularBucket11_20Days: 5,
      historicSumGranularBucket21_30Days: 1,
      historicSumGranularBucket31_40Days: 1,
      historicSumGranularBucket41_50Days: 2,
      historicSumGranularBucket51_60Days: 1,
      historicSumGranularBucket61_70Days: 2,
      historicSumGranularBucket71_80Days: 7,
      historicSumGranularBucket81_90Days: 4,
      historicSumGranularBucket91_100Days: 5,
      historicSumGranularBucket101_110Days: 9,
      historicSumGranularBucket111_120Days: 2,
      historicSumGranularBucket121_130Days: 3,
      historicSumGranularBucket131_140Days: 13,
      historicSumGranularBucket141_150Days: 25,
      historicSumGranularBucket151_160Days: 28,
      historicSumGranularBucket161_170Days: 13,
      historicSumGranularBucket171_180Days: 17,
      historicSumGranularBucket181_195Days: 7,
      historicSumGranularBucket196_210Days: 14,
      historicSumGranularBucket211_225Days: 2,
      historicSumGranularBucket226_240Days: 3,
      historicSumGranularBucket241_255Days: 5,
      historicSumGranularBucket256_270Days: 9,
      historicSumGranularBucket271_285Days: 3,
      historicSumGranularBucket286_300Days: 7,
      historicSumGranularBucket301_315Days: 14,
      historicSumGranularBucket316_330Days: 6,
      historicSumGranularBucket331_345Days: 2,
      historicSumGranularBucket346_365Days: 2,
      historicSumGranularBucket365Days: 23,
      monthlyCount: 250,
      monthlyMedianAge: 189.0634493882407,
    },
    {
      countPerBucket0_10Days: 20,
      countPerBucket11_30Days: 18,
      countPerBucket31_90Days: 7,
      countPerBucket91_180Days: 62,
      countPerBucket181_270Days: 88,
      countPerBucket271_365Days: 27,
      countPerBucket365Days: 19,
      date: new Date('2023-05-01'),
      historicSumGranularBucket0_10Days: 20,
      historicSumGranularBucket11_20Days: 11,
      historicSumGranularBucket21_30Days: 7,
      historicSumGranularBucket31_40Days: 1,
      historicSumGranularBucket41_50Days: 2,
      historicSumGranularBucket51_60Days: 3,
      historicSumGranularBucket61_70Days: 1,
      historicSumGranularBucket71_80Days: 2,
      historicSumGranularBucket81_90Days: 1,
      historicSumGranularBucket91_100Days: 2,
      historicSumGranularBucket101_110Days: 7,
      historicSumGranularBucket111_120Days: 3,
      historicSumGranularBucket121_130Days: 5,
      historicSumGranularBucket131_140Days: 7,
      historicSumGranularBucket141_150Days: 4,
      historicSumGranularBucket151_160Days: 3,
      historicSumGranularBucket161_170Days: 13,
      historicSumGranularBucket171_180Days: 18,
      historicSumGranularBucket181_195Days: 45,
      historicSumGranularBucket196_210Days: 18,
      historicSumGranularBucket211_225Days: 6,
      historicSumGranularBucket226_240Days: 14,
      historicSumGranularBucket241_255Days: 2,
      historicSumGranularBucket256_270Days: 3,
      historicSumGranularBucket271_285Days: 4,
      historicSumGranularBucket286_300Days: 8,
      historicSumGranularBucket301_315Days: 1,
      historicSumGranularBucket316_330Days: 5,
      historicSumGranularBucket331_345Days: 6,
      historicSumGranularBucket346_365Days: 3,
      historicSumGranularBucket365Days: 19,
      monthlyCount: 241,
      monthlyMedianAge: 189.67546651461888,
    },
    {
      countPerBucket0_10Days: 26,
      countPerBucket11_30Days: 25,
      countPerBucket31_90Days: 20,
      countPerBucket91_180Days: 32,
      countPerBucket181_270Days: 114,
      countPerBucket271_365Days: 17,
      countPerBucket365Days: 16,
      date: new Date('2023-06-01'),
      historicSumGranularBucket0_10Days: 26,
      historicSumGranularBucket11_20Days: 14,
      historicSumGranularBucket21_30Days: 11,
      historicSumGranularBucket31_40Days: 5,
      historicSumGranularBucket41_50Days: 7,
      historicSumGranularBucket51_60Days: 6,
      historicSumGranularBucket61_70Days: 1,
      historicSumGranularBucket71_80Days: 1,
      historicSumGranularBucket81_90Days: 3,
      historicSumGranularBucket91_100Days: 1,
      historicSumGranularBucket101_110Days: 2,
      historicSumGranularBucket111_120Days: 1,
      historicSumGranularBucket121_130Days: 2,
      historicSumGranularBucket131_140Days: 7,
      historicSumGranularBucket141_150Days: 3,
      historicSumGranularBucket151_160Days: 5,
      historicSumGranularBucket161_170Days: 7,
      historicSumGranularBucket171_180Days: 4,
      historicSumGranularBucket181_195Days: 11,
      historicSumGranularBucket196_210Days: 23,
      historicSumGranularBucket211_225Days: 43,
      historicSumGranularBucket226_240Days: 18,
      historicSumGranularBucket241_255Days: 6,
      historicSumGranularBucket256_270Days: 13,
      historicSumGranularBucket271_285Days: 1,
      historicSumGranularBucket286_300Days: 2,
      historicSumGranularBucket301_315Days: 4,
      historicSumGranularBucket316_330Days: 4,
      historicSumGranularBucket331_345Days: 1,
      historicSumGranularBucket346_365Days: 5,
      historicSumGranularBucket365Days: 16,
      monthlyCount: 250,
      monthlyMedianAge: 176.99576527796296,
    },
    {
      countPerBucket0_10Days: 20,
      countPerBucket11_30Days: 33,
      countPerBucket31_90Days: 34,
      countPerBucket91_180Days: 16,
      countPerBucket181_270Days: 104,
      countPerBucket271_365Days: 31,
      countPerBucket365Days: 10,
      date: new Date('2023-07-01'),
      historicSumGranularBucket0_10Days: 20,
      historicSumGranularBucket11_20Days: 25,
      historicSumGranularBucket21_30Days: 8,
      historicSumGranularBucket31_40Days: 10,
      historicSumGranularBucket41_50Days: 4,
      historicSumGranularBucket51_60Days: 5,
      historicSumGranularBucket61_70Days: 4,
      historicSumGranularBucket71_80Days: 4,
      historicSumGranularBucket81_90Days: 7,
      historicSumGranularBucket91_100Days: 1,
      historicSumGranularBucket101_110Days: 1,
      historicSumGranularBucket111_120Days: 2,
      historicSumGranularBucket121_130Days: 2,
      historicSumGranularBucket131_140Days: 3,
      historicSumGranularBucket141_150Days: 2,
      historicSumGranularBucket151_160Days: 3,
      historicSumGranularBucket161_170Days: 6,
      historicSumGranularBucket171_180Days: 2,
      historicSumGranularBucket181_195Days: 8,
      historicSumGranularBucket196_210Days: 7,
      historicSumGranularBucket211_225Days: 11,
      historicSumGranularBucket226_240Days: 20,
      historicSumGranularBucket241_255Days: 33,
      historicSumGranularBucket256_270Days: 25,
      historicSumGranularBucket271_285Days: 9,
      historicSumGranularBucket286_300Days: 11,
      historicSumGranularBucket301_315Days: 1,
      historicSumGranularBucket316_330Days: 2,
      historicSumGranularBucket331_345Days: 4,
      historicSumGranularBucket346_365Days: 4,
      historicSumGranularBucket365Days: 10,
      monthlyCount: 248,
      monthlyMedianAge: 175.6965030947114,
    },
    {
      countPerBucket0_10Days: 38,
      countPerBucket11_30Days: 26,
      countPerBucket31_90Days: 34,
      countPerBucket91_180Days: 20,
      countPerBucket181_270Days: 54,
      countPerBucket271_365Days: 68,
      countPerBucket365Days: 8,
      date: new Date('2023-08-01'),
      historicSumGranularBucket0_10Days: 38,
      historicSumGranularBucket11_20Days: 5,
      historicSumGranularBucket21_30Days: 21,
      historicSumGranularBucket31_40Days: 6,
      historicSumGranularBucket41_50Days: 9,
      historicSumGranularBucket51_60Days: 6,
      historicSumGranularBucket61_70Days: 4,
      historicSumGranularBucket71_80Days: 6,
      historicSumGranularBucket81_90Days: 3,
      historicSumGranularBucket91_100Days: 5,
      historicSumGranularBucket101_110Days: 1,
      historicSumGranularBucket111_120Days: 8,
      historicSumGranularBucket121_130Days: 2,
      historicSumGranularBucket131_140Days: 1,
      historicSumGranularBucket141_150Days: 2,
      historicSumGranularBucket151_160Days: 2,
      historicSumGranularBucket161_170Days: 2,
      historicSumGranularBucket171_180Days: 1,
      historicSumGranularBucket181_195Days: 4,
      historicSumGranularBucket196_210Days: 7,
      historicSumGranularBucket211_225Days: 7,
      historicSumGranularBucket226_240Days: 7,
      historicSumGranularBucket241_255Days: 11,
      historicSumGranularBucket256_270Days: 18,
      historicSumGranularBucket271_285Days: 30,
      historicSumGranularBucket286_300Days: 23,
      historicSumGranularBucket301_315Days: 6,
      historicSumGranularBucket316_330Days: 6,
      historicSumGranularBucket331_345Days: 2,
      historicSumGranularBucket346_365Days: 1,
      historicSumGranularBucket365Days: 8,
      monthlyCount: 248,
      monthlyMedianAge: 166.44540400663644,
    },
    {
      countPerBucket0_10Days: 37,
      countPerBucket11_30Days: 37,
      countPerBucket31_90Days: 31,
      countPerBucket91_180Days: 28,
      countPerBucket181_270Days: 24,
      countPerBucket271_365Days: 63,
      countPerBucket365Days: 7,
      date: new Date('2023-09-01'),
      historicSumGranularBucket0_10Days: 37,
      historicSumGranularBucket11_20Days: 20,
      historicSumGranularBucket21_30Days: 17,
      historicSumGranularBucket31_40Days: 8,
      historicSumGranularBucket41_50Days: 2,
      historicSumGranularBucket51_60Days: 12,
      historicSumGranularBucket61_70Days: 3,
      historicSumGranularBucket71_80Days: 1,
      historicSumGranularBucket81_90Days: 5,
      historicSumGranularBucket91_100Days: 3,
      historicSumGranularBucket101_110Days: 5,
      historicSumGranularBucket111_120Days: 3,
      historicSumGranularBucket121_130Days: 5,
      historicSumGranularBucket131_140Days: 1,
      historicSumGranularBucket141_150Days: 8,
      historicSumGranularBucket151_160Days: 2,
      historicSumGranularBucket161_170Days: 1,
      historicSumGranularBucket171_180Days: 3,
      historicSumGranularBucket181_195Days: 1,
      historicSumGranularBucket196_210Days: 2,
      historicSumGranularBucket211_225Days: 3,
      historicSumGranularBucket226_240Days: 7,
      historicSumGranularBucket241_255Days: 6,
      historicSumGranularBucket256_270Days: 5,
      historicSumGranularBucket271_285Days: 10,
      historicSumGranularBucket286_300Days: 15,
      historicSumGranularBucket301_315Days: 20,
      historicSumGranularBucket316_330Days: 9,
      historicSumGranularBucket331_345Days: 4,
      historicSumGranularBucket346_365Days: 5,
      historicSumGranularBucket365Days: 7,
      monthlyCount: 227,
      monthlyMedianAge: 151.97989214161157,
    },
    {
      countPerBucket0_10Days: 41,
      countPerBucket11_30Days: 42,
      countPerBucket31_90Days: 42,
      countPerBucket91_180Days: 31,
      countPerBucket181_270Days: 15,
      countPerBucket271_365Days: 36,
      countPerBucket365Days: 6,
      date: new Date('2023-10-01'),
      historicSumGranularBucket0_10Days: 41,
      historicSumGranularBucket11_20Days: 23,
      historicSumGranularBucket21_30Days: 19,
      historicSumGranularBucket31_40Days: 14,
      historicSumGranularBucket41_50Days: 9,
      historicSumGranularBucket51_60Days: 7,
      historicSumGranularBucket61_70Days: 4,
      historicSumGranularBucket71_80Days: 3,
      historicSumGranularBucket81_90Days: 9,
      historicSumGranularBucket91_100Days: 4,
      historicSumGranularBucket101_110Days: 3,
      historicSumGranularBucket111_120Days: 2,
      historicSumGranularBucket121_130Days: 6,
      historicSumGranularBucket131_140Days: 2,
      historicSumGranularBucket141_150Days: 5,
      historicSumGranularBucket151_160Days: 3,
      historicSumGranularBucket161_170Days: 5,
      historicSumGranularBucket171_180Days: 5,
      historicSumGranularBucket181_195Days: 2,
      historicSumGranularBucket196_210Days: 4,
      historicSumGranularBucket211_225Days: 4,
      historicSumGranularBucket226_240Days: 3,
      historicSumGranularBucket241_255Days: 6,
      historicSumGranularBucket256_270Days: 4,
      historicSumGranularBucket271_285Days: 7,
      historicSumGranularBucket286_300Days: 3,
      historicSumGranularBucket301_315Days: 7,
      historicSumGranularBucket316_330Days: 8,
      historicSumGranularBucket331_345Days: 8,
      historicSumGranularBucket346_365Days: 3,
      historicSumGranularBucket365Days: 6,
      monthlyCount: 213,
      monthlyMedianAge: 120.76800959996088,
    },
  ],
  scalar: [
    {
      medianAgeCurrentMonth: 121,
      medianAgeDiff: -31,
      over180PercentageDiff: -15,
      percentageCurrentMonth: 27,
    },
  ],
  stockAge: [
    {
      countPerBucket0_10Days: 655,
      countPerBucket11_30Days: 544,
      countPerBucket31_90Days: 883,
      countPerBucket91_180Days: 1251,
      countPerBucket181_270Days: 1011,
      countPerBucket271_365Days: 407,
      countPerBucket365Days: 72,
      eSeries: 'F40 Pre LCI',
      eSeriesCount: 0,
      eSeriesPercentage0_10Days: 14,
      eSeriesPercentage11_30Days: 11,
      eSeriesPercentage31_90Days: 18,
      eSeriesPercentage91_180Days: 26,
      eSeriesPercentage181_270Days: 21,
      eSeriesPercentage271_365Days: 8,
      eSeriesPercentage365Days: 1,
    },
    {
      countPerBucket0_10Days: 50,
      countPerBucket11_30Days: 54,
      countPerBucket31_90Days: 83,
      countPerBucket91_180Days: 251,
      countPerBucket181_270Days: 111,
      countPerBucket271_365Days: 7,
      countPerBucket365Days: 7,
      eSeries: 'F23 LCI',
      eSeriesCount: 0,
      eSeriesPercentage0_10Days: 14,
      eSeriesPercentage11_30Days: 11,
      eSeriesPercentage31_90Days: 18,
      eSeriesPercentage91_180Days: 26,
      eSeriesPercentage181_270Days: 21,
      eSeriesPercentage271_365Days: 8,
      eSeriesPercentage365Days: 1,
    },
  ],
};

export const BAR_CHART_STOCK_AGE_DATA_RESPONSE_MOCK: any = {
  fields: [
    {
      averageDays: 112,
      data: {
        days_31_90: {
          days_31_40: 1,
          days_41_50: 2,
          days_51_60: 3,
          days_61_70: 1,
          days_71_80: 1,
          days_81_90: 1,
        },
      },
      days_0_10: 3,
      days_11_30: 14,
      days_31_90: 9,
      days_90_180: 2,
      days_181_270: 3,
      days_271_365: 6,
      days_greater_365: 1,
      xAxisValue: new Date(2023, 0).getTime(),
    },
    {
      averageDays: 82,
      data: {
        days_31_90: {
          days_31_40: 1,
          days_41_50: 2,
          days_51_60: 3,
          days_61_70: 1,
          days_71_80: 1,
          days_81_90: 1,
        },
      },
      days_0_10: 6,
      days_11_30: 5,
      days_31_90: 8,
      days_90_180: 2,
      days_181_270: 1,
      days_271_365: 2,
      days_greater_365: 1,
      xAxisValue: new Date(2023, 1).getTime(),
    },
    {
      averageDays: 74,
      data: {
        days_31_90: {
          days_31_40: 1,
          days_41_50: 2,
          days_51_60: 3,
          days_61_70: 1,
          days_71_80: 1,
          days_81_90: 1,
        },
      },
      days_0_10: 3,
      days_11_30: 11,
      days_31_90: 6,
      days_90_180: 3,
      days_181_270: 7,
      days_271_365: 1,
      days_greater_365: 1,
      xAxisValue: new Date(2023, 2).getTime(),
    },
    {
      averageDays: 34,
      data: {
        days_31_90: {
          days_31_40: 1,
          days_41_50: 2,
          days_51_60: 3,
          days_61_70: 1,
          days_71_80: 1,
          days_81_90: 1,
        },
      },
      days_0_10: 5,
      days_11_30: 6,
      days_31_90: 7,
      days_90_180: 3,
      days_181_270: 2,
      days_271_365: 4,
      days_greater_365: 1,
      xAxisValue: new Date(2023, 3).getTime(),
    },
    {
      averageDays: 168,
      data: {
        days_31_90: {
          days_31_40: 1,
          days_41_50: 2,
          days_51_60: 3,
          days_61_70: 1,
          days_71_80: 1,
          days_81_90: 1,
        },
      },
      days_0_10: 2,
      days_11_30: 8,
      days_31_90: 3,
      days_90_180: 4,
      days_181_270: 1,
      days_271_365: 3,
      days_greater_365: 1,
      xAxisValue: new Date(2023, 4).getTime(),
    },
    {
      averageDays: 98,
      data: {
        days_31_90: [
          { color: '#52A2EB', description: '31-40 days', value: 1 },
          { color: '#79DDC0', description: '41-50 days', value: 2 },
          { color: '#225577', description: '51-60 days', value: 1 },
          { color: '#F28F33', description: '61-70 days', value: 3 },
          { color: '#F8C237', description: '71-80 days', value: 1 },
          { color: '#7D98C1', description: '81-90 days', value: 1 },
        ],
      },
      days_0_10: 4,
      days_11_30: 2,
      days_31_90: 5,
      days_90_180: 6,
      days_181_270: 3,
      days_271_365: 2,
      days_greater_365: 1,
      xAxisValue: new Date(2023, 5).getTime(),
    },
  ],
  headings: [
    {
      description: 'days 0-10',
      key: 'days_0_10',
    },
    {
      description: '11-30 days',
      key: 'days_11_30',
    },
    {
      description: '31-90 days',
      key: 'days_31_90',
    },
    {
      description: '91-180 days',
      key: 'days_90_180',
    },
    {
      description: '181-270 days',
      key: 'days_181_270',
    },
    {
      description: '271-365 days',
      key: 'days_271_365',
    },
    {
      description: '> 365 days',
      key: 'days_greater_365',
    },
  ],
};

export const HORIZONTAL_BAR_CHART_DATA_RESPONSE_MAPPED_MOCK: any = {
  fields: [
    {
      days_0_10: 30,
      days_11_30: 52,
      days_31_90: 23,
      days_91_180: 58,
      days_181_270: 12,
      days_271_365: 32,
      days_greater_365: 15,
      e_series: 'F60',
    },
    {
      days_0_10: 43,
      days_11_30: 22,
      days_31_90: 54,
      days_91_180: 42,
      days_181_270: 61,
      days_271_365: 45,
      days_greater_365: 30,
      e_series: 'F57',
    },
    {
      data: {
        fields: [
          {
            averageDays: 93,
            days_0_10: 5,
            days_11_30: 3,
            days_31_90: 1,
            days_90_180: 1,
            days_181_270: 2,
            days_271_365: 1,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 0).getTime(),
          },
          {
            averageDays: 102,
            days_0_10: 1,
            days_11_30: 1,
            days_31_90: 4,
            days_90_180: 5,
            days_181_270: 8,
            days_271_365: 5,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 1).getTime(),
          },
          {
            averageDays: 82,
            days_0_10: 3,
            days_11_30: 2,
            days_31_90: 4,
            days_90_180: 3,
            days_181_270: 3,
            days_271_365: 1,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 2).getTime(),
          },
          {
            averageDays: 105,
            days_0_10: 5,
            days_11_30: 6,
            days_31_90: 7,
            days_90_180: 3,
            days_181_270: 2,
            days_271_365: 4,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 3).getTime(),
          },
          {
            averageDays: 114,
            days_0_10: 2,
            days_11_30: 8,
            days_31_90: 3,
            days_90_180: 4,
            days_181_270: 1,
            days_271_365: 3,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 4).getTime(),
          },
          {
            averageDays: 124,
            days_0_10: 4,
            days_11_30: 2,
            days_31_90: 1,
            days_90_180: 6,
            days_181_270: 3,
            days_271_365: 2,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 5).getTime(),
          },
        ],
        headings: [
          {
            description: 'days 0-10',
            key: 'days_0_10',
          },
          {
            description: '11-30 days',
            key: 'days_11_30',
          },
          {
            description: '31-90 days',
            key: 'days_31_90',
          },
          {
            description: '91-180 days',
            key: 'days_90_180',
          },
          {
            description: '181-270 days',
            key: 'days_181_270',
          },
          {
            description: '271-365 days',
            key: 'days_271_365',
          },
          {
            description: '> 365 days',
            key: 'days_greater_365',
          },
        ],
      },
      days_0_10: 20,
      days_11_30: 32,
      days_31_90: 42,
      days_91_180: 32,
      days_181_270: 41,
      days_271_365: 65,
      days_greater_365: 24,
      e_series: 'F55',
    },
    {
      data: {
        fields: [
          {
            averageDays: 92,
            days_0_10: 3,
            days_11_30: 14,
            days_31_90: 9,
            days_90_180: 2,
            days_181_270: 3,
            days_271_365: 6,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 0).getTime(),
          },
          {
            averageDays: 102,
            days_0_10: 6,
            days_11_30: 5,
            days_31_90: 8,
            days_90_180: 2,
            days_181_270: 1,
            days_271_365: 2,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 1).getTime(),
          },
          {
            averageDays: 192,
            days_0_10: 3,
            days_11_30: 11,
            days_31_90: 6,
            days_90_180: 3,
            days_181_270: 7,
            days_271_365: 1,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 2).getTime(),
          },
          {
            averageDays: 91,
            days_0_10: 5,
            days_11_30: 6,
            days_31_90: 7,
            days_90_180: 3,
            days_181_270: 2,
            days_271_365: 4,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 3).getTime(),
          },
          {
            averageDays: 132,
            days_0_10: 2,
            days_11_30: 8,
            days_31_90: 3,
            days_90_180: 4,
            days_181_270: 1,
            days_271_365: 3,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 4).getTime(),
          },
          {
            averageDays: 111,
            days_0_10: 4,
            days_11_30: 2,
            days_31_90: 1,
            days_90_180: 6,
            days_181_270: 3,
            days_271_365: 2,
            days_greater_365: 1,
            xAxisValue: new Date(2023, 5).getTime(),
          },
        ],
        headings: [
          {
            description: 'days 0-10',
            key: 'days_0_10',
          },
          {
            description: '11-30 days',
            key: 'days_11_30',
          },
          {
            description: '31-90 days',
            key: 'days_31_90',
          },
          {
            description: '91-180 days',
            key: 'days_90_180',
          },
          {
            description: '181-270 days',
            key: 'days_181_270',
          },
          {
            description: '271-365 days',
            key: 'days_271_365',
          },
          {
            description: '> 365 days',
            key: 'days_greater_365',
          },
        ],
      },
      days_0_10: 150,
      days_11_30: 26,
      days_31_90: 150,
      days_91_180: 90,
      days_181_270: 80,
      days_271_365: 30,
      days_greater_365: 30,
      e_series: 'F56',
    },
  ],
  headings: [
    {
      description: '10%',
      key: 'days_0_10',
    },
    {
      description: '15%',
      key: 'days_11_30',
    },
    {
      description: '30%',
      key: 'days_31_90',
    },
    {
      description: '40%',
      key: 'days_91_180',
    },
    {
      description: '2%',
      key: 'days_181_270',
    },
    {
      description: '4%',
      key: 'days_271_365',
    },
    {
      description: '5%',
      key: 'days_greater_365',
    },
  ],
};

export const BAR_CHART_DATA_RESPONSE_STOCK_COVERAGE_MOCK: IBarChartData = {
  fields: [
    {
      accessible: 30,
      e_series: 'F60',
      xAxisValue: 'SOY',
    },
    {
      accessible: 43,
      e_series: 'F57',
      xAxisValue: 'May',
    },
    {
      e_series: 'F55',
      forecast_accessible: 32,
      xAxisValue: 'Jun',
    },
    {
      e_series: 'F56',
      forecast_accessible: 26,
      xAxisValue: 'Jul',
    },
  ],
  headings: [
    {
      description: 'Accessible',
      key: 'accessible',
    },
    {
      description: 'Forecast Accessible',
      key: 'forecast_accessible',
    },
  ],
};

export const LINE_CHART_DATA_RESPONSE_STOCK_COVERAGE_MOCK: ILineChartData = {
  fields: [
    {
      e_series: 'F60',
      total_isp_stock_coverage: 30,
      total_stock_coverage: 33,
      xAxisValue: 'SOY',
    },
    {
      e_series: 'F57',
      total_isp_stock_coverage: 6,
      total_stock_coverage: 20,
      xAxisValue: 'Feb',
    },
    {
      e_series: 'F57',
      total_isp_stock_coverage: 6,
      total_stock_coverage: 20,
      xAxisValue: 'Mar',
    },
    {
      e_series: 'F55',
      total_isp_stock_coverage: 25,
      total_stock_coverage: 20,
      xAxisValue: 'Apr',
    },
    {
      e_series: 'F56',
      engine_predicted_stock_coverage: 50,
      forecast_total_isp_stock_coverage: 40,
      forecast_total_stock_coverage: 26,
      total_isp_stock_coverage: 40,
      total_stock_coverage: 26,
      xAxisValue: 'May',
    },
    {
      e_series: 'F56',
      forecast_total_isp_stock_coverage: 50,
      forecast_total_stock_coverage: 10,
      xAxisValue: 'Jun',
    },
    {
      e_series: 'F56',
      forecast_total_isp_stock_coverage: 30,
      forecast_total_stock_coverage: 30,
      xAxisValue: 'Jul',
    },
    {
      e_series: 'F56',
      forecast_total_isp_stock_coverage: 15,
      forecast_total_stock_coverage: 33,
      xAxisValue: 'Aug',
    },
  ],
  headings: [
    {
      description: 'Total ISP Stock Coverage',
      key: 'total_isp_stock_coverage',
    },
    {
      description: 'Forecast Total ISP Stock Coverage',
      key: 'forecast_total_isp_stock_coverage',
    },
    {
      description: 'Total Stock Coverage',
      key: 'total_stock_coverage',
    },
    {
      description: 'Forecast Total Stock Coverage',
      key: 'forecast_total_stock_coverage',
    },
    {
      description: 'Engine Predicted Stock Coverage',
      key: 'engine_predicted_stock_coverage',
    },
  ],
};

export const LEASE_RETURNS_CHART_DATA_MOCK: IGenericChartData = {
  fields: [
    {
      averageLeaseRateReturn: 310,
      currency: '€',
      currentLeaseRate: 369,
      totalLeaseReturns: 88,
      xAxisValue: new Date(2022, 11).getTime(),
    },
    {
      averageLeaseRateReturn: 310,
      currency: '€',
      currentLeaseRate: 369,
      totalLeaseReturns: 88,
      xAxisValue: new Date(2023, 0).getTime(),
    },
    {
      averageLeaseRateReturn: 305,
      currency: '€',
      currentLeaseRate: 369,
      totalLeaseReturns: 84,
      xAxisValue: new Date(2023, 1).getTime(),
    },
    {
      averageLeaseRateReturn: 310,
      currency: '€',
      currentLeaseRate: 369,
      totalLeaseReturns: 80,
      xAxisValue: new Date(2023, 2).getTime(),
    },
    {
      averageLeaseRateReturn: 315,
      currency: '€',
      currentLeaseRate: 369,
      totalLeaseReturns: 99,
      xAxisValue: new Date(2023, 3).getTime(),
    },
    {
      averageLeaseRateReturn: 320,
      currency: '€',
      currentLeaseRate: 369,
      totalLeaseReturns: 80,
      xAxisValue: new Date(2023, 4).getTime(),
    },
    {
      averageLeaseRateReturn: 320,
      currency: '€',
      currentLeaseRate: 369,
      totalLeaseReturns: 80,
      xAxisValue: new Date(2023, 5).getTime(),
    },
    {
      averageLeaseRateReturn: 380,
      currency: '€',
      currentLeaseRate: 410,
      expectedLeaseRate: 410,
      forecastAverageLeaseRateReturn: 380,
      lessThanAverageLeaseReturnPercentage: 30,
      lessThanLeaseReturnPercentage: 10,
      moreThanAverageLeaseReturnPercentage: 70,
      moreThanLeaseReturnPercentage: 90,
      totalAverageLeaseRateUnits: 120,
      totalLeaseRateUnits: 120,
      totalLeaseReturns: 140,
      xAxisValue: new Date(2023, 6).getTime(),
    },
    {
      currency: '€',
      expectedLeaseRate: 296,
      expectedTotalLeaseReturns: 130,
      forecastAverageLeaseRateReturn: 320,
      returningCustomersCompared: 59,
      xAxisValue: new Date(2023, 7).getTime(),
    },
    {
      currency: '€',
      expectedLeaseRate: 320,
      expectedTotalLeaseReturns: 100,
      forecastAverageLeaseRateReturn: 315,
      returningCustomersCompared: 44,
      xAxisValue: new Date(2023, 8).getTime(),
    },
    {
      currency: '€',
      expectedLeaseRate: 380,
      expectedTotalLeaseReturns: 122,
      forecastAverageLeaseRateReturn: 305,
      returningCustomersCompared: 56,
      xAxisValue: new Date(2023, 9).getTime(),
    },
    {
      currency: '€',
      expectedLeaseRate: 300,
      expectedTotalLeaseReturns: 130,
      forecastAverageLeaseRateReturn: 360,
      returningCustomersCompared: 60,
      xAxisValue: new Date(2023, 10).getTime(),
    },
    {
      currency: '€',
      expectedLeaseRate: 220,
      expectedTotalLeaseReturns: 144,
      forecastAverageLeaseRateReturn: 320,
      returningCustomersCompared: 69,
      xAxisValue: new Date(2023, 11).getTime(),
    },
  ],
  headings: [
    {
      description: leaseReturnsSeriesName.forecastAverageLeaseRateReturn,
      key: 'forecastAverageLeaseRateReturn',
    },
    {
      description: leaseReturnsSeriesName.averageLeaseRateReturn,
      key: 'averageLeaseRateReturn',
    },
    {
      description: leaseReturnsSeriesName.expectedLeaseRate,
      key: 'expectedLeaseRate',
    },
    {
      description: leaseReturnsSeriesName.currentLeaseRate,
      key: 'currentLeaseRate',
    },
    {
      description: leaseReturnsSeriesName.totalLeaseReturns,
      key: 'totalLeaseReturns',
    },
    {
      description: leaseReturnsSeriesName.expectedTotalLeaseReturns,
      key: 'expectedTotalLeaseReturns',
    },
    {
      description: leaseReturnsSeriesName.returningCustomersCompared,
      key: 'returningCustomersCompared',
    },
  ],
};

export const CALENDARIZED_ORDER_BANK_CHART_DATA_MOCK: IGenericChartData = {
  fields: [
    {
      budget: 3416,
      retail: 799,
      xAxisValue: 'Jan',
    },
    {
      budget: 5564,
      retail: 980,
      xAxisValue: 'Feb',
    },
    {
      budget: 8380,
      retail: 2160,
      xAxisValue: 'Mar',
    },
    {
      budget: 7208,
      retail: 1241,
      xAxisValue: 'Apr',
    },
    {
      budget: 8288,
      retail: 1723,
      xAxisValue: 'May',
    },
    {
      budget: 8588,
      forecast_not_covered_by_ob_f07: 200,
      order_bank_percentage: 10,
      order_bank_value: 491,
      xAxisValue: 'Jun',
    },
    {
      budget: 6600,
      forecast_not_covered_by_ob_f07: 530,
      order_bank_percentage: 0,
      order_bank_value: 207,
      xAxisValue: 'Jul',
    },
    {
      budget: 3320,
      forecast_not_covered_by_ob_f07: 743,
      order_bank_percentage: 0,
      order_bank_value: 120,
      xAxisValue: 'Aug',
    },
    {
      budget: 3736,
      forecast_not_covered_by_ob_f07: 510,
      order_bank_percentage: 0,
      order_bank_value: 0,
      xAxisValue: 'Sep',
    },
    {
      budget: 6992,
      forecast_not_covered_by_ob_f07: 620,
      order_bank_percentage: 0,
      order_bank_value: 0,
      xAxisValue: 'Oct',
    },
    {
      budget: 7500,
      forecast_not_covered_by_ob_f07: 0,
      order_bank_percentage: 0,
      order_bank_value: 0,
      xAxisValue: 'Nov',
    },
    {
      budget: 6500,
      forecast_not_covered_by_ob_f07: 0,
      order_bank_percentage: 0,
      order_bank_value: 0,
      xAxisValue: 'Dec',
    },
    {
      order_bank_value: 4377,
      xAxisValue: '2023',
    },
  ],
  headings: [
    {
      description: 'Retail Budget',
      key: 'budget',
    },
    {
      description: 'Retail Forecast',
      key: 'forecast_not_covered_by_ob_f07',
    },
    {
      description: 'Calendarized Order Bank',
      key: 'order_bank_value',
    },
    {
      description: 'Retail Actuals',
      key: 'retail',
    },
  ],
};

export const PORTFOLIO_LOLLIPOP_CHART_DATA_RESPONSE_MOCK: ILineChartData = {
  fields: [
    {
      eSeries: 'F54 LCI',
      kw: 165,
      model: 'JCW',
      pricePointColor: LineChartPricePointColor.GREEN,
      series: 'Clubman',
      value: 52_900,
      volMix: '2%',
      xAxisValue: 'Clubman F54 LCI',
    },
    {
      eSeries: 'F54 LCI',
      kw: 100,
      model: 'Cooper',
      pricePointColor: LineChartPricePointColor.RED,
      priceThreshold: -5_301,
      pricingGuideline: 'Clubman Cooper > Clubman Cooper S',
      series: 'Clubman',
      value: 43_000,
      volMix: '2%',
      xAxisValue: 'Clubman F54 LCI',
    },
    {
      eSeries: 'F54 LCI',
      kw: 131,
      model: 'Cooper S',
      pricePointColor: LineChartPricePointColor.GREEN,
      series: 'Clubman',
      value: 38_700,
      volMix: '2%',
      xAxisValue: 'Clubman F54 LCI',
    },
    {
      eSeries: 'F40 Pre LCI',
      kw: 167,
      model: 'Cooper S',
      pricePointColor: LineChartPricePointColor.BLUE,
      series: '1 series',
      value: 50_300,
      volMix: '6%',
      xAxisValue: '1 series F40 Pre LCI',
    },
    {
      eSeries: 'F40 Pre LCI',
      kw: 167,
      model: 'Cooper SD',
      pricePointColor: LineChartPricePointColor.GRAY,
      series: '1 series',
      value: 41_700,
      volMix: '6%',
      xAxisValue: '1 series F40 Pre LCI',
    },
    {
      eSeries: 'F60 LCI',
      kw: 131,
      model: 'Cooper S',
      pricePointColor: LineChartPricePointColor.RED,
      priceThreshold: 3_301,
      pricingGuideline: 'Clubman Cooper < Clubman Cooper S',
      series: 'Countryman',
      value: 38_700,
      volMix: '21%',
      xAxisValue: 'Clubman LCI',
    },
    {
      eSeries: 'F60 LCI',
      model: 'Cooper SD',
      pricePointColor: LineChartPricePointColor.GRAY,
      series: 'Countryman',
      value: 45_100,
      volMix: '5%',
      xAxisValue: 'Clubman LCI',
    },
  ] as any,
  headings: [
    {
      description: 'List price car (base car)',
      key: 'value',
    },
  ],
};

export const CHART_TOOLTIP_DATA_MOCK: IChartTooltipData[] = [
  { key: '{name}', value: '{valueY}' },
  { key: 'Effective interest rate', value: '6%' },
  { key: 'RV', value: '59%' },
  { key: 'Mileage', value: '10k' },
  { key: 'Term', value: '24 mth' },
  { key: 'Down payment', value: '5%' },
];

export const CHART_INFO_TILE_DATA_MOCK: IOpenVolumeYe = {
  displayName: 'TOTAL ORDER BANK CY',
  displayNameDetails: '115,881 total order bank',
  ratioRetailOpenVolumeBudget: '',
  value: '88.706',
};

export const STOCK_AGE_HORIZONTAL_CHART_DATA_MOCK = {
  fields: [
    {
      bulletAnchor: 6074,
      countPerBucket0_10Days: 575,
      countPerBucket11_30Days: 760,
      countPerBucket31_90Days: 1223,
      countPerBucket91_180Days: 2176,
      countPerBucket181_270Days: 786,
      countPerBucket271_365Days: 432,
      countPerBucket365Days: 122,
      eSeriesPercentage0_10Days: 9,
      eSeriesPercentage11_30Days: 13,
      eSeriesPercentage31_90Days: 20,
      eSeriesPercentage91_180Days: 36,
      eSeriesPercentage181_270Days: 13,
      eSeriesPercentage271_365Days: 7,
      eSeriesPercentage365Days: 2,
      lastIndex: 0,
      selected: true,
      value: 'G21 LCI',
    },
    {
      bulletAnchor: 268,
      countPerBucket0_10Days: 25,
      countPerBucket11_30Days: 31,
      countPerBucket31_90Days: 63,
      countPerBucket91_180Days: 71,
      countPerBucket181_270Days: 52,
      countPerBucket271_365Days: 17,
      countPerBucket365Days: 9,
      eSeriesPercentage0_10Days: 9,
      eSeriesPercentage11_30Days: 12,
      eSeriesPercentage31_90Days: 24,
      eSeriesPercentage91_180Days: 26,
      eSeriesPercentage181_270Days: 19,
      eSeriesPercentage271_365Days: 6,
      eSeriesPercentage365Days: 3,
      lastIndex: 0,
      selected: false,
      value: 'G80 Pre LCI',
    },
    {
      bulletAnchor: 98,
      countPerBucket0_10Days: 9,
      countPerBucket11_30Days: 4,
      countPerBucket31_90Days: 3,
      countPerBucket91_180Days: 28,
      countPerBucket181_270Days: 46,
      countPerBucket271_365Days: 8,
      countPerBucket365Days: null,
      eSeriesPercentage0_10Days: 9,
      eSeriesPercentage11_30Days: 4,
      eSeriesPercentage31_90Days: 3,
      eSeriesPercentage91_180Days: 29,
      eSeriesPercentage181_270Days: 47,
      eSeriesPercentage271_365Days: 8,
      eSeriesPercentage365Days: null,
      lastIndex: 0,
      selected: false,
      value: 'G81 Pre LCI',
    },
    {
      bulletAnchor: 90,
      countPerBucket0_10Days: 4,
      countPerBucket11_30Days: 3,
      countPerBucket31_90Days: 4,
      countPerBucket91_180Days: null,
      countPerBucket181_270Days: null,
      countPerBucket271_365Days: null,
      countPerBucket365Days: 79,
      eSeriesPercentage0_10Days: 4,
      eSeriesPercentage11_30Days: 3,
      eSeriesPercentage31_90Days: 4,
      eSeriesPercentage91_180Days: null,
      eSeriesPercentage181_270Days: null,
      eSeriesPercentage271_365Days: null,
      eSeriesPercentage365Days: 88,
      lastIndex: 0,
      selected: false,
      value: 'G20 Pre LCI',
    },
    {
      bulletAnchor: 1,
      countPerBucket0_10Days: null,
      countPerBucket11_30Days: null,
      countPerBucket31_90Days: null,
      countPerBucket91_180Days: null,
      countPerBucket181_270Days: null,
      countPerBucket271_365Days: null,
      countPerBucket365Days: 1,
      eSeriesPercentage0_10Days: null,
      eSeriesPercentage11_30Days: null,
      eSeriesPercentage31_90Days: null,
      eSeriesPercentage91_180Days: null,
      eSeriesPercentage181_270Days: null,
      eSeriesPercentage271_365Days: null,
      eSeriesPercentage365Days: 100,
      lastIndex: 0,
      selected: false,
      value: 'F34 LCI',
    },
    {
      bulletAnchor: 2711,
      countPerBucket0_10Days: 596,
      countPerBucket11_30Days: 614,
      countPerBucket31_90Days: 555,
      countPerBucket91_180Days: 492,
      countPerBucket181_270Days: 286,
      countPerBucket271_365Days: 140,
      countPerBucket365Days: 28,
      eSeriesPercentage0_10Days: 22,
      eSeriesPercentage11_30Days: 23,
      eSeriesPercentage31_90Days: 20,
      eSeriesPercentage91_180Days: 18,
      eSeriesPercentage181_270Days: 11,
      eSeriesPercentage271_365Days: 5,
      eSeriesPercentage365Days: 1,
      lastIndex: 0,
      selected: false,
      value: 'G20 LCI',
    },
    {
      bulletAnchor: 37,
      countPerBucket0_10Days: 1,
      countPerBucket11_30Days: 2,
      countPerBucket31_90Days: null,
      countPerBucket91_180Days: null,
      countPerBucket181_270Days: null,
      countPerBucket271_365Days: null,
      countPerBucket365Days: 34,
      eSeriesPercentage0_10Days: 3,
      eSeriesPercentage11_30Days: 5,
      eSeriesPercentage31_90Days: null,
      eSeriesPercentage91_180Days: null,
      eSeriesPercentage181_270Days: null,
      eSeriesPercentage271_365Days: null,
      eSeriesPercentage365Days: 92,
      lastIndex: 0,
      selected: false,
      value: 'G21 Pre LCI',
    },
  ],
  headings: [
    {
      description: '',
      key: 'countPerBucket0_10Days',
    },
    {
      description: '',
      key: 'countPerBucket11_30Days',
    },
    {
      description: '',
      key: 'countPerBucket31_90Days',
    },
    {
      description: '',
      key: 'countPerBucket91_180Days',
    },
    {
      description: '',
      key: 'countPerBucket181_270Days',
    },
    {
      description: '',
      key: 'countPerBucket271_365Days',
    },
    {
      description: '',
      key: 'countPerBucket365Days',
    },
    {
      description: '',
      key: 'lastIndex',
    },
  ],
};

export const STOCK_AGE_POPUP_CHART_DATA_MOCK: IGenericChartData = {
  fields: [
    {
      countPerBucket0_10Days: 17,
      countPerBucket11_30Days: 16,
      countPerBucket31_90Days: 32,
      countPerBucket91_180Days: 112,
      countPerBucket181_270Days: 2,
      countPerBucket271_365Days: null,
      countPerBucket365Days: null,
      date: '2023-01-01',
      historicSumGranularBucket0_10Days: 17,
      historicSumGranularBucket11_20Days: 15,
      historicSumGranularBucket21_30Days: 1,
      historicSumGranularBucket31_40Days: null,
      historicSumGranularBucket41_50Days: 2,
      historicSumGranularBucket51_60Days: 6,
      historicSumGranularBucket61_70Days: 5,
      historicSumGranularBucket71_80Days: 9,
      historicSumGranularBucket81_90Days: 10,
      historicSumGranularBucket91_100Days: 6,
      historicSumGranularBucket101_110Days: 18,
      historicSumGranularBucket111_120Days: 14,
      historicSumGranularBucket121_130Days: 16,
      historicSumGranularBucket131_140Days: 26,
      historicSumGranularBucket141_150Days: 7,
      historicSumGranularBucket151_160Days: null,
      historicSumGranularBucket161_170Days: 14,
      historicSumGranularBucket171_180Days: 5,
      historicSumGranularBucket181_195Days: 2,
      historicSumGranularBucket196_210Days: null,
      historicSumGranularBucket211_225Days: null,
      historicSumGranularBucket226_240Days: null,
      historicSumGranularBucket241_255Days: null,
      historicSumGranularBucket256_270Days: null,
      historicSumGranularBucket271_285Days: null,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      lastIndex: 0,
      monthlyCount: 179,
      monthlyMedianAge: 99,
      xAxisValue: 'Jan',
    },
    {
      countPerBucket0_10Days: 13,
      countPerBucket11_30Days: 29,
      countPerBucket31_90Days: 23,
      countPerBucket91_180Days: 110,
      countPerBucket181_270Days: 25,
      countPerBucket271_365Days: null,
      countPerBucket365Days: null,
      date: '2023-02-01',
      historicSumGranularBucket0_10Days: 13,
      historicSumGranularBucket11_20Days: 21,
      historicSumGranularBucket21_30Days: 8,
      historicSumGranularBucket31_40Days: 8,
      historicSumGranularBucket41_50Days: 6,
      historicSumGranularBucket51_60Days: 1,
      historicSumGranularBucket61_70Days: null,
      historicSumGranularBucket71_80Days: 2,
      historicSumGranularBucket81_90Days: 6,
      historicSumGranularBucket91_100Days: 5,
      historicSumGranularBucket101_110Days: 12,
      historicSumGranularBucket111_120Days: 8,
      historicSumGranularBucket121_130Days: 6,
      historicSumGranularBucket131_140Days: 22,
      historicSumGranularBucket141_150Days: 7,
      historicSumGranularBucket151_160Days: 19,
      historicSumGranularBucket161_170Days: 22,
      historicSumGranularBucket171_180Days: 9,
      historicSumGranularBucket181_195Days: 17,
      historicSumGranularBucket196_210Days: 6,
      historicSumGranularBucket211_225Days: 2,
      historicSumGranularBucket226_240Days: null,
      historicSumGranularBucket241_255Days: null,
      historicSumGranularBucket256_270Days: null,
      historicSumGranularBucket271_285Days: null,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      lastIndex: 0,
      monthlyCount: 200,
      monthlyMedianAge: 111,
      xAxisValue: 'Feb',
    },
    {
      countPerBucket0_10Days: 46,
      countPerBucket11_30Days: 14,
      countPerBucket31_90Days: 36,
      countPerBucket91_180Days: 68,
      countPerBucket181_270Days: 71,
      countPerBucket271_365Days: null,
      countPerBucket365Days: null,
      date: '2023-03-01',
      historicSumGranularBucket0_10Days: 46,
      historicSumGranularBucket11_20Days: 8,
      historicSumGranularBucket21_30Days: 6,
      historicSumGranularBucket31_40Days: 4,
      historicSumGranularBucket41_50Days: 8,
      historicSumGranularBucket51_60Days: 10,
      historicSumGranularBucket61_70Days: 6,
      historicSumGranularBucket71_80Days: 7,
      historicSumGranularBucket81_90Days: 1,
      historicSumGranularBucket91_100Days: null,
      historicSumGranularBucket101_110Days: 2,
      historicSumGranularBucket111_120Days: 6,
      historicSumGranularBucket121_130Days: 5,
      historicSumGranularBucket131_140Days: 11,
      historicSumGranularBucket141_150Days: 9,
      historicSumGranularBucket151_160Days: 5,
      historicSumGranularBucket161_170Days: 18,
      historicSumGranularBucket171_180Days: 12,
      historicSumGranularBucket181_195Days: 29,
      historicSumGranularBucket196_210Days: 18,
      historicSumGranularBucket211_225Days: 15,
      historicSumGranularBucket226_240Days: 7,
      historicSumGranularBucket241_255Days: 2,
      historicSumGranularBucket256_270Days: null,
      historicSumGranularBucket271_285Days: null,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      lastIndex: 0,
      monthlyCount: 235,
      monthlyMedianAge: 115,
      xAxisValue: 'Mar',
    },
    {
      countPerBucket0_10Days: 25,
      countPerBucket11_30Days: 40,
      countPerBucket31_90Days: 62,
      countPerBucket91_180Days: 47,
      countPerBucket181_270Days: 94,
      countPerBucket271_365Days: 2,
      countPerBucket365Days: null,
      date: '2023-04-01',
      historicSumGranularBucket0_10Days: 25,
      historicSumGranularBucket11_20Days: 26,
      historicSumGranularBucket21_30Days: 14,
      historicSumGranularBucket31_40Days: 30,
      historicSumGranularBucket41_50Days: 6,
      historicSumGranularBucket51_60Days: 5,
      historicSumGranularBucket61_70Days: 4,
      historicSumGranularBucket71_80Days: 7,
      historicSumGranularBucket81_90Days: 10,
      historicSumGranularBucket91_100Days: 6,
      historicSumGranularBucket101_110Days: 7,
      historicSumGranularBucket111_120Days: 1,
      historicSumGranularBucket121_130Days: null,
      historicSumGranularBucket131_140Days: 2,
      historicSumGranularBucket141_150Days: 6,
      historicSumGranularBucket151_160Days: 5,
      historicSumGranularBucket161_170Days: 11,
      historicSumGranularBucket171_180Days: 9,
      historicSumGranularBucket181_195Days: 16,
      historicSumGranularBucket196_210Days: 17,
      historicSumGranularBucket211_225Days: 28,
      historicSumGranularBucket226_240Days: 12,
      historicSumGranularBucket241_255Days: 14,
      historicSumGranularBucket256_270Days: 7,
      historicSumGranularBucket271_285Days: 2,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      lastIndex: 0,
      monthlyCount: 270,
      monthlyMedianAge: 118,
      xAxisValue: 'Apr',
    },
    {
      countPerBucket0_10Days: 95,
      countPerBucket11_30Days: 156,
      countPerBucket31_90Days: 32,
      countPerBucket91_180Days: 43,
      countPerBucket181_270Days: 90,
      countPerBucket271_365Days: 19,
      countPerBucket365Days: null,
      date: '2023-05-01',
      historicSumGranularBucket0_10Days: 95,
      historicSumGranularBucket11_20Days: 151,
      historicSumGranularBucket21_30Days: 5,
      historicSumGranularBucket31_40Days: 9,
      historicSumGranularBucket41_50Days: 7,
      historicSumGranularBucket51_60Days: 3,
      historicSumGranularBucket61_70Days: 6,
      historicSumGranularBucket71_80Days: 3,
      historicSumGranularBucket81_90Days: 4,
      historicSumGranularBucket91_100Days: 5,
      historicSumGranularBucket101_110Days: 6,
      historicSumGranularBucket111_120Days: 7,
      historicSumGranularBucket121_130Days: 10,
      historicSumGranularBucket131_140Days: 7,
      historicSumGranularBucket141_150Days: null,
      historicSumGranularBucket151_160Days: null,
      historicSumGranularBucket161_170Days: 2,
      historicSumGranularBucket171_180Days: 6,
      historicSumGranularBucket181_195Days: 14,
      historicSumGranularBucket196_210Days: 10,
      historicSumGranularBucket211_225Days: 15,
      historicSumGranularBucket226_240Days: 17,
      historicSumGranularBucket241_255Days: 24,
      historicSumGranularBucket256_270Days: 10,
      historicSumGranularBucket271_285Days: 11,
      historicSumGranularBucket286_300Days: 7,
      historicSumGranularBucket301_315Days: 1,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: null,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      lastIndex: 0,
      monthlyCount: 435,
      monthlyMedianAge: 83,
      xAxisValue: 'May',
    },
    {
      countPerBucket0_10Days: 145,
      countPerBucket11_30Days: 71,
      countPerBucket31_90Days: 96,
      countPerBucket91_180Days: 48,
      countPerBucket181_270Days: 52,
      countPerBucket271_365Days: 35,
      countPerBucket365Days: null,
      date: '2023-06-01',
      historicSumGranularBucket0_10Days: 145,
      historicSumGranularBucket11_20Days: 58,
      historicSumGranularBucket21_30Days: 13,
      historicSumGranularBucket31_40Days: 57,
      historicSumGranularBucket41_50Days: 18,
      historicSumGranularBucket51_60Days: 4,
      historicSumGranularBucket61_70Days: 8,
      historicSumGranularBucket71_80Days: 6,
      historicSumGranularBucket81_90Days: 3,
      historicSumGranularBucket91_100Days: 6,
      historicSumGranularBucket101_110Days: 3,
      historicSumGranularBucket111_120Days: 4,
      historicSumGranularBucket121_130Days: 5,
      historicSumGranularBucket131_140Days: 6,
      historicSumGranularBucket141_150Days: 7,
      historicSumGranularBucket151_160Days: 10,
      historicSumGranularBucket161_170Days: 7,
      historicSumGranularBucket171_180Days: null,
      historicSumGranularBucket181_195Days: 1,
      historicSumGranularBucket196_210Days: 7,
      historicSumGranularBucket211_225Days: 14,
      historicSumGranularBucket226_240Days: 7,
      historicSumGranularBucket241_255Days: 12,
      historicSumGranularBucket256_270Days: 11,
      historicSumGranularBucket271_285Days: 15,
      historicSumGranularBucket286_300Days: 6,
      historicSumGranularBucket301_315Days: 9,
      historicSumGranularBucket316_330Days: 4,
      historicSumGranularBucket331_345Days: 1,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: null,
      lastIndex: 0,
      monthlyCount: 447,
      monthlyMedianAge: 78,
      xAxisValue: 'Jun',
    },
    {
      countPerBucket0_10Days: 162,
      countPerBucket11_30Days: 143,
      countPerBucket31_90Days: 95,
      countPerBucket91_180Days: 44,
      countPerBucket181_270Days: 39,
      countPerBucket271_365Days: 35,
      countPerBucket365Days: 1,
      date: '2023-07-01',
      historicSumGranularBucket0_10Days: 162,
      historicSumGranularBucket11_20Days: 89,
      historicSumGranularBucket21_30Days: 54,
      historicSumGranularBucket31_40Days: 16,
      historicSumGranularBucket41_50Days: 4,
      historicSumGranularBucket51_60Days: 5,
      historicSumGranularBucket61_70Days: 59,
      historicSumGranularBucket71_80Days: 6,
      historicSumGranularBucket81_90Days: 5,
      historicSumGranularBucket91_100Days: 4,
      historicSumGranularBucket101_110Days: 7,
      historicSumGranularBucket111_120Days: 4,
      historicSumGranularBucket121_130Days: 6,
      historicSumGranularBucket131_140Days: 3,
      historicSumGranularBucket141_150Days: 4,
      historicSumGranularBucket151_160Days: 5,
      historicSumGranularBucket161_170Days: 6,
      historicSumGranularBucket171_180Days: 5,
      historicSumGranularBucket181_195Days: 13,
      historicSumGranularBucket196_210Days: 3,
      historicSumGranularBucket211_225Days: 1,
      historicSumGranularBucket226_240Days: 4,
      historicSumGranularBucket241_255Days: 8,
      historicSumGranularBucket256_270Days: 10,
      historicSumGranularBucket271_285Days: 8,
      historicSumGranularBucket286_300Days: 9,
      historicSumGranularBucket301_315Days: 8,
      historicSumGranularBucket316_330Days: 5,
      historicSumGranularBucket331_345Days: 3,
      historicSumGranularBucket346_365Days: 2,
      historicSumGranularBucket365Days: 1,
      lastIndex: 0,
      monthlyCount: 519,
      monthlyMedianAge: 67,
      xAxisValue: 'Jul',
    },
    {
      countPerBucket0_10Days: 90,
      countPerBucket11_30Days: 154,
      countPerBucket31_90Days: 119,
      countPerBucket91_180Days: 95,
      countPerBucket181_270Days: 29,
      countPerBucket271_365Days: 32,
      countPerBucket365Days: 1,
      date: '2023-08-01',
      historicSumGranularBucket0_10Days: 90,
      historicSumGranularBucket11_20Days: 68,
      historicSumGranularBucket21_30Days: 86,
      historicSumGranularBucket31_40Days: 26,
      historicSumGranularBucket41_50Days: 56,
      historicSumGranularBucket51_60Days: 21,
      historicSumGranularBucket61_70Days: 8,
      historicSumGranularBucket71_80Days: 5,
      historicSumGranularBucket81_90Days: 3,
      historicSumGranularBucket91_100Days: 48,
      historicSumGranularBucket101_110Days: 18,
      historicSumGranularBucket111_120Days: 4,
      historicSumGranularBucket121_130Days: 5,
      historicSumGranularBucket131_140Days: 5,
      historicSumGranularBucket141_150Days: 4,
      historicSumGranularBucket151_160Days: 4,
      historicSumGranularBucket161_170Days: 4,
      historicSumGranularBucket171_180Days: 3,
      historicSumGranularBucket181_195Days: 5,
      historicSumGranularBucket196_210Days: 10,
      historicSumGranularBucket211_225Days: 8,
      historicSumGranularBucket226_240Days: 2,
      historicSumGranularBucket241_255Days: 1,
      historicSumGranularBucket256_270Days: 3,
      historicSumGranularBucket271_285Days: 3,
      historicSumGranularBucket286_300Days: 9,
      historicSumGranularBucket301_315Days: 4,
      historicSumGranularBucket316_330Days: 6,
      historicSumGranularBucket331_345Days: 5,
      historicSumGranularBucket346_365Days: 5,
      historicSumGranularBucket365Days: 1,
      lastIndex: 0,
      monthlyCount: 520,
      monthlyMedianAge: 70,
      xAxisValue: 'Aug',
    },
    {
      countPerBucket0_10Days: 52,
      countPerBucket11_30Days: 29,
      countPerBucket31_90Days: 290,
      countPerBucket91_180Days: 97,
      countPerBucket181_270Days: 29,
      countPerBucket271_365Days: 13,
      countPerBucket365Days: 4,
      date: '2023-09-01',
      historicSumGranularBucket0_10Days: 52,
      historicSumGranularBucket11_20Days: 15,
      historicSumGranularBucket21_30Days: 14,
      historicSumGranularBucket31_40Days: 82,
      historicSumGranularBucket41_50Days: 63,
      historicSumGranularBucket51_60Days: 54,
      historicSumGranularBucket61_70Days: 20,
      historicSumGranularBucket71_80Days: 50,
      historicSumGranularBucket81_90Days: 21,
      historicSumGranularBucket91_100Days: 8,
      historicSumGranularBucket101_110Days: 5,
      historicSumGranularBucket111_120Days: 3,
      historicSumGranularBucket121_130Days: 48,
      historicSumGranularBucket131_140Days: 18,
      historicSumGranularBucket141_150Days: 4,
      historicSumGranularBucket151_160Days: 2,
      historicSumGranularBucket161_170Days: 5,
      historicSumGranularBucket171_180Days: 4,
      historicSumGranularBucket181_195Days: 5,
      historicSumGranularBucket196_210Days: 4,
      historicSumGranularBucket211_225Days: 5,
      historicSumGranularBucket226_240Days: 7,
      historicSumGranularBucket241_255Days: 6,
      historicSumGranularBucket256_270Days: 2,
      historicSumGranularBucket271_285Days: null,
      historicSumGranularBucket286_300Days: 2,
      historicSumGranularBucket301_315Days: 2,
      historicSumGranularBucket316_330Days: 5,
      historicSumGranularBucket331_345Days: 1,
      historicSumGranularBucket346_365Days: 3,
      historicSumGranularBucket365Days: 4,
      lastIndex: 0,
      monthlyCount: 514,
      monthlyMedianAge: 80,
      xAxisValue: 'Sep',
    },
    {
      countPerBucket0_10Days: 40,
      countPerBucket11_30Days: 33,
      countPerBucket31_90Days: 240,
      countPerBucket91_180Days: 172,
      countPerBucket181_270Days: 31,
      countPerBucket271_365Days: 12,
      countPerBucket365Days: 4,
      date: '2023-10-01',
      historicSumGranularBucket0_10Days: 40,
      historicSumGranularBucket11_20Days: 27,
      historicSumGranularBucket21_30Days: 6,
      historicSumGranularBucket31_40Days: 27,
      historicSumGranularBucket41_50Days: 5,
      historicSumGranularBucket51_60Days: 35,
      historicSumGranularBucket61_70Days: 52,
      historicSumGranularBucket71_80Days: 73,
      historicSumGranularBucket81_90Days: 48,
      historicSumGranularBucket91_100Days: 34,
      historicSumGranularBucket101_110Days: 34,
      historicSumGranularBucket111_120Days: 17,
      historicSumGranularBucket121_130Days: 9,
      historicSumGranularBucket131_140Days: 4,
      historicSumGranularBucket141_150Days: 10,
      historicSumGranularBucket151_160Days: 54,
      historicSumGranularBucket161_170Days: 6,
      historicSumGranularBucket171_180Days: 4,
      historicSumGranularBucket181_195Days: 5,
      historicSumGranularBucket196_210Days: 5,
      historicSumGranularBucket211_225Days: 5,
      historicSumGranularBucket226_240Days: 4,
      historicSumGranularBucket241_255Days: 6,
      historicSumGranularBucket256_270Days: 6,
      historicSumGranularBucket271_285Days: 6,
      historicSumGranularBucket286_300Days: null,
      historicSumGranularBucket301_315Days: null,
      historicSumGranularBucket316_330Days: 3,
      historicSumGranularBucket331_345Days: 1,
      historicSumGranularBucket346_365Days: 2,
      historicSumGranularBucket365Days: 4,
      lastIndex: 0,
      monthlyCount: 532,
      monthlyMedianAge: 96,
      xAxisValue: 'Oct',
    },
    {
      countPerBucket0_10Days: 28,
      countPerBucket11_30Days: 62,
      countPerBucket31_90Days: 124,
      countPerBucket91_180Days: 281,
      countPerBucket181_270Days: 30,
      countPerBucket271_365Days: 10,
      countPerBucket365Days: 6,
      date: '2023-11-01',
      historicSumGranularBucket0_10Days: 28,
      historicSumGranularBucket11_20Days: 39,
      historicSumGranularBucket21_30Days: 23,
      historicSumGranularBucket31_40Days: 21,
      historicSumGranularBucket41_50Days: 3,
      historicSumGranularBucket51_60Days: 8,
      historicSumGranularBucket61_70Days: 4,
      historicSumGranularBucket71_80Days: 17,
      historicSumGranularBucket81_90Days: 71,
      historicSumGranularBucket91_100Days: 66,
      historicSumGranularBucket101_110Days: 55,
      historicSumGranularBucket111_120Days: 29,
      historicSumGranularBucket121_130Days: 37,
      historicSumGranularBucket131_140Days: 19,
      historicSumGranularBucket141_150Days: 9,
      historicSumGranularBucket151_160Days: 3,
      historicSumGranularBucket161_170Days: 5,
      historicSumGranularBucket171_180Days: 58,
      historicSumGranularBucket181_195Days: 8,
      historicSumGranularBucket196_210Days: 2,
      historicSumGranularBucket211_225Days: 5,
      historicSumGranularBucket226_240Days: 5,
      historicSumGranularBucket241_255Days: 4,
      historicSumGranularBucket256_270Days: 6,
      historicSumGranularBucket271_285Days: 5,
      historicSumGranularBucket286_300Days: 1,
      historicSumGranularBucket301_315Days: 3,
      historicSumGranularBucket316_330Days: null,
      historicSumGranularBucket331_345Days: 1,
      historicSumGranularBucket346_365Days: null,
      historicSumGranularBucket365Days: 6,
      lastIndex: 0,
      monthlyCount: 541,
      monthlyMedianAge: 106,
      xAxisValue: 'Nov',
    },
  ],
  headings: [
    {
      description: '',
      key: 'countPerBucket0_10Days',
    },
    {
      description: '',
      key: 'countPerBucket11_30Days',
    },
    {
      description: '',
      key: 'countPerBucket31_90Days',
    },
    {
      description: '',
      key: 'countPerBucket91_180Days',
    },
    {
      description: '',
      key: 'countPerBucket181_270Days',
    },
    {
      description: '',
      key: 'countPerBucket271_365Days',
    },
    {
      description: '',
      key: 'countPerBucket365Days',
    },
    {
      description: '',
      key: 'lastIndex',
    },
  ],
};

export const STOCK_MIX_CHART_DATA_MOCK: any = {
  fields: [
    {
      averageStockCoverage: 10,
      model: 'F57 LCI',
    },
    {
      averageStockCoverage: 50,
      model: 'F57 Pre LCI',
    },
  ],
  headings: [
    {
      description: 'AverageStockCoverage',
      key: 'averageStockCoverage',
    },
  ],
};

export const STOCK_MIX_CHART_DATA_MOCK_RESPONSE: any = {
  stock_mix: {
    data: [
      {
        deltaStockCoverage: DeltaStockCoverageEnum.increase,
        model: 'Cooper 3',
        next_month_expected: '-0.6',
        total_stock: 152,
        value: 5,
      },
      {
        deltaStockCoverage: DeltaStockCoverageEnum.stable,
        model: 'Cooper 2',
        next_month_expected: '0.1',
        total_stock: 121,
        value: 5,
      },
      {
        deltaStockCoverage: DeltaStockCoverageEnum.decrease,
        model: 'Cooper 1',
        next_month_expected: '+0.1',
        total_stock: 90,
        value: 3.5,
      },
    ],
    ideal_stock_parameter: 3,
    stock_mix_avg_coverage: {
      display_name: 'AVG. Stock COVERAGE',
      display_name_details: '+0.4 vs prev. month',
      selected_series: 'Countryman',
      value: '2.7 months',
    },
    stock_mix_isp_coverage: {
      display_name: 'ISP Stock COVERAGE',
      display_name_details: '+0.0 vs prev. month',
      selected_series: 'Countryman',
      value: '2.2 months',
    },
  },
};
