import { AuthService } from '../../auth/services/auth.service';
import { Component, OnInit } from '@angular/core';
import { ContextService } from 'src/app/matrix-view/services/context/context.service';
import { FtdWebVitalsService } from '../../common/services/ftd-web-vitals/ftd-web-vitals.service';
import { MAIN_SCENARIO_CONFIG } from '../configs/scenario.config';
import { ScenarioContext } from 'src/app/common/enums/scenario-context.enum';
import { ScenarioDefaultIds } from '../../common/enums/scenario-default-ids.enum';

@Component({
  selector: 'app-ftd-main-scenario',
  templateUrl: './main-scenario.component.html',
})
export class MainScenarioComponent implements OnInit {
  get isApproveScenarioButtonHidden(): boolean {
    return this._isApproveScenarioButtonHidden;
  }

  set isApproveScenarioButtonHidden(value: boolean) {
    this._isApproveScenarioButtonHidden = value;
  }

  get context(): ScenarioContext {
    return this._context;
  }

  set context(value: ScenarioContext) {
    this._context = value;
  }

  get isMatrixViewSideFiltersHidden(): boolean {
    return this._isMatrixViewSideFiltersHidden;
  }

  set isMatrixViewSideFiltersHidden(value: boolean) {
    this._isMatrixViewSideFiltersHidden = value;
  }

  get isMatrixViewDataHidden(): boolean {
    return this._isMatrixViewDataHidden;
  }

  set isMatrixViewDataHidden(value: boolean) {
    this._isMatrixViewDataHidden = value;
  }

  get isReturnButtonHidden(): boolean {
    return this._isReturnButtonHidden;
  }

  set isReturnButtonHidden(value: boolean) {
    this._isReturnButtonHidden = value;
  }

  get title(): string {
    return this._title;
  }

  set title(value: string) {
    this._title = value;
  }

  get isExportButtonHidden(): boolean {
    return this._isExportButtonHidden;
  }

  set isExportButtonHidden(value: boolean) {
    this._isExportButtonHidden = value;
  }

  get isUnlockScenarioButtonHidden(): boolean {
    return this._isUnlockScenarioButtonHidden;
  }

  set isUnlockScenarioButtonHidden(value: boolean) {
    this._isUnlockScenarioButtonHidden = value;
  }

  get isSubmitScenarioButtonHidden(): boolean {
    return this._isSubmitScenarioButtonHidden;
  }

  set isSubmitScenarioButtonHidden(value: boolean) {
    this._isSubmitScenarioButtonHidden = value;
  }

  private _title: string = MAIN_SCENARIO_CONFIG.title;
  private _isReturnButtonHidden: boolean = MAIN_SCENARIO_CONFIG.isReturnButtonHidden;
  private _isMatrixViewDataHidden: boolean = MAIN_SCENARIO_CONFIG.isMatrixViewDataHidden;
  private _isMatrixViewSideFiltersHidden: boolean = MAIN_SCENARIO_CONFIG.isMatrixViewSideFiltersHidden;
  private _isExportButtonHidden: boolean = MAIN_SCENARIO_CONFIG.isExportButtonHidden;
  private _isApproveScenarioButtonHidden: boolean = MAIN_SCENARIO_CONFIG.isApproveScenarioButtonHidden;
  private _isUnlockScenarioButtonHidden: boolean = MAIN_SCENARIO_CONFIG.isUnlockScenarioButtonHidden;
  private _isSubmitScenarioButtonHidden: boolean = MAIN_SCENARIO_CONFIG.isSubmitScenarioButtonHidden;
  private _context: ScenarioContext = MAIN_SCENARIO_CONFIG.context;

  constructor(
    private contextService: ContextService,
    private authService: AuthService,
    private ftdWebVitalsService: FtdWebVitalsService
  ) {}

  ngOnInit(): void {
    this.ftdWebVitalsService.logWebVitals();
    this.contextService.setCurrentScenarioContext(ScenarioContext.mainScenario);
    this.contextService.scenarioId = ScenarioDefaultIds.governance;

    const loggedInUser = this.authService.getLoggedInUser();
    if (loggedInUser) {
      loggedInUser.permissions?.setSelectedPermissions(null);
      this.authService.updateLoggedInUserAndNotifyObservers(loggedInUser);
    }
  }
}
