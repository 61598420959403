<app-ftd-custom-modal>
  <app-ftd-custom-modal-header
    [titleLeftMatIcon]="'warning_amber'"
    [colorLeftMatIcon]="'warning'"
    [title]="title"
  ></app-ftd-custom-modal-header>

  <app-ftd-custom-modal-body>
    <div class="mb-3">
      <span>
        Save changes in “{{ scenarioName }}” before leaving this page? <br />
        If you don’t save, your changes will be lost.
      </span>
    </div>

    <div>
      <span> Are you sure you want to save the price adjustment(s) in this User Scenario? </span>
    </div>
  </app-ftd-custom-modal-body>

  <app-ftd-custom-modal-footer>
    <app-ftd-button
      [id]="'dont-save-scenario-and-continue-btn'"
      (btnClick)="continueNavigation(false)"
      [disabled]="false"
      [label]="'Don’t Save and Continue'"
      ariaText="Don't Save and Continue"
      [matTooltip]="''"
      hierarchy="secondary"
      matTooltipPosition="above"
      [matTooltipDisabled]="true"
    ></app-ftd-button>
    <app-ftd-button
      [id]="'save-scenario-and-continue-btn'"
      (btnClick)="continueNavigation(true)"
      [disabled]="false"
      [label]="'Save and continue'"
      ariaText="Save and continue"
      [matTooltip]="''"
      matTooltipPosition="above"
      [matTooltipDisabled]="true"
      [mat-dialog-close]="true"
    ></app-ftd-button>
  </app-ftd-custom-modal-footer>
</app-ftd-custom-modal>
