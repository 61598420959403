import { Component, Input } from '@angular/core';
import { DiscountApprovalStatus } from 'src/app/discount-management/enums/discount-approval-status.enum';
import { IDiscount } from '../../../../graphql/services/gql-api.service';
import { IDiscountStatusMap, discountStatusMap } from '../config/discount-status-map.config';
import { ISummaryTableColumnModel, SummaryTableColumnType } from '../../../models/discounts-summary-table.model';

@Component({
  selector: 'app-discounts-summary-table-column-map',
  styleUrls: ['./discounts-summary-table-column-map.component.scss'],
  templateUrl: './discounts-summary-table-column-map.component.html',
})
export class DiscountsSummaryTableColumnMapComponent {
  @Input({ required: true }) index!: number;
  @Input({ required: true }) dataItem!: IDiscount;
  @Input({ required: true }) column!: ISummaryTableColumnModel;
  protected readonly ColumnType = SummaryTableColumnType;
  protected readonly discountStatusMap: Map<DiscountApprovalStatus, IDiscountStatusMap> = discountStatusMap;

  actions: IActionButton[] = [
    {
      icon: 'description',
      onClick: (id: string): void => console.log('Note for ', id),
      tooltip: 'Private note',
    },
    {
      icon: 'chat',
      onClick: (id: string): void => console.log('Comment for ', id),
      tooltip: 'Public comment',
    },
  ];

  getStatus(dataItem: IDiscount): DiscountApprovalStatus {
    return (dataItem.approvalStatus || DiscountApprovalStatus.SUBMITTED) as DiscountApprovalStatus;
  }
}

interface IActionButton {
  icon: 'description' | 'chat' | 'visibility';
  onClick: Function;
  tooltip: string;
}
